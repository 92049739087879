// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html

import * as actions from "../../../_redux/country/countryAction";
import * as columnFormatters from "./column-formatters";
import * as uiHelpers from "../ContainerUIHelpers";
import SVG from "react-inlinesvg";
import React, { useEffect, useMemo } from "react";
import {
  getHandlerTableChange,
  getSelectRow,
  headerSortingClasses,
  sortCaret,
  toAbsoluteUrl,
} from "../../../../../../_metronic/_helpers";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import BootstrapTable from "react-bootstrap-table-next";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import { useContainerUIContext } from "../ContainerUIContext";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { fetchContainerList } from "../../../_redux/container/containerAction";

const ContainerTable = ({ permission ,setOpenCanva }) => {
  const intl = useIntl();
  let history = useHistory();
  const ContainerUIContext = useContainerUIContext();
  const ContainerUIProps = useMemo(() => {
    return {
      ids: ContainerUIContext?.ids,
      setIds: ContainerUIContext?.setIds,
      queryParams: ContainerUIContext?.queryParams,
      setQueryParams: ContainerUIContext?.setQueryParams,
      openEditOrderPage: ContainerUIContext?.openEditOrderPage,
      openDeleteOrderDialog: ContainerUIContext?.openDeleteOrderDialog,
    };
  }, [ContainerUIContext]);
  const dispatch = useDispatch();
  const { currentState } = useSelector(
    (state) => ({
      currentState: state.container.list,
      permission: state.auth.permission,
    }),
    shallowEqual
  );

  const { totalCount, entities, listLoading } = currentState;

  useEffect(() => {
    // clear selections list
    // server call by queryParams
    ContainerUIProps.setIds([]);
    dispatch(fetchContainerList(ContainerUIProps.queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ContainerUIProps.queryParams, dispatch]);

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "container_size",
      text: "Container size",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (e, data) => {
        return <span>{e+" "+"sqft" || "-"}</span>;
      },
    },
    {
      dataField: "franchise_name",
      text: "Franchise Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },

    {
      dataField: "deposit_value",
      text: "Deposite value",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (e, data) => {
        return <span> £{e}</span>;
      },
    },
    {
      dataField: "collection_fee",
      text: "Collection Fee",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (e, data) => {
        return <span> £{e}</span>;
      },
    },
    {
      dataField: "return_fee",
      text: "Return fee",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (e, data) => {
        return <span> £{e}</span>;
      },
    },
    {
      dataField: "status",
      text: "Action",
      sort: true,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return  <span
        className="symbol-label  pointer"
        onClick={() => setOpenCanva({ check: true, data:data })}
      >
        <span className="svg-icon svg-icon-sm svg-icon-primary mr-1">
          <SVG
            className="h-25 align-self-end"
            title="Edit"
            src={toAbsoluteUrl(
              "/media/svg/icons/Design/Edit.svg"
            )}
          ></SVG>
        </span>
      </span>;
      },
    },
  ];

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: ContainerUIProps.queryParams?.pageSize,
    page: ContainerUIProps.queryParams?.pageNumber,
  };

  // const rowEvents = {
  //   onClick: (e, row, rowIndex) => {
  //     history.push(`country/country-details/${row.id}`);
  //   },
  // };

  const selectRow = {
    mode: "checkbox",
    hideSelectAll: true,
    hideSelectColumn: true,
  };

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
              entities={entities}
            >
              <BootstrapTable
                // rowEvents={rowEvents}
                wrapperClasses="table-responsive"
                hover
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="id"
                selectRow={
                  permission?.setting_country?.delete
                    ? getSelectRow({
                        entities,
                        ids: ContainerUIProps.ids,
                        setIds: ContainerUIProps.setIds,
                      })
                    : selectRow
                }
                defaultSorted={uiHelpers.defaultSorted}
                data={entities === null ? [] : entities}
                onTableChange={getHandlerTableChange(
                  ContainerUIProps.setQueryParams
                )}
                columns={columns}
                {...paginationTableProps}
              ></BootstrapTable>

              {entities && entities?.length ? (
                ""
              ) : (
                <div style={{ textAlign: "center" }}>
                  <p>{intl.formatMessage({ id: "No.Record.Found" })}</p>
                </div>
              )}
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
};

export default ContainerTable;
