/* eslint-disable array-callback-return */
import React, { useEffect } from "react";
import { FormControl, MenuItem, Select } from "@material-ui/core";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import SVG from "react-inlinesvg";
import { dealsFranchiseListing } from "../_redux/deals/dealsCrud";
import { getInputClasses } from "../../../utils/commonFunction";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { useIntl } from "react-intl";
import CountryPhoneNumber from "../../../../_metronic/layout/components/CountryPhoneNumber";

const CreateContactForm = ({
  formik,
  type,
  handleGetAddress,
  contactDetail,
  ownerAgent,
  fieldArray,
  setFieldArray,
  admin,
  check,
  editCustomer,
  checked,
  dropDown,
  pageCheck,
  dropDownFranchise,
  setDropDownFranchise,
}) => {
  const intl = useIntl();

  const handleFranchiseList = () => {
    dealsFranchiseListing().then((res) => {
      setDropDownFranchise({ franchise: res.data.data });
    });
  };

  useEffect(() => {
    handleFranchiseList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleManualUpdate = () => {
    formik.setFieldValue("address", "");
    formik.setFieldValue("city", "");
    formik.setFieldValue("is_address_manual", 1);
  };

  const handleAddArrayField = ({ e, index, field }) => {
    const arr = [...formik.values[field]];

    arr[index] = "+" + e;

    formik.setFieldValue(field, arr);
  };
  const handleAddArrayEmailField = (e, index, field) => {
    const arr = [...formik.values[field]];

    arr[index] = e.target.value;

    formik.setFieldValue(field, arr);
  };

  useEffect(() => {
    if (type === "edit" && contactDetail?.email) {
      setFieldArray({
        ...fieldArray,
        email: contactDetail?.email?.length || 1,
        phone: contactDetail?.phone?.length || 1,
      });
    } else if (check === "customer" && editCustomer?.email && type === "edit") {
      setFieldArray({
        ...fieldArray,
        email: editCustomer?.email?.length || 1,
        phone: editCustomer?.phone?.length || 1,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactDetail, type]);
  return (
    <div className="row">
      <div className="col-6">
        <label>{intl.formatMessage({ id: "First.Name" })}</label>
        <div className="form-groupB">
          <input
            type="text"
            className={`form-control  ${getInputClasses(formik, "first_name")}`}
            placeholder={intl.formatMessage({ id: "First.Name" })}
            name="first_name"
            {...formik.getFieldProps("first_name")}
          />
          {formik.touched.first_name && formik.errors.first_name ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.first_name}</div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="col-6">
        <label>{intl.formatMessage({ id: "Last.Name" })}</label>
        <div className="form-groupB">
          <input
            type="text"
            className={`form-control  ${getInputClasses(formik, "last_name")}`}
            placeholder={intl.formatMessage({ id: "Last.Name" })}
            name="last_name"
            {...formik.getFieldProps("last_name")}
          />
          {formik.touched.last_name && formik.errors.last_name ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.last_name}</div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="col-12 ">
        <label>{intl.formatMessage({ id: "Email.Name" })}</label>

        <div className=" d-flex  align-items-center row">
          {Array(fieldArray?.email)
            .fill("")
            .map((data, index) => (
              <div className="col-10 form-groupB w-100" key={index}>
                <input
                  type="text"
                  className={`form-control `}
                  placeholder={index === 0 ? "Email" : "Additional Email"}
                  name="email"
                  onChange={(e) => handleAddArrayEmailField(e, index, "email")}
                  value={
                    formik &&
                    formik.values &&
                    formik.values.email &&
                    formik.values.email.length
                      ? formik.values.email[index]
                      : ""
                  }
                  // {...formik.getFieldProps("email")}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {typeof formik.errors?.email === "string" && index === 0
                        ? formik.errors?.email
                        : typeof formik.errors?.email === "object"
                        ? formik.errors?.email[index]
                        : ""}
                    </div>
                  </div>
                ) : null}
              </div>
            ))}

          <div
            className={
              formik.touched.email && formik.errors.email ? "mb-8" : "mb-2"
            }
          >
            <span className="symbol-label col-2">
              <span
                className="svg-icon svg-icon-lg svg-icon-primary"
                id="kt_contactdetails_panel_toggle"
                onClick={() =>
                  setFieldArray({ ...fieldArray, email: fieldArray.email + 1 })
                }
              >
                <SVG
                  className="h-25 align-self-end"
                  title=" "
                  style={{ cursor: "pointer" }}
                  src={toAbsoluteUrl("/media/svg/icons/Code/Plus.svg")}
                ></SVG>
              </span>
            </span>
          </div>
        </div>
      </div>

      <div className="col-12">
        <label>{intl.formatMessage({ id: "PHONE" })}</label>
        <div className="d-flex  align-items-center row">
          {Array(fieldArray?.phone)
            .fill("")
            .map((data, index) => (
              <div className="col-10 form-groupB w-100" key={index}>
                <CountryPhoneNumber
                  countryPhoneValue={
                    formik &&
                    formik.values &&
                    formik.values.phone &&
                    formik.values.phone.length
                      ? formik.values.phone[index]
                      : ""
                  }
                  formik={formik}
                  fieldKey={"phone"}
                  type={"on_page"}
                  functionData={index}
                  handleCountryPhone={handleAddArrayField}
                />
                {/* <input
                  type="text"
                  className={`form-control`}
                  placeholder={
                    index === 0
                      ? intl.formatMessage({ id: "PHONE" })
                      : `Additional Phone ${index + 1}`
                  }
                  name="phone"
                  onChange={(e) => handleAddArrayField(e, index, "phone")}
                  value={
                    formik &&
                    formik.values &&
                    formik.values.phone &&
                    formik.values.phone.length
                      ? formik.values.phone[index]
                      : ""
                  }
                  maxLength={15}
                />*/}

                {formik.touched.phone && formik.errors.phone ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {typeof formik.errors?.phone === "string" && index === 0
                        ? formik.errors?.phone
                        : typeof formik.errors?.phone === "object"
                        ? formik.errors?.phone[index]
                        : ""}
                    </div>
                  </div>
                ) : null}
              </div>
            ))}

          <div
            className={
              formik.touched.phone && formik.errors.phone ? "mb-8" : "mb-2"
            }
          >
            <span className="symbol-label col-2">
              <span
                className="svg-icon svg-icon-lg svg-icon-primary"
                id="kt_contactdetails_panel_toggle"
              >
                <SVG
                  className="h-25 align-self-end"
                  title=" "
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    setFieldArray({
                      ...fieldArray,
                      phone: fieldArray.phone + 1,
                    })
                  }
                  src={toAbsoluteUrl("/media/svg/icons/Code/Plus.svg")}
                ></SVG>
              </span>
            </span>
          </div>
        </div>
      </div>
      <div className="col-12">
        {/* <label>{intl.formatMessage({ id: "Post.Code" })}</label> */}
        {pageCheck === "new_order"
          ? "Collection Post Code"
          : intl.formatMessage({ id: "Post.Code" })}
        <div className="row align-items-center">
          <div className="col-6 d-flex justify-content-between align-items-center ">
            <div className="form-groupB w-100">
              <input
                type="text"
                className={`form-control  ${getInputClasses(
                  formik,
                  "postcode"
                )}`}
                placeholder={intl.formatMessage({ id: "Post.Code" })}
                name="postcode"
                value={formik.values?.postcode}
                onChange={(e) => {
                  const inputValue = e.target.value;

                  // Remove any non-alphanumeric characters from the input value
                  const alphanumericValue = inputValue.replace(/\W/g, "");

                  // Use regex to format the input value as a UK postcode
                  const formattedValue = alphanumericValue.replace(
                    /^([A-Z]{1,2}\d{1,2}[A-Z]?)\s?(\d[A-Z]{2})$/i,
                    "$1 $2"
                  );
                  formik.setFieldValue("postcode", formattedValue);
                }}
                // {...formik.getFieldProps("postcode")}
              />
            </div>
            <div className="mb-2">
              <span className="symbol-label ml-2">
                <span
                  className="svg-icon svg-icon-lg svg-icon-primary"
                  onClick={() => handleGetAddress(formik.values.postcode)}
                >
                  <SVG
                    className="h-25 align-self-end"
                    style={{ cursor: "pointer" }}
                    title=" "
                    src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")}
                  ></SVG>
                </span>
              </span>
            </div>
          </div>
          <div className="col-md-6 mb-2 123">
            <span
              style={{ color: "#ff9f60", cursor: "pointer" }}
              onClick={handleManualUpdate}
            >
              {intl.formatMessage({ id: "Enter.Manually" })}
            </span>
          </div>
          {formik.touched.postcode && formik.errors.postcode ? (
            <div className=" ml-4 mb-4 fv-plugins-message-container">
              <div className="fv-help-block">{formik?.errors?.postcode}</div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="col-12">
        <label>
          {pageCheck === "new_order"
            ? "Collection Address"
            : intl.formatMessage({ id: "Address" })}
        </label>
        <div className="form-groupB">
          {formik.values.is_address_manual === 1 ? (
            <input
              type="text"
              className={`form-control  ${getInputClasses(formik, "address")}`}
              placeholder={intl.formatMessage({ id: "Address" })}
              name="address"
              {...formik.getFieldProps("address")}
            />
          ) : (
            <FormControl fullWidth>
              <Select
                name="address"
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                className={`form-control  ${getInputClasses(
                  formik,
                  "address"
                )}`}
                IconComponent={(props) => (
                  <i {...props}>
                    <KeyboardArrowDownIcon />
                  </i>
                )}
                inputProps={{ "aria-label": "Without label" }}
                displayEmpty
                {...formik.getFieldProps("address")}
              >
                <MenuItem value="">
                  {intl.formatMessage({ id: "Address" })}
                </MenuItem>
                {dropDown?.address && dropDown?.address.length > 0
                  ? dropDown?.address.map((data, index) => {
                      let addressData = data.formatted_address.toString();
                      addressData = addressData?.replaceAll(", ", ",");
                      addressData = addressData?.replaceAll(",, ", ",");
                      addressData = addressData?.replaceAll(",,,", ",");

                      return (
                        <MenuItem value={addressData} key={index}>
                          {addressData}
                        </MenuItem>
                      );
                    })
                  : null}
              </Select>
            </FormControl>
          )}

          {formik.touched.address && formik.errors.address ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.address}</div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="col-12">
        <label>{intl.formatMessage({ id: "CITY" })}</label>
        <div className="form-groupB">
          <input
            type="text"
            className={`form-control  ${getInputClasses(formik, "city")}`}
            placeholder={intl.formatMessage({ id: "CITY" })}
            name="city"
            {...formik.getFieldProps("city")}
          />
          {formik.touched.city && formik.errors.city ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.city}</div>
            </div>
          ) : null}
        </div>
      </div>
      {type === "edit" && check !== "customer" ? (
        ""
      ) : (
        <>
          <div className="col-8">
            <label>{intl.formatMessage({ id: "Franchise" })}</label>
            <div className="form-groupB">
              <FormControl fullWidth>
                <Select
                  name="franchise_id"
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                  className={`form-control  ${getInputClasses(
                    formik,
                    "franchise_id"
                  )}`}
                  IconComponent={(props) => (
                    <i {...props}>
                      <KeyboardArrowDownIcon />
                    </i>
                  )}
                  inputProps={{ "aria-label": "Without label" }}
                  displayEmpty
                  {...formik.getFieldProps("franchise_id")}
                  disabled={checked === "customers"}
                >
                  <MenuItem value="">
                    {intl.formatMessage({ id: "Franchise" })}
                  </MenuItem>

                  {dropDownFranchise?.franchise &&
                  dropDownFranchise?.franchise.length > 0
                    ? dropDownFranchise?.franchise.map((data, index) => {
                        return (
                          <MenuItem
                            key={index}
                            value={data?.id}
                            disabled={
                              formik?.values?.postcode &&
                              admin?.roles &&
                              admin?.roles?.length > 0 &&
                              data?.id !== formik?.values?.franchise_id &&
                              admin?.roles[0]?.name === "franchise-owner" &&
                              pageCheck === "new_order"
                            }
                          >
                            {data?.name}
                          </MenuItem>
                        );
                      })
                    : null}
                </Select>
              </FormControl>
              {formik.touched.franchise_id && formik.errors.franchise_id ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.franchise_id}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div className="col-8">
            <label>{intl.formatMessage({ id: "Agent" })}</label>
            <div className="form-groupB">
              <FormControl fullWidth>
                <Select
                  name="contact_owner_id"
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                  className={`form-control  ${getInputClasses(
                    formik,
                    "contact_owner_id"
                  )}`}
                  IconComponent={(props) => (
                    <i {...props}>
                      <KeyboardArrowDownIcon />
                    </i>
                  )}
                  inputProps={{ "aria-label": "Without label" }}
                  displayEmpty
                  {...formik.getFieldProps("contact_owner_id")}
                  disabled={checked === "customers"}
                >
                  <MenuItem value="">
                    {intl.formatMessage({ id: "Agent" })}
                  </MenuItem>
                  <MenuItem value={admin.id}>
                    {admin.first_name +
                      " " +
                      (admin.last_name ? admin.last_name : "")}
                  </MenuItem>
                  {ownerAgent && ownerAgent.length ? (
                    ownerAgent.map((data, index) => {
                      if (admin.id !== data.id)
                        return (
                          <MenuItem
                            key={index}
                            value={data.id}
                            disabled={data?.is_available !== 1 ? true : false}
                          >
                            {data.first_name +
                              " " +
                              (data.last_name ? data.last_name : "")}
                          </MenuItem>
                        );
                    })
                  ) : (
                    <MenuItem value="d" disabled></MenuItem>
                  )}
                </Select>
              </FormControl>
              {formik.touched.contact_owner_id &&
              formik.errors.contact_owner_id ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.contact_owner_id}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CreateContactForm;
