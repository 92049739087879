/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */

import { EmailWidget } from "./email/EmailWidget";
import NoRecord from "../../../../../_metronic/_partials/widgets/NoRecord";
import React from "react";
import SVG from "react-inlinesvg";
import SpinnerLoader from "../../../../../_metronic/_partials/widgets/spinner";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";

export function Emails({ emailList, revertEmail, permission }) {
  return (
    <>
      <ul className="deal-top deal-note-icon">
        <li>
          <span className="symbol-label mr-4">
            {permission ? (
              <span
                className="svg-icon svg-icon-lg svg-icon-primary"
                id="kt_SendEmail_panel_toggle"
              >
                <SVG
                  title=" "
                  className="h-75 align-self-end"
                  src={toAbsoluteUrl("/media/svg/icons/Communication/Mail.svg")}
                ></SVG>
              </span>
            ) : (
              ""
            )}
          </span>
        </li>
      </ul>
      {emailList.loading ? (
        <SpinnerLoader />
      ) : (
        <div>
          {emailList && emailList.entities && emailList.entities?.length ? (
            emailList.entities.map((data, index) => (
              <div key={index}>
                <EmailWidget data={data} />
              </div>
            ))
          ) : (
            <NoRecord />
          )}
        </div>
      )}
      {revertEmail && revertEmail?.entities && revertEmail.entities?.length
        ? revertEmail.entities.map((data, index) => (
            <div key={index}>
              <EmailWidget data={data} type={"re"} />
            </div>
          ))
        : ""}
    </>
  );
}
