import { ContentRoute, LayoutSplashScreen } from "../../../../_metronic/layout";
import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";

import CountryDetailPage from "./country/CountryDetailPage";
import { CountryPage } from "./country/CountryPage";
import { DepotDetails } from "./depots/depot-details/DepotDetails";
import { DepotsPage } from "./depots/DepotsPage";
import { Feeds } from "./feeds/Feeds";
import { FranchiseDetails } from "./franchises/franchise-details/FranchiseDetails";
import { FranchisesPage } from "./franchises/FranchisesPage";
import { ProductDetails } from "./products/product-edit/ProductDetail/ProductDetails";
import { ProductsPage } from "./products/ProductsPage";
import ProfileDetailPage from "./user/ProfileDetailPage";
import PromotionsDetailPage from "./promotions/PromotionsDetailPage";
import { PromotionsPage } from "./promotions/PromotionsPage";
import { ReportingPage } from "./reporting/ReportingPage";
import UserDetailPage from "./user/UserDetailPage";
import { UserPage } from "./user/UserPage";
import { Availability } from "./Availability";
import { ExportImport } from "./ExportImport";
import { ContainerPage } from "./container/ContainerPage";
import LogsPage from "./franchises/Logs component/LogsPage";
// import { CustomerPage } from "../../Customer/CustomerPage";
// import { CustomersDetails } from "../../Customer/page/CustomersDetails";

export default function SettingPages() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from eCommerce root URL to /customers */
          <Redirect exact={true} from="/settings" to="/setting-page" />
        }
        <ContentRoute exact path="/settings/reports" component={ExportImport} />
        <ContentRoute
          exact
          path="/settings/products/product-details/:id"
          component={ProductDetails}
        />
        <ContentRoute
          exact
          path="/settings/franchises/franchise-details/:id/:name/products/:p_id"
          component={ProductDetails}
        />
        <ContentRoute
          exact
          path="/settings/products"
          component={ProductsPage}
        />
        <ContentRoute
          exact
          path="/settings/franchises/franchise-details/:id"
          component={FranchiseDetails}
        />
        <ContentRoute
          exact
          path="/settings/franchises/franchise-details/:id/:name/products"
          component={ProductsPage}
        />
         <ContentRoute
          exact
          path="/settings/franchises/franchise-details/:id/:name/logs"
          component={LogsPage}
        />
        <ContentRoute
          exact
          path="/settings/franchises"
          component={FranchisesPage}
        />
        <ContentRoute exact path="/settings/country" component={CountryPage} />
        <ContentRoute
          exact
          path="/settings/country/country-details/:id"
          component={CountryDetailPage}
        />
        <ContentRoute
          exact
          path="/settings/promotions"
          component={PromotionsPage}
        />
        <ContentRoute
          exact
          path="/settings/promotions/promotions-details/:id"
          component={PromotionsDetailPage}
        />
        <ContentRoute
          exact
          path="/settings/reporting"
          component={ReportingPage}
        />
        <ContentRoute exact path="/settings/users" component={UserPage} />
        <ContentRoute
          exact
          path="/settings/users/user-details/:id"
          component={UserDetailPage}
        />
        <ContentRoute
          exact
          path="/settings/users/profile-details/:id"
          component={ProfileDetailPage}
        />

        <ContentRoute
          path="/settings/depots/depot-details/:id"
          component={DepotDetails}
        />
        <ContentRoute exact path="/settings/feeds" component={Feeds} />
        <ContentRoute path="/settings/depots" component={DepotsPage} />

        <ContentRoute path="/settings/timeline" component={Feeds} />
        <ContentRoute
          path="/settings/sales-agent-availability"
          component={Availability}
        />
        <ContentRoute
          exact
          path="/settings/container"
          component={ContainerPage}
        />
        {/* <ContentRoute
          exact
          path="/settings/customers"
          component={CustomerPage}
        />
        <ContentRoute
          exact
          path="/settings/customer-details/:id"
          component={CustomersDetails}
        /> */}
      </Switch>
    </Suspense>
  );
}
