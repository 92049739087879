import { useReducer } from "react";

//productArrayList
// const productArrayList = {
//   id: "",
//   name: "",
//   price: "",
//   total: "",
// };
const initialValue = {
  productArrayList: [],
  params: { unit: 1, period: "month" },
  products_id: [],
  productSubTotal: "",
  productTotal: "",
  coupon: [],
  productDicountAmount: "",
  adhoc: {},
  manualDiscount: [],
  extraCollection: null,
  cover: {},
  initialBillingCheck: false,
  adhocChargeName: "",
  productArray: [],
  orderData: {},
  breakdown: {},
  isBreakeDownApiCall: false,
  applyChanges: { prorate: true, invoicing_option: true },
};

const reducer = (state = initialValue, action) => {
  switch (action.type) {
    case "SET_DATA":
      return { ...state, ...action.data };
    case "RESET_DATA":
      return initialValue;
    default:
      return state;
  }
};

export const useContextState = (initial) => {
  const [state, dispatchReducer] = useReducer(reducer, initial || initialValue);
  return { state, dispatchReducer };
};
