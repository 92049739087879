import { assignComplete, assignDelete, exportApi, viewGetInvoice, viewPdfInvoice } from "../_redux/InvoiceCrud";

import { Modal } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { LoadingSpinner } from "../../../../_metronic/_partials/controls/LoadingSpinner";
import { LoadingCustome } from "../../../../_metronic/_partials/controls/LoadingCustome";

const SaveDialog = ({ active, setActive, handleComplete, type }) => {
  const intl = useIntl();
  const [interval, setIntervalData] = useState(null)
  const [getInterval, setGetInterval] = useState(null)
  const [loading, setLoading] = useState(false)
  const [callApi, setCallApi] = useState(false)


  const handleExportInterval = () => {
    setLoading(true)
    const data = {
      franchise_id: active?.fra_id,
      export_type: type === "credit" ? "credit_note" : "invoice"
    }
    exportApi(data).then((res) => {
      setIntervalData(res.data?.data)
      handleGetExport(res.data?.data)

    }).catch((error) => {

    })
  }

  const handleGetExport = (dataI) => {
    setLoading(true)
    const data = {
      franchise_id: active?.fra_id,
      id: dataI?.id || interval?.id
    }
    viewGetInvoice(data).then((res) => {
      // setGetInterval(res.data?.data)
      if (res.data?.data?.status === "completed") {
        window.open(res.data?.data?.download?.download_url, '_blank');
        setCallApi(false)
      } else {
        console.log("check-09");
        setTimeout(() => {

          setCallApi(true)
        }, 6000);
      }
      if (res.data?.data?.status === "completed") {
        setLoading(false)
        setActive(false)

      }
    }).catch((error) => {
      setLoading(false)
      setCallApi(false)
      setActive(false)



    })
  }

  // const intervalId = setTimeout(() => {
  //   if (getInterval?.status !== "completed") {

  //     // handleGetExport();
  //   }
  // }, 6000);
  console.log("check-09", callApi);
  useEffect(() => {
    if (callApi) {
      handleGetExport();
      setCallApi(false)
    }
  }, [callApi])


  useEffect(() => {
    handleExportInterval()

  }, []);


  return (
    <>
      {loading && <LoadingCustome />}
      {/*  */}
    </>
  );
};

export default SaveDialog;
