export const PromotionsSchema = (intl, Yup) => {
  return Yup.object().shape({
    offer_code: Yup.string().required(
      intl.formatMessage({ id: "Offer.Required" })
    ),
    offer_description: Yup.string().required(
      intl.formatMessage({ id: "Offer.description.Required" })
    ),
    offer_type: Yup.string().required(
      intl.formatMessage({ id: "Offer.type.Required" })
    ),
    value: Yup.string()
      .matches(
        /^[0-9.-\s]+$/,
        intl.formatMessage({ id: "Only.Numbers.allowed" })
      )
      .trim()
      .required(intl.formatMessage({ id: "value.Required" })),
    apply_on: Yup.string().required(
      intl.formatMessage({ id: "ApplyOn.Required" })
    ),
    duration_type: Yup.string().required(
      intl.formatMessage({ id: "Duration.Type.Required" })
    ),
    duration: Yup.string().when('duration_type', {
      is: 'multiple_months', 
      then: Yup.string().required(  intl.formatMessage({ id: "Duration.Required" })),
      otherwise: Yup.string().notRequired() 
    }    
    ),
  });
};
