/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */

import React, { useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap";
import {
  createDealNotes,
  createDealTask,
  sendEmailDeals,
} from "../_redux/deals/dealsCrud";
import {
  fetchDealEmailList,
  fetchDealNotesList,
  fetchDealTasksList,
  fetchDealfeedList,
} from "../_redux/deals/dealsAction";

import { Calls } from "./tabs/Calls";
import { Emails } from "./tabs/Emails";
import { Feeds } from "../../../../_metronic/_partials/widgets/lists/Feeds";
import { NotesWidget } from "./tabs/notes/NotesWidget";
import { SendEmail } from "../canvas/SendEmail";
import { Sms } from "./tabs/Sms";
import SpinnerLoader from "../../../../_metronic/_partials/widgets/spinner";
import { Tasks } from "./tabs/tasks/Tasks.js";
// import { WebActivityWidget } from "./tabs/WebActivityWidget";
import { fetchDealSmsList } from "../_redux/deals/dealsAction";
import { initIds } from "../../../../_metronic/_partials/controls/RightPanel";
import { sendSmsDeals } from "../_redux/deals/dealsCrud";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useIntl } from "react-intl";
import { fetchContactCall } from "../_redux/contact/contactAction";
import { fetchCustomerOrderList } from "../../Customer/_redux/customer/customerAction";
import { CustomerOrderWidget } from "../../Customer/components/CustomerOrderWidget";

export function DealsTabs({
  feedList,
  dealDetail,
  notesList,
  agentList,
  tasksList,
  emailsList,
  smsList,
  activeTab,
  setActiveTab,
  revertEmail,
  callList,
  permission,
  apiCallDeal,
  apiCall,
}) {
  const dispatch = useDispatch();
  const intl = useIntl();

  const { orderList, loading } = useSelector(
    (state) => ({
      orderList: state.customer.order.list,
      loading: state.customer.order.loading,
    }),
    shallowEqual
  );
  const init = ["kt_SendEmail_panel"];

  useEffect(() => {
    initIds(init);
  }, [init]);

  const handleTabs = (k) => {
    setActiveTab(k);
    if (k === "feeds") {
      dispatch(fetchDealfeedList(dealDetail.contact_id, dealDetail.id));
    } else if (k === "notes") {
      dispatch(fetchDealNotesList(apiCall, apiCallDeal));
    } else if (k === "tasks") {
      dispatch(fetchDealTasksList(apiCall, apiCallDeal));
    } else if (k === "emails") {
      dispatch(fetchDealEmailList(apiCall, apiCallDeal));
    } else if (k === "sms") {
      dispatch(fetchDealSmsList(apiCall, apiCallDeal));
    } else if (k === "orders") {
      dispatch(fetchCustomerOrderList(dealDetail?.contact_data?.user_id));
    } else if (k === "calls") {
      if (
        dealDetail &&
        dealDetail.contact_data &&
        dealDetail.contact_data.phone &&
        dealDetail.contact_data.phone.length &&
        dealDetail.contact_data.phone[0]
      ) {
        dispatch(fetchContactCall(dealDetail.contact_data.phone[0]));
      }
    }
  };

  return (
    <>
      <div className="card">
        <div className="card-body detailCard deals-tabs">
          <Tabs
            activeKey={activeTab}
            defaultActiveKey="feeds"
            id="uncontrolled-tab-example"
            onSelect={(k) => handleTabs(k)}
          >
            <Tab
              eventKey="feeds"
              title={
                <span>
                  <img
                    src="/media/svg/icons/General/Notifications1.svg"
                    alt="Feeds"
                  />{" "}
                  {intl.formatMessage({ id: "Timeline" })}{" "}
                </span>
              }
            >
              <Feeds className="card-stretch deals-feed" data={feedList} />
            </Tab>
            <Tab
              style={{
                height: "490px",
                overflowY: "auto",
                scrollbarWidth: "thin",
              }}
              className="scrollThin"
              eventKey="notes"
              title={
                <span>
                  {" "}
                  <img
                    src="/media/svg/icons/Files/File-plus.svg"
                    alt="Notes"
                  />{" "}
                  {intl.formatMessage({ id: "Notes" })}
                </span>
              }
            >
              {notesList.loading ? (
                <SpinnerLoader />
              ) : (
                <NotesWidget
                  ID={dealDetail?.contact_id}
                  dealId={dealDetail?.id}
                  notesList={notesList?.entities}
                  type={"Deal"}
                  submitFunction={createDealNotes}
                  listFunction={fetchDealNotesList}
                  activeTab={activeTab}
                  permission={permission?.deal?.edit}
                />
              )}
            </Tab>
            <Tab
              style={{
                height: "490px",
                overflowY: "auto",
                scrollbarWidth: "thin",
              }}
              eventKey="tasks"
              title={
                <span>
                  <img
                    src="/media/svg/icons/Communication/Clipboard-list.svg"
                    alt="Tasks"
                  />{" "}
                  {intl.formatMessage({ id: "TASKS" })}{" "}
                </span>
              }
            >
              {tasksList.loading ? (
                <SpinnerLoader />
              ) : (
                <Tasks
                  type="Deal"
                  ownerList={agentList}
                  ID={dealDetail?.contact_id}
                  dealId={dealDetail?.id}
                  tasksList={tasksList?.entities}
                  submitFunction={createDealTask}
                  listFunction={fetchDealTasksList}
                  activeTab={activeTab}
                  permission={permission?.deal?.edit}
                />
              )}
            </Tab>
            <Tab
              style={{
                height: "490px",
                overflowY: "auto",
                scrollbarWidth: "thin",
              }}
              eventKey="calls"
              title={
                <span>
                  {" "}
                  <img
                    src="/media/svg/icons/Communication/Active-call.svg"
                    alt="Calls"
                  />
                  {intl.formatMessage({ id: "Calls" })}{" "}
                </span>
              }
            >
              <Calls
                callList={callList}
                detailData={dealDetail}
                permission={permission?.deal?.edit}
              />
            </Tab>
            <Tab
              style={{
                height: "490px",
                overflowY: "auto",
                scrollbarWidth: "thin",
              }}
              eventKey="emails"
              title={
                <span>
                  {" "}
                  <img
                    src="/media/svg/icons/Communication/Mail.svg"
                    alt="Emails"
                  />{" "}
                  {intl.formatMessage({ id: "Emails" })}{" "}
                </span>
              }
            >
              <Emails
                emailList={emailsList}
                revertEmail={revertEmail}
                permission={permission?.deal?.edit}
              />
            </Tab>
            <Tab
              style={{
                height: "490px",
                overflowY: "auto",
                scrollbarWidth: "thin",
              }}
              eventKey="sms"
              title={
                <span>
                  {" "}
                  <img
                    src="/media/svg/icons/Communication/Chat5.svg"
                    alt="SMS"
                  />{" "}
                  {intl.formatMessage({ id: "SMS" })}{" "}
                </span>
              }
            >
              {emailsList.loading ? (
                <SpinnerLoader />
              ) : (
                <Sms
                  ID={dealDetail?.contact_id}
                  dealId={dealDetail?.id}
                  smsList={smsList?.entities}
                  type="Deal"
                  submitFunction={sendSmsDeals}
                  listFunction={fetchDealSmsList}
                  detailData={dealDetail}
                  activeTab={activeTab}
                  permission={permission?.deal?.edit}
                />
              )}
            </Tab>
            {/* <Tab
              eventKey="activity"
              title={
                <span>
                  {" "}
                  <img
                    src="/media/svg/icons/Devices/Laptop.svg"
                    alt="Activity"
                  />{" "}
                  {intl.formatMessage({ id: "Activity" })}{" "}
                </span>
              }
            >
              <WebActivityWidget />
            </Tab> */}
            <Tab
              style={{
                height: "490px",
                overflowY: "auto",
                scrollbarWidth: "thin ",
              }}
              eventKey="orders"
              title={
                <span>
                  {" "}
                  <img
                    src="/media/svg/icons/Layout/Layout-left-panel-2.svg"
                    alt="Activity"
                  />{" "}
                  Orders
                </span>
              }
            >
              {/* <div style={{ overflow: "scroll", height: "80vh" }}> */}
              <CustomerOrderWidget
                orderList={orderList && orderList.length > 0 ? orderList : ""}
                loading={loading}
              />
              {/* </div> */}
            </Tab>
          </Tabs>
        </div>
      </div>
      <SendEmail
        type="Deal"
        ID={dealDetail?.contact_id}
        dealId={dealDetail?.id}
        submitFunction={sendEmailDeals}
        listFunction={fetchDealEmailList}
        detailData={dealDetail}
      />
    </>
  );
}
