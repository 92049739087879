/* eslint-disable eqeqeq */
import * as Yup from "yup";
// const phoneRegExp = /^\+[1-9]{1}[0-9]{3,14}$/;

export const NewUserSchema = (intl, yp) => {
  return Yup.object().shape({
    first_name: Yup.string()
      .matches(/^[aA-zZ\s]+$/, intl.formatMessage({ id: "Only.alphabets" }))
      .trim()
      .required(intl.formatMessage({ id: "First.Name.Required" })),
    last_name: Yup.string()
      .matches(/^[aA-zZ\s]+$/, intl.formatMessage({ id: "Only.alphabets" }))
      .trim()
      .required(intl.formatMessage({ id: "Last.Name.Required" })),
    username: Yup.string()
      .matches(/^[aA-zZ\s]+$/, intl.formatMessage({ id: "Only.alphabets" }))
      .trim()
      .required(intl.formatMessage({ id: "User.Name.Required" })),
    password: Yup.string()
      .min(8, intl.formatMessage({ id: "Your password is too short." }))
      .matches(/(?=.*[a-z])/, "Password must contain a lowercase letter")
      .matches(/(?=.*[A-Z])/, "Password must contain an uppercase letter")
      .matches(/(?=.*[0-9])/, "Password must contain a number")
      .matches(/(?=.*[!@#$%^&*])/, "Password must contain a symbol")
      .required(intl.formatMessage({ id: "Please.enter.password" })),
    email: Yup.string()
      .email(intl.formatMessage({ id: "Wrong.email.format" }))
      .min(3, intl.formatMessage({ id: "Minimum.3.symbols" }))
      .max(50, intl.formatMessage({ id: "Maximum.50.symbols" })),
    phone: Yup.string()
      // .matches(phoneRegExp, intl.formatMessage({ id: "Country.Code.valid" }))
      // .min(12, intl.formatMessage({ id: "Country.Code.valid" }))
      // .required(intl.formatMessage({ id: "Phone.Number.Required" }))
      .required(intl.formatMessage({ id: "Phone.Number.Required" })),

    franchise_id: Yup.string().when("profile_id", {
      is: (profile_id) => profile_id !== "1" && profile_id !== "5",
      then: Yup.string().required(
        intl.formatMessage({ id: "Franchise.Required" })
      ),
      otherwise: Yup.string(),
    }),
    country_id: Yup.string().when("profile_id", {
      is: (profile_id) => profile_id != "1",
      then: Yup.string().required(
        intl.formatMessage({ id: "CountryRequired" })
      ),
      otherwise: Yup.string(),
    }),
    profile_id: Yup.string().required(
      intl.formatMessage({ id: "Profile.Required" })
    ),
  });
};
export const EditUserSchema = (intl, yp) => {
  return Yup.object().shape({
    first_name: Yup.string()
      .matches(/^[aA-zZ\s]+$/, intl.formatMessage({ id: "Only.alphabets" }))
      .trim()
      .required(intl.formatMessage({ id: "First.Name.Required" })),
    last_name: Yup.string()
      .matches(/^[aA-zZ\s]+$/, intl.formatMessage({ id: "Only.alphabets" }))
      .trim()
      .required(intl.formatMessage({ id: "Last.Name.Required" })),
    username: Yup.string()
      .matches(/^[aA-zZ\s]+$/, intl.formatMessage({ id: "Only.alphabets" }))
      .trim()
      .required(intl.formatMessage({ id: "User.Name.Required" })),
    password: Yup.string()
      .min(8, intl.formatMessage({ id: "Your password is too short." }))
      .matches(/(?=.*[a-z])/, "Password must contain a lowercase letter")
      .matches(/(?=.*[A-Z])/, "Password must contain an uppercase letter")
      .matches(/(?=.*[0-9])/, "Password must contain a number")
      .matches(/(?=.*[!@#$%^&*])/, "Password must contain a symbol"),
    // .required(intl.formatMessage({ id: "Please.enter.password" })),
    email: Yup.string()
      .email(intl.formatMessage({ id: "Wrong.email.format" }))
      .min(3, intl.formatMessage({ id: "Minimum.3.symbols" }))
      .max(50, intl.formatMessage({ id: "Maximum.50.symbols" })),
    phone: Yup.string()
      // .matches(phoneRegExp, intl.formatMessage({ id: "Country.Code.valid" }))
      // .min(12, intl.formatMessage({ id: "Country.Code.valid" }))
      // .required(intl.formatMessage({ id: "Phone.Number.Required" }))
      .required(intl.formatMessage({ id: "Phone.Number.Required" })),

    profile_id: Yup.string().required(
      intl.formatMessage({ id: "Profile.Required" })
    ),
  });
};

export const NewProfileSchema = (intl) => {
  return Yup.object().shape({
    name: Yup.string()
      .matches(/^[aA-zZ\s]+$/, intl.formatMessage({ id: "Only.alphabets" }))
      .trim()
      .required(intl.formatMessage({ id: "Product.Required" })),
    description: Yup.string()
      .matches(/^[aA-zZ\s]+$/, intl.formatMessage({ id: "Only.alphabets" }))
      .trim()
      .required(intl.formatMessage({ id: "Product.Description.Required" })),
    parent_id: Yup.string().required(
      intl.formatMessage({ id: "Parent.Profile.Required" })
    ),
    permission: Yup.object().test(
      "123",
      intl.formatMessage({ id: "Please.Select.Permission" }),
      function (value) {
        // your logic

        if (Object.keys(value)?.length > 0) {
          for (const items of Object.keys(value)) {
            if (
              value[items].create ||
              value[items].view ||
              value[items].edit ||
              value[items].delete
            ) {
              return "123";
            }
          }
        } else {
          return false;
        }
      }
    ),
  });
};
