/* eslint-disable array-callback-return */
import { Modal } from "react-bootstrap";
import React, { useEffect, useState } from "react";
// import { FormControl, MenuItem, Select } from "@material-ui/core";
// import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { fetchFranchisesDepot } from "../../Settings/_redux/franchises/franchisesActions";
import { emailPayment, requestPayment } from "../_redux/order/orderCrud";
import SnackBarTool from "../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { LoadingCustome } from "../../../../_metronic/_partials/controls/LoadingCustome";
// import {
//   fetchMyOrderDetailCard,
//   fetchMyOrderTimeline,
// } from "../_redux/order/orderAction";
const EmailCustomerModal = ({
    header,
    mainText,
    dialog,
    setDialog,
    handleAction,
    children,
    orderDetail,
}) => {
    const dispatch = useDispatch();
    const [snack, setSnack] = useState({ call: false, type: "", message: "" });
    const initialValues = {
        order_id: orderDetail?.id || "",
    };
    const [isLoading, setIsLoading] = useState(false)

    // useEffect(() => {
    //     if (orderDetail?.franchise_id && dialog?.check) {
    //         dispatch(fetchFranchisesDepot(orderDetail?.franchise_id));
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [orderDetail?.franchise_id, dialog?.check]);
    const handleSubmit = (values) => {
        setIsLoading(true)
        setSnack({ call: false, type: "", message: "" });
        emailPayment(values)
            .then((res) => {
                setIsLoading(false)
                // dispatch(fetchMyOrderDetailCard(orderDetail?.id));
                // dispatch(fetchMyOrderTimeline(orderDetail?.id));
                setSnack({ call: true, type: "success", message: res.data.message });
                setTimeout(() => {
                    handleClose();
                }, 3000);
            })
            .catch((error) => {
                setIsLoading(false)
                setSnack({
                    call: true,
                    type: "error",
                    message: error.response.data.message,
                });
            });
    };
    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        // validationSchema: AssignContactSchema(intl, Yup),
        onSubmit: handleSubmit,
    });
    const handleClose = () => {
        setDialog({ check: false });
    };
    return (
        <Modal
            show={dialog.check}
            aria-labelledby="example-modal-sizes-title-lg"
            onHide={() => handleClose()}
        >
            {isLoading && <LoadingCustome />}
            <Modal.Header className="d-flex justify-content-between">
                <Modal.Title id="example-modal-sizes-title-lg">
                    Resend Order Confirmation Email
                </Modal.Title>
                <span
                    class="btn btn-xs btn-icon btn-light btn-hover-primary"
                    onClick={() => handleClose()}
                >
                    <i class="ki ki-close icon-sm text-muted"></i>
                </span>
            </Modal.Header>
            <form onSubmit={formik.handleSubmit}>
                <Modal.Body style={{ marginTop: "-30px" }}>
                    <br />
                    <span className="mt-3 ">It will send an mail to customer</span>
                </Modal.Body>
                <Modal.Footer>
                    <div>
                        <button
                            type="button"
                            onClick={() => handleClose()}
                            className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="btn btn-primary font-weight-bolder font-size-sm "
                        >
                            Ok
                        </button>
                    </div>
                </Modal.Footer>
            </form>
            {snack.call ? <SnackBarTool {...snack} /> : ""}
        </Modal>
    );
};

export default EmailCustomerModal;
