/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

import * as Yup from "yup";

import React, { useState } from "react";

import { Form } from "react-bootstrap";
import SnackBarTool from "../../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { VanSchema } from "../franchise-form/validateSchema";
import { canvaCancel } from "../../../../../utils/canvaCloseFunction";
import { createFranchiseVan } from "../../../_redux/franchises/franchisesCrud";
import { fetchFranchisesVan } from "../../../_redux/franchises/franchisesActions";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { useIntl } from "react-intl";
import { getInputClasses } from "../../../../../utils/commonFunction";

export function AddVan({ detailData }) {
  const intl = useIntl();
  // Use the useDispatch hook to dispatch actions
  const dispatch = useDispatch();

  // Use state hook to manage snackbar state
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });

  // Initial values for the form
  const initialValues = {
    franchise_id: detailData && detailData.id ? detailData.id : "",
    make: "",
    model: "",
    van_name: "",
    registration: "",
    no_of_pods: "",
  };

  // Event handler for form submission
  const handleSubmit = (values, { resetForm }) => {
    setSnack({ call: false, type: "", message: "" });
    createFranchiseVan(values)
      .then((res) => {
        if (res.data.success) {
          dispatch(fetchFranchisesVan(detailData.id));
          setSnack({ call: true, type: "success", message: res.data.message });
          resetForm();
          canvaCancel("kt_addvan_panel_close");
        } else {
          setSnack({ call: true, type: "error", message: res.data.message });
        }
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
          error: error.response.data.errors,
        });
      });
  };

  const handleReset = () => {};

  // Use the useFormik hook to handle form logic
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: VanSchema(intl, Yup),
    onSubmit: handleSubmit,
    onReset: handleReset,
  });

  return (
    <div
      id="kt_addvan_panel"
      className="offcanvas offcanvas-right pt-5 pb-10 offcanvas-rightss"
    >
      {/*begin::Header*/}
      <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
        <div
          className="offcanvas-close mt-n1 pr-5"
          style={{ position: "absolute", top: "15px", right: "10px" }}
        >
          <span
            onClick={() => formik.resetForm()}
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            id="kt_addvan_panel_close"
          >
            <i className="ki ki-close icon-sm text-muted"></i>
          </span>
        </div>
      </div>
      {/*end::Header*/}
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <div className="offcanvas-content px-10 ">
          <div>
            <h5>{intl.formatMessage({ id: "Add.Van" })}</h5>
          </div>
          <div
            id="kt_quick_panel_logs_kt_addvan_panel"
            className="fade pt-3 pr-5 mr-n5 scroll active show ps--active-y "
          >
            <div className="mt-10 pb-10">
              <div className="row">
                <div className="col-md-4">
                  <Form.Group className="mb-5">
                    <Form.Label>
                      {intl.formatMessage({ id: "Make" })}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={intl.formatMessage({ id: "Make" })}
                      maxLength={"30"}
                      className={`form-control  ${getInputClasses(
                        formik,
                        "make"
                      )}`}
                      name="make"
                      {...formik.getFieldProps("make")}
                    />
                    {formik.touched.make && formik.errors.make ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.make}
                        </div>
                      </div>
                    ) : null}
                  </Form.Group>
                </div>
                <div className="col-md-4">
                  <Form.Group className="mb-5">
                    <Form.Label>
                      {intl.formatMessage({ id: "Model" })}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={intl.formatMessage({ id: "Model" })}
                      maxLength={"30"}
                      className={`form-control  ${getInputClasses(
                        formik,
                        "model"
                      )}`}
                      name="model"
                      {...formik.getFieldProps("model")}
                    />
                    {formik.touched.model && formik.errors.model ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.model}
                        </div>
                      </div>
                    ) : null}
                  </Form.Group>
                </div>
                <div className="col-md-4">
                  <Form.Group className="mb-5">
                    <Form.Label>
                      {intl.formatMessage({ id: "Van.Name" })}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={intl.formatMessage({ id: "Van.Name" })}
                      maxLength={"30"}
                      className={`form-control  ${getInputClasses(
                        formik,
                        "van_name"
                      )}`}
                      name="van_name"
                      {...formik.getFieldProps("van_name")}
                    />
                    {formik.touched.van_name && formik.errors.van_name ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.van_name}
                        </div>
                      </div>
                    ) : null}
                  </Form.Group>
                </div>
                <div className="col-md-4">
                  <Form.Group className="mb-5">
                    <Form.Label>
                      {intl.formatMessage({ id: "Registration" })}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={intl.formatMessage({ id: "Registration" })}
                      maxLength={"30"}
                      className={`form-control  ${getInputClasses(
                        formik,
                        "registration"
                      )}`}
                      name="registration"
                      {...formik.getFieldProps("registration")}
                    />
                    {formik.touched.registration &&
                    formik.errors.registration ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.registration}
                        </div>
                      </div>
                    ) : null}
                  </Form.Group>
                </div>
                <div className="col-md-4">
                  <Form.Group className="mb-5">
                    <Form.Label>
                      {intl.formatMessage({ id: "#.of.Pods" })}
                    </Form.Label>
                    <Form.Control
                      type="string"
                      placeholder={intl.formatMessage({ id: "#.of.Pods" })}
                      maxLength={"30"}
                      className={`form-control  ${getInputClasses(
                        formik,
                        "no_of_pods"
                      )}`}
                      name="no_of_pods"
                      {...formik.getFieldProps("no_of_pods")}
                    />
                    {formik.touched.no_of_pods && formik.errors.no_of_pods ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.no_of_pods}
                        </div>
                      </div>
                    ) : null}
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="offcanvas-footer">
          <div className="offcanvas-content text-right ">
            <button
              type="reset"
              className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
              onClick={() => canvaCancel("kt_addvan_panel_close")}
            >
              {intl.formatMessage({ id: "CANCEL" })}
            </button>
            <button
              type="submit"
              className="btn btn-primary font-weight-bolder font-size-sm "
            >
              {intl.formatMessage({ id: "SAVE" })}
            </button>
          </div>
        </div>
      </form>
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </div>
  );
}
