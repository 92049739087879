import React from "react";
import { Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import { CircularProgress } from "@material-ui/core";
import {
  convertGBPtoPoundSymbol,
  isDateWithinNext3Days,
  isWithinTwoWorkingDaysFromToday,
} from "../../../../app/utils/commonFunction";
import { useEffect } from "react";
import { cancellationPrice, getCollectionExtraCharge, orderAccessCharge } from "../../../../app/modules/Order/_redux/order/orderCrud";
import { useState } from "react";

const CustomeOrderSaveDialog = ({
  header,
  mainText,
  dialog,
  setDialog,
  handleAction,
  type,
  formik,
  dialogCondition,
  isLoading,
  extraData,
  returnData,
  loadingDialog,
  check,
  showCheck,
  dialogConditionCheck,
  orderDetail,
  dis,
  franchiseId,
  accessAmount,
  setAccess,
  callPage
}) => {
  console.log("showCheck", showCheck, check, formik);
  const intl = useIntl();
  const [price, setPrice] = useState(null);
  const [collectionExtraPrice, setCollectionExtraPrice] = useState(null)


  useEffect(() => {
    if (
      orderDetail?.return_date &&
      isDateWithinNext3Days(orderDetail?.return_date)
    ) {
      cancellationPrice().then((res) => setPrice(res.data.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDetail?.return_date]);
  useEffect(() => {
    if (showCheck === "access") {
      orderAccessCharge(franchiseId).then((res) => {
        setAccess(res?.data?.data)
      })
    }
  }, [showCheck]);
  useEffect(() => {
    if (showCheck == "collection" && check == "order") {
      const data = {
        order_id: orderDetail?.id || "",
        type: "collection",
        collection_date: formik?.values?.collection_date || "",
      }
      getCollectionExtraCharge(data).then((res) => {
        setCollectionExtraPrice(res.data.data)
        formik.setFieldValue("extra_days_charge", res.data.data?.price)
        formik.setFieldValue("extra_days_type", res.data.data?.type)
      })
    }
  }, [formik?.values?.collection_date])
  console.log("collectionExtraPrice", collectionExtraPrice);
  return (
    <Modal show={dialog.type} aria-labelledby="example-modal-sizes-title-lg">
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{header}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>{mainText}</span>
        {showCheck == "collection" && check == "order" && collectionExtraPrice?.price && formik?.values?.is_student != 1 ? (
          <li>
            {`Extra Collection ${collectionExtraPrice?.type}: £` + collectionExtraPrice?.price}{" "}
          </li>
        ) : (
          ""
        )}
        {extraData && (
          <div>
            {" "}
            {/* {intl.formatMessage({ id: "Do.you.changes.booking" })} */}
            Charges applied
            {loadingDialog ? (
              <div className="text-center mt-8">
                <CircularProgress />
              </div>
            ) : (
              <ul>
                {" "}

                {returnData?.extra_return_price ? (
                  <li>
                    {"Extra Return Price: " + returnData?.extra_return_price}{" "}
                  </li>
                ) : (
                  ""
                )}{" "}
                {returnData?.pod_price ? (
                  <li> {"Pod Price: " + returnData?.pod_price} </li>
                ) : (
                  " "
                )}
                {returnData?.toll_charge ? (
                  <li> {"Toll Charge: " + returnData?.toll_charge} </li>
                ) : (
                  " "
                )}
                {returnData?.extraMiles?.id ? (
                  <li>
                    {" "}
                    {"Extra Miles Price: " +
                      returnData?.extraMiles?.totalPrice}{" "}
                  </li>
                ) : (
                  " "
                )}
                <li>
                  Total Price:
                  {(+returnData?.extra_return_price || 0) +
                    (+returnData?.pod_price || 0) +
                    (+returnData?.toll_charge || 0) +
                    (+returnData?.extraMiles?.totalPrice || 0)}
                </li>
              </ul>
            )}
          </div>
        )}
        {check === "order" &&
          showCheck === "return" &&
          dialog?.cancel &&
          isDateWithinNext3Days(orderDetail?.return_date) && (
            <div className="mt-2">
              {/* <label className="checkbox">
                <input
                  type="checkbox"
                  name="is_cancel_charge"
                  //   checked={
                  //     formik.values.enabled_in_portal === "true" ||
                  //     formik.values.enabled_in_portal === true
                  //       ? true
                  //       : false
                  //   }
                  onChange={(e) =>
                    setDialog({
                      ...dialog,
                      is_cancel_charge: e.target.checked ? 1 : "0",
                    })
                  }
                />
                <span className="mr-2"></span>
                Waive Off Cancellation Charge{" "}
                
              </label> */}
              <div className="mt-2">
                <label>Would you like to waive off the cancellation fee {orderDetail?.currency
                  ? convertGBPtoPoundSymbol(orderDetail?.currency)
                  : "-"}
                  {price?.collection_charge}</label>
                <div className="mt-2 d-flex">
                  <div className="mr-6">
                    <label className="checkbox ">
                      <input
                        type="checkbox"
                        name="is_cancel_charge"
                        //   checked={
                        //     formik.values.enabled_in_portal === "true" ||
                        //     formik.values.enabled_in_portal === true
                        //       ? true
                        //       : false
                        //   }
                        // checked={formik.values.is_cancel_charge === "0"}
                        onChange={(e) =>
                          setDialog({
                            ...dialog,
                            is_cancel_charge: "0",
                          })
                        }
                      />
                      <span className="mr-2"></span>
                      Yes
                      {/* Charge{" "}
                    {orderDetail?.currency
                      ? convertGBPtoPoundSymbol(orderDetail?.currency)
                      : "-"}
                    {price?.collection_charge} */}
                    </label>
                    <div className="mt-2">Do not charge cancellation fee</div>
                  </div>
                  <div className="ml-6">
                    <label className="checkbox ">
                      <input
                        type="checkbox"
                        name="is_cancel_charge"
                        //   checked={
                        //     formik.values.enabled_in_portal === "true" ||
                        //     formik.values.enabled_in_portal === true
                        //       ? true
                        //       : false
                        //   }
                        // checked={formik.values.is_cancel_charge === 1}
                        onChange={(e) =>
                          setDialog({
                            ...dialog,
                            is_cancel_charge: 1,
                          })
                        }
                      />
                      <span className="mr-2"></span>
                      No
                      {/* Charge{" "}
                    {orderDetail?.currency
                      ? convertGBPtoPoundSymbol(orderDetail?.currency)
                      : "-"}
                    {price?.collection_charge} */}
                    </label>
                    <div className="mt-2">Charge cancellation fee</div>
                  </div>
                </div>
              </div>
            </div>
          )}
        {check === "order" && showCheck !== "access" && dialogConditionCheck && (
          <div className="mt-2">
            <label className="checkbox">
              <input
                type="checkbox"
                name="enabled_in_portal"
                //   checked={
                //     formik.values.enabled_in_portal === "true" ||
                //     formik.values.enabled_in_portal === true
                //       ? true
                //       : false
                //   }
                onChange={(e) =>
                  formik.setFieldValue(
                    "mail_to_customer",
                    e.target.checked ? 1 : "0"
                  )
                }
              />
              <span className="mr-2"></span>
              Send email to customer
            </label>
          </div>
        )}
        <br />

        {isWithinTwoWorkingDaysFromToday(orderDetail?.access_date, new Date(formik?.values?.access_date)) && callPage === "collectionEdit" &&
          <>
            {showCheck === "access" && !dialogCondition && orderDetail?.order_details?.pod_plan_type === "Economy" && (!orderDetail?.access_date || orderDetail?.is_access_cancel == 1) && `Access Charge: ${accessAmount?.amount}`}
            <br />
            {showCheck === "access" && !dialogCondition && orderDetail?.order_details?.pod_plan_type === "Economy" && "You can upgrade your plan to Premium plan to skip Access Charge"}

          </>
        }
        {showCheck === "access" && !dialogCondition && callPage !== "collectionEdit" && orderDetail?.order_details?.pod_plan_type === "Economy" && `Access Charge: ${accessAmount?.amount}`}
        <br />
        {showCheck === "access" && !dialogCondition && callPage !== "collectionEdit" && orderDetail?.order_details?.pod_plan_type === "Economy" && "You can upgrade your plan to Premium plan to skip Access Charge"}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={() => setDialog({ type: false, check: "", id: "" })}
            className="btn btn-light btn-elevate"
            disabled={isLoading ? isLoading : false}
          >
            {intl.formatMessage({ id: "No" })}
          </button>
          <> </>
          <button
            type="button"
            onClick={() => {
              if (type === "order" && !dialogCondition) {
                formik.handleSubmit();
                if (dialog?.returnWizard) {
                  setDialog({ type: false, returnWizard: true });
                } else {
                  setDialog({ type: false });
                }
                return;
              } else if (type === "overwrite") {
                handleAction("overwrite");
                return;
              }
              handleAction(dialog?.check, dialog?.id, accessAmount?.amount);
            }}
            // onDoubleClick={() => ""}
            className="btn btn-delete btn-elevate"
            disabled={
              dis === "no"
                ? false
                : isLoading
                  ? isLoading
                  : false || formik?.isSubmitting
                    ? formik?.isSubmitting
                    : false
            }
          >
            {intl.formatMessage({ id: "Yes" })}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default CustomeOrderSaveDialog;
