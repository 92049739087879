/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */

import React, { useCallback, useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import {
  agentList,
  createContactNotes,
  createContactTask,
  createSms,
  sendEmailContact,
} from "../_redux/contact/contactCrud";
import {
  fetchContactCall,
  fetchContactEmailList,
  fetchContactNotesList,
  fetchContactSmsList,
  fetchContactTasksList,
  fetchContactfeedList,
} from "../_redux/contact/contactAction";

import { Calls } from "./tabs/Calls";
import { Emails } from "./tabs/Emails";
import { Feeds } from "../../../../_metronic/_partials/widgets/lists/Feeds";
import { Card, CardBody } from "../../../../_metronic/_partials/controls";

import { NotesWidget } from "./tabs/notes/NotesWidget";
import { SendEmail } from "../canvas/SendEmail";
import { Sms } from "./tabs/Sms";
import SpinnerLoader from "../../../../_metronic/_partials/widgets/spinner";
import { Tasks } from "./tabs/tasks/Tasks";
// import { WebActivityWidget } from "./tabs/WebActivityWidget";
import { initIds } from "../../../../_metronic/_partials/controls/RightPanel";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useIntl } from "react-intl";
import { fetchCustomerOrderList } from "../../Customer/_redux/customer/customerAction";
import { CustomerOrderWidget } from "../../Customer/components/CustomerOrderWidget";
import { fetchContactQuotes, fetchQuotes } from "../_redux/deals/dealsAction";
import NoRecord from "../../../../_metronic/_partials/widgets/NoRecord";
import DealsTable from "../Table/DealTable";
import { useDealsUIContext } from "../DealsUIContext";
export function ContactTabs({
  ID,
  notesList,
  tasksList,
  emailList,
  smsList,
  feedList,
  activeTab,
  setActiveTab,
  callList,
  detailData,
  revertEmail,
  permission,
  callId,
}) {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { orderList, loading, quotesList } = useSelector(
    (state) => ({
      orderList: state.customer.order.list,
      loading: state.customer.order.loading,
      quotesList: state.deals_list.quotesList,
    }),
    shallowEqual
  );
  const dealsUIContext = useDealsUIContext();

  const init = ["kt_SendEmail_panel"];

  useEffect(() => {
    initIds(init);
  }, [init]);

  const [ownerList, setOwnerList] = useState([]);

  const handleOwner = useCallback(() => {
    agentList(ID)
      .then((res) => {
        setOwnerList(res.data.data);
      })
      .catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleOwner();
  }, [handleOwner]);

  const handleTabs = (tab) => {
    setActiveTab(tab);
    if (tab === "feeds") {
      dispatch(fetchContactfeedList(ID));
    } else if (tab === "notes") {
      dispatch(fetchContactNotesList(callId));
    } else if (tab === "tasks") {
      dispatch(fetchContactTasksList(callId));
    } else if (tab === "emails") {
      dispatch(fetchContactEmailList(callId));
    } else if (tab === "sms") {
      dispatch(fetchContactSmsList(callId));
    } else if (tab === "orders") {
      dispatch(fetchCustomerOrderList(detailData?.user_id));
    } else if (tab === "calls") {
      if (detailData?.user_data?.phone) {
        dispatch(fetchContactCall(detailData?.user_data?.phone));
      }
    } else if (tab === "activity") {
      // console.log("dealsUIContext", dealsUIContext?.queryParams);
      dispatch(fetchContactQuotes(ID, dealsUIContext?.queryParams));
    }
  };
  useEffect(() => {
    dispatch(fetchContactQuotes(ID, dealsUIContext?.queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealsUIContext?.queryParams]);

  return (
    <>
      <div className="card card-custom  card-stretch ">
        <div className="card-body deals-tabs detailCard">
          <Tabs
            activeKey={activeTab}
            onSelect={(tab) => handleTabs(tab)}
            id="uncontrolled-tab-example"
          >
            <Tab
              eventKey="feeds"
              title={
                <span>
                  <img
                    src="/media/svg/icons/General/Notifications1.svg"
                    alt="Feeds"
                  />{" "}
                  {intl.formatMessage({ id: "Timeline" })}{" "}
                </span>
              }
            >
              <Feeds
                className="card-stretch deals-feed"
                data={feedList}
                type="contact"
              />
            </Tab>
            <Tab
              style={{
                height: "490px",
                overflowY: "auto",
                scrollbarWidth: "thin ",
              }}
              eventKey="notes"
              title={
                <span>
                  {" "}
                  <img
                    src="/media/svg/icons/Files/File-plus.svg"
                    alt="Notes"
                  />{" "}
                  {intl.formatMessage({ id: "Notes" })}
                </span>
              }
            >
              {notesList.loading ? (
                <SpinnerLoader />
              ) : (
                <NotesWidget
                  ID={ID}
                  type={"contact"}
                  notesList={notesList.entities}
                  submitFunction={createContactNotes}
                  listFunction={fetchContactNotesList}
                  activeTab={activeTab}
                  permission={permission?.contact?.edit}
                />
              )}
            </Tab>
            <Tab
              style={{
                height: "490px",
                overflowY: "auto",
                scrollbarWidth: "thin ",
              }}
              eventKey="tasks"
              title={
                <span>
                  <img
                    src="/media/svg/icons/Communication/Clipboard-list.svg"
                    alt="Tasks"
                  />{" "}
                  {intl.formatMessage({ id: "TASKS" })}{" "}
                </span>
              }
            >
              {tasksList.loading ? (
                <SpinnerLoader />
              ) : (
                <Tasks
                  ownerList={ownerList}
                  ID={ID}
                  tasksList={tasksList.entities}
                  submitFunction={createContactTask}
                  listFunction={fetchContactTasksList}
                  activeTab={activeTab}
                  permission={permission?.contact?.edit}
                />
              )}
            </Tab>
            {/* <Tab
              style={{
                height: "490px",
                overflowY: "auto",
                scrollbarWidth: "thin ",
              }}
              eventKey="calls"
              title={
                <span>
                  {" "}
                  <img
                    src="/media/svg/icons/Communication/Active-call.svg"
                    alt="Calls"
                  />
                  {intl.formatMessage({ id: "Calls" })}{" "}
                </span>
              }
            >
              <Calls callList={callList} detailData={detailData} />
            </Tab> */}
            {/* <Tab
              style={{
                height: "490px",
                overflowY: "auto",
                scrollbarWidth: "thin ",
              }}
              eventKey="emails"
              title={
                <span>
                  {" "}
                  <img
                    src="/media/svg/icons/Communication/Mail.svg"
                    alt="Emails"
                  />{" "}
                  {intl.formatMessage({ id: "Emails" })}{" "}
                </span>
              }
            >
              <Emails
                ID={ID}
                emailList={emailList}
                revertEmail={revertEmail}
                permission={permission?.contact?.edit}
              />
            </Tab> */}
            {/* <Tab
              style={{
                height: "490px",
                overflowY: "auto",
                scrollbarWidth: "thin ",
              }}
              eventKey="sms"
              title={
                <span>
                  {" "}
                  <img
                    src="/media/svg/icons/Communication/Chat5.svg"
                    alt="SMS"
                  />{" "}
                  {intl.formatMessage({ id: "SMS" })}{" "}
                </span>
              }
            >
              {smsList.loading ? (
                <SpinnerLoader />
              ) : (
                <Sms
                  ID={ID}
                  smsList={smsList?.entities}
                  detailData={detailData}
                  submitFunction={createSms}
                  listFunction={fetchContactSmsList}
                  activeTab={activeTab}
                  permission={permission?.contact?.edit}
                />
              )}
            </Tab> */}
            <Tab
              eventKey="activity"
              style={{
                height: "490px",
                overflowY: "auto",
                scrollbarWidth: "thin ",
              }}
              title={
                <span>
                  {" "}
                  <img
                    src="/media/svg/icons/Devices/Laptop.svg"
                    alt="Activity"
                  />{" "}
                  Draft/Quote
                </span>
              }
            >
              <div className=" order-2 order-xxl-1 mt-5">
                <Card>
                  <CardBody>
                    <h3 className="card-title align-items-start flex-column">
                      <span className="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary">
                        {intl.formatMessage({ id: "Quotes" })}
                      </span>
                    </h3>
                    {quotesList &&
                    quotesList.entities &&
                    quotesList.entities.length ? (
                      <DealsTable
                        checkbox={true}
                        dealList={quotesList}
                        type="Deal"
                        checkCon="contact"
                        hat= "quoteContact"
                        // isDeal={props?.isDeal || ""}
                      />
                    ) : (
                      <NoRecord />
                    )}
                  </CardBody>
                </Card>
              </div>
            </Tab>
            <Tab
              style={{
                height: "490px",
                overflowY: "auto",
                scrollbarWidth: "thin ",
              }}
              eventKey="orders"
              title={
                <span>
                  {" "}
                  <img
                    src="/media/svg/icons/Layout/Layout-left-panel-2.svg"
                    alt="Activity"
                  />{" "}
                  Orders
                </span>
              }
            >
              {/* <div style={{ overflow: "scroll", height: "80vh" }}> */}
              <CustomerOrderWidget
                orderList={orderList && orderList.length > 0 ? orderList : ""}
                loading={loading}
              />
              {/* </div> */}
            </Tab>
          </Tabs>
        </div>
      </div>
      <SendEmail
        ID={ID}
        detailData={detailData}
        submitFunction={sendEmailContact}
        listFunction={fetchContactEmailList}
      />
    </>
  );
}
