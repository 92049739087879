import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { EditOrderNotes } from "../order-canva/EditOrderNotes";
export function OrderDetailNotes({ orderDetail, title, type }) {
  return (
    <>
      <div className="card card-custom gutter-b customer-duration ">
        <div className="card-header align-items-center border-0 mt-4 mb-0">
          <div className="d-flex justify-content-between w-100">
            <h3 className="card-title align-items-start flex-column">
              <span className="font-weight-bolder text-dark">{title}</span>
            </h3>
            <div>
              {type === "note" ? (
                ""
              ) : (
                <span
                  className="symbol-label ml-2 pointer"
                  id="kt_EditOrderNotes_panel_toggle"
                >
                  <span className="svg-icon svg-icon-sm svg-icon-primary">
                    <SVG
                      title=" "
                      className="h-25 align-self-end"
                      src={toAbsoluteUrl("/media/svg/icons/Design/Edit.svg")}
                    ></SVG>
                  </span>
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="card-body">
          {type === "note"
            ? orderDetail?.mavis_note || "-"
            : orderDetail?.driver_note || "-"}
        </div>
      </div>
      <EditOrderNotes orderDetail={orderDetail} />
    </>
  );
}
