import { Modal } from "react-bootstrap";
import React from "react";

const InvoiceActionModal = ({
  header,
  mainText,
  dialog,
  setDialog,
  handleAction,
  children,
}) => {
  return (
    <Modal
      show={dialog.check}
      size="lg"
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{header}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};

export default InvoiceActionModal;
