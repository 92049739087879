// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html

import * as actions from "../../../_redux/user/userAction";
import * as columnFormatters from "./column-formatters";
import * as uiHelpers from "../UserUIHelpers";

import React, { useEffect, useMemo, useRef } from "react";
import {
  getHandlerTableChange,
  getSelectRow,
  headerSortingClasses,
  sortCaret,
} from "../../../../../../_metronic/_helpers";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import BootstrapTable from "react-bootstrap-table-next";
import NoRecord from "../../../../../../_metronic/_partials/widgets/NoRecord";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { useUserUIContext } from "../UserUIContext";

const OrderTable = () => {
  const intl = useIntl();
  let history = useHistory();
  const userUIContext = useUserUIContext();

  const userUIProps = useMemo(() => {
    return {
      ids: userUIContext?.ids,
      setIds: userUIContext?.setIds,
      queryParams: userUIContext?.queryParams,
      setQueryParams: userUIContext?.setQueryParams,
      openEditOrderPage: userUIContext?.openEditOrderPage,
      openDeleteOrderDialog: userUIContext?.openDeleteOrderDialog,
    };
  }, [userUIContext]);

  const dispatch = useDispatch();

  const { currentState, permission } = useSelector(
    (state) => ({
      currentState: state.userDetail.user.list,
      permission: state.auth.permission,
    }),
    shallowEqual
  );

  const { totalCount, entities, listLoading } = currentState;
  const abortControllerRef = useRef(null);
  useEffect(() => {
    // clear selections list
    // server call by queryParams

    if (abortControllerRef?.current) {
      abortControllerRef.current.abort();
    }
    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    userUIProps.setIds([]);
    dispatch(actions.fetchUser(userUIProps.queryParams, abortController));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userUIProps.queryParams, dispatch]);

  const columns = [
    {
      dataField: "id",
      text: intl.formatMessage({ id: "User.ID" }),
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "first_name",
      text: intl.formatMessage({ id: "User.Name" }),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (e, data) => {
        return (
          <span>
            {data?.first_name}&nbsp;{data?.last_name}
          </span>
        );
      },
    },
    {
      dataField: "roles.name",
      text: intl.formatMessage({ id: "Profile" }),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (e, data) => {
        const capital =
          data?.profile.charAt(0).toUpperCase() + data?.profile.slice(1);
        const name = capital.replace(/-/g, " ");
        return <span>{name ? name : "-"}</span>;
      },
    },

    {
      dataField: "franchises.name",
      text: intl.formatMessage({ id: "FRANCHISES" }),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (e, data) => {
        return <span>{data && data.franchise ? data.franchise : "-"}</span>;
      },
    },
    {
      dataField: "email",
      text: intl.formatMessage({ id: "Email" }),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },

    {
      dataField: "status",
      text: intl.formatMessage({ id: "User.Active" }),
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.StatusColumnFormatter,
    },
  ];

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: userUIProps.queryParams?.pageSize,
    page: userUIProps.queryParams?.pageNumber,
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      history.push(`users/user-details/${row.id}`);
    },
  };

  const selectRow = {
    mode: "checkbox",
    hideSelectAll: true,
    hideSelectColumn: true,
  };

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
              entities={entities}
            >
              <BootstrapTable
                rowEvents={rowEvents}
                wrapperClasses="table-responsive"
                hover
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="id"
                selectRow={
                  permission?.setting_user?.delete
                    ? getSelectRow({
                        entities,
                        ids: userUIProps.ids,
                        setIds: userUIProps.setIds,
                      })
                    : selectRow
                }
                defaultSorted={uiHelpers.defaultSorted}
                data={entities === null ? [] : entities}
                onTableChange={getHandlerTableChange(
                  userUIProps.setQueryParams
                )}
                columns={columns}
                {...paginationTableProps}
              ></BootstrapTable>
              {entities && entities?.length ? "" : <NoRecord />}
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
};

export default OrderTable;
