import React, { useEffect } from "react";

import { NewProfileCanva } from "./user-canva/NewProfile";
import { NewUserCanva } from "./user-canva/NewUser";
import UserCard from "./UserCard";
import { UserUIProvider } from "./UserUIContext";
import { fetchPermission } from "../../../../pages/redux/permission";
import { initIds } from "../../../../../_metronic/_partials/controls/RightPanel";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";

export const UserPage = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const init = ["kt_new_user_panel"];

  useEffect(() => {
    initIds(init);
  }, [init]);

  useEffect(() => {
    dispatch(fetchPermission());
  }, [dispatch]);

  return (
    <>
      <UserUIProvider>
        <UserCard />
        <NewUserCanva title={intl.formatMessage({ id: "New.User" })} />
        <NewProfileCanva title={intl.formatMessage({ id: "New.Profile" })} />
      </UserUIProvider>
    </>
  );
};
