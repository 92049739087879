import { FormControl, MenuItem, Select } from "@material-ui/core";
import { shallowEqual, useSelector } from "react-redux";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { getInputClasses } from "../../../../../utils/commonFunction";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import CountryPhoneNumber from "../../../../../../_metronic/layout/components/CountryPhoneNumber";

const UserForm = ({
  formik,
  active,
  setDialog,
  type,
  available,
  setDialogAva,
  franchiseList,
  handleCountry,
}) => {
  const intl = useIntl();
  const { detailData, profileList } = useSelector(
    (state) => ({
      detailData: state.country.activeCountry,
      profileList: state.userDetail.profile.drop,
    }),
    shallowEqual
  );
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  return (
    <div className="row">
      {type === "edit" ? (
        <>
          <div className="col-12 col-sm-6">
            <div className="form-groupB row">
              <label className="col-4 col-form-label ">
                {intl.formatMessage({ id: "ACTIVE" })}
              </label>
              <div className="col-3 text-right mt-1">
                <span className="switch switch-outline switch-icon switch-primary">
                  <label>
                    <input
                      type="checkbox"
                      checked={active}
                      name="quick_panel_notifications_2"
                      onChange={() => setDialog(true)}
                    />
                    <span></span>
                  </label>
                </span>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <div className="form-groupB row">
              <label className="col-4 col-form-label ">
                {intl.formatMessage({ id: "Available" })}
              </label>
              <div className="col-3 text-right mt-1">
                <span className="switch switch-outline switch-icon switch-primary">
                  <label>
                    <input
                      type="checkbox"
                      // defaultChecked={
                      //   formik.values.is_available === 1 ? true : false
                      // }
                      checked={available}
                      name="quick_panel_notifications_2"
                      onChange={() => setDialogAva(true)}
                    />
                    <span></span>
                  </label>
                </span>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      <div className="col-12 col-sm-6">
        <div className="form-groupB">
          <label>{intl.formatMessage({ id: "First.Name" })}</label>
          <input
            type="text"
            className={`form-control  ${getInputClasses(formik, "first_name")}`}
            placeholder={intl.formatMessage({ id: "First.Name" })}
            name="first_name"
            {...formik.getFieldProps("first_name")}
          />
          {formik.touched.first_name && formik.errors.first_name ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.first_name}</div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="col-12 col-sm-6">
        <div className="form-groupB">
          <label>{intl.formatMessage({ id: "Last.Name" })}</label>
          <input
            type="text"
            className={`form-control  ${getInputClasses(formik, "last_name")}`}
            placeholder={intl.formatMessage({ id: "Last.Name" })}
            name="last_name"
            {...formik.getFieldProps("last_name")}
          />
          {formik.touched.last_name && formik.errors.last_name ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.last_name}</div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="col-12 col-sm-6">
        <div className="form-groupB">
          <label>{intl.formatMessage({ id: "EMAIL" })}</label>
          <input
            type="text"
            className={`form-control  ${getInputClasses(formik, "email")}`}
            placeholder={intl.formatMessage({ id: "EMAIL" })}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="col-12 col-sm-6">
        <div className="form-groupB">
          <label>{intl.formatMessage({ id: "AUTH.INPUT.USERNAME" })}</label>
          <input
            type="text"
            className={`form-control  ${getInputClasses(formik, "username")}`}
            placeholder={intl.formatMessage({ id: "AUTH.INPUT.USERNAME" })}
            name="username"
            autocomplete="new-username"
            {...formik.getFieldProps("username")}
          />
          {formik.touched.username && formik.errors.username ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.username}</div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="col-12 col-sm-6">
        <div className="form-groupB">
          <label>{intl.formatMessage({ id: "AUTH.INPUT.PASSWORD" })}</label>
          <input
            type={showPassword ? "text" : "password"}
            className={`form-control  `}
            placeholder={intl.formatMessage({ id: "AUTH.INPUT.PASSWORD" })}
            name="password"
            autocomplete="new-password"
            {...formik.getFieldProps("password")}
          />
          <SVG
            style={{
              color: "orange",
              position: "absolute",
              right: "6%",
              top:
                formik.touched.password && formik.errors.password
                  ? "35%"
                  : "45%",
            }}
            title=" "
            className="h-22 align-self-end "
            src={toAbsoluteUrl(
              showPassword
                ? "/media/svg/icons/General/Visible.svg"
                : "/media/svg/icons/General/Hidden.svg"
            )}
            onClick={togglePasswordVisibility}
          ></SVG>
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="col-12 col-sm-6">
        <div className="form-groupB">
          <label>{intl.formatMessage({ id: "PHONE" })}</label>
          <CountryPhoneNumber
            countryPhoneValue={formik?.values?.phone}
            formik={formik}
            fieldKey={"phone"}
          />
          {/* <input
            type="text"
            className={`form-control  ${getInputClasses(formik, "phone")}`}
            placeholder={intl.formatMessage({ id: "PHONE" })}
            name="phone"
            value
            {...formik.getFieldProps("phone")}
          /> */}
          {formik.touched.phone && formik.errors.phone ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.phone}</div>
            </div>
          ) : null}
        </div>
      </div>
      {type !== "edit" && (
        <div className="col-12 col-sm-6">
          <div className="form-groupB">
            <label>{intl.formatMessage({ id: "COUNTRY" })}</label>
            <FormControl fullWidth>
              <Select
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                className={`form-control  ${getInputClasses(
                  formik,
                  "country_id"
                )}`}
                IconComponent={(props) => (
                  <i {...props}>
                    <KeyboardArrowDownIcon />
                  </i>
                )}
                inputProps={{ "aria-label": "Without label" }}
                displayEmpty
                name="country_id"
                onChange={handleCountry}
                value={formik.values.country_id}
                disabled={formik?.values?.profile_id === 1 ? true : false}
              >
                <MenuItem value="">
                  {intl.formatMessage({ id: "COUNTRY" })}
                </MenuItem>
                {detailData && detailData?.length ? (
                  detailData.map((data, index) => (
                    <MenuItem value={data.id} key={index}>
                      {data.name}&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                      <span style={{ color: "lightgrey" }}>
                        {data.timezone}
                      </span>
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value=""></MenuItem>
                )}
              </Select>
            </FormControl>
            {formik.touched.country_id && formik.errors.country_id ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.country_id}</div>
              </div>
            ) : null}
          </div>
        </div>
      )}
      <div className="col-12 col-sm-6">
        <div className="form-groupB">
          <label>{intl.formatMessage({ id: "Profile" })}</label>
          <FormControl fullWidth>
            <Select
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              IconComponent={(props) => (
                <i {...props}>
                  <KeyboardArrowDownIcon />
                </i>
              )}
              inputProps={{ "aria-label": "Without label" }}
              displayEmpty
              name="profile_id"
              className={`form-control  ${getInputClasses(
                formik,
                "profile_id"
              )}`}
              {...formik.getFieldProps("profile_id")}
            >
              <MenuItem value="">
                {intl.formatMessage({ id: "Profile" })}
              </MenuItem>
              {profileList && profileList?.length ? (
                profileList.map((data, index) => (
                  <MenuItem value={data.id} key={index}>
                    {data.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value=""></MenuItem>
              )}
            </Select>
          </FormControl>
          {formik.touched.profile_id && formik.errors.profile_id ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.profile_id}</div>
            </div>
          ) : null}
        </div>
      </div>
      {/* {type !== "edit" && ( */}
      <div className="col-12 col-sm-6">
        <div className="form-groupB">
          <label>{intl.formatMessage({ id: "FRANCHISES" })}</label>
          <FormControl fullWidth>
            <Select
              name="franchise_id"
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              className={`form-control  ${getInputClasses(
                formik,
                "franchise_id"
              )}`}
              IconComponent={(props) => (
                <i {...props}>
                  <KeyboardArrowDownIcon />
                </i>
              )}
              inputProps={{ "aria-label": "Without label" }}
              displayEmpty
              {...formik.getFieldProps("franchise_id")}
              disabled={(formik?.values?.profile_id === 1) || (formik?.values?.profile_id === 5)}
            >
              {franchiseList && !!franchiseList?.length ? (
                franchiseList.map((data, index) => (
                  <MenuItem value={data.id} key={index}>
                    {data.name}
                    {" | "}
                    <span className="text-muted">&nbsp;{data.short_name}</span>
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="">
                  {intl.formatMessage({ id: "FRANCHISES" })}
                </MenuItem>
              )}
            </Select>
          </FormControl>
          {formik.touched.franchise_id && formik.errors.franchise_id ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.franchise_id}</div>
            </div>
          ) : null}
        </div>
      </div>
      {/* )} */}
      {type !== "edit" && (
        <>
          <div className="col-12 col-sm-7">
            <div className="form-groupB row">
              <label className="col-4 col-sm-2 col-form-label ">
                {intl.formatMessage({ id: "ACTIVE" })}
              </label>
              <div className="col-8  col-sm-10 text-right mt-1">
                <span className="switch switch-outline switch-icon switch-primary">
                  <label>
                    <input
                      type="checkbox"
                      checked={active}
                      name="quick_panel_notifications_2"
                      onChange={() => setDialog(true)}
                    />
                    <span></span>
                  </label>
                </span>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default UserForm;
