import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import SnackBarTool from "../../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { excemptPrice, requestPayment } from "../../../_redux/order/orderCrud";
import { fetchMyOrderDetailCard } from "../../../_redux/order/orderAction";
import { useDispatch } from "react-redux";

const ExemptDialog = ({
  dialog,
  setDialog,
  orderId
}) => {
    const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const intl = useIntl();
  const dispatch = useDispatch()
  const handleChange = () => {
    setSnack({ call: false, type: "", message: "" });
    excemptPrice({order_id:orderId})
      .then((res) => {
        dispatch(fetchMyOrderDetailCard(orderId));
        // dispatch(fetchMyOrderTimeline(orderDetail?.id));
        setSnack({ call: true, type: "success", message: res.data.message });
        setTimeout(() => {
            setDialog(false)
        }, 3000);
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
  };
  return (
    <Modal show={dialog} aria-labelledby="example-modal-sizes-title-lg">
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">EXEMPT Automated Price Inc</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>Are you sure you want to EXEMPT this order from automatic price increase </span>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={() =>setDialog(false)}
            className="btn btn-light btn-elevate"
          >
            {intl.formatMessage({ id: "No" })}
          </button>
          <> </>
          <button
            type="button"
            onClick={() => handleChange()}
            className="btn btn-delete btn-elevate"
          >
            {intl.formatMessage({ id: "Yes" })}
          </button>
        </div>
      </Modal.Footer>
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </Modal>
  );
};

export default ExemptDialog;
