export const ProductStatusCssClasses = ["success", "info", ""];
export const ProductStatusTitles = ["Selling", "Sold"];
export const ProductConditionCssClasses = ["success", "danger", ""];
export const ProductConditionTitles = ["New", "Used"];
export const defaultSorted = [{ dataField: "id", order: "asc" }];
export const sizePerPageList = [
  { text: "20", value: 20 },
  { text: "50", value: 50 },
  { text: "100", value: 100 },
];
export const initialFilter = {
  filter: {
    agent: "",
    dealStage: "",
    franchise: "",
    view: "",
    searchText: "",
  },
  sortOrder: "", // asc||desc
  sortField: "",
  pageNumber: 1,
  pageSize: 20,
};
export const buttons = [
  {
    name: "Assign Selected",
    id: "kt_assign_panel_toggle",
    className: "btn btn-primary mr-3",
    disabled: true,
  },
  {
    name: "Create Contact",
    id: "kt_quick_panelone_toggle",
    className: "btn btn-primary ",
  },
];
