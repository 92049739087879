import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./InvoiceUIHelpers";

const InvoiceUIContext = createContext();

export function useInvoiceUIContext() {
  return useContext(InvoiceUIContext);
}

export const InvoiceUIConsumer = InvoiceUIContext.Consumer;

export function InvoiceUIProvider({ InvoiceUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([[]]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    openEditProductPage: InvoiceUIEvents?.openEditProductPage,
    openDeleteProductDialog: InvoiceUIEvents?.openDeleteProductDialog,
    openDeleteInvoiceDialog: InvoiceUIEvents?.openDeleteInvoiceDialog,
    openFetchInvoiceDialog: InvoiceUIEvents?.openFetchInvoiceDialog,
    openUpdateInvoiceStatusDialog: InvoiceUIEvents?.openUpdateInvoiceStatusDialog,
  };

  return (
    <InvoiceUIContext.Provider value={value}>{children}</InvoiceUIContext.Provider>
  );
}
