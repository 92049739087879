import React from "react";
// import SVG from "react-inlinesvg";
import moment from "moment";
// import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";

export function EmailWidget({ data, type }) {
  return (
    <>
      <div className=" mt-4 mb-5 p-0 cursor-pointer shadow-xs toggle-off">
        <div className="card-body p-5">
          <div className="dealsemail d-flex ">
            <div className="username">
              {type === "re " ? data?.user : data?.user_data?.username}
            </div>
            <div style={{ width: "50%" }}>
              <span>
                <strong>
                  {type === "re " ? data?.subject : `Sub :  ${data?.subject}`}
                </strong>
                &nbsp;
                <span
                  dangerouslySetInnerHTML={{
                    __html: data?.description,
                  }}
                ></span>
              </span>
            </div>
            <div className="emaildate">
              {moment(data?.created_at).format("DD-MM-YYYY")}
              {/* <span
                className="symbol-label pointer"
                id="kt_SendEmail_panel_toggle"
              >
                <span className="svg-icon svg-icon-lg ">
                  <SVG
                    title=" "
                    className="h-75 align-self-end"
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Reply.svg"
                    )}
                  ></SVG>
                </span>
              </span> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
