/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

import React, { useState } from "react";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import * as Yup from "yup";

import { AssignDealSchema } from "../form/validationSchema";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import SnackBarTool from "../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { assignDealAgent } from "../_redux/deals/dealsCrud";
import { canvaCancel } from "../../../utils/canvaCloseFunction";
import { fetchDeals } from "../_redux/deals/dealsAction";
import { getInputClasses } from "../../../utils/commonFunction";
import { useDealsUIContext } from "../DealsUIContext";
import { useFormik } from "formik";
import { useIntl } from "react-intl";

export function AssignDealsCanvas() {
  const intl = useIntl();

  const DealsUIContext = useDealsUIContext();

  // Get the dispatch function for dispatching redux actions
  const dispatch = useDispatch();

  // Create state to manage snackbar notifications
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });

  // Get the current state of the agent from the store
  const { currentState } = useSelector(
    (state) => ({ currentState: state.contact.agent }),
    shallowEqual
  );

  // Initial values for the form
  const initialValues = {
    contact_owner_id: "",
  };

  // Handle form submission
  const handleSubmit = (values, actions) => {
    const data = {
      contact_owner_id: values.contact_owner_id,
      deal_id: DealsUIContext.ids,
    };
    setSnack({ call: false, type: "", message: "" });
    assignDealAgent(data)
      .then((res) => {
        // On success, update snackbar message and refresh deals list
        setSnack({ call: true, type: "success", message: res.data.message });
        dispatch(fetchDeals(DealsUIContext.queryParams));
        actions.resetForm();
        DealsUIContext.setIds([]);
        formik.resetForm();
        canvaCancel("kt_deals_assign_panel_close");
      })
      .catch((error) => {
        // On error, update snackbar message
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
  };

  // Formik hook for handling form state and validation
  const formik = useFormik({
    initialValues,
    validationSchema: AssignDealSchema(intl, Yup),
    onSubmit: handleSubmit,
  });

  return (
    <div
      id="kt_deals_assign_panel"
      className="offcanvas offcanvas-right pt-5 pb-10"
    >
      {/*begin::Header*/}
      <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
        <div
          className="offcanvas-close mt-n1  pr-5"
          style={{ position: "absolute", top: "15px", right: "10px" }}
        >
          <button
            onClick={() => formik.resetForm()}
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            id="kt_deals_assign_panel_close"
          >
            <i className="ki ki-close icon-xs text-muted"></i>
          </button>
        </div>
      </div>

      {/*end::Header*/}

      {/*begin::Content*/}
      <form onSubmit={formik.handleSubmit}>
        <div className="offcanvas-content px-10">
          <div>
            <h5>{intl.formatMessage({ id: "Assign.Deals" })}</h5>
          </div>
          <div className="tab-content">
            <div
              id="kt_quick_panel_logs_kt_deals_assign_panel"
              role="tabpanel"
              className={`tab-pane fade pt-3 pr-5 mr-n5 scroll  active show `}
            >
              <div className="row">
                <>
                  <div className="col-12">
                    <div className="form-group">
                      <label>
                        {intl.formatMessage({ id: "Assign.selected.Deal" })} :{" "}
                      </label>
                      <FormControl fullWidth>
                        <Select
                          name="contact_owner_id"
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          }}
                          className={`form-control  ${getInputClasses(
                            "contact_owner_id"
                          )}`}
                          IconComponent={(props) => (
                            <i {...props}>
                              <KeyboardArrowDownIcon />
                            </i>
                          )}
                          inputProps={{ "aria-label": "Without label" }}
                          displayEmpty
                          {...formik.getFieldProps("contact_owner_id")}

                          //   onBlur={handleBlur}
                          //   value={values.status}
                        >
                          <MenuItem value="">
                            {intl.formatMessage({ id: "No.owner" })}
                          </MenuItem>
                          {currentState &&
                            currentState.map((data, index) => (
                              <MenuItem key={index} value={data.id}>
                                {data.first_name +
                                  " " +
                                  (data.last_name ? data.last_name : "")}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                      {formik.touched.contact_owner_id &&
                      formik.errors.contact_owner_id ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.contact_owner_id}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </>
              </div>
            </div>
            {/*end::Content*/}
          </div>
        </div>
        <div className="offcanvas-footer">
          <div className="offcanvas-content text-right ">
            <button
              type="reset"
              className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
              onClick={() => canvaCancel("kt_deals_assign_panel_close")}
            >
              {intl.formatMessage({ id: "CANCEL" })}
            </button>
            <button
              type="submit"
              className="btn btn-primary font-weight-bolder font-size-sm "
            >
              {intl.formatMessage({ id: "SAVE" })}
            </button>
          </div>
        </div>
      </form>
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </div>
  );
}
