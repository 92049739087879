/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

import * as Yup from "yup";

import React, { useState } from "react";

import { Form } from "react-bootstrap";
import SnackBarTool from "../../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import {
  ApplicationFeeValSchema,
  VanSchema,
} from "../franchise-form/validateSchema";
import { canvaCancel } from "../../../../../utils/canvaCloseFunction";
import {
  createApplicationFee,
  createFranchiseVan,
} from "../../../_redux/franchises/franchisesCrud";
import {
  fetchFranchisesDetail,
  fetchFranchisesVan,
} from "../../../_redux/franchises/franchisesActions";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { useIntl } from "react-intl";
import { getInputClasses } from "../../../../../utils/commonFunction";

export function ApplicationFee({ detailData, setApplicationCanva }) {
  const intl = useIntl();

  // Use the useDispatch hook to dispatch actions
  const dispatch = useDispatch();

  // Use state hook to manage snackbar state
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });

  // Initial values for the form
  const initialValues = {
    franchise_id: detailData && detailData.id ? detailData.id : "",
    management_fee:
      detailData && detailData.management_fee ? detailData.management_fee : "",
    brand_fee: detailData && detailData.brand_fee ? detailData.brand_fee : "",
    i_s_fee: detailData && detailData.i_s_fee ? detailData.i_s_fee : "",
    stripe_fee:
      detailData && detailData.stripe_fee ? detailData.stripe_fee : "",
  };

  // Event handler for form submission
  const handleSubmit = (values, { resetForm }) => {
    setSnack({ call: false, type: "", message: "" });
    createApplicationFee(values)
      .then((res) => {
        if (res.data.success) {
          dispatch(fetchFranchisesDetail(detailData.id));
          setSnack({ call: true, type: "success", message: res.data.message });
          formik.resetForm();
          setTimeout(() => {
            setApplicationCanva({ open: false });
          }, 3000);
          // console.log("snack", snack);
          // if (!snack.call) {
          //   setApplicationCanva({ open: false });
          // }
        } else {
          setSnack({ call: true, type: "error", message: res.data.message });
        }
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
          error: error.response.data.errors,
        });
      });
  };

  const handleReset = () => {};

  // Use the useFormik hook to handle form logic
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: ApplicationFeeValSchema(intl, Yup),
    onSubmit: handleSubmit,
    onReset: handleReset,
  });


  return (
    <div
      id="kt_Application_panel"
      className="offcanvas offcanvas-right pt-5 pb-10 offcanvas-on"
    >
      {/*begin::Header*/}
      <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
        <div
          className="offcanvas-close mt-n1 pr-5"
          style={{ position: "absolute", top: "15px", right: "10px" }}
        >
          <span
            onClick={() => {
              formik.resetForm();
              setApplicationCanva({ open: false });
            }}
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            id="kt_Application_panel_close"
          >
            <i className="ki ki-close icon-sm text-muted"></i>
          </span>
        </div>
      </div>
      {/*end::Header*/}
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <div className="offcanvas-content px-10 ">
          <div>
            <h5>Application fee</h5>
          </div>
          <div
            id="kt_quick_panel_logs_kt_Application_panel"
            className="fade pt-3 pr-5 mr-n5 scroll active show ps--active-y "
          >
            <div className="mt-10 pb-10">
              <div className="row">
                <div className="col-md-8">
                  <Form.Group className="mb-5">
                    <Form.Label>Management Fee %</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={"Management Fee"}
                      maxLength={"30"}
                      className={`form-control  ${getInputClasses(
                        formik,
                        "management_fee"
                      )}`}
                      name="management_fee"
                      {...formik.getFieldProps("management_fee")}
                    />
                    {formik.touched.management_fee &&
                    formik.errors.management_fee ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.management_fee}
                        </div>
                      </div>
                    ) : null}
                  </Form.Group>
                </div>
                <div className="col-md-8">
                  <Form.Group className="mb-5">
                    <Form.Label>Brand Fee %</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={"Brand Fee"}
                      maxLength={"30"}
                      className={`form-control  ${getInputClasses(
                        formik,
                        "brand_fee"
                      )}`}
                      name="brand_fee"
                      {...formik.getFieldProps("brand_fee")}
                    />
                    {formik.touched.brand_fee && formik.errors.brand_fee ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.brand_fee}
                        </div>
                      </div>
                    ) : null}
                  </Form.Group>
                </div>
                <div className="col-md-8">
                  <Form.Group className="mb-5">
                    <Form.Label>I.S Fee %</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={"I.S Fee"}
                      maxLength={"30"}
                      className={`form-control  ${getInputClasses(
                        formik,
                        "i_s_fee"
                      )}`}
                      name="i_s_fee"
                      {...formik.getFieldProps("i_s_fee")}
                    />
                    {formik.touched.i_s_fee && formik.errors.i_s_fee ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.i_s_fee}
                        </div>
                      </div>
                    ) : null}
                  </Form.Group>
                </div>
                <div className="col-md-8">
                  <Form.Group className="mb-5">
                    <Form.Label>Stripe Fee %</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={"Stripe Fee"}
                      maxLength={"30"}
                      className={`form-control  ${getInputClasses(
                        formik,
                        "stripe_fee"
                      )}`}
                      name="stripe_fee"
                      {...formik.getFieldProps("stripe_fee")}
                    />
                    {formik.touched.stripe_fee && formik.errors.stripe_fee ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.stripe_fee}
                        </div>
                      </div>
                    ) : null}
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="offcanvas-footer">
          <div className="offcanvas-content text-right ">
            <button
              type="reset"
              className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
              onClick={() => setApplicationCanva({ open: false })}
            >
              {intl.formatMessage({ id: "CANCEL" })}
            </button>
            <button
              type="submit"
              className="btn btn-primary font-weight-bolder font-size-sm "
            >
              {intl.formatMessage({ id: "SAVE" })}
            </button>
          </div>
        </div>
      </form>
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </div>
  );
}
