import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { ContainerUIProvider } from "./ContainerUIContext";
import { NewContainerCanva } from "./container-canva/NewContainer";
import { fetchPermission } from "../../../../pages/redux/permission";
import { initIds } from "../../../../../_metronic/_partials/controls/RightPanel";
import { useIntl } from "react-intl";
import ContainerCard from "./CountryCard";

export const ContainerPage = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const init = ["kt_new_Container_panel"];
  const [openCanva, setOpenCanva] = useState(false);
  
  useEffect(() => {
    initIds(init);
  }, [init]);
  const { permission } = useSelector(
    (state) => ({
      permission: state.auth.permission,
    }),
    shallowEqual
  );
  useEffect(() => {
    dispatch(fetchPermission());
  }, [dispatch]);

  return (
    <>
      <ContainerUIProvider>
        <ContainerCard permission={permission} intl={intl} init={init} setOpenCanva={setOpenCanva} />
        {openCanva.check ? (
            <>
              <NewContainerCanva
              openCanva={openCanva}
              setOpenCanva={setOpenCanva}
               />
              <div
                className="offcanvas-overlay"
                onClick={() => setOpenCanva(false)}
              ></div>
            </>
          ) : (
            ""
          )}
        
      </ContainerUIProvider>
    </>
  );
};
