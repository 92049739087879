import axios from "axios";
export const Tasks_URL = "api/tasks";
export const TASKS = "task";
export const COMPLETE = "/mark-completed";
export const DELETE = "/delete-tasks";
export const OWNERS = "/change-task-owner";
export const SEARCH = "/search-contact-deals";
export const CREATE_TASKS = "/add/tasks";

export function getAllProducts() {
  return axios.get(Tasks_URL);
}
export function findTasks(queryParams) {
  // Make an HTTP GET request to the tasks endpoint with query parameters
  return axios.get(
    `${process.env.REACT_APP_SITE_URL + TASKS}?page=${
      queryParams.pageNumber
    }&per_page=${queryParams.pageSize}&sort_by=${
      queryParams.sortField
    }&sort_dir=${queryParams.sortOrder}&search_keyword=${
      queryParams.filter.search
    }&search_type=${queryParams.filter.search_type}&search_view=${
      queryParams.filter.search_view
    }&search_owner=${queryParams.filter.search_owner}&search_franchise=${
      queryParams.filter.search_franchise
    }&search_stage=${queryParams.filter.search_stage}&search_priority=${
      queryParams.filter.priority
    }`
  );
}

export function assignComplete(data) {
  // Make an HTTP POST request to the tasks complete endpoint with data
  return axios.post(process.env.REACT_APP_SITE_URL + TASKS + COMPLETE, data);
}
export function assignDelete(data) {
  // Make an HTTP POST request to the tasks delete endpoint with data
  return axios.post(process.env.REACT_APP_SITE_URL + TASKS + DELETE, data);
}
export function assignOwner(data) {
  // Make an HTTP POST request to the tasks delete endpoint with data
  return axios.post(process.env.REACT_APP_SITE_URL + TASKS + OWNERS, data);
}
export function searchTaskApi(data) {
  // Make an HTTP POST request to the tasks delete endpoint with data
  return axios.get(
    process.env.REACT_APP_SITE_URL + TASKS + SEARCH + `?search_contact=${data}`
  );
}
export function createTask(data) {
  // Make an HTTP POST request to the tasks delete endpoint with data
  return axios.post(
    process.env.REACT_APP_SITE_URL + TASKS + CREATE_TASKS,
    data
  );
}
