import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import CountryCard from "./CountryCard";
import { CountryUIProvider } from "./CountryUIContext";
import { NewCountryCanva } from "./country-canva/NewCountry";
import { fetchPermission } from "../../../../pages/redux/permission";
import { initIds } from "../../../../../_metronic/_partials/controls/RightPanel";
import { useIntl } from "react-intl";

export const CountryPage = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const init = ["kt_new_country_panel"];
  useEffect(() => {
    initIds(init);
  }, [init]);
  const { permission } = useSelector(
    (state) => ({
      permission: state.auth.permission,
    }),
    shallowEqual
  );
  useEffect(() => {
    dispatch(fetchPermission());
  }, [dispatch]);

  return (
    <>
      <CountryUIProvider>
        <CountryCard permission={permission} intl={intl} init={init} />
        <NewCountryCanva />
      </CountryUIProvider>
    </>
  );
};
