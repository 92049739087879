// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html

import * as actions from "../../../_redux/user/userAction";
import * as columnFormatters from "./column-formatters";
import * as uiHelpers from "../UserUIHelpers";

import React, { useEffect, useMemo } from "react";
import {
  getHandlerTableChange,
  getSelectRow,
  headerSortingClasses,
  sortCaret,
} from "../../../../../../_metronic/_helpers";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import BootstrapTable from "react-bootstrap-table-next";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { useUserUIContext } from "../UserUIContext";

const ProfileTable = () => {
  const intl = useIntl();
  let history = useHistory();
  const UserUIContext = useUserUIContext();

  const UserUIProps = useMemo(() => {
    return {
      ids: UserUIContext?.ids,
      setIds: UserUIContext?.setIds,
      queryParams: UserUIContext?.queryParams,
      setQueryParams: UserUIContext?.setQueryParams,
      openEditOrderPage: UserUIContext?.openEditOrderPage,
      openDeleteOrderDialog: UserUIContext?.openDeleteOrderDialog,
    };
  }, [UserUIContext]);

  const dispatch = useDispatch();

  const { currentState } = useSelector(
    (state) => ({ currentState: state.userDetail.profile.list }),
    shallowEqual
  );

  const { totalCount, entities } = currentState;

  useEffect(() => {
    // clear selections list
    // server call by queryParams
    UserUIProps.setIds([]);
    dispatch(actions.fetchProfile(UserUIProps.queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UserUIProps.queryParams, dispatch]);

  const columns = [
    {
      dataField: "name",
      text: intl.formatMessage({ id: "Profile.Name" }),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "description",
      text: intl.formatMessage({ id: "Profile.Description" }),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (e, data) => {
        return <span>{data && data.description ? data.description : "-"}</span>;
      },
    },

    {
      dataField: "status",
      text: intl.formatMessage({ id: "Profile.Active" }),
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.StatusColumnFormatter,
    },
  ];

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: UserUIProps.queryParams?.pageSize,
    page: UserUIProps.queryParams?.pageNumber,
  };
  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      history.push(`users/profile-details/${row.id}`);
    },
  };

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={false}
              paginationProps={paginationProps}
              entities={entities}
            >
              <BootstrapTable
                rowEvents={rowEvents}
                wrapperClasses="table-responsive"
                hover
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="id"
                selectRow={getSelectRow({
                  entities,
                  ids: UserUIProps.ids,
                  setIds: UserUIProps.setIds,
                })}
                defaultSorted={uiHelpers.defaultSorted}
                data={entities === null ? [] : entities}
                onTableChange={getHandlerTableChange(
                  UserUIProps.setQueryParams
                )}
                columns={columns}
                {...paginationTableProps}
              ></BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
};

export default ProfileTable;
