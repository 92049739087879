/* eslint-disable array-callback-return */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

import React, { useEffect, useState } from "react";
import {
  fetchInvoiceList,
  fetchMyOrderDetailCard,
  fetchMyOrderTimeline,
  fetchOrderInvoice,
  fetchPaymentInvoice,
} from "../_redux/order/orderAction";

import { OrderSummaryDetailTable } from "../Table/OrderSummaryDetailTable";
import { SearchProductList } from "../components/orderDetailComponents/SearchProductList";
import SnackBarTool from "../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { SummaryButtons } from "../components/orderDetailComponents/SummaryButtons";
import { SummaryTable } from "../components/orderDetailComponents/SummaryTable";
import { canvaCancel } from "../../../utils/canvaCloseFunction";
import { orderSlice } from "../_redux/order/orderSlice";
import queryString from "query-string";
import {
  collectionExtraChage,
  getEstimationPrice,
  updateOrder,
} from "../_redux/order/orderCrud";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { useOrderUIContext } from "../OrderUIContext";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Table } from "react-bootstrap";
import { convertGBPtoPoundSymbol } from "../../../utils/commonFunction";
import { CircularProgress } from "@material-ui/core";

export function EditOrderDetailSummary({
  orderDetail,
  setDialog,
  orderInvoice,
}) {
  const intl = useIntl();
  const { search } = useLocation();
  const query = queryString.parse(search);
  const { actions } = orderSlice;

  const dispatch = useDispatch();

  const orderUIContext = useOrderUIContext();
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [loading, setLoading] = useState(false);
  const [loadingCharge, setLoadingCharge] = useState(false);
  const [fieldValue, setFieldValue] = useState("");
  const [openCanva, setOpenCanva] = useState(false);
  const [openCheck, setOpenCheck] = useState(false);

  //update order
  const handleUpdateOrder = async () => {
    const data = {
      subscription_id: orderDetail?.subscription_id || "",
      franchise_id: orderDetail?.franchise_id || "",
      item_price_id: [],
      quantity: [],
      product: [],
      packaging_materials: [],
      currency_code: orderDetail?.currency || "",
      coupon_id: [],
      adhoc_charge_price: orderUIContext?.state?.adhocCharge || "",
      adhoc_charge_quantity: "",
      apply_manual_discounts: [
        {
          discount_type:
            orderUIContext?.state?.manualDiscount?.discountType || "",
          duration_type: orderUIContext?.state?.manualDiscount?.duration || "",
          apply_on: orderUIContext?.state?.manualDiscount?.applyOn || "",
          discount_amount:
            orderUIContext?.state?.manualDiscount?.enterDiscount || "",
          manual_discount_period:
            orderUIContext?.state?.manualDiscount?.limitedPeriod || "",
          manual_discount_period_unit:
            orderUIContext?.state?.manualDiscount?.time || "",
          // manual_discount_item_price_id:orderUIContext?.state?.manualDiscount?.applyOn ||"",
          discount_id: orderUIContext?.state?.manualDiscount?.id || "",
        },
      ],
      is_manual_discount: orderUIContext?.state?.manualDiscount ? 1 : "0",
      ship_packaging_materials: 1,
      order_id: orderDetail?.id || "",
      first_name: orderDetail?.customer_details?.first_name || "",
      last_name: orderDetail?.customer_details?.last_name || "",
      address: orderDetail?.customer_details?.address || "",
      city: orderDetail?.customer_details?.city || "",
      zip: orderDetail?.customer_details?.postcode || "",
      email: orderDetail?.customer_details?.email || "",
      phone: orderDetail?.customer_details?.phone || "",
      period: orderUIContext.state?.params?.unit || "",
      period_unit: orderUIContext.state?.params?.period || "",
      is_sector_price: orderDetail?.is_sector_price || "",
    };
    // eslint-disable-next-line no-unused-expressions
    orderUIContext?.state?.productArrayList.map((item) => {
      data.item_price_id.push(item?.item_price_id);
      data.quantity.push(item?.quantity);
      if (item.type === "addon") {
        data.product.push({
          id: item.id,
          quantity: item.qty ? item.qty : 1,
          name: item.name,
          price: item.cost,
          item_price_id: item?.item_price_id,
          cover: item?.cover,
        });
        data.insurance_cover = item?.cover;
      } else {
        data.product.push({
          id: item?.id,
          quantity: item?.quantity,
          name: item?.name,
          price: item.price,
          item_price_id: item?.item_price_id,
        });
      }
      if (
        item?.is_packaging_material === "1" ||
        item?.is_packaging_material === 1
      ) {
        data.packaging_materials.push({
          id: item.id,
          quantity: item.qty ? item.qty : 1,
          name: item.name,
          price: item.cost,
          item_price_id: item.price_id,
        });
      }
    });
    // eslint-disable-next-line no-unused-expressions
    orderUIContext?.state?.coupon.map((item) => {
      if (item.name !== "manual") {
        data.coupon_id.push(item?.id);
      }
    });
    if (orderUIContext.state?.adhocChargeName) {
      data.adhoc_charge_name = orderUIContext.state?.adhocChargeName;
    }
    const check = orderUIContext?.state?.productArrayList.find(
      (e) => e.id === orderUIContext?.state?.adhoc?.id
    );
    if (check) {
      data.adhoc_charge_quantity = check?.quantity;
    }
    if (!openCheck) {
      setOpenCheck(true);
      setLoadingCharge(true);
      let estimationData = {
        extraCollectionData: "",
        price: "",
      };
      if (orderDetail?.is_student === 0) {
        const podCount = orderUIContext.state.productArrayList.find(
          (e) => e.type === "plan"
        );
        if (podCount?.quantity) {
          const queryParams = {
            id: orderDetail.id,
            pod: podCount?.quantity,
          };

          await collectionExtraChage(queryParams).then((res) => {
            estimationData.extraCollectionData = res.data.data;
          });
        }
      }
      await getEstimationPrice(data).then((res) => {
        estimationData.price = res.data.data;
      });
      await orderUIContext.dispatchReducer({
        type: "SET_DATA",
        data: {
          ...orderUIContext.state,
          extraCollection: estimationData.extraCollectionData,
          estimationPrice: estimationData.price,
        },
      });
      setLoadingCharge(false);

      return;
    }
    setLoading(true);
    setSnack({ call: false, type: "", message: "" });

    updateOrder(data)
      .then((res) => {
        canvaCancel("kt_EditOrderDetailSummary_panel_close");
        setOpenCheck(false);
        setSnack({ call: true, type: "success", message: res.data.message });
        setLoading(false);
        dispatch(fetchMyOrderDetailCard(orderDetail?.id));
        dispatch(fetchOrderInvoice(orderDetail?.id));
        dispatch(fetchMyOrderTimeline(orderDetail?.id));
        dispatch(
          fetchPaymentInvoice(
            orderDetail?.id,
            orderDetail?.franchise_id,
            orderDetail?.subscription_id
          )
        );
        dispatch(
          fetchInvoiceList(
            orderDetail?.franchise_id,
            orderDetail?.id,
            orderDetail?.subscription_id
          )
        );
        setTimeout(() => {
          orderUIContext.dispatchReducer({
            type: "SET_DATA",
            data: {
              ...orderUIContext.state,
              productArrayList: [],
              products_id: [],
              adhoc: {},
              adhocCharge: "",
              adhocChargeName: "",
            },
          });
        }, 2000);
        setFieldValue("");
      })
      .catch((error) => {
        setLoading(false);
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
  };
  useEffect(() => {
    if (orderDetail?.subscription_data?.billing_period) {
      orderUIContext.dispatchReducer({
        type: "SET_DATA",
        data: {
          ...orderUIContext.state,
          params: {
            unit: orderDetail?.subscription_data?.billing_period || 1,
            period:
              orderDetail?.subscription_data?.billing_period_unit || "month",
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDetail?.subscription_data?.billing_period]);
  useEffect(() => {
    return () => {
      dispatch(actions.orderReset({}));
      orderUIContext.dispatchReducer({
        type: "RESET_DATA",
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const value =
    (orderInvoice?.last_invoice &&
      orderInvoice?.last_invoice.length &&
      orderInvoice?.last_invoice[0]?.invoice) ||
    "";
  const firstValue =
    (orderInvoice?.first_invoice &&
      orderInvoice?.first_invoice.length &&
      orderInvoice?.first_invoice[0]?.invoice) ||
    "";

  const handleCancel = () => {
    canvaCancel("kt_EditOrderDetailSummary_panel_close");
    setOpenCheck(false);
    orderUIContext.dispatchReducer({
      type: "SET_DATA",
      data: {
        ...orderUIContext.state,
        productArrayList: [],
        products_id: [],
      },
    });
    setFieldValue("");
  };

  useEffect(() => {
    if (query["cm-qty"] && !openCanva && orderDetail?.id) {
      setOpenCanva(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query?.cm, orderDetail?.id]);
  useEffect(() => {
    return () => {
      dispatch(
        orderSlice.actions.orderSummaryFetched({ loading: false, data: [] })
      );
      orderUIContext.dispatchReducer({
        type: "RESET_DATA",
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div
        id="kt_EditOrderDetailSummary_panel"
        className={`offcanvas offcanvas-right pt-5 pb-10 ${
          openCanva ? "offcanvas-on" : ""
        }`}
      >
        {/*begin::Header*/}
        <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
          <div
            className="offcanvas-close mt-n1 pr-5"
            style={{ position: "absolute", top: "15px", right: "10px" }}
          >
            <span
              className="btn btn-xs btn-icon btn-light btn-hover-primary"
              onClick={() => handleCancel()}
            >
              <i className="ki ki-close icon-sm text-muted"></i>
            </span>
          </div>
          <div id="kt_EditOrderDetailSummary_panel_close"></div>
        </div>
        {/*end::Header*/}
        <div className="offcanvas-content scrollhd px-10 ">
          <div>
            <h5>{intl.formatMessage({ id: "Edit.Order" })}</h5>
          </div>
          <div
            id="kt_quick_panel_logs_kt_EditOrderDetailSummary_panel"
            className="fade pt-3 pr-5 mr-n5 scroll active show ps--active-y"
          >
            <div className="mt-6">
              <div
                className="mb-8"
                style={{
                  display: "flex",
                  justifyContent: openCheck ? "left" : "center",
                }}
              >
                {openCheck ? (
                  <ArrowBackIcon onClick={() => setOpenCheck(false)} />
                ) : (
                  ""
                )}
                {!openCheck && (
                  <div className="d-flex prod-search justify-content-end ">
                    <div className="order-search">
                      <SearchProductList
                        orderDetail={orderDetail}
                        orderInvoice={orderInvoice}
                        setFieldValue={setFieldValue}
                        fieldValue={fieldValue}
                        handleCancel={handleCancel}
                        setDialog={setDialog}
                      />
                    </div>
                  </div>
                )}
              </div>
              {!openCheck ? (
                <div>
                  <>
                  
                    <h5>{intl.formatMessage({ id: "Order.summary" })}</h5>
                    <SummaryTable
                      orderUIContext={orderUIContext}
                      orderDetail={orderDetail}
                    />
                    {orderUIContext.state?.productArrayList.length > 0 ? (
                      <SummaryButtons
                        orderUIContext={orderUIContext}
                        orderDetail={orderDetail}
                      />
                    ) : (
                      <div style={{ paddingTop: "60px" }}></div>
                    )}
                  </>
                  <div className="pt-4 pb-4">
                    <hr />
                  </div>
                  {value?.id === firstValue?.id || value === "" ? (
                    ""
                  ) : (
                    <>
                      <h5 className=" mt-6 mb-2">
                        {intl.formatMessage({ id: "Recent.Invoice" })}
                      </h5>
                      <OrderSummaryDetailTable
                        orderDetail={orderDetail}
                        value={value}
                        type={"last"}
                      />
                    </>
                  )}
                </div>
              ) : (
                <>
                  <Table responsive className="summerytable customColor">
                    <thead>
                      <tr>
                        <th>Item</th>
                        <th>Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* {orderUIContext?.state?.extraCollection
                        ?.collectionCharge ? ( */}
                      <tr>
                        <td>Collection Charge:</td>
                        <td>
                          {" "}
                          {orderDetail?.currency
                            ? convertGBPtoPoundSymbol(orderDetail?.currency)
                            : "-"}
                          &nbsp;
                          {orderUIContext?.state?.extraCollection
                            ?.collectionCharge
                            ? Number(
                                orderUIContext?.state?.extraCollection
                                  ?.collectionCharge
                              ).toFixed(2)
                            : "0"}
                        </td>
                      </tr>
                      {/* ) : (
                        ""
                      )} */}
                      {/* {orderUIContext?.state?.extraCollection?.returnCharge ? ( */}
                      <tr>
                        <td>Return Charge:</td>
                        <td>
                          {orderDetail?.currency
                            ? convertGBPtoPoundSymbol(orderDetail?.currency)
                            : "-"}
                          &nbsp;
                          {orderUIContext?.state?.extraCollection?.returnCharge
                            ? Number(
                                orderUIContext?.state?.extraCollection
                                  ?.returnCharge
                              ).toFixed(2)
                            : "0"}
                        </td>
                      </tr>
                      {/* ) : (
                        ""
                      )} */}
                      {/* {orderUIContext?.state?.estimationPrice
                        ?.estimate_amount_on_update ? ( */}
                      <tr>
                        <td>Extra Charge / Storage Cost:</td>
                        <td>
                          {orderDetail?.currency
                            ? convertGBPtoPoundSymbol(orderDetail?.currency)
                            : "-"}
                          &nbsp;
                          {orderUIContext?.state?.estimationPrice
                            ?.estimate_amount_on_update
                            ? Number(
                                orderUIContext?.state?.estimationPrice
                                  ?.estimate_amount_on_update
                              ).toFixed(2)
                            : "0"}
                        </td>
                      </tr>
                      {orderUIContext?.state?.estimationPrice
                        ?.credit_note_estimate_amount ? (
                        <tr>
                          <td>Credit Note Amount:</td>
                          <td>
                            {orderDetail?.currency
                              ? convertGBPtoPoundSymbol(orderDetail?.currency)
                              : "-"}
                            &nbsp;
                            {orderUIContext?.state?.estimationPrice
                              ?.credit_note_estimate_amount
                              ? Number(
                                  orderUIContext?.state?.estimationPrice
                                    ?.credit_note_estimate_amount
                                ).toFixed(2)
                              : "0"}
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}
                      {/* ) : (
                        ""
                      )} */}
                      {/* <tr>
                      <td className="text-right">
                        <strong>Total Price:</strong>
                      </td>
                      <td>
                        <strong></strong>
                      </td>
                    </tr> */}
                    </tbody>
                  </Table>
                  {loadingCharge && (
                    <div className="text-center mt-8">
                      <CircularProgress />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>

        <div className="offcanvas-footer">
          <div className="offcanvas-content text-right ">
            <button
              type="reset"
              className="btn btn-dark font-weight-bolder font-size-sm mr-3"
              onClick={() => handleCancel()}
            >
              {intl.formatMessage({ id: "CANCEL" })}
            </button>
            <button
              // onClick={() => setOpenCheck(true)}
              onClick={handleUpdateOrder}
              className="btn btn-primary font-weight-bolder font-size-sm "
              disabled={
                loading || orderUIContext?.state?.productSubTotal === "N/A"
              }
            >
              {openCheck ? intl.formatMessage({ id: "SAVE" }) : "Next"}
              {loading && <span className="spinner spinner-white mr-4"></span>}
            </button>
          </div>
        </div>
        {snack.call ? <SnackBarTool {...snack} /> : ""}
      </div>
      {openCanva && (
        <div
          class="offcanvas-overlay"
          onClick={() => setOpenCanva(false)}
        ></div>
      )}
    </>
  );
}
