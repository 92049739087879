import React, { useState, useEffect, memo } from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

import DistanceDuration from "../order-canva/DistanceDuration";
import { initIds } from "../../../../_metronic/_partials/controls/RightPanel";
import {
  convertGBPtoPoundSymbol,
  convertKilometersToMiles,
} from "../../../utils/commonFunction";
import { useIntl } from "react-intl";
function OrderDetailDistance({
  orderDetail,
  type,
  customerAddress,
  postcode,
  state,
  franchise,
  setParamData,
  paramData,
  dispatchReducer,
  setKeyDis,
  // keyDis,
}) {
  const keyDis = "return";
  const intl = useIntl();
  const [distanceResponse, setdistanceResponse] = useState();
  const [extraMilesData, setExtraMilesData] = useState();
  const [addressData, setAddressData] = useState({
    collection: {},
    return: {},
  });
  const [openCanva, setOpenCanva] = useState(false);
  // Initial array of ids
  const init = ["kt_distanceduration_panel"];
  // Use the useEffect hook to run the initIds function when the check state changes
  useEffect(() => {
    initIds(init);
  }, [init]);
  useEffect(() => {
    if (!state?.collectionRetrun?.order_id && type === "new_order") {
      setdistanceResponse();
      setExtraMilesData();
      setAddressData({ collection: {}, return: {} });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.collectionRetrun?.order_id]);
  return (
    <>
      {type === "new_order" && state?.collectionRetrun?.return_date ? (
        <div className="card card-custom gutter-b customer-duration ">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div>
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1">
                    <div className="d-flex align-items-center justify-content-between flex-wrap mt-2">
                      <div className="mr-3 mb-5">
                        <h4 className="card-title align-items-start flex-column mb-0">
                          <span className="font-weight-bolder text-dark">
                            Map
                          </span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="neworderdtl-tab">
              {type === "new_order" ? (
                ""
              ) : (
                <>
                  {/* <button
                    className={`btn ${
                      keyDis === "collection" ? "btn-primaryCus" : "btn-dark"
                    } font-weight-bolder font-size-sm mr-3 `}
                    onClick={() => setKeyDis("collection")}
                    disabled={type === "new_order" && !state?.depotData?.id}
                  >
                    {intl.formatMessage({ id: "Collection" })}
                  </button> */}
                  <button
                    className={`btn ${
                      keyDis === "return" ? "btn-primaryCus" : "btn-dark"
                    } font-weight-bolder font-size-sm mr-3 `}
                    onClick={() => setKeyDis("return")}
                    disabled={
                      type === "new_order"
                        ? state?.collectionRetrun?.return_date === ""
                        : !orderDetail?.return_address_line
                    }
                  >
                    {intl.formatMessage({ id: "Return" })}
                  </button>
                </>
              )}

              {keyDis === "collection" ? (
                ""
              ) : (
                <>
                  <div className="d-flex justify-content-between align-items-center pt-4 mb-7 ">
                    <div className="text-center">
                      <div>
                        {keyDis === "collection"
                          ? intl.formatMessage({ id: "Depot" })
                          : "Collection"}
                      </div>
                      <div>
                        <span className="symbol-label ml-2">
                          <span
                            className="svg-icon svg-icon-sm svg-icon-primary"
                            id="kt_sendquote_panel_toggle"
                          >
                            <SVG
                              title=" "
                              className="h-25 align-self-end"
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Map/Marker1.svg"
                              )}
                            ></SVG>
                          </span>
                        </span>
                      </div>
                      <div style={{ width: "102%" }}>
                        {type === "new_order" && keyDis === "collection"
                          ? state?.depotData?.postcode
                          : type === "new_order" && keyDis === "return"
                          ? state?.collectionRetrun?.collection_postcode ||
                            postcode
                          : type === "order" && keyDis === "collection"
                          ? orderDetail?.depot_details?.postcode
                          : type === "order" && keyDis === "return"
                          ? orderDetail?.collection_postcode
                          : ""}
                      </div>
                    </div>
                    <div className="distance"></div>
                    <div className="text-center">
                      <div>
                        {keyDis === "collection"
                          ? intl.formatMessage({ id: "CUSTOMERS" })
                          : "Return"}
                      </div>
                      <div>
                        <span className="symbol-label ml-2">
                          <span className="svg-icon svg-icon-sm svg-icon-primary">
                            <SVG
                              title=" "
                              className="h-25 align-self-end"
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Map/Marker1.svg"
                              )}
                            ></SVG>
                          </span>
                        </span>
                      </div>
                      <div>
                        {type === "new_order" ? (
                          <>
                            {keyDis === "collection"
                              ? state?.collectionRetrun?.collection_postcode ||
                                postcode
                              : keyDis === "return" &&
                                state?.collectionRetrun?.return_date
                              ? state?.collectionRetrun?.return_postcode ||
                                postcode
                              : "-"}
                          </>
                        ) : (
                          <>
                            {keyDis === "collection"
                              ? orderDetail?.collection_postcode
                              : orderDetail?.return_postcode || "-"}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-6 mb-4">
                      <strong>{intl.formatMessage({ id: "Distance" })}:</strong>{" "}
                      {(distanceResponse &&
                        distanceResponse[keyDis] &&
                        distanceResponse[keyDis]?.routes.length > -1 &&
                        distanceResponse[keyDis]?.routes[0]?.legs.length > -1 &&
                        convertKilometersToMiles(
                          distanceResponse[keyDis]?.routes[0]?.legs[0]?.distance
                            .text
                        ) +
                          " " +
                          intl.formatMessage({ id: "miles" })) ||
                        "0"}{" "}
                      {/* {intl.formatMessage({ id: "miles" })} */}
                    </div>
                    <div className="col-md-6 mb-4">
                      <strong>{intl.formatMessage({ id: "Duration" })}:</strong>{" "}
                      {(distanceResponse &&
                        distanceResponse[keyDis] &&
                        distanceResponse[keyDis]?.routes.length > -1 &&
                        distanceResponse[keyDis]?.routes[0]?.legs.length > -1 &&
                        distanceResponse[keyDis]?.routes[0]?.legs[0]?.duration
                          .text) ||
                        "0"}
                    </div>
                    <div className="col-md-6 mb-4">
                      <strong>
                        {intl.formatMessage({ id: "Extra.Miles" })}:
                      </strong>{" "}
                      {keyDis === "collection" &&
                      extraMilesData?.collectionMiles
                        ? extraMilesData?.collectionMiles
                        : keyDis === "return" && extraMilesData?.returnMiles
                        ? extraMilesData?.returnMiles
                        : "0"}
                    </div>
                    <div className="col-md-6 mb-4">
                      <strong>
                        {intl.formatMessage({ id: "Extra.Miles.Charges" })}:
                      </strong>{" "}
                      {keyDis === "collection" &&
                      extraMilesData?.collectionCurrency
                        ? convertGBPtoPoundSymbol(
                            extraMilesData?.collectionCurrency
                          )
                        : keyDis === "return" && extraMilesData?.returnCurrency
                        ? convertGBPtoPoundSymbol(
                            extraMilesData?.returnCurrency
                          )
                        : ""}
                      {keyDis === "collection"
                        ? extraMilesData?.collectionPrice || "0"
                        : extraMilesData?.returnPrice || "0"}
                    </div>
                  </div>

                  <div>
                    <button
                      className="btn btn-primary font-weight-bolder font-size-sm mr-3 "
                      id="kt_distanceduration_panel_toggle"
                      onClick={() => setOpenCanva(true)}
                    >
                      {intl.formatMessage({ id: "See.Map" })}
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {franchise || orderDetail?.franchise_id ? (
        <>
          <DistanceDuration
            setdistanceResponse={setdistanceResponse}
            distanceResponse={distanceResponse}
            setKey={setKeyDis}
            keys={keyDis}
            type={type}
            state={state}
            customerAddress={customerAddress}
            franchise={type === "order" ? orderDetail?.franchise_id : franchise}
            setExtraMilesData={setExtraMilesData}
            extraMilesData={extraMilesData}
            setParamData={setParamData}
            paramData={paramData}
            postcode={postcode}
            dispatchReducer={dispatchReducer}
            setAddressData={setAddressData}
            addressData={addressData}
            setOpenCanva={setOpenCanva}
            openCanva={openCanva}
          />
          {openCanva ? (
            <>
              <div
                className="offcanvas-overlay"
                onClick={() => setOpenCanva(false)}
              ></div>
            </>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default memo(OrderDetailDistance);
