import React from "react";
import moment from "moment";
import ReplyIcon from "@material-ui/icons/Reply";

export function SmsWidget({ data }) {
  return (
    <>
      <div className=" mt-4 mb-5 p-0 cursor-pointer shadow-xs toggle-off">
        <div className="card-body p-5">
          <div className="dealsemail d-flex ">
            <div className="username">{data?.user_data?.username}</div>
            <div>
              <span>
                <span>{data?.description}</span>
              </span>
            </div>
            <div className="emaildate">
              {moment(data?.created_at).format("DD-MM-YYYY")}
              <ReplyIcon />
              {/* <span
                className="symbol-label pointer"
                id="kt_SendEmail_panel_toggle"
              >
                <span className="svg-icon svg-icon-lg "></span>
              </span> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
