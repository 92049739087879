// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html

import * as actions from "../../../_redux/depots/deoptsActions";
import * as columnFormatters from "./column-formatters";
import * as uiHelpers from "../DepotsUIHelpers";

import React, { useEffect, useMemo } from "react";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  getHandlerTableChange,
  getSelectRow,
  sortCaret,
} from "../../../../../../_metronic/_helpers";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import BootstrapTable from "react-bootstrap-table-next";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import { useDepotUIContext } from "../DepotsUIContext";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";

export function DepotsTable() {
  const intl = useIntl();
  let history = useHistory();

  // Products UI Context
  const DepotUIContext = useDepotUIContext();
  const depotUIProps = useMemo(() => {
    return {
      ids: DepotUIContext.ids,
      setIds: DepotUIContext.setIds,
      queryParams: DepotUIContext.queryParams,
      setQueryParams: DepotUIContext.setQueryParams,
      openEditProductPage: DepotUIContext.openEditProductPage,
      openDeleteProductDialog: DepotUIContext.openDeleteProductDialog,
    };
  }, [DepotUIContext]);

  // Getting curret state of products list from store (Redux)
  const { currentState, permission } = useSelector(
    (state) => ({
      currentState: state.depot.list,
      permission: state.auth.permission,
    }),
    shallowEqual
  );
  const { totalCount, entities, listLoading } = currentState;
  // Products Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    depotUIProps.setIds([]);
    // server call by queryParams
    dispatch(actions.fetchProducts(depotUIProps.queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [depotUIProps.queryParams, dispatch]);

  // useEffect(() => {
  //   dispatch(actions.fetchDepotListCount(uiHelpers.initialFilter));
  // }, [dispatch]);

  // Table columns
  const columns = [
    {
      dataField: "depot_reference_id",
      text: intl.formatMessage({ id: "Depot.ID" }),
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "name",
      text: intl.formatMessage({ id: "Depot.Name" }),
      sort: true,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return <span className="breakeWrap">{e ? e : ""}</span>;
      },
    },

    {
      dataField: "short_name",
      text: intl.formatMessage({ id: "Depot.Short" }),
      sort: true,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return <span className="breakeWrap">{e ? e : ""}</span>;
      },
    },

    {
      dataField: "manager_name",
      text: intl.formatMessage({ id: "MANAGER.NAME" }),
      sort: true,
      sortCaret: sortCaret,
    },

    {
      dataField: "phone",
      text: intl.formatMessage({ id: "PHONE" }),
      sort: true,
      sortCaret: sortCaret,
      // formatter: columnFormatters.ConditionColumnFormatter,
    },
    {
      dataField: "manager_email",
      text: intl.formatMessage({ id: "Email" }),
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "Depot.Status" }),
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.StatusColumnFormatter,
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: depotUIProps.queryParams.pageSize,
    page: depotUIProps.queryParams.pageNumber,
  };
  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      history.push(`/settings/depots/depot-details/${row.id}`);
      // window.open(`/settings/depots/depot-details/${row.id}`, "_blank");
    },
  };

  const selectRow = {
    mode: "checkbox",
    hideSelectAll: true,
    hideSelectColumn: true,
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
              entities={entities}
            >
              <BootstrapTable
                rowEvents={rowEvents}
                hover
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                bordered={false}
                remote
                keyField="id"
                data={entities === null ? [] : entities}
                columns={columns}
                selectRow={
                  permission?.setting_depot?.delete
                    ? getSelectRow({
                        entities,
                        ids: depotUIProps.ids,
                        setIds: depotUIProps.setIds,
                      })
                    : selectRow
                }
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  depotUIProps.setQueryParams
                )}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>

              {entities && entities?.length ? (
                ""
              ) : (
                <div style={{ textAlign: "center" }}>
                  <p>{intl.formatMessage({ id: "No.Record.Found" })}</p>
                </div>
              )}
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
