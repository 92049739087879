/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */

import React, { useEffect, useState } from "react";
import {
  addFranchiseDriverLoader,
  createFranchiseDriverLoaderStatus,
} from "../../../_redux/franchises/franchisesCrud";
import {
  fetchFranchisesDriverLoader,
  fetchFranchisesDriverSearch,
} from "../../../_redux/franchises/franchisesActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import ActiveDialogApi from "../../../../../../_metronic/_partials/widgets/alert/ActiveDialogApiCall";
import { Dropdown } from "react-bootstrap";
import SnackBarTool from "../../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { Table } from "react-bootstrap";
import { useIntl } from "react-intl";
import { LoadingCustome } from "../../../../../../_metronic/_partials/controls/LoadingCustome";
import { CircularProgress, Switch } from "@material-ui/core";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

export function DriversLoaders({ permission, franchData }) {
  const intl = useIntl();
  // Use the dispatch hook to dispatch actions
  const dispatch = useDispatch();

  // Use state hook to manage snackbar state
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  // Use state hook to manage dialog state
  const [dialog, setDialog] = useState({ modal: false });
  const [isLoading, setIsLoading] = useState(false);
  const [toggleCheck, setToggleCheck] = useState(false);

  // Use the useSelector hook to access the state
  const {
    driverAddLoading,
    detailData,
    driverSearch,
    franchiseDetail,
    driverLoading,
  } = useSelector(
    (state) => ({
      driverAddLoading: state.franchise.driverAddLoading,
      detailData: state.franchise.driverLoader,
      driverSearch: state.franchise.driverSearch,
      franchiseDetail: state.franchise.detail,
      driverLoading: state.franchise.driverLoading,
    }),
    shallowEqual
  );

  // Event handler for changing the driver status
  const handleChange = () => {
    setSnack({ call: false, type: "", message: "" });

    const data = {
      id: dialog.data.id,
      status: dialog.data.e ? 1 : 0,
    };
    // Call the createFranchiseDriverLoaderStatus function to update the driver status
    createFranchiseDriverLoaderStatus(data)
      .then((res) => {
        // Dispatch an action to fetch updated driver data
        dispatch(fetchFranchisesDriverLoader(dialog.data.franchise));
        setSnack({ call: true, type: "success", message: res.data.message });
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
    setDialog({ modal: false });
  };

  // Event handler for adding a new driver
  const handleAdd = (id) => {
    setSnack({ call: false, type: "", message: "" });
    setIsLoading(true);
    const data = {
      user_id: id,
      franchise_id: franchiseDetail?.id,
    };
    // Call the addFranchiseDriverLoader function to add a new driver
    addFranchiseDriverLoader(data)
      .then((res) => {
        setIsLoading(false);
        if (res.data.success) {
          dispatch(fetchFranchisesDriverLoader(franchiseDetail?.id));
          setSnack({ call: true, type: "success", message: res.data.message });
        } else {
          setSnack({ call: true, type: "error", message: res.data.message });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
  };

  // useEffect(() => {
  //   if (franchiseDetail?.id) {
  //     dispatch(fetchFranchisesDriverSearch(franchiseDetail?.id));
  //   }
  // }, [dispatch, franchiseDetail.id]);

  useEffect(() => {
    if (toggleCheck) {
      dispatch(fetchFranchisesDriverLoader(franchData?.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleCheck]);

  return (
    <>
      {isLoading && <LoadingCustome />}
      <div className="card card-custom  gutter-b mb-10">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center w-100 ">
            <h3 className="card-title p-0 m-0 ">
              <span className="font-weight-bolder text-dark">
                {intl.formatMessage({ id: "Drivers.Loaders" })}
              </span>
              <span>
                {toggleCheck ? (
                  <ExpandLessIcon
                    style={{
                      fontSize: "3rem",
                      margin: " 0 10px",
                      cursor: "pointer",
                    }}
                    onClick={() => setToggleCheck(!toggleCheck)}
                  />
                ) : (
                  <KeyboardArrowRightIcon
                    style={{
                      fontSize: "3rem",
                      margin: " 0 10px",
                      cursor: "pointer",
                    }}
                    onClick={() => setToggleCheck(!toggleCheck)}
                  />
                )}
              </span>
              {/* <Switch
                checked={toggleCheck}
                onChange={() => setToggleCheck(!toggleCheck)}
                color="primary"
                name="checkedB"
                inputProps={{ "aria-label": "primary checkbox" }}
              /> */}
            </h3>
            {toggleCheck && (
              <>
                {permission?.setting_franchise?.create ||
                permission?.setting_franchise?.create ? (
                  <div>
                    <Dropdown
                      className="dropdown-inline"
                      drop="down"
                      alignRight
                      onClick={() => {
                        if (franchiseDetail?.id) {
                          dispatch(
                            fetchFranchisesDriverSearch(franchiseDetail?.id)
                          );
                        }
                      }}
                    >
                      <Dropdown.Toggle
                        id="dropdown-toggle-top2"
                        variant="transparent"
                        className="btn btn-light  font-size-sm font-weight-bolder dropdown-toggle text-dark-75"
                      >
                        {intl.formatMessage({ id: "Add.Driver.Loaders" })}
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                        {driverSearch &&
                        !driverAddLoading &&
                        driverSearch?.length > 0 ? (
                          driverSearch.map((data, index) => (
                            <Dropdown.Item
                              key={index}
                              onClick={() => handleAdd(data.id)}
                            >
                              {data.first_name + " " + data.last_name}
                            </Dropdown.Item>
                          ))
                        ) : (
                          <Dropdown.Item
                          // key={index}
                          // onClick={() => handleAdd(data.id)}
                          >
                            {driverAddLoading ? (
                              <CircularProgress />
                            ) : (
                              "No Record"
                            )}
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                ) : (
                  ""
                )}
              </>
            )}
          </div>
          {toggleCheck && (
            <>
              {detailData && detailData.length ? (
                <Table responsive className="mt-5">
                  <thead>
                    <tr>
                      <th>{intl.formatMessage({ id: "NAME" })}</th>
                      <th>{intl.formatMessage({ id: "ROLE" })}</th>
                      <th style={{ width: "20px" }}></th>
                      <th style={{ width: "20px" }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {detailData
                      ? detailData.map((data, index) => (
                          <tr key={index}>
                            <td>{data.name}</td>
                            <td>{data.role[0]}</td>

                            <td>
                              {" "}
                              <span className="switch switch-outline switch-icon switch-primary">
                                <label>
                                  <input
                                    type="checkbox"
                                    onChange={(e) =>
                                      setDialog({
                                        status: data.status,
                                        modal: true,
                                        data: {
                                          e: e.target.checked,
                                          id: data.id,
                                          franchise: data.franchise_id,
                                        },
                                      })
                                    }
                                    checked={data.status === 1 ? true : false}
                                  />
                                  <span></span>
                                </label>
                              </span>
                            </td>
                          </tr>
                        ))
                      : ""}
                  </tbody>
                </Table>
              ) : (
                <>
                  {driverLoading ? (
                    <div className="text-center mt-8">
                      <CircularProgress />
                    </div>
                  ) : (
                    <div className="mt-4" style={{ textAlign: "center" }}>
                      <p>{intl.formatMessage({ id: "No.Record.Found" })}</p>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
      {snack.call ? <SnackBarTool {...snack} /> : ""}
      <ActiveDialogApi
        dialog={dialog}
        setDialog={setDialog}
        handleChange={handleChange}
      />
    </>
  );
}
