/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { FormControl, MenuItem, Select } from "@material-ui/core/";
import React, { useCallback, useEffect, useState } from "react";
import {
  fetchContactDetail,
  fetchContactfeedList,
} from "../_redux/contact/contactAction";
import {
  getFranchise,
  getFranchiseOwner,
  updateContactCard,
} from "../_redux/contact/contactCrud";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import DatePicker from "react-datepicker";
import { InputGroup } from "react-bootstrap";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import SVG from "react-inlinesvg";
import SnackBarTool from "../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import SpinnerLoader from "../../../../_metronic/_partials/widgets/spinner";
import moment from "moment";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { useFormik } from "formik";
import { useIntl } from "react-intl";
import { getInputClasses } from "../../../utils/commonFunction";

export function ContactUsers({ detailData, setOpenCanva }) {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [list, setList] = useState({ franchiseList: [] });
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });

  const { ownerAgent, admin, permission } = useSelector(
    (state) => ({
      ownerAgent: state.contact.agent,
      admin: state.auth.user,
      permission: state.auth.permission,
    }),
    shallowEqual
  );

  const initialValues = {
    franchise_id:
      detailData && detailData.franchise_id ? detailData.franchise_id : "",
    contact_owner_id:
      detailData && detailData.contact_owner_id
        ? detailData.contact_owner_id
        : "",
    contact_stage:
      detailData && detailData.contact_stage ? detailData.contact_stage : "",
    closed_at: detailData && detailData.closed_at ? detailData.closed_at : "",
  };

  const handleSubmit = (values, { resetForm }) => {
    setSnack({ call: false, type: "", message: "" });
    updateContactCard(detailData.id, values)
      .then((res) => {
        setSnack({ call: true, type: "success", message: res.data.message });

        dispatch(fetchContactDetail(detailData.id));
        dispatch(fetchContactfeedList(detailData.id));
        resetForm();
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
  };

  const handleReset = () => {};

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: handleSubmit,
    onReset: handleReset,
  });

  const handleFranchise = (postcode) => {
    getFranchise(postcode)
      .then((res) => {
        // if (!res.data.data.length < 1) {
        setList((prev) => ({
          ...prev,
          franchiseList: res.data.data,
        }));
        // }
      })
      .catch((error) => {});
  };
  useEffect(() => {
    if (detailData?.user_data?.postcode) {
      handleFranchise(detailData.user_data.postcode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailData, detailData?.user_data?.postcode]);

  const handleOwner = useCallback(() => {
    getFranchiseOwner(formik.values.franchise_id)
      .then((res) => {
        setList((prev) => ({ ...prev, ownerList: res.data.data }));
      })
      .catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.franchise_id]);

  useEffect(() => {
    if (formik?.values?.franchise_id) {
      handleOwner();
    }
  }, [formik.values.franchise_id, handleOwner]);

  //   useEffect(() => {
  //     return () => {
  //       setList({ franchiseList: [] });
  //     };
  //   }, []);
  return (
    <>
      {detailData && (
        <div className="flex-row-auto  profilepannel ">
          <div className="card card-custom  card-stretch ">
            {/* begin::Body */}

            <div className="card-body  pt-4 detailCard">
              {/* begin::User */}
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <a
                    href="#"
                    className="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary"
                  >
                    {detailData?.user_data?.first_name}{" "}
                    {detailData?.user_data?.last_name}
                  </a>
                </div>
                <span className="symbol-label ml-2">
                  {permission?.contact?.edit ? (
                    <span
                      // id="kt_quick_panelone_toggle"
                      onClick={() => setOpenCanva(true)}
                    >
                      <span
                        className="svg-icon svg-icon-sm svg-icon-primary"
                        style={{ cursor: "pointer" }}
                      >
                        <SVG
                          title=" "
                          className="h-25 align-self-end"
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Design/Edit.svg"
                          )}
                        ></SVG>
                      </span>
                    </span>
                  ) : (
                    ""
                  )}
                </span>
              </div>
              {/* end::User */}
              {/* begin::Contact */}
              {detailData.loading ? (
                <SpinnerLoader />
              ) : (
                <>
                  <div className="py-9">
                    <div className="d-flex align-items-center mb-4">
                      <span className="navi-icon mr-2">
                        <span className="svg-icon svg-icon-lg ">
                          <SVG
                            title=" "
                            className="h-75 align-self-end"
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Active-call.svg"
                            )}
                          ></SVG>
                        </span>
                      </span>
                      <a
                        className="text-hover-primary"
                        style={{ color: "black" }}
                        href={`tel:${detailData?.user_data?.phone}`}
                      >
                        {detailData?.user_data?.phone}
                      </a>
                    </div>
                    <div className="d-flex align-items-center  mb-4">
                      <span className="navi-icon mr-2">
                        <span className="svg-icon">
                          <SVG
                            title=" "
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Mail.svg"
                            )}
                          ></SVG>{" "}
                        </span>
                      </span>
                      <a
                        className="text-hover-primary"
                        style={{ color: "black" }}
                        href={`mailto:${detailData?.user_data?.email}`}
                      >
                        {detailData &&
                        detailData.user_data &&
                        detailData.user_data.email
                          ? detailData.user_data.email
                          : "-"}
                      </a>
                    </div>

                    <div className="d-flex align-items-unset mb-4 ">
                      <span className="navi-icon mr-2">
                        <span className="svg-icon">
                          <SVG
                            title=" "
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Map/Marker1.svg"
                            )}
                          ></SVG>{" "}
                        </span>
                      </span>
                      <span className=" ">
                        {detailData &&
                        detailData.user_data &&
                        detailData.user_data.address ? (
                          <>
                            {" "}
                            {detailData.user_data.address
                              .replace(/,,,/g, ",")
                              .replace(/,/g, ", ")
                              ?.replaceAll(",", ", ")}
                            <br />
                          </>
                        ) : (
                          ""
                        )}

                        {detailData &&
                        detailData.user_data &&
                        detailData.user_data.city ? (
                          <>
                            {" "}
                            {detailData.user_data.city}
                            <br />
                          </>
                        ) : (
                          ""
                        )}

                        {detailData &&
                        detailData.user_data &&
                        detailData.user_data.postcode
                          ? detailData.user_data.postcode
                          : ""}
                      </span>
                    </div>
                  </div>
                  {/* end::Contact */}
                  {/* begin::Nav */}
                  <div className="row mb-4">
                    <div className="col-lg-12 col-xl-5   ">
                      <span className="font-weight-bold mr-2">
                        {intl.formatMessage({ id: "Source" })}
                      </span>
                    </div>
                    <div className="col-lg-12 col-xl-7 ">
                      <span className=" ">
                        {detailData && detailData.source
                          ? detailData.source
                          : "-"}
                      </span>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-lg-12 col-xl-5  ">
                      <span className="font-weight-bold mr-2">
                        {intl.formatMessage({ id: "Create.Date" })}
                      </span>
                    </div>
                    <div className="col-lg-12 col-xl-7">
                      <span className=" ">
                        {detailData && detailData.created_at
                          ? moment(detailData?.created_at).format("DD-MM-YYYY")
                          : "-"}
                      </span>
                    </div>
                  </div>
                  <form
                    onSubmit={formik.handleSubmit}
                    onReset={formik.handleReset}
                  >
                    <div className="row mb-4 d-flex align-items-center">
                      <div className="col-lg-12 col-xl-5  ">
                        <span className="font-weight-bold mr-2">
                          {intl.formatMessage({ id: "Franchise" })}
                        </span>
                      </div>
                      <div className="col-lg-12 col-xl-7">
                        <FormControl fullWidth>
                          <Select
                            name="franchise_id"
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                            }}
                            className={`form-control  ${getInputClasses(
                              formik,
                              "franchise_id"
                            )}`}
                            IconComponent={(props) => (
                              <i {...props}>
                                <KeyboardArrowDownIcon />
                              </i>
                            )}
                            inputProps={{ "aria-label": "Without label" }}
                            displayEmpty
                            {...formik.getFieldProps("franchise_id")}
                            //   onBlur={handleBlur}
                            //   value={values.status}
                          >
                            <MenuItem value="">All franchises</MenuItem>

                            {/* {list &&
                              list.franchiseList &&
                              list.franchiseList.selected_franchise &&
                              list.franchiseList.selected_franchise.map(
                                (data, index) => (
                                  <MenuItem key={index} value={data.id}>
                                    {data.name}
                                  </MenuItem>
                                )
                              )} */}
                            {list &&
                              list.franchiseList &&
                              list.franchiseList.all_franchise &&
                              list.franchiseList.all_franchise.map(
                                (data, index) => (
                                  <MenuItem key={index} value={data.id}>
                                    {data.name}
                                  </MenuItem>
                                )
                              )}
                          </Select>
                        </FormControl>
                        {formik.touched.franchise_id &&
                        formik.errors.franchise_id ? (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              {formik.errors.franchise_id}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row mb-4 d-flex align-items-center">
                      <div className="col-lg-12 col-xl-5  ">
                        <span className="font-weight-bold mr-2">
                          {intl.formatMessage({ id: "Contact" })}{" "}
                          {intl.formatMessage({ id: "Owner" })}
                        </span>
                      </div>
                      <div className="col-lg-12 col-xl-7">
                        <FormControl fullWidth>
                          <Select
                            name="contact_owner_id"
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                            }}
                            className={`form-control  ${getInputClasses(
                              formik,
                              "contact_owner_id"
                            )}`}
                            IconComponent={(props) => (
                              <i {...props}>
                                <KeyboardArrowDownIcon />
                              </i>
                            )}
                            inputProps={{ "aria-label": "Without label" }}
                            displayEmpty
                            {...formik.getFieldProps("contact_owner_id")}
                            //   onBlur={handleBlur}
                            //   value={values.status}
                          >
                            <MenuItem value="">
                              {intl.formatMessage({ id: "Contact" })}{" "}
                              {intl.formatMessage({ id: "Owner" })}
                            </MenuItem>
                            <MenuItem value={admin.id}>
                              {admin.first_name +
                                " " +
                                (admin.last_name ? admin.last_name : "")}
                            </MenuItem>
                            {ownerAgent &&
                              ownerAgent.map((data, index) => {
                                if (admin.id !== data.id)
                                  return (
                                    <MenuItem
                                      key={index}
                                      value={data.id}
                                      disabled={
                                        data?.is_available !== 1 ? true : false
                                      }
                                    >
                                      {data.first_name +
                                        " " +
                                        (data.last_name ? data.last_name : "")}
                                    </MenuItem>
                                  );
                              })}
                          </Select>
                        </FormControl>
                        {formik.touched.contact_owner_id &&
                        formik.errors.contact_owner_id ? (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              {formik.errors.contact_owner_id}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row mb-4 d-flex align-items-center">
                      <div className="col-lg-12 col-xl-5  ">
                        <span className="font-weight-bold mr-2">
                          {intl.formatMessage({ id: "Contact" })}{" "}
                          {intl.formatMessage({ id: "Stage" })}
                        </span>
                      </div>
                      <div className="col-lg-12 col-xl-7">
                        <FormControl fullWidth>
                          <Select
                            name="contact_stage"
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                            }}
                            className={`form-control  ${getInputClasses(
                              formik,
                              "contact_stage"
                            )}`}
                            IconComponent={(props) => (
                              <i {...props}>
                                <KeyboardArrowDownIcon />
                              </i>
                            )}
                            inputProps={{ "aria-label": "Without label" }}
                            displayEmpty
                            {...formik.getFieldProps("contact_stage")}
                          >
                            <MenuItem value="Open">Open</MenuItem>
                            <MenuItem value={"Qualified"}>Qualified</MenuItem>
                            <MenuItem value={"Not Qualified"}>
                              Not Qualified
                            </MenuItem>
                            <MenuItem value={"Customer"}>Customer</MenuItem>
                          </Select>
                        </FormControl>
                        {formik.touched.contact_stage &&
                        formik.errors.contact_stage ? (
                          <div className=" ml-4 mb-4 fv-plugins-message-container">
                            <div className="fv-help-block">
                              {formik.errors.contact_stage}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="row mb-4 d-flex align-items-center">
                      <div className="col-lg-12 col-xl-5  ">
                        <span className="font-weight-bold mr-2">
                          {intl.formatMessage({ id: "Close.Date" })}
                        </span>
                      </div>
                      <div className="col-lg-12 col-xl-7 date-picker">
                        <span className="text-muted">
                          <InputGroup className="calendr">
                            <DatePicker
                              fullWidth
                              fixedHeight
                              className="form-control"
                              wrapperClassName="datepicker"
                              dateFormat="dd-MM-Y"
                              placeholderText="DD/MM/YYYY"
                              minDate={new Date()}
                              selected={Date.parse(formik.values?.closed_at)}
                              onChange={(date) =>
                                formik.setFieldValue(
                                  "closed_at",
                                  moment(date).format("YYYY-MM-DD")
                                )
                              }
                            />
                            <CalendarTodayIcon />
                          </InputGroup>
                        </span>
                      </div>
                    </div>
                    {permission?.contact?.edit ? (
                      <div style={{ textAlign: "right" }}>
                        <button
                          disabled={!formik.dirty}
                          type="submit"
                          className="btn btn-primary font-weight-bolder font-size-sm  "
                        >
                          {intl.formatMessage({ id: "SAVE" })}
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </form>
                </>
              )}
              {/* end::Nav */}
            </div>

            {/* end::Body */}
          </div>
        </div>
      )}

      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </>
  );
}
