import React from "react";
import { FormControl, MenuItem, Select } from "@material-ui/core";

import { Form } from "react-bootstrap";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { getInputClasses } from "../../../../../utils/commonFunction";

const CountryForm = ({
  intl,
  formik,
  countryApi,
  handleSelectCountry,
  languageData,
  formData,
  setDialog,
}) => {
  return (
    <div className="row">
      {/* Country Select Field */}
      <div className="col-12">
        <div className="form-groupB">
          <label>{intl.formatMessage({ id: "COUNTRY" })}</label>
          <FormControl fullWidth>
            <Select
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              className={`form-control  ${getInputClasses(formik, "name")}`}
              IconComponent={(props) => (
                <i {...props}>
                  <KeyboardArrowDownIcon />
                </i>
              )}
              inputProps={{ "aria-label": "Without label" }}
              displayEmpty
              name="name"
              onChange={(e) => handleSelectCountry(e.target.value)}
              value={formik.values.name}
              // {...formik.getFieldProps("name")}
              //   onBlur={handleBlur}
              //   value={values.status}
            >
              <MenuItem value="">
                {intl.formatMessage({ id: "COUNTRY" })}
              </MenuItem>
              {countryApi.map((data, index) => (
                <MenuItem value={data.name.common} key={index}>
                  {data.name.common}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {formik.touched.name && formik.errors.name ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.name}</div>
            </div>
          ) : null}
        </div>
      </div>
      {/* Short Name Input Field */}
      <div className="col-12">
        <div className="form-groupB">
          <label>{intl.formatMessage({ id: "Country.Short" })}</label>
          <Form.Control
            type="text"
            placeholder={intl.formatMessage({ id: "Country.Short" })}
            className={`form-control  ${getInputClasses(formik, "short_name")}`}
            name="short_name"
            disabled
            {...formik.getFieldProps("short_name")}
          />
          {formik.touched.short_name && formik.errors.short_name ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.short_name}</div>
            </div>
          ) : null}
        </div>
      </div>
      {/* Language Select Field */}
      <div className="col-12">
        <div className="form-groupB">
          <label>{intl.formatMessage({ id: "Language" })}</label>
          <FormControl fullWidth>
            <Select
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              className={`form-control  ${getInputClasses(formik, "language")}`}
              name="language"
              {...formik.getFieldProps("language")}
              IconComponent={(props) => (
                <i {...props}>
                  <KeyboardArrowDownIcon />
                </i>
              )}
              inputProps={{ "aria-label": "Without label" }}
              displayEmpty
            >
              <MenuItem value="">
                {intl.formatMessage({ id: "Language" })}
              </MenuItem>
              {languageData && languageData.length ? (
                languageData.map((data, index) => (
                  <MenuItem value={data} key={index}>
                    {data}
                  </MenuItem>
                ))
              ) : (
                <div style={{ textAlign: "center" }}>
                  <p>{intl.formatMessage({ id: "No.Record.Found" })}</p>
                </div>
              )}
            </Select>
          </FormControl>

          {formik.touched.language && formik.errors.language ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.language}</div>
            </div>
          ) : null}
        </div>
      </div>
      {/* Currency Select Field */}
      <div className="col-12">
        <div className="form-groupB">
          <label>{intl.formatMessage({ id: "Currency" })}</label>
          <FormControl fullWidth>
            <Select
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              className={`form-control  ${getInputClasses(formik, "currency")}`}
              name="currency"
              {...formik.getFieldProps("currency")}
              IconComponent={(props) => (
                <i {...props}>
                  <KeyboardArrowDownIcon />
                </i>
              )}
              inputProps={{ "aria-label": "Without label" }}
              displayEmpty
              disabled={
                formData && formData.currencies && formData.currencies.length
                  ? true
                  : false
              }
            >
              <MenuItem value="">
                {intl.formatMessage({ id: "Currency" })}
              </MenuItem>
              {formData && formData.currencies && formData.currencies.length ? (
                formData.currencies.map((data, index) => (
                  <MenuItem value={data.name} key={index}>
                    {data.name}
                  </MenuItem>
                ))
              ) : (
                <div style={{ textAlign: "center" }}>
                  <p>{intl.formatMessage({ id: "No.Record.Found" })}</p>
                </div>
              )}
            </Select>
          </FormControl>

          {formik.touched.currency && formik.errors.currency ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.currency}</div>
            </div>
          ) : null}
        </div>
      </div>
      {/* Time Zone Select Field */}
      <div className="col-12">
        <div className="form-groupB">
          <label>{intl.formatMessage({ id: "Time.Zone" })}</label>
          <FormControl fullWidth>
            <Select
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              IconComponent={(props) => (
                <i {...props}>
                  <KeyboardArrowDownIcon />
                </i>
              )}
              inputProps={{ "aria-label": "Without label" }}
              displayEmpty
              className={`form-control  ${getInputClasses(formik, "timezone")}`}
              name="timezone"
              {...formik.getFieldProps("timezone")}
            >
              <MenuItem value="">
                {intl.formatMessage({ id: "Time.Zone" })}
              </MenuItem>
              {formData?.timezones.map((data, index) => (
                <MenuItem value={data} key={index}>
                  {data}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {formik.touched.timezone && formik.errors.timezone ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.timezone}</div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="col-6">
        <div className="form-groupB row">
          <label className="col-5 col-form-label ">
            {intl.formatMessage({ id: "ACTIVE" })}
          </label>
          <div className="col-7 text-right mt-1">
            <span className="switch switch-outline switch-icon switch-primary">
              <label>
                <input
                  type="checkbox"
                  checked={formik.values.status === 1 ? true : false}
                  name="quick_panel_notifications_2"
                  onChange={() => setDialog(true)}
                />
                <span></span>
              </label>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CountryForm;
