import React, { useEffect, useState } from "react";
import {
  CreateContactOnFraSchema,
  CreateContactSchema,
  CreateOnContactSchema,
  UpdateContactSchema,
} from "../form/validationSchema";
import {
  createContact,
  getAddress,
  getFranchise,
  getFranchiseOwnerList,
  updateContact,
} from "../_redux/contact/contactCrud";
import {
  createContactApiCall,
  dealsUpdateApiCall,
  editContactValue,
  editDeal,
  updateContactApiCall,
  updateCustomerApiCall,
} from "../ContactHelper";
import {
  fetchContact,
  fetchContactDetail,
} from "../_redux/contact/contactAction";
import {
  fetchMyOrderDetailCard,
  fetchNewCustomerCard,
} from "../../Order/_redux/order/orderAction";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import * as Yup from "yup";

import CreateContactForm from "../form/CreateContactForm";
import DiscardDialog from "../../../../_metronic/_partials/widgets/alert/DiscardDialog";
import SnackBarTool from "../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { dealsUpdate } from "../_redux/deals/dealsCrud";
import { fetchDealDetail } from "../_redux/deals/dealsAction";
import { initialFilter } from "../ContactUIHelpers";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { fetchCustomerTimeLine } from "../../Customer/_redux/customer/customerAction";

export function CreateContactOffCanvas({
  title,
  setOpenCanva,
  type,
  check,
  contactDetail,
  orderId,
  editCustomerId,
  editCustomer,
  checked,
  pageCheck,
  detailData,
  customerId,
  setBillingCall,
  isPopSelected,
  handleContactUpdate,
  dispatchReducer,
  stateReducer,
}) {
  // use hooks
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [fieldArray, setFieldArray] = useState({ email: 1, phone: 1 });
  const [dropDown, setDropDown] = useState({ address: [] });
  const [loading, setLoading] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [checkCall, setCheckCall] = useState(false);
  const [dropDownFranchise, setDropDownFranchise] = useState({ franchise: [] });

  // useSelector hook for getting data from the store
  const { ownerAgent, admin } = useSelector(
    (state) => ({
      ownerAgent: state.contact.agent,
      admin: state.auth.user,
    }),
    shallowEqual
  );

  // handleClose is called when the form is closed
  const handleClose = () => {
    if (formik.dirty && Object.keys(formik.touched)?.length !== 0) {
      setDialog(true);
    } else {
      formik.resetForm();
      setOpenCanva(false);
    }
  };

  // initialValues sets the initial form values
  const initialValues = {
    first_name: "",
    last_name: "",
    email: [],
    phone: [],
    postcode: "",
    city: "",
    address: "",
    franchise_id: "",
    contact_owner_id: admin && admin.id ? admin.id : "",
    is_address_manual: 0,
  };

  // handleSubmit is called when the form is submitted
  const handleSubmit = (values, actions) => {
    setLoading(true);
    setSnack({ call: false, type: "", message: "" });
    let arg = { setSnack, values, setLoading, dispatch, setOpenCanva };
    if (type === "edit") {
      console.log("Edittttttt");
      if (check === "Deal") {
        console.log("Deallllllllllllllllllll");
        arg = { ...arg, dealsUpdate, contactDetail, fetchDealDetail };
        dealsUpdateApiCall({ ...arg });
      } else if (check === "customer") {
        console.log("customerrrrrrrrrrrrrrr-90");
        dispatch(fetchCustomerTimeLine(customerId, editCustomerId));
        arg = {
          ...arg,
          orderId,
          fetchMyOrderDetailCard,
          updateContact,
          editCustomerId,
          fetchNewCustomerCard,
          check,
          pageCheck,
          formik,
          setBillingCall,
          isPopSelected,
          handleContactUpdate,
        };
        updateCustomerApiCall({ ...arg });
        dispatchReducer({
          type: "SET_DATA",
          data: {
            ...stateReducer,

            siteSelect: {},
          },
        });
      } else {
        console.log("demo-91");
        arg = {
          ...arg,
          updateContact,
          contactDetail,
          fetchContactDetail,
        };
        updateContactApiCall({ ...arg });
      }
    } else {
      console.log("demo-92");

      arg = {
        ...arg,
        createContact,
        fetchContact,
        initialFilter,
        check,
        history,
      };
      createContactApiCall({ ...arg });
    }
  };

  const formik = useFormik({
    initialValues:
      type === "edit" && !check
        ? editContactValue(contactDetail)
        : type === "edit" && check === "Deal"
        ? editDeal(contactDetail)
        : check === "customer"
        ? editCustomer
        : initialValues,
    validationSchema:
      admin?.roles &&
      admin?.roles?.length > 0 &&
      admin?.roles[0]?.name === "franchise-owner"
        ? CreateContactOnFraSchema(intl, Yup)
        : type === "edit" && checked === "customers"
        ? UpdateContactSchema(intl, Yup)
        : pageCheck === "new_order"
        ? CreateContactSchema(intl, Yup)
        : type === "edit"
        ? CreateOnContactSchema(intl, Yup)
        : CreateOnContactSchema(intl, Yup),
    // validationSchema:
    //   type === "edit"
    //     ? UpdateContactSchema(intl, Yup)
    //     : CreateContactSchema(intl, Yup),
    enableReinitialize: true,

    onSubmit: handleSubmit,
  });

  //function to get uk address filter by postcode given in argument
  const handleGetAddress = (postcode, type, check) => {
    if (!postcode) return;

    if (type !== "edit") {
      handleGetFranchise(postcode);
      formik.setFieldValue("is_address_manual", "0");
    }
    setSnack({
      call: false,
      type: "",
      message: "",
    });
    getAddress(postcode)
      .then((res) => {
        setDropDown({ address: res.data.addresses });
        if (type === "edit") {
          formik.setFieldValue("city", formik?.values?.city);
        } else {
          formik.setFieldValue("city", res.data.addresses[0].town_or_city);
        }
        if (check !== "onMount") {
          setSnack({
            call: true,
            type: "success",
            message: "Postcode found successfully",
          });
        }
      })
      .catch((error) => {
        // formik.setFieldValue("address", "");
        // formik.setFieldValue("city", "");
        formik.setValues({ ...formik.values, address: "", city: "" });
        setDropDown({ address: [] });
        setSnack({
          call: true,
          type: "error",
          message: "Cannot find Post Code",
        });
      });
  };

  //function to selected franchise based on postcode passed in argument
  const handleGetFranchise = (postcode) => {
    if (
      admin?.roles &&
      admin?.roles?.length > 0 &&
      admin?.roles[0]?.name === "franchise-owner" &&
      pageCheck === "new_order"
    ) {
      getFranchiseOwnerList(postcode)
        .then((res) => {
          formik.setFieldValue("franchise_id", admin?.franchise_id || "");

          if (
            admin?.roles &&
            admin?.roles?.length > 0 &&
            admin?.roles[0]?.name === "franchise-owner"
          ) {
            setDropDownFranchise({
              franchise: res.data.data?.selected_franchise,
            });
          }
        })
        .catch((error) => {});
    } else {
      getFranchise(postcode)
        .then((res) => {
          formik.setFieldValue(
            "franchise_id",
            res.data.data?.selected_franchise[0]?.id || ""
          );
          if (
            admin?.roles &&
            admin?.roles?.length > 0 &&
            admin?.roles[0]?.name === "franchise-owner"
          ) {
            setDropDownFranchise({
              franchise: res.data.data?.selected_franchise,
            });
          }
        })
        .catch((error) => {});
    }
  };
  //calls only on edit condition to address list
  useEffect(() => {
    if (
      type === "edit" &&
      formik?.values?.postcode &&
      formik.values?.is_address_manual !== 1 &&
      !checkCall
    ) {
      handleGetAddress(formik?.values?.postcode, type, "onMount");
      setCheckCall(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.values?.postcode]);
  return (
    <div
      id="kt_quick_panelone"
      className={`offcanvas offcanvas-right pt-5 pb-10 offcanvas-on `}
    >
      {/*begin::Header*/}
      <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
        <div style={{ position: "absolute", top: "15px", right: "10px" }}>
          <button
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            onClick={() => handleClose()}
          >
            <i className="ki ki-close icon-xs text-muted"></i>
          </button>
        </div>
      </div>

      {/*end::Header*/}

      {/*begin::Content*/}
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <div className=" px-10 ">
          <div>
            <h5>
              {" "}
              {pageCheck === "new_order" && !detailData?.order?.id
                ? "Contact Details"
                : pageCheck === "new_order" && detailData?.order?.id
                ? "Customer Detail"
                : title}
            </h5>
          </div>
          <div className="tab-content">
            <div
              id="kt_quick_panel_logs_kt_quick_panelone"
              role="tabpanel"
              className={`tab-pane fade pt-3 pr-5 mr-n5 scroll  active show `}
            >
              {/* rendering form of create contact */}
              <CreateContactForm
                formik={formik}
                type={type}
                handleGetAddress={handleGetAddress}
                contactDetail={contactDetail}
                ownerAgent={ownerAgent}
                fieldArray={fieldArray}
                setFieldArray={setFieldArray}
                admin={admin}
                check={check}
                checked={checked}
                dropDown={dropDown}
                setDropDown={setDropDown}
                pageCheck={pageCheck}
                dropDownFranchise={dropDownFranchise}
                setDropDownFranchise={setDropDownFranchise}
              />
            </div>
            {/*end::Content*/}
          </div>
        </div>
        <div className="offcanvas-footer">
          <div className="offcanvas-content text-right ">
            <button
              type="button"
              className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
              onClick={() => handleClose()}
            >
              {intl.formatMessage({ id: "CANCEL" })}
            </button>

            <button
              type="submit"
              className="btn btn-primary font-weight-bolder font-size-sm "
              disabled={loading}
            >
              {intl.formatMessage({ id: "SAVE" })}
              {loading && (
                <span className="ml-2 mr-2 spinner spinner-white"></span>
              )}
            </button>
          </div>
        </div>
      </form>

      {/* rendring discard message modal */}
      <DiscardDialog
        setDialogDis={setDialog}
        dialogDis={dialog}
        formik={formik}
        setActive={setOpenCanva}
      />

      {/* rendering snack bar notifications */}
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </div>
  );
}
