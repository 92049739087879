import React from "react";
import { ProgressBar } from "react-bootstrap";

export function CustomeProgressBar({ variant, now }) {
  return (
    <ProgressBar
      variant={variant}
      animated
      now={now}
      style={{ height: "3px", width: "100%" }}
    />
  );
}
