// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html

import * as actions from "../_redux/order/orderAction";
import * as uiHelpers from "../OrderUIHelpers";

import React, { useEffect, useMemo } from "react";
import {
  getHandlerTableChange,
  getSelectRow,
  headerSortingClasses,
  sortCaret,
} from "../../../../_metronic/_helpers";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import BootstrapTable from "react-bootstrap-table-next";
import NoRecord from "../../../../_metronic/_partials/widgets/NoRecord";
import { Pagination } from "../../../../_metronic/_partials/controls";
// import { ProgressBar } from "react-bootstrap";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { useOrderUIContext } from "../OrderUIContext";
// import { convertGBPtoPoundSymbol } from "../../../utils/commonFunction";
// import { fulfillmentStatus } from "../../../utils/commonFunction";

const OrderTable = () => {
  const intl = useIntl();
  let history = useHistory();
  const OrderUIContext = useOrderUIContext();
  const OrderUIProps = useMemo(() => {
    return {
      ids: OrderUIContext?.ids,
      setIds: OrderUIContext?.setIds,
      queryParams: OrderUIContext?.queryParams,
      setQueryParams: OrderUIContext?.setQueryParams,
      openEditOrderPage: OrderUIContext?.openEditOrderPage,
      openDeleteOrderDialog: OrderUIContext?.openDeleteOrderDialog,
    };
  }, [OrderUIContext]);

  const dispatch = useDispatch();
  const { currentState } = useSelector(
    (state) => ({ currentState: state.order.list }),
    shallowEqual
  );
  const { totalCount, entities, listLoading } = currentState;

  useEffect(() => {
    // clear selections list
    // server call by queryParams
    OrderUIProps.setIds([]);
    dispatch(actions.fetchMyOrderList(OrderUIProps.queryParams));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [OrderUIProps.queryParams, dispatch]);

  useEffect(() => {
    return () => {
      OrderUIProps.setQueryParams(uiHelpers.initialFilter);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      dataField: "booking_reference",
      text: intl.formatMessage({ id: "Order" }),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "users.first_name",
      text: intl.formatMessage({ id: "Customer.Name" }),
      width: "2%",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,

      headerStyle: { width: "2%" },
      formatter: (e, data) => {
        return (
          <div
            style={{
              maxWidth: "300px",
              minWidth: "100px",
              whiteSpace: "initial",
            }}
          >
            <span>
              {data && data.customer_details && data.customer_details.first_name
                ? data.customer_details.first_name
                : " - "}
              &nbsp;{data?.customer_details?.last_name || ""}
            </span>
          </div>
        );
      },
    },

    
    {
      dataField: "pod",
      text: "UNIT",
      sort: true,

      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (e, data) => {
        return <span>{e ? e : " - "}</span>;
      },
    },
    // {
    //   dataField: "subscription_status",
    //   text: "sub status",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   headerSortingClasses,
    //   formatter: (e, data) => {
    //     const formattedText = e 
    //       ? e.replace(/_/g, " ").replace(/^\w/, c => c.toUpperCase())
    //       : " - ";
    //     return <span>{formattedText}</span>;
    //   },
    // },
    {
      dataField: "pod_plan_type",
      text: "CLASS",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (e, data) => {
        return <span>      { data?.pod_plan_type === "First Class"
        ? "FC":data?.pod_plan_type === "Economy"
        ? "E":data?.pod_plan_type === "Premium" ? "P":"-"}</span>;
      },
    },
    {
      dataField: "is_container",
      text: "Type",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (e, data) => {
        return (
          <>
            {data.is_container == "1"
              ? "Container"
              : data.is_container == "2"
              ? "Howden"
              : data.is_container == "3"?"Box":"Pod"}
          </>
        );
      },
    },
    {
      dataField: "franchises.name",
      text: intl.formatMessage({ id: "Franchise" }),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      headerStyle: { width: "2%" },
      formatter: (e, data) => {
        return <span>{data?.franchise_details?.short_name || ""}</span>;
      },
    },
    {
      dataField: "",
      text: "Location",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return (
          <span>
            {data.is_container == "1"
              ? (data?.site_name && data?.site_name?.substring(0, 15)) || "-"
              : data.is_container == "2"
              ? (data.depot_name && data.depot_name?.substring(0, 15)) || "-"
              : (data?.depot_name && data?.depot_name?.substring(0, 15)) || "-"}
          </span>
        );
      },
    },
    // {
    //   dataField: "agent",
    //   text: "Created By",
    //   sort: false,
    //   sortCaret: sortCaret,
    //   formatter: (e, data) => {
    //     return (
    //       <span>
    //         {data?.user_id === data?.created_by ? (
    //           "Online"
    //         ) : (
    //           <>{data?.created_by_name || "-"} &nbsp;</>
    //         )}
    //       </span>
    //     );
    //   },
    // },
    {
      dataField: "agent.first_name",
      text: "Closed By",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return (
          <span>
            {data?.user_id === data?.created_by ? (
              "Online"
            ) : (
              <>{data?.contact_owner_name || "-"} &nbsp;</>
            )}
          </span>
        );
      },
    },
    {
      dataField: "collection_date",
      text: "Collection Date",
      sort: true,

      // sortCaret: sortCaret,
      // headerSortingClasses,
      formatter: (e, data) => {
        return (
          <span>{moment(data?.collection_date).format("DD-MM-YYYY")}</span>
        );
      },
    },
    // {
    //   dataField: "return_date",
    //   text: "Return Date",
    //   sort: true,

    //   // sortCaret: sortCaret,
    //   // headerSortingClasses,
    //   formatter: (e, data) => {
    //     return (
    //       <span>
    //         {data?.return_date
    //           ? moment(data?.created_at).format("DD-MM-YYYY")
    //           : "-"}
    //       </span>
    //     );
    //   },
    // },
    {
      dataField: "orders.created_at",
      text: intl.formatMessage({ id: "Date" }),
      sort: true,

      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (e, data) => {
        return (
          <span>{moment(data?.created_at).format("DD-MM-YYYY hh:mm A")}</span>
        );
      },
    },
    {
      dataField: "payment_status",
      text: intl.formatMessage({ id: "Status" }),
      headerStyle: { width: "100%" },
      sort: true,
      style: { width: "100%" },
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (e, data) => {
        return (
          <>
            {/* <div className="d-flex justify-content-between"> */}
            <div className="font-size-sm ">
              {data.is_container === 1
                ? data?.container_status == "1"
                  ? "Reserved"
                  : data?.container_status == "2"
                  ? "Confirmed"
                  : data?.container_status == "3"
                  ? "In Storage"
                  : data?.container_status == "4"
                  ? "Completed"
                  : data?.container_status == "5"
                  ? "Cancelled"
                  : "-"
                : data?.is_cancel === 1
                ? "Cancelled"
                : data?.is_same_time_return === 1
                ? "Col-Ret"
                : data?.is_student_access === 1
                ? "Access"
                : data?.is_student_access !== 1 && data?.is_return === 1
                ? "Return"
                : intl.formatMessage({ id: "Collection" })}
            </div>
          </>
        );
      },
    },
  ];

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: OrderUIProps.queryParams?.pageSize,
    page: OrderUIProps.queryParams?.pageNumber,
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      window.open(`order-details/${row.id}`, "_blank"); // Opens in a new tab
    },
  };

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
              entities={entities}
            >
              <BootstrapTable
                rowEvents={rowEvents}
                hover
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden newordertable"
                bootstrap4
                remote
                keyField="id"
                defaultSorted={uiHelpers.defaultSorted}
                data={ entities|| []}
                selectRow={
                  // permission?.setting_product?.delete
                  //   ?
                  getSelectRow({
                    entities,
                    ids: OrderUIProps.ids,
                    setIds: OrderUIProps.setIds,
                  })
                  // : selectRow
                }
                onTableChange={getHandlerTableChange(
                  OrderUIProps.setQueryParams
                )}
                columns={columns}
                {...paginationTableProps}
              ></BootstrapTable>
              {(entities && entities?.length) || listLoading ? (
                ""
              ) : (
                <NoRecord />
              )}
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
};

export default OrderTable;

