/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */

import React, { Fragment } from "react";

import { CallsWidget } from "../../../../../_metronic/_partials/widgets/mixed/CallsWidget";
import NoRecord from "../../../../../_metronic/_partials/widgets/NoRecord";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";

export function Calls({ callList, detailData, permission }) {
  return (
    <>
      <ul className="deal-top deal-note-icon">
        <li>
          <span className="symbol-label">
            {/* {permission ? ( */}
            <a
              href={`tel:${
                detailData && detailData.phone && detailData.phone?.length
                  ? detailData.phone[0]
                  : detailData && detailData.user_data
                  ? detailData.user_data.phone
                  : callList && callList.length
                  ? callList[0].customer_phone_number
                  : ""
              }`}
              className="svg-icon svg-icon-lg svg-icon-primary"
            >
              <SVG
                title=" "
                className="h-75 align-self-end"
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Active-call.svg"
                )}
              ></SVG>
            </a>
            {/* ) : (
              ""
            )} */}
          </span>
        </li>
      </ul>
      <div>
        {callList && callList.length ? (
          callList.map((data, index) => (
            <Fragment key={index}>
              <CallsWidget data={data} />{" "}
            </Fragment>
          ))
        ) : (
          <NoRecord />
        )}
      </div>
    </>
  );
}
