import React from "react";
import { Button } from "react-bootstrap";

export function SiteCard({state, setDialogSite }) {
console.log("state", state);

    return (
        <>
            <div className="card card-custom gutter-b newordnoteCon  ">
                <div className="card-body ">
                    <div className="d-flex justify-content-between">
                        <div>
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1">
                                    <div className="d-flex align-items-center justify-content-between flex-wrap mt-2">
                                        <div className="mr-3 mb-5">
                                            <h4 className="card-title align-items-start flex-column mb-0">
                                                <span className="font-weight-bolder text-dark">
                                                    Site
                                                </span>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="edit-icon">
                            {/* <Button variant="primary"
                            //  onClick={()=>
                            //     setDialogType({ open:true})
                            //     }
                            //      disabled={state.containerTypeSelect == "container"}
                            >Edit Type</Button>&nbsp; */}
                            <Button variant="primary"
                            onClick={()=>setDialogSite({open:true})}
                            >Edit Site</Button>

                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center"></div>
                        <div className="row">
                            <div className="col-4">
                                Name:&nbsp;
                                <span className="font-weight-bolder text-dark">
                                    {state?.siteSelect?.name}
                                </span>
                            </div>
                            <div className="col-4">
                                Franchise:&nbsp;
                                <span className="font-weight-bolder text-dark">
                                    {state?.siteSelect?.franchise?.name || ""}
                                </span>
                            </div>
                            <div className="col-4">Distance:&nbsp;
                                {state?.siteSelect?.distance || ""} Km
                            </div>
                            <div className="col-6">
                                Available&nbsp;From:&nbsp;
                                {state?.siteSelect?.available_from || ""}
                            </div>
                            <div className="col-12">Address:&nbsp;
                                {state?.siteSelect?.address || ""}
                            </div>
                        </div>
                </div>
            </div>
        </>
    )
}