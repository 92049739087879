import { assignComplete, assignDelete } from "../_redux/TasksCrud";

import { Modal } from "react-bootstrap";
import React from "react";
import { useIntl } from "react-intl";

const SaveDialog = ({ active, setActive, handleComplete }) => {
  const intl = useIntl();

  const handleSubmit = () => {
    handleComplete(active.action === "delete" ? assignDelete : assignComplete);
    setActive(
      active.action === "delete"
        ? { type: false, action: "delete" }
        : { type: false, action: "complete" }
    );
  };
  return (
    <Modal show={active.type} aria-labelledby="example-modal-sizes-title-lg">
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          {active.action === "delete"
            ? intl.formatMessage({ id: "Delete" })
            : intl.formatMessage({ id: "Mark.as.Complete" })}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* {!isLoading && ( */}
        <span>
          {active?.action === "delete"
            ? intl.formatMessage({ id: "Message.Delete.Task" })
            : intl.formatMessage({ id: "Message.Complete.Task" })}
        </span>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={() => setActive(false)}
            className="btn btn-light btn-elevate"
          >
            {intl.formatMessage({ id: "No" })}
          </button>
          <> </>
          <button
            type="button"
            onClick={() => handleSubmit()}
            className="btn btn-delete btn-elevate"
          >
            {intl.formatMessage({ id: "Yes" })}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default SaveDialog;
