import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { fetchVersion } from "../modules/Order/_redux/order/orderAction";
import queryString from "query-string";
import { CircularProgress } from "@material-ui/core";

const TermsAndCondition = (props) => {
  const { search } = useLocation();
  const query = queryString.parse(search);
  const dispatch = useDispatch();
  const { versionDetail, versionDetailLoading } = useSelector(
    (state) => ({
      versionDetail: state.order.versionDetail,
      versionDetailLoading: state.order.versionDetailLoading,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (query?.franchise_id) {
      dispatch(
        fetchVersion(query?.franchise_id, query?.order_id, query?.version)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, query?.franchise_id, query?.order_id]);

  return (
    <div>
      <label
        className="font-weight-bolder text-dark"
        style={{ fontSize: "20px" }}
      >
        Terms And Condition
      </label>
      {versionDetailLoading && (
        <div className="text-center mt-8">
          <CircularProgress />
        </div>
      )}
      <div
        dangerouslySetInnerHTML={{
          __html: versionDetail?.versionDetails?.term_and_conditions
            ? versionDetail?.versionDetails?.term_and_conditions
            : "",
        }}
        style={{backgroundColor:"white", padding:"10px"}}
      ></div>
    </div>
  );
};

export default TermsAndCondition;
