/* eslint-disable array-callback-return */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useHistory } from "react-router-dom";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import { toAbsoluteUrl } from "../../../../../_helpers";
import moment from "moment";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import StoreIcon from "@material-ui/icons/Store";
import ThumbsUpDownIcon from "@material-ui/icons/ThumbsUpDown";
import TimelineIcon from "@material-ui/icons/Timeline";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import PersonIcon from "@material-ui/icons/Person";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

export function SearchResult({ data , setSearchValue }) {
  const history = useHistory();
  if (!data) {
    return null;
  }

  if (data?.length === 0) {
    return (
      <div
        style={{ maxHeight: "325px", overflow: "hidden" }}
        className="quick-search-wrapper scroll ps ps--active-y"
      >
        <div className="quick-search-result">
          <div className="text-muted d-none">No record found</div>
        </div>
      </div>
    );
  }

  const handleType = (source, id, item) => {
    switch (source) {
      case "franchises":
        history.push(`/settings/franchises/franchise-details/${id}`);
        setSearchValue("")
        break;
      case "vans":
        history.push(`/settings/franchises/franchise-details/${id}`);
        setSearchValue("")
        break;
      case "depots":
        history.push(`/settings/depots/depot-details/${id}`);
        setSearchValue("")
        break;
      case "model_has_roles":
        history.push(`/customer-details/${id}`);
        setSearchValue("")
        break;
      case "orders":
        history.push(`/order-details/${id}`);
        setSearchValue("")
        break;
      case "deals":
        history.push(`/deal-view/${id}`);
        setSearchValue("")
        break;
      case "contacts":
        history.push(`/contact-details/${id}`);
        setSearchValue("")
        break;
      case "users":
        history.push(`/settings/users/user-details/${id}`);
        setSearchValue("")
        break;
      case "roles":
        history.push(`/settings/users/profile-details/${id}`);
        setSearchValue("")
        break;
      case "order_storage_details":
        history.push(`/order-details/${id}?search`);
        setSearchValue("")
        break;
      case "activity_log":
        if (item?.event === "contact") {
          history.push(`/contact-details/${item.subject_id}`);
          setSearchValue("")
        } else {
          history.push(`/order-details/${item?.subject_id}`);
          setSearchValue("")
        }
        break;

      default:
        break;
    }
  };
  const groupedData = data?.reduce((result, item) => {
    const source = item.source;

    if (!result[source]) {
      result[source] = [];
    }

    result[source].push(item);

    return result;
  }, {});
  return (
    <div
      style={{ maxHeight: "70vh", overflow: "hidden" }}
      className="quick-search-wrapper scroll ps ps--active-y "
    >
      <PerfectScrollbar
        options={perfectScrollbarOptions}
        className="scroll"
        style={{ maxHeight: "70vh", position: "relative" }}
      >
        <div className="quick-search-result">
          <div className="row mt-5">
            <div className="card-body" style={{ marginTop: "-5%" }}>
              {groupedData &&
                Object.entries(groupedData).map(([source, items]) => {
                  return (
                    <>
                      <div
                        className="d-flex"
                        style={{
                          margin:
                            source === "orders"
                              ? "-4px 0 8px 0"
                              : "12px 0 8px 0",
                        }}
                      >
                        <div>
                          <div className="d-flex align-items-center">
                            <div className="flex-grow-1">
                              <div className="d-flex align-items-center justify-content-between flex-wrap mt-2">
                                <div className="mr-3">
                                  <span className="d-flex align-items-center text-dark  font-size-h3 font-weight-bold mr-3">
                                    {source === "orders" ? (
                                      <ShoppingCartIcon fontSize="large" />
                                    ) : source === "users" ? (
                                      <PersonIcon fontSize="large" />
                                    ) : source === "franchises" ? (
                                      <ThumbsUpDownIcon fontSize="large" />
                                    ) : source === "activity_log" ? (
                                      <TimelineIcon fontSize="large" />
                                    ) : source === "Storage details" ? (
                                      <LocalShippingIcon fontSize="large" />
                                    ) : source === "depots" ? (
                                      <StoreIcon fontSize="large" />
                                    ) : (
                                      ""
                                    )}
                                    &nbsp;
                                    {source && source === "activity_log"
                                      ? "Activity Log"
                                      : source &&
                                        source.charAt(0).toUpperCase() +
                                          source.slice(1)}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {items.map((item, index) => (
                        <div
                          className="selectHover"
                          onClick={() => handleType(source, item.id, item)}
                        >
                          <div className="separator separator-solid "></div>
                          <div
                            className="d-flex align-items-center flex-wrap row selectHover"
                            style={{ paddingBottom: "6px" , paddingTop:"6px" }}
                          >
                            <div
                              className={`${
                                source === "users"
                                  ? "col-5"
                                  : item.source === "activity_log"
                                  ? "col-8"
                                  : "col-4"
                              } d-flex align-items-center flex-lg-fill mb-1 `}
                            >
                              <span className="mr-4">
                                <span className="svg-icon svg-icon-2x svg-icon-gray-500 mr-1">
                                  {item.source === "activity_log"
                                    ? "Description:"
                                    : ""}
                                </span>
                              </span>
                              <div className="d-flex flex-column ">
                                {item.source !== "activity_log" ? (
                                  <span className="font-size-h6">
                                    {item.name}{" "}
                                    {item.source === "franchises"
                                      ? `(${item.short_name})`
                                      : ""}
                                  </span>
                                ) : (
                                  <span className="d-flex flex-column">
                                    {item.description}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div
                              className={`${
                                source === "users" ? "col-3" : "col-4"
                              } d-flex align-items-center flex-lg-fill mb-1 `}
                            >
                              <span className="d-flex ">
                                <span className="">
                                  <span className="svg-icon svg-icon-2x svg-icon-gray-500 mr-1">
                                    {source === "orders" ||
                                    (item.source === "activity_log" &&
                                      item.event !== "contact")
                                      ? "Job ID:"
                                      : item.source === "activity_log" &&
                                        item.event === "contact"
                                      ? "ID:"
                                      : item.source === "franchises" ||
                                        item.source === "depots"
                                      ? "Phone"
                                      : item.source === "Storage details"
                                      ? "Pod Number:"
                                      : "ID:"}
                                  </span>
                                </span>
                                <div className="d-flex flex-column ">
                                  {source === "orders" ||
                                  (item.source === "activity_log" &&
                                    item.event !== "contact")
                                    ? item.booking_reference
                                    : item.source === "activity_log" &&
                                      item.event === "contact"
                                    ? item.id
                                    : item.source === "franchises" ||
                                      item.source === "depots"
                                    ? item.phone
                                    : item.source === "Storage details"
                                    ? item.pod_number
                                    : item.id}
                                </div>
                              </span>
                            </div>
                            {item.source === "activity_log" && (
                              <div
                                className={`${
                                  item.source === "activity_log"
                                    ? "col-2 "
                                    : "col-4"
                                } d-flex align-items-center flex-lg-fill`}
                              >
                                <span className="d-flex ">
                                  <span className="mr-4">
                                    <span className="svg-icon svg-icon-2x svg-icon-gray-500 mr-1 customTextMuted">
                                      {item.source === "activity_log"
                                        ? item.name
                                        : item.source === "vans"
                                        ? "Make:"
                                        : "Email:"}
                                    </span>
                                  </span>
                                  <div className="d-flex flex-column customTextMuted">
                                    {item.source === "activity_log"
                                      ? ""
                                      : item.email}
                                  </div>{" "}
                                </span>
                              </div>
                            )}
                            <div className="col-4 d-flex align-items-center flex-lg-fill mb-1 ">
                              <span className="">
                                <span className="svg-icon svg-icon-2x svg-icon-gray-500 mr-1 customTextMuted">
                                  {source === "orders" ||
                                  item.source === "Storage details"
                                    ? "Collection Date:"
                                    : "Created Date:"}
                                </span>
                              </span>
                              <div className="d-flex flex-column flex-lg-fill customTextMuted">
                                {source === "orders" ||
                                item.source === "Storage details"
                                  ? moment(item.collection_date).format(
                                      "DD-MM-yyyy"
                                    )
                                  : moment(item.created_at).format(
                                      "DD-MM-yyyy"
                                    )}
                              </div>
                            </div>
                            {item.source !== "activity_log" && (
                              <div
                                className={`${
                                  item.source === "activity_log"
                                    ? "col-4 "
                                    : item.source === "depots"
                                    ? "col-12"
                                    : "col-4"
                                } d-flex align-items-center flex-lg-fill`}
                              >
                                <span className="d-flex ">
                                  <span className="mr-4">
                                    <span className="svg-icon svg-icon-2x svg-icon-gray-500 mr-1 customTextMuted">
                                      {item.source === "activity_log"
                                        ? item.name
                                        : item.source === "vans"
                                        ? "Make:"
                                        : ""}
                                    </span>
                                  </span>
                                  <div className="d-flex flex-column customTextMuted">
                                    {item.source === "activity_log"
                                      ? ""
                                      : item.email}
                                  </div>{" "}
                                </span>
                              </div>
                            )}
                            {item.source === "Storage details" && (
                              <>
                                <div className="col-4 d-flex align-items-center flex-lg-fill ">
                                  <span className="d-flex ">
                                    <span className="">
                                      <span className="svg-icon svg-icon-2x svg-icon-gray-500 mr-1">
                                        {"Job ID:"}
                                      </span>
                                    </span>
                                    <div className="d-flex flex-column ">
                                      {item.booking_reference}
                                    </div>
                                  </span>
                                </div>
                                <div className="col-4 d-flex align-items-center flex-lg-fill ">
                                  <span className="d-flex ">
                                    <span className="">
                                      <span className="svg-icon svg-icon-2x svg-icon-gray-500 mr-1"></span>
                                    </span>
                                    <div className="d-flex flex-column "></div>
                                  </span>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      ))}
                    </>
                  );
                })}
            </div>
          </div>
          {/* begin: Section */}
          {/* <div className="font-size-sm text-primary font-weight-bolder text-uppercase mb-2">
            Documents
          </div> */}
          <div className="mb-10">
            {data && data.length ? (
              data.map((obj, index) => {
                if (!obj.title) return;
                return (
                  <div
                    className="d-flex align-items-center flex-grow-1 mb-2"
                    key={index}
                  >
                    <div className="d-flex flex-column ml-3 mt-2 mb-2">
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => handleType(obj)}
                        className=" font-size-lg font-weight-bold text-dark text-hover-primary"
                      >
                        &#8226;&nbsp;&nbsp;{obj.title}&nbsp;&nbsp;
                        <span className="font-size-sm font-weight-bold text-muted">
                          |&nbsp;&nbsp;{" "}
                          {obj.type === "model_has_roles"
                            ? "CUSTOMER"
                            : obj.type === "roles"
                            ? "PROFILE"
                            : obj.type === "order_storage_details"
                            ? "ORDER"
                            : obj.type.toLocaleUpperCase()}
                        </span>
                      </span>
                    </div>
                  </div>
                );
              })
            ) : (
              <div>
                <p>No Record Found</p>
              </div>
            )}
          </div>
        </div>
      </PerfectScrollbar>
    </div>
  );
}
