import React from "react";
import { Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import { CircularProgress } from "@material-ui/core";

const CustomeDialog = ({
  header,
  mainText,
  dialog,
  setDialog,
  handleAction,
  type,
  formik,
  dialogCondition,
  isLoading,
  extraData,
  returnData,
  loadingDialog,
}) => {
  const intl = useIntl();

  return (
    <Modal show={dialog.type} aria-labelledby="example-modal-sizes-title-lg">
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{header}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>{mainText}</span>
        {extraData && (
          <div>
            {" "}
            {/* {intl.formatMessage({ id: "Do.you.changes.booking" })} */}
            Charges applied
            {loadingDialog ? (
              <div className="text-center mt-8">
                <CircularProgress />
              </div>
            ) : (
              <ul>
                {" "}
                {returnData?.extra_return_price ? (
                  <li>
                    {"Extra Return Price: " + returnData?.extra_return_price}{" "}
                  </li>
                ) : (
                  ""
                )}{" "}
                {returnData?.pod_price ? (
                  <li> {"Pod Price: " + returnData?.pod_price} </li>
                ) : (
                  " "
                )}
                {returnData?.extraMiles?.id ? (
                  <li>
                    {" "}
                    {"Extra Miles Price: " +
                      returnData?.extraMiles?.totalPrice}{" "}
                  </li>
                ) : (
                  " "
                )}
                <li>
                  Total Price:
                  {(+returnData?.extra_return_price || 0) +
                    (+returnData?.pod_price || 0) +
                    (+returnData?.extraMiles?.totalPrice || 0)}
                </li>
              </ul>
            )}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={() => setDialog({ type: false, check: "", id: "" })}
            className="btn btn-light btn-elevate"
            disabled={isLoading ? isLoading : false}
          >
            {intl.formatMessage({ id: "No" })}
          </button>
          <> </>
          <button
            type="button"
            onClick={() => {
              if (type === "order" && !dialogCondition) {
                formik.handleSubmit();
                setDialog({ type: false });
                return;
              } else if (type === "overwrite") {
                handleAction("overwrite");
                return;
              }
              handleAction(dialog?.check, dialog?.id);
            }}
            // onDoubleClick={() => ""}
            className="btn btn-delete btn-elevate"
            disabled={
              isLoading
                ? isLoading
                : false || formik?.isSubmitting
                ? formik?.isSubmitting
                : false
            }
          >
            {intl.formatMessage({ id: "Yes" })}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default CustomeDialog;
