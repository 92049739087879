import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";

const DealCard = ({ item }) => {
  const intl = useIntl();
  const history = useHistory();
  return (
    <div
      className="card card-custom card-stretch gutter-b "
      onClick={() => history.push(`/deal-view/${item?.id}`)}
    >
      <div className="card-body">
        <ul>
          <li>
            <figure>
              <span className="symbol-label ml-2">
                <span className="svg-icon svg-icon-xl svg-icon-primary">
                  <SVG
                    title=" "
                    className="h-25 align-self-end"
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Contact1.svg"
                    )}
                  ></SVG>
                </span>
              </span>
            </figure>
            <h2>
              {item?.user_data?.first_name || "-"}&nbsp;
              {item?.user_data?.last_name || " "}
            </h2>
          </li>
          <li>
            <figure>
              <span className="symbol-label ml-2">
                <span className="svg-icon svg-icon-lg svg-icon-primary">
                  <SVG
                    title=" "
                    className="h-25 align-self-end"
                    src={toAbsoluteUrl("/media/svg/icons/Shopping/Money.svg")}
                  ></SVG>
                </span>
              </span>
            </figure>
            <span className="text-muted">{item?.amount}</span>
          </li>
          <li>
            <figure>
              <span className="symbol-label ml-2">
                <span className="svg-icon svg-icon-lg svg-icon-primary">
                  <SVG
                    title=" "
                    className="h-25 align-self-end"
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Layout/Layout-top-panel-6.svg"
                    )}
                  ></SVG>
                </span>
              </span>
            </figure>
            <span className="text-muted">
              {intl.formatMessage({ id: "Closing.Date" })} :
              <br />
              {item && item.closed_at ? item.closed_at : "-"}
            </span>
          </li>
          <li>
            <figure>
              <span className="symbol-label ml-2">
                <span className="svg-icon svg-icon-lg svg-icon-primary">
                  <SVG
                    title=" "
                    className="h-25 align-self-end"
                    src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}
                  ></SVG>
                </span>
              </span>
            </figure>
            <span className="text-muted">
              {item && item.agent_data && item.agent_data.first_name
                ? item.agent_data.first_name
                : " "}{" "}
              {item && item.agent_data && item.agent_data.last_name
                ? item.agent_data.last_name
                : ""}{" "}
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default DealCard;
