/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

import React, { useState } from "react";
import {
  fetchBillingCardDetail,
  fetchMyOrderTimeline,
} from "../_redux/order/orderAction";

import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import DatePicker from "react-datepicker";
import DiscardDialog from "../../../../_metronic/_partials/widgets/alert/DiscardDialog";
import { EditNextBillingSchema } from "../order-form/validationSchema";
import { Form } from "react-bootstrap";
import { InputGroup } from "react-bootstrap";
import SnackBarTool from "../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { canvaCancel } from "../../../utils/canvaCloseFunction";
import moment from "moment";
import { nextBilling } from "../_redux/order/orderCrud";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { useIntl } from "react-intl";

export function EditNextBilling({ orderDetail, billingCard }) {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [dialogDis, setDialogDis] = useState(false);
  const [loading, setLoading] = useState(false);

  const initialValues = {
    order_id: orderDetail?.id || "",
    franchise_id: orderDetail?.franchise_id || "",
    subscription_id: orderDetail?.subscription_id || "",
    billing_date: "",
    comment: "",
  };

  const handleSubmit = (values, actions) => {
    setSnack({ call: false, type: "", message: "" });
    setLoading(true);
    nextBilling(values)
      .then((res) => {
        setSnack({ call: true, type: "success", message: res.data.message });
        setLoading(false);
        dispatch(fetchMyOrderTimeline(orderDetail?.id));
        dispatch(
          fetchBillingCardDetail(
            orderDetail?.franchise_id,
            orderDetail?.subscription_data?.id
          )
        );
        formik.resetForm();
        canvaCancel("kt_EditNextBilling_panel_close");
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
        setLoading(false);
      });
  };

  const handleReset = () => {};

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: EditNextBillingSchema,
    onSubmit: handleSubmit,
    onReset: handleReset,
  });

  const handleDiscard = () => {
    if (formik.dirty || Object.keys(formik.touched).length !== 0) {
      setDialogDis(true);
    } else {
      canvaCancel("kt_EditNextBilling_panel_close");
      formik.resetForm();
    }
  };

  return (
    <div
      id="kt_EditNextBilling_panel"
      className="offcanvas offcanvas-right pt-5 pb-10"
    >
      {/*begin::Header*/}
      <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
        <div
          className="offcanvas-close mt-n1 pr-5"
          style={{ position: "absolute", top: "15px", right: "10px" }}
        >
          <span
            onClick={() => handleDiscard()}
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
          >
            <i className="ki ki-close icon-sm text-muted"></i>
          </span>
        </div>
        <div id="kt_EditNextBilling_panel_close"></div>
      </div>
      {/*end::Header*/}
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <div
          className="offcanvas-content fade pt-3 pr-5 mr-n5 date-picker scroll active show ps--active-y mt-10 "
          id="kt_quick_panel_logs_kt_EditNextBilling_panel"
        >
          <div>
            <h5>{intl.formatMessage({ id: "Edit.Next.Billing.Date" })}</h5>
          </div>
          <div>
            <Form.Group className="mb-5">
              <Form.Label>
                {intl.formatMessage({ id: "The.next.billing" })}{" "}
                {billingCard?.next_billing_date || ""}{" "}
              </Form.Label>
            </Form.Group>
          </div>
          <div>
            <Form.Group className="mb-5">
              <Form.Label>
                {intl.formatMessage({ id: "Next.Storage.Start.Date" })}
              </Form.Label>
              <InputGroup className="calendr">
                <DatePicker
                  fullWidth
                  fixedHeight
                  className="form-control w-100"
                  wrapperClassName="datepicker"
                  dateFormat="dd-MM-yyyy"
                  minDate={new Date()}
                  selected={Date.parse(formik.values?.billing_date)}
                  onChange={(date) =>
                    formik.setFieldValue(
                      "billing_date",
                      moment(date).format("YYYY-MM-DD")
                    )
                  }
                  placeholderText="DD-MM-YYYY"
                />
                <CalendarTodayIcon />
              </InputGroup>
              {formik.touched.billing_date && formik.errors.billing_date ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.billing_date}
                  </div>
                </div>
              ) : null}
            </Form.Group>
          </div>

          <div>
            <Form.Group className="mb-5">
              <Form.Label>{intl.formatMessage({ id: "Comments" })}</Form.Label>
              <Form.Control
                as="textarea"
                rows="3"
                placeholder="Add a note (optional)"
                className={`form-control  ${getInputClasses("comment")}`}
                name="comment"
                {...formik.getFieldProps("comment")}
              />
              {formik.touched.comment && formik.errors.comment ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.comment}</div>
                </div>
              ) : null}
            </Form.Group>
          </div>
        </div>
        <div className="offcanvas-footer">
          <div className="offcanvas-content text-right ">
            <button
              type="button"
              className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
              onClick={() => handleDiscard()}
            >
              {intl.formatMessage({ id: "CANCEL" })}
            </button>
            <button
              type="submit"
              className="btn btn-primary font-weight-bolder font-size-sm "
              disabled={loading}
            >
              {intl.formatMessage({ id: "SAVE" })}
              {loading && (
                <span className="ml-2 mr-2 spinner spinner-white"></span>
              )}
            </button>
          </div>
        </div>
      </form>
      <DiscardDialog
        setDialogDis={setDialogDis}
        dialogDis={dialogDis}
        formik={formik}
        closeId={"kt_EditNextBilling_panel_close"}
      />
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </div>
  );
}
