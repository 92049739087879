/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  // FormLabel,
} from "@material-ui/core";
import { capitalizeFirstLetter } from "../../../../utils/canvaCloseFunction";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
// import SVG from "react-inlinesvg";
import { MenuItem, Select } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { containerList } from "../../_redux/order/orderCrud";
import SnackBarTool from "../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { shallowEqual, useSelector } from "react-redux";

export const ContainerConfirmModal = ({
  show,
  setShow,
  dispatchReducer,
  state,
  setDialogSite,
}) => {
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const { role } = useSelector(
    (state) => ({
      role: state.auth.user.roles,
    }),
    shallowEqual
  );
  const handleRadioChange = (e) => {
    if (e.target.value === "pod") {
    }
    setValue(e.target.value);
  };
  const handleSubmit = () => {
    setSnack({ call: false, type: "", message: "" });
    dispatchReducer({
      type: "SET_DATA",
      data: { ...state, containerTypeSelect: value, collectionRetrunEmpty: {} },
  });
   
    if (state.containerTypeSelect === "" && value === "") {
      setSnack({
        call: true,
        type: "error",
        message: "Please Select Type",
      });
      return;
    }
    if (value === "container") {
      if (value === "") {
        setSnack({
          call: true,
          type: "error",
          message: "Please Select Type",
        });
        return;
      }
      if (state.containerListSelect === "") {
        setSnack({
          call: true,
          type: "error",
          message: "Please Select Container Size",
        });
        return;
      }
      // if (state.containerPlanListSelect === "") {
      //   setSnack({
      //     call: true,
      //     type: "error",
      //     message: "Please Select Container plan",
      //   });
      //   return;
      // }
      setDialogSite({ open: true });
    }
    if (value === "pod") {
      dispatchReducer({
        type: "SET_DATA",
        data: { ...state, containerListSelect: "" },
    });
    }
    setShow(false);
   
  };
  useEffect(() => {
    if (value === "container") {
      setLoading(true);
      containerList()
        .then((res) => {
          dispatchReducer({
            type: "SET_DATA",
            data: { ...state, containerList: res.data.data },
        });
          setLoading(false);
         
        })
        .catch((err) => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  useEffect(() => {
    if (value === "" && state?.containerTypeSelect !== "") {
      setValue(state?.containerTypeSelect);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  return (
    <Modal
      show={show.open}
      // onHide={() => setShow(false)}
      dialogClassName="modal-90w"
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          Select type
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="position"
            name="position"
            value={value}
            onChange={handleRadioChange}
          >
            {/* (role && role?.length && role[0] === "super-admin") */}
            {/* {state?.plan_type */}
            {role && role?.length && role[0] === "super-admin"
              ? ["pod", "container"]?.map((e) => (
                  <FormControlLabel
                    value={e}
                    control={<Radio color="primary" />}
                    label={
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "17px",
                        }}
                      >
                        {capitalizeFirstLetter(e)}&nbsp;&nbsp;&nbsp;
                        <div>
                          <img
                            src={toAbsoluteUrl(`/img/${e}.png`)}
                            alt="Image"
                            style={{
                              marginRight: "8px",
                              width: "38px",
                              height: "24px",
                            }}
                          />
                        </div>
                      </div>
                    }
                  />
                ))
              : state?.plan_type?.map((e) => (
                  <FormControlLabel
                    value={e}
                    control={<Radio color="primary" />}
                    label={
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "17px",
                        }}
                      >
                        {capitalizeFirstLetter(e)}&nbsp;&nbsp;&nbsp;
                        <div>
                          <img
                            src={toAbsoluteUrl(`/img/${e}.png`)}
                            alt="Image"
                            style={{
                              marginRight: "8px",
                              width: "38px",
                              height: "24px",
                            }}
                          />
                        </div>
                      </div>
                    }
                  />
                ))}
          </RadioGroup>
        </FormControl>
        {((state?.containerTypeSelect === "container" && value !== "pod") ||
          value === "container") && (
          <>
            <Form.Group className="mb-0">
              <Form.Label>Container size</Form.Label>
              <FormControl fullWidth>
                <Select
                  name="container_id"
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                  className={`form-control  `}
                  IconComponent={(props) => (
                    <i {...props}>
                      <KeyboardArrowDownIcon />
                    </i>
                  )}
                  inputProps={{ "aria-label": "Without label" }}
                  displayEmpty
                  //   onBlur={handleBlur}
                  value={state.containerListSelect}
                  onChange={(e) => {
                    dispatchReducer({
                      type: "SET_DATA",
                      data: { ...state, containerListSelect: e.target.value },
                  });
                 
                   
                  
                  
                  }}
                >
                  {loading ? (
                    <MenuItem value="">Loading</MenuItem>
                  ) : (
                    <MenuItem value="">Select</MenuItem>
                  )}
                  {!loading &&
                    state?.containerList &&
                    state?.containerList?.length > 0 &&
                    state.containerList.map((e) => (
                      <MenuItem value={e.id}>{e.id}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Form.Group>
            
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => window.location.reload()}
          // disabled={state.containerTypeSelect === ""}
        >
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Proceed
        </Button>
      </Modal.Footer>
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </Modal>
  );
};
