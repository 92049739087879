import axios from "axios";

export const PRODUCTS_URL = "product";
export const STRIPE_PRODUCT_URL = "stripe-product";
export const PRICING = "/update-price";
export const FRANCHISE_LIST = "/franchise-sector/list?";
export const LIST_UPDATE = "/product-link-franchise";
export const DELETE = "/delete/product";
export const PRODUCT_TIMELINE = "/get-products-timeline";

//product list
export function findProduct(queryParams) {
  return axios.get(
    process.env.REACT_APP_SITE_URL +
      STRIPE_PRODUCT_URL +
      `?per_page=${queryParams.pageSize ||
        queryParams.perPage}&page=${queryParams?.pageNumber ||
        queryParams.page}&sort_dir=${queryParams.sortOrder}&sort_by=${
        queryParams.sortField
      }&search_keyword=${queryParams.filter.search}&filter_by_type=${
        queryParams.filter.search_by_type
      }&filter_by_status=${queryParams.filter.search_by_status}&franchise_id=${
        queryParams.franchiseId ? queryParams.franchiseId : ""
      }`
  );
}
//create product api
export function createProduct(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + STRIPE_PRODUCT_URL, data);
}

//update product api
export function updateProduct(data, id) {
  return axios.post(
    process.env.REACT_APP_SITE_URL + STRIPE_PRODUCT_URL + `/${id}`,
    data
  );
}

//get product detail
export function getProductDetail(id, f_id) {
  return axios.get(
    process.env.REACT_APP_SITE_URL +
      STRIPE_PRODUCT_URL +
      `/${id}?franchise_id=${f_id ? f_id : ""}`
  );
}

export function updateStripeprice(data) {
  return axios.post(
    process.env.REACT_APP_SITE_URL + `stripe-product-price`,
    data
  );
}
//update pricing of product api
export function updatePricing(data, id) {
  return axios.post(
    process.env.REACT_APP_SITE_URL + PRODUCTS_URL + PRICING + `/${id}`,
    data
  );
}

//get franchise list api
export function getfranchiseList(id) {
  return axios.get(
    process.env.REACT_APP_SITE_URL +
      PRODUCTS_URL +
      FRANCHISE_LIST +
      `country_id=${id}`
  );
}

//franchise update api
export function updateFranchise(data, id) {
  return axios.post(
    process.env.REACT_APP_SITE_URL + PRODUCTS_URL + LIST_UPDATE + `/${id}`,
    data
  );
}

//delete product api
export function deleteProduct(data) {
  return axios.post(
    process.env.REACT_APP_SITE_URL + STRIPE_PRODUCT_URL + DELETE,
    data
  );
}

//my product timeline
export function findMyProductTimeline(data) {
  return axios.get(
    process.env.REACT_APP_SITE_URL +
      STRIPE_PRODUCT_URL +
      PRODUCT_TIMELINE +
      `?product_id=${data.productId}&franchise_id=${data.franchiseId}`
  );
}
