export const ProductStatusCssClasses = ["success", "info", ""];
export const ProductStatusTitles = ["Selling", "Sold"];
export const ProductConditionCssClasses = ["success", "danger", ""];
export const ProductConditionTitles = ["New", "Used"];
export const defaultSorted = [{ dataField: "", order: "" }];
export const sizePerPageList = [
  { text: "20", value: 20 },
  { text: "50", value: 50 },
  { text: "100", value: 100 },
];
export const initialFilter = {
  filter: {
    search: "",
    search_view: "",
    search_agent: "",
    search_franchise: "",
    search_status: "",
    from_date: "",
    to_date: "",
  },
  sortOrder: "", // asc||desc
  sortField: "",
  pageNumber: 1,
  pageSize: 20,
};

export const initsNewOrder = [
  "kt_quick_panelone",
  "kt_ContactDealDetail_panel",
  "kt_quick_panelone",
  "kt_Manual_Discount_panel",
];
