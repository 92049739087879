import { createSlice } from "@reduxjs/toolkit";

const initialDealsState = {
  list: {
    listLoading: false,
    loading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    productForEdit: undefined,
    lastError: null,
  },
  quotesList: {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    productForEdit: undefined,
    lastError: null,
  },
  dealDetail: {},
  feedList: [],
  notesList: { entities: [], loading: false },
  tasksList: { entities: [], loading: false },
  emailsList: { entities: [], loading: false },
  smsList: { entities: [], loading: false },
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const dealsSlice = createSlice({
  name: "deals",
  initialState: initialDealsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    //deal list
    dealsFetched: (state, action) => {
      const { totalCount, entities, loading } = action.payload;
      state.list.listLoading = false;
      state.list.error = null;
      state.list.entities = entities;
      state.list.totalCount = totalCount;
      state.list.loading = loading;
    },

    //deal quotes list
    dealsQuotes: (state, action) => {
      const { totalCount, entities } = action.payload;

      state.quotesList.quotesListLoading = false;
      state.quotesList.error = null;
      state.quotesList.entities = entities;
      state.quotesList.totalCount = totalCount;
    },
    //deal detail
    dealDetailFetched: (state, action) => {
      const { data, loading } = action.payload;
      if (data?.user_data?.address) {
        data.user_data.address = data?.user_data?.address
          ?.replaceAll(", ", ",")
          ?.replaceAll(",, ", ",")
          ?.replaceAll(",,,", ",");
      }
      state.dealDetail = data;
      state.dealDetail.loading = loading;
    },
    //deal feed list
    dealFeedList: (state, action) => {
      const { data } = action.payload;
      state.feedList = data;
    },
    //deal notes list
    dealNotesList: (state, action) => {
      const { data, loading } = action.payload;
      state.notesList.entities = data;
      state.notesList.loading = loading;
    },
    //deal tasks list
    dealTasksList: (state, action) => {
      const { data, loading } = action.payload;
      state.tasksList.entities = data;
      state.tasksList.loading = loading;
    },
    //deal email list
    dealEmailsList: (state, action) => {
      const { data, loading } = action.payload;
      state.emailsList.entities = data;
      state.emailsList.loading = loading;
    },
    //deal sms list
    dealSmsList: (state, action) => {
      const { data, loading } = action.payload;
      state.smsList.entities = data;
      state.smsList.loading = loading;
    },
  },
});
