import axios from "axios";

export const DEPOTS_URL = "api/depots";
export const DEPOTS_URL_LIVE = "depot";
export const FRANCHISE_USING_DEPOT = "depot-franchise-list";
export const FRANCHISE_USING_DEPOT_STATUS = "depot-franchise-status-update";
export const FRANCHISE_DEPOT_LIST = "franchise-list";
export const ADD_FRANCHISE = "depot-franchise";
export const DELETE = "depot/delete-depot";
export const DEFAULT_SELECT = "set-default-depot";

export function createDepot(data) {
  // mock.restore();

  return axios.post(process.env.REACT_APP_SITE_URL + DEPOTS_URL_LIVE, data);
}

export function editDepot(data, id) {
  // mock.restore();

  return axios.post(
    process.env.REACT_APP_SITE_URL + DEPOTS_URL_LIVE + `/${id}?_method=PATCH`,
    data
  );
}
export function addDepotfranchise(data) {
  // mock.restore();

  return axios.post(process.env.REACT_APP_SITE_URL + ADD_FRANCHISE, data);
}
// CREATE =>  POST: add a new product to the server
export function createProduct(product) {
  return axios.post(DEPOTS_URL, { product });
}

// READ
export function getAllProducts() {
  return axios.get(process.env.REACT_APP_SITE_URL + DEPOTS_URL_LIVE);
}
export function findDepot(queryParams) {
  return axios.get(
    `${process.env.REACT_APP_SITE_URL + DEPOTS_URL_LIVE}?page=${
      queryParams.pageNumber
    }&per_page=${queryParams.pageSize}&sort_by=${
      queryParams.sortField
    }&sort_dir=${queryParams.sortOrder}&search_keyword=${
      queryParams.filter.search_keyword
    }&status=${queryParams.filter.status}&search_franchise=${
      queryParams.filter.search_franchise
    }`
  );
}
export function findDepotDetail(queryParams) {
  return axios.get(
    `${process.env.REACT_APP_SITE_URL + DEPOTS_URL_LIVE}/${queryParams}`
  );
}
export function findFranchiseUsingDepot(queryParams) {
  return axios.get(
    `${process.env.REACT_APP_SITE_URL +
      FRANCHISE_USING_DEPOT}?depot_id=${queryParams}`
  );
}
export function findFranchiseDepotList(queryParams) {
  return axios.get(
    `${process.env.REACT_APP_SITE_URL +
      FRANCHISE_DEPOT_LIST}?depot_id=${queryParams}`
  );
}
export function findFranchiseDepotStatus(data) {
  return axios.post(
    `${process.env.REACT_APP_SITE_URL + FRANCHISE_USING_DEPOT_STATUS}`,
    data
  );
}
export function getProductById(productId) {
  return axios.get(`${DEPOTS_URL}/${productId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findProducts(queryParams) {
  return axios.post(`${DEPOTS_URL}/find`, { queryParams });
}

// UPDATE => PUT: update the procuct on the server
export function updateProduct(product) {
  return axios.put(`${DEPOTS_URL}/${product.id}`, { product });
}

// UPDATE Status
export function updateStatusForProducts(ids, status) {
  return axios.post(`${DEPOTS_URL}/updateStatusForProducts`, {
    ids,
    status,
  });
}

// DELETE => delete the product from the server
export function deleteProduct(productId) {
  return axios.delete(`${DEPOTS_URL}/${productId}`);
}

// DELETE Products by ids
export function deleteProducts(ids) {
  return axios.post(`${DEPOTS_URL}/deleteProducts`, { ids });
}

//delete depot
export function deleteDepots(ids) {
  return axios.post(`${process.env.REACT_APP_SITE_URL + DELETE}`, ids);
}

export function defaultDepot(data) {
  return axios.post(`${process.env.REACT_APP_SITE_URL + DEFAULT_SELECT}`, data);
}
