/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { Modal } from "react-bootstrap";
import React, { useState } from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import SnackBarTool from "../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import {
  fetchBillingCardDetail,
  // fetchOrderSummary,
} from "../_redux/order/orderAction";
import { LoadingCustome } from "../../../../_metronic/_partials/controls/LoadingCustome";
// import { CompetitorModalSchema } from "../order-form/validationSchema";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  // FormLabel,
} from "@material-ui/core";
import { autoCollect } from "../_redux/order/orderCrud";
import { capitalizeFirstLetter } from "../../../utils/canvaCloseFunction";
const AutoCollectModal = ({ dialog, setDialog, orderDetail, billingCard }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const initialValues = {
    order_id: orderDetail?.id || "",
    auto_collection: billingCard?.subscription_auto_collection || "",
  };
  //   const { accessFeeList } = useSelector(
  //     (state) => ({
  //       accessFeeList: state.order.bookingAccessCharges,
  //     }),
  //     shallowEqual
  //   );

  // Call on mount of componet to get Booking
  //   useEffect(() => {
  //     if (orderDetail?.id) {
  //       dispatch(
  //         fetchBookingAccessCharges(orderDetail?.id, orderDetail?.franchise_id)
  //       );
  //     }
  //   }, [dispatch, orderDetail?.id]);

  const handleSubmit = (values) => {
    setSnack({
      call: false,
      type: "",
      message: "",
    });
    setIsLoading(true);
    autoCollect(values)
      .then((res) => {
        dispatch(
          fetchBillingCardDetail(
            orderDetail?.franchise_id,
            orderDetail?.subscription_data?.id,
            "",
            orderDetail?.customer_chargebee_id,
            orderDetail?.id
          )
        );
        setIsLoading(false);
        setSnack({
          call: true,
          type: "success",
          message: res?.data?.message || "",
        });
        setTimeout(() => {
          setDialog({ check: false });
          setSnack({
            call: false,
            type: "",
            message: "",
          });
        }, 3000);
      })
      .catch((error) => {
        setIsLoading(false);
        setSnack({
          call: true,
          type: "error",
          message: error?.response?.data?.message || "",
        });
      });
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    // validationSchema: CompetitorModalSchema,
    onSubmit: handleSubmit,
  });

  const handleClose = () => {
    setDialog({ check: false });
  };
  const labelSame = (
    <div className="mb-4">
      <h6>
        Same as Customer(
        {capitalizeFirstLetter(billingCard?.customer_auto_collection || "")})
      </h6>
      <span>Auto-collection will be turned ON or OFF based on this </span>
      <span>customer's configuration</span>
    </div>
  );
  const labelOn = (
    <div className="mb-4">
      <h6>On</h6>
      <span>Whenever an invoice is generated, the customer's payment </span>
      <span>method will be charged automatically.</span>
    </div>
  );
  const labelOff = (
    <div>
      <h6>Off</h6>
      <span>When an invoice is generated, you need to collect and record </span>
      <span>payments manually.</span>
    </div>
  );
  return (
    <Modal
      show={dialog.check}
      aria-labelledby="example-modal-sizes-title-lg"
      onHide={handleClose}
    >
      <Modal.Header className="d-flex justify-content-between">
        <Modal.Title id="example-modal-sizes-title-lg">
          Edit Auto Collection
        </Modal.Title>
        <span
          className="btn btn-xs btn-icon btn-light btn-hover-primary"
          onClick={handleClose}
        >
          <i className="ki ki-close icon-sm text-muted"></i>
        </span>
      </Modal.Header>
      <form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          {isLoading && <LoadingCustome />}
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="gender"
              name="auto"
              value={formik.values?.auto_collection}
              onChange={(e) => {
                formik.setFieldValue("auto_collection", e.target.value);
              }}
            >
              <FormControlLabel
                value=""
                control={<Radio />}
                label={labelSame}
              />
              <FormControlLabel
                value="on"
                control={<Radio />}
                label={labelOn}
              />
              <FormControlLabel
                value="Off"
                control={<Radio />}
                label={labelOff}
              />
            </RadioGroup>
          </FormControl>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              onClick={() => handleClose()}
              className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
              //   disabled={isLoading}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary font-weight-bolder font-size-sm "
            >
              Save
            </button>
          </div>
        </Modal.Footer>
      </form>
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </Modal>
  );
};

export default AutoCollectModal;
