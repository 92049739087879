// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html

import * as actions from "../_redux/customer/customerAction";
import * as uiHelpers from "../CustomerUIHelpers";

import React, { useEffect, useMemo } from "react";
import {
  getHandlerTableChange,
  getSelectRow,
  headerSortingClasses,
  sortCaret,
} from "../../../../_metronic/_helpers";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import BootstrapTable from "react-bootstrap-table-next";
import NoRecord from "../../../../_metronic/_partials/widgets/NoRecord";
import { Pagination } from "../../../../_metronic/_partials/controls";
import { useCustomerUIContext } from "../CustomerUIContext";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";

const CustomerTable = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  let history = useHistory();

  const customerUIContext = useCustomerUIContext();
  const customerUIProps = useMemo(() => {
    return {
      ids: customerUIContext?.ids,
      setIds: customerUIContext?.setIds,
      queryParams: customerUIContext?.queryParams,
      setQueryParams: customerUIContext?.setQueryParams,
      openEditCustomerPage: customerUIContext?.openEditCustomerPage,
      openDeleteCustomerDialog: customerUIContext?.openDeleteCustomerDialog,
    };
  }, [customerUIContext]);

  const { currentState } = useSelector(
    (state) => ({ currentState: state.customer.list }),
    shallowEqual
  );

  const { totalCount, entities, listLoading } = currentState;

  useEffect(() => {
    // clear selections list
    // server call by queryParams
    customerUIProps.setIds([]);
    dispatch(actions.fetchCustomerList(customerUIProps.queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerUIProps.queryParams, dispatch]);

  useEffect(() => {
    return () => {
      customerUIProps.setQueryParams(uiHelpers.initialFilter);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      dataField: "users.first_name",
      text: intl.formatMessage({ id: "Customer.Name" }),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      headerStyle: { width: "2%" },
      formatter: (e, data) => {
        return (
          <span>
            {data?.first_name || "-"}&nbsp;{data?.last_name || ""}
          </span>
        );
      },
    },
    {
      dataField: "phone",
      text: intl.formatMessage({ id: "PHONE" }),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },

    {
      dataField: "email",
      text: intl.formatMessage({ id: "Email" }),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "franchises.name",
      text: intl.formatMessage({ id: "Franchise" }),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (e, data) => {
        return <span>{data?.franchise || "-"}</span>;
      },
    },
    {
      dataField: "agent.first_name",
      text: intl.formatMessage({ id: "Agent" }),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (e, data) => {
        return (
          <span>
            {data?.agent_id === data?.id ? "Online" : data?.agent || "-"}
          </span>
        );
      },
    },
  ];

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: customerUIProps.queryParams?.pageSize,
    page: customerUIProps.queryParams?.pageNumber,
  };
  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      history.push(`/customer-details/${row.id}`);
    },
  };
  // const rowEvents = {
  //   onClick: (e, row, rowIndex) => {
  //     window.open(`/customer-details/${row.id}`, "_blank");
  //   },
  // };

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
              entities={entities}
            >
              <BootstrapTable
                rowEvents={rowEvents}
                hover
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="id"
                selectRow={getSelectRow({
                  entities,
                  ids: customerUIProps.ids,
                  setIds: customerUIProps.setIds,
                })}
                defaultSorted={uiHelpers.defaultSorted}
                data={entities === null ? [] : entities}
                onTableChange={getHandlerTableChange(
                  customerUIProps.setQueryParams
                )}
                columns={columns}
                {...paginationTableProps}
              ></BootstrapTable>
              {entities && entities?.length ? "" : <NoRecord />}
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
};

export default CustomerTable;
