import React, { useEffect, useMemo, useState } from "react";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import { allTasks, stages, view } from "../_mocks/staticData";
import { shallowEqual, useSelector, useDispatch } from "react-redux";

import { Formik } from "formik";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { dealsFranchiseListing } from "../../ContactDeals/_redux/deals/dealsCrud";
import { isEqual } from "lodash";
import { useIntl } from "react-intl";
import { useQTasksUIContext } from "../TasksUIContext";
import { CustomeSearch } from "../../../../_metronic/_partials/controls/CustomeSearch";
import { fetchAgent } from "../../ContactDeals/_redux/contact/contactAction";

const prepareFilter = (queryParams, values) => {
  const {
    searchText,
    search_type,
    search_view,
    search_owner,
    search_franchise,
    search_stage,
    priority,
    source
  } = values;
  const newQueryParams = { ...queryParams };
  const filter = {};

  filter.search = searchText ? searchText : "";
  filter.search_view = search_view ? search_view : "";
  filter.search_owner = search_owner ? search_owner : "";
  filter.search_franchise = search_franchise ? search_franchise : "";
  filter.priority = priority ? priority : "";
  filter.search_type = search_type ? search_type : "";
  filter.search_stage = search_stage ? search_stage : "";
  filter.source = source ? source : ""

  newQueryParams.filter = filter;
  return newQueryParams;
};
export function TasksFilter({ check }) {
  const intl = useIntl();
  const tasksUIContext = useQTasksUIContext();

  const [franchises, setFranchise] = useState([]);

  const { currentState } = useSelector(
    (state) => ({ currentState: state.contact.agent }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const tasksUIProps = useMemo(() => {
    return {
      setQueryParams: tasksUIContext.setQueryParams,
      queryParams: tasksUIContext.queryParams,
    };
  }, [tasksUIContext]);

  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(tasksUIProps.queryParams, values);

    if (!isEqual(newQueryParams, tasksUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      tasksUIProps.setQueryParams(newQueryParams);
    }
  };

  const handleFranchiseList = () => {
    dealsFranchiseListing().then((res) => {
      setFranchise(res.data.data);
    });
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      handleFranchiseList();
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);
  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(fetchAgent());
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [dispatch]);
  return (
    <>
      <Formik
        initialValues={{
          search_type: "", // values => All=""/Susspended=0/Active=1/Pending=2
          search_view: "",
          search_owner: "",
          search_franchise: "", // values => All=""/Business=0/Individual=1
          searchText: "",
          search_stage: "",
          priority: "",
          source: ""
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,

          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row d-flex justify-content-between">
              {/* <div className="col-lg-10">
                <h5 class="text-dark font-weight-bold my-2 mr-5">Quotes</h5>
              </div> */}
              <div className="col-lg-3 order-flt">
                <span className="mt-3">
                  Source:&nbsp;{" "}
                </span>
                <FormControl fullWidth>
                  <Select
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                    className="form-control"
                    onChange={(e) => {
                      setFieldValue("source", e.target.value);

                      handleSubmit();
                    }}
                    name="source"
                    onBlur={handleBlur}
                    value={values.source}
                    displayEmpty
                    IconComponent={(props) => (
                      <i {...props}>
                        <KeyboardArrowDownIcon />
                      </i>
                    )}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="">
                      Select
                    </MenuItem>
                    <MenuItem value="Online">
                      Online
                    </MenuItem>
                    <MenuItem value="V2">
                      V2
                    </MenuItem>


                  </Select>
                </FormControl>
              </div>
              <div className="col-lg-2 order-flt mt-2">
                <CustomeSearch
                  intl={intl}
                  handleBlur={handleBlur}
                  values={values}
                  setFieldValue={setFieldValue}
                  handleSubmit={handleSubmit}
                  topData={"22px"}
                />
                {/* <div style={{ position: "relative" }}>
                  <input
                    style={{ paddingRight: "40px" }}
                    type="text"
                    className="form-control"
                    name="searchText"
                    placeholder={intl.formatMessage({ id: "Search" })}
                    onBlur={handleBlur}
                    value={values.searchText}
                    onChange={(e) => {
                      setFieldValue("searchText", e.target.value);
                      handleSubmit();
                    }}
                  />
                  <i
                    style={{
                      position: "absolute",
                      top: "22px",
                      transform: "translateY(-50%)",
                      right: "15px",
                    }}
                    className="fa fa-search taskSearch"
                    aria-hidden="true"
                  ></i>
                </div> */}
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
