import React from "react";
import { CustomePaginationLinks } from "./CustomePaginationLinks";
import { PaginationLinks } from "./PaginationLinks";
import { PaginationToolbar } from "./PaginationToolbar";

export function Pagination(props) {
  const {
    children,
    isLoading,
    paginationProps,
    entities,
    customePagination,
    loading,
  } = props;
  return (
    <>
      {children}
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        {customePagination ? (
          <CustomePaginationLinks
            paginationProps={paginationProps}
            customePagination={customePagination}
            loading={loading}
          />
        ) : (
          <PaginationLinks paginationProps={paginationProps} />
        )}

        <PaginationToolbar
          isLoading={isLoading}
          paginationProps={paginationProps}
          entities={entities}
          customePagination={customePagination}
        />
      </div>
    </>
  );
}
