/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

import React, { useState } from "react";
import {
  fetchInvoiceList,
  fetchMyOrderTimeline,
  fetchPaymentInvoice,
} from "../_redux/order/orderAction";

import { AdhocSchema } from "../order-form/validationSchema";
// import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
// import DatePicker from "react-datepicker";
import DiscardDialog from "../../../../_metronic/_partials/widgets/alert/DiscardDialog";
import { Form } from "react-bootstrap";
// import { InputGroup } from "react-bootstrap";
import SnackBarTool from "../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { adhocChargeAdd } from "../_redux/order/orderCrud";
import { canvaCancel } from "../../../utils/canvaCloseFunction";
// import moment from "moment";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { useIntl } from "react-intl";

export function AdhocCharge({ orderDetail }) {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [loading, setLoading] = useState(false);
  const [dialogDis, setDialogDis] = useState(false);

  const initialValues = {
    amount: "",
    order_id: orderDetail?.id || "",
    franchise_id: orderDetail?.franchise_id || "",
    subscription_id: orderDetail?.subscription_id || "",
    // date_from: "",
    // date_to: "",
    invoice_option: "",
    description: "",
    currency_code: orderDetail?.currency || "",
  };

  const handleSubmit = (values, actions) => {
    setSnack({ call: false, type: "", message: "" });
    setLoading(true);
    adhocChargeAdd(values)
      .then((res) => {
        setSnack({ call: true, type: "success", message: res.data.message });
        setLoading(false);
        dispatch(
          fetchPaymentInvoice(
            orderDetail?.id,
            orderDetail?.franchise_id,
            orderDetail?.subscription_id
          )
        );
        dispatch(fetchMyOrderTimeline(orderDetail?.id));
        dispatch(
          fetchInvoiceList(
            orderDetail?.franchise_id,
            orderDetail?.id,
            orderDetail?.subscription_id
          )
        );
        setTimeout(() => {
          canvaCancel("kt_EditAdhocCharge_panel_close");
          formik.resetForm();
        }, 2000);
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",
          message: error?.response?.data?.message,
        });
        setLoading(false);
      });
    // actions.resetForm({ values: "" });
  };

  const handleReset = () => {};

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: AdhocSchema,
    onSubmit: handleSubmit,
    onReset: handleReset,
  });

  const handleClose = () => {
    if (formik.dirty && Object.keys(formik.touched).length !== 0) {
      setDialogDis(true);
    } else {
      canvaCancel("kt_EditAdhocCharge_panel_close");
      formik.resetForm();
    }
  };

  return (
    <div
      id="kt_EditAdhocCharge_panel"
      className="offcanvas offcanvas-right pt-5 pb-10"
    >
      {/*begin::Header*/}
      <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
        <div
          className="offcanvas-close mt-n1 pr-5"
          style={{ position: "absolute", top: "15px", right: "10px" }}
        >
          <span
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            onClick={() => handleClose()}
          >
            <i className="ki ki-close icon-sm text-muted"></i>
          </span>
        </div>
        <div id="kt_EditAdhocCharge_panel_close"></div>
      </div>
      {/*end::Header*/}
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <div className="offcanvas-content date-picker ">
          <div>
            <h5> {intl.formatMessage({ id: "Ad.Hoc.Charge" })}</h5>
          </div>
          <div
            className="fade pt-3 pr-5 mr-n5 mt-8  scroll active show ps--active-y "
            id="kt_quick_panel_logs_kt_EditNextBilling_panel"
          >
            <div>
              <Form.Group className="mb-5">
                <Form.Label> {intl.formatMessage({ id: "Amount" })}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  className={`form-control  ${getInputClasses("amount")}`}
                  name="amount"
                  {...formik.getFieldProps("amount")}
                />
                {formik.touched.amount && formik.errors.amount ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.amount}</div>
                  </div>
                ) : null}
              </Form.Group>
            </div>
            {/* <div>
              <Form.Group className="mb-5">
                <Form.Label>
                  {" "}
                  {intl.formatMessage({ id: "From.Date" })}
                </Form.Label>
                <InputGroup className="calendr">
                  <DatePicker
                    fullWidth
                    fixedHeight
                    className={`form-control w-100`}
                    wrapperClassName="datepicker"
                    dateFormat="dd-MM-yyyy"
                    minDate={new Date()}
                    selected={Date.parse(formik.values?.date_from)}
                    onChange={(date) =>
                      formik.setFieldValue(
                        "date_from",
                        moment(date).format("DD-MM-yyyy")
                      )
                    }
                    // popperClassName="date-picker-reports"
                    placeholderText="DD-MM-YYYY"
                  />
                  <CalendarTodayIcon />
                </InputGroup>
                {formik.touched.date_from && formik.errors.date_from ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.date_from}
                    </div>
                  </div>
                ) : null}
              </Form.Group>
            </div>
            <div>
              <Form.Group className="mb-5">
                <Form.Label>
                  {" "}
                  {intl.formatMessage({ id: "To.Date" })}
                </Form.Label>
                <InputGroup className="calendr">
                  <DatePicker
                    fullWidth
                    fixedHeight
                    className={`form-control w-100}`}
                    wrapperClassName="datepicker"
                    dateFormat="dd-MM-yyyy"
                    minDate={new Date()}
                    selected={Date.parse(formik.values?.date_to)}
                    onChange={(date) =>
                      formik.setFieldValue(
                        "date_to",
                        moment(date).format("DD-MM-yyyy")
                      )
                    }
                    // popperClassName="date-picker-reports"
                    placeholderText="DD-MM-YYYY"
                  />
                  <CalendarTodayIcon />
                </InputGroup>
                {formik.touched.date_to && formik.errors.date_to ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.date_to}</div>
                  </div>
                ) : null}
              </Form.Group>
            </div> */}
            <div>
              <Form.Group className="mb-5">
                <Form.Label>
                  {" "}
                  {intl.formatMessage({ id: "Description" })}
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows="3"
                  className={`form-control  ${getInputClasses("description")}`}
                  name="description"
                  {...formik.getFieldProps("description")}
                />
                {formik.touched.description && formik.errors.description ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.description}
                    </div>
                  </div>
                ) : null}
              </Form.Group>
            </div>
            <div>
              <Form.Group className="mb-5">
                <Form.Label>
                  {" "}
                  {intl.formatMessage({ id: "Invoicing.options" })}
                </Form.Label>
                <Form.Check
                  className="mb-4"
                  type="radio"
                  label="Invoice immediately"
                  name="formHorizontalRadios"
                  id="formHorizontalRadios1"
                  checked={
                    formik.values?.invoice_option === "invoice_immediately"
                  }
                  onClick={() =>
                    formik.setFieldValue(
                      "invoice_option",
                      "invoice_immediately"
                    )
                  }
                />
                <Form.Check
                  type="radio"
                  label="Add to unbilled charges"
                  name="formHorizontalRadios"
                  id="formHorizontalRadios2"
                  checked={formik.values?.invoice_option === "unbilled"}
                  onClick={() =>
                    formik.setFieldValue("invoice_option", "unbilled")
                  }
                />
                {formik.touched.invoice_option &&
                formik.errors.invoice_option ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.invoice_option}
                    </div>
                  </div>
                ) : null}
              </Form.Group>
            </div>
          </div>
        </div>
        <div className="offcanvas-footer">
          <div className="offcanvas-content text-right">
            <button
              type="button"
              className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
              onClick={() => handleClose()}
            >
              {intl.formatMessage({ id: "CANCEL" })}
            </button>

            <button
              type="submit"
              disabled={loading}
              className="btn btn-primary font-weight-bolder font-size-sm "
            >
              {intl.formatMessage({ id: "SAVE" })}
              {loading && (
                <span className="ml-3 mr-3 spinner spinner-white"></span>
              )}
            </button>
          </div>
        </div>
      </form>
      <DiscardDialog
        setDialogDis={setDialogDis}
        dialogDis={dialogDis}
        formik={formik}
        closeId={"kt_EditAdhocCharge_panel_close"}
      />
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </div>
  );
}
