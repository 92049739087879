/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

import * as Yup from "yup";

import React, { useEffect, useState } from "react";
import { createUser, updateUser } from "../../../_redux/user/userCrud";
import {
  fetchProfileDrop,
  fetchUser,
  fetchUserDetail,
} from "../../../_redux/user/userAction";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import ActiveDialog from "../user-dialog/ActiveDialog";
import AvailableDialog from "../user-dialog/AvailableDialog";
import DiscardDialog from "../../../../../../_metronic/_partials/widgets/alert/DiscardDialog";
import { NewUserSchema } from "../user-form/validateSchema";
import SnackBarTool from "../../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import UserForm from "../user-form/UserForm";
import { canvaCancel } from "../../../../../utils/canvaCloseFunction";
import { fetchActiveCountry } from "../../../_redux/country/countryAction";
import { fetchFranchisesCountry } from "../../../_redux/franchises/franchisesActions";
import { useFormik } from "formik";
import { useIntl } from "react-intl";
import { useUserUIContext } from "../UserUIContext";

export function NewUserCanva({ check, type, title, detailData }) {
  // Destructure intl object for formatting translations
  const intl = useIntl();
  const dispatch = useDispatch();
  const userUIContext = useUserUIContext();

  const [active, setActive] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [available, setAvailable] = useState(false);
  const [dialogAva, setDialogAva] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dialogDis, setDialogDis] = useState(false);
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });

  useEffect(() => {
    dispatch(fetchActiveCountry());
    dispatch(fetchProfileDrop());
  }, [dispatch]);
  const { franchiseList } = useSelector(
    (state) => ({
      franchiseList: state.franchise.countryFranchise,
    }),
    shallowEqual
  );

  const initialValues = {
    first_name: detailData?.first_name || "",
    last_name: detailData?.last_name || "",
    email: detailData?.email || "",
    username: detailData?.username || "",
    password: "",
    phone: detailData?.phone || "",
    franchise_id: detailData?.franchise_id || "",
    country_id: detailData?.country?.id || 190 || "",
    profile_id: detailData?.profile_id || "",
    status: detailData?.status || 0,
    is_available: detailData?.is_available || "0",
  };

  const handleSubmit = (values, actions) => {
    setLoading(true);
    setSnack({ call: false, type: "" });
    if (type === "edit") {
      updateUser(values, detailData?.id)
        .then((res) => {
          // canvaCancel("kt_create_tasks_panel_close");
          dispatch(fetchUserDetail(detailData?.id));
          setSnack({ call: true, type: "success", message: res.data.message });
          canvaCancel("kt_new_user_panel_close");

          setLoading(false);
          formik.resetForm();
          setActive(false);
        })
        .catch((error) => {
          setSnack({
            call: true,
            type: "error",
            message: error?.response?.data?.message,
            errors: error?.response?.data?.message,
          });
          setLoading(false);
        });
    } else {
      createUser(values)
        .then((res) => {
          // canvaCancel("kt_create_tasks_panel_close");
          setSnack({ call: true, type: "success", message: res.data.message });
          canvaCancel("kt_new_user_panel_close");

          dispatch(fetchUser(userUIContext.queryParams));
          setLoading(false);
          formik.resetForm();
        })
        .catch((error) => {
          setSnack({
            call: true,
            type: "error",
            message: error?.response?.data?.message,
            errors: error?.response?.data?.message,
          });
          setLoading(false);
        });
    }
    // actions.resetForm({ values: "" });
  };

  const handleReset = () => { };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: type === "edit" ? "" : NewUserSchema(intl, Yup),
    onSubmit: handleSubmit,
    onReset: handleReset,
  });

  useEffect(() => {
    if (formik?.values?.country_id) {
      dispatch(fetchFranchisesCountry(formik?.values?.country_id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.country_id]);

  const handleCountry = (e) => {
    formik.setFieldValue("country_id", e.target.value);
    setTimeout(() => {
      formik.setFieldValue("franchise_id", []);
    }, 500);
  };

  useEffect(() => {
    if ((formik?.values?.profile_id === 1) || (formik?.values?.profile_id === 5)) {
      formik.setFieldValue("franchise_id", "");
      setTimeout(() => {
        formik.setFieldValue("country_id", "");
      }, 500);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.profile_id]);

  const handleClose = () => {
    if (formik.dirty) {
      setDialogDis(true);
    } else {
      canvaCancel("kt_new_user_panel_close");
      setActive(false);
      formik.resetForm();
    }
  };

  useEffect(() => {
    if (type === "edit" && formik.values.status) {
      setActive(formik.values.status === 1 ? true : false);
    }
    if (type === "edit" && formik.values.is_available) {
      setAvailable(formik.values.is_available === 1 ? true : false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.status, formik.values.is_available]);

  return (
    <div
      id="kt_new_user_panel"
      className={`offcanvas offcanvas-right pt-5 pb-10 ${check ? "offcanvas-on" : ""
        }`}
    >
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        {/*begin::Header*/}
        <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
          <div
            className="offcanvas-close mt-n1  pr-5"
            style={{ position: "absolute", top: "15px", right: "10px" }}
          >
            <button
              type="button"
              className="btn btn-xs btn-icon btn-light btn-hover-primary"
              onClick={() => handleClose()}
            >
              <i className="ki ki-close icon-xs text-muted"></i>
            </button>
            <div id="kt_new_user_panel_close"></div>
          </div>
        </div>

        {/*end::Header*/}

        {/*begin::Content*/}
        <div className=" px-10">
          <div>
            <h5>{title}</h5>
          </div>
          <div className="tab-content">
            <div
              id="kt_quick_panel_logs_kt_new_user_panel"
              role="tabpanel"
              className={`fade pt-3 pr-5 mr-n5 scroll  active show `}
            >
              <UserForm
                formik={formik}
                active={active}
                setDialog={setDialog}
                available={available}
                setDialogAva={setDialogAva}
                type={type}
                franchiseList={franchiseList}
                handleCountry={handleCountry}
              />
            </div>
            {/*end::Content*/}
          </div>
        </div>
        <div className="offcanvas-footer">
          <div className="offcanvas-content text-right ">
            <button
              type="button"
              className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
              onClick={() => handleClose()}
            >
              {intl.formatMessage({ id: "CANCEL" })}
            </button>
            <button
              type="submit"
              className="btn btn-primary font-weight-bolder font-size-sm "
              disabled={loading}
            >
              {intl.formatMessage({ id: "SAVE" })}
              {loading && (
                <span className="ml-2 mr-2 spinner spinner-white"></span>
              )}
            </button>
          </div>
        </div>
      </form>
      <ActiveDialog
        active={active}
        formik={formik}
        setActive={setActive}
        dialog={dialog}
        setDialog={setDialog}
      />
      <AvailableDialog
        available={available}
        formik={formik}
        setAvailable={setAvailable}
        dialogAva={dialogAva}
        setDialogAva={setDialogAva}
      />
      <DiscardDialog
        setDialogDis={setDialogDis}
        dialogDis={dialogDis}
        formik={formik}
        closeId={"kt_new_user_panel_close"}
        setActive={setActive}
      />
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </div>
  );
}
