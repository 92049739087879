import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { ContactDetails } from "../../../../app/modules/ContactDeals/page/ContactDetails";
import { Deals } from "../../../../app/modules/ContactDeals/page/Deals";

const ContactDealTaskModal = ({ dialog, setDialog }) => {
  const [check, setCheck] = useState(false);
  const [dealData, setDealData] = useState({
    type: "deal",
    dealId: "",
    contactId: "",
  });

  useEffect(() => {
    if (dialog) {
      setTimeout(() => {
        setCheck(true);
      }, 100);
    }
  }, [dialog]);

  const handleClose = () => {
    // dispatch(
    //   fetchNewCustomerCard(
    //     cardDetail && cardDetail.length && cardDetail[0].id
    //       ? cardDetail[0].id
    //       : ""
    //   )
    // );
    setDialog({ type: "", open: false, id: "" });
    setCheck(false);
  };
  return (
    <Modal
      show={dialog.open}
      onHide={() => handleClose()}
      aria-labelledby="example-modal-sizes-title-xl"
      dialogClassName="my-modal"
      contentClassName="modal-height"
    >
      <div className="mt-2 mr-2" style={{ textAlign: "end" }}>
        <span
          className="btn btn-xs btn-icon btn-light btn-hover-primary"
          onClick={() => handleClose()}
        >
          <i className="ki ki-close icon-sm text-muted"></i>
        </span>
      </div>

      {check ? (
        <Modal.Body>
          {dialog?.type === "deal" ? (
            <Deals
              ids={dialog?.deal_id}
              contactIds={dialog?.contact_id}
              from_task={dialog?.from_tasks}
              isDeal={"modal"}
            />
          ) : (
            <ContactDetails
              ids={dialog?.contact_id}
              setDealData={setDealData}
              dealData={dealData}
            />
          )}
        </Modal.Body>
      ) : (
        ""
      )}
    </Modal>
  );
};

export default ContactDealTaskModal;
