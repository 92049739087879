import { createSlice } from "@reduxjs/toolkit";

const initialCustomerState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  productForEdit: undefined,
  lastError: null,
  list: {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    productForEdit: undefined,
    lastError: null,
  },
  detail: {},
  order: { list: [], loading: false },
  timeline: [],
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const customerSlice = createSlice({
  name: "customer",
  initialState: initialCustomerState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.list.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.list.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    customerFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    //customer list
    customerFetchedList: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.list.listLoading = false;
      state.list.error = null;
      state.list.entities = entities;
      state.list.totalCount = totalCount;
    },
    //order on customer detail page
    customerFetchedOrderList: (state, action) => {
      const { data, loading } = action.payload;
      state.order.list = data;
      state.order.loading = loading;
    },
    //timeline on customer detail page
    customerFetchedTimeLine: (state, action) => {
      const { data } = action.payload;
      state.timeline = data;
    },
  },
});
