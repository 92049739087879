import moment from 'moment';
import React from 'react'
import { Modal } from "react-bootstrap";
import { LoadingSpinner } from '../../../../_metronic/_partials/controls/LoadingSpinner';


const TransferTableModal = ({dialog, setDialog}) => {
    console.log("dialogInModal", dialog)
  return (
    <>
   
     <Modal show={dialog.modal}  aria-labelledby="example-modal-sizes-title-xl"
      dialogClassName="my-transfer-modal"
      contentClassName="modal-height">
        
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
       Transfer Details
        </Modal.Title>
      </Modal.Header>
      {dialog.loading ? <LoadingSpinner /> : (<> 
      <Modal.Body>
      <div className="container">
  {/* Row for the first two divs */}
  <div 
    className="p-4 shadow-sm border rounded d-flex justify-content-between mb-5" 
    style={{ backgroundColor: "#fff", color: "#333", border: "1px solid #e0e0e0" }}
  >
    <div>
     <div style={{ fontSize: "1rem", fontWeight: "500", color: "#555" }}>
     <strong>Last Update</strong> 
        </div>
        <div style={{ fontSize: "1rem", color: "#000" }}>
      {moment(dialog?.data?.payment?.created_at * 1000).format("DD-MM-YYYY HH:mm")}
        </div>
        </div>
        <div>
     <div style={{ fontSize: "1rem", fontWeight: "500", color: "#555" }}>
     <strong> Customer</strong>
        </div>
        <div style={{ fontSize: "1rem", color: "#000" }}>
       {dialog?.data?.payment?.customer_name || "-"}
        </div>
        </div>
        <div>
     <div style={{ fontSize: "1rem", fontWeight: "500", color: "#555" }}>
     <strong> Payment Method</strong> 
        </div>
        <div style={{ fontSize: "1rem", color: "#000" }}>
       {dialog?.data?.payment?.payment_method || "-"}
        </div>
        </div>
        <div>
     <div style={{ fontSize: "1rem", fontWeight: "500", color: "#555" }}>
     <strong> Risk Evaluation </strong> 
        </div>
        <div style={{ fontSize: "1rem", color: "#000" }}>
        <span style={{backgroundColor: dialog?.data?.payment?.risk_level === "Normal" ? "yellow" : dialog?.data?.payment?.risk_level === "Low" ? "green" :  dialog?.data?.payment?.risk_level === "High" ? "red" : "" }}>{dialog?.data?.payment?.risk_score || "-"} </span>
      <span>&nbsp;&nbsp;{dialog?.data?.payment?.risk_level || "-"}</span>
        </div>
        </div>
  </div>
  <div className="row mb-5">
  <div className="col-md-6 mb-3">
  <div 
    className="p-4 shadow-sm border rounded" 
    style={{ backgroundColor: "#fff", color: "#333", border: "1px solid #e0e0e0" }}
  >
    <h3 className="mb-4" style={{ color: "#000", fontWeight: "600" }}>Payment Breakdown</h3>
    {dialog.data?.payment_breakdown?.map((e, index) => (
      <div key={index} className="d-flex justify-content-between align-items-center mb-3">
        <span style={{ fontSize: "1rem", fontWeight: "500", color: "#555" }}>
          {e.key}:
        </span>
        <span style={{ fontSize: "1rem", color: "#000" }}>
         &pound;{e.value}
        </span>
      </div>
    ))}
  </div>
</div>

<div className="col-md-6">
  <div 
    className="p-4 shadow-sm border rounded" 
    style={{ backgroundColor: "#fff", color: "#333", border: "1px solid #e0e0e0" }}
  >
    <h3 className="mb-4" style={{ color: "#000", fontWeight: "600" }}>Payment Details</h3>
    <div className="mb-4">
      <span style={{ fontSize: "1rem", fontWeight: "500", color: "#555" }}>
        Statement Descriptor:
      </span>
      <span className="ml-2" style={{ fontSize: "1rem", color: "#000" }}>
        {dialog?.data?.payment?.statement_descriptor || "-"}
      </span>
    </div>
    {dialog.data?.payment_details?.map((e, index) => (
      <div key={index} className="d-flex justify-content-between align-items-center mb-3">
        <span style={{ fontSize: "1rem", fontWeight: "500", color: "#555" }}>
          {e.key}:
        </span>
        <span style={{ fontSize: "1rem" }}>
          <a href={e.link} style={{ textDecoration: "none" }}>
            {e.value} <i className="fas fa-external-link-alt"></i>
          </a>
        </span>
      </div>
    ))}
  </div>
</div>

  
  </div>

  {/* Timeline Section */}
  <hr />
  <div className="timeline mt-5">
  <h3 className="mb-4" style={{ color: "#000" }}>Timeline</h3>
  <ul className="list-unstyled position-relative" style={{ paddingLeft: "20px" }}>
    {dialog.data?.timeline?.map((e, index) => (
      <li key={index} className="mb-4 position-relative" style={{ paddingLeft: "30px" }}>
        {/* Circle indicator for the event */}
        <div 
          className="position-absolute" 
          style={{
            left: 0, 
            top: "10px", 
            width: "10px", 
            height: "10px", 
            backgroundColor: "#007bff", 
            borderRadius: "50%",
          }}
        ></div>

        {/* Vertical line between timeline items */}
        {index < dialog.data.timeline.length - 1 && (
          <div 
            className="position-absolute" 
            style={{
              left: "4px", 
              top: "20px", 
              bottom: "-20px", 
              width: "2px", 
              backgroundColor: "#ccc"
            }}
          ></div>
        )}

        {/* Event details */}
        <div className="d-flex flex-column">
          <strong style={{ fontSize: "1.1rem", color: "#333" }}>{e?.event}</strong>
          <span className="text-muted" style={{ fontSize: "0.9rem", marginTop: "4px" }}>
          {moment(e?.time * 1000).format("DD-MM-YYYY HH:mm")}
          </span>
        </div>
      </li>
    ))}
  </ul>
</div>
</div>

</Modal.Body>
</>)}
      <Modal.Footer>
      <button
            type="button"
            className="btn btn-light btn-elevate"
            onClick={()=>setDialog({modal:false})}
            // disabled={!transferList?.hasMore} // Disable the button if no more pages
          >
            close
          </button>
      </Modal.Footer>
    

    </Modal>
    </>
  )
}

export default TransferTableModal