import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const CountryPhoneNumber = ({
  handleCountryPhone,
  countryPhoneValue,
  formik,
  fieldKey,
  type,
  functionData,
}) => {
  return (
    <PhoneInput
      inputClassName="form-control "
      // defaultCountry="gb"
      country={"gb"}
      inputProps={{
        style: { width: "100%" },
      }}
      placeholder="Enter phone number"
      value={countryPhoneValue}
      onChange={(e) => {
        if (type === "on_page") {
          const data = {
            e,
            index: functionData,
            field: fieldKey,
          };
          handleCountryPhone(data);
          return;
        }
        formik.setFieldValue(fieldKey, e);
      }}
    />
  );
};

export default CountryPhoneNumber;
