import * as requestFromServer from "./userCrud";
import { userSlice, callTypes } from "./userSlice";

const { actions } = userSlice;

export const fetchUser = (queryParams, abortController) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  return requestFromServer
    .findUser(queryParams, abortController)
    .then((response) => {
      const { meta, data } = response.data;
      dispatch(actions.userFetched({ totalCount: meta.total, entities: data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      if (error.message !== "canceled") {
        dispatch(actions.catchError({ error, callType: callTypes.list }));
      }
    });
};

//user Detail
export const fetchUserDetail = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  dispatch(actions.userDetailFetched({ data: { loading: true } }));
  return requestFromServer
    .findUserDetail(queryParams)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.userDetailFetched({ data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
export const fetchProfile = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .findProfile(queryParams)
    .then((response) => {
      const { meta, data } = response.data;
      dispatch(
        actions.profileFetched({ totalCount: meta.total, entities: data })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchProfileDrop = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .findProfileDrop(queryParams)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.profileDropFetched({ data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchProfileDetail = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .findProfileDetail(queryParams)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.profileDetailFetched({ data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

//parent profile dropdown
export const fetchParentProfileDrop = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .findParentProfileDrop(queryParams)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.parentProfileDropFetched({ data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
