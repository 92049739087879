import React from "react";
import { Modal } from "react-bootstrap";
import { useIntl } from "react-intl";

const AccessAsk = ({
    dialog,
    setDialog,
    handleSetKey

}) => {
    const intl = useIntl();

    const handleClose = () => {
        setDialog(false);
    };
    const handleSuccess = () => {

    };

    return (
        <Modal show={dialog} aria-labelledby="example-modal-sizes-title-lg">
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    Access Book
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <span>Already has an access booked. Do you want to cancel existing access and book a new access ?</span>
            </Modal.Body>
            <Modal.Footer>
                <div>
                    <button
                        type="reset"
                        onClick={() => handleClose()}
                        className="btn btn-light btn-elevate"
                    >
                        {intl.formatMessage({ id: "No" })}
                    </button>
                    <> </>
                    <button
                        type="submit"
                        onClick={() => {
                            setDialog(false)
                            handleSetKey("access")
                        }}
                        className="btn btn-delete btn-elevate"
                    >
                        {intl.formatMessage({ id: "Yes" })}
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default AccessAsk;
