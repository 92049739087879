import { useReducer } from "react";

//productArrayList
// const productArrayList = {
//   id: "",
//   name: "",
//   price: "",
//   total: "",
// };
export const initialValue = {
  is_call_upcoming: false,
};

const reducer = (state = initialValue, action) => {
  switch (action.type) {
    case "SET_DATA":
      return { ...state, ...action.data };
    case "RESET_DATA":
      return initialValue;
    default:
      return state;
  }
};

export const useContextState = (initial) => {
  const [state, dispatchReducer] = useReducer(reducer, initial || initialValue);

  return { state, dispatchReducer };
};
