import React from "react";

import { ReportingUIProvider } from "./ReportingUIContext";
import NewBreadCrumbs from "../../../_metronic/layout/components/subheader/components/NewBreadCrumbs";
import ReportingCard from "./ReportingCard";
export const ReportingListPage = () => {
  // const init = ["kt_new_country_panel"];
  // useEffect(() => {
  //   initIds(init);
  // }, [init]);

  return (
    <>
      <NewBreadCrumbs
        buttons={[
          {
            name: "Run",
            id: "_toggle",
            className: "btn btn-primary m-2",
          },
          {
            name: "Export",
            id: "_toggle",
            className: "btn btn-primary m-2",
          },
        ]}
        title="Pod In Storage"
      />
      <ReportingUIProvider>
        <ReportingCard />
        {/* <NewCountryCanva /> */}
      </ReportingUIProvider>
    </>
  );
};
