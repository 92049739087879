import React, { useEffect, useState, useMemo, useCallback } from "react";
import clsx from "clsx";
import { Dropdown } from "react-bootstrap";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../../_core/MetronicLayout";
import { SearchResult } from "./SearchResult";
import { toAbsoluteUrl } from "../../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../../_partials/dropdowns";
import axios from "axios";
import { useIntl } from "react-intl";
import NoRecord from "../../../../../_partials/widgets/NoRecord";

export function SearchDropdown() {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [searchValue, setSearchValue] = useState("");

  const handleSearch = useCallback(async () => {
    setLoading(true);
    setData(null);
    const response = await axios.get(
      process.env.REACT_APP_SITE_URL +
        `global-search-union?global_search=${encodeURIComponent(searchValue)}`
    );
    if (response.data.success) {
      setData(response.data.data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [searchValue]);

  const handleSearchChange = (event) => {
    setData(null);
    setSearchValue(event.target.value === ""? " ":event.target.value);

    // if (event.target.value.length > 1) {
    // setLoading(true);
    // handleSearch(event.target.value);

    // simulate getting search result
    // }
  };
  // function searchbtn() {
  //   setLoading(true);
  //   setData(null);
  //   handleSearch();
  // }
  // useEffect(() => {
  //   if (loading && searchValue.length > 0) {
  //     handleSearch();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [handleSearch, loading]);

  // const clear = () => {
  //   setData(null);
  //   setSearchValue("");
  // };

  useEffect(() => {
    return () => {
      clearTimeout();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas:
        objectPath.get(uiService.config, "extras.search.layout") ===
        "offcanvas",
    };
  }, [uiService]);

  useEffect(() => {
    // if(searchValue){

    // }
    const delayDebounceFn = setTimeout(() => {
      // Send Axios request here
      if(searchValue !== ""){

        handleSearch();
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  return (
    <>

    
      {!layoutProps.offcanvas && (
        <Dropdown
          alignRight
          drop="down"
          show={searchValue !==""  ? true:false}
          onToggle={() => {
            setData(null);
            setLoading(false);
            // setSearchValue("");
          }}
          id="kt_quick_search_toggle"
        >
          <Dropdown.Toggle as={DropdownTopbarItemToggler}>
          <div style={{ position: "relative" }}>
      <input
        style={{ paddingRight: "40px" , marginBottom:"1px"}}
        type="text"
        autoFocus={true}
        placeholder={intl.formatMessage({ id: "Search..." })}
        value={searchValue}
        onChange={handleSearchChange}
        className="form-control border50"
      />
      <i
        style={{
          position: "absolute",
          top:  "50%",
          transform: "translateY(-50%)",
          right: "15px",
        }}
        className="fa fa-search"
        aria-hidden="true"
      ></i>
       
    </div>
            
          </Dropdown.Toggle>
          <Dropdown.Menu
            className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg w-100"
            // style={{ minWidth: "800px" }}
          >
            <div
              id="kt_quick_search_dropdown"
              className={clsx("quick-search quick-search-dropdown", {
                "quick-search-has-result": data && data.length,
              })}
            >
               {loading ? (
                          <div className="btn btn-icon btn-clean btn-lg btn-dropdown mr-1 w-100">
                            <span
                              className="input-group-append spinner spinner-sm spinner-primary"
                              style={{ padding: "8px" }}
                            />
                          </div>
                        ) : data&& data.length? (
                          <SearchResult data={data} setSearchValue={setSearchValue} />
                        ):<NoRecord/>}
              
            </div>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
}
