// eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef /

import React, { memo, useEffect, useState } from "react";
import {
  calculateExtraPrice,
  convertKilometersToMiles,
} from "../../../utils/commonFunction";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import DistanceCollectionReturn from "../../../../_metronic/_partials/widgets/lists/DistanceCollectionReturn";
// import { canvaCancel } from "../../../utils/canvaCloseFunction";
import { extraMiles } from "../_redux/order/orderCrud";
import { fetchOrderSummary } from "../_redux/order/orderAction";
import { useIntl } from "react-intl";
import { useJsApiLoader } from "@react-google-maps/api";
import { transformDataToAddProduct } from "../NewOrderStripe/NewOrderStripeUtils";

function DistanceDuration({
  setdistanceResponse,
  distanceResponse,
  setKey,

  type,
  franchise,
  state,
  extraMilesData,
  setExtraMilesData,
  setParamData,
  paramData,
  dispatchReducer,
  postcode,
  setAddressData,
  addressData,
  setOpenCanva,
  openCanva,
}) {
  const keys = "return";
  const intl = useIntl();
  const [podCount, setPodCount] = useState("");

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE,
  });
  const dispatch = useDispatch();
  const { orderDetail, cardDetail, orderSummaryListStripe } = useSelector(
    (state) => ({
      orderDetail: state.order.orderDetail,
      cardDetail: state.order.cardDetail,
      orderSummaryListStripe: state.order.orderSummaryListStripe
    }),
    shallowEqual
  );
  // const handleOrderListApiCall = async (check, arr, pod, params) => {
  //   const data = {
  //     collection_date: state.containerTypeSelect === "container" ? state?.collectionRetrun?.container_collection_date : state?.collectionRetrun?.collection_date || "",
  //     pod: pod ? pod : podCount,
  //     return_date: state.containerTypeSelect === "container" ? state?.collectionRetrun?.container_return_date : state?.containerTypeSelect === "container" && state.collectionRetrun?.return_date ?
  //       state.collectionRetrun?.return_date :
  //       state.collectionRetrun?.return_date &&
  //         state.collectionRetrun?.return_start_time_slot
  //         ? state.collectionRetrun?.return_date
  //         : "",
  //     return_postcode:
  //       state.collectionRetrun?.return_postcode &&
  //         state.collectionRetrun?.return_start_time_slot
  //         ? state.collectionRetrun?.return_postcode
  //         : "",
  //     is_student: state.collectionRetrun?.is_student || 0,
  //     is_student_return: state.collectionRetrun?.is_student_return || 0,
  //     competitor_price: state.competitorPrice?.check
  //       ? state.competitorPrice?.price
  //       : 0,
  //     isCompetitor: state.competitorPrice?.check,
  //     container_size: state.containerListSelect,
  //     site_id: state?.siteSelect?.id,
  //     is_plan_replaced: state?.is_plan_replaced
  //     // container_plan:state.containerPlanListSelect
  //   };
  //   const period =
  //     data.collection_date !== "" && data.return_date !== "" && !state.product_array.some((e) => e.id === "Howdens")
  //       ? "week"
  //       : params.period;

  //   await dispatch(
  //     fetchOrderSummary(
  //       state?.deletedProduct,
  //       check,
  //       params.unit,
  //       period,
  //       arr,
  //       franchise,
  //       cardDetail && cardDetail.length && cardDetail[0].postcode
  //         ? cardDetail[0].postcode
  //         : "",
  //       "",
  //       "",
  //       "",
  //       "",
  //       data
  //     )
  //   );
  // };

  const handleCalculateExtraMiles = (result) => {
    if (type === "new_order" && keys === "collection") return;
    if (
      result?.routes.length > -1 &&
      result?.routes[0]?.legs.length > -1 &&
      convertKilometersToMiles(result?.routes[0]?.legs[0]?.distance.text) > 10
    ) {
      let ExtraMiles =
        (result?.routes.length > -1 &&
          result?.routes[0]?.legs.length > -1 &&
          convertKilometersToMiles(
            result?.routes[0]?.legs[0]?.distance.text
          ) - 10) ||
        "-";
      const extraMilesProduct = orderSummaryListStripe?.allProducts.find((e) => e.stripe_product_id === process.env.REACT_APP_PRODUCT_EXTRAMILES);
      let product = transformDataToAddProduct(extraMilesProduct)
      product.quantity =Math.ceil(ExtraMiles)
      product.total = product.cost * ExtraMiles
      const arr = [...state.product_array, product]
      dispatchReducer({
        type: "SET_DATA",
        data: { ...state, extra_miles: ExtraMiles, product_array: arr, extraMilesCall:false },
      });
      // extraMiles(franchise).then((res) => {
      //   if (type !== "order") {
      //     const arr = [...paramData.products_id];
      //     const check = arr.some((e) => e === res.data.data.id);
      //     if (!check) {
      //       arr.push(res.data.data.id);
      //       // setParamData({ ...paramData, products_id: arr });
      //     }
      //     if (keys === "collection") {
      //     }
      //   }
      //   let miles =
      //     (result?.routes.length > -1 &&
      //       result?.routes[0]?.legs.length > -1 &&
      //       convertKilometersToMiles(
      //         result?.routes[0]?.legs[0]?.distance.text
      //       ) - 10) ||
      //     "-";
      //   let price = calculateExtraPrice(
      //     Math.round(miles),
      //     res.data?.data?.prices && res.data?.data?.prices.length
      //       ? res.data?.data?.prices[0]?.item_price?.price / 100
      //       : 0
      //   );
      //   let currency =
      //     res.data?.data?.prices &&
      //     res.data?.data?.prices.length &&
      //     res.data?.data?.prices[0]?.item_price?.currency_code;
      //   if (keys === "collection") {
      //     setExtraMilesData({
      //       ...extraMilesData,
      //       id: res.data.data.id,
      //       collectionMiles: Math.round(miles),
      //       collectionPrice: price,
      //       collectionCurrency: currency,
      //     });
      //   } else {
      //     setExtraMilesData({
      //       ...extraMilesData,
      //       id: res.data.data.id,
      //       returnMiles: Math.round(miles),
      //       returnPrice: price,
      //       returnCurrency: currency,
      //     });
      //   }
      // });
    } else {
      if (keys === "collection") {
        setExtraMilesData({
          ...extraMilesData,
          collectionMiles: 0,
          collectionPrice: 0,
        });
      } else {
        setExtraMilesData({
          ...extraMilesData,
          returnMiles: 0,
          returnPrice: 0,
        });
      }
    }
  };

  useEffect(() => {
    if (
      state?.product_array?.length > 0 &&
      state?.collectionRetrun?.collection_date &&
      extraMilesData?.id
    ) {
      const proArr = [...state?.product_array];
      const index = proArr?.findIndex((e) => e.id === extraMilesData?.id);
      if (index !== -1) {
        const totla =
          Number(extraMilesData?.collectionMiles || 0) +
          Number(extraMilesData.returnMiles || 0);
        const priceTotal =
          Number(extraMilesData?.collectionPrice || 0) +
          Number(extraMilesData.returnPrice || 0);
        proArr[index].qty = totla;
        proArr[index].total = priceTotal;
      }
      dispatchReducer({
        type: "product_array",
        product_array: proArr,
        check: "7",
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.product_array?.length, state?.collectionRetrun, keys]);

  const handleCheck = () => {
    let status = false;
    if (
      state?.collectionRetrun?.collection_date &&
      state?.product_array.length > 0
    ) {
      status = true;
    }

    if (
      state?.collectionRetrun?.return_date &&
      state?.product_array.length > 0
    ) {
      status = true;
    }
    if (extraMilesData?.id && type === "new_order") {
      status = true;
    }
    return status;
  };
  useEffect(() => {
    const status = handleCheck();

    if (status) {
      // const arr = [...paramData.products_id];

      const pod =
        (state?.product_array.length > 0 &&
          state?.product_array.find((e) => e.type === "plan")?.qty) ||
        podCount;
      setPodCount(pod);
      // handleOrderListApiCall("11", arr, pod, state.params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.collectionRetrun, extraMilesData]);

  return (
    <div
      id="kt_distanceduration_panel"
      className={`offcanvas offcanvas-right   pt-5 pb-10 ${openCanva &&
        "offcanvas-on"}`}
    >
      {/*begin::Header*/}
      <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
        <div
          className="offcanvas-close mt-n1 pr-5"
          style={{ position: "absolute", top: "15px", right: "10px" }}
        >
          <span
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            onClick={() => setOpenCanva(false)}
            id="kt_distanceduration_panel_close"
          >
            <i className="ki ki-close icon-sm text-muted"></i>
          </span>
        </div>
      </div>
      {/*end::Header*/}
      <div className=" px-10 ">
        <div
          className=" fade pt-3 pr-5 mr-n5 scroll active show ps--active-y mt-10"
          id="kt_quick_panel_logs_kt_distanceduration_panel"
        >
          <div>
            <h5>{intl.formatMessage({ id: "Distance.Duration" })}</h5>
          </div>
          <div className="deals-tabs">
            {/* <button
              className={`btn ${
                keys === "collection" ? "btnClick" : "btn-dark"
              } font-weight-bolder font-size-sm mr-3 `}
              onClick={() => setKey("collection")}
            >
              {intl.formatMessage({ id: "Collection" })}
            </button> */}
            {/* <button
              className={`btn ${
                keys === "return" ? "btnClick" : "btn-dark"
              } font-weight-bolder font-size-sm mr-3 `}
              onClick={() => setKey("return")}
              disabled={
                type === "new_order"
                  ? state?.collectionRetrun?.return_date === ""
                  : !orderDetail?.return_address_line
              }
              // disabled={!orderDetail?.return_address_line}
            >
              {intl.formatMessage({ id: "Return" })}
            </button> */}
            <DistanceCollectionReturn
              isLoaded={isLoaded}
              setdistanceResponse={setdistanceResponse}
              distanceResponse={distanceResponse}
              orderDetail={orderDetail}
              destination={
                type === "new_order"
                  ? keys === "collection"
                    ? state?.collectionRetrun?.collection_address_line +
                    "," +
                    state?.collectionRetrun?.collection_address_city +
                    "," +
                    state?.collectionRetrun?.collection_postcode
                    : state?.collectionRetrun?.return_address_line +
                    "," +
                    state?.collectionRetrun?.return_address_city +
                    "," +
                    state?.collectionRetrun?.return_postcode
                  : keys === "collection"
                    ? orderDetail?.collection_address_line
                    : orderDetail?.return_address_line
              }
              origin={
                type === "new_order" && keys === "collection"
                  ? state.depotData?.address_line_1 +
                  "," +
                  state.depotData?.address_line_2 +
                  "," +
                  state.depotData?.address_line_3 +
                  "," +
                  state.depotData?.city
                  : type === "new_order" && keys === "return"
                    ? state?.collectionRetrun?.collection_address_line +
                    "," +
                    state?.collectionRetrun?.collection_address_city +
                    "," +
                    state?.collectionRetrun?.collection_postcode
                    : keys === "collection"
                      ? orderDetail?.depot_details?.address_line_1 +
                      "," +
                      orderDetail?.depot_details?.address_line_2 +
                      "," +
                      orderDetail?.depot_details?.address_line_3 +
                      "," +
                      orderDetail?.depot_details?.city
                      : orderDetail?.collection_address_line
              }
              keys={keys}
              franchise={
                type === "new_order" ? franchise : orderDetail?.franchise_id
              }
              setExtraMilesData={setExtraMilesData}
              extraMilesData={extraMilesData}
              paramData={paramData}
              type={type}
              postcode={postcode}
              state={state}
              handleCalculateExtraMiles={handleCalculateExtraMiles}
              setAddressData={setAddressData}
              addressData={addressData}
            // lat={type === "new_order" ? state.depotData?.latitude : ""}
            // lon={type === "new_order" ? state.depotData?.latitude : ""}
            />
          </div>
        </div>
      </div>
      <div className="offcanvas-footer">
        <div className="offcanvas-content text-right ">
          <button
            className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
            onClick={() => setOpenCanva(false)}
          // onClick={() => canvaCancel("kt_distanceduration_panel_close")}
          >
            {intl.formatMessage({ id: "CANCEL" })}
          </button>
        </div>
      </div>
    </div>
  );
}

export default memo(DistanceDuration);
