import React, { createContext, useCallback, useContext, useState } from "react";
import { isEqual, isFunction } from "lodash";

import { initialFilter } from "./ContactUIHelpers";

const ContactUIContext = createContext();

export function useContactUIContext() {
  return useContext(ContactUIContext);
}

export const ContactUIConsumer = ContactUIContext.Consumer;

export function ContactUIProvider({ ContactUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);

  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    openEditProductPage: ContactUIEvents?.openEditProductPage,
    openDeleteProductDialog: ContactUIEvents?.openDeleteProductDialog,
    openDeleteContactDialog: ContactUIEvents?.openDeleteContactDialog,
    openFetchContactDialog: ContactUIEvents?.openFetchContactDialog,
    openUpdateContactStatusDialog:
      ContactUIEvents?.openUpdateContactStatusDialog,
  };

  return (
    <ContactUIContext.Provider value={value}>
      {children}
    </ContactUIContext.Provider>
  );
}
