import React, { useEffect, useState } from "react";
// import { newOrderPayment } from "../../orderUtils";
// import { getPlaceOrder } from "../../_redux/order/orderCrud";
import { Dropdown } from "react-bootstrap";
import { useSelector, shallowEqual } from "react-redux";
import { ReturnReasonModal } from "../modal/ReturnReasonModal";
import { returnExtraChage } from "../../_redux/order/orderCrud";
import { WeekCounter } from "../../../../utils/commonFunction";

export const OrderSummaryButton = ({
  state,
  formik,
  setOpenCanvaQuote,

  dispatchReducer,
  disableAll,
  intl,
  setOpenCanva,
  getInputClasses,
  applyCode,
  setApplyCode,
  handleOfferCode,
  franchise,
  paramData,
  setParamData,
  fetchOrderSummary,
  cardDetail,
  dispatch,
  setSnack,
  location,
}) => {
  const [adhocPrice, setAdhocPrice] = useState("");
  const [show, setShow] = useState(false);

  const { role } = useSelector(
    (state) => ({
      role: state.auth.user.roles,
    }),
    shallowEqual
  );

  const handleAddAdHoc = (adhocPriceCheck) => {
    setAdhocPrice("");
    dispatchReducer({
      type: "adhocGbp",
      value: adhocPriceCheck ? adhocPriceCheck : state?.adhocGbpDummy,
    });
    const arr = [...paramData.products_id];

    const check = arr.findIndex((e) => e === state.adHoc.id);
    // eslint-disable-next-line eqeqeq
    if (check != -1) {
      arr.splice(check, 1);
    }
    arr.push(state.adHoc.id);

    setParamData({ ...paramData, products_id: arr });
    const data = {
      collection_date:state.containerTypeSelect === "container"? state?.collectionRetrun?.container_collection_date: state?.collectionRetrun?.collection_date || "",
      pod:
        (state?.productArray.length > 0 &&
          state?.productArray.find((e) => e.type === "plan")?.qty) ||
        "",
      return_date:state.containerTypeSelect === "container"? state?.collectionRetrun?.container_return_date:
        state.collectionRetrun?.return_date &&
        state.collectionRetrun?.return_start_time_slot
          ? state.collectionRetrun?.return_date
          : "",
      return_postcode:
        state.collectionRetrun?.return_postcode &&
        state.collectionRetrun?.return_start_time_slot
          ? state.collectionRetrun?.return_postcode
          : "",
      is_student: state.collectionRetrun?.is_student || 0,
      is_student_return: state.collectionRetrun?.is_student_return || 0,
      competitor_price: state.competitorPrice?.check
        ? state.competitorPrice?.price
        : 0,
      isCompetitor: state.competitorPrice?.check,
      container_size:state.containerListSelect,
      site_id:state?.siteSelect?.id,
      is_plan_replaced:state?.is_plan_replaced
      // container_plan:state.containerPlanListSelect
    };
    dispatch(
      fetchOrderSummary(
state?.deletedProduct,
        "78",
        paramData.unit,
        paramData.period,
        arr,
        franchise,
        cardDetail && cardDetail.length && cardDetail[0].postcode
          ? cardDetail[0].postcode
          : "",
        "",
        "",
        "",
        "",
        data
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handlePaymentButton = () => {
      setOpenCanvaQuote({ type: "payment", open: true });
  };
  useEffect(() => {
    const pod = state?.productArray.find((e) => e.type === "plan");

    const check = pod?.extra_pod_price;
    if (check && state.adhocGbpDummy === 0 && state?.competitorPrice?.check) {
      // dispatchReducer({ type: "adhocGbp", value: check });
      dispatchReducer({ type: "adhocGbpDummy", value: check });
      dispatchReducer({
        type: "adhocChargeName",
        value: `extra ${+state?.params?.unit - 2} months price`,
      });
      handleAddAdHoc(check);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleAddAdHoc, state.productArray, state.productArray?.length]);

  useEffect(() => {
    const pod = state?.productArray.find((e) => e.type === "plan");
   
    if(pod?.id === "First-Class" && state?.collectionRetrun?.return_date  &&  state?.collectionRetrun?.return_start_time_slot && paramData.period == "week" ){
      
      const data = {
        franchise_id:franchise || "",
        products:[pod?.id],
        weeks:WeekCounter(state?.collectionRetrun?.collection_date, state?.collectionRetrun?.return_date),
        pod:pod?.qty||""
      }
      if(data.weeks <13){

        returnExtraChage(data).then((res)=>{
          if(state?.adhocGbpDummy != res.data?.data){
  
            dispatchReducer({
              type: "adhocChargeName",
              value: "Minimum Term Payment",
            });
            setAdhocPrice(+res.data.data);
            dispatchReducer({
              type: "adhocGbpDummy",
              value: +res.data.data,
            });
            handleAddAdHoc(+res.data.data)
          }
        }).catch((err)=>{
        
        })
      }else {
        // Copy the products_id array from paramData
        const arr = [...paramData?.products_id];
        // Copy the productArray from the state
        const arrProduct = [...state?.productArray];
    
        // Log the initial arrays
    
        // Filter out 'Adhoc-Charges' from both arrays
        const filteredArr = arr.filter((e) => e !== 'Adhoc-Charges');
        const filteredArrProduct = arrProduct.filter((e) => e.id !== 'Adhoc-Charges');
    
        // Log the filtered arrays
    
        // Dispatch the updated productArray to the reducer
        dispatchReducer({
            type: "productArray",
            productArray: filteredArrProduct,
            check: "1",
        });
    
        // Update paramData with the filtered products_id array
        setParamData({ ...paramData, products_id: filteredArr });
    }
     
    }
   
  }, [state?.collectionRetrun?.return_start_time_slot, state?.collectionRetrun,paramData.period]);
  return (
    <>
      <div id="btn-neworderpage">
        <div className="row">
          <div className="col-4">
            <input
              type="text"
              // pattern="[0-9]{5}"
              // maxLength={"3"}
              className={`form-control  mt-2`}
              placeholder="Adhoc Charge"
              value={state?.adhocChargeName}
              onChange={(e) => {
                dispatchReducer({
                  type: "adhocChargeName",
                  value: e.target.value,
                });
              }}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              pattern="[0-9]{5}"
              maxLength={"5"}
              className={`form-control  mt-2`}
              placeholder="£ 0.00"
              value={adhocPrice}
              onChange={(e) => {
                if (e.target.value === "+") return;
                setAdhocPrice(e.target.value);
                dispatchReducer({
                  type: "adhocGbpDummy",
                  value: e.target.value,
                });
              }}
            />
          </div>
          {/* </div> */}
          {/* <div className="form-inline" style={{ width: "100%" }}> */}
          {/* {" "} */}
          <button
            type="submit"
            className="btn btn-primary font-weight-bolder font-size-sm mt-2  col-4 "
            onClick={() => handleAddAdHoc()}
            disabled={
              state?.adHoc?.id && Number(state?.adhocGbpDummy) > 0
                ? false
                : true || disableAll
            }
          >
            {intl.formatMessage({ id: "Add.Charge" })}
          </button>
          <div className="col-4">
            <input
              type="text"
              className={`form-control  mt-3  ${getInputClasses(
                formik,
                "offerCode"
              )}`}
              placeholder={intl.formatMessage({ id: "Offer.Code" })}
              name="offerCode"
              value={applyCode}
              maxLength="40"
              onChange={(e) => setApplyCode(e.target.value)}
              disabled={state.productArray.some((e)=>e.id === "Howdens")}
            />
            {state?.coupon_id?.some((e) => e.name === applyCode) && (
              <span style={{ color: "red" }}>
                {intl.formatMessage({ id: "Already.used" })}
              </span>
            )}
          </div>
          <div className="col-4">
            <button
              type="button"
              onClick={() => handleOfferCode(applyCode, franchise)}
              className="btn btn-primary font-weight-bolder font-size-sm  mt-3 w-100"
              disabled={
                applyCode === "" ||
                state?.coupon_id?.some((e) => e.name === applyCode)
              }
            >
              {intl.formatMessage({ id: "Apply.Code" })}
            </button>
            {formik.touched.offerCode && formik.errors.offerCode ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.offerCode}</div>
              </div>
            ) : null}
          </div>
          <Dropdown
            className="col-4 dropdown-inline"
            style={{ padding: 0 }}
            drop="down"
            alignRight
          >
            <Dropdown.Toggle
              variant="transparent"
              className="btn btn-primary font-weight-bolder font-size-sm dropdown-toggle  mt-3 w-100 "
              id="dropdown-toggle-top2"
            >
              Action
            </Dropdown.Toggle>

            <Dropdown.Menu className="w-100">
              <Dropdown.Item
                disabled={
                  state?.containerTypeSelect === "container"?!state.collectionRetrun?.collection_date:
                  (disableAll ||
                  !state.collectionRetrun?.start_time_slot ||
                  !cardDetail.length > 0)
                }
                onClick={() => handlePaymentButton()}
              >
                Make Payment
              </Dropdown.Item>
              {/* <Dropdown.Item
                disabled={
                  disableAll ||
                  !state.collectionRetrun?.start_time_slot ||
                  !cardDetail.length > 0
                }
                onClick={() => handlePayByLink()}
              >
                Send SMS
              </Dropdown.Item> */}
              {/* {role &&
              role?.length > 0 &&
              role[0]?.name !== "franchise-owner" ? (
                <> */}
                
                  <Dropdown.Item
                    onClick={() =>
                      setOpenCanvaQuote({ type: "quote", open: true })
                    }
                    disabled={state.competitorPrice?.check}
                  >
                    {intl.formatMessage({ id: "Send.Quote" })}
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      setOpenCanvaQuote({ type: "draft", open: true })
                    }
                    disabled={state.competitorPrice?.check}

                  >
                    {intl.formatMessage({ id: "Save.Draft" })}
                  </Dropdown.Item>
                {/* </>
               ) : (
                ""
              )}  */}
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <div className="adhoc-charge mt-2">
          {/* </div> */}

          {/* <button
              type="submit"
              className="btn btn-primary font-weight-bolder font-size-sm mr-3 mt-2 "
              id="kt_Manual_Discount_panel_toggle"
              onClick={() => setOpenCanva(true)}
              disabled={disableAll}
            >
              {state.manualDiscount?.discountType
                ? intl.formatMessage({ id: "Edit.Discount" })
                : intl.formatMessage({ id: "Add.Discount" })}
            </button> */}
          {/* </div> */}
        </div>
      </div>
      <div className="summer-footer w-100 justify-content-end">
        {/* <div className="mb-2">
          <div className="d-flex justify-content-between promt-btnarea">
             <div className="ordpromtbut">
              <div className="form-inline">
                {" "}
                <input
                  type="text"
                  className={`form-control offerCode mr-1  ${getInputClasses(
                    formik,
                    "offerCode"
                  )}`}
                  placeholder={intl.formatMessage({ id: "Offer.Code" })}
                  name="offerCode"
                  value={applyCode}
                  maxLength="40"
                  onChange={(e) => setApplyCode(e.target.value)}
                />
                {state?.coupon_id?.some((e) => e.name === applyCode) && (
                  <span style={{ color: "red" }}>
                    {intl.formatMessage({ id: "Already.used" })}
                  </span>
                )}
              </div>

              <div className="ml-3">
                <button
                  type="button"
                  onClick={() => handleOfferCode(applyCode, franchise)}
                  className="btn btn-primary font-weight-bolder font-size-sm mr-3 sapply-code "
                  disabled={
                    applyCode === "" ||
                    state?.coupon_id?.some((e) => e.name === applyCode)
                  }
                >
                  {intl.formatMessage({ id: "Apply.Code" })}
                </button>
              </div>
            </div> 
          </div>
        </div> */}
        {/* <div className="text-left " style={{ marginLeft: "-10px" }}> */}
        {/* <button
          className="btn btn-primary font-weight-bolder font-size-sm mr-3 save-draft "
          style={{ width: "30%", marginLeft: 0, padding: "10px" }}
          // onClick={handleSaveDraft}
          onClick={() => setOpenCanvaQuote({ type: "draft", open: true })}
          disabled={disableAll}
        >
          {intl.formatMessage({ id: "Save.Draft" })}
        </button>
        <button
          className="btn btn-primary font-weight-bolder font-size-sm mr-3 snd-code"
          // id="kt_sendquote_panel_toggle"
          onClick={() => setOpenCanvaQuote({ type: "quote", open: true })}
          disabled={disableAll}
          style={{ width: "30%", padding: "5px" }}
        >
          {intl.formatMessage({ id: "Send.Quote" })}
        </button> */}

        {/* <button
          className="btn btn-primary font-weight-bolder font-size-sm  sum-payment"
          id="kt_orderpayment_panel_toggle"
          onClick={() => handlePaymentButton()}
          disabled={
            disableAll ||
            !state.collectionRetrun?.start_time_slot ||
            !cardDetail.length > 0
          }
          style={{ width: "30%" }}
        >
          {intl.formatMessage({ id: "Payment" })}
        </button> */}
        {/* </div> */}
      </div>
      <ReturnReasonModal setShow={setShow} show={show} />
    </>
  );
};
