/* eslint-disable no-restricted-imports */
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import SnackBarTool from "../../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
// import { excemptPrice, requestPayment } from "../../../_redux/order/orderCrud";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import { fetchMyOrderDetailCard } from "../../../../Order/_redux/order/orderAction";
import { fetchClassList, fetchFranchisesDetail } from "../../../_redux/franchises/franchisesActions";
import { useFormik } from "formik";
import { createClass, setStairStepPrice } from "../../../_redux/franchises/franchisesCrud";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
const useStyles = makeStyles({
    table: {
        minWidth: 200,
    },
});

const StairStepModal = ({ dialog, setDialog, orderId, fraId, edit }) => {
    const classes = useStyles();

    const [snack, setSnack] = useState({ call: false, type: "", message: "" });
    const [loading, setLoading] = useState(false)
    const intl = useIntl();
    const dispatch = useDispatch();
    const { classList, classLoading } = useSelector(
        (state) => ({
            classList: state.franchise.classList,
            classLoading: state.franchise.classLoading,
        }),
        shallowEqual
    );
    const initialValues = {
        franchise_id: fraId || "",
        plan_type: dialog?.plan_type || "",
        tiers: dialog?.data && dialog.data.length > 0
            ? [
                ...dialog.data,
                ...Array.from({ length: Math.max(7 - dialog.data.length, 0) }, (_, index) => ({ tier: dialog.data.length + index + 1, price: '' }))
            ]
            : Array.from({ length: 7 }, (_, index) => ({ tier: index + 1, price: '' })),
    };
    const handleSubmit = (values) => {
        setLoading(true)
        setSnack({ call: false, type: "", message: "" });
        setStairStepPrice(values)
            .then((res) => {
                setLoading(false)
                dispatch(fetchFranchisesDetail(values?.franchise_id));

                setSnack({ call: true, type: "success", message: res.data.message });
                setTimeout(() => {
                    setDialog(false)
                }, 3000);
            })
            .catch((error) => {
                setLoading(false)
                setSnack({
                    call: true,
                    type: "error",
                    message: error.response.data.message,
                });
            });
    };


    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        onSubmit: handleSubmit,
    });

    const dataArray = {
        plan_pricing_economy: [
            {
                "id": 1,
                "franchise_id": 81,
                "plan_type": "Economy-POD",
                "tier": 1,
                "price": "25.00",
                "created_at": "2024-06-10T12:50:14.000000Z",
                "updated_at": "2024-06-21T09:36:18.000000Z"
            },
            {
                "id": 2,
                "franchise_id": 81,
                "plan_type": "Economy-POD",
                "tier": 2,
                "price": "100.00",
                "created_at": "2024-06-10T12:50:14.000000Z",
                "updated_at": "2024-06-21T09:36:18.000000Z"
            },
            {
                "id": 3,
                "franchise_id": 81,
                "plan_type": "Economy-POD",
                "tier": 3,
                "price": "150.00",
                "created_at": "2024-06-10T12:51:06.000000Z",
                "updated_at": "2024-06-21T09:36:18.000000Z"
            },
            {
                "id": 38,
                "franchise_id": 81,
                "plan_type": "Economy-POD",
                "tier": 4,
                "price": "200.00",
                "created_at": "2024-06-17T13:29:20.000000Z",
                "updated_at": "2024-06-21T09:36:18.000000Z"
            },
            {
                "id": 39,
                "franchise_id": 81,
                "plan_type": "Economy-POD",
                "tier": 5,
                "price": "250.00",
                "created_at": "2024-06-17T13:29:20.000000Z",
                "updated_at": "2024-06-21T09:36:18.000000Z"
            },
            {
                "id": 40,
                "franchise_id": 81,
                "plan_type": "Economy-POD",
                "tier": 6,
                "price": "300.00",
                "created_at": "2024-06-17T13:29:20.000000Z",
                "updated_at": "2024-06-21T09:36:18.000000Z"
            },
            {
                "id": 41,
                "franchise_id": 81,
                "plan_type": "Economy-POD",
                "tier": 7,
                "price": "350.00",
                "created_at": "2024-06-17T13:29:20.000000Z",
                "updated_at": "2024-06-21T09:36:18.000000Z"
            }
        ],
        plan_pricing_first_class: [
            {
                "id": 7,
                "franchise_id": 81,
                "plan_type": "First-Class",
                "tier": 1,
                "price": "150.00",
                "created_at": "2024-06-11T06:29:18.000000Z",
                "updated_at": "2024-06-18T07:03:08.000000Z"
            },
            {
                "id": 8,
                "franchise_id": 81,
                "plan_type": "First-Class",
                "tier": 2,
                "price": "300.00",
                "created_at": "2024-06-11T06:29:18.000000Z",
                "updated_at": "2024-06-18T07:03:08.000000Z"
            },
            {
                "id": 9,
                "franchise_id": 81,
                "plan_type": "First-Class",
                "tier": 3,
                "price": "450.00",
                "created_at": "2024-06-11T06:29:18.000000Z",
                "updated_at": "2024-06-18T07:03:08.000000Z"
            },
            {
                "id": 46,
                "franchise_id": 81,
                "plan_type": "First-Class",
                "tier": 4,
                "price": "600.00",
                "created_at": "2024-06-18T05:39:50.000000Z",
                "updated_at": "2024-06-18T07:03:08.000000Z"
            },
            {
                "id": 47,
                "franchise_id": 81,
                "plan_type": "First-Class",
                "tier": 5,
                "price": "750.00",
                "created_at": "2024-06-18T05:39:50.000000Z",
                "updated_at": "2024-06-18T07:03:08.000000Z"
            },
            {
                "id": 48,
                "franchise_id": 81,
                "plan_type": "First-Class",
                "tier": 6,
                "price": "900.00",
                "created_at": "2024-06-18T05:39:50.000000Z",
                "updated_at": "2024-06-18T07:03:08.000000Z"
            },
            {
                "id": 49,
                "franchise_id": 81,
                "plan_type": "First-Class",
                "tier": 7,
                "price": "1050.00",
                "created_at": "2024-06-18T05:39:50.000000Z",
                "updated_at": "2024-06-18T07:03:08.000000Z"
            }
        ],
        plan_pricing_premium: [
            {
                "id": 4,
                "franchise_id": 81,
                "plan_type": "POD",
                "tier": 1,
                "price": "80.00",
                "created_at": "2024-06-11T06:28:03.000000Z",
                "updated_at": "2024-06-21T09:44:28.000000Z"
            },
            {
                "id": 5,
                "franchise_id": 81,
                "plan_type": "POD",
                "tier": 2,
                "price": "160.00",
                "created_at": "2024-06-11T06:28:03.000000Z",
                "updated_at": "2024-06-21T09:44:28.000000Z"
            },
            {
                "id": 6,
                "franchise_id": 81,
                "plan_type": "POD",
                "tier": 3,
                "price": "230.00",
                "created_at": "2024-06-11T06:28:03.000000Z",
                "updated_at": "2024-06-21T09:44:28.000000Z"
            },
            {
                "id": 30,
                "franchise_id": 81,
                "plan_type": "POD",
                "tier": 4,
                "price": "300.00",
                "created_at": "2024-06-17T10:29:59.000000Z",
                "updated_at": "2024-06-21T09:44:28.000000Z"
            },
            {
                "id": 31,
                "franchise_id": 81,
                "plan_type": "POD",
                "tier": 5,
                "price": "350.00",
                "created_at": "2024-06-17T10:29:59.000000Z",
                "updated_at": "2024-06-21T09:44:28.000000Z"
            },
            {
                "id": 32,
                "franchise_id": 81,
                "plan_type": "POD",
                "tier": 6,
                "price": "400.00",
                "created_at": "2024-06-17T10:29:59.000000Z",
                "updated_at": "2024-06-21T09:44:28.000000Z"
            },
            {
                "id": 33,
                "franchise_id": 81,
                "plan_type": "POD",
                "tier": 7,
                "price": "440.00",
                "created_at": "2024-06-17T10:29:59.000000Z",
                "updated_at": "2024-06-21T09:44:28.000000Z"
            }
        ]
    }

    return (
        <Modal show={dialog.open} aria-labelledby="example-modal-sizes-title-lg"
            size="lg"
            dialogClassName="my-modal60"
            contentClassName="modal-height">
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">Sector pricing breakdown
                    <div style={{ margin: "-2% 0 2% 0" }}>

                        {/* <span className="text-dark" style={{ fontSize: "12px", fontWeight: "400" }}>Prices displayed below are without the 50% discount</span> */}
                    </div> </Modal.Title>

            </Modal.Header>
            <Modal.Body >
                <div className="row">
                    <div className="col-4">
                        <div className="card card-custom  gutter-b mb-10">
                            <div className="card-body">
                                <div className="d-flex justify-content-between align-items-center w-100 ">
                                    <h3 className="card-title p-0 m-0 ">
                                        <span className="font-weight-bolder text-dark">
                                            Economy
                                        </span>
                                        {/* <Switch
                          checked={toggleCheck?.eco}
                          onChange={() => setToggleCheck({...toggleCheck, eco:!toggleCheck?.eco})}
                          color="primary"
                          name="checkedB"
                          inputProps={{ "aria-label": "primary checkbox" }}
                        /> */}
                                    </h3>
                                    <div>
                                        <Button variant="primary" size="sm" onClick={() => {
                                            //   setDialogStair({open:true, data:detailData?.plan_pricing_economy, plan_type:"Economy-POD"})
                                        }} >
                                            Add
                                        </Button>
                                    </div>
                                </div>
                                {/* {toggleCheck?.eco && */}
                                <div>
                                    <TableContainer component={Paper}>
                                        <Table className={classes.table} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Pod (Units)</TableCell>
                                                    <TableCell align="right">Price per Unit (&pound;/mo)</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {dataArray?.plan_pricing_economy?.length > 0 && dataArray?.plan_pricing_economy.map((e) => {
                                                    return (
                                                        <>
                                                            <TableRow >
                                                                <TableCell component="th" scope="row">
                                                                    {e?.tier == 7 ? "7+" : e?.tier}
                                                                </TableCell>
                                                                <TableCell align="right">&pound;{e?.price}</TableCell>
                                                            </TableRow>
                                                        </>
                                                    )
                                                })}

                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                                {/* } */}
                            </div>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="card card-custom  gutter-b mb-10">
                            <div className="card-body">
                                <div className="d-flex justify-content-between align-items-center w-100 ">
                                    <h3 className="card-title p-0 m-0 ">
                                        <span className="font-weight-bolder text-dark">
                                            Premium
                                        </span>
                                        {/* <Switch
                          checked={toggleCheck?.pre}
                          onChange={() => setToggleCheck({...toggleCheck, pre:!toggleCheck?.pre})}
                          color="primary"
                          name="checkedB"
                          inputProps={{ "aria-label": "primary checkbox" }}
                        /> */}
                                    </h3>
                                    <div>
                                        <Button variant="primary" size="sm" onClick={() => {
                                            //   setDialogStair({open:true, data:detailData?.plan_pricing_premium, plan_type:"POD"})
                                        }}  >
                                            Add
                                        </Button>
                                    </div>
                                </div>
                                {/* {toggleCheck?.pre && */}
                                <div>
                                    <TableContainer component={Paper}>
                                        <Table className={classes.table} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Pod (Units)</TableCell>
                                                    <TableCell align="right">Price per Unit (&pound;/mo)</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {dataArray?.plan_pricing_premium?.length > 0 && dataArray?.plan_pricing_premium.map((e) => {
                                                    return (
                                                        <>
                                                            <TableRow >
                                                                <TableCell component="th" scope="row">
                                                                    {e?.tier == 7 ? "7+" : e?.tier}
                                                                </TableCell>
                                                                <TableCell align="right">&pound;{e?.price}</TableCell>
                                                            </TableRow>
                                                        </>
                                                    )
                                                })}

                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                                {/* } */}
                            </div>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="card card-custom  gutter-b mb-10">
                            <div className="card-body">
                                <div className="d-flex justify-content-between align-items-center w-100 ">
                                    <h3 className="card-title p-0 m-0 ">
                                        <span className="font-weight-bolder text-dark">
                                            First Class
                                        </span>
                                        {/* <Switch
                          checked={toggleCheck?.fc}
                          onChange={() => setToggleCheck({...toggleCheck, fc:!toggleCheck?.fc})}
                          color="primary"
                          name="checkedB"
                          inputProps={{ "aria-label": "primary checkbox" }}
                        /> */}
                                    </h3>
                                    <div>
                                        <Button variant="primary" size="sm" onClick={() => {
                                            //   setDialogStair({open:true, data:detailData?.plan_pricing_first_class, plan_type:"First-Class"})
                                        }} >
                                            Add
                                        </Button>
                                    </div>
                                </div>
                                {/* {toggleCheck?.fc && */}
                                <div>
                                    <TableContainer component={Paper}>
                                        <Table className={classes.table} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Pod (Units)</TableCell>
                                                    <TableCell align="right">Price per Unit (&pound;/mo)</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {dataArray?.plan_pricing_first_class?.length > 0 && dataArray?.plan_pricing_first_class.map((e) => {
                                                    return (
                                                        <>
                                                            <TableRow >
                                                                <TableCell component="th" scope="row">
                                                                    {e?.tier == 7 ? "7+" : e?.tier}
                                                                </TableCell>
                                                                <TableCell align="right">&pound;{e?.price}</TableCell>
                                                            </TableRow>
                                                        </>
                                                    )
                                                })}

                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                                {/* } */}
                            </div>
                        </div>
                    </div>
                </div>

            </Modal.Body>
            <Modal.Footer>
                <div>
                    <button
                        type="button"
                        onClick={() => setDialog(false)}
                        className="btn btn-light btn-elevate"
                    >
                        Close
                    </button>
                    <> </>
                    {/* <button
                        type="button"
                        className="btn btn-delete btn-elevate"
                        onClick={() => formik.submitForm()}
                        disabled={loading}
                    >
                        {"ADD"}
                        {loading && <span className="ml-1 spinner spinner-white"></span>}
                    </button> */}

                </div>
            </Modal.Footer>
            {snack.call ? <SnackBarTool {...snack} /> : ""}
        </Modal>
    );
};

export default StairStepModal;
