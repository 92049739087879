import React, { useState } from "react";
import * as Yup from "yup";
import * as auth from "../_redux/authRedux";

import { Link, Redirect } from "react-router-dom";

import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import queryString from "query-string";
import { resetPassword } from "../_redux/authCrud";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

function ResetPassword(props) {
  let history = useHistory();
  const { intl } = props;
  const { search } = useLocation();
  const values = queryString.parse(search);
  const initialValues = {
    email: values?.email,
    password: "",
    password_confirmation: "",
    token: props?.match?.params?.id,
  };

  const [isRequested, setIsRequested] = useState(false);
  const [success, setSuccess] = useState({ type: false, message: "" });
  const [disable, setDisable] = useState(false);
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirm: false,
  });
  const togglePasswordVisibility = (type) => {
    if (type === "password") {
      setShowPassword({ ...showPassword, password: !showPassword?.password });
    } else {
      setShowPassword({ ...showPassword, confirm: !showPassword?.confirm });
    }
  };
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Email address is required"),
    password: Yup.string()
      .required("Please enter your password.")
      .min(8, "Your password is too short.")
      .max(20, "Maximum 20 symbols"),
    password_confirmation: Yup.string()
      .max(20, "Maximum 20 symbols")
      .required("Please enter your Confirm password.")
      .oneOf([Yup.ref("password")], "Your passwords do not match."),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting, setErrors }) => {
      resetPassword(
        values.email,
        values.password,
        values.password_confirmation,
        values.token
      )
        .then((res) => {
          setSuccess({
            type: true,
            message: "Your password has been changed successfully ",
          });
          setDisable(true);
          setTimeout(() => {
            history.push("/login");
          }, 2000);
        })
        .catch((error) => {
          // setErrors({
          //   email: "The email address you entered did  not match our records",
          // });
          setIsRequested(false);
          setSubmitting(false);
          setStatus(
            error?.response?.data?.message ||
              intl.formatMessage(
                { id: "AUTH.VALIDATION.NOT_FOUND" },
                { name: values.email }
              )
          );
          // setStatus(
          //   intl.formatMessage(
          //     { id: "AUTH.VALIDATION.NOT_FOUND" },
          //     { name: values.email }
          //   )
          // );
        });
    },
  });

  return (
    <>
      {isRequested && <Redirect to="/auth" />}
      {!isRequested && (
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10">
            <img src={"/media/logos/easystorage.png"} alt="logo" />
          </div>
          <div className="text-center mb-5 mb-lg-10">
            <h3 className="font-size-h1">Reset Password</h3>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {success.type && (
              <div className="mb-10 alert alert-custom alert-light-success alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {success.message}
                </div>
              </div>
            )}
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            <div className="form-group fv-plugins-icon-container">
              <label className="font-weight-bold">Email</label>
              <input
                disabled
                placeholder="Email"
                type="email"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "email"
                )}`}
                name="email"
                {...formik.getFieldProps("email")}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.email}</div>
                </div>
              ) : null}
            </div>{" "}
            <div className="form-group fv-plugins-icon-container">
              <label className="font-weight-bold">Password</label>
              <div className="d-flex position-relative">
                <input
                  placeholder="Password"
                  type={showPassword.password ? "text" : "password"}
                  className={`form-control form-control-solid h-auto py-5 px-6 `}
                  name="password"
                  {...formik.getFieldProps("password")}
                />
                <SVG
                  style={{
                    color: "orange",
                    position: "absolute",
                    right: "3%",
                    top: "30%",
                  }}
                  title=" "
                  className="h-22 align-self-end "
                  src={toAbsoluteUrl(
                    showPassword.password
                      ? "/media/svg/icons/General/Visible.svg"
                      : "/media/svg/icons/General/Hidden.svg"
                  )}
                  onClick={() => togglePasswordVisibility("password")}
                ></SVG>
              </div>
              {formik.touched.password && formik.errors.password ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.password}</div>
                </div>
              ) : null}
            </div>{" "}
            <div className="form-group fv-plugins-icon-container">
              <label className="font-weight-bold">Confirm Password</label>
              <div className="d-flex position-relative">
                <input
                  placeholder="Confirm Password"
                  type={showPassword.confirm ? "text" : "password"}
                  className={`form-control form-control-solid h-auto py-5 px-6 `}
                  name="password_confirmation"
                  {...formik.getFieldProps("password_confirmation")}
                />
                <SVG
                  style={{
                    color: "orange",
                    position: "absolute",
                    right: "3%",
                    top: "30%",
                  }}
                  title=" "
                  className="h-22 align-self-end "
                  src={toAbsoluteUrl(
                    showPassword.confirm
                      ? "/media/svg/icons/General/Visible.svg"
                      : "/media/svg/icons/General/Hidden.svg"
                  )}
                  onClick={() => togglePasswordVisibility()}
                ></SVG>
              </div>
              {formik.touched.password_confirmation &&
              formik.errors.password_confirmation ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.password_confirmation}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn org-btn font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={disable}
              >
                Submit
              </button>
              <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-dark font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  Cancel
                </button>
              </Link>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ResetPassword));
