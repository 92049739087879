/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */

import { DirectionsRenderer, GoogleMap } from "@react-google-maps/api";
import React, { memo, useCallback, useEffect, useState } from "react";

import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";
import {
  convertGBPtoPoundSymbol,
  convertKilometersToMiles,
} from "../../../../app/utils/commonFunction";
import { useIntl } from "react-intl";

function DistanceCollectionReturn({
  isLoaded,
  setdistanceResponse,
  distanceResponse,
  orderDetail,
  keys,
  origin,
  destination,
  extraMilesData,
  type,
  postcode,
  state,
  handleCalculateExtraMiles,
  setAddressData,
  addressData,
}) {
  const intl = useIntl();
  const [map, setMap] = useState(null);

  const containerStyle = {
    width: "400px",
    height: "400px",
  };
  const center = {
    lat: 28.618698883918658,
    lng: 77.21201288871427,
  };
  const handleResponse = (response) => {
    const directionsService = new window.google.maps.DirectionsService();

    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: window.google.maps.TravelMode.DRIVING,
        // drivingOptions: {
        //   departureTime: new Date(Date.now()),  // for the time N milliseconds from now.
        //   trafficModel: 'pessimistic'
        // }
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          const ori = result?.request?.origin.query;
          const des = result?.request?.destination.query;

          if (
            (keys === "collection" && addressData[keys] !== ori) ||
            (keys === "return" && addressData[keys] !== des)
          ) {
            setAddressData({
              ...addressData,
              [keys]: keys === "collection" ? ori : des,
            });
            setdistanceResponse({ ...distanceResponse, [keys]: result });

            handleCalculateExtraMiles(result);
          }
        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );
  };
  const onLoad = useCallback((marker) => setMap(marker), []);

  useEffect(() => {
    if (map && origin && destination && state?.extraMilesCall) {
      handleResponse();
    }
  }, [map, origin, destination, keys, state?.extraMilesCall ]);

  // useEffect(() => {
  //   if (state?.extraMilesCall) {
  //     handleResponse();
  //   }
  // }, [state?.extraMilesCall ]);
  // useEffect(() => {
  //   if (map && origin && destination) {
  //     if (
  //       state?.collectionRetrun?.collection_date &&
  //       type === "new_order" &&
  //       extraMilesData?.collectionMiles
  //     ) {
  //       handleResponse();
  //     }
  //   }
  // }, [
  //   map,
  //   origin,
  //   destination,
  //   keys,
  //   state?.collectionRetrun?.collection_date,
  //   state?.depotData?.id,
  // ]);
  return (
    <>
      <div className="mt-10">
        <div className="d-flex justify-content-between align-items-center pt-5 mb-4">
          <div className="text-center">
            <div>
              {keys === "collection"
                ? intl.formatMessage({ id: "Depot" })
                : "Collection"}
            </div>
            <div>
              <span className="symbol-label ml-2">
                <span
                  className="svg-icon svg-icon-sm svg-icon-primary"
                  id="kt_sendquote_panel_toggle"
                >
                  <SVG
                    title=" "
                    className="h-25 align-self-end"
                    src={toAbsoluteUrl("/media/svg/icons/Map/Marker1.svg")}
                  ></SVG>
                </span>
              </span>
            </div>
            <div>
              {/* {type === "new_order" ? (
                <>
                  {keys === "collection"
                    ? state?.collectionRetrun?.collection_postcode || postcode
                    : keys === "return"
                    ? state?.collectionRetrun?.return_postcode || postcode
                    : "-"}
                </>
              ) : (
                <>
                  {keys === "collection"
                    ? orderDetail?.collection_postcode
                    : orderDetail?.return_postcode || "-"}
                </>
              )} */}
              {type === "new_order" && keys === "collection"
                ? state?.depotData?.postcode
                : type === "new_order" && keys === "return"
                ? state?.collectionRetrun?.collection_postcode || postcode
                : orderDetail?.depot_details?.postcode}
            </div>
          </div>
          <div className="distance"></div>
          <div className="text-center">
            <div>
              {keys === "collection"
                ? intl.formatMessage({ id: "CUSTOMERS" })
                : "Return"}
            </div>
            <div>
              <span className="symbol-label ml-2">
                <span className="svg-icon svg-icon-sm svg-icon-primary">
                  <SVG
                    title=" "
                    className="h-25 align-self-end"
                    src={toAbsoluteUrl("/media/svg/icons/Map/Marker1.svg")}
                  ></SVG>
                </span>
              </span>
            </div>
            <div>
              {/* {type === "new_order"
                ? state?.depotData?.postcode
                : orderDetail?.depot_details?.postcode} */}
              {type === "new_order" ? (
                <>
                  {keys === "collection"
                    ? state?.collectionRetrun?.collection_postcode || postcode
                    : keys === "return"
                    ? state?.collectionRetrun?.return_postcode || postcode
                    : "-"}
                </>
              ) : (
                <>
                  {keys === "collection"
                    ? orderDetail?.collection_postcode
                    : orderDetail?.return_postcode || "-"}
                </>
              )}
            </div>
          </div>
        </div>

        <div className="mt10 mb-10">
          {isLoaded && (
            <GoogleMap
              id="direction-example"
              mapContainerStyle={containerStyle}
              zoom={14}
              center={center}
              onLoad={onLoad}
            >
              {distanceResponse && (
                <DirectionsRenderer
                  // required
                  options={{
                    directions: distanceResponse[keys],
                  }}
                />
              )}
            </GoogleMap>
          )}
        </div>

        <div className="row">
          <div className="col-md-6 mb-5">
            <strong>{intl.formatMessage({ id: "Distance" })}:</strong>{" "}
            {(distanceResponse &&
              distanceResponse[keys] &&
              distanceResponse[keys]?.routes.length > -1 &&
              distanceResponse[keys]?.routes[0]?.legs.length > -1 &&
              convertKilometersToMiles(
                distanceResponse[keys]?.routes[0]?.legs[0]?.distance.text
              )) ||
              "-"}{" "}
            {intl.formatMessage({ id: "Miles" })}
          </div>
          <div className="col-md-6 mb-5">
            <strong>{intl.formatMessage({ id: "Duration" })}:</strong>{" "}
            {(distanceResponse &&
              distanceResponse[keys] &&
              distanceResponse[keys]?.routes.length > -1 &&
              distanceResponse[keys]?.routes[0]?.legs.length > -1 &&
              distanceResponse[keys]?.routes[0]?.legs[0]?.duration.text) ||
              "-"}
          </div>
          <div className="col-md-6 mb-5">
            <strong>{intl.formatMessage({ id: "Extra.Miles" })}:</strong>{" "}
            {keys === "collection" && extraMilesData?.collectionMiles
              ? extraMilesData?.collectionMiles
              : keys === "return" && extraMilesData?.returnMiles
              ? extraMilesData?.returnMiles
              : "0"}{" "}
            {intl.formatMessage({ id: "Miles" })}
          </div>
          <div className="col-md-6 mb-5">
            <strong>
              {intl.formatMessage({ id: "Extra.Miles.Charges" })}:
            </strong>{" "}
            {keys === "collection" && extraMilesData?.collectionCurrency
              ? convertGBPtoPoundSymbol(extraMilesData?.collectionCurrency)
              : keys === "return" && extraMilesData?.returnCurrency
              ? convertGBPtoPoundSymbol(extraMilesData?.returnCurrency)
              : ""}{" "}
            {keys === "collection"
              ? extraMilesData?.collectionPrice
              : extraMilesData?.returnPrice}
          </div>
        </div>
      </div>
    </>
  );
}

export default memo(DistanceCollectionReturn);
