import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";

import { Paper, Tabs, Tab } from "@material-ui/core";
import { ContainerModalCompoent } from "../components/ContainerModalCompoent";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { getClassSiteList } from "../../../Settings/_redux/franchises/franchisesCrud";
import { useFormik } from "formik";
import { newOrderCollectionValue } from "../../orderUtils";
import SnackBarTool from "../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { transformDataToAddProduct } from "../../NewOrderStripe/NewOrderStripeUtils";

export function ContainerCollectionModal({
  dispatchReducer,
  state,
  show,
  setShow,
  initialValues,
  franchise,
  orderSummaryListStripe
}) {
  const [value, setValue] = React.useState(2);
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClassList = async (id) => {
    await getClassSiteList(id).then((res) => {
      dispatchReducer({
        type: "SET_DATA",
        data: { ...state, classSiteList:res.data.data,  },
    });
    });
  };
  useEffect(() => {
    if (state.siteSelect?.id) {
      handleClassList(state.siteSelect?.id);
    }
  }, [state.siteSelect?.id]);

  const handleSubmit = (values) => {
    const collectionData = newOrderCollectionValue(values, formik, "", state);
    console.log("collectionData", collectionData, formik?.values.date_type);
    if(collectionData.collection_date === ""){

      setSnack({ call: true, type: "error", message: "Please Select Move In Date" });
      return;
    }
    if(formik?.values.date_type == 1){
      const arr = [...state.product_array]
      const charge = transformDataToAddProduct(orderSummaryListStripe?.container?.charges?.collection_fee_20ft[0])
      console.log("charge-90", charge);
      if(charge.price_id){

        arr.push(charge)
    console.log("collectionData-123123", collectionData);

        dispatchReducer({
          type: "SET_DATA",
          data: { ...state, collectionRetrun:collectionData, product_array:arr  },
      });
      }

    }else if(formik?.values?.date_type == 3){
      const arr = [...state.product_array]
      const charge = transformDataToAddProduct(orderSummaryListStripe?.container?.charges?.return_fee_20ft[0])
      if(charge.price_id){

        arr.push(charge)
        dispatchReducer({
          type: "SET_DATA",
          data: { ...state, collectionRetrun:collectionData, product_array:arr  },
      });
      }
    }else{

      dispatchReducer({
        type: "SET_DATA",
        data: { ...state, collectionRetrun:collectionData,  },
    });
    }
    
    setShow(false);
  };
  useEffect(() => {
   if(snack.call){
    setTimeout(() => {
      setSnack({ call: false, type: "", message: "" });
    }, 4000);
   }
  }, [snack.call]);
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });
  const handleChange = (event, newValue) => {
    formik.setFieldValue("date_type", newValue);
    setValue(newValue);
  };
  useEffect(() => {
   if(!state?.collectionRetrun?.collection_date){
formik.resetForm()
   }
  }, [state?.collectionRetrun?.collection_date]);
  return (
    <>
      <Modal
        show={show}
        onHide={() => handleClose()}
        aria-labelledby="example-modal-sizes-title-lg"
        size="lg"
        contentClassName="modal-height"
      >
        <Modal.Header closeButton>
          <Modal.Title>Calendar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit}>
            <Paper square>
              <Tabs
                value={formik.values.date_type}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleChange}
                aria-label="disabled tabs example"
                style={{ justifyContent: "center" }}
              >
                <Tab label="MoveIn" value="2" />

                <Tab
                  label="Collection"
                  value="1"
                  disabled={formik.values.collection_date === ""}
                />
                <Tab label="MoveOut" value="4" />

                <Tab
                  label="Return"
                  value="3"
                  disabled={formik.values.return_date === ""}
                />
              </Tabs>
              <div className="row" style={{ padding: "12px" }}>
                <div className="col-12">
                  <ContainerModalCompoent formik={formik} state={state} franchise={franchise} dispatchReducer={dispatchReducer}/>
                </div>
                {/* <div className="col-12">

              <Form.Group className="mb-0">
                <Form.Label>Class</Form.Label>
                <FormControl fullWidth>
                  <Select
                    name={`site_class_id`}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                    className={`form-control  `}
                    IconComponent={(props) => (
                      <i {...props}>
                        <KeyboardArrowDownIcon />
                      </i>
                    )}
                    inputProps={{ "aria-label": "Without label" }}
                    displayEmpty
                      {...formik.getFieldProps(`site_class_id`)}

                  >
                    <MenuItem value="">Select</MenuItem>
                    {state.classSiteList &&
                      state.classSiteList.length > 0 &&
                      state.classSiteList.map((e) => (
                        <MenuItem value={e.id}>{e.name}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
                
              </Form.Group>
                </div> */}
              </div>
            </Paper>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={() => formik.submitForm()}>
            Save Changes
          </Button>
        </Modal.Footer>
        {snack.call ? <SnackBarTool {...snack} /> : ""}
      </Modal>
    </>
  );
}
