// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";

import {
  sortCaret,
  headerSortingClasses,
  getHandlerTableChange,
} from "../../../../_metronic/_helpers";

import { Pagination } from "../../../../_metronic/_partials/controls";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
// import * as actions from "../_redux/reporting/reportingAction";
import * as uiHelpers from "../ReportingUIHelpers";
import { useReportingUIContext } from "../ReportingUIContext";
import { NetTotalPodTabel } from "./NetTotalPodTabel";

const ReportingTable = () => {
  const RepotingUIContext = useReportingUIContext();
  const ReportingUIProps = useMemo(() => {
    return {
      ids: RepotingUIContext?.ids,
      setIds: RepotingUIContext?.setIds,
      queryParams: RepotingUIContext?.queryParams,
      setQueryParams: RepotingUIContext?.setQueryParams,
      openEditOrderPage: RepotingUIContext?.openEditOrderPage,
      openDeleteOrderDialog: RepotingUIContext?.openDeleteOrderDialog,
    };
  }, [RepotingUIContext]);
  const dispatch = useDispatch();
  const { currentState } = useSelector(
    (state) => ({ currentState: state.reporting }),
    shallowEqual
  );
  const { totalCount, entities } = currentState;
  useEffect(() => {
    // clear selections list
    // server call by queryParams
    ReportingUIProps.setIds([]);
    // dispatch(actions.fetchReporting(ReportingUIProps.queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ReportingUIProps.queryParams, dispatch]);

  const columns = [
    {
      dataField: "date",
      text: "Date",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      style: { width: "154px" },
    },
    {
      dataField: "franchise",
      text: "Franchise",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      style: { width: "130px" },
    },

    {
      dataField: "depot",
      text: "Depot",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      style: { width: "160px" },
    },
    {
      dataField: "open",
      text: "Open",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      style: { width: "65px" },
    },
    {
      dataField: "in",
      text: "In",
      sort: false,
      sortCaret: sortCaret,
      style: { width: "65px" },
    },
    {
      dataField: "out",
      text: "Out",
      sort: false,
      sortCaret: sortCaret,
      style: { width: "65px" },
    },
    {
      dataField: "balance",
      text: "Balance",
      sort: false,
      sortCaret: sortCaret,
      style: { width: "65px" },
    },

    // {
    //   dataField: "action",
    //   text: "Actions",
    //   formatter: () => {
    //     return <ActionsButton />;
    //   },
    //   classes: "text-right pr-0",
    //   headerClasses: "text-right pr-3",
    //   style: {
    //     minWidth: "100px",
    //   },
    // },
  ];

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: ReportingUIProps.queryParams?.pageSize,
    page: ReportingUIProps.queryParams?.pageNumber,
  };
  const rowEvents = {
    onClick: (e, row, rowIndex) => {},
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination isLoading={false} paginationProps={paginationProps}>
              <BootstrapTable
                rowEvents={rowEvents}
                wrapperClasses="table-responsive"
                hover
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="id"
                defaultSorted={uiHelpers.defaultSorted}
                data={entities === null ? [] : entities}
                onTableChange={getHandlerTableChange(
                  ReportingUIProps.setQueryParams
                )}
                columns={columns}
                {...paginationTableProps}
              ></BootstrapTable>
              <NetTotalPodTabel />
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
};

export default ReportingTable;
