import { Card, CardBody } from "../../../_metronic/_partials/controls";
import React, { useEffect, useState } from "react";

import DeleteDialog from "../../../_metronic/_partials/widgets/alert/DeleteDialog";
import { useLocation } from "react-router-dom";
import NewBreadCrumbs from "../../../_metronic/layout/components/subheader/components/NewBreadCrumbs";
import { OrderFilter } from "./Filter/Order";
import OrderTable from "./Table/OrderTable";
import SnackBarTool from "../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { deleteOrder } from "./_redux/order/orderCrud";
import { fetchMyOrderList, fetchStats } from "./_redux/order/orderAction";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useIntl } from "react-intl";
import { useOrderUIContext } from "./OrderUIContext";
import NotAuthorized from "../../../_metronic/_partials/widgets/NotAuthorized";

export const OrderPage = () => {
  const location = useLocation();
  const intl = useIntl();
  const dispatch = useDispatch();

  const OrderUIContext = useOrderUIContext();

  const [dialog, setDialog] = useState(false);
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });

  const handleDelete = () => {
    setSnack({ call: false, type: "", message: "" });
    deleteOrder({ order_id: OrderUIContext.ids })
      .then((res) => {
        setSnack({ call: true, type: "success", message: res.data.message });
        dispatch(fetchMyOrderList(OrderUIContext.queryParams));
        // // resetForm();
        setDialog(false);
        OrderUIContext.setIds([]);
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
        setDialog(false);
      });
  };
  const { permission, statsData, role } = useSelector(
    (state) => ({
      permission: state.auth.permission,
      statsData: state.order.statsData,
      role: state.auth.user.roles,
    }),
    shallowEqual
  );
  useEffect(() => {
    if ((role && role?.length && role[0] === "super-admin") || (role && role?.length && role[0]?.name === "franchise-owner")) {
      dispatch(fetchStats());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <NewBreadCrumbs
          breadcrumb={true}
          title={
            location.pathname === "/create-return"
              ? "Create Return"
              : location.pathname === "/create-access"
              ? "Create Access"
              : "Orders"
          }
        />
        <div className="text-right mb-5">
          {/* {permission?.order?.delete ? (
            <button
              className="btn btn-primary mr-2"
              type="button"
              onClick={() => setDialog(true)}
              disabled={OrderUIContext.ids.length === 0}
            >
              {intl.formatMessage({ id: "Delete" })}
            </button>
          ) : (
            ""
          )} */}
          {/* {permission?.order?.create ? (
            <Link to="/new-order">
              <button className="btn btn-primary" type="button">
                {intl.formatMessage({ id: "Create.Order" })}
              </button>
            </Link>
          ) : (
            ""
          )} */}
        </div>
      </div>
      <Card>
        <CardBody>
          {permission?.order?.view ? (
            <>
              <OrderFilter location={location} statsData={statsData} />
              <OrderTable />
            </>
          ) : (
            <NotAuthorized />
          )}
        </CardBody>
      </Card>
      <DeleteDialog
        dialog={dialog}
        setDialog={setDialog}
        handleDelete={handleDelete}
        title={"Order"}
      />
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </>
  );
};
