// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html

import * as actions from "../../../_redux/products/productsActions";
import * as uiHelpers from "../ProductsUIHelpers";

import {
  NoRecordsFoundMessage,
  getHandlerTableChange,
  getSelectRow,
  getSelectRowCharge,
  sortCaret,
} from "../../../../../../_metronic/_helpers";
import React, { useEffect, useMemo } from "react";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import BootstrapTable from "react-bootstrap-table-next";
import NoRecord from "../../../../../../_metronic/_partials/widgets/NoRecord";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { useProductsUIContext } from "../ProductsUIContext";
import { useParams } from "react-router-dom";

export function ProductsTable({ getData, onStatusResult }) {
  let history = useHistory();
  const params = useParams();

  // Products UI Context
  const productsUIContext = useProductsUIContext();
  const productsUIProps = useMemo(() => {
    return {
      ids: productsUIContext.ids,
      setIds: productsUIContext.setIds,
      queryParams: productsUIContext.queryParams,
      setQueryParams: productsUIContext.setQueryParams,
      openEditProductPage: productsUIContext.openEditProductPage,
      openDeleteProductDialog: productsUIContext.openDeleteProductDialog,
    };
  }, [productsUIContext]);

  const modifiedQueryParams = {
    ...productsUIContext.queryParams,
    franchiseId: params.id ? params.id : "",
  };

  const dispatch = useDispatch();
  const intl = useIntl();
  // Getting curret state of products list from store (Redux)
  const { currentState, permission } = useSelector(
    (state) => ({
      currentState: state.products.list,
      permission: state.auth.permission,
    }),
    shallowEqual
  );

  const { entities, totalCount, loading } = currentState;
  // Products Redux state
  useEffect(() => {
    // clear selections list
    // server call by queryParams
    dispatch(actions.fetchProducts(modifiedQueryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsUIProps.queryParams, dispatch]);
  // Table columns

  useEffect(() => {
    if (entities?.length) {
      const statusResult = getData(entities);
      onStatusResult(statusResult);
    }
  }, [
    entities,
    getData,
    onStatusResult,
    entities?.length,
    productsUIContext.ids,
  ]);

  const columns = [
    {
      dataField: "name",
      text: intl.formatMessage({ id: "Product.Name" }),
      sort: true,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return <span>{data?.name}</span>;
      },
    },
    // {
    //   dataField: "stripe_product_id",
    //   text: intl.formatMessage({ id: "Product.ID" }),
    //   sort: true,
    //   sortCaret: sortCaret,
    //   formatter: (e, data) => {
    //     return <span>{data?.stripe_product_id}</span>;
    //   },
    // },
    {
      dataField: "type",
      text: intl.formatMessage({ id: "Product.Type" }),
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return (
          <span>
            {data?.type === "charge"
              ? "Charge"
              : data?.type === "plan"
              ? "Plan"
              : data?.type === "addon"
              ? "Addon"
              : "-"}
          </span>
        );
      },
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "Status" }),
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return (
          <span
            className={`label label-lg label-light-${
              data && data.status === 0 ? "danger" : "success  "
            } label-inline`}
          >
            {data && data.status === 1 ? "Active" : "Archive"}
          </span>
        );
      },
    },
  ];

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: productsUIProps.queryParams.perPage,
    page: productsUIProps.queryParams.pageNumber,
    ids: productsUIProps?.ids,
    setIds: productsUIProps.setIds,
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      if (!params.id) {
        history.push(`products/product-details/${row?.stripe_product_id}`);
      } else {
        history.push(`products/${row?.stripe_product_id}`);
      }
    },
  };

  const selectRow = {
    mode: "checkbox",
    hideSelectAll: true,
    hideSelectColumn: true,
  };

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={loading}
              paginationProps={paginationProps}
              entities={entities}
            >
              <BootstrapTable
                rowEvents={rowEvents}
                hover
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                bordered={false}
                remote
                keyField="id"
                data={entities === null ? [] : entities}
                columns={columns}
                selectRow={
                  permission?.setting_product?.delete
                    ? getSelectRow({
                        entities,
                        ids: productsUIProps.ids,
                        setIds: productsUIProps.setIds,
                        productId: true,
                      })
                    : selectRow
                }
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  productsUIProps.setQueryParams
                )}
                {...paginationTableProps}
              ></BootstrapTable>
              {(entities && entities?.length) || loading ? "" : <NoRecord />}
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
