/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { Card, CardBody } from "../../../../_metronic/_partials/controls";
import {
  // fetchContactCall,
  fetchContactDeal,
  fetchContactDetail,
  // fetchContactEmailList,
  // fetchContactNotesList,
  // fetchContactRevertEmail,
  // fetchContactSmsList,
  // fetchContactTasksList,
  fetchContactfeedList,
} from "../_redux/contact/contactAction";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { AddAttachment } from "../canvas/AddAttachment";
import { ContactDetailTop } from "../components/ContactDetailTop";
import { ContactTabs } from "../components/ContactTabs";
import { ContactUsers } from "../components/ContactUsers";
import { CreateContactOffCanvas } from "../canvas/CreateContactOffCanvas";
import DealsTable from "../Table/DealTable";
import { initIds } from "../../../../_metronic/_partials/controls/RightPanel";
import { useSubheader } from "../../../../_metronic/layout";

export const ContactDetails = (props) => {
  const subheader = useSubheader();
  subheader.setTitle("Contact Details");

  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState("feeds");
  const [openCanva, setOpenCanva] = useState(false);

  const {
    contactDetail,
    notesList,
    tasksList,
    emailList,
    smsList,
    feedList,
    dealList,
    callList,
    revertEmail,
    permission,
  } = useSelector(
    (state) => ({
      contactDetail: state.contact.detail,
      notesList: state.contact.notesList,
      tasksList: state.contact.tasksList,
      emailList: state.contact.emailList,
      smsList: state.contact.smsList,
      feedList: state.contact.feedList,
      dealList: state.contact.dealList,
      callList: state.contact.callList,
      revertEmail: state.contact.revertEmail,
      permission: state.auth.permission,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (props?.match?.params?.id || props.ids) {
      dispatch(fetchContactDetail(props?.match?.params?.id || props.ids));
      // dispatch(fetchContactNotesList(props?.match?.params?.id || props.ids));
      // dispatch(fetchContactTasksList(props?.match?.params?.id || props.ids));
      // dispatch(fetchContactEmailList(props?.match?.params?.id || props.ids));
      // dispatch(fetchContactSmsList(props?.match?.params?.id || props.ids));
      dispatch(fetchContactfeedList(props?.match?.params?.id || props.ids));
      dispatch(fetchContactDeal(props?.match?.params?.id || props.ids));
      // dispatch(fetchContactRevertEmail(props?.match?.params?.id || props.ids));
    }
  }, [dispatch, props?.match?.params?.id, props?.ids]);

  // useEffect(() => {
  //   if (contactDetail?.user_data?.phone) {
  //     dispatch(fetchContactCall(contactDetail?.user_data?.phone));
  //   }
  // }, [contactDetail, dispatch]);
  const init = ["kt_quick_panelone"];

  useEffect(() => {
    initIds(init);
  }, [init]);

  return (
    <>
      <ContactDetailTop
        setActiveTab={setActiveTab}
        detail={contactDetail}
        setDealData={props?.setDealData}
        dealData={props?.dealData}
      />
      <div className="row mt-5">
        <div
          className={`col-md-12  ${
            props.type === "customer" ? "" : "col-lg-4"
          } contact-dtl gutter-c`}
        >
          <ContactUsers
            detailData={contactDetail}
            setOpenCanva={setOpenCanva}
          />
        </div>
        <div
          className={`col-md-12 ${
            props.type === "customer" ? "" : "col-lg-8"
          }  gutter-c mb-7`}
        >
          <ContactTabs
            ID={props?.match?.params?.id || props.ids}
            notesList={notesList}
            tasksList={tasksList}
            emailList={emailList}
            smsList={smsList}
            feedList={feedList}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            callList={callList}
            detailData={contactDetail}
            revertEmail={revertEmail}
            permission={permission}
            callId={props?.match?.params?.id || props.ids}
          />
        </div>
      </div>
      {openCanva ? (
        <>
          <CreateContactOffCanvas
            title={"Contact Details"}
            type={"edit"}
            contactDetail={contactDetail ? contactDetail : ""}
            setOpenCanva={setOpenCanva}
          />
          <div
            className="offcanvas-overlay"
            onClick={() => setOpenCanva(false)}
          ></div>
        </>
      ) : (
        ""
      )}
      <AddAttachment />

      <div className="order-2 order-xxl-1 ">
        <Card>
          <CardBody>
            <DealsTable checkbox={true} dealList={dealList} type="contact" />
          </CardBody>
        </Card>
      </div>
    </>
  );
};
