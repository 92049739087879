import React from "react";
import { Spinner } from "react-bootstrap";

export default function SpinnerLoader() {
  return (
    <div className="mt-8" style={{ textAlign: "center" }}>
      <Spinner animation="border" variant="primary" />
    </div>
  );
}
