import { Modal } from "react-bootstrap";
import React from "react";
import { canvaCancel } from "../../../utils/canvaCloseFunction";
import { useIntl } from "react-intl";

const CancelDialog = ({ dialog, setDialog, formik }) => {
  const intl = useIntl();

  const handleClose = () => {
    setDialog(false);
  };
  const handleSuccess = () => {
    canvaCancel("kt_change_tasks_panel_close");
    formik.resetForm();
    setDialog(false);
  };

  return (
    <Modal show={dialog} aria-labelledby="example-modal-sizes-title-lg">
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Close Window
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>Do you want to discard changes ?</span>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="reset"
            onClick={() => handleClose()}
            className="btn btn-light btn-elevate"
          >
            {intl.formatMessage({ id: "No" })}
          </button>
          <> </>
          <button
            type="submit"
            onClick={() => handleSuccess()}
            className="btn btn-delete btn-elevate"
          >
            {intl.formatMessage({ id: "Yes" })}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default CancelDialog;
