import React, { useEffect, useState } from "react";
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { feedsCreate, feedsUpdate } from "../../_redux/feeds/feedsCrud";
import { fetchFeeds, fetchProfileFeeds } from "../../_redux/feeds/feedsAction";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import Dropdown from "./components/dropdown";
import NewBreadCrumbs from "../../../../../_metronic/layout/components/subheader/components/NewBreadCrumbs";
import NotAuthorized from "../../../../../_metronic/_partials/widgets/NotAuthorized";
import SVG from "react-inlinesvg";
import SnackBarTool from "../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { fetchPermission } from "../../../../pages/redux/permission";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export const Feeds = () => {
  const classes = useStyles();
  const intl = useIntl();
  // Use redux hook to dispatch an action
  const dispatch = useDispatch();
  // Use redux hook to access state
  const { profileList, feedsList, permission } = useSelector(
    (state) => ({
      profileList: state.feeds.profilelist,
      feedsList: state.feeds.list.entities,
      permission: state.auth.permission,
    }),
    shallowEqual
  );
  // State for snackbar messages
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });

  // Fetch data when component mounts
  useEffect(() => {
    dispatch(fetchProfileFeeds());
    dispatch(fetchFeeds());
    dispatch(fetchPermission());
  }, [dispatch]);

  // Handle check event for feeds
  const handleFeedCheck = (e, id, name) => {
    setSnack({ call: false, type: "", message: "" });
    const data = {
      role_id: id,
      name: name,
    };
    // If checkbox is checked, create a feed
    if (e.target.checked) {
      feedsCreate(data)
        .then((res) => {
          setSnack({
            call: true,
            type: "success",
            message: res.data.message,
          });
        })
        .catch((error) => {
          setSnack({
            call: true,
            type: "error",
            message: error.response.data.message,
          });
        });
    } else {
      // If checkbox is not checked, update the feed
      feedsUpdate(id, data)
        .then((res) => {
          dispatch(fetchFeeds());
          setSnack({ call: true, type: "success", message: res.data.message });
        })
        .catch((error) => {
          setSnack({
            call: true,
            type: "error",
            message: error.response.data.message,
          });
        });
    }
  };

  return (
    <>
      {/* Render breadcrumbs component */}
      <NewBreadCrumbs title={intl.formatMessage({ id: "Timeline" })} />
      {/* Check if user has permission to view feeds */}
      {permission?.setting_feed?.view ? (
        <>
          {/* Card component to display feeds */}
          <div className="card card-custom gutter-b ">
            <div className="card-body feeds-section">
              {/* Title for feeds section */}
              <div className="d-flex ">
                <h3 className="card-title align-items-start flex-column d-flex">
                  {" "}
                  <span className="font-weight-bolder text-dark">
                    {intl.formatMessage({ id: "Feed.Profile" })}
                  </span>
                </h3>
              </div>
              {/* Map through list of profiles and render an expansion panel for each profile */}
              {profileList && profileList.length
                ? profileList.map((data, index) => (
                    <div className={classes.root} key={index}>
                      <ExpansionPanel>
                        <ExpansionPanelSummary
                          expandIcon={
                            <span className="svg-icon svg-icon-sm svg-icon-primary">
                              <SVG
                                title=" "
                                className="h-25 align-self-end"
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Navigation/Angle-double-down.svg"
                                )}
                              ></SVG>
                            </span>
                          }
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className={classes.heading}>
                            {data.name}
                          </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                          <Dropdown
                            handleFeedCheck={handleFeedCheck}
                            profile={data}
                            feedsList={feedsList}
                          />
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    </div>
                  ))
                : ""}
            </div>
          </div>
          {/* Render snackbar component for messages */}
          {snack.call ? <SnackBarTool {...snack} /> : ""}
        </>
      ) : (
        <NotAuthorized />
      )}
    </>
  );
};
