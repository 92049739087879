import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./UserUIHelpers";

const UserUIContext = createContext();

export function useUserUIContext() {
  return useContext(UserUIContext);
}

export const UserUIConsumer = UserUIContext.Consumer;

export function UserUIProvider({ UserUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    openEditProductPage: UserUIEvents?.openEditProductPage,
    openDeleteProductDialog: UserUIEvents?.openDeleteProductDialog,
    openDeleteUserDialog: UserUIEvents?.openDeleteUserDialog,
    openFetchUserDialog: UserUIEvents?.openFetchUserDialog,
    openUpdateUserStatusDialog: UserUIEvents?.openUpdateUserStatusDialog,
  };

  return (
    <UserUIContext.Provider value={value}>{children}</UserUIContext.Provider>
  );
}
