/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import CheckboxDetail from "./user-components/CheckboxDetail";
import NewBreadCrumbs from "../../../../../_metronic/layout/components/subheader/components/NewBreadCrumbs";
import { NewProfileCanva } from "./user-canva/NewProfile";
import SVG from "react-inlinesvg";
import { fetchProfileDetail } from "../../_redux/user/userAction";
import { initIds } from "../../../../../_metronic/_partials/controls/RightPanel";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { useIntl } from "react-intl";

const ProfileDetailPage = (props) => {
  const intl = useIntl();
  // initialize the list of ids for the useEffect hook
  const init = ["kt_new_profile_panel"];

  // useEffect hook that calls the initIds function
  useEffect(() => {
    initIds(init);
  }, [init]);

  // initialize state variables for permissArray and check
  const [permissArray, setPermissArray] = useState();
  const [check, setCheck] = useState(false);

  // get the dispatch function from the useDispatch hook
  const dispatch = useDispatch();

  // get the detailData from the redux store using the useSelector hook
  const { detailData } = useSelector(
    (state) => ({
      detailData: state.userDetail.profile.detail,
    }),
    shallowEqual
  );

  // useEffect hook that fetches the profile detail data
  useEffect(() => {
    dispatch(fetchProfileDetail(props?.match?.params?.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // function that takes in an array of strings and returns an object
  function display(x) {
    const obj = {};
    for (let item of x) {
      // split the string by the "_" character
      const split = item.split("_");
      let key = "";
      for (let i = 0; i < split?.length - 1; i++) {
        key += `${split[i]}_`;
      }

      // set the value of the key in the object to an object with a property named after the last item in the split array
      obj[key.substring(0, key?.length - 1)] = {
        ...obj[key.substring(0, key?.length - 1)],
        [split[split.length - 1]]: true,
      };
    }
    return obj;
  }

  // useEffect hook that sets the permissArray state variable
  useEffect(() => {
    if (detailData && detailData.permissions) {
      // call the display function with the permissions array and set the result as the value of permissArray
      const obj = display(detailData && detailData.permissions);
      setPermissArray(obj);
    }
  }, [detailData, detailData.permissions, dispatch]);

  // set the value of check to true after 1000 milliseconds (1 second)
  setTimeout(() => {
    setCheck(true);
  }, 1000);
  return (
    <>
      <div>
        {/* Render the breadcrumbs component with the title 'USERS' */}
        <NewBreadCrumbs title={intl.formatMessage({ id: "USERS" })} />
        {/* Render a card component with user detail information */}
        <div className="row">
          <div className="col-12">
            <div className="container card card-custom card-stretch">
              <div className="card-body">
                <div className="mt-4 font-weight-bolder font-size-h5 text-dark-75 text-hover-primary ">
                  <div className="d-flex ">
                    <h3 className="card-title align-items-start flex-column d-flex">
                      {/* Render user's name and status */}
                      <span className="font-weight-bolder text-dark">
                        {/* If detailData exists and has a 'name' property, display it. Otherwise, display '-' */}
                        {detailData?.name || "-"}
                      </span>
                      <span
                        className={`text-${
                          detailData &&
                          detailData.status &&
                          detailData.status === 1
                            ? "success"
                            : "danger"
                        } font-weight-bold font-size-sm mt-3`}
                      >
                        {detailData &&
                        detailData.status &&
                        detailData.status === 1
                          ? "Active"
                          : "Inactive"}
                      </span>
                    </h3>
                    {/* Render an SVG icon with the title 'edit' and the source '/media/svg/icons/Design/Edit.svg' */}
                    <span
                      className="svg-icon svg-icon-sm svg-icon-primary ml-3"
                      id="kt_new_profile_panel_toggle"
                      style={{ cursor: "pointer" }}
                    >
                      <SVG
                        style={{ color: "orange" }}
                        title="edit"
                        className="h-22 align-self-end"
                        src={toAbsoluteUrl("/media/svg/icons/Design/Edit.svg")}
                      ></SVG>
                    </span>
                  </div>
                </div>
                <div
                  className="d-flex mt-4"
                  style={{ justifyContent: "space-between" }}
                >
                  <div>
                    <span className="font-weight-bolder ">
                      {intl.formatMessage({ id: "Description" })}
                    </span>
                    <br />
                    <span className="text-muted">
                      {detailData && detailData.description
                        ? detailData.description
                        : "-"}
                    </span>
                  </div>
                </div>
                {/* If permissArray and check props are both true, render the CheckboxDetail component with the permissArray prop */}
                <div className="table-responsive mt-6">
                  <br />
                  {permissArray && check ? (
                    <CheckboxDetail permissArray={permissArray} />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NewProfileCanva
        title={"Edit Profile"}
        detailData={detailData}
        permissArray={permissArray}
        type="edit"
      />
    </>
  );
};

export default ProfileDetailPage;
