import React, { useEffect, useMemo, useState } from "react";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import { allTasks, stages, view } from "../_mocks/staticData";
import { shallowEqual, useSelector, useDispatch } from "react-redux";

import { Formik } from "formik";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { dealsFranchiseListing } from "../../ContactDeals/_redux/deals/dealsCrud";
import { isEqual } from "lodash";
import { useIntl } from "react-intl";
import { useInvoiceUIContext } from "../InvoiceUIContext";
import { CustomeSearch } from "../../../../_metronic/_partials/controls/CustomeSearch";
import { fetchAgent } from "../../ContactDeals/_redux/contact/contactAction";
import { InputGroup } from "react-bootstrap";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import DatePicker from "react-datepicker";
import moment from "moment";

const prepareFilter = (queryParams, values) => {
  const {
    searchText,
    search_type,
    search_view,
    search_owner,
    search_franchise,
    search_stage,
    priority,
    invoice,
    from_date,
    to_date,
    status,
    search,
    type,
  } = values;
  console.log("type", type);

  const newQueryParams = { ...queryParams };
  const filter = {};

  filter.search = search ? search : "";
  filter.search_view = search_view ? search_view : "";
  filter.search_owner = search_owner ? search_owner : "";
  filter.search_franchise = search_franchise ? search_franchise : "";
  filter.priority = priority ? priority : "";
  filter.search_type = search_type ? search_type : "";
  filter.search_stage = search_stage ? search_stage : "";
  filter.invoice = invoice ? invoice : "";
  filter.from_date = from_date ? from_date : "";
  filter.to_date = to_date ? to_date : "";
  filter.status = status ? status : "";
  filter.type = type ? type : "";

  newQueryParams.filter = filter;
  return newQueryParams;
};
export function TasksFilter({ check, pageOn, type }) {
  const intl = useIntl();
  const InvoiceUIContext = useInvoiceUIContext();

  const [franchises, setFranchise] = useState([]);

  const { currentState, user, admin } = useSelector(
    (state) => ({
      currentState: state.contact.agent,
      user: state.auth,
      admin: state.auth.user,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const tasksUIProps = useMemo(() => {
    return {
      setQueryParams: InvoiceUIContext.setQueryParams,
      queryParams: InvoiceUIContext.queryParams,
    };
  }, [InvoiceUIContext]);

  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(tasksUIProps.queryParams, values);

    if (!isEqual(newQueryParams, tasksUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      tasksUIProps.setQueryParams(newQueryParams);
    }
  };

  const handleFranchiseList = () => {
    dealsFranchiseListing().then((res) => {
      setFranchise(res.data.data);
    });
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      handleFranchiseList();
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);
  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(fetchAgent());
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [dispatch]);
  const { role, userData } = useSelector(
    (state) => ({
      role: state.auth.user.roles,
      userData: state.auth.user,
    }),
    shallowEqual
  );
  console.log("role", role);

  return (
    <>
      <Formik
        initialValues={{
          search_type: "", // values => All=""/Susspended=0/Active=1/Pending=2
          search_view: "",
          search_owner: "",
          search_franchise:
            role && role?.length && role[0] === "franchise-owner"
              ? userData?.franchise_id
              : "", // values => All=""/Business=0/Individual=1
          searchText: "",
          search_stage: "",
          priority: "",
          invoice: "",
          from_date: "",
          to_date: "",
          status: "",
          type: "",
        }}
        onSubmit={(values) => {
          console.log("values", values);
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,

          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row d-flex justify-content-between">
              <div className="col-lg-10">
                {type != "sub" ? (
                  <>
                    <div className="row">
                      <div
                        className="col-xxl-3 col-md-6 order-flt mt-2"
                        style={{ display: "flex" }}
                      >
                        <span className="mt-3">
                          {intl.formatMessage({ id: "Franchise" })}:&nbsp;{" "}
                        </span>
                        <FormControl fullWidth>
                          <Select
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                            }}
                            className="form-control"
                            onChange={(e) => {
                              setFieldValue("search_franchise", e.target.value);
                              handleSubmit();
                            }}
                            onBlur={handleBlur}
                            value={values.search_franchise}
                            displayEmpty
                            IconComponent={(props) => (
                              <i {...props}>
                                <KeyboardArrowDownIcon />
                              </i>
                            )}
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            <MenuItem value="">All franchises</MenuItem>
                            {franchises.map((data, index) => (
                              <MenuItem key={index} value={data.id}>
                                {data.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div
                        className="col-xxl-3 col-md-6 order-flt mt-2"
                        style={{ display: "flex" }}
                      >
                        <span className="mt-3">Search&nbsp;Filter:&nbsp; </span>
                        <FormControl fullWidth>
                          <Select
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                            }}
                            className="form-control"
                            onChange={(e) => {
                              setFieldValue("invoice", e.target.value);
                              if (e.target.value === "") {
                                handleSubmit();
                              }
                            }}
                            onBlur={handleBlur}
                            value={values.invoice}
                            displayEmpty
                            IconComponent={(props) => (
                              <i {...props}>
                                <KeyboardArrowDownIcon />
                              </i>
                            )}
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            <MenuItem value="">Filter</MenuItem>
                            <MenuItem
                              value={
                                pageOn === "credit" ? "created_at" : "issue_on"
                              }
                            >
                              {pageOn === "credit" ? "Created at" : "Issued on"}
                            </MenuItem>
                            {/* {pageOn !== "credit" &&

                          <MenuItem value={"paid_on"}>
                            Paid on
                          </MenuItem>

                        } */}
                          </Select>
                        </FormControl>
                      </div>

                      {values?.invoice !== "" && (
                        <div
                          className={`col-lg-5  d-flex order-flt mb-4 ${
                            pageOn !== "credit" ? "mt-2" : "mt-2"
                          }`}
                        >
                          <span className="mt-3">Date:&nbsp;</span>{" "}
                          <InputGroup className="calendr">
                            <DatePicker
                              fullWidth
                              fixedHeight
                              className="form-control"
                              wrapperClassName="datepicker"
                              dateFormat="dd-MM-yyyy"
                              placeholderText="From Date"
                              selected={Date.parse(values?.from_date)}
                              startDate={Date.parse(values?.from_date)}
                              endDate={Date.parse(values?.to_date)}
                              // selectsRange
                              // isClearable
                              onChange={(dates) => {
                                // const [start, end] = dates;
                                setFieldValue(
                                  "from_date",
                                  dates
                                    ? moment(dates).format("yyyy-MM-DD")
                                    : ""
                                );
                                setFieldValue("to_date", "");

                                // if (dates === null) {
                                //   handleSubmit();
                                // }
                              }}
                            />

                            <CalendarTodayIcon />
                          </InputGroup>
                          &nbsp;&nbsp;
                          <InputGroup className="calendr">
                            <DatePicker
                              fullWidth
                              fixedHeight
                              className="form-control"
                              wrapperClassName="datepicker"
                              dateFormat="dd-MM-yyyy"
                              placeholderText="To Date"
                              selected={Date.parse(values?.to_date)}
                              startDate={Date.parse(values?.from_date)}
                              endDate={Date.parse(values?.to_date)}
                              // selectsRange
                              onChange={(dates) => {
                                // setFieldValue(
                                //   "from_date",
                                //   start ? moment(start).format("yyyy-MM-DD") : ""
                                // );
                                setFieldValue(
                                  "to_date",
                                  dates
                                    ? moment(dates).format("yyyy-MM-DD")
                                    : ""
                                );
                                // if (dates || (start === null && end === null)) {
                                handleSubmit();
                                // }
                              }}
                            />

                            <CalendarTodayIcon />
                          </InputGroup>
                        </div>
                      )}
                      {pageOn !== "credit" && (
                        <div
                          className="col-xxl-3 col-md-6 order-flt mt-2"
                          style={{ display: "flex" }}
                        >
                          <span className="mt-3">Status:&nbsp;</span>
                          <FormControl fullWidth>
                            <Select
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left",
                                },
                                getContentAnchorEl: null,
                              }}
                              className="form-control"
                              onChange={(e) => {
                                setFieldValue("status", e.target.value);

                                handleSubmit();
                              }}
                              onBlur={handleBlur}
                              value={values.status}
                              displayEmpty
                              IconComponent={(props) => (
                                <i {...props}>
                                  <KeyboardArrowDownIcon />
                                </i>
                              )}
                              inputProps={{ "aria-label": "Without label" }}
                            >
                              <MenuItem value="">Filter</MenuItem>
                              <MenuItem value={"draft"}>Draft</MenuItem>
                              <MenuItem value={"open"}>Open</MenuItem>
                              <MenuItem value={"paid"}>Paid</MenuItem>
                              <MenuItem value={"void"}>Void</MenuItem>
                              <MenuItem value={"uncollectible"}>
                                Uncollectible
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="row">
                      <div
                        className="col-xxl-3 col-md-6 order-flt mt-2"
                        style={{ display: "flex" }}
                      >
                        <span className="mt-3">Status:&nbsp;</span>
                        <FormControl fullWidth>
                          <Select
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                            }}
                            className="form-control"
                            onChange={(e) => {
                              setFieldValue("status", e.target.value);

                              handleSubmit();
                            }}
                            onBlur={handleBlur}
                            value={values.status}
                            displayEmpty
                            IconComponent={(props) => (
                              <i {...props}>
                                <KeyboardArrowDownIcon />
                              </i>
                            )}
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            <MenuItem value="">Filter</MenuItem>
                            <MenuItem value={"active"}>Active</MenuItem>
                            <MenuItem value={"past_due"}>Past Due</MenuItem>
                            <MenuItem value={"canceled"}>Cancelled</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      {/* <div
                        className="col-xxl-3 col-md-6 order-flt mt-2"
                        style={{ display: "flex" }}
                      >
                        {console.log("formik", values)}
                        <span className="mt-3">Type:&nbsp;</span>
                        <FormControl fullWidth>
                          <Select
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                            }}
                            className="form-control"
                            onChange={(e) => {
                              setFieldValue("type", e.target.value);
                              handleSubmit();
                            }}
                            onBlur={handleBlur}
                            value={values.type}
                            displayEmpty
                            IconComponent={(props) => (
                              <KeyboardArrowDownIcon {...props} />
                            )}
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            <MenuItem value="">Filter</MenuItem>
                            {admin.roles[0] !== "super-admin" &&
                              user?.user?.franchise_plan_types?.includes(
                                "pod"
                              ) && <MenuItem value="pod">Pod</MenuItem>}
                            {admin.roles[0] !== "super-admin" &&
                              user?.user?.franchise_plan_types?.includes(
                                "container"
                              ) && (
                                <MenuItem value="container">Container</MenuItem>
                              )}
                            {admin.roles[0] === "super-admin" && (
                              <MenuItem value="pod">Pod</MenuItem>
                            )}
                            {admin.roles[0] === "super-admin" && (
                              <MenuItem value="container">Container</MenuItem>
                            )}
                          </Select>
                        </FormControl>
                      </div> */}
                    </div>
                  </>
                )}
              </div>
              <div className="col-lg-2 order-flt mt-2">
                {type != "sub" && (
                  <CustomeSearch
                    intl={intl}
                    handleBlur={handleBlur}
                    values={values}
                    setFieldValue={setFieldValue}
                    handleSubmit={handleSubmit}
                    topData={"22px"}
                  />
                )}
                {/* <div style={{ position: "relative" }}>
                  <input
                    style={{ paddingRight: "40px" }}
                    type="text"
                    className="form-control"
                    name="searchText"
                    placeholder={intl.formatMessage({ id: "Search" })}
                    onBlur={handleBlur}
                    value={values.searchText}
                    onChange={(e) => {
                      setFieldValue("searchText", e.target.value);
                      handleSubmit();
                    }}
                  />
                  <i
                    style={{
                      position: "absolute",
                      top: "22px",
                      transform: "translateY(-50%)",
                      right: "15px",
                    }}
                    className="fa fa-search taskSearch"
                    aria-hidden="true"
                  ></i>
                </div> */}
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
