/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React, { useState } from "react";

import { Form } from "react-bootstrap";
import { useFormik } from "formik";
import { CancelSubscriptionSchema } from "../order-form/validationSchema";
import SnackBarTool from "../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import {
  cancelSubscription,
  cancellationPrice,
} from "../_redux/order/orderCrud";
import { useIntl } from "react-intl";
import { canvaCancel } from "../../../utils/canvaCloseFunction";
import {
  fetchBillingCardDetail,
  fetchMyOrderDetailCard,
  fetchMyOrderTimeline,
  fetchPaymentInvoice,
} from "../_redux/order/orderAction";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { MenuItem, FormControl, Select } from "@material-ui/core/";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import {
  convertGBPtoPoundSymbol,
  isDateToday,
  isDateWithinNext3Days,
  isOneDayBefore,
  isOneWeekdayGap,
  isOneWorkingDayBefore,
} from "../../../utils/commonFunction";
import { useEffect } from "react";
export function CancelSubscription({ orderDetail, billingCard, eightCharge }) {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [loading, setLoading] = useState(false);
  const [price, setPrice] = useState(null);
  const [remain, setRemain] = useState(0);

  const initialValues = {
    order_id: orderDetail?.id || "",
    franchise_id: orderDetail?.franchise_id || "",
    subscription_id: orderDetail?.subscription_id || "",
    credit_option: "",
    cancel_reason: "",
    refundable_credits_handling: "no_action",
    is_cancel_charge: "0",
  };

  const { orderDropDown } = useSelector(
    (state) => ({
      orderDropDown: state.order.orderDropDown,
    }),
    shallowEqual
  );

console.log("orderDropDown",orderDropDown)

  const handleSubmit = (values, actions) => {
    setSnack({
      call: false,
      type: "",
      message: "",
    });
    setLoading(true);
    if (orderDetail.is_container == 1) {
      values.is_container = 1;
      if (remain) {
        values.eighty_percent_amount = remain;
      }
    }
    if (values?.credit_option === "fullRefund") {
      values.credit_option = "fullRefund";
      values.is_cancel_charge = 1
    }
    // delete values.credit_option;
    cancelSubscription(values)
      .then((res) => {
        setSnack({ call: true, type: "success", message: res.data.message });
        actions.resetForm({ values: "" });
        canvaCancel("kt_CancelSubscription_panel_close");
        setLoading(false);
        dispatch(fetchMyOrderDetailCard(orderDetail?.id));
        dispatch(fetchMyOrderTimeline(orderDetail?.id));
        dispatch(
          fetchPaymentInvoice(
            orderDetail?.id,
            orderDetail?.franchise_id,
            orderDetail?.subscription_id
          )
        );
        dispatch(
          fetchBillingCardDetail(
            orderDetail?.franchise_id,
            orderDetail?.subscription_data?.id
          )
        );
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
        setLoading(false);
      });
  };

  const handleReset = () => { };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: CancelSubscriptionSchema,
    onSubmit: handleSubmit,
    onReset: handleReset,
  });

  const handleDiscard = () => {
    canvaCancel("kt_CancelSubscription_panel_close");
    formik.resetForm();
  };

  useEffect(() => {
    if (
      orderDetail?.collection_date &&
      isDateWithinNext3Days(orderDetail?.collection_date)
    ) {
      cancellationPrice().then((res) => setPrice(res.data.data));
    }
  }, [orderDetail.collection_date]);
  useEffect(() => {
    if (isDateToday(orderDetail?.collection_date)) {
      formik.setFieldValue("credit_option", "none");
    } else {
      formik.setFieldValue("credit_option", "full");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDetail.collection_date]);
  return (
    <div
      id="kt_CancelSubscription_panel"
      className="offcanvas offcanvas-right pt-5 pb-10"
    >
      {/*begin::Header*/}
      <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
        <div
          className="offcanvas-close mt-n1 pr-5"
          style={{ position: "absolute", top: "15px", right: "10px" }}
        >
          <span
            onClick={() => handleDiscard()}
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
          >
            <i className="ki ki-close icon-sm text-muted"></i>
          </span>
        </div>
        <div id="kt_CancelSubscription_panel_close"></div>
      </div>
      {/*end::Header*/}
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <div className="offcanvas-content px-10 ">
          <div>
            <h5>{intl.formatMessage({ id: "Cancel.Subscription" })}</h5>
          </div>
          <div
            className="fade pt-3 pr-5 mr-n5 scroll active show ps--active-y mt-10"
            id="kt_quick_panel_logs_kt_CancelSubscription_panel"
          >
            <div>
              <Form.Group className="mb-5">
                <Form.Label>Cancel Reason</Form.Label>
                <FormControl fullWidth>
                  <Select
                    name="cancel_reason"
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                    IconComponent={(props) => (
                      <i {...props}>
                        <KeyboardArrowDownIcon />
                      </i>
                    )}
                    inputProps={{ "aria-label": "Without label" }}
                    displayEmpty
                    className={`form-control w-100 ${getInputClasses(
                      "cancel_reason"
                    )}`}
                    {...formik.getFieldProps("cancel_reason")}
                  >
                    <MenuItem value="">Reason Code</MenuItem>
                    {orderDropDown &&
                      orderDropDown?.cancelReasons &&
                      orderDropDown.cancelReasons.length &&
                      orderDropDown.cancelReasons.length > 0 ? (
                      orderDropDown.cancelReasons.map((item, index) => (
                        <MenuItem value={item?.title} key={index}>
                          {item?.title}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="no"></MenuItem>
                    )}
                  </Select>
                </FormControl>
                {formik.touched.cancel_reason && formik.errors.cancel_reason ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.cancel_reason}
                    </div>
                  </div>
                ) : null}
              </Form.Group>
            </div>
            <div>
              <Form.Group className="mb-5">
                <Form.Label>Cancel Option</Form.Label>
                <FormControl fullWidth>
                  <Select
                    name="credit_option"
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                    IconComponent={(props) => (
                      <i {...props}>
                        <KeyboardArrowDownIcon />
                      </i>
                    )}
                    inputProps={{ "aria-label": "Without label" }}
                    displayEmpty
                    className={`form-control w-100 ${getInputClasses(
                      "credit_option"
                    )}`}
                    {...formik.getFieldProps("credit_option")}
                  // disabled={isDateToday(orderDetail?.collection_date)}
                  >
                    <MenuItem value="">Cancel Option</MenuItem>
                    {/* {isDateToday(orderDetail?.collection_date) && ( */}
                    <MenuItem value="none">Cancel without refund</MenuItem>
                    <MenuItem value="refund_insurance">
                      Cancel with Protection refund
                    </MenuItem>
                    {/* )} */}
                    {/* {!isDateToday(orderDetail?.collection_date) && (
                      <MenuItem value="prorate">
                        Issue Prorated Credits
                      </MenuItem>
                    )} */}
                    {/* {!isDateToday(orderDetail?.collection_date) && ( */}
                    <MenuItem value="full">Cancel with full refund</MenuItem>
                    {console.log("hello", isOneWeekdayGap(orderDetail?.collection_date))}
                    {orderDetail?.order_details?.pod_plan_type != "Economy" && orderDetail.is_container != 1 && isOneWeekdayGap(orderDetail?.collection_date) &&
                      <MenuItem value="fullRefund">Charge £75 and refund the rest</MenuItem>
                    }
                    {/* )} */}
                  </Select>
                </FormControl>
                {formik.touched.credit_option && formik.errors.credit_option ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.credit_option}
                    </div>
                  </div>
                ) : null}
              </Form.Group>
            </div>
            {/* {orderDetail.is_container == 1 && (
              <div>
                <Form.Group className="mb-5">
                  <Form.Label>Refundable Credits Handling</Form.Label>
                  <FormControl fullWidth>
                    <Select
                      name="refundable_credits_handling"
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                      IconComponent={(props) => (
                        <i {...props}>
                          <KeyboardArrowDownIcon />
                        </i>
                      )}
                      inputProps={{ "aria-label": "Without label" }}
                      displayEmpty
                      className={`form-control w-100 ${getInputClasses(
                        "refundable_credits_handling"
                      )}`}
                      {...formik.getFieldProps("refundable_credits_handling")}
                    >
                      <MenuItem value="">Refundable Credits Handling</MenuItem>

                      <MenuItem value="no_action">No Action</MenuItem>

                      <MenuItem value="schedule_refund">
                        Schedule Refund
                      </MenuItem>
                    </Select>
                  </FormControl>
                  {formik.touched.refundable_credits_handling &&
                    formik.errors.refundable_credits_handling ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.refundable_credits_handling}
                      </div>
                    </div>
                  ) : null}
                </Form.Group>
              </div>
            )} */}
            <div>
              {/* yes -0 , no - 1 */}

            </div>
            {/* {eightCharge && (
              <div>
                <Form.Group className="mb-5">
                  <Form.Label>
                    {intl.formatMessage({ id: "Due.Arrears" })}(&pound;)
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={
                      billingCard?.currency_code
                        ? convertGBPtoPoundSymbol(billingCard?.currency_code)
                        : "-"
                    }
                    className={`form-control  ${getInputClasses("due_amount")}`}
                    name="due_amount"
                    value={billingCard?.in_arrears || 0}
                    disabled
                  />
                  <Form.Control
                    type="text"
                    placeholder={
                      billingCard?.currency_code
                        ? convertGBPtoPoundSymbol(billingCard?.currency_code)
                        : "-"
                    }
                    className={`form-control  ${getInputClasses("due_amount")}`}
                    name="due_amount"
                    value={remain}
                    onChange={(e) =>
                      e.target.value < billingCard?.in_arrears
                        ? setRemain(e.target.value)
                        : billingCard?.in_arrears
                    }
                  // disabled
                  />
                  {formik.touched.due_amount && formik.errors.due_amount ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.due_amount}
                      </div>
                    </div>
                  ) : null}
                </Form.Group>
              </div>
            )} */}
          </div>
        </div>
        <div className="offcanvas-footer">
          <div className="offcanvas-content text-right ">
            <button
              type="reset"
              className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
              onClick={() => handleDiscard()}
            >
              {intl.formatMessage({ id: "CANCEL" })}
            </button>
            <button
              disabled={loading}
              type="submit"
              className="btn btn-primary font-weight-bolder font-size-sm "
            >
              {intl.formatMessage({ id: "SAVE" })}
              {loading && <span className="spinner spinner-white mr-4"></span>}
            </button>
          </div>
        </div>
      </form>
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </div>
  );
}
