/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

import * as Yup from "yup";

import React, { useState } from "react";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Privacy } from "../franchise-form/validateSchema";
import SnackBarTool from "../../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { canvaCancel } from "../../../../../utils/canvaCloseFunction";
import { editFranchisePopDetail } from "../../../_redux/franchises/franchisesCrud";
import { useFormik } from "formik";
import { useIntl } from "react-intl";

export function PrivacyPolicy({ detailData }) {
  const intl = useIntl();
  // Initial values for the form
  const initialValues = {
    franchise_id: detailData && detailData.id ? detailData.id : "",

    privacy_policy:
      detailData && detailData.privacy_policy ? detailData.privacy_policy : "",
    type: "privacy_policy",
  };

  // Use state hook to manage snackbar state
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });

  // Event handler for form submission
  const handleSubmit = (values, actions) => {
    setSnack({ call: false, type: "", message: "" });
    editFranchisePopDetail(values)
      .then((res) => {
        "";
        if (res.data.success) {
          setSnack({
            call: true,
            type: "success",
            message: "Privacy policy updated successfully",
          });
          canvaCancel("kt_privacypolicy_panel_close");
        } else {
          setSnack({ call: true, type: "error", message: res.data.message });
        }
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
  };

  const handleReset = () => {};

  // Use the useFormik hook to handle form logic
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: Privacy(intl, Yup),
    onSubmit: handleSubmit,
    onReset: handleReset,
  });

  return (
    <div
      id="kt_privacypolicy_panel"
      className="offcanvas offcanvas-right pt-5 pb-10"
    >
      {/*begin::Header*/}
      <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
        <div
          className="offcanvas-close mt-n1 pr-5"
          style={{ position: "absolute", top: "15px", right: "10px" }}
        >
          <span
            onClick={() => formik.resetForm()}
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            id="kt_privacypolicy_panel_close"
          >
            <i className="ki ki-close icon-sm text-muted"></i>
          </span>
        </div>
      </div>
      {/*end::Header*/}
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <div className="offcanvas-content px-10 ">
          <div>
            <h5>{intl.formatMessage({ id: "Privacy.Policy" })}</h5>
          </div>
          <div
            id="kt_quick_panel_logs_kt_privacypolicy_panel"
            className="fade pt-3 pr-5 mr-n5 scroll active show ps--active-y mt-10"
          >
            <div className="mt-10 pb-10">
              <CKEditor
                editor={ClassicEditor}
                data={formik.values.privacy_policy}
                onReady={(editor) => {
                  // You can store the "editor" and use when it is needed.
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  formik.setFieldValue("privacy_policy", data);
                }}
                onBlur={(event, editor) => {}}
                onFocus={(event, editor) => {}}
              />
              {formik.touched.privacy_policy && formik.errors.privacy_policy ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.privacy_policy}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="offcanvas-footer">
          <div className="offcanvas-content text-right ">
            <button
              type="reset"
              className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
              onClick={() => canvaCancel("kt_privacypolicy_panel_close")}
            >
              {intl.formatMessage({ id: "CANCEL" })}
            </button>
            <button
              type="submit"
              className="btn btn-primary font-weight-bolder font-size-sm mr-3 "
            >
              {intl.formatMessage({ id: "SAVE" })}
            </button>
          </div>
        </div>
      </form>
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </div>
  );
}
