import * as requestFromServer from "./franchisesCrud";
import { franchiseSlice, callTypes } from "./franchisesSlice";

const { actions } = franchiseSlice;

//franchise list
export const fetchFranchises = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  return requestFromServer
    .findFranchise(queryParams)
    .then((response) => {
      const { meta, data, total_count } = response.data;
      dispatch(actions.franchiseFetchedTotalCount({ totalCount: total_count }));
      dispatch(
        actions.franchiseFetched({
          totalCount: meta.total,
          entities: data,
          loading: false,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";

      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

//franchise total count
export const fetchFranchisesTotalCount = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .findFranchise(queryParams)
    .then((response) => {
      const { total_count } = response.data;

      dispatch(actions.franchiseFetchedTotalCount({ totalCount: total_count }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

//franchise detail
export const fetchFranchisesDetail = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .findFranchiseDetail(queryParams)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.franchiseDetailFetched({ data }));
    })
    .catch((error) => {
      if (error?.response?.status === 404 || error?.response?.status === 400) {
        // Redirect to the error page
        window.location.href = "/error/error-v1"; // Replace '/error' with the actual route of your error page
      }
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

//franchise based postcode action
export const fetchFranchisesPostCode = (queryParams, text) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.fraPostcode }));
  return requestFromServer
    .findFranchisePostCode(queryParams, text)
    .then((response) => {
      const { data, total } = response.data;
      dispatch(actions.franchisePostCodeFetched({ data, total }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.fraPostCode }));
    });
};

//sector based postcode
export const fetchFranchisesSectorPostCode = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.secPostcode }));
  return requestFromServer
    .findFranchiseSectorPostCode(queryParams)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.franchiseSectorPostCodeFetched({ data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.secPostcode }));
    });
};

//timeline
export const fetchTimeline = (queryParams) => (dispatch) => {
  // dispatch(actions.startCall({ callType: callTypes.secPostcode }));
  return requestFromServer
    .getTimeline(queryParams)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.franchiseTimeline({ data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      // dispatch(actions.catchError({ error, callType: callTypes.secPostcode }));
    });
};

//franchises van action
export const fetchFranchisesVan = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.van }));
  return requestFromServer
    .findFranchiseVan(queryParams)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.franchiseDetailVan({ data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.van }));
    });
};

//franchises depot action
export const fetchFranchisesDepot = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.depot }));
  return requestFromServer
    .findFranchiseDepot(queryParams)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.franchiseDetailDepot({ data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.depot }));
    });
};

//franchise driver loader action
export const fetchFranchisesDriverLoader = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.driver }));
  return requestFromServer
    .findFranchiseDriverLoader(queryParams)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.franchiseDetailDriverLoader({ data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.driver }));
    });
};

//franchises depot search
export const fetchFranchisesDepotSearch = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .findFranchiseDepotSearch(queryParams)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.franchiseDetailSearch({ data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

//franchises driver search
export const fetchFranchisesDriverSearch = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.driverAddList }));
  return requestFromServer
    .findFranchiseDriverSearch(queryParams)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.franchiseDriverSearch({ data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(
        actions.catchError({ error, callType: callTypes.driverAddList })
      );
    });
};

export const fetchFranchisesCountry = (queryParams, promotion) => (
  dispatch
) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .findFranchiseCountry(queryParams, promotion)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.franchiseCountryFetched({ data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

//working days
export const fetchWorkingDays = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .findWorkingDays(queryParams)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.franchiseWorkingDaysFetched({ data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
//site list
export const fetchSiteList = (franchiseId, productId = "") => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.siteList }));
  return requestFromServer
    .getSiteList(franchiseId, productId)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.franchiseSiteListFetched({ data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.siteList }));
    });
};
//site list
export const fetchClassList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.classList }));
  return requestFromServer
    .getClassList(queryParams)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.franchiseClassListFetched({ data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.classList }));
    });
};

export const resetStateFranchise = () => (dispatch) => {
  dispatch(actions.resetState());
};
export const resetFranchiseDetail = () => (dispatch) => {
  dispatch(actions.resetStateDetail());
};

export const fetchFranchisesLogs = (queryParams, f_id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  return requestFromServer
    .fetchLogsOfFranchise(queryParams, f_id)
    .then((response) => {
      const {  logs,pagination } = response.data.data;
      // dispatch(actions.franchiseFetchedTotalCount({ totalCount: total_count }));
      dispatch(
        actions.franchiseLogsFetched({
          totalCount: pagination.total_items,
          entities: logs,
          loading: false,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find logs";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};