import React, { useEffect, useState } from "react";
import {
  fetchCustomerOrderList,
  fetchCustomerTimeLine,
} from "../_redux/customer/customerAction";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { CreateContactOffCanvas } from "../../ContactDeals/canvas/CreateContactOffCanvas";
import { CustomerAttachment } from "../../../../_metronic/layout/components/extras/offcanvas/Customers/CustomerAttachment";
import { CustomerNotes } from "../../../../_metronic/layout/components/extras/offcanvas/Customers/CustomerNotes";
import { CustomerOrderWidget } from "../components/CustomerOrderWidget";
import { Feeds } from "../../../../_metronic/_partials/widgets";
import SVG from "react-inlinesvg";
import { fetchNewCustomerCard } from "../../Order/_redux/order/orderAction";
import { initIds } from "../../../../_metronic/_partials/controls/RightPanel";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { useSubheader } from "../../../../_metronic/layout";
import { orderSlice } from "../../Order/_redux/order/orderSlice";
import AutomatePriceModal from "../../Settings/pages/franchises/franchise-canva/AutomatePriceModal";

export const CustomersDetails = (props) => {
  // Declare and initialize the dispatch hook
  const dispatch = useDispatch();
  const [dialog , setDialog] = useState({check:false})
  const { actions } = orderSlice;
  // Set the page title
  useSubheader().setTitle("Customers");

  // Initialize the array of ids to be passed to the initIds function
  const init = ["kt_customerdetail_panel", "kt_quick_panelone"];

  // Call the initIds function when the component mounts and the ids array changes
  useEffect(() => {
    initIds(init);
  }, [init]);

  // Get the card detail, order list, timeline, and loading state from the Redux store
  const { cardDetail, orderList, timeline, loading } = useSelector(
    (state) => ({
      cardDetail: state.order.cardDetail[0],
      orderList: state.customer.order.list,
      loading: state.customer.order.loading,
      timeline: state.customer.timeline,
    }),
    shallowEqual
  );

  // Declare and initialize the openCanva state variable
  const [openCanva, setOpenCanva] = useState(false);

  // Fetch the customer card detail and order list when the component mounts and the customer ID changes
  useEffect(() => {
    dispatch(fetchNewCustomerCard(props?.match?.params?.id));
    dispatch(fetchCustomerOrderList(props?.match?.params?.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, props.match.params.id]);

  // Fetch the customer timeline when the component mounts and the customer ID or contact ID changes
  useEffect(() => {
    if (cardDetail?.contact?.id && !dialog.check) {
      dispatch(
        fetchCustomerTimeLine(props?.match?.params?.id, cardDetail?.contact.id)
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardDetail?.contact?.id, dispatch, props.match.params.id , dialog]);
  let cus_address = cardDetail?.address || "";
  if (cus_address !== "") {
    cus_address = cus_address?.replaceAll(", ", ",");
  }
  // Set the initial values for the CreateContactOffCanvas form
  const editValues = {
    first_name: cardDetail?.first_name || "",
    last_name: cardDetail?.last_name || "",
    email: cardDetail?.contact?.email || [],
    phone: cardDetail?.contact?.phone || [],
    postcode: cardDetail?.postcode || "",
    city: cardDetail?.city || "",
    address: cus_address,
    user_id: cardDetail?.contact?.user_id || "",
    is_address_manual: cardDetail?.contact?.is_address_manual || 0,
    franchise_id: cardDetail?.contact?.franchise_id || "",
    contact_owner_id: cardDetail?.contact?.contact_owner_id || "",
  };

  useEffect(() => {
    return () => {
      dispatch(actions.blankCustomer({}));
    };
  }, [actions, dispatch]);

  return (
    <>
      <div className="row mt-5">
        <div className="w-100 mb-4" style={{textAlign:"end"}}>

      <button
        className="btn btn-primary font-size-sm font-weight-bolder mt-1 mr-3"
        onClick={()=>setDialog({check:true})}
      >
        Automated Price Increase
      </button>
        </div>
        <div className="col-md-12">
          <div className="card card-custom gutter-b">
            <div className="card-body">
              <div className="d-flex">
                <div>
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0 mr-7">
                      <div className="symbol symbol-50 symbol-lg-50  symbol-light-blue">
                        <span className="font-size-h5 symbol-label font-weight-boldest">
                          {cardDetail?.first_name?.charAt(0) || "-"}&nbsp;
                          {cardDetail?.last_name?.charAt(0) || ""}
                        </span>
                      </div>
                    </div>

                    <div className="flex-grow-1">
                      <div className="d-flex align-items-center justify-content-between flex-wrap mt-2">
                        <div className="mr-3">
                          <span className="d-flex align-items-center text-dark text-hover-primary font-size-h5 font-weight-bold mr-3">
                            {cardDetail?.first_name || "-"}&nbsp;
                            {cardDetail?.last_name || ""}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <span className="symbol-label pointer">
                    <button
                      className="btn btn-link"
                      onClick={() => setOpenCanva(true)}
                    >
                      <span className="svg-icon svg-icon-sm svg-icon-primary">
                        <SVG
                          title=" "
                          className="h-25 align-self-end"
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Design/Edit.svg"
                          )}
                        ></SVG>
                      </span>
                    </button>
                  </span>
                </div>
              </div>
              <div className="separator separator-solid my-7"></div>

              <div className="d-flex align-items-center flex-wrap">
                <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
                  <span className="mr-4">
                    <span className="svg-icon svg-icon-2x svg-icon-gray-500 mr-1">
                      <SVG
                        src={toAbsoluteUrl("/media/svg/icons/Map/Marker1.svg")}
                      ></SVG>
                    </span>
                  </span>
                  <div className="d-flex flex-column text-dark-75">
                    <span className="font-size-h6">
                      {cardDetail?.address?.replaceAll(",", ", ") || "-"}
                    </span>
                  </div>
                </div>

                <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
                  <span className="d-flex linktxt">
                    <span className="mr-4">
                      <span className="svg-icon svg-icon-2x svg-icon-gray-500 mr-1">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Communication/Mail.svg"
                          )}
                        ></SVG>
                      </span>
                    </span>
                    <div className="d-flex flex-column ">
                      <a
                        className="font-size-h6 text-hover-primary"
                        style={{ color: "#3F4254" }}
                        href={`mailto:${cardDetail?.email || ""}`}
                      >
                        {cardDetail?.email || "-"}
                      </a>
                    </div>{" "}
                  </span>
                </div>

                <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
                  <span className="d-flex linktxt">
                    <span className="mr-4">
                      <span className="svg-icon svg-icon-2x svg-icon-gray-500 mr-1">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Communication/Active-call.svg"
                          )}
                        ></SVG>
                      </span>
                    </span>
                    <div className="d-flex flex-column ">
                      <a
                        className="font-size-h6 text-hover-primary"
                        style={{ color: "#3F4254" }}
                        href={`tel:${cardDetail?.phone || "-"}`}
                      >
                        {cardDetail?.phone || "-"}
                      </a>
                    </div>
                  </span>
                </div>

                <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
                  <span className="mr-4">
                    <span className="svg-icon svg-icon-2x svg-icon-gray-500 mr-1">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Home/Building.svg"
                        )}
                      ></SVG>
                    </span>
                  </span>
                  <div className="d-flex flex-column flex-lg-fill">
                    <span className="font-size-h6">
                      {cardDetail?.contact?.franchise?.name || "-"}
                    </span>
                  </div>
                </div>

                <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
                  <span className="mr-4">
                    <span className="svg-icon svg-icon-2x svg-icon-gray-500 mr-1">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Communication/Group.svg"
                        )}
                      ></SVG>
                    </span>
                  </span>
                  <div className="d-flex flex-column">
                    <span className="font-size-h6">
                      {cardDetail?.contact?.agent?.first_name || "-"}&nbsp;
                      {cardDetail?.contact?.agent?.last_name || ""}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-md-12 col-lg-8 mmb-10">
          {/* Customer Order Widget */}
          <CustomerOrderWidget
            orderList={orderList && orderList.length > 0 ? orderList : ""}
            loading={loading}
          />
        </div>
        <div className="col-md-12 col-lg-4">
          <div className="customer-pay">
            {/* Feeds Widget */}
            <Feeds
              className="card-stretch gutter-c"
              data={timeline}
              type={"customer"}
              check={"customer"}
            />
          </div>
        </div>
      </div>
      {/* Customer Notes Widget */}
      <CustomerNotes />
      {/* Customer Attachment Widget */}
      <CustomerAttachment />

      {/* Create Contact Off Canvas */}
      {openCanva ? (
        <>
          <CreateContactOffCanvas
            title={"Customer Details"}
            type={cardDetail ? "edit" : ""}
            check={"customer"}
            checked={"customers"}
            editCustomer={editValues}
            editCustomerId={cardDetail?.contact?.id || ""}
            customerId={props?.match?.params?.id || ""}
            setOpenCanva={setOpenCanva}
          />
          <div
            className="offcanvas-overlay"
            onClick={() => setOpenCanva(false)}
          ></div>
        </>
      ) : (
        ""
      )}
      
       <AutomatePriceModal dialog={dialog} setDialog={setDialog} franchiseId={cardDetail?.contact?.franchise_id} user_id={cardDetail?.contact?.user_id } />
    </>
  );
};
