import * as requestFromServer from "./feedsCrud";
import { feedsSlice, callTypes } from "./feedsSlice";

const { actions } = feedsSlice;

export const fetchFeeds = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .feedsList(queryParams)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.feedList({ data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const fetchProfileFeeds = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .feedsProfileList(queryParams)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.feedProfileList({ data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
