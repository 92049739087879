// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html

import * as actions from "../../../_redux/promotions/promotionsAction";
import * as uiHelpers from "../PromotionsUIHelpers";

import React, { useEffect, useMemo, useState } from "react";
import {
  getHandlerTableChange,
  getSelectRowChargePromo,
  headerSortingClasses,
  sortCaret,
} from "../../../../../../_metronic/_helpers";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import BootstrapTable from "react-bootstrap-table-next";
import NoRecord from "../../../../../../_metronic/_partials/widgets/NoRecord";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { usePromotionsUIContext } from "../PromotionsUIContext";
import { convertGBPtoPoundSymbol } from "../../../../../utils/commonFunction";
import { divideByHundred } from "../../../../../utils/canvaCloseFunction";

const PromotionsTable = () => {
  let history = useHistory();
  const intl = useIntl();
  const PromotionsUIContext = usePromotionsUIContext();

  const PromotionsUIProps = useMemo(() => {
    return {
      ids: PromotionsUIContext?.ids,
      setIds: PromotionsUIContext?.setIds,
      queryParams: PromotionsUIContext?.queryParams,
      setQueryParams: PromotionsUIContext?.setQueryParams,
      openEditOrderPage: PromotionsUIContext?.openEditOrderPage,
      openDeleteOrderDialog: PromotionsUIContext?.openDeleteOrderDialog,
    };
  }, [PromotionsUIContext]);

  const dispatch = useDispatch();

  const { currentState, permission } = useSelector(
    (state) => ({
      currentState: state.promotions.list,
      permission: state.auth.permission,
    }),
    shallowEqual
  );

  const { entities, nextoffset, totalCount, loading } = currentState;

  useEffect(() => {
    // clear selections list
    // server call by queryParams
    dispatch(
      actions.fetchPromotions(
        PromotionsUIProps.queryParams,
        PromotionsUIProps.ids
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PromotionsUIProps.queryParams, dispatch]);

  const columns = [
    {
      dataField: "offerCode",
      text: intl.formatMessage({ id: "Offer.Code" }),
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return <span>{data && data.name ? data.name : "-"}</span>;
      },
    },
    {
      dataField: "description",
      text: intl.formatMessage({ id: "Offer.Description" }),
      sort: false,
      sortCaret: sortCaret,
      headerSortingClasses,
      style: { whiteSpace: "break-spaces", width: "300px" },
      formatter: (e, data) => {
        return (
          <span>
            {data && data.metadata && data.metadata.description
              ? data.metadata.description
              : "-"}
          </span>
        );
      },
    },
    {
      dataField: "type",
      text: intl.formatMessage({ id: "Offer.Type" }),
      sort: false,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (e, data) => {
        return (
          <span>
            {data && data.metadata && data.metadata.offer_type
              ? data.metadata.offer_type === "percentage"
                ? "Percentage"
                : data.metadata.offer_type === "fixed_amount"
                ? "Fixed Amount"
                : "-"
              : "-"}
          </span>
        );
      },
    },

    {
      dataField: "value",
      text: intl.formatMessage({ id: "Value" }),
      sort: false,
      sortCaret: sortCaret,
      // formatter: columnFormatters.PriceColumnFormatter,
      formatter: (e, data) => {
        return (
          <span>
            {data && data.metadata
              ? data.metadata.offer_type === "percentage"
                ? data.percent_off + "%"
                : data.metadata.offer_type === "fixed_amount"
                ? convertGBPtoPoundSymbol(data.currency) +
                  divideByHundred(data.amount_off)
                : "-"
              : "-"}
          </span>
        );
      },
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "Status" }),
      sort: false,
      sortCaret: sortCaret,
      // formatter: columnFormatters.StatusColumnFormatter,
      formatter: (e, data) => {
        return (
          <span
            className={`label label-lg label-light-${
              data && data.valid === false ? "danger" : "success  "
            } label-inline`}
          >
            {data && data.valid
              ? data.valid === true
                ? "Active"
                : "Expired"
              : "-"}
          </span>
        );
      },
    },
    {
      dataField: "validFrom",
      text: intl.formatMessage({ id: "Valid.From" }),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (e, data) => {
        return (
          <span>
            {data && data.created
              ? moment(data.created * 1000).format("DD-MM-YYYY")
              : "-"}
          </span>
        );
      },
    },
    {
      dataField: "validto",
      text: intl.formatMessage({ id: "Valid.To" }),
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        const date = data.created * 1000;
        return (
          <span>
            {data && data.duration_in_months
              ? moment(date)
                  .add(data.duration_in_months, "months")
                  .format("DD-MM-YYYY")
              : "-"}
          </span>
        );
      },
    },
  ];

  // const [total, setTotal] = useState(5);

  // useEffect(() => {
  //   if (PromotionsUIProps.queryParams?.pageNumber) {
  //     setTotal(total + 5);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [PromotionsUIProps.queryParams.pageNumber]);
  console.log("PromotionsUIProps", PromotionsUIProps);

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: PromotionsUIProps.queryParams?.pageSize,
    page: PromotionsUIProps.queryParams?.pageNumber,
    offset: nextoffset,
    ids: PromotionsUIProps?.ids,
    setIds: PromotionsUIProps.setIds,
  };

  // const rowEvents = {
  //   onClick: (e, row, rowIndex) => {
  //     let url = encodeURIComponent(row.coupon.id);
  //     history.push(`/settings/promotions/promotions-details/${url}`);
  //   },
  // };
  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      let url = encodeURIComponent(row.id);
      history.push(`/settings/promotions/promotions-details/${url}`);
      // window.open(`/settings/promotions/promotions-details/${url}`, "_blank");
    },
  };
  const selectRow = {
    mode: "checkbox",
    hideSelectAll: true,
    hideSelectColumn: true,
  };

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={loading}
              paginationProps={paginationProps}
              entities={entities}
              customePagination={true}
              loading={loading}
            >
              <BootstrapTable
                rowEvents={rowEvents}
                hover
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="coupon"
                defaultSorted={uiHelpers.defaultSorted}
                data={entities === null ? [] : entities}
                onTableChange={getHandlerTableChange(
                  PromotionsUIProps.setQueryParams
                )}
                selectRow={
                  permission?.setting_promotion?.delete
                    ? getSelectRowChargePromo({
                        entities,
                        ids: PromotionsUIProps.ids,
                        setIds: PromotionsUIProps.setIds,
                      })
                    : selectRow
                }
                columns={columns}
                {...paginationTableProps}
              ></BootstrapTable>
              {(entities && entities?.length) || loading ? "" : <NoRecord />}
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
};

export default PromotionsTable;
