import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import NewBreadCrumbs from "../../../../../_metronic/layout/components/subheader/components/NewBreadCrumbs";
import { useFormik } from "formik";
import { assignAvailability } from "../../_redux/availability/availabilityCrud";
// import SnackBarTool from "../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { dealsFranchiseListing } from "../../../ContactDeals/_redux/deals/dealsCrud";
import { InputGroup } from "react-bootstrap";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import DatePicker from "react-datepicker";
import moment from "moment";
// import { getStorageList } from "../../../../pages/redux/commonCrud";
import { findFranchiseDepot } from "../../_redux/franchises/franchisesCrud";
import { useSelector, shallowEqual } from "react-redux";

export const ExportImport = () => {
  const intl = useIntl();
  // Create state to manage snackbar notifications
  const [franchises, setFranchise] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [depot, setDepot] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [minDate, setMinDate] = useState(
    moment(new Date()).format("yyyy-MM-DD")
  );

  const { role, userData } = useSelector(
    (state) => ({
      role: state.auth.user.roles,
      userData: state.auth.user,
    }),
    shallowEqual
  );

  const initialValues = {
    from: "",
    to: "",
    type: "all",
    franchise_id: "all",
    from_pod: "",
    to_pod: "",
    depot_pod: "",
    franchise_id_pod: "",
    service_from: "",
    service_to: "",
    service_type: "all",
    service_franchise_id: "all",
    podIn_from: "",
    podIn_to: "",
    podIn_franchise_id: "all",
    podInStorage_franchise_id: "all",
    podInStorage_to: moment(new Date()).format("yyyy-MM-DD"),
    containerStorage_franchise_id:"all"
  };

  const handleSubmit = () => {
    setSnack({ call: false, type: "", message: "" });
    assignAvailability(formik.values)
      .then((res) => {
        setSnack({ call: true, type: "success", message: res.data.message });
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
  };
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  const handleFranchiseList = () => {
    dealsFranchiseListing().then((res) => {
      setFranchise(res.data.data);
    });
  };
  useEffect(() => {
    handleFranchiseList();
  }, []);

  // const handleRun = () => {
  //   getStorageList(formik?.values).then((res) => {
  //     console.log("res", res);
  //   });
  // };

  useEffect(() => {
    if (formik?.values?.franchise_id_pod) {
      findFranchiseDepot(formik?.values?.franchise_id_pod).then((res) => {
        setDepot(res.data.data);
      });
    }
  }, [formik.values.franchise_id_pod]);

  useEffect(() => {
    if (role && role?.length && role[0]?.name === "franchise-owner") {
      formik.setValues({
        ...formik.values,
        franchise_id: userData?.franchise_id,
        service_franchise_id: userData?.franchise_id,
        podInStorage_franchise_id: userData?.franchise_id,
        containerStorage_franchise_id: userData?.franchise_id,

        podIn_franchise_id: userData?.franchise_id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);

  return (
    <>
      <NewBreadCrumbs title={"Reports"} />
      <form onSubmit={formik.handleSubmit}>
        <div className="card card-custom gutter-b ">
          <div className="card-body feeds-section">
            {/* Title for feeds section */}
            <div className="d-flex ">
              <h3 className="card-title align-items-start flex-column d-flex">
                {" "}
                <span className="font-weight-bolder text-dark">
                  Export Order Per Booking Date
                </span>
              </h3>
            </div>{" "}
            <div className="row">
              <div className="col-3 ">
                <label className="mt-3">
                  {intl.formatMessage({ id: "Franchise" })}:&nbsp;{" "}
                </label>
                <FormControl fullWidth>
                  <Select
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                    className="form-control"
                    name="franchise_id"
                    displayEmpty
                    IconComponent={(props) => (
                      <i {...props}>
                        <KeyboardArrowDownIcon />
                      </i>
                    )}
                    inputProps={{ "aria-label": "Without label" }}
                    {...formik.getFieldProps("franchise_id")}
                  >
                    {role &&
                      role?.length &&
                      role[0]?.name !== "franchise-owner" && (
                        <MenuItem value="all">
                          {intl.formatMessage({ id: "All" })}{" "}
                          {intl.formatMessage({ id: "FRANCHISES" })}
                        </MenuItem>
                      )}

                    {franchises.map((data, index) => (
                      <MenuItem key={index} value={data.id}>
                        {data.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              {/* <div className="col-3 ">
                <label className="mt-3">Type</label>
                <FormControl fullWidth>
                  <Select
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                    className="form-control"
                    name="type"
                    displayEmpty
                    IconComponent={(props) => (
                      <i {...props}>
                        <KeyboardArrowDownIcon />
                      </i>
                    )}
                    inputProps={{ "aria-label": "Without label" }}
                    {...formik.getFieldProps("type")}
                  >
                    <MenuItem value="all">All</MenuItem>
                    <MenuItem value="collection">Collection</MenuItem>
                    <MenuItem value="return">Return</MenuItem>
                    <MenuItem value="access">access</MenuItem>
                  </Select>
                </FormControl>
              </div> */}
              <div className="col-4 mb-5">
                <label className="mt-3">From</label>
                <InputGroup className="calendr">
                  <DatePicker
                    fullWidth
                    fixedHeight
                    className="form-control"
                    wrapperClassName="datepicker"
                    dateFormat="dd-MM-yyyy"
                    placeholderText="Select Date"
                    selected={Date.parse(formik?.values?.from)}
                    onChange={(dates) => {
                      formik.setFieldValue(
                        "from",
                        moment(dates).format("yyyy-MM-DD")
                      );
                      const date = new Date(dates);

                      // Increment the month by 3
                      // date.setMonth(date.getMonth() + 12);

                      // Set the date to the 1st of the month
                      // date.setDate(1);

                      // Return the formatted date as a string (YYYY-MM-DD)
                      // const data = date.toISOString().slice(0, 10);
                      // setMinDate(data);
                      // formik.setFieldValue("to", "");
                    }}
                  />
                  <CalendarTodayIcon />
                </InputGroup>
              </div>
              <div className="col-3 mb-5">
                <label className="mt-3">To</label>
                <InputGroup className="calendr">
                  <DatePicker
                    fullWidth
                    fixedHeight
                    className="form-control"
                    wrapperClassName="datepicker"
                    dateFormat="dd-MM-yyyy"
                    placeholderText="Select Date"
                    selected={Date.parse(formik?.values?.to)}
                    minDate={Date.parse(formik?.values?.from)}
                    // maxDate={Date.parse(minDate)}
                    onChange={(dates) =>
                      formik.setFieldValue(
                        "to",
                        moment(dates).format("yyyy-MM-DD")
                      )
                    }
                  />
                  <CalendarTodayIcon />
                </InputGroup>
              </div>
              <div className="col-2 mt-8">
                {
                  <a
                    href={
                      formik?.values?.franchise_id &&
                      formik?.values?.type &&
                      formik?.values?.to &&
                      formik?.values?.from
                        ? process.env.REACT_APP_SITE_URL +
                          `export-orders?franchise_id=${formik?.values?.franchise_id}&type=${formik?.values?.type}&to=${formik?.values?.to}&from=${formik?.values?.from}&export=booked`
                        : ""
                    }
                  >
                    <label>&nbsp;</label>
                    <button
                      type="button"
                      className="ml-4 btn btn-primary font-weight-bolder font-size-sm mt-3"
                      disabled={
                        !formik?.values?.franchise_id ||
                        !formik?.values?.type ||
                        !formik?.values?.to ||
                        !formik?.values?.from
                      }
                    >
                      {intl.formatMessage({ id: "Submit" })}
                    </button>
                  </a>
                }
              </div>
            </div>
          </div>
        </div>
        <div className="card card-custom gutter-b ">
          <div className="card-body feeds-section">
            {/* Title for feeds section */}
            <div className="d-flex ">
              <h3 className="card-title align-items-start flex-column d-flex">
                {" "}
                <span className="font-weight-bolder text-dark">
                  Export Order Per Service Date
                </span>
              </h3>
            </div>{" "}
            <div className="row">
              <div className="col-3 ">
                <label className="mt-3">
                  {intl.formatMessage({ id: "Franchise" })}:&nbsp;{" "}
                </label>
                <FormControl fullWidth>
                  <Select
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                    className="form-control"
                    name="service_franchise_id"
                    displayEmpty
                    IconComponent={(props) => (
                      <i {...props}>
                        <KeyboardArrowDownIcon />
                      </i>
                    )}
                    inputProps={{ "aria-label": "Without label" }}
                    {...formik.getFieldProps("service_franchise_id")}
                  >
                    {role &&
                      role?.length &&
                      role[0]?.name !== "franchise-owner" && (
                        <MenuItem value="all">
                          {intl.formatMessage({ id: "All" })}{" "}
                          {intl.formatMessage({ id: "FRANCHISES" })}
                        </MenuItem>
                      )}
                    {franchises.map((data, index) => (
                      <MenuItem key={index} value={data.id}>
                        {data.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              {/* <div className="col-3 ">
                <label className="mt-3">Type</label>
                <FormControl fullWidth>
                  <Select
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                    className="form-control"
                    name="service_type"
                    displayEmpty
                    IconComponent={(props) => (
                      <i {...props}>
                        <KeyboardArrowDownIcon />
                      </i>
                    )}
                    inputProps={{ "aria-label": "Without label" }}
                    {...formik.getFieldProps("service_type")}
                  >
                    <MenuItem value="all">All</MenuItem>
                    <MenuItem value="collection">Collection</MenuItem>
                    <MenuItem value="return">Return</MenuItem>
                    <MenuItem value="access">access</MenuItem>
                  </Select>
                </FormControl>
              </div> */}
              <div className="col-4 mb-5">
                <label className="mt-3">From</label>
                <InputGroup className="calendr">
                  <DatePicker
                    fullWidth
                    fixedHeight
                    className="form-control"
                    wrapperClassName="datepicker"
                    dateFormat="dd-MM-yyyy"
                    placeholderText="Select Date"
                    selected={Date.parse(formik?.values?.service_from)}
                    onChange={(dates) => {
                      formik.setFieldValue(
                        "service_from",
                        moment(dates).format("yyyy-MM-DD")
                      );
                      const date = new Date(dates);

                      // Increment the month by 3
                      // date.setMonth(date.getMonth() + 12);

                      // Set the date to the 1st of the month
                      // date.setDate(1);

                      // Return the formatted date as a string (YYYY-MM-DD)
                      // const data = date.toISOString().slice(0, 10);
                      // setMinDate(data);
                      // formik.setFieldValue("service_to", "");
                    }}
                  />
                  <CalendarTodayIcon />
                </InputGroup>
              </div>
              <div className="col-3 mb-5">
                <label className="mt-3">To</label>
                <InputGroup className="calendr">
                  <DatePicker
                    fullWidth
                    fixedHeight
                    className="form-control"
                    wrapperClassName="datepicker"
                    dateFormat="dd-MM-yyyy"
                    placeholderText="Select Date"
                    selected={Date.parse(formik?.values?.service_to)}
                    minDate={Date.parse(formik?.values?.service_from)}
                    // maxDate={Date.parse(minDate)}
                    onChange={(dates) =>
                      formik.setFieldValue(
                        "service_to",
                        moment(dates).format("yyyy-MM-DD")
                      )
                    }
                  />
                  <CalendarTodayIcon />
                </InputGroup>
              </div>
              <div className="col-2 mt-8">
                {
                  <a
                    href={
                      formik?.values?.service_franchise_id &&
                      formik?.values?.service_type &&
                      formik?.values?.service_to &&
                      formik?.values?.service_from
                        ? process.env.REACT_APP_SITE_URL +
                          `export-orders?franchise_id=${formik?.values?.service_franchise_id}&type=${formik?.values?.service_type}&to=${formik?.values?.service_to}&from=${formik?.values?.service_from}&export=service`
                        : ""
                    }
                  >
                    <label>&nbsp;</label>
                    <button
                      type="button"
                      className="ml-4 btn btn-primary font-weight-bolder font-size-sm mt-3"
                      disabled={
                        !formik?.values?.service_franchise_id ||
                        !formik?.values?.service_type ||
                        !formik?.values?.service_to ||
                        !formik?.values?.service_from
                      }
                    >
                      {intl.formatMessage({ id: "Submit" })}
                    </button>
                  </a>
                }
              </div>
            </div>
          </div>
        </div>

        {role && role?.length && (role[0]?.name === "franchise-owner" || role[0] === "super-admin" )&& (
          <div className="card card-custom gutter-b ">
            <div className="card-body feeds-section">
              {/* Title for feeds section */}
              <div className="d-flex ">
                <h3 className="card-title align-items-start flex-column d-flex">
                  {" "}
                  <span className="font-weight-bolder text-dark">
                    Export Opening/Closing balance
                  </span>
                </h3>
              </div>{" "}
              <div className="row">
                <div className="col-3 ">
                  <label className="mt-3">
                    {intl.formatMessage({ id: "Franchise" })}:&nbsp;{" "}
                  </label>
                  <FormControl fullWidth>
                    <Select
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                      className="form-control"
                      name="podIn_franchise_id"
                      displayEmpty
                      IconComponent={(props) => (
                        <i {...props}>
                          <KeyboardArrowDownIcon />
                        </i>
                      )}
                      inputProps={{ "aria-label": "Without label" }}
                      {...formik.getFieldProps("podIn_franchise_id")}
                    >
                      {role &&
                        role?.length &&
                        role[0]?.name !== "franchise-owner" && (
                          <MenuItem value="all">
                            {intl.formatMessage({ id: "All" })}{" "}
                            {intl.formatMessage({ id: "FRANCHISES" })}
                          </MenuItem>
                        )}
                      {franchises.map((data, index) => (
                        <MenuItem key={index} value={data.id}>
                          {data.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                {/* <div className="col-3 ">
                <label className="mt-3">Type</label>
                <FormControl fullWidth>
                  <Select
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                    className="form-control"
                    name="service_type"
                    displayEmpty
                    IconComponent={(props) => (
                      <i {...props}>
                        <KeyboardArrowDownIcon />
                      </i>
                    )}
                    inputProps={{ "aria-label": "Without label" }}
                    {...formik.getFieldProps("service_type")}
                  >
                    <MenuItem value="all">All</MenuItem>
                    <MenuItem value="collection">Collection</MenuItem>
                    <MenuItem value="return">Return</MenuItem>
                    <MenuItem value="access">access</MenuItem>
                  </Select>
                </FormControl>
              </div> */}
                <div className="col-4 mb-5">
                  <label className="mt-3">From</label>
                  <InputGroup className="calendr">
                    <DatePicker
                      fullWidth
                      fixedHeight
                      className="form-control"
                      wrapperClassName="datepicker"
                      dateFormat="dd-MM-yyyy"
                      placeholderText="Select Date"
                      selected={Date.parse(formik?.values?.podIn_from)}
                      onChange={(dates) => {
                        formik.setFieldValue(
                          "podIn_from",
                          moment(dates).format("yyyy-MM-DD")
                        );
                        const date = new Date(dates);

                        // Increment the month by 3
                        // date.setMonth(date.getMonth() + 12);

                        // Set the date to the 1st of the month
                        // date.setDate(1);

                        // Return the formatted date as a string (YYYY-MM-DD)
                        // const data = date.toISOString().slice(0, 10);
                        // setMinDate(data);
                        // formik.setFieldValue("service_to", "");
                      }}
                    />
                    <CalendarTodayIcon />
                  </InputGroup>
                </div>
                <div className="col-3 mb-5">
                  <label className="mt-3">To</label>
                  <InputGroup className="calendr">
                    <DatePicker
                      fullWidth
                      fixedHeight
                      className="form-control"
                      wrapperClassName="datepicker"
                      dateFormat="dd-MM-yyyy"
                      placeholderText="Select Date"
                      selected={Date.parse(formik?.values?.podIn_to)}
                      minDate={Date.parse(formik?.values?.podIn_from)}
                      // maxDate={Date.parse(minDate)}
                      onChange={(dates) =>
                        formik.setFieldValue(
                          "podIn_to",
                          moment(dates).format("yyyy-MM-DD")
                        )
                      }
                    />
                    <CalendarTodayIcon />
                  </InputGroup>
                </div>
                <div className="col-2 mt-8">
                  {
                    <a
                      href={
                        formik?.values?.podIn_franchise_id &&
                        formik?.values?.podIn_to &&
                        formik?.values?.podIn_from
                          ? process.env.REACT_APP_SITE_URL +
                            `pod-bal-export?franchise_id=${formik?.values?.podIn_franchise_id}&depot=all&to=${formik?.values?.podIn_to}&from=${formik?.values?.podIn_from}&Export=Export`
                          : ""
                      }
                    >
                      <label>&nbsp;</label>
                      <button
                        type="button"
                        className="ml-4 btn btn-primary font-weight-bolder font-size-sm mt-3"
                        disabled={
                          !formik?.values?.podIn_franchise_id ||
                          !formik?.values?.service_type ||
                          !formik?.values?.podIn_to ||
                          !formik?.values?.podIn_from
                        }
                      >
                        {intl.formatMessage({ id: "Submit" })}
                      </button>
                    </a>
                  }
                </div>
              </div>
            </div>
          </div>
        )}
        {role && role?.length && (role[0]?.name === "franchise-owner" || role[0] === "super-admin" ) && (
          <div className="card card-custom gutter-b ">
            <div className="card-body feeds-section">
              <div className="d-flex ">
                <h3 className="card-title align-items-start flex-column d-flex">
                  {" "}
                  <span className="font-weight-bolder text-dark">
                    Export Pod In Storage
                  </span>
                </h3>
              </div>{" "}
              <div className="row">
                <div className="col-5 ">
                  <label className="mt-3">
                    {intl.formatMessage({ id: "Franchise" })}:&nbsp;{" "}
                  </label>
                  <FormControl fullWidth>
                    <Select
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                      className="form-control"
                      name="podInStorage_franchise_id"
                      displayEmpty
                      IconComponent={(props) => (
                        <i {...props}>
                          <KeyboardArrowDownIcon />
                        </i>
                      )}
                      inputProps={{ "aria-label": "Without label" }}
                      {...formik.getFieldProps("podInStorage_franchise_id")}
                    >
                      {role &&
                        role?.length &&
                        role[0]?.name !== "franchise-owner" && (
                          <MenuItem value="all">
                            {intl.formatMessage({ id: "All" })}{" "}
                            {intl.formatMessage({ id: "FRANCHISES" })}
                          </MenuItem>
                        )}
                      {franchises.map((data, index) => (
                        <MenuItem key={index} value={data.id}>
                          {data.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
               
                {/* <div className="col-5 mb-5">
                  <label className="mt-3">To</label>
                  <InputGroup className="calendr">
                    <DatePicker
                      fullWidth
                      fixedHeight
                      className="form-control"
                      wrapperClassName="datepicker"
                      dateFormat="dd-MM-yyyy"
                      placeholderText="Select Date"
                      selected={Date.parse(formik?.values?.podInStorage_to)}
                      // minDate={Date.parse(formik?.values?.podIn_from)}
                      // maxDate={Date.parse(minDate)}
                      onChange={(dates) => {
                        console.log(
                          "dates",
                          dates,
                          moment(dates).format("yyyy-MM-DD")
                        );
                        formik.setFieldValue(
                          "podInStorage_to",
                          moment(dates).format("yyyy-MM-DD")
                        );
                      }}
                    />
                    <CalendarTodayIcon />
                  </InputGroup>
                </div> */}
                <div className="col-2 mt-8">
                  {
                    <a
                      href={
                        formik?.values?.podInStorage_franchise_id &&
                        formik?.values?.podInStorage_to
                          ? process.env.REACT_APP_SITE_URL +
                            `export-orders?franchise_id=${formik?.values?.podInStorage_franchise_id}&to=${formik?.values?.podInStorage_to}&export=pod-in-storage`
                          : ""
                      }
                    >
                      <label>&nbsp;</label>
                      <button
                        type="button"
                        className="ml-4 btn btn-primary font-weight-bolder font-size-sm mt-3"
                        disabled={
                          !formik?.values?.podInStorage_franchise_id ||
                          !formik?.values?.podInStorage_to
                        }
                      >
                        {intl.formatMessage({ id: "Submit" })}
                      </button>
                    </a>
                  }
                </div>
              </div>
            </div>
          </div>
        )}
{role && role?.length && (userData?.franchise_plan_types?.some((e)=>e === "container") || role[0] === "super-admin" ) && (
          <div className="card card-custom gutter-b ">
            <div className="card-body feeds-section">
              <div className="d-flex ">
                <h3 className="card-title align-items-start flex-column d-flex">
                  {" "}
                  <span className="font-weight-bolder text-dark">
                  Export Container Availability
                  </span>
                </h3>
              </div>{" "}
              <div className="row">
                <div className="col-5 ">
                  <label className="mt-3">
                    {intl.formatMessage({ id: "Franchise" })}:&nbsp;{" "}
                  </label>
                  <FormControl fullWidth>
                    <Select
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                      className="form-control"
                      name="containerStorage_franchise_id"
                      displayEmpty
                      IconComponent={(props) => (
                        <i {...props}>
                          <KeyboardArrowDownIcon />
                        </i>
                      )}
                      inputProps={{ "aria-label": "Without label" }}
                      {...formik.getFieldProps("containerStorage_franchise_id")}
                    >
                      {role &&
                        role?.length &&
                        role[0]?.name !== "franchise-owner" && (
                          <MenuItem value="all">
                            {intl.formatMessage({ id: "All" })}{" "}
                            {intl.formatMessage({ id: "FRANCHISES" })}
                          </MenuItem>
                        )}
                      {franchises.map((data, index) => (
                        <MenuItem key={index} value={data.id}>
                          {data.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
               
               
                <div className="col-2 mt-8">
                  {
                    <a
                      href={
                        formik?.values?.containerStorage_franchise_id &&
                        formik?.values?.podInStorage_to
                          ? process.env.REACT_APP_SITE_URL +
                            `export-orders?franchise_id=${formik?.values?.containerStorage_franchise_id}&export=container-availability`
                          : ""
                      }
                    >
                      <label>&nbsp;</label>
                      <button
                        type="button"
                        className="ml-4 btn btn-primary font-weight-bolder font-size-sm mt-3"
                        disabled={
                          !formik?.values?.containerStorage_franchise_id ||
                          !formik?.values?.podInStorage_to
                        }
                      >
                        {intl.formatMessage({ id: "Submit" })}
                      </button>
                    </a>
                  }
                </div>
              </div>
            </div>
          </div>
        )}
        {/* <div className="card card-custom gutter-b ">
          <div className="card-body feeds-section">
            <div className="d-flex ">
              <h3 className="card-title align-items-start flex-column d-flex">
                {" "}
                <span className="font-weight-bolder text-dark">
                  Pod In Storage
                </span>
              </h3>
            </div>{" "}
            <div className="row">
              <div className="col-3 ">
                <label className="mt-3">
                  {intl.formatMessage({ id: "Franchise" })}:&nbsp;{" "}
                </label>
                <FormControl fullWidth>
                  <Select
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                    className="form-control"
                    name="franchise_id_pod"
                    displayEmpty
                    IconComponent={(props) => (
                      <i {...props}>
                        <KeyboardArrowDownIcon />
                      </i>
                    )}
                    inputProps={{ "aria-label": "Without label" }}
                    {...formik.getFieldProps("franchise_i d_pod")}
                  >
                    <MenuItem value="all">
                      {intl.formatMessage({ id: "All" })}{" "}
                      {intl.formatMessage({ id: "Franchise" })}
                    </MenuItem>
                    {franchises.map((data, index) => (
                      <MenuItem key={index} value={data.id}>
                        {data.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-3 ">
                <label className="mt-3">Depot</label>
                <FormControl fullWidth>
                  <Select
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                    className="form-control"
                    name="depot_pod"
                    displayEmpty
                    IconComponent={(props) => (
                      <i {...props}>
                        <KeyboardArrowDownIcon />
                      </i>
                    )}
                    inputProps={{ "aria-label": "Without label" }}
                    {...formik.getFieldProps("depot_pod")}
                  >
                    <MenuItem value="all">All</MenuItem>
                    {depot.map((data, index) => (
                      <MenuItem key={index} value={data.id}>
                        {data.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-2 mb-5">
                <label className="mt-3">From</label>
                <InputGroup className="calendr">
                  <DatePicker
                    fullWidth
                    fixedHeight
                    className="form-control"
                    wrapperClassName="datepicker"
                    dateFormat="dd-MM-yyyy"
                    placeholderText="Select Date"
                    selected={Date.parse(formik?.values?.from_pod)}
                    onChange={(dates) => {
                      formik.setFieldValue(
                        "from_pod",
                        moment(dates).format("yyyy-MM-DD")
                      );
                      const date = new Date(dates);

                      // Increment the month by 3
                      date.setMonth(date.getMonth() + 3);

                      // Set the date to the 1st of the month
                      date.setDate(1);

                      // Return the formatted date as a string (YYYY-MM-DD)
                      const data = date.toISOString().slice(0, 10);
                      setMinDate(data);
                    }}
                  />
                  <CalendarTodayIcon />
                </InputGroup>
              </div>
              <div className="col-2 mb-5">
                <label className="mt-3">To</label>
                <InputGroup className="calendr">
                  <DatePicker
                    fullWidth
                    fixedHeight
                    className="form-control"
                    wrapperClassName="datepicker"
                    dateFormat="dd-MM-yyyy"
                    placeholderText="Select Date"
                    selected={Date.parse(formik?.values?.to_pod)}
                    minDate={Date.parse(formik?.values?.from_pod)}
                    maxDate={Date.parse(minDate)}
                    onChange={(dates) =>
                      formik.setFieldValue(
                        "to_pod",
                        moment(dates).format("yyyy-MM-DD")
                      )
                    }
                  />
                  <CalendarTodayIcon />
                </InputGroup>
              </div>

              <div className="col-2 mt-8">
                <button
                  type="button"
                  onClick={handleRun}
                  className="ml-4 btn btn-primary font-weight-bolder font-size-sm mt-3 "
                  disabled={
                    !formik?.values?.franchise_id_pod ||
                    !formik?.values?.depot_pod ||
                    !formik?.values?.to_pod ||
                    !formik?.values?.from_pod
                  }
                >
                  Run
                </button>
                <a
                  href={
                    formik?.values?.franchise_id_pod &&
                    formik?.values?.depot_pod &&
                    formik?.values?.to_pod &&
                    formik?.values?.from_pod
                      ? process.env.REACT_APP_SITE_URL +
                        `pod-in-storage?franchiseo=${formik?.values?.franchise_id_pod}&depot=${formik?.values?.depot_pod}&tenddatep=${formik?.values?.to_pod}&tstartdatep=${formik?.values?.from_pod}&Export=Export`
                      : ""
                  }
                >
                  <button
                    type="button"
                    className="ml-4 btn btn-primary font-weight-bolder font-size-sm mt-3"
                    disabled={
                      !formik?.values?.franchise_id_pod ||
                      !formik?.values?.depot_pod ||
                      !formik?.values?.to_pod ||
                      !formik?.values?.from_pod
                    }
                  >
                    Export
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div> */}
      </form>
    </>
  );
};
