/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

import React, { useEffect, useState } from "react";
import {
  AddonSchema,
  ChargeSchema,
  PlanSchema,
  ProductStripeSchema,
} from "../product-form/validationSchema";
import { ProductStripeValues } from "../product-form/InitialValues";
import {
  createProduct,
  updateProduct,
  updateStripeprice,
} from "../../../_redux/products/productsCrud";
import {
  fetchProductDetail,
  fetchProducts,
} from "../../../_redux/products/productsActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import DiscardDialog from "../../../../../../_metronic/_partials/widgets/alert/DiscardDialog";
import ProductFormStripe from "../product-form/ProductFormStripe";
import SnackBarTool from "../../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import currencyApi from "../../../../../utils/currencyApi.json";
import { fetchActiveCountry } from "../../../_redux/country/countryAction";
import { initialFilter } from "../ProductsUIHelpers";
import { useFormik } from "formik";
import { useIntl } from "react-intl";
import {
  productFormDataValues,
  productPriceFormDataValues,
} from "../../../__mocks__/dummyData";
import { useParams } from "react-router-dom";

export function NewProductCanvas({
  canva,
  setCanva,
  editValue,
  type,
  detailDataProduct,
}) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { detailData } = useSelector(
    (state) => ({
      detailData: state.country.activeCountry,
    }),
    shallowEqual
  );
  const params = useParams();
  const [dialogDis, setDialogDis] = useState(false);
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [loading, setLoading] = useState(false);

  const handleProductEdit = (values) => {
    const formData = new FormData();
    productFormDataValues.map((values) => {
     return formData.append(values.apiValue, formik.values[values.formikValue]);
    });
    if (formik.values.tieredPrice.length) {
      formik.values.tieredPrice.forEach((data, index) => {
        if (data.trim() !== "") {
          console.log("DATA>TRIM");
          formData.append(`price[tiers][${index}][unit_amount]`, data);
          formData.append(`price[tiers][${index}][up_to]`, index + 1);
          formData.append(
            `price[tiers][${index}][id]`,
            editValue?.product_prices[index]?.id
          );
        }
      });
    }
    
    formData.append(
      "price_id",
      editValue?.product_prices[0]?.id ? editValue?.product_prices[0]?.id : ""
    );
    formData.append("price[currency]", "GBP");
    updateProduct(formData, values.id)
      .then((res) => {
        setSnack({ call: true, type: "success", message: res.data.message });
        setTimeout(() => {
          setCanva({ type: false });
          dispatch(fetchProductDetail(values.id));
        }, 2000);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
  };

  const handleProductPriceEdit = (values) => {
    const formData = new FormData();
    if (canva?.data?.type === "editSitePrice") {
      formData.append("franchise_id", params.id);
      formData.append("product_id", canva?.data?.pID);
      formData.append("site_id", canva?.data?.siteID);
      formData.append("unit_amount", formik.values.amount);
      if (editValue && editValue.product_prices?.length > 0) {
        formData.append("price_id", editValue.product_prices[0].id);
      }
    } else {
      productPriceFormDataValues.map((values) => {
        formData.append(values.apiValue, formik.values[values.formikValue]);
      });
      if (formik.values.tieredPrice.length) {
        formik.values.tieredPrice.forEach((data, index) => {
          if (data !== "") {
            formData.append(`tiers[${index}][unit_amount]`, data);
            formData.append(`tiers[${index}][up_to]`, index + 1);

            if (editValue && editValue.product_prices?.length > 0) {
              if (canva.data.sectorId) {
                const isSectorPrice =
                  editValue &&
                  editValue.product_prices?.length &&
                  editValue.product_prices.filter(
                    (e) => e.sector_id === canva.data.sectorId
                  );
                formData.append(
                  `tiers[${index}][id]`,
                  isSectorPrice[index]?.id ? isSectorPrice[index]?.id : ""
                );
              } else {
                formData.append(
                  `tiers[${index}][id]`,
                  editValue?.product_prices[index]?.id
                    ? editValue?.product_prices[index]?.id
                    : ""
                );
              }
            }
          }
        });
      }
      formData.append("sector_id", canva?.data?.sectorId || "");
      formData.append("franchise_id", params.id);
      formData.append("product_id", editValue?.id);
      if (editValue && editValue.product_prices?.length === 1) {
        formData.append("price_id", editValue.product_prices[0].id);
      }
    }
    updateStripeprice(formData)
      .then((res) => {
        setSnack({ call: true, type: "success", message: res.data.message });
        setTimeout(() => {
          setCanva({ type: false });
          dispatch(fetchProductDetail(params.p_id, params.id));
          setLoading(false);
        }, 2000);
      })
      .catch((error) => {
        setLoading(false);
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
  };

  const handleCreateProduct = (values) => {
    const formData = new FormData();
    productFormDataValues.map((values) => {
      formData.append(values.apiValue, formik.values[values.formikValue]);
    });
    if (formik.values.tieredPrice.length) {
      formik.values.tieredPrice.forEach((data, index) => {
        if (data.trim() !== "") {
          formData.append(`price[tiers][${index}][unit_amount]`, data);
          formData.append(`price[tiers][${index}][up_to]`, index + 1);
        }
      });
    }
    formData.append("price[currency]", "GBP");
    createProduct(formData)
      .then((res) => {
        setSnack({ call: true, type: "success", message: res.data.message });
        dispatch(fetchProducts(initialFilter));
        setTimeout(() => {
          setCanva({ type: false });
        }, 2000);
        setLoading(false);
        formik.resetForm();
        // canvaCancel("kt_plan_panel_close");
      })
      .catch((error) => {
        setLoading(false);
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
  };

  const handleFormSubmit = (values) => {
    console.log("TYPEE", type)
    setSnack({ call: false, type: "", message: "" });
    setLoading(true);
    if (type === "productEdit") {
      console.log("HERE In PRODUCT EDIT")
      handleProductEdit(values);
    } else if (type === "priceEdit" || canva?.data?.type === "editSitePrice") {
      handleProductPriceEdit(values);
    } else {
      handleCreateProduct(values);
    }
  };
  const formik = useFormik({
    initialValues: ProductStripeValues(editValue, canva?.data?.sectorId),
    enableReinitialize: true,
    // validationSchema: ProductStripeSchema,
    onSubmit: handleFormSubmit,
  });

  const handleClose = () => {
    if (formik.dirty && Object.keys(formik.touched)?.length !== 0) {
      setDialogDis(true);
    } else {
      setCanva({ type: false });
      formik.resetForm();
    }
  };

  const handleCurrency = () => {
    const contryCurrency = detailData?.filter(
      (element) => element.id === Number(formik.values.country)
    );
    if (contryCurrency?.length) {
      const currencyCode = currencyApi.filter(
        (element) => element.name === contryCurrency[0]?.currency
      );
      if (currencyCode?.length) {
        formik.setFieldValue("currency_code", currencyCode[0]?.cc);
      } else {
        formik.setFieldValue("currency_code", "");
      }
    }
  };

  console.log("FORMIK VALUES", formik);

  useEffect(() => {
    if (formik.values.country) {
      handleCurrency();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.country]);

  useEffect(() => {
    dispatch(fetchActiveCountry({ type: "data_two" }));
  }, [dispatch]);
  return (
    <>
      <div
        id="kt_plan_panel"
        className="offcanvas offcanvas-right pt-5 pb-10 offcanvas-on"
      >
        {/*begin::Header*/}
        <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
          <div
            className="offcanvas-close mt-n1  pr-5"
            style={{ position: "absolute", top: "15px", right: "10px" }}
          >
            <button
              type="button"
              className="btn btn-xs btn-icon btn-light btn-hover-primary"
              onClick={() => handleClose()}
            >
              <i className="ki ki-close icon-xs text-muted"></i>
            </button>
            <div id="kt_plan_panel_close"></div>
          </div>
        </div>

        {/*end::Header*/}

        {/*begin::Content*/}

        <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
          <div className="offcanvas-content px-10">
            <div>
              <h5>{canva?.data?.title}</h5>
            </div>
            <div className="tab-content">
              <div
                id="kt_quick_panel_logs_kt_plan_panel"
                role="tabpanel"
                className={`tab-pane fade pt-3 pr-5 mr-n5 scroll active show overflowCanva`}
              >
                {/* {canva?.data?.type === "plan" ? (
                  <NewPlanCanvas
                    formik={formik}
                    detailData={detailData}
                    type={type}
                  />
                ) : canva?.data?.type === "addon" ? (
                  <NewAddonsCanvas
                    formik={formik}
                    detailData={detailData}
                    type={type}
                  />
                ) : canva?.data?.type === "charge" ? (
                  <NewChargesCanvas
                    formik={formik}
                    detailData={detailData}
                    type={type}
                  />
                ) :  */}
                {canva?.data?.type === "stripeProduct" ||
                canva?.data?.type === "editProduct" ||
                canva?.data?.type === "editPrice" ? (
                  <ProductFormStripe
                    canva={canva}
                    formik={formik}
                    detailData={detailData}
                    type={type}
                    editValue={editValue}
                  />
                ) : canva?.data?.type === "editSitePrice" ? (
                  <div className="col-lg-4">
                    <label>Site Price</label>
                    <input
                      className="form-control"
                      value={formik.values.amount}
                      placeholder="Enter Price"
                      name="amount"
                      onChange={formik.handleChange}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
              {/*end::Content*/}
            </div>
          </div>

          <div className="offcanvas-footer">
            <div className="offcanvas-content text-right ">
              <button
                type="button"
                className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
                onClick={() => handleClose()}
              >
                {intl.formatMessage({ id: "CANCEL" })}
              </button>
              <button
                className="btn btn-primary font-weight-bolder font-size-sm "
                type="submit"
                // onClick={() => {
                //   if (!editValue) {
                //     const productID = (prefix = "prod_", length = 12) => {
                //       const characters =
                //         "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
                //       let randomPart = "";

                //       for (let i = 0; i < length; i++) {
                //         const randomIndex = Math.floor(
                //           Math.random() * characters.length
                //         );
                //         randomPart += characters[randomIndex];
                //       }
                //       return `${prefix}${randomPart}`;
                //     };
                //     const newProductId = productID();
                //     formik.setFieldValue("id", newProductId);
                //   }
                // }}
              >
                {intl.formatMessage({ id: "SAVE" })}
                {loading && (
                  <span className="spinner spinner-white mr-4"></span>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
      <DiscardDialog
        setDialogDis={setDialogDis}
        dialogDis={dialogDis}
        formik={formik}
        closeId={"kt_plan_panel_close"}
        setCanva={setCanva}
      />
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </>
  );
}
