import React from "react";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";

const Card = ({ title, url, description, svgPath , id }) => {
    return (
      
          <div className="card card-custom card-stretch gutter-b">
              <div className="card-body">
                  <div className="d-flex align-items-center ">
                      <div className="symbol symbol-40 symbol-light-primary mr-5">
                          <span className="symbol-label">
                              <span className="svg-icon svg-icon-lg svg-icon-primary">
                                  <SVG className="h-75 align-self-end" src={ svgPath }></SVG>
                              </span>
                          </span>
                      </div>
                      <div className="d-flex flex-column font-weight-bold">
                          <Link to={url}>{title}</Link>
                          <span className="text-muted">{description}.</span>
                      </div>
                  </div>
              </div>
          </div>
       
    );
};

export default Card;

