import axios from "axios";
export const NOTIFICATION = "user-alert-notification";
export const DASHBOARD_FEEDS = "timeline-list";
export const DASHBOARD_DATA = "get-dashboard-data";
export const POD_STANDING = "get-pod-standings";
export const CONTAINER_STANDING = "get-container-standings";
export const PERMISSION = "user-permissions-list";
export const CALENDAR_TEST = "calendar/get-booking-calendar?";
export const CALENDAR_ACCESS = "calendar/access-time-slots-list?";
export const ACTIVE_VAN = "calendar/van-list";

export function convertContactDeal() {
  return axios.get(`${process.env.REACT_APP_SITE_URL + NOTIFICATION}`);
}
export function exportDownload(formik) {
  return axios.get(
    `${process.env.REACT_APP_SITE_URL +
    `export-orders?franchise_id=${formik?.values?.franchise_id}&type=${formik?.values?.type}&to=${formik?.values?.to}&from=${formik?.values?.from}&export=booked`}`
  );
}

export function dashboardFeed() {
  return axios.get(`${process.env.REACT_APP_SITE_URL + DASHBOARD_FEEDS}`);
}

export function getDashboardDataAxios(data) {
  return axios.get(`${process.env.REACT_APP_SITE_URL + DASHBOARD_DATA}?franchise_id=${data?.franchise_id}&end_date=${data?.end_date}&start_date=${data?.start_date}`);
}
export function getDashboardPodStandingDataAxios(data) {
  return axios.get(`${process.env.REACT_APP_SITE_URL + POD_STANDING}?franchise_id=${data?.franchise_id}&end_date=${data?.end_date}&start_date=${data?.start_date}`);
}
export function getDashboardContainerStandingDataAxios(data) {
  return axios.get(`${process.env.REACT_APP_SITE_URL + CONTAINER_STANDING}?franchise_id=${data?.franchise_id}&end_date=${data?.end_date}&start_date=${data?.start_date}`);
}

export function permissionFetch() {
  return axios.get(`${process.env.REACT_APP_SITE_URL + PERMISSION}`);
}

//calendar demo test for backend
export function calendarTest(data) {

  return axios.get(
    `${process.env.REACT_APP_SITE_URL +
    CALENDAR_TEST +
    `franchise_id=${data.id}&date=${data.date}&pod=${data.pod}&is_overwrite=${data.is_overwrite ? true : false
    }${data.search_keyword ? "&search_keyword=" + data.search_keyword : ""}${data?.is_access ? "&is_access=" + data?.is_access : ""
    }${data?.depot_id ? "&depot_id=" + data?.depot_id : ""}${data?.is_calendar ? "&is_calendar=" + data?.is_calendar : ""}${data?.customer_postcode ? "&customer_postcode=" + data?.customer_postcode : ""}${data?.depot_postcode ? "&depot_postcode=" + data?.depot_postcode : ""}`}`
  );
}
export function calendarAccess(data) {
  return axios.get(
    `${process.env.REACT_APP_SITE_URL +
    CALENDAR_ACCESS +
    `franchise_id=${data.id}&date=${data.date}&pod=${data.pod}&is_overwrite=${data.is_overwrite ? true : false
    }${data.search_keyword ? "&search_keyword=" + data.search_keyword : ""}${data?.is_access ? "&is_access=" + data?.is_access : ""
    }${data?.depot_id ? "&depot_id=" + data?.depot_id : ""}&day=${data.day}`}`
  );
}
export function getActiveVan(id) {
  return axios.get(
    `${process.env.REACT_APP_SITE_URL + ACTIVE_VAN}?franchise_id=${id}`
  );
}
export function getStorageList(query) {
  return axios.get(
    process.env.REACT_APP_SITE_URL +
    `export-orders?franchise_id=${query?.franchise_id_pod}&type=${query?.depot_pod}&to=${query?.to_pod}&from=${query?.from_pod}&Run=Run&oby=oby`
  );
}
