import React from "react";
import { Form } from "react-bootstrap";
import { getInputClasses } from "../../../../../utils/commonFunction";
import { useIntl } from "react-intl";

export function AddNotes({ formik, loading }) {
  const intl = useIntl();

  return (
    <>
      <div className="fieldset-area mt-5">
        <fieldset className="mb-5">
          {/*<legend>15-11-2021 at 13:24</legend>*/}
          <div className="p-5 ">
            <div className="row">
              <div className="col-md-12">
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>
                    <h6>{intl.formatMessage({ id: "Notes" })}</h6>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    className={`form-control  ${getInputClasses(
                      formik,
                      "note"
                    )}`}
                    name="note"
                    {...formik.getFieldProps("note")}
                    // maxLength={600}
                  />
                  {/* {formik.values.note?.length === 600 ? (
                    <span style={{ color: "red" }}>
                      {intl.formatMessage({ id: "600.character" })}
                    </span>
                  ) : (
                    ""
                  )} */}
                  {formik.touched.note && formik.errors.note ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.note}</div>
                    </div>
                  ) : null}
                </Form.Group>
              </div>
              <div className="col-md-12 text-right">
                <button
                  type="reset"
                  className="btn btn-dark font-weight-bolder font-size-sm mr-3"
                >
                  {intl.formatMessage({ id: "CANCEL" })}
                </button>
                <button
                  type="submit"
                  className="btn btn-primary font-weight-bolder font-size-sm "
                  disabled={loading}
                >
                  {intl.formatMessage({ id: "SAVE" })}
                  {loading && (
                    <span className="ml-3 mr-3 spinner spinner-white"></span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
    </>
  );
}
