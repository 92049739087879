export const ProductStatusCssClasses = ["danger", "success", ""];
export const ProductStatusTitles = ["Inactive", "Active"];
export const ProductConditionCssClasses = ["success", "danger", ""];
export const ProductConditionTitles = ["New", "Used"];
export const defaultSorted = [{ dataField: "", order: "asc" }];
export const sizePerPageList = [
  { text: "20", value: 20 },
  { text: "50", value: 50 },
  { text: "100", value: 100 },
];
export const initialFilter = {
  filter: {
    contactName: "",
    email: "",
    search_keyword: "",
    status: "",
    search_franchise: "",
  },
  sortOrder: "", // asc||desc
  sortField: "",
  pageNumber: 1,
  pageSize: 20,
};
