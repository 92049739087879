import axios from "axios";

export const LOGIN_URL_LIVE = "login";
export const LOGIN_URL = "api/auth/login";
export const ME_URL = "api/me";
export const RESET_PASSWORD = "reset-password";
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
export const REQUEST_PASSWORD_URL_LIVE = "forgot-password";
export const SITE_URL_LIVE = "http://192.168.4.197:8000/";

export function login(email, password) {
  // mock.restore();
  return axios.post(process.env.REACT_APP_SITE_URL + LOGIN_URL_LIVE, {
    email,
    password,
  });
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  // mock.restore();
  return axios.post(
    process.env.REACT_APP_SITE_URL + REQUEST_PASSWORD_URL_LIVE,
    { email }
  );
}
export function resetPassword(email, password, password_confirmation, token) {
  // mock.restore();
  return axios.post(process.env.REACT_APP_SITE_URL + RESET_PASSWORD, {
    email,
    password,
    password_confirmation,
    token,
  });
}
