// import { useIntl } from "react-intl";
export const FranchiseStatusCssClasses = ["success", "info", ""];

export const FranchiseStatusTitles = ["Selling", "Sold"];
export const FranchiseConditionCssClasses = ["success", "danger", ""];
export const FranchiseConditionTitles = ["New", "Used"];
export const defaultSorted = [{ dataField: "id", order: "asc" }];
export const sizePerPageList = [
  { text: "20", value: 20 },
  { text: "50", value: 50 },
  { text: "100", value: 100 },
];
export const initialFilter = {
  filter: {
    search: "",
  },
  sortOrder: "", // asc||desc
  sortField: "",
  pageNumber: 1,
  pageSize: 20,
};
export const AVAILABLE_COLORS = [
  "Red",
  "CadetBlue",
  "Eagle",
  "Gold",
  "LightSlateGrey",
  "RoyalBlue",
  "Crimson",
  "Blue",
  "Sienna",
  "Indigo",
  "Green",
  "Violet",
  "GoldenRod",
  "OrangeRed",
  "Khaki",
  "Teal",
  "Purple",
  "Orange",
  "Pink",
  "Black",
  "DarkTurquoise",
];

export const AVAILABLE_MANUFACTURES = [
  "Pontiac",
  "Kia",
  "Lotus",
  "Subaru",
  "Jeep",
  "Isuzu",
  "Mitsubishi",
  "Oldsmobile",
  "Chevrolet",
  "Chrysler",
  "Audi",
  "Suzuki",
  "GMC",
  "Cadillac",
  "Infinity",
  "Mercury",
  "Dodge",
  "Ram",
  "Lexus",
  "Lamborghini",
  "Honda",
  "Nissan",
  "Ford",
  "Hyundai",
  "Saab",
  "Toyota",
];

export const formikFranchiseEdit = (detailData) => {
  return {
    name: detailData?.name || "",
    short_name: detailData?.short_name || "",
    franchise_reference_id: detailData?.franchise_reference_id || "",
    manager_name: detailData?.manager_name || "",
    email: detailData?.email || "",
    phone: detailData && detailData.phone ? detailData.phone : "",
    trading_name: detailData?.trading_name || "",
    vat_registration_no: detailData?.vat_registration_no || "",
    address_line_1: detailData?.address_line_1 || "",
    address_line_2: detailData?.address_line_2 || "",
    address_line_3: detailData?.address_line_3 || "",
    city: detailData?.city || "",
    postcode: detailData?.postcode || "",
    country_id: detailData?.country_id || "",
    telephone_number: detailData?.telephone_number || "",
    additional_emails: detailData?.additional_emails || "",
    depot_notification_email: detailData?.depot_notification_email || "",
    plan_type:detailData?.plan_type||[],
    status: detailData?.status || 0,
    discount_after_renewal:detailData?.discount_after_renewal,
    container_fifty_percent_discount:detailData?.container_fifty_percent_discount
  };
};
