/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { Modal } from "react-bootstrap";
import React, { useState } from "react";
import { useFormik } from "formik";
// import { useDispatch } from "react-redux";
import SnackBarTool from "../../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { LoadingCustome } from "../../../../../../_metronic/_partials/controls/LoadingCustome";
import { getInputClasses } from "../../../../../utils/commonFunction";
// import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

// import { FormControl, MenuItem, Select } from "@material-ui/core";
// import { chargebee } from "../../../_mocks/dummyData";
import {
  // getProduct,
  updateSubscription,
} from "../../../_redux/order/orderCrud";
import { getUpdateSubscriptionPayload } from "../../../EditOrderUtils";
import { useHistory } from "react-router-dom";
const UpdateSubscriptionModal = ({ dialog, setDialog, orderUIContext }) => {
  // const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const initialValues = {
    comment: "",
  };

  const handleSubmit = (values) => {
    setSnack({ call: false, type: "", message: "" });
    setIsLoading(true);
    const dataObject = getUpdateSubscriptionPayload(orderUIContext?.state);
    dataObject.comment = values?.comment || "";

    for (const obj of dataObject.items) {
      if (obj.hasOwnProperty("insurance_cover")) {
        dataObject.insurance_cover = obj.insurance_cover;
        break; // Stop searching once we find an object with the "h" property.
      }
    }
    for (const obj of dataObject.items) {
      if (obj.id === "Insurance-Cover" && !dataObject.insurance_cover) {
        setSnack({
          call: true,
          type: "error",
          message: "Please select insurance cover to complete this process",
        });
        setIsLoading(false);
        return;
      }
    }

    updateSubscription(dataObject)
      .then((res) => {
        setIsLoading(false);
        setSnack({ call: true, type: "success", message: res.data.message });
        setTimeout(() => {
          history.push(
            
            `/order-details/${orderUIContext?.state?.orderData?.order_id}`
          );
        }, 3000);
      })
      .catch((error) => {
        setTimeout(() => {
        setIsLoading(false);
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      }, 3000);
      });
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    // validationSchema: CompetitorModalSchema,
    onSubmit: handleSubmit,
  });
  const handleClose = () => {
    setDialog({ update: false });
  };
  return (
    <Modal
      show={dialog?.update}
      aria-labelledby="example-modal-sizes-title-lg"
      onHide={handleClose}
    >
      <Modal.Header className="d-flex justify-content-between">
        <Modal.Title id="example-modal-sizes-title-lg">
          Change subscription?
        </Modal.Title>
        <span
          className="btn btn-xs btn-icon btn-light btn-hover-primary"
          onClick={handleClose}
        >
          <i className="ki ki-close icon-sm text-muted"></i>
        </span>
      </Modal.Header>
      <form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          {isLoading && <LoadingCustome />}
          <div className="row">
            <div className="col-12 mt-2">
              <label>Add a comment</label>
              <div className="form-groupB">
                <textarea
                  className={`form-control ${getInputClasses("comment")}`}
                  placeholder="Add your comment here"
                  name="comment"
                  // value={formik.values.comment}
                  // maxLength={formik.discountType === "percentage" ? "3" : "4"}
                  {...formik.getFieldProps("comment")}
                />
                {formik.touched.comment && formik.errors.comment ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.comment}</div>
                  </div>
                ) : null}
              </div>
            </div>{" "}
            {/* <div className="col-12">
              <label>Number of billing cycles applicable</label>
              <div className="form-groupB">
                <input
                  type="text"
                  className={`form-control  `}
                  placeholder={"billing cycles"}
                  name="billing"
                  {...formik.getFieldProps("billing")}
                />
                {formik.touched.last_name && formik.errors.last_name ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.last_name}
                    </div>
                  </div>
                ) : null}
              </div>
            </div> */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              onClick={() => handleClose()}
              className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
              //   disabled={isLoading}
            >
              Dismiss
            </button>
            <button
              type="submit"
              className="btn btn-primary font-weight-bolder font-size-sm "
            >
              Save
            </button>
          </div>
        </Modal.Footer>
      </form>
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </Modal>
  );
};

export default UpdateSubscriptionModal;
