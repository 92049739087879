import React, { useMemo } from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import ErrorIcon from "@material-ui/icons/Error";
import { useState } from "react";
import BreakDownTableModal from "./Modal/BreakDownTableModal";
import {
  divideByHundred,
  divideByHundredReturn,
} from "../../../../utils/canvaCloseFunction";
import moment from "moment";
import {
  addTwoValue,
  convertGBPtoPoundSymbol,
} from "../../../../utils/commonFunction";

export const EditBreakDownCard = ({ orderUIContext }) => {
  const [dialog, setDialog] = useState({ table: false });
  const item = orderUIContext?.state?.breakdown;
  const getHeading = useMemo(() => {
    const formatTitle = (str) => {
      const words = str?.split("_");
      return words
        ? words
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ")
        : "";
    };

    return (value, str) => formatTitle(str) + " " + value;
  }, []);

  // const totalDiscountedAmount = useMemo(() => {
  //   return item?.next_invoice_estimate?.line_items?.reduce((total, item) => {
  //     const amount = divideByHundredReturn(+item.amount);
  //     const discount = divideByHundredReturn(+item?.discount_amount || 0);
  //     const discountedAmount = Number(amount) - Number(discount);
  //     return total + discountedAmount;
  //   }, 0);
  // }, [item.next_invoice_estimate.line_items]);

  return (
    <div className="card-body pt-0">
      <div className="py-4">
        {orderUIContext?.state?.orderData.status === "future" ? (
          <div class=" mb-4">
            Subscription activates on{" "}
            {moment(orderUIContext?.state?.orderData?.startDate).format(
              "DD-MM-yyyy"
            )}
          </div>
        ) : (
          <div class=" mb-4">
            Subscription status remains{" "}
            <strong>{orderUIContext?.state?.orderData.status}.</strong>
          </div>
        )}
        <div className="d-flex mb-4">
          <span className={`svg-icon svg-icon-danger svg-icon-lg mr-2`}>
            <ErrorIcon />
          </span>
          <div>
            {item?.invoice_estimate ? (
              <h6>
                {convertGBPtoPoundSymbol(orderUIContext?.state?.currencyCode)}
                {item?.invoice_estimate?.amount_due
                  ? divideByHundred(item?.invoice_estimate?.amount_due)
                  : "0"}
              </h6>
            ) : orderUIContext?.state?.orderData?.status === "future" ? (
              <h6>
                {/* {convertGBPtoPoundSymbol(orderUIContext?.state?.currencyCode)} */}
                {/* {"0.00"} Amount Due */}
                {item?.next_invoice_estimate?.amount_due
                  ? divideByHundred(item?.next_invoice_estimate?.amount_due)
                  : "0"}
              </h6>
            ) : (
              <h6>
                {convertGBPtoPoundSymbol(orderUIContext?.state?.currencyCode)}
                {"0.00"} Amount Due
                {/* {item?.next_invoice_estimate?.amount_due
                  ? divideByHundred(item?.next_invoice_estimate?.amount_due)
                  : "0"} */}
              </h6>
            )}
            {item?.invoice_estimate?.object ? (
              <span>
                {getHeading("Amount due", item?.invoice_estimate?.object)}
              </span>
            ) : orderUIContext?.state?.orderData?.status === "future" ? (
              <span>
                {getHeading("Amount due", item?.next_invoice_estimate?.object)}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>

        {// item?.invoice_estimate?.credits_applied !== 0 ? (
        //   <div className="d-flex mb-4">
        //     <span className={`svg-icon svg-icon-success svg-icon-lg mr-2`}>
        //       <SVG
        //         title=" "
        //         className="h-25 align-self-end"
        //         src={toAbsoluteUrl("/media/svg/icons/Code/Done-circle.svg")}
        //       ></SVG>
        //     </span>
        //     <span>
        //       {item?.invoice_estimate?.credits_applied
        //         ? divideByHundred(item?.invoice_estimate?.credits_applied)
        //         : "0"}
        //       &nbsp;
        //       {getHeading("Credits applied", item?.invoice_estimate?.object)}
        //     </span>
        //   </div>
        // )
        // :
        item?.next_invoice_estimate?.credits_applied !== 0 ? (
          <div className="d-flex mb-4">
            <span className={`svg-icon svg-icon-success svg-icon-lg mr-2`}>
              <SVG
                title=" "
                className="h-25 align-self-end"
                src={toAbsoluteUrl("/media/svg/icons/Code/Done-circle.svg")}
              ></SVG>
            </span>
            <span>
              {item?.next_invoice_estimate?.credits_applied
                ? divideByHundred(item?.next_invoice_estimate?.credits_applied)
                : "0"}
              &nbsp;
              {getHeading(
                "Credits applied",
                item?.next_invoice_estimate?.object
              )}
            </span>
          </div>
        ) : (
          ""
        )}

        {!item?.invoice_estimate?.discounts?.length > 0 &&
          !item?.credit_note_estimates?.discounts?.length > 0 &&
          item?.next_invoice_estimate?.discounts?.map((row, index) => (
            <div className="d-flex mb-4" key={index}>
              <span className={`svg-icon svg-icon-success svg-icon-lg mr-2`}>
                <SVG
                  title=" "
                  className="h-25 align-self-end"
                  src={toAbsoluteUrl("/media/svg/icons/Code/Done-circle.svg")}
                ></SVG>
              </span>
              <span>
                {row?.amount ? divideByHundred(row?.amount) : "0"}&nbsp;
                {getHeading("Amount", row?.object)}
              </span>
            </div>
          ))}
        {item?.invoice_estimate?.discounts?.map((row, index) => (
          <div className="d-flex mb-4" key={index}>
            <span className={`svg-icon svg-icon-success svg-icon-lg mr-2`}>
              <SVG
                title=" "
                className="h-25 align-self-end"
                src={toAbsoluteUrl("/media/svg/icons/Code/Done-circle.svg")}
              ></SVG>
            </span>
            <span>
              {row?.amount ? divideByHundred(row?.amount) : "0"}&nbsp;
              {getHeading("Amount", row?.object)}
            </span>
          </div>
        ))}
        {item?.credit_note_estimates?.map((row, index) => (
          <div className="d-flex mb-4" key={index}>
            <span className={`svg-icon svg-icon-success svg-icon-lg mr-2`}>
              <SVG
                title=" "
                className="h-25 align-self-end"
                src={toAbsoluteUrl("/media/svg/icons/Code/Done-circle.svg")}
              ></SVG>
            </span>
            <span>
              {row?.total ? divideByHundred(row?.total) : "0"}&nbsp;
              {getHeading("Total", row?.object)}
            </span>
          </div>
        ))}
        {item?.credit_note_estimates?.map((row, index) => {
          if (row?.amount_available === 0) {
            return true;
          }
          return (
            <div className="d-flex mb-4" key={index}>
              <span className={`svg-icon svg-icon-success svg-icon-lg mr-2`}>
                <SVG
                  title=" "
                  className="h-25 align-self-end"
                  src={toAbsoluteUrl("/media/svg/icons/Code/Done-circle.svg")}
                ></SVG>
              </span>
              <span>
                {row?.amount_available
                  ? divideByHundred(row?.amount_available)
                  : "0"}
                &nbsp;
                {getHeading("Amount Available", row?.object)}
              </span>
            </div>
          );
        })}
        {item?.credit_note_estimates &&
          item?.credit_note_estimates.length > 0 &&
          item?.credit_note_estimates[0]?.discounts?.map((row, index) => (
            <div className="d-flex mb-4" key={index}>
              <span className={`svg-icon svg-icon-success svg-icon-lg mr-2`}>
                <SVG
                  title=" "
                  className="h-25 align-self-end"
                  src={toAbsoluteUrl("/media/svg/icons/Code/Done-circle.svg")}
                ></SVG>
              </span>
              <span>
                {row?.amount ? divideByHundred(row?.amount) : "0"}&nbsp;
                {getHeading("Amount", row?.object)}
              </span>
            </div>
          ))}
        {item?.unbilled_charge_estimates?.map((row, index) => (
          <div className="d-flex mb-4" key={index}>
            <span className={`svg-icon svg-icon-success svg-icon-lg mr-2`}>
              <SVG
                title=" "
                className="h-25 align-self-end"
                src={toAbsoluteUrl("/media/svg/icons/Code/Done-circle.svg")}
              ></SVG>
            </span>
            <span>
              {row?.amount ? divideByHundred(row?.amount) : "0"}&nbsp;
              {getHeading("Total", row?.object)}
            </span>
          </div>
        ))}
        <div className="d-flex justify-content-end">
          <button
            type="button"
            class="btn btn-primaryCus font-weight-bolder font-size-sm mr-3 "
            onClick={() => setDialog({ table: true })}
          >
            See breakdown
          </button>
        </div>
      </div>
      {orderUIContext?.state?.orderData?.status !== "active" ? (
        <div>
          <strong>
            £
            {item?.next_invoice_estimate?.amount_due
              ? addTwoValue(
                  divideByHundred(item?.next_invoice_estimate?.amount_due),
                  item?.credit_note_estimates &&
                    item?.credit_note_estimates.length > 0
                    ? divideByHundredReturn(
                        item?.credit_note_estimates &&
                          item?.credit_note_estimates.length > 0
                          ? Number(
                              item?.credit_note_estimates[0]?.amount_allocated
                            )
                          : 0
                      )
                    : 0
                )
              : "0"}
          </strong>{" "}
          will be charged on renewals(excluding credit){" "}
        </div>
      ) : (
        // : orderUIContext?.state?.orderData?.status === "active" &&
        //   !orderUIContext?.state.applyChanges?.end_of_term ? (
        //   <>
        //     <div>
        //       Next invoice of{" "}
        //       <strong>
        //         £
        //         {addTwoValue(
        //           totalDiscountedAmount,
        //           item?.credit_note_estimates &&
        //             item?.credit_note_estimates.length > 0
        //             ? divideByHundredReturn(
        //                 item?.credit_note_estimates &&
        //                   item?.credit_note_estimates.length > 0
        //                   ? Number(item?.credit_note_estimates[0]?.total)
        //                   : 0
        //               )
        //             : 0
        //         )?.toFixed(2)}
        //       </strong>{" "}
        //       will be raised on{" "}
        //       <strong>
        //         {moment(
        //           +orderUIContext?.state?.orderData?.next_billing_at * 1000
        //         ).format("DD-MM-yyyy")}
        //       </strong>
        //     </div>
        //   </>
        // )
        <div>
          Next invoice of{" "}
          <strong>
            £
            {item?.next_invoice_estimate?.amount_due
              ? // ? addTwoValue(
                divideByHundred(item?.next_invoice_estimate?.amount_due)
              : // item?.credit_note_estimates &&
                //   item?.credit_note_estimates.length > 0
                //   ? divideByHundredReturn(
                //       item?.credit_note_estimates &&
                //         item?.credit_note_estimates.length > 0
                //         ? Number(
                //             item?.credit_note_estimates[0]?.amount_allocated
                //           )
                //         : 0
                //     )
                //   : 0
                //   )
                "0"}
            {item?.credit_note_estimates &&
            item?.credit_note_estimates.length > 0
              ? " (with Credit  £" +
                divideByHundredReturn(
                  item?.credit_note_estimates &&
                    item?.credit_note_estimates.length > 0
                    ? Number(item?.credit_note_estimates[0]?.amount_allocated)
                    : 0
                ) +
                ")"
              : ""}
          </strong>{" "}
          will be raised on{" "}
          <strong>
            {moment(
              +orderUIContext?.state?.orderData?.next_billing_at * 1000
            ).format("DD-MM-yyyy")}
          </strong>
        </div>
      )}
      {dialog.table && (
        <BreakDownTableModal
          dialog={dialog}
          setDialog={setDialog}
          orderUIContext={orderUIContext}
        />
      )}
    </div>
  );
};
