import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import NewBreadCrumbs from "../../../../../_metronic/layout/components/subheader/components/NewBreadCrumbs";
import { fetchAgent } from "../../../ContactDeals/_redux/contact/contactAction";
import { useFormik } from "formik";
import CustomeDialog from "../../../../../_metronic/_partials/widgets/alert/CustomeDialog";
import { assignAvailability } from "../../_redux/availability/availabilityCrud";
import SnackBarTool from "../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";

export const Availability = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [dialog, setDialog] = useState({ type: false });
  // Create state to manage snackbar notifications
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });

  const { agentList } = useSelector(
    (state) => ({ agentList: state.contact.agent }),
    shallowEqual
  );
  const initialValues = {
    agent_availability: [],
  };
  useEffect(() => {
    dispatch(fetchAgent());
  }, [dispatch]);
  const handleSubmit = () => {
    setSnack({ call: false, type: "", message: "" });
    assignAvailability(formik.values)
      .then((res) => {
        setSnack({ call: true, type: "success", message: res.data.message });
        setDialog({ type: false });
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
  };
  const formik = useFormik({
    initialValues,
    // validationSchema: AssignContactSchema(intl, Yup),
    onSubmit: handleSubmit,
  });
  const handleChange = (e, data, value) => {
    const agents = [...formik.values.agent_availability];
    if (value > -1) {
      agents[value].is_available = e.target.checked ? 1 : "0";
    } else {
      const item = {
        id: data.id,
        is_available: e.target.checked ? 1 : "0",
      };
      agents.push(item);
    }

    formik.setFieldValue("agent_availability", agents);
  };

  const handleAllAgent = (e) => {
    const arr = [];
    if (e.target.checked) {
      for (let items of agentList) {
        const data = {
          id: items.id,
          is_available: 1,
        };
        arr.push(data);
      }
    }
    formik.setFieldValue("agent_availability", arr);
  };

  useEffect(() => {
    if (agentList.length > 0) {
      const arr = [];

      for (let items of agentList) {
        const data = {
          id: items.id,
          is_available: items?.is_available === 1 ? 1 : "0",
        };
        arr.push(data);
      }

      formik.setFieldValue("agent_availability", arr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentList, agentList.length]);

  return (
    <>
      <NewBreadCrumbs title={"Sales Agent Availability"} />
      <form onSubmit={formik.handleSubmit}>
        <div className="card card-custom gutter-b ">
          <div className="card-body feeds-section">
            {/* Title for feeds section */}
            <div className="d-flex ">
              <h3 className="card-title align-items-start flex-column d-flex">
                {" "}
                <span className="font-weight-bolder text-dark">
                  Sales Agent Availability
                </span>
              </h3>
            </div>{" "}
            <div className="col-md-12">
              <span className="switch switch-outline switch-icon switch-primary row">
                <span className="col-8 col-md-3">
                  {intl.formatMessage({ id: "All.Franchises" })}
                </span>
                <label className="col-3 col-md-3">
                  <input
                    type="checkbox"
                    name="is_all_franchise"
                    checked={
                      formik.values?.agent_availability.length > 0 &&
                      formik.values?.agent_availability.length ===
                        agentList.length &&
                      formik.values?.agent_availability.every(
                        (e) => e?.is_available === 1
                      )
                    }
                    // disabled={franchiseList && !franchiseList.length}
                    // {...formik.getFieldProps("is_all_franchise")}
                    onChange={(e) => handleAllAgent(e)}
                  />
                  <span></span>
                </label>
              </span>
            </div>
            {agentList && agentList.length
              ? agentList.map((data, index) => {
                  // const charge = data.chargebee_key ? data.chargebee_key : data.id;
                  // const datas = formik.values.franchise_charbee_key.find(
                  //   (element) => element === charge
                  // );
                  const value = formik?.values?.agent_availability.findIndex(
                    (e) => e.id === data.id
                  );
                  return (
                    <div className="col-md-12" key={index}>
                      <span className="switch switch-outline switch-icon switch-primary row">
                        <span className="col-8 col-md-3">
                          {data.first_name +
                            " " +
                            (data.last_name ? data.last_name : "")}
                        </span>
                        <label className=" col-3 col-md-3">
                          <input
                            type="checkbox"
                            // name="is_all_franchise"
                            // name={`agent_availability[${index}].id`}
                            onChange={(e) => handleChange(e, data, value)}
                            checked={
                              Number(
                                formik.values?.agent_availability[value]
                                  ?.is_available
                              ) > 0
                                ? true
                                : false
                            }
                            // onChange={(e) => handleFranchise(e, data)}
                          />
                          <span></span>
                        </label>
                      </span>
                    </div>
                  );
                })
              : ""}
            <div style={{ width: "35%", textAlign: "end" }}>
              <button
                type="button"
                className="btn btn-primary font-weight-bolder font-size-sm "
                // disabled={loading}
                onClick={() => setDialog({ type: true })}
              >
                {intl.formatMessage({ id: "SAVE" })}
                {/* {loading && (
                <span className="ml-2 mr-2 spinner spinner-white"></span>
              )} */}
              </button>
            </div>
          </div>
        </div>
      </form>
      <CustomeDialog
        dialog={dialog}
        setDialog={setDialog}
        header="Save Changes?"
        mainText="Are you sure you want to save changes made to Availability?"
        handleAction={handleSubmit}
      />
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </>
  );
};
