/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

import { FormControl, MenuItem, Select } from "@material-ui/core/";
import React, { useEffect, useState } from "react";
import {
  fetchInvoiceList,
  fetchMyOrderTimeline,
  fetchOrderDropDown,
  fetchOrderInvoice,
  fetchPaymentInvoice,
} from "../_redux/order/orderAction";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import DatePicker from "react-datepicker";
import DiscardDialog from "../../../../_metronic/_partials/widgets/alert/DiscardDialog";
import { Form } from "react-bootstrap";
import { InputGroup } from "react-bootstrap";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { OfflinePaymentSchema } from "../order-form/validationSchema";
import SnackBarTool from "../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import moment from "moment";
import { offlinePaymentApi } from "../_redux/order/orderCrud";
import { useFormik } from "formik";
import { convertGBPtoPoundSymbol } from "../../../utils/commonFunction";

export function OfflinePayment({ setCanva, canva, orderDetailData }) {
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [dialogDis, setDialogDis] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const initialValues = {
    franchise_id: canva?.data?.franchise_id || "",
    invoice_id: canva?.data?.invoice_id || "",
    order_id: canva?.data?.order_id || "",
    currency_code: canva?.data?.currency || "",
    amount: "",
    payment_method: "",
    payment_date: "",
    reference_number: "",
    comment: "",
  };

  const { orderDetail } = useSelector(
    (state) => ({
      orderDetail: state.order.orderDropDown,
    }),
    shallowEqual
  );

  const handleSubmit = (values, actions) => {
    setLoading(true);
    setSnack({ call: false, type: "", message: "" });
    offlinePaymentApi(values)
      .then((res) => {
        setSnack({ call: true, type: "success", message: res.data.message });
        setTimeout(() => {
          setCanva({ type: false });
        }, 2000);
        setLoading(false);
        dispatch(
          fetchPaymentInvoice(
            canva?.data?.order_id,
            orderDetail?.franchise_id,
            orderDetail?.subscription_id
          )
        );
        dispatch(fetchOrderInvoice(canva?.data?.order_id));
        dispatch(
          fetchInvoiceList(
            canva?.data?.franchise_id,
            canva?.data?.order_id,
            orderDetailData?.subscription_id
          )
        );
        dispatch(fetchMyOrderTimeline(canva?.data?.order_id));
      })
      .catch((error) => {
        setLoading(false);
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
  };

  const handleReset = () => {};

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: OfflinePaymentSchema,
    onSubmit: handleSubmit,
    onReset: handleReset,
  });
  useEffect(() => {
    dispatch(fetchOrderDropDown());
  }, [dispatch]);
  const handleClose = () => {
    if (formik.dirty && Object.keys(formik.touched).length !== 0) {
      setDialogDis(true);
    } else {
      setCanva({ type: "" });
      formik.resetForm();
    }
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <div
          className=" offcanvas-content fade pt-3 pr-5 mr-n5 scroll date-picker active show ps--active-y mt-10"
          id="kt_quick_panel_logs_kt_CreditNotes_panel"
        >
          <div>
            <h5>Offline payment</h5>
          </div>

          <div>
            <Form.Group className="mb-5">
              <Form.Label>
                For invoice [#{canva?.data?.invoice_id || ""}]
              </Form.Label>
            </Form.Group>
          </div>
          <div>
            <Form.Group className="mb-5">
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="text"
                placeholder={
                  canva?.data?.currency
                    ? convertGBPtoPoundSymbol(canva?.data?.currency)
                    : ""
                }
                className={`form-control  ${getInputClasses("amount")}`}
                name="amount"
                {...formik.getFieldProps("amount")}
              />
              {formik.touched.amount && formik.errors.amount ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.amount}</div>
                </div>
              ) : null}
            </Form.Group>
          </div>
          <div>
            <Form.Group className="mb-5">
              <Form.Label>Payment method</Form.Label>
              <FormControl fullWidth>
                <Select
                  name="payment_method"
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                  IconComponent={(props) => (
                    <i {...props}>
                      <KeyboardArrowDownIcon />
                    </i>
                  )}
                  inputProps={{ "aria-label": "Without label" }}
                  displayEmpty
                  className={`form-control w-100 ${getInputClasses(
                    "payment_method"
                  )}`}
                  {...formik.getFieldProps("payment_method")}
                >
                  <MenuItem value="">Payment method</MenuItem>
                  {orderDetail &&
                  orderDetail?.paymentMethod &&
                  orderDetail.paymentMethod.length &&
                  orderDetail.paymentMethod.length > 0 ? (
                    orderDetail.paymentMethod.map((item, index) => (
                      <MenuItem value={item?.id} key={index}>
                        {item?.title}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="no"></MenuItem>
                  )}
                </Select>
              </FormControl>
              {formik.touched.payment_method && formik.errors.payment_method ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.payment_method}
                  </div>
                </div>
              ) : null}
            </Form.Group>
          </div>
          <div>
            <Form.Group className="mb-5">
              <Form.Label>Payment Date</Form.Label>
              <InputGroup className="calendr">
                <DatePicker
                  fullWidth
                  fixedHeight
                  className="form-control w-100"
                  wrapperClassName="datepicker"
                  dateFormat="dd-MM-yyyy"
                  // minDate={new Date()}
                  selected={Date.parse(formik.values?.payment_date)}
                  onChange={(date) =>
                    formik.setFieldValue(
                      "payment_date",
                      moment(date).format("YYYY-MM-DD")
                    )
                  }
                  // popperClassName="date-picker-reports"
                  placeholderText="DD-MM-YYYY"
                />
                <CalendarTodayIcon />
              </InputGroup>
              {formik.touched.payment_date && formik.errors.payment_date ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.payment_date}
                  </div>
                </div>
              ) : null}
            </Form.Group>
          </div>

          <div>
            <Form.Group className="mb-2">
              <Form.Label>Reference Number (optional)</Form.Label>
              <Form.Control
                type="text"
                className={`form-control  ${getInputClasses(
                  "reference_number"
                )}`}
                name="reference_number"
                {...formik.getFieldProps("reference_number")}
              />
            </Form.Group>
          </div>
          <div>
            <Form.Group className="mb-2" style={{ paddingBottom: "120px" }}>
              <Form.Label>Comment (optional)</Form.Label>
              <Form.Control
                type="text"
                className={`form-control  ${getInputClasses("comment")}`}
                name="comment"
                {...formik.getFieldProps("comment")}
              />
            </Form.Group>
          </div>
        </div>

        <div className="offcanvas-footer">
          <div className="offcanvas-content text-right mr-4">
            <button
              type="button"
              className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
              onClick={() => handleClose()}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary font-weight-bolder font-size-sm "
              disabled={loading}
            >
              Create
              {loading && <span className="spinner spinner-white mr-4"></span>}
            </button>
          </div>
        </div>
      </form>
      <DiscardDialog
        setDialogDis={setDialogDis}
        dialogDis={dialogDis}
        formik={formik}
        setCanva={setCanva}
      />
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </>
  );
}
