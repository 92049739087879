import { createSlice } from "@reduxjs/toolkit";

const initialUserState = {
  user: {
    list: {
      listLoading: false,
      actionsLoading: false,
      totalCount: 0,
      entities: [],
      productForEdit: undefined,
      lastError: null,
    },
    detail: {},
  },
  profile: {
    list: {
      listLoading: false,
      actionsLoading: false,
      totalCount: 0,
      entities: null,
      productForEdit: undefined,
      lastError: null,
    },
    drop: [],
    parentDrop: [],
    detail: {},
  },
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const userSlice = createSlice({
  name: "User",
  initialState: initialUserState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.user.list.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.user.list.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getProductById

    // findProducts
    userFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.user.list.listLoading = false;
      state.user.list.error = null;
      state.user.list.entities = entities;
      state.user.list.totalCount = totalCount;
    },
    userDetailFetched: (state, action) => {
      const { data } = action.payload;
      state.user.detail = data;
    },
    profileFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.profile.list.listLoading = false;
      state.profile.list.error = null;
      state.profile.list.entities = entities;
      state.profile.list.totalCount = totalCount;
    },
    profileDropFetched: (state, action) => {
      const { data } = action.payload;
      state.profile.drop = data;
    },
    parentProfileDropFetched: (state, action) => {
      const { data } = action.payload;
      state.profile.parentDrop = data;
    },
    profileDetailFetched: (state, action) => {
      const { data } = action.payload;
      state.profile.detail = data;
    },
  },
});
