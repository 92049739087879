import React from "react";
import { useIntl } from "react-intl";

export default function NoRecord() {
  const intl = useIntl();
  return (
    <div style={{ textAlign: "center" }}>
      <p>{intl.formatMessage({ id: "No.Record.Found" })}</p>
    </div>
  );
}
