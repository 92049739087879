import React from "react";
import { Modal } from "react-bootstrap";
import { useIntl } from "react-intl";

const CancelDialog = ({
  dialog,
  setDialog,
  formik,
  setAddressError,
  setEmailArray,
  setPhoneArray,
  type,
  setCanva,
  setOpenCanva,
}) => {
  const intl = useIntl();

  const handleClose = () => {
    setDialog(false);
  };
  const handleSuccess = () => {
    if (type === "task") {
      setCanva({ type: false, data: {} });
      setDialog(false);
    } else {
      setOpenCanva(false);
      setDialog(false);
      setAddressError({ postcode: "" });
      formik.resetForm();
      setEmailArray(1);
      setPhoneArray(1);
    }
  };

  return (
    <Modal show={dialog} aria-labelledby="example-modal-sizes-title-lg">
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Close Window
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>Do you want to discard changes ?</span>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="reset"
            onClick={() => handleClose()}
            className="btn btn-light btn-elevate"
          >
            {intl.formatMessage({ id: "No" })}
          </button>
          <> </>
          <button
            type="submit"
            onClick={() => handleSuccess()}
            className="btn btn-delete btn-elevate"
          >
            {intl.formatMessage({ id: "Yes" })}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default CancelDialog;
