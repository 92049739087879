/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */

import React, { useEffect, useState } from "react";
import {
  fetchDepotDetail,
  fetchFranchiseDepotList,
  fetchFranchiseUsingDepot,
} from "../../../_redux/depots/deoptsActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import ActiveDialogApi from "../../../../../../_metronic/_partials/widgets/alert/ActiveDialogApiCall";
import { DepotDetailsEdit } from "../../franchises/franchise-canva";
import { LinkDepotToFranchiseCanvas } from "../depot-canvas/LinkDepotToFranchiseCanvas";
import NewBreadCrumbs from "../../../../../../_metronic/layout/components/subheader/components/NewBreadCrumbs";
import { NewDepotCanvas } from "../depot-canvas/NewDepotCanvas";
import SVG from "react-inlinesvg";
import SnackBarTool from "../../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { findFranchiseDepotStatus } from "../../../_redux/depots/depotsCrud";
import { initIds } from "../../../../../../_metronic/_partials/controls/RightPanel";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import { useIntl } from "react-intl";

export function DepotDetails(props) {
  const intl = useIntl();
  const dispatch = useDispatch();

  const init = [
    "kt_new_depot_panel",
    "kt_link_depot_to_franchise_panel",
    "kt_depotdetailsedit_panel1",
  ];

  useEffect(() => {
    initIds(init);
  }, [init]);

  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [dialog, setDialog] = useState({ modal: false });

  const { detailData, franchiseOnDepot, permission } = useSelector(
    (state) => ({
      detailData: state.depot.detail,
      franchiseOnDepot: state.depot.franchiseUsingDepot,
      permission: state.auth.permission,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(fetchDepotDetail(props?.match?.params?.id));
    dispatch(fetchFranchiseUsingDepot(props?.match?.params?.id));
    dispatch(fetchFranchiseDepotList(props?.match?.params?.id));
  }, [dispatch, props.match.params.id]);

  const getDay = (day) => {
    switch (day) {
      case 1:
        return intl.formatMessage({ id: "Monday" });
      case 2:
        return intl.formatMessage({ id: "Tuesday" });
      case 3:
        return intl.formatMessage({ id: "Wednesday" });
      case 4:
        return intl.formatMessage({ id: "Thursday" });
      case 5:
        return intl.formatMessage({ id: "Friday" });
      case 6:
        return intl.formatMessage({ id: "Saturday" });
      case 7:
        return intl.formatMessage({ id: "Sunday" });

      default:
        return "";
    }
  };

  const handleUpdateStatus = () => {
    setSnack({ call: false, type: "", message: "" });
    const data = {
      id: dialog.data.id,
      status: dialog.data.e ? 1 : 0,
    };
    findFranchiseDepotStatus(data)
      .then((res) => {
        dispatch(fetchFranchiseUsingDepot(props?.match?.params?.id));
        setSnack({ call: true, type: "success", message: res.data.message });
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
    setDialog({ modal: false });
  };

  return (
    <>
      {/* NewDepotCanvas component */}
      <NewDepotCanvas
        title={intl.formatMessage({ id: "Edit.Depot.Details" })}
      />
      {/* LinkDepotToFranchiseCanvas component */}
      <LinkDepotToFranchiseCanvas
        title={intl.formatMessage({ id: "Add.Franchise" })}
        depotId={detailData}
      />
      {/* NewBreadCrumbs component */}
      <NewBreadCrumbs title={intl.formatMessage({ id: "Depot.Details" })} />
      <div className="row">
        <div className="col-lg-12 col-xl-12 col-md-12">
          <div className="card card-custom  gutter-b mb-10">
            <div className="card-body">
              <div className="d-flex  w-100 mb-10">
                <div className="mt-4 font-weight-bolder font-size-h5 text-dark-75 text-hover-primary ">
                  <h3 className="card-title align-items-start flex-column d-flex">
                    <span className="font-weight-bolder text-dark">
                      {detailData?.name}
                    </span>
                    <span
                      className={`text-${
                        detailData?.status === 1 ? "success" : "danger"
                      } font-weight-bold font-size-sm mt-3`}
                    >
                      {detailData?.status === 1 ? "Active" : "Inactive"}
                    </span>
                  </h3>
                </div>
                <div className="mt-2">
                  <span className="symbol-label ml-2 pointer">
                    {permission?.setting_depot?.edit ? (
                      <button
                        className="btn btn-link"
                        id="kt_depotdetailsedit_panel1_toggle"
                      >
                        <span className="svg-icon svg-icon-sm svg-icon-primary">
                          <SVG
                            className="h-25 align-self-end"
                            title="Edit"
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Design/Edit.svg"
                            )}
                          ></SVG>
                        </span>
                      </button>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 mb-5">
                  <div>
                    <strong>{intl.formatMessage({ id: "Depot.Short" })}</strong>{" "}
                  </div>
                  <div className="text-muted">{detailData?.short_name}</div>
                </div>
                <div className="col-md-3 mb-5">
                  <div>
                    <strong>{intl.formatMessage({ id: "Depot.ID" })}</strong>{" "}
                  </div>
                  <div className="text-muted">
                    {detailData.depot_reference_id}
                  </div>
                </div>
                <div className="col-md-5 mb-5">
                  <div>
                    <strong>
                      {intl.formatMessage({ id: "Depot.Manager" })}
                    </strong>{" "}
                  </div>
                  <div className="text-muted">{detailData?.manager_name}</div>
                </div>

                <div className="col-md-7 mb-5">
                  <div>
                    <strong> {intl.formatMessage({ id: "Address" })}</strong>{" "}
                  </div>
                  <div className="text-muted">
                    {detailData?.address_line_1}
                    <br></br>
                    {detailData && detailData.address_line_2 ? (
                      <>
                        {detailData.address_line_2}

                        <br></br>
                      </>
                    ) : (
                      ""
                    )}
                    {detailData && detailData.address_line_3 ? (
                      <>
                        {detailData.address_line_3}

                        <br></br>
                      </>
                    ) : (
                      ""
                    )}
                    {detailData?.city}
                    <br></br>
                    {detailData?.postcode}
                  </div>
                </div>
                <div className="col-md-5 mb-5">
                  <div className="mb-5">
                    <div>
                      <strong>{intl.formatMessage({ id: "PHONE" })}</strong>{" "}
                    </div>
                    <div className="text-muted">{detailData?.phone}</div>
                  </div>
                  <div className="mb-5">
                    <div>
                      <strong>{intl.formatMessage({ id: "EMAIL" })}</strong>{" "}
                    </div>
                    <div className="text-muted">
                      {detailData?.manager_email}
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className="mb-5">
                  <strong>{intl.formatMessage({ id: "Opening.Hours" })}</strong>
                </div>
                <div className="row text-muted">
                  <div className="col-md-6">
                    {detailData &&
                      detailData.depot_hours &&
                      detailData.depot_hours.map((data, index) => (
                        <div className="row mb-5  " key={index}>
                          <div className="col">{getDay(data?.day)}</div>
                          <div className="col">
                            {data.open_time === "00:00"
                              ? "Closed"
                              : data.open_time}{" "}
                            -{" "}
                            {data.close_time === "00:00"
                              ? "Closed"
                              : data.close_time}
                          </div>
                        </div>
                      ))}
                  </div>
                  <div className="col-md-6"></div>
                </div>
              </div>
              <div>
                <div className="d-flex justify-content-between align-items-center mb-5 mt-10">
                  <div>
                    <strong>
                      {intl.formatMessage({ id: "Franchise.Using.Depot" })}
                    </strong>
                  </div>

                  <div>
                    <button
                      id="kt_link_depot_to_franchise_panel_toggle"
                      className="btn btn-primary btn-sm font-weight-bold"
                    >
                      {intl.formatMessage({ id: "Add.Franchise" })}
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 text-muted">
                    {franchiseOnDepot &&
                      franchiseOnDepot.map((data, index) => (
                        <div
                          className="row mb-5 d-flex align-items-center "
                          key={index}
                        >
                          <div className="col">{data.franchise_data.name}</div>
                          <div className="col">
                            <div className="d-flex align-items-center">
                              <div> </div>
                              <div>
                                <span className="switch switch-outline switch-icon switch-primary">
                                  <label>
                                    <input
                                      type="checkbox"
                                      checked={
                                        data?.status === 1 ? true : false
                                      }
                                      name="franchise_using_depot"
                                      onChange={(e) =>
                                        setDialog({
                                          status: data.status,
                                          modal: true,
                                          data: {
                                            e: e.target.checked,
                                            id: data.id,
                                          },
                                        })
                                      }
                                    />
                                    <span></span>
                                  </label>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                  <div className="col-md-6"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-lg-12 col-xl-6">
          <Metrics filterBtn="false" noOfChart="false" />
        </div> */}
      </div>
      <ActiveDialogApi
        dialog={dialog}
        setDialog={setDialog}
        handleChange={handleUpdateStatus}
      />
      {snack.call ? <SnackBarTool {...snack} /> : ""}
      <DepotDetailsEdit
        type={"1"}
        data={detailData ? detailData : ""}
        check={"depot"}
      />
    </>
  );
}
