import * as requestFromServer from "./commonCrud";
import { commonSlice, callTypes } from "./commonSlice";

const { actions } = commonSlice;

export const fetchNotification = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .convertContactDeal()
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.notificationFetched({ data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const fetchDashboardFeed = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .dashboardFeed()
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.dashboardFeeds({ data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const getDashboardData = (filter) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.loading }));
  return requestFromServer.getDashboardDataAxios(filter).then((response) => {
    const { data } = response.data;
    dispatch(actions.getDashboardDataSlice({ data }));
  }).catch((error) => {
    error.clientMessage = "Can't find products";
    dispatch(actions.catchError({ error, callType: callTypes.loading }));
  });
};
export const getPodStandingData = (filter) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.loadingPod }));
  return requestFromServer.getDashboardPodStandingDataAxios(filter).then((response) => {
    const { data } = response.data;
    dispatch(actions.getDashboardPodDataSlice({ data }));
  }).catch((error) => {
    error.clientMessage = "Can't find products";
    dispatch(actions.catchError({ error, callType: callTypes.loadingPod }));
  });
};
export const getContainerStandingData = (filter) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.loadingContainer }));
  return requestFromServer.getDashboardContainerStandingDataAxios(filter).then((response) => {
    const { data } = response.data;
    dispatch(actions.getDashboardContainerDataSlice({ data }));
  }).catch((error) => {
    error.clientMessage = "Can't find products";
    dispatch(actions.catchError({ error, callType: callTypes.loadingContainer }));
  });
};
