/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

import * as Yup from "yup";

import { FormControl, MenuItem, Select } from "@material-ui/core/";
import React, { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import CancelDialog from "../tasks-dialog/CancelDialog";
import { ChangeTasksSchema } from "../tasks-form/validationSchema";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import SnackBarTool from "../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { assignOwner } from "../_redux/TasksCrud";
import { canvaCancel } from "../../../utils/canvaCloseFunction";
import { fetchTasks } from "../_redux/TasksAction";
import { getInputClasses } from "../../../utils/commonFunction";
import { initialFilter } from "../TasksUIHelpers";
import { useFormik } from "formik";
import { useIntl } from "react-intl";
import { useTasksUIContext } from "../TasksUIContext";

export function ChangeTasksCanva({ setShow }) {
  // useIntl hook for formatting text/multi language
  const intl = useIntl();
  // useTasksUIContext hook for managing UI state of tasks
  const tasksUIContext = useTasksUIContext();
  // useDispatch hook for dispatching actions
  const dispatch = useDispatch();

  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  // State for managing loading display
  const [loading, setLoading] = useState(false);
  // State for managing confirm dialog display
  const [dialog, setDialog] = useState(false);

  // Select current state of agent from redux store
  const { currentState } = useSelector(
    (state) => ({ currentState: state.contact.agent }),
    shallowEqual
  );

  // Initial values for formik form
  const initialValues = {
    task_owner_id: "",
  };

  // Handle form submission
  const handleSubmit = (values, { resetForm }) => {
    setLoading(true);
    setShow(false);
    setSnack({ call: false, type: "", message: "" });
    // Data to be sent to the server
    const data = {
      task_owner_id: values.task_owner_id,
      task_id: tasksUIContext.ids,
    };
    // Assign owner to tasks
    assignOwner(data)
      .then((res) => {
        setLoading(false);
        setSnack({ call: true, type: "success", message: res.data.message });
        // Fetch updated tasks data
        dispatch(fetchTasks(initialFilter));
        // Reset the tasks UI context
        tasksUIContext.setIds([]);
        canvaCancel("kt_change_tasks_panel_close");
        // Reset the form
        resetForm();
        setShow(true);
      })
      .catch((error) => {
        setLoading(false);
        setShow(true);
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
  };

  // Formik form configuration
  const formik = useFormik({
    initialValues,
    validationSchema: ChangeTasksSchema(intl, Yup),
    onSubmit: handleSubmit,
  });

  // Handle close of the change tasks panel
  const handleClose = () => {
    // If the form is dirty, show the confirm dialog
    if (formik.dirty) {
      setDialog(true);
      canvaCancel("kt_change_tasks_panel_close");
    } else {
      // Otherwise, reset the form
      formik.resetForm();
      canvaCancel("kt_change_tasks_panel_close");
    }
  };

  return (
    <div
      id="kt_change_tasks_panel"
      className="offcanvas offcanvas-right pt-5 pb-10"
    >
      {/*begin::Header*/}
      <div className="offcanvas-header offcanvas-header d-flex align-items-center justify-content-between mb-5">
        <div
          className="offcanvas-close mt-n1 pr-5"
          style={{ position: "absolute", top: "15px", right: "10px" }}
        >
          <span
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            onClick={() => handleClose()}
          >
            <i className="ki ki-close icon-sm text-muted"></i>
          </span>
          <div id="kt_change_tasks_panel_close"></div>
        </div>
      </div>
      {/*end::Header*/}
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <div
          className="offcanvas-content  fade pt-3 pr-5 mr-n5 scroll active show ps--active-y "
          id="kt_quick_panel_logs_kt_change_tasks_panel"
        >
          <div>
            <h5>Change Task Owner</h5>
          </div>
          <div className="row mt-10">
            <div className="col-md-12 mb-5">
              <label>Assign selected to Task(s)</label>
              {/* contact owner selection field */}
              <FormControl fullWidth>
                <Select
                  name="task_owner_id"
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                  className={`form-control  ${getInputClasses(
                    "task_owner_id"
                  )}`}
                  IconComponent={(props) => (
                    <i {...props}>
                      <KeyboardArrowDownIcon />
                    </i>
                  )}
                  inputProps={{ "aria-label": "Without label" }}
                  displayEmpty
                  {...formik.getFieldProps("task_owner_id")}
                >
                  <MenuItem value="">
                    {" "}
                    {intl.formatMessage({ id: "No.owner" })}
                  </MenuItem>
                  <MenuItem value="is_unassigned">
                    {intl.formatMessage({ id: "Unassigned" })}
                  </MenuItem>
                  {currentState &&
                    currentState.map((data, index) => (
                      <MenuItem
                        key={index}
                        value={data.id}
                        disabled={data?.is_available !== 1 ? true : false}
                      >
                        {data && data.first_name ? data.first_name : " "}{" "}
                        {data && data.last_name ? data.last_name : " "}
                      </MenuItem>
                    ))}
                </Select>
                {formik.touched.task_owner_id && formik.errors.task_owner_id ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.task_owner_id}
                    </div>
                  </div>
                ) : null}
              </FormControl>
            </div>
          </div>
        </div>

        <div className="offcanvas-footer">
          <div className="offcanvas-content text-right ">
            <button
              type="button"
              className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
              onClick={() => handleClose()}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary font-weight-bolder font-size-sm "
              disabled={loading}
            >
              Save
              {loading && (
                <span className="ml-2 mr-2 spinner spinner-white"></span>
              )}
            </button>
          </div>
        </div>
      </form>
      {/* snack bar component */}
      {snack.call ? <SnackBarTool {...snack} /> : ""}
      {/* modal for discard confirmation  */}
      <CancelDialog setDialog={setDialog} dialog={dialog} formik={formik} />
    </div>
  );
}
