import { useReducer } from "react";

//productArrayList
// const productArrayList = {
//   id: "",
//   name: "",
//   price: "",
//   total: "",
// };
export const initialValue = {
    defaultProduct: false,
    billingParam: { period: "month", unit: 1 },
    promoCoupon: [],
    product_array: [],
    is_packaging_check:false,
    extraMilesCall:false,
    type_change:false,
    collectionRetrun:{return_date:""}

};

const reducer = (state = initialValue, action) => {
    switch (action.type) {
        case "SET_DATA":
            return { ...state, ...action.data };
        case "RESET_DATA":
            return initialValue;
        default:
            return state;
    }
};

export const useContextState = (initial) => {
    const [state, dispatchReducer] = useReducer(reducer, initial || initialValue);
  
    return { state, dispatchReducer };
};
