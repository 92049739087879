import React from "react";
import { Table } from "react-bootstrap";
import { divideByHundred } from "../../../../utils/commonFunction";
import { divideByHundredReturn } from "../../../../utils/canvaCloseFunction";

export const BreakDownTable = ({ orderUIContext }) => {
  const breakdown = orderUIContext?.state?.breakdown;
  const invoiceEstimate = breakdown?.invoice_estimate;
  const nextInvoiceEstimate = breakdown?.next_invoice_estimate;

  const value = invoiceEstimate?.customer_id
    ? invoiceEstimate
    : nextInvoiceEstimate;
  return (
    <div>
      {" "}
      <div className="py-4">
        {" "}
        <Table responsive className="summerytable customColor">
          <thead>
            <tr>
              <th style={{ width: "35%" }}>Items</th>
              <th>Unit Price</th>
              <th className="">Discount</th>
              <th className=" ">Tax</th>
              <th className=" ">Amount</th>
            </tr>
          </thead>
          <tbody>
            {value?.line_items?.map((item, index) => (
              <tr key={index}>
                <td>{item?.description}</td>
                <td className="d-flex">
                  £{divideByHundred(item?.unit_amount)}
                </td>
                <td>£{divideByHundred(item?.discount_amount)}</td>
                <td>£{divideByHundred(item?.tax_amount)}</td>
                <td>£{divideByHundred(item?.amount)}</td>
              </tr>
            ))}
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td className="text-right">Sub Total</td>
              <td>£{divideByHundred(value?.sub_total)}</td>
            </tr>
            {value?.discounts?.map((item, index) => (
              <tr key={index}>
                <td></td>
                <td></td>
                <td>{item?.description}</td>
                <td className="text-right">
                  {item?.discount_percentage
                    ? item?.discount_percentage + "%"
                    : item?.discount_amount}
                </td>
                <td>£{divideByHundred(item?.amount)}</td>
              </tr>
            ))}
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td className="text-right">Total</td>
              <td>
                £{divideByHundred(value?.total)}
                {/* {addTwoValue(
                  value?.total ? divideByHundred(value?.total) : 0,
                  value?.amount_due ? divideByHundred(value?.amount_due) : 0
                )} */}
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td className="text-right">Credits</td>
              <td>
                -£
                {divideByHundredReturn(
                  breakdown?.credit_note_estimates &&
                    breakdown?.credit_note_estimates.length > 0
                    ? Number(
                        breakdown?.credit_note_estimates[0]?.amount_allocated
                      )
                    : 0
                )}
                {/* {addTwoValue(
                  value?.total ? divideByHundred(value?.total) : 0,
                  value?.amount_due ? divideByHundred(value?.amount_due) : 0
                )} */}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  );
};
