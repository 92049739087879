import React, { useMemo } from "react";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { useCountryUIContext } from "../CountryUIContext";
import { useIntl } from "react-intl";
const prepareFilter = (queryParams, values) => {
  const { searchText } = values;
  const newQueryParams = { ...queryParams };
  const filter = {};
  filter.search = searchText ? searchText : "";
  newQueryParams.filter = filter;
  return newQueryParams;
};
export function CountryFilter() {
  const intl = useIntl();
  const countryUIContext = useCountryUIContext();
  const countryUIProps = useMemo(() => {
    return {
      setQueryParams: countryUIContext.setQueryParams,
      queryParams: countryUIContext.queryParams,
    };
  }, [countryUIContext]);
  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(countryUIProps.queryParams, values);

    if (!isEqual(newQueryParams, countryUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      countryUIProps.setQueryParams(newQueryParams);
    }
  };
  return (
    <>
      <Formik
        initialValues={{
          status: "", // values => All=""/Susspended=0/Active=1/Pending=2
          type: "", // values => All=""/Business=0/Individual=1
          searchText: "",
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,

          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row d-flex justify-content-between">
              <div className="col-md-8 col-lg-8">
                <div className="row"></div>
              </div>
              <div className="col-md-2 col-lg-2">
                <div style={{ position: "relative" }}>
                  <input
                    style={{ paddingRight: "40px" }}
                    type="text"
                    className="form-control"
                    name="searchText"
                    placeholder={intl.formatMessage({ id: "Search" })}
                    onBlur={handleBlur}
                    value={values.searchText}
                    onChange={(e) => {
                      setFieldValue("searchText", e.target.value);
                      handleSubmit();
                    }}
                  />
                  <i
                    style={{
                      position: "absolute",
                      top: "50%",
                      transform: "translateY(-50%)",
                      right: "15px",
                    }}
                    className="fa fa-search"
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
