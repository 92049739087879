export const ProductStatusCssClasses = ["success", "info", ""];
export const ProductStatusTitles = ["Selling", "Sold"];
export const ProductConditionCssClasses = ["success", "danger", ""];
export const ProductConditionTitles = ["New", "Used"];
export const defaultSorted = [{ dataField: "id", order: "asc" }];
export const sizePerPageList = [
  { text: "20", value: 20 },
  { text: "50", value: 50 },
  { text: "100", value: 100 },
];
export const initialFilter = {
  filter: {
    search: "",
    search_view: "",
    search_agent: "",
    search_franchise: "",
  },
  sortOrder: "", // asc||desc
  sortField: "",
  pageNumber: 1,
  pageSize: 20,
};
export const buttons = [
  {
    name: "Create Customer",
    id: "kt_assign_panel_toggle",
    className: "btn btn-primary ",
  },
];
