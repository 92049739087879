/* eslint-disable jsx-a11y/anchor-is-valid */

import { FormControl, MenuItem, Select } from "@material-ui/core/";
import React, { useEffect, useState } from "react";
import {
  fetchDealDetail,
  fetchDealfeedList,
} from "../_redux/deals/dealsAction";
import { getFranchise, getFranchiseOwner } from "../_redux/contact/contactCrud";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import { CreateContactOffCanvas } from "../canvas/CreateContactOffCanvas";
import DatePicker from "react-datepicker";
import { InputGroup } from "react-bootstrap";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import SVG from "react-inlinesvg";
import SnackBarTool from "../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { Spinner } from "react-bootstrap";
import moment from "moment";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { updateDealCard } from "../_redux/deals/dealsCrud";
import { useCallback } from "react";
import { useFormik } from "formik";
import { useIntl } from "react-intl";
import { getInputClasses } from "../../../utils/commonFunction";

export function DealsUsers({ dealDetail, setCanva }) {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [list, setList] = useState({ franchiseList: [] });
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [openCanva, setOpenCanva] = useState(false);

  const { ownerAgent, admin, permission } = useSelector(
    (state) => ({
      ownerAgent: state.contact.agent,
      admin: state.auth.user,
      permission: state.auth.permission,
    }),
    shallowEqual
  );

  const initialValues = {
    franchise_id:
      dealDetail && dealDetail.franchise_id ? dealDetail.franchise_id : "",
    contact_owner_id:
      dealDetail && dealDetail.contact_owner_id
        ? dealDetail.contact_owner_id
        : "",
    deal_status:
      dealDetail && dealDetail.deal_status ? dealDetail.deal_status : "",
    closed_at: dealDetail && dealDetail.closed_at ? dealDetail.closed_at : "",
    amount: dealDetail && dealDetail.amount ? dealDetail.amount : "",
  };

  const handleSubmit = (values, { resetForm }) => {
    setSnack({ call: false, type: "", message: "" });
    updateDealCard(dealDetail.id, values)
      .then((res) => {
        setSnack({ call: true, type: "success", message: res.data.message });

        dispatch(fetchDealDetail(dealDetail.id));
        dispatch(fetchDealfeedList(dealDetail.id));
        resetForm();
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  const handleFranchise = (postcode) => {
    getFranchise(postcode)
      .then((res) => {
        // if (!res.data.data.length < 1) {
        setList((prev) => ({ ...prev, franchiseList: res.data.data }));
        // }
      })
      .catch((error) => {});
  };
  useEffect(() => {
    if (dealDetail?.user_data?.postcode) {
      handleFranchise(dealDetail.user_data.postcode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealDetail, dealDetail?.user_data?.postcode]);

  const handleOwner = useCallback(() => {
    getFranchiseOwner(formik.values.franchise_id)
      .then((res) => {
        setList((prev) => ({ ...prev, ownerList: res.data.data }));
      })
      .catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.franchise_id]);

  useEffect(() => {
    if (formik?.values?.franchise_id) {
      handleOwner();
    }
  }, [formik.values.franchise_id, handleOwner]);

  return (
    <>
      {dealDetail && (
        <div className="flex-row-auto profilepannel ">
          <div className="card card-custom card-stretch">
            {/* begin::Body */}

            {/* begin::User */}
            <div className="card-body pt-4 detailCard">
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <a
                    href="#"
                    className="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary"
                  >
                    {dealDetail?.user_data?.first_name}{" "}
                    {dealDetail?.user_data?.last_name}
                  </a>
                </div>
                <span className="symbol-label ml-2">
                  {permission?.deal?.edit ? (
                    <span>
                      <span
                        className="svg-icon svg-icon-sm svg-icon-primary"
                        style={{ cursor: "pointer" }}
                        onClick={() => setOpenCanva(true)}
                      >
                        <SVG
                          title=" "
                          className="h-25 align-self-end"
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Design/Edit.svg"
                          )}
                        ></SVG>
                      </span>
                    </span>
                  ) : (
                    ""
                  )}
                </span>
              </div>
              {/* end::User */}
              {/* begin::Contact */}
              {dealDetail.loading ? (
                <div className="mt-8" style={{ textAlign: "center" }}>
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : (
                <>
                  <div className="py-9">
                    <div className="d-flex align-items-center mb-4">
                      <span className="navi-icon mr-2">
                        <span className="svg-icon svg-icon-lg ">
                          <SVG
                            title=" "
                            className="h-75 align-self-end"
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Active-call.svg"
                            )}
                          ></SVG>
                        </span>
                      </span>
                      <a
                        className="text-hover-primary"
                        style={{ color: "black" }}
                        href={`tel:${dealDetail?.user_data?.phone}`}
                      >
                        {dealDetail?.user_data?.phone}
                      </a>
                    </div>
                    <div className="d-flex align-items-center  mb-4">
                      <span className="navi-icon mr-2">
                        <span className="svg-icon">
                          <SVG
                            title=" "
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Mail.svg"
                            )}
                          ></SVG>
                        </span>
                      </span>
                      <a
                        className="text-hover-primary"
                        style={{ color: "black" }}
                        href={`mailto:${dealDetail?.user_data?.email}`}
                      >
                        {dealDetail?.user_data?.email}
                      </a>
                    </div>

                    <div className="d-flex align-items-unset mb-4 ">
                      <span className="navi-icon mr-2">
                        <span className="svg-icon">
                          <SVG
                            title=" "
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Map/Marker1.svg"
                            )}
                          ></SVG>{" "}
                        </span>
                      </span>
                      <span className=" ">
                        {dealDetail &&
                        dealDetail.user_data &&
                        dealDetail.user_data.address ? (
                          <>
                            {" "}
                            {dealDetail.user_data.address
                              .replace(/,,,/g, ",")
                              .replace(/,/g, ", ")
                              ?.replaceAll(",", ", ")}
                            <br />
                          </>
                        ) : (
                          ""
                        )}

                        {dealDetail &&
                        dealDetail.user_data &&
                        dealDetail.user_data.city ? (
                          <>
                            {" "}
                            {dealDetail.user_data.city} <br />{" "}
                          </>
                        ) : (
                          ""
                        )}

                        {dealDetail &&
                        dealDetail.user_data &&
                        dealDetail.user_data.postcode
                          ? dealDetail.user_data.postcode
                          : ""}
                      </span>
                    </div>
                  </div>
                  {/* end::Contact */}
                  {/* begin::Nav */}
                  <div className="row mb-4">
                    <div className="col-lg-12 col-xl-5   ">
                      <span className="font-weight-bold mr-2">
                        {intl.formatMessage({ id: "Source" })}
                      </span>
                    </div>
                    <div className="col-lg-12 col-xl-7 ">
                      <span className=" ">
                        {dealDetail && dealDetail.source
                          ? dealDetail.source
                          : "-"}
                      </span>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-lg-12 col-xl-5   ">
                      <span className="font-weight-bold mr-2">
                        {intl.formatMessage({ id: "Created.By" })}
                      </span>
                    </div>
                    <div className="col-lg-12 col-xl-7 ">
                      <span className=" ">
                        {dealDetail.created_by_data &&
                        dealDetail.created_by_data &&
                        dealDetail.created_by_data.first_name
                          ? dealDetail.created_by_data.first_name +
                            " " +
                            dealDetail.created_by_data.last_name
                          : "-"}
                      </span>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-lg-12 col-xl-5  ">
                      <span className="font-weight-bold mr-2">
                        {intl.formatMessage({ id: "Create.Date" })}
                      </span>
                    </div>
                    <div className="col-lg-12 col-xl-7">
                      <span className=" ">
                        {moment(dealDetail?.created_at).format("DD-MM-YYYY")}
                      </span>
                    </div>
                  </div>
                  <form
                    onSubmit={formik.handleSubmit}
                    onReset={formik.handleReset}
                  >
                    <div className="row mb-4 d-flex align-items-center">
                      <div className="col-lg-12 col-xl-5  ">
                        <span className="font-weight-bold mr-2">
                          {intl.formatMessage({ id: "Franchise" })}
                        </span>
                      </div>
                      <div className="col-lg-12 col-xl-7">
                        <FormControl fullWidth>
                          <Select
                            name="franchise_id"
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                            }}
                            className={`form-control  ${getInputClasses(
                              formik,
                              "franchise_id"
                            )}`}
                            IconComponent={(props) => (
                              <i {...props}>
                                <KeyboardArrowDownIcon />
                              </i>
                            )}
                            inputProps={{ "aria-label": "Without label" }}
                            displayEmpty
                            {...formik.getFieldProps("franchise_id")}
                          >
                            <MenuItem value="">All franchises</MenuItem>

                            {list &&
                              list.franchiseList &&
                              list.franchiseList.all_franchise &&
                              list.franchiseList.all_franchise.map(
                                (data, index) => (
                                  <MenuItem key={index} value={data.id}>
                                    {data.name}
                                  </MenuItem>
                                )
                              )}
                          </Select>
                        </FormControl>
                        {formik.touched.franchise_id &&
                        formik.errors.franchise_id ? (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              {formik.errors.franchise_id}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row mb-4 d-flex align-items-center">
                      <div className="col-lg-12 col-xl-5  ">
                        <span className="font-weight-bold mr-2">
                          {intl.formatMessage({ id: "Deal" })}{" "}
                          {intl.formatMessage({ id: "Owner" })}
                        </span>
                      </div>
                      <div className="col-lg-12 col-xl-7">
                        <FormControl fullWidth>
                          <Select
                            name="contact_owner_id"
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                            }}
                            className={`form-control  ${getInputClasses(
                              formik,
                              "contact_owner_id"
                            )}`}
                            IconComponent={(props) => (
                              <i {...props}>
                                <KeyboardArrowDownIcon />
                              </i>
                            )}
                            inputProps={{ "aria-label": "Without label" }}
                            displayEmpty
                            {...formik.getFieldProps("contact_owner_id")}
                          >
                            <MenuItem value="">
                              {intl.formatMessage({ id: "Contact" })}{" "}
                              {intl.formatMessage({ id: "Owner" })}
                            </MenuItem>
                            <MenuItem value={admin.id}>
                              {admin.first_name +
                                " " +
                                (admin.last_name ? admin.last_name : "")}
                            </MenuItem>
                            {ownerAgent &&
                              ownerAgent.map((data, index) => (
                                <MenuItem
                                  key={index}
                                  value={data.id}
                                  disabled={
                                    data?.is_available !== 1 ? true : false
                                  }
                                >
                                  {data.first_name +
                                    " " +
                                    (data.last_name ? data.last_name : "")}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                        {formik.touched.contact_owner_id &&
                        formik.errors.contact_owner_id ? (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              {formik.errors.contact_owner_id}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row mb-4 d-flex align-items-center">
                      <div className="col-lg-12 col-xl-5  ">
                        <span className="font-weight-bold mr-2">
                          {intl.formatMessage({ id: "Deal" })}{" "}
                          {intl.formatMessage({ id: "Stage" })}
                        </span>
                      </div>
                      <div className="col-lg-12 col-xl-7">
                        <FormControl fullWidth>
                          <Select
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                            }}
                            className={`form-control  ${getInputClasses(
                              formik,
                              "deal_status"
                            )}`}
                            name="deal_status"
                            IconComponent={(props) => (
                              <i {...props}>
                                <KeyboardArrowDownIcon />
                              </i>
                            )}
                            inputProps={{ "aria-label": "Without label" }}
                            displayEmpty
                            {...formik.getFieldProps("deal_status")}
                          >
                            <MenuItem value="Open">
                              {intl.formatMessage({ id: "Open" })}
                            </MenuItem>
                            <MenuItem value={"Transit"}>
                              {intl.formatMessage({ id: "Transit" })}
                            </MenuItem>
                            <MenuItem value={"Pipeline"}>
                              {intl.formatMessage({ id: "Pipeline" })}
                            </MenuItem>
                            <MenuItem value={"Close-Won"}>
                              {intl.formatMessage({ id: "Close-Won" })}
                            </MenuItem>
                            <MenuItem value={"Close-Lost"}>
                              {intl.formatMessage({ id: "Close-Lost" })}
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>

                    <div className="row mb-4 d-flex align-items-center">
                      <div className="col-lg-12 col-xl-5  ">
                        <span className="font-weight-bold mr-2">
                          {intl.formatMessage({ id: "Close.Date" })}
                        </span>
                      </div>
                      <div className="col-lg-12 col-xl-7 date-picker">
                        <span className="text-muted">
                          <InputGroup className="calendr">
                            <DatePicker
                              fullWidth
                              fixedHeight
                              className="form-control"
                              wrapperClassName="datepicker"
                              dateFormat="dd-MM-Y"
                              placeholderText="DD-MM-YYYY"
                              minDate={new Date()}
                              selected={Date.parse(formik.values?.closed_at)}
                              onChange={(date) =>
                                formik.setFieldValue(
                                  "closed_at",
                                  moment(date).format("YYYY-MM-DD")
                                )
                              }
                              // popperClassName="date-picker-reports"
                            />
                            <CalendarTodayIcon />
                          </InputGroup>
                        </span>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12 col-xl-5  ">
                        <span className="font-weight-bold mr-2">
                          {intl.formatMessage({ id: "Amount" })}( £ )
                        </span>
                      </div>
                      <div className="col-lg-12 col-xl-7">
                        <span className=" ">
                          <input
                            className="form-control"
                            type="text"
                            {...formik.getFieldProps("amount")}
                          />
                        </span>
                      </div>
                    </div>
                    {permission?.contact?.edit ? (
                      <div style={{ textAlign: "right" }}>
                        <button
                          disabled={!formik.dirty}
                          type="submit"
                          className="btn btn-primary font-weight-bolder font-size-sm  mt-4"
                        >
                          {intl.formatMessage({ id: "SAVE" })}
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </form>
                </>
              )}
            </div>
            {/* end::Body */}
          </div>
          {openCanva ? (
            <>
              <CreateContactOffCanvas
                title={intl.formatMessage({ id: "Deals.Details" })}
                type={"edit"}
                check="Deal"
                contactDetail={dealDetail}
                setOpenCanva={setOpenCanva}
              />
              <div
                className="offcanvas-overlay"
                onClick={() => setOpenCanva(false)}
              ></div>
            </>
          ) : (
            ""
          )}
        </div>
      )}

      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </>
  );
}
