export const canvaCancel = (id) => {
  document.getElementById(id).click();
};

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function divideByHundred(value, type) {
  if (!value) return "N/A";
  const data = Number(value) / 100;
  let twoDigit = Number(data).toFixed(2);
  return twoDigit || "N/A";
}
export function divideByHundredReturn(value, type) {
  if (!value) return 0;
  const data = Number(value) / 100;
  let twoDigit = Number(data).toFixed(2);
  return twoDigit || 0;
}

export function truncateString(str, num) {
  if (str?.length > num) {
    return str.slice(0, num) + "...";
  } else {
    return str;
  }
}
