import { MenuItem, Select } from '@material-ui/core';
import React from 'react'
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";


export const MuiSelect = ({handleChange, item, index}) => {
  const itemsAdd = Array.from({ length: 51 }, (_, index) => index * 1000);

  return (
    <div style={{marginTop:"10px"}}>
    <Select
    name="address"
    MenuProps={{
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "left",
      },
      getContentAnchorEl: null,
    }}
    className={`form-control marginCustom11  width116 `}
    IconComponent={(props) => (
      <i {...props}>
        <KeyboardArrowDownIcon />
      </i>
    )}
    inputProps={{ "aria-label": "Without label" }}
    displayEmpty
    value={process.env.REACT_APP_PRODUCT_POD_PROTECT == item.stripe_product_id ?  item.quantity*1000:  item.quantity}

    onChange={(e) => {
      handleChange(e.target.value/1000, index, item)

      
    }}
  >
   
    {itemsAdd.map((item) => {
      if (item === 0) return;
      return (
        <MenuItem key={item} value={item}>
          &pound;
          {item.toLocaleString("en-IN")}
        </MenuItem>
      );
    })}
  </Select>
    </div>
  )
}
