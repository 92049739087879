import React from "react";
import { useIntl } from "react-intl";

const CheckboxTableCheck = ({ permissArray }) => {
  const intl = useIntl();
  return (
    <>
      <th scope="row">
        <div>
          <label className="checkbox">
            <input
              type="checkbox"
              name="Checkboxes4"
              disabled
              checked={permissArray && permissArray.create ? true : false}
            />
            <span className="mr-2"></span>
            {intl.formatMessage({ id: "Create" })}
          </label>
        </div>
      </th>
      <th scope="row">
        <div>
          <label className="checkbox">
            <input
              type="checkbox"
              name="Checkboxes4"
              checked={permissArray && permissArray.view ? true : false}
              disabled
            />
            <span className="mr-2"></span>
            {intl.formatMessage({ id: "View" })}
          </label>
        </div>
      </th>
      <th scope="row">
        <div>
          <label className="checkbox">
            <input
              type="checkbox"
              name="Checkboxes4"
              checked={permissArray && permissArray.edit ? true : false}
              disabled
            />
            <span className="mr-2"></span>
            {intl.formatMessage({ id: "Edit" })}
          </label>
        </div>
      </th>
      <th scope="row">
        <div>
          <label className="checkbox">
            <input
              type="checkbox"
              name="Checkboxes4"
              checked={permissArray && permissArray.delete ? true : false}
              disabled
            />
            <span className="mr-2"></span>
            {intl.formatMessage({ id: "Delete" })}
          </label>
        </div>
      </th>
    </>
  );
};
export default CheckboxTableCheck;
