import React, { useState } from "react";
import { Card, CardBody } from "../../../../../_metronic/_partials/controls";

import DeleteDialog from "../../../../../_metronic/_partials/widgets/alert/DeleteDialog";
import { DepotsFilter } from "./depot-filter/DepotsFilter";
import { DepotsTable } from "./depot-table/DepotsTable";
import NewBreadCrumbs from "../../../../../_metronic/layout/components/subheader/components/NewBreadCrumbs";
import NotAuthorized from "../../../../../_metronic/_partials/widgets/NotAuthorized";
import SnackBarTool from "../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { deleteDepots } from "../../_redux/depots/depotsCrud";
import { fetchProducts } from "../../_redux/depots/deoptsActions";
import { initialFilter } from "./DepotsUIHelpers";
import { useDepotUIContext } from "./DepotsUIContext";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";

export function DepotsCard({ permission }) {
  const intl = useIntl();
  const DepotUIContext = useDepotUIContext();
  const dispatch = useDispatch();

  const [dialog, setDialog] = useState(false);
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });

  const handleDelete = () => {
    setSnack({ call: false, type: "", message: "" });
    deleteDepots({ depot_id: DepotUIContext.ids })
      .then((res) => {
        setSnack({ call: true, type: "success", message: res.data.message });
        dispatch(fetchProducts(initialFilter));
        setDialog(false);
        DepotUIContext.setIds([]);
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
        setDialog(false);
      });
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {/* redering NewBreadCrumbs components */}
        <NewBreadCrumbs title={intl.formatMessage({ id: "DEPOTS" })} />
        <div>
          {permission?.setting_depot?.delete ? (
            <button
              className="btn btn-primary mr-2"
              type="button"
              onClick={() => setDialog(true)}
              disabled={DepotUIContext.ids.length === 0}
            >
              Delete
            </button>
          ) : (
            ""
          )}
          {permission?.setting_depot?.create ? (
            <button
              id="kt_new_depot_panel_toggle"
              className="btn btn-primary mr-2"
              type="button"
            >
              {intl.formatMessage({ id: "New.Depot" })}
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
      {/* card that contains depot filter and depot table */}
      <Card>
        <CardBody>
          {permission?.setting_depot?.view ? (
            <>
              <DepotsFilter />

              <DepotsTable />
            </>
          ) : (
            <NotAuthorized />
          )}
        </CardBody>
      </Card>

      {/* rendering delete modal */}
      <DeleteDialog
        dialog={dialog}
        setDialog={setDialog}
        handleDelete={handleDelete}
        title={"Depot"}
      />
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </>
  );
}
