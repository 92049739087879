/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

import React, { useState } from "react";

import SnackBarTool from "../../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { capitalizeFirstLetter } from "../../../../../utils/canvaCloseFunction";
import { editWorkingDays } from "../../../_redux/franchises/franchisesCrud";
import { useFormik } from "formik";
import { useIntl } from "react-intl";
import DiscardDialog from "../../../../../../_metronic/_partials/widgets/alert/DiscardDialog";
import { fetchWorkingDays } from "../../../_redux/franchises/franchisesActions";
import { useDispatch } from "react-redux";

export function WorkingDaysCanva({ workingDays, setCanva }) {
  const intl = useIntl();
  const dispatch = useDispatch();

  // Initial values for the form
  const initialValues = {
    franchise_id: workingDays?.franchise_id || "",
    monday: workingDays?.days?.monday || "0",
    tuesday: workingDays?.days?.tuesday || "0",
    wednesday: workingDays?.days?.wednesday || "0",
    thursday: workingDays?.days?.thursday || "0",
    friday: workingDays?.days?.friday || "0",
    saturday: workingDays?.days?.saturday || "0",
    sunday: workingDays?.days?.sunday || "0",
  };

  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [dialog, setDialog] = useState(false);

  // Event handler for form submission
  const handleSubmit = (values, actions) => {
    setSnack({ call: false, type: "", message: "" });
    editWorkingDays(values)
      .then((res) => {
        setSnack({
          call: true,
          type: "success",
          message: res.data.message,
        });
        dispatch(fetchWorkingDays(values?.franchise_id));
        setTimeout(() => {
          setCanva(false);
        }, 2000);
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
  };

  // Use the useFormik hook to handle form logic
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    // validationSchema: InsuranceSchema(intl, Yup),
    onSubmit: handleSubmit,
    // onReset: handleReset,
  });

  const handleChecked = (e, keys) => {
    if (e.target.checked) {
      formik.setFieldValue(keys, 1);
    } else {
      formik.setFieldValue(keys, "0");
    }
  };
  const handleClose = () => {
    if (formik.dirty || Object.keys(formik.touched)?.length !== 0) {
      setDialog(true);
    } else {
      formik.resetForm();
      setCanva(false);
    }
  };
  return (
    <div
      id="kt_workingDays_panel"
      className="offcanvas offcanvas-right pt-5 pb-10 offcanvas-on"
    >
      {/*begin::Header*/}
      <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
        <div
          className="offcanvas-close mt-n1 pr-5"
          style={{ position: "absolute", top: "15px", right: "10px" }}
        >
          <span
            onClick={() => handleClose()}
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            id="kt_workingDays_panel_close"
          >
            <i className="ki ki-close icon-sm text-muted"></i>
          </span>
        </div>
      </div>
      {/*end::Header*/}
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <div className="offcanvas-content px-10 ">
          <div>
            <h5>{intl.formatMessage({ id: "Working.Days" })}</h5>
          </div>
          <div
            id="kt_quick_panel_logs_kt_workingDays_panel"
            className="fade pt-3 pr-5 mr-n5 scroll active show ps--active-y mt-2"
          >
            <div className="mt-2 pb-10">
              {Object.entries(workingDays?.days || {}).map(
                ([keys, value], index) => {
                  const day = capitalizeFirstLetter(keys);
                  return (
                    <div className="mt-2" key={index}>
                      <label className="checkbox">
                        <input
                          type="checkbox"
                          name="enabled_for_checkout"
                          checked={formik?.values[keys] === 1 ? true : false}
                          onChange={(e) => handleChecked(e, keys)}
                        />
                        <span className="mr-2"></span>
                        {intl.formatMessage({ id: day })}
                      </label>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </div>
        <div className="offcanvas-footer">
          <div className="offcanvas-content text-right ">
            <button
              type="button"
              className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
              onClick={() => handleClose()}
            >
              {intl.formatMessage({ id: "CANCEL" })}
            </button>
            <button
              type="submit"
              className="btn btn-primary font-weight-bolder font-size-sm mr-3 "
            >
              {intl.formatMessage({ id: "SAVE" })}
            </button>
          </div>
        </div>
      </form>
      <DiscardDialog
        setDialogDis={setDialog}
        dialogDis={dialog}
        formik={formik}
        setActive={setCanva}
      />
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </div>
  );
}
