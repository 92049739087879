import axios from "axios";
// Endpoints for the customer API
export const CUSTOMER_URL = "customer";
export const CUSTOMER_DETAIL = "customer/get-customer-orders/";
export const TIMELINE = "/get-customer-timeline";
export const DELETE_CUSTOMER = "/delete-customer";

// Finds a customer on the server using the given query parameters
export function findCustomer(queryParams) {
  return axios.post(`${CUSTOMER_URL}/find`, { queryParams });
}
// Finds a list of customers on the server using the given query parameters
export function findCustomerList(queryParams) {
  // Construct the URL with the query parameters
  const url =
    process.env.REACT_APP_SITE_URL +
    CUSTOMER_URL +
    `?page=${queryParams.pageNumber}&per_page=${queryParams.pageSize}&sort_by=${queryParams.sortField}&sort_dir=${queryParams.sortOrder}&search_keyword=${queryParams.filter.search}&search_view=${queryParams.filter.search_view}&search_agent=${queryParams.filter.search_agent}&search_franchise=${queryParams.filter.search_franchise}`;

  return axios.get(url);
}
// Finds a list of orders for a customer with the given ID
export function findCustomerOrderList(queryParams) {
  return axios.get(
    process.env.REACT_APP_SITE_URL + CUSTOMER_DETAIL + `${queryParams}`
  );
}
// Finds the timeline for a customer with the given ID and contact ID
export function findCustomerTimeLine(queryParams, id) {
  return axios.get(
    process.env.REACT_APP_SITE_URL +
      CUSTOMER_URL +
      TIMELINE +
      `?customer_id=${queryParams}&contact_id=${id}`
  );
}
// Deletes a customer on the server with the given data
export function deleteCustomer(data) {
  return axios.post(
    process.env.REACT_APP_SITE_URL + CUSTOMER_URL + DELETE_CUSTOMER,
    data
  );
}
