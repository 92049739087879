/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */

import NoRecord from "../../../../_metronic/_partials/widgets/NoRecord";
import { OrderCardDetailEdit } from "../order-canva/OrderCardDetailEdit";
import React from "react";
import SVG from "react-inlinesvg";
import { capitalizeFirstLetter } from "../../../utils/canvaCloseFunction";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import { useState } from "react";
import { Switch, CircularProgress } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { fetchCardDetail } from "../_redux/order/orderAction";

export function OrderCardWidget({
  cardDetailArray,
  orderDetail,
  cardDetailCheck,
}) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [number, setNumber] = useState("");
  const [cardCanva, setCardCanva] = useState(false);
  const [toggleCheck, setToggleCheck] = React.useState({
    checkedB: true,
  });

  useEffect(() => {
    const primaryCardIndex = cardDetailArray.findIndex(
      (e) => e.is_primary_card === "1"
    );
    const customerPrimaryCardIndex = cardDetailArray.findIndex(
      (e) => e.is_customer_primary_card === "1"
    );

    if (primaryCardIndex !== -1) {
      setNumber(primaryCardIndex);
    } else if (customerPrimaryCardIndex !== -1) {
      setNumber(customerPrimaryCardIndex);
    }
  }, [cardDetailArray]);
  const handleChange = (event) => {
    setToggleCheck({
      ...toggleCheck,
      [event.target.name]: event.target.checked,
    });
  };
  useEffect(() => {
    if (orderDetail?.customer_chargebee_id && toggleCheck?.checkedB) {
      dispatch(
        fetchCardDetail(
          orderDetail?.franchise_id,
          orderDetail?.customer_chargebee_id,
          orderDetail?.subscription_id ? orderDetail?.subscription_id : "",
          orderDetail?.id,
        )
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDetail?.customer_chargebee_id, toggleCheck?.checkedB]);

  console.log("CRADASKfgusiztgfuszvcjkszgsa", cardDetailArray[number]);

  return (
    <>
      <div
        className={`card card-custom card-stretch gutter-b ${!toggleCheck.checkedB &&
          "smallCard"} `}
      >
        {/* Header */}
        {/* Body */}
        <div className="card-header align-items-center border-0 mt-4 mb-0">
          <div className="d-flex justify-content-between w-100">
            <h3 className="card-title align-items-start flex-column">
              <span className="font-weight-bolder text-dark">
                {intl.formatMessage({ id: "Card.Details" })}{" "}
                {cardDetailArray.length > 1 ? "(Primary)" : ""}
                <Switch
                  checked={toggleCheck.checkedB}
                  onChange={handleChange}
                  color="primary"
                  name="checkedB"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </span>
            </h3>
            {toggleCheck.checkedB && (
              <span
                className="symbol-label ml-2 pointer"
                id="kt_OrderCardDetailEdit_panel_toggle"
              >
                <span
                  className="svg-icon svg-icon-sm svg-icon-primary"
                  onClick={() => setCardCanva(true)}
                >
                  <SVG
                    title=" "
                    className="h-25 align-self-end"
                    src={toAbsoluteUrl("/media/svg/icons/Design/Edit.svg")}
                  ></SVG>
                </span>
              </span>
            )}
          </div>
        </div>
        {cardDetailCheck?.loading && (
          <div className="text-center mt-8">
            <CircularProgress />
          </div>
        )}
        {toggleCheck.checkedB && !cardDetailCheck?.loading && (
          <div className="card-body pt-0 ">
            {/* begin::Contact */}

            {cardDetailArray.length > 0 ? (
              <div className=" mt-5">
                <div className="d-flex mb-4">
                  <span className="svg-icon svg-icon-lg mr-1 ">
                    <SVG
                      className="h-75 align-self-end"
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Shopping/Credit-card.svg"
                      )}
                    ></SVG>
                  </span>
                  <span className=" ">
                    <div className="mb-3">
                      {console.log("cardDetailArray", cardDetailArray[number])}
                      {cardDetailArray[number]?.brand
                        ? capitalizeFirstLetter(cardDetailArray[number]?.brand)
                        : "-"}{" "}
                      {intl.formatMessage({ id: "ending.in" })}{" "}
                      {cardDetailArray[number]?.last4 || "-"}
                    </div>
                    <div>
                      {intl.formatMessage({ id: "Expires" })}:{" "}
                      {cardDetailArray[number]?.exp_month || "-"}/
                      {cardDetailArray[number]?.exp_year || "-"}{" "}
                    </div>
                  </span>
                </div>

                <div className="d-flex align-items-center mb-4 ">
                  <span className="navi-icon mr-2">
                    <span className="svg-icon">
                      <SVG
                        src={toAbsoluteUrl("/media/svg/icons/Map/Marker1.svg")}
                      ></SVG>{" "}
                    </span>
                  </span>
                  <span className=" ">
                    {cardDetailArray[number]?.billing_details?.address?.line1 ||
                      "-"}
                    ,
                    {cardDetailArray[number]?.billing_details?.address?.city ||
                      "-"}
                  </span>
                </div>
              </div>
            ) : (
              <NoRecord />
            )}
          </div>
        )}
      </div>
      {cardCanva ? (
        <>
          <OrderCardDetailEdit
            cardDetailArray={cardDetailArray}
            orderDetail={orderDetail}
            setCardCanva={setCardCanva}
          />
          <div
            className="offcanvas-overlay"
            onClick={() => setCardCanva(false)}
          ></div>
        </>
      ) : (
        ""
      )}
    </>
  );
}
