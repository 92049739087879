/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

import { Tab, Tabs } from "react-bootstrap";

import React from "react";

export function OrderNotes() {
  return (
    <div
      id="kt_OrderNotes_panel"
      className="offcanvas offcanvas-right pt-5 pb-10"
    >
      {/*begin::Header*/}
      <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
        <div
          className="offcanvas-close mt-n1 pr-5"
          style={{ position: "absolute", top: "15px", right: "10px" }}
        >
          <a
            href="#"
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            id="kt_OrderNotes_panel_close"
          >
            <i className="ki ki-close icon-sm text-muted"></i>
          </a>
        </div>
      </div>
      {/*end::Header*/}
      <div className="offcanvas-content px-10 ">
        <div>
          <h5>Notes</h5>
        </div>
        <div
          className="fade pt-3 pr-5 mr-n5 scroll active show ps--active-y mt-10"
          id="kt_quick_panel_logs_kt_OrderNotes_panel"
        >
          <div className="deals-tabs">
            <Tabs defaultActiveKey="Order" id="uncontrolled-tab-example">
              <Tab eventKey="Order" title="Order Note">
                <p className="mt-10">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Aliquam vel felis enim. Phasellus purus turpis, accumsan non
                  urna a, semper lacinia lectus. Integer sit amet vehicula eros,
                  sed convallis ex. Quisque quis enim lacus. Quisque finibus
                  tincidunt nisl, et lobortis augue pellentesque non. Fusce
                  congue nisi nec libero maximus tristique. Fusce semper nibh
                  tellus, at mattis erat vulputate et. Integer dictum ultrices
                  orci, et imperdiet quam luctus molestie. Integer elit metus,
                  ornare non neque vel, congue imperdiet odio. Phasellus sed
                  lorem neque. Sed et elementum justo. Donec vitae dapibus diam,
                  id congue tellus.{" "}
                </p>
              </Tab>
              <Tab eventKey="Driver" title="Driver Note">
                <p className="mt-10">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Aliquam vel felis enim. Phasellus purus turpis, accumsan non
                  urna a, semper lacinia lectus. Integer sit amet vehicula eros,
                  sed convallis ex. Quisque quis enim lacus. Quisque finibus
                  tincidunt nisl, et lobortis augue pellentesque non. Fusce
                  congue nisi nec libero maximus tristique. Fusce semper nibh
                  tellus, at mattis erat vulputate et. Integer dictum ultrices
                  orci, et imperdiet quam luctus molestie. Integer elit metus,
                  ornare non neque vel, congue imperdiet odio. Phasellus sed
                  lorem neque. Sed et elementum justo. Donec vitae dapibus diam,
                  id congue tellus.{" "}
                </p>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
      <div className="offcanvas-footer">
        <div className="offcanvas-content text-right ">
          <button className="btn btn-dark font-weight-bolder font-size-sm mr-3 ">
            Cancel
          </button>
          <button className="btn btn-primary font-weight-bolder font-size-sm ">
            Place Order
          </button>
        </div>
      </div>
    </div>
  );
}
