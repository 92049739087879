/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React, { useState } from "react";
// import DealsForm from "../form/DealsForm";
import { useFormik } from "formik";
import axios from "axios";
import SnackBarTool from "../../../../_partials/widgets/snack-bar/SnackBar";
import * as Yup from "yup";
import { useIntl } from "react-intl";
import {useHistory} from "react-router"

// import { CreateDealSchema } from "../form/validationSchema";
// import { CreateContactSchema } from "./form/validationSchema";
const PasswordSchema = (intl, Yup) => {
  return Yup.object().shape({
    current_password: Yup.string()
      .required(intl.formatMessage({ id: "Please.enter.password" }))
      .min(8, intl.formatMessage({ id: "Your.password.short" })),
    new_password: Yup.string()
      .required(intl.formatMessage({ id: "Please.enter.password" }))
      .min(8, intl.formatMessage({ id: "Your.password.short" })),
    confirm_password: Yup.string()
      .required(intl.formatMessage({ id: "Please.retype.password" }))
      .oneOf(
        [Yup.ref("new_password")],
        intl.formatMessage({ id: "Your.passwords.match" })
      ),
  });
};

export function ChangePasswordCanvas({ check, type, title }) {
  const intl = useIntl();
  const initialValues = {
    current_password: "",
    new_password: "",
    confirm_password: "",
  };
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const history = useHistory();
  const handleSubmit = async (values, actions) => {
    setSnack({ call: false, type: "", message: "" });
    const data = {
      "current-password": values.current_password,
      "new-password": values.new_password,
      "confirm-password": values.confirm_password,
    };
    await axios
      .post(process.env.REACT_APP_SITE_URL + `update-password`, data)
      .then((res) => {
        if (res.data.success) {
          setSnack({ call: true, type: "success", message: res.data.message });
          setTimeout(() => {
            history.push("/logout");
          }, 3000);
        } else {
          setSnack({ call: true, type: "error", message: res.data.message });
        }
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
  };
  const handleReset = () => {};

  const formik = useFormik({
    initialValues,
    validationSchema: PasswordSchema(intl, Yup),
    onSubmit: handleSubmit,
    onReset: handleReset,
  });
  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  return (
    <div
      id="kt_change_password_panel"
      className={`offcanvas offcanvas-right pt-5 pb-10 ${
        check ? "offcanvas-on" : ""
      }`}
    >
      {/*begin::Header*/}
      <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
        <div
          className="offcanvas-close mt-n1  pr-5"
          style={{ position: "absolute", top: "15px", right: "10px" }}
        >
          <a
            href="#"
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            id="kt_change_password_panel_close"
          >
            <i className="ki ki-close icon-xs text-muted"></i>
          </a>
        </div>
      </div>

      {/*end::Header*/}

      {/*begin::Content*/}
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <div className="offcanvas-content px-10 date-picker">
          <div>
            <h5>{intl.formatMessage({ id: "Change.Password" })}</h5>
          </div>
          <div className="tab-content">
            <div
              id="kt_quick_panel_logs_kt_deals_panel"
              role="tabpanel"
              className={`tab-pane fade pt-3 pr-5 mr-n5 scroll  active show `}
            >
              <div className="col-6">
                <label>{intl.formatMessage({ id: "Current.Password" })}</label>
                <div className="form-groupB">
                  <input
                    type="password"
                    className={`form-control  ${getInputClasses(
                      "current_password"
                    )}`}
                    placeholder={intl.formatMessage({ id: "Current.Password" })}
                    name="current_password"
                    {...formik.getFieldProps("current_password")}
                  />
                  {formik.touched.current_password &&
                  formik.errors.current_password ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.current_password}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-6">
                <label>{intl.formatMessage({ id: "New.Password" })}</label>
                <div className="form-groupB">
                  <input
                    type="password"
                    className={`form-control  ${getInputClasses(
                      "new_password"
                    )}`}
                    placeholder={intl.formatMessage({ id: "New.Password" })}
                    name="new_password"
                    {...formik.getFieldProps("new_password")}
                  />
                  {formik.touched.new_password && formik.errors.new_password ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.new_password}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-6">
                <label>{intl.formatMessage({ id: "Confirm.Password" })}</label>
                <div className="form-groupB">
                  <input
                    type="password"
                    className={`form-control  ${getInputClasses(
                      "confirm_password"
                    )}`}
                    placeholder={intl.formatMessage({ id: "Confirm.Password" })}
                    name="confirm_password"
                    {...formik.getFieldProps("confirm_password")}
                  />
                  {formik.touched.confirm_password &&
                  formik.errors.confirm_password ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.confirm_password}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            {/*end::Content*/}
          </div>
        </div>
        <div className="offcanvas-footer">
          <div className="offcanvas-content text-right ">
            <button
              type="reset"
              className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
            >
              {intl.formatMessage({ id: "CANCEL" })}
            </button>
            <button
              type="submit"
              className="btn btn-primary font-weight-bolder font-size-sm "
            >
              {intl.formatMessage({ id: "SAVE" })}
            </button>
          </div>
        </div>
      </form>
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </div>
  );
}
