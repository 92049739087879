// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html

import * as uiHelpers from "../InvoiceUIHelpers";

import React, { Fragment, useEffect, useMemo, useState } from "react";
import {
  getHandlerTableChange,
  getSelectRow,
  getSelectRowChargeIn,
  getSelectRowChargePromo,
  sortCaret,
} from "../../../../_metronic/_helpers";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import BootstrapTable from "react-bootstrap-table-next";
import NoRecord from "../../../../_metronic/_partials/widgets/NoRecord";
import NotAuthorized from "../../../../_metronic/_partials/widgets/NotAuthorized";
import { Pagination } from "../../../../_metronic/_partials/controls";
// import { fetchAgent } from "../../ContactDeals/_redux/contact/contactAction";
import { fetchInvoice, fetchReset } from "../_redux/InvoiceAction";
import moment from "moment";
import { useIntl } from "react-intl";
import { useInvoiceUIContext } from "../InvoiceUIContext";
import ContactDealTaskModal from "../../../../_metronic/_partials/widgets/alert/ContactDealTaskModal";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useHistory } from "react-router-dom";
import { findTasksExport, viewPdfInvoice } from "../_redux/InvoiceCrud";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SnackBarTool from "../../../../_metronic/_partials/widgets/snack-bar/SnackBar";

const TasksTable = ({ show, setShow, permission, active, setActive }) => {
  const intl = useIntl();
  const InvoiceUIContext = useInvoiceUIContext();
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });

  const [dialog, setDialog] = useState({ type: "", open: false, id: "" });
  let history = useHistory();
  const TasksUIProps = useMemo(() => {
    return {
      ids: InvoiceUIContext?.ids,
      setIds: InvoiceUIContext?.setIds,
      queryParams: InvoiceUIContext?.queryParams,
      setQueryParams: InvoiceUIContext?.setQueryParams,
      openEditContactPage: InvoiceUIContext?.openEditContactPage,
      openDeleteContactDialog: InvoiceUIContext?.openDeleteContactDialog,
    };
  }, [InvoiceUIContext]);

  const dispatch = useDispatch();

  const { currentState, role } = useSelector(
    (state) => ({ currentState: state.invoice.list, role: state.auth.user.roles, }),
    shallowEqual
  );

  const { entities, nextoffset, totalCount, loading } = currentState;
  console.log("currentState", currentState);

  useEffect(() => {
  
    return () => {
      dispatch(fetchReset())
    };
  }, []);
useEffect(() => {
if(active){
  setSnack({ call: false, type: "", message: "" });
  findTasksExport(TasksUIProps.queryParams, TasksUIProps?.ids).then((res) => {
    setSnack({ call: true, type: "success", message: res.data.message });
    
    // window.open(res.data?.data.download_url, '_blank');
    setActive(false)
  }).catch((error) => {
    setSnack({
      call: true,
      type: "error",
      message: error.response.data.message,
    });
    setActive(false)
  })
}
}, [active])



  useEffect(() => {
    // clear selections list
    // server call by queryParams
    // TasksUIProps.setIds([]);
    if(role && role?.length && role[0] === "franchise-owner"){
      if(TasksUIProps.queryParams?.filter.search_franchise){

        dispatch(fetchInvoice(TasksUIProps.queryParams, TasksUIProps?.ids));
      }
    }else{
      dispatch(fetchInvoice(TasksUIProps.queryParams, TasksUIProps?.ids));

    }
    // dispatch(fetchAgent());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TasksUIProps.queryParams, dispatch]);

  // useEffect(() => {
  //   TasksUIProps.setQueryParams(uiHelpers.initialFilter);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [dispatch]);

  const columns = [
    {
      dataField: "parent_id",
      text: "Order Id",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return (
          <>
            <span>
              {data.type === "invoice"
                ? data?.booking_reference
                : data?.booking_reference}
            </span>
          </>
        );
      },
    },
    {
      dataField: "number",
      text: "Id",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return (
          <span
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
            onClick={() => {
              const datas = {
                invoice_id: data.id,
              };
              viewPdfInvoice(datas)
                .then((res) => {
                  window.open(res.data?.data.pdf, "_blank");
                })
                .catch((error) => {});
            }}
          >
            <span>{e}</span>
          </span>
        );
      },
    },
    {
      dataField: "type",
      text: "Type",
      sort: false,
      sortCaret: sortCaret,
    },
    {
      dataField: "amount",
      text: "Amount",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return (
          <>
            <span>&pound;{e}</span>
          </>
        );
      },
    },
    {
      dataField: "amount_due",
      text: "Due",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return (
          <>
            <span>&pound;{e}</span>
          </>
        );
      },
    },
    {
      dataField: "franchise_short_name",
      text: "Fr Short name",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return (
          <>
            <span>{e.toUpperCase()}</span>
          </>
        );
      },
    },
    {
      dataField: "status",
      text: "Payment Status",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return (
          <>
            <span
              className={`label label-lg label-light-${
                e === "paid"
                  ? "success"
                  : e === "void"
                  ? "warning"
                  : e === "payment_due"
                  ? "danger"
                  : "warning"
              } label-inline`}
            >
              {e === "paid"
                ? "Paid "
                : e === "payment_due"
                ? "Due"
                : e === "voided"
                ? "Void"
                : e == "not_paid"
                ? "Not Paid"
                : e}
            </span>
          </>
        );
      },
    },
    {
      dataField: "created_at",
      text: "Issued On",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return (
          <>
            <span>{e?moment(e * 1000).format("DD-MM-YYYY"):"-"}</span>
          </>
        );
      },
    },
    {
      dataField: "finalized_at",
      text: "Fully Paid On",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return (
          <>
            <span>{e?moment(e * 1000).format("DD-MM-YYYY"):"-"}</span>
          </>
        );
      },
    },
    {
      dataField: "transfer_number",
      text: "Franchise Invoice ID",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return (
          <span
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
            onClick={() => {
              const datas = {
                invoice_id: data.transfer_id,
              };

              viewPdfInvoice(datas)
                .then((res) => {
                  window.open(res.data?.data.pdf, "_blank");
                })
                .catch((error) => {});
            }}
          >
            <span>{e}</span>
          </span>
        );
      },
    },

    {
      dataField: "transfer_status",
      text: "Franchise Status",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return (
          <>
            <span
              className={`label label-lg label-light-${
                e === "paid"
                  ? "success"
                  : e === "void"
                  ? "warning"
                  : e === "payment_due"
                  ? "danger"
                  : "warning"
              } label-inline`}
            >
              {e === "paid"
                ? "Paid "
                : e === "payment_due"
                ? "Due"
                : e === "voided"
                ? "Void"
                : e == "not_paid"
                ? "Not Paid"
                : e}
            </span>
          </>
        );
      },
    },
    {
      dataField: "application_fee_number",
      text: "MSF Invoice",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return (
          <span
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
            onClick={() => {
              const datas = {
                invoice_id: data.application_fee_id,
              };

              viewPdfInvoice(datas)
                .then((res) => {
                  window.open(res.data?.data.pdf, "_blank");
                })
                .catch((error) => {});
            }}
          >
            <span>{e}</span>
          </span>
        );
      },
    },
    {
      dataField: "application_fee_status",
      text: "MSF Status",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        console.log("data", data);
        return (
          <>
            <span
              className={`label label-lg label-light-${
                e === "paid"
                  ? "success"
                  : e === "void"
                  ? "warning"
                  : e === "payment_due"
                  ? "danger"
                  : "warning"
              } label-inline`}
            >
              {e === "paid"
                ? "Paid "
                : e === "payment_due"
                ? "Due"
                : e === "voided"
                ? "Void"
                : e == "not_paid"
                ? "Not Paid"
                : e}
            </span>
          </>
        );
      },
    },
  ];
  // const [total, setTotal] = useState(5);

  // useEffect(() => {
  //   if (TasksUIProps.queryParams?.pageNumber) {
  //     setTotal(total + 5);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [TasksUIProps.queryParams.pageNumber]);

  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: TasksUIProps.queryParams?.pageSize,
    page: TasksUIProps.queryParams?.pageNumber,
    offset: entities?.next_page,
    ids: TasksUIProps?.ids,
    setIds: TasksUIProps.setIds,
  };
  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      // history.push(`/order-details/${row.order_id}`);
    },
  };
  console.log("TasksUIProps", TasksUIProps);
  const selectRow = {
    mode: "checkbox",
    hideSelectAll: true,
    hideSelectColumn: true,
  };
  console.log("entities", entities);
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={loading}
              paginationProps={paginationProps}
              entities={entities?.invoices}
              customePagination={true}
              loading={loading}
            >
              {show && (
                <BootstrapTable
                  hover
                  wrapperClasses="table-responsive"
                  bordered={false}
                  rowEvents={rowEvents}
                  classes="table table-head-custom table-vertical-center overflow-hidden"
                  bootstrap4
                  remote
                  keyField="invoice"
                  // defaultSorted={uiHelpers.defaultSorted}
                  data={!entities?.invoices ? [] : entities?.invoices}
                  onTableChange={getHandlerTableChange(
                    TasksUIProps.setQueryParams
                  )}
                  columns={columns}
                  {...paginationTableProps}
                ></BootstrapTable>
              )}
              {/* {permission?.task?.view ? (
                <>
                  {(entities && entities?.length) || loading ? (
                    ""
                  ) : (
                    <NoRecord />
                  )}
                </>
              ) : (
                ""
                // <NotAuthorized />
              )} */}
            </Pagination>
          );
        }}
      </PaginationProvider>
      {snack.call ? <SnackBarTool {...snack} /> : ""}

      <ContactDealTaskModal dialog={dialog} setDialog={setDialog} />
    </>
  );
};

export default TasksTable;
