/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */

import React, { useState } from "react";
import { shallowEqual, useSelector } from "react-redux";

import { ContactActionDropDown } from "./ContactActionDropDown";
import { Dropdown } from "react-bootstrap";
import SnackBarTool from "../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { convertContactDeal } from "../_redux/contact/contactCrud";
import { useHistory, Link } from "react-router-dom";
import { useIntl } from "react-intl";
import { MergeContactCanvas } from "../canvas/MergeContact";
import {
  fetchContactDeal,
  fetchContactfeedList,
} from "../_redux/contact/contactAction";
import { useDispatch } from "react-redux";

export function ContactDetailTop({
  setActiveTab,
  detail,
  setDealData,
  dealData,
}) {
  // Use the useHistory hook to access the history object for redirects
  const history = useHistory();
  // Use the useIntl hook to access the intl object for translations
  const intl = useIntl();
  const dispatch = useDispatch();
  // Use the useState hook to create a state variable for snackbar
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [canva, setCanva] = useState({ type: false, data: {} });

  // Use the useSelector hook to access the permission state
  const { permission } = useSelector(
    (state) => ({
      permission: state.auth.permission,
    }),
    shallowEqual
  );

  // Handle converting a contact to a deal
  // eslint-disable-next-line no-unused-vars
  const handleConvert = () => {
    // Update snackbar state to not show
    setSnack({ call: false, type: "", message: "" });

    convertContactDeal(detail?.id)
      .then((res) => {
        setSnack({ call: true, type: "success", message: res.data.message });
        dispatch(fetchContactDeal(res.data.data.contact_id));
        dispatch(fetchContactfeedList(detail?.id));
        setTimeout(() => {
          if (dealData?.type === "deal") {
            setDealData({
              ...dealData,
              dealId: res.data.data.id,
              contactId: res.data.data.contact_id,
            });
          } else {
            history.push(`/deal-view/${res.data.data.id}`);
          }
        }, 3000);
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",

          error: error.response.data.errors,
        });
      });
  };
  const handleClick = () => {
    const state = {
      id: detail?.user_data?.id,
      type: "deal",
      dealId: detail?.id,
    };

    history.push({
      pathname: "/new-order",
      search: "?customer",
      state: state
    });
  };

  return (
    <>
      <ul className="deal-top">
        <li className="mr-3">
          <Dropdown className="dropdown-inline" drop="down" alignRight>
            <Dropdown.Toggle
              id="dropdown-toggle-top2"
              variant="transparent"
              className="btn btn-light  font-size-sm font-weight-bolder dropdown-toggle text-dark-75"
            >
              {intl.formatMessage({ id: "Actions" })}
            </Dropdown.Toggle>
            <ContactActionDropDown
              setActiveTab={setActiveTab}
              Dropdown={Dropdown}
              setCanva={setCanva}
            />
          </Dropdown>
        </li>
        <li>
          {permission?.deal?.create ? (
            // <button
            //   disabled={
            //     (detail && detail.contact_stage === "Qualified") ||
            //     (detail && detail.contact_stage === "Open")
            //       ? false
            //       : true
            //   }
            //   className="btn btn-dark font-weight-bolder font-size-sm "
            //   onClick={() => handleConvert()}
            // >
            //   {intl.formatMessage({ id: "Create.Deal" })}
            // </button>
            // <Link
            //   to={{
            //     pathname: "/new-order#customer",

            //     state: {
            //       id: detail?.user_data?.id,
            //       type: "deal",
            //       dealId: detail?.id,
            //     },
            //   }}
            // >
            <>
            </>
              // <button className="btn btn-primary font-weight-bolder font-size-sm " onClick={handleClick}>
              //   Create Order
              // </button>
            // </Link>
          ) : (
            ""
          )}
        </li>
      </ul>
      {canva.type ? (
        <>
          <MergeContactCanvas setCanva={setCanva} canva={canva} />
          <div
            className="offcanvas-overlay"
            onClick={() => setCanva({ type: false, data: {} })}
          ></div>
        </>
      ) : (
        ""
      )}
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </>
  );
}
