import React from "react";
import { Form } from "react-bootstrap";
import { useIntl } from "react-intl";
export function SMSWidget({ formik, loading, detailData }) {
  const intl = useIntl();
  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };
  return (
    <>
      <div className=" mb-10 mt-10" id="kt_inbox_reply">
        <div className="card card-custom shadow-sm mr-2 ml-2">
          <div className="card-body p-0">
            <div className="d-block">
              <div className="d-flex align-items-center border-bottom inbox-to px-8 min-h-50px">
                <div className="text-dark-50 w-75px">To:</div>
                <div className="d-flex align-items-center flex-grow-1">
                  <input
                    type="text"
                    className={`form-control w-100 border-0  ${getInputClasses(
                      "sms_to"
                    )}`}
                    name="sms_to"
                    {...formik.getFieldProps("sms_to")}
                  />
                  {/* <span>{formik.values.sms_to}</span> */}
                </div>
              </div>
              {formik.touched.sms_to && formik.errors.sms_to ? (
                <div className="fv-plugins-message-container  ">
                  <div className="fv-help-block">
                    {formik.errors.sms_to
                      ? formik.errors.sms_to
                      : formik.errors.sms_to}
                  </div>
                </div>
              ) : null}
              <div className="ql-editor ql-blank px-5">
                <Form.Control
                  as="textarea"
                  rows="8"
                  className={`form-control border-0  w-100  ${getInputClasses(
                    "description"
                  )}`}
                  name="description"
                  {...formik.getFieldProps("description")}
                  placeholder={intl.formatMessage({ id: "Type.Message" })}
                />
              </div>
              <div className="border-top">
                {formik.touched.description && formik.errors.description ? (
                  <div className="fv-plugins-message-container  ">
                    <div className="fv-help-block">
                      {formik.errors.description
                        ? formik.errors.description
                        : formik.errors.description}
                    </div>
                  </div>
                ) : null}
                <div className="d-flex align-items-center justify-content-end py-5 pl-8 ">
                  <div className="d-flex align-items-center mr-3">
                    <div className=" mr-4">
                      <button
                        type="submit"
                        className="btn btn-primary font-weight-bolder font-size-sm mr-3"
                        disabled={loading}
                      >
                        {intl.formatMessage({ id: "Send" })}
                        {loading && (
                          <span className="ml-2 mr-2 spinner spinner-white"></span>
                        )}
                      </button>
                      <button
                        type="reset"
                        className="btn btn-dark font-weight-bolder font-size-sm mr-3"
                      >
                        {intl.formatMessage({ id: "CANCEL" })}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
