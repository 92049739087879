import React, { useState } from "react";
import { Card, CardBody } from "../../../../../_metronic/_partials/controls";
import { FranchisesFilter } from "./franchises-filter/FranchisesFilter";
import { FranchisesTable } from "./franchises-table/FranchisesTable";
import { useSelector, shallowEqual } from "react-redux";
import NotAuthorized from "../../../../../_metronic/_partials/widgets/NotAuthorized";
import NewBreadCrumbs from "../../../../../_metronic/layout/components/subheader/components/NewBreadCrumbs";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useFranchisesUIContext } from "./FranchisesUIContext";
import { deleteFranchise } from "../../_redux/franchises/franchisesCrud";
import { initialFilter } from "./FranchisesUIHelpers";
import { fetchFranchises } from "../../_redux/franchises/franchisesActions";
import DeleteDialog from "../../../../../_metronic/_partials/widgets/alert/DeleteDialog";
import SnackBarTool from "../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";

export function FranchisesCard(props) {
  // Use the useSelector hook to access the state
  const { permission, currentState } = useSelector(
    (state) => ({
      permission: state.auth.permission,
      currentState: state.franchise.list,
    }),
    shallowEqual
  );
  const { listLoading } = currentState;
  const intl = useIntl();

  // Use the useDispatch hook to dispatch actions
  const dispatch = useDispatch();

  const FranchisesUIContext = useFranchisesUIContext();

  const [dialog, setDialog] = useState(false);
  // Use state hook to manage snackbar state
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });

  const handleDelete = () => {
    setSnack({ call: false, type: "", message: "" });
    deleteFranchise({ franchise_id: FranchisesUIContext.ids })
      .then((res) => {
        setSnack({ call: true, type: "success", message: res.data.message });
        dispatch(fetchFranchises(initialFilter));
        setDialog(false);
        FranchisesUIContext.setIds([]);
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
        setDialog(false);
      });
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <NewBreadCrumbs title={intl.formatMessage({ id: "FRANCHISES" })} />
        <div>
          {permission?.setting_franchise?.delete ? (
            <button
              className="btn btn-primary mr-2"
              type="button"
              onClick={() => setDialog(true)}
              disabled={FranchisesUIContext.ids.length === 0}
            >
              Delete
            </button>
          ) : (
            ""
          )}
          {permission?.setting_franchise?.create ? (
            <button
              id="kt_new_franchise_panel_toggle"
              className="btn btn-primary mr-2"
              type="button"
              disabled={listLoading}
            >
              {intl.formatMessage({ id: "NEW.FRANCHISE" })}
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
      <Card>
        <CardBody>
          {permission?.setting_franchise?.view ? (
            <>
              <FranchisesFilter {...props} />

              <FranchisesTable />
            </>
          ) : (
            <NotAuthorized />
          )}
        </CardBody>
      </Card>
      <DeleteDialog
        dialog={dialog}
        setDialog={setDialog}
        handleDelete={handleDelete}
        title={"Franchise"}
      />
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </>
  );
}
