/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */

import { ProgressBar } from "react-bootstrap";
import React from "react";
import { Table } from "react-bootstrap";
import { capitalizeFirstLetter } from "../../../utils/canvaCloseFunction";
// import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { convertGBPtoPoundSymbol } from "../../../utils/commonFunction";
import NoRecord from "../../../../_metronic/_partials/widgets/NoRecord";

// Displays a widget with a list of orders for a customer
export function CustomerOrderWidget({ orderList, loading }) {
  // Use the react-intl hook for multilanguage
  const intl = useIntl();
  // Use the react-router-dom hook for programmatic navigation
  // const history = useHistory();

  // Handler for redirecting to the order details page
  const handleRedirect = (id) => {
    window.open(`/order-details/${id}`, "_blank");
    // history.push(`/order-details/${id}`);
  };

  return (
    <>
      {/* Card component */}
      <div className="card card-custom card-stretch">
        {/* Card header */}
        <div className="card-header align-items-center border-0 mt-4 mb-0 ">
          {/* Card title */}
          <h3 className="card-title align-items-start flex-column">
            <span className="font-weight-bolder text-dark">
              {intl.formatMessage({ id: "ORDERS" })}
            </span>
          </h3>
        </div>

        {/* Card body */}
        <div className="card-body pt-2">
          {/* {!loading && orderList && orderList.length < 1 && <NoRecord />} */}
          {/* Display a loading spinner while loading */}
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                height: "50%",
              }}
            >
              <span className="ml-2 mr-2 spinner spinner-black"></span>
            </div>
          ) : orderList && orderList.length > 0 ? (
            <Table responsive>
              {/* Table component */}
              {/* Table head */}
              <thead>
                <tr>
                  <th style={{ width: "120px" }}>
                    {intl.formatMessage({ id: "Order.Number" })}
                  </th>
                  <th>{intl.formatMessage({ id: "Date" })}</th>
                  <th>{intl.formatMessage({ id: "Total" })}</th>
                  <th>{intl.formatMessage({ id: "Status" })}</th>
                  <th style={{ width: "120px" }}>
                    {intl.formatMessage({ id: "Payment" })}
                  </th>
                </tr>
              </thead>
              {/* Table body */}
              <tbody>
                {/* Map over the order list and display a row for each order */}
                {orderList && orderList.length > 0
                  ? orderList.map((item) => (
                      <tr onClick={() => handleRedirect(item?.id)}>
                        <td>{item?.booking_reference || ""}</td>
                        <td>{item?.collection_date || " "}</td>
                        <td>
                          {item?.currency
                            ? convertGBPtoPoundSymbol(item?.currency)
                            : ""}
                          {item?.amount || ""}
                        </td>
                        <td className="statusarea">
                          <span>
                            {item?.is_cancel === 1
                              ? "Cancelled"
                              : intl.formatMessage({ id: "Collection" })}
                          </span>
                          {/* Progress bar component */}
                          <ProgressBar
                            variant={
                              item?.is_cancel === 1 ? "danger" : "success"
                            }
                            now={item?.is_cancel === 1 ? 100 : 40}
                            label={item?.is_cancel === 1 ? "Can" : ""}
                          />
                        </td>
                        <td>
                          {/* Display the payment status as a label */}
                          <span className="label label-lg label-light-warning label-inline">
                            {capitalizeFirstLetter(
                              item?.payment_status || ""
                            ).replace("_", " ")}
                          </span>
                        </td>
                      </tr>
                    ))
                  : ""}
              </tbody>
            </Table>
          ) : (
            <NoRecord />
          )}
        </div>
      </div>
    </>
  );
}
