/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

import * as Yup from "yup";

import React, { useEffect, useState } from "react";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import { fetchAgent, fetchContact } from "../_redux/contact/contactAction";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { AssignContactSchema } from "../form/validationSchema";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import SnackBarTool from "../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { assignContact } from "../_redux/contact/contactCrud";
import { canvaCancel } from "../../../utils/canvaCloseFunction";
import { getInputClasses } from "../../../utils/commonFunction";
import { useContactUIContext } from "../ContactUIContext";
import { useFormik } from "formik";
import { useIntl } from "react-intl";

export function AssignContactCanvas({ setShow = null }) {
  // Initialize the internationalization hook
  const intl = useIntl();

  // Get the contact UI context for managing selected contact IDs
  const contactUIContext = useContactUIContext();

  // Get the dispatch function for dispatching redux actions
  const dispatch = useDispatch();

  // Create state to manage snackbar notifications
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  // Create state to manage loading state
  const [loading, setLoading] = useState(false);
  // Get the current state of the agent from the redux store

  const { currentState } = useSelector(
    (state) => ({ currentState: state.contact.agent }),
    shallowEqual
  );

  // Initial values for the formik form
  const initialValues = {
    assined_user_agent_id: "",
  };

  // Fetch the agent data when the component mounts
  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(fetchAgent());
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [dispatch]);

  // Handle form submission
  const handleSubmit = (values, actions) => {
    setShow(false);
    setLoading(true);
    const data = {
      assined_user_agent_id: values.assined_user_agent_id,
      contact_id: contactUIContext.ids,
    };
    setSnack({ call: false, type: "", message: "" });
    assignContact(data)
      .then((res) => {
        setLoading(false);

        setSnack({ call: true, type: "success", message: res.data.message });
        dispatch(fetchContact(contactUIContext.queryParams));

        contactUIContext.setIds([]);
        canvaCancel("kt_assign_panel_close");
        setShow(true);
      })
      .catch((error) => {
        setLoading(false);
        setShow(true);
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: AssignContactSchema(intl, Yup),
    onSubmit: handleSubmit,
  });

  return (
    <div id="kt_assign_panel" className="offcanvas offcanvas-right pt-5 pb-10">
      {/*begin::Header*/}
      <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
        <div
          className="offcanvas-close  pr-5"
          style={{ top: "15px", right: "10px" }}
        >
          <span
            onClick={() => formik.resetForm()}
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            id="kt_assign_panel_close"
          >
            <i className="ki ki-close icon-xs text-muted"></i>
          </span>
        </div>
      </div>

      {/*end::Header*/}

      {/*begin::Content*/}
      <form onSubmit={formik.handleSubmit}>
        <div className="offcanvas-content px-10">
          <div>
            <h5>{intl.formatMessage({ id: "Assign.Contact" })}</h5>
          </div>
          <div className="tab-content">
            <div
              id="kt_quick_panel_logs_kt_assign_panel"
              role="tabpanel"
              className={`tab-pane fade pt-3 pr-5 mr-n5 scroll ps active show `}
            >
              <div className="row">
                <>
                  <div className="col-12">
                    <div className="form-group">
                      <label>
                        {intl.formatMessage({ id: "Assign.selected.Contact" })}{" "}
                        :{" "}
                      </label>
                      <FormControl fullWidth>
                        <Select
                          name="assined_user_agent_id"
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          }}
                          className={`form-control  ${getInputClasses(
                            "assined_user_agent_id"
                          )}`}
                          IconComponent={(props) => (
                            <i {...props}>
                              <KeyboardArrowDownIcon />
                            </i>
                          )}
                          inputProps={{ "aria-label": "Without label" }}
                          displayEmpty
                          {...formik.getFieldProps("assined_user_agent_id")}
                        >
                          <MenuItem value="">
                            {intl.formatMessage({ id: "No.owner" })}
                          </MenuItem>
                          {currentState &&
                            currentState.map((data, index) => (
                              <MenuItem key={index} value={data.id}>
                                {data.first_name +
                                  " " +
                                  (data.last_name ? data.last_name : "")}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                      {formik.touched.assined_user_agent_id &&
                      formik.errors.assined_user_agent_id ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.assined_user_agent_id}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </>
              </div>
            </div>
            {/*end::Content*/}
          </div>
        </div>
        <div className="offcanvas-footer">
          <div className="offcanvas-content text-right ">
            <button
              type="reset"
              className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
              onClick={() => canvaCancel("kt_assign_panel_close")}
            >
              {intl.formatMessage({ id: "CANCEL" })}
            </button>
            <button
              type="submit"
              className="btn btn-primary font-weight-bolder font-size-sm "
              disabled={loading}
            >
              {intl.formatMessage({ id: "SAVE" })}
              {loading && (
                <span className="ml-2 mr-2 spinner spinner-white"></span>
              )}
            </button>
          </div>
        </div>
      </form>
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </div>
  );
}
