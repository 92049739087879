/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

import React, { useState } from "react";

import { Form } from "react-bootstrap";
import SnackBarTool from "../../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { canvaCancel } from "../../../../../utils/canvaCloseFunction";
import { editFranchisePopDetail } from "../../../_redux/franchises/franchisesCrud";
import { useFormik } from "formik";
import { useIntl } from "react-intl";

export function QuickbookDetail({ detailData }) {
  // const [selectedTab, setSelectedTab] = useState("AuditLogs");
  const intl = useIntl();

  const initialValues = {
    franchise_id: detailData && detailData.id ? detailData.id : "",
    qbo_id: detailData && detailData.qbo_id ? detailData.qbo_id : "",
    qbo_csk: detailData && detailData.qbo_csk ? detailData.qbo_csk : "",
    type: "quickbook",
  };

  const [snack, setSnack] = useState({ call: false, type: "", message: "" });

  const handleSubmit = (values, actions) => {
    setSnack({ call: false, type: "", message: "" });
    editFranchisePopDetail(values)
      .then((res) => {
        setSnack({
          call: true,
          type: "success",
          message: "Quick book detail updated successfully",
        });
        canvaCancel("kt_quickbookdetail_panel_close");
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
  };

  const handleReset = () => {};

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: handleSubmit,
    onReset: handleReset,
  });
  return (
    <div
      id="kt_quickbookdetail_panel"
      className="offcanvas offcanvas-right pt-5 pb-10"
    >
      {/*begin::Header*/}
      <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
        <div
          className="offcanvas-close mt-n1 pr-5"
          style={{ position: "absolute", top: "15px", right: "10px" }}
        >
          <span
            onClick={() => formik.resetForm()}
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            id="kt_quickbookdetail_panel_close"
          >
            <i className="ki ki-close icon-sm text-muted"></i>
          </span>
        </div>
      </div>
      {/*end::Header*/}
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <div className="offcanvas-content px-10 ">
          <div>
            <h5>{intl.formatMessage({ id: "Quickbook.Details" })}</h5>
          </div>
          <div
            id="kt_quick_panel_logs_kt_quickbookdetail_panel"
            className="fade pt-3 pr-5 mr-n5 scroll active show ps--active-y mt-10"
          >
            <div className="mt-10 pb-10">
              <div>
                <Form.Group controlId="" className="mb-5">
                  <Form.Label>
                    {intl.formatMessage({ id: "Quick.Book.Client.ID" })}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={intl.formatMessage({
                      id: "Quick.Book.Client.ID",
                    })}
                    className={`form-control  ${getInputClasses("qbo_id")}`}
                    name="qbo_id"
                    {...formik.getFieldProps("qbo_id")}
                  />
                  {formik.touched.qbo_id && formik.errors.qbo_id ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.qbo_id}
                      </div>
                    </div>
                  ) : null}
                </Form.Group>
              </div>
              <div>
                <Form.Group controlId="" className="mb-5">
                  <Form.Label>
                    {intl.formatMessage({ id: "Quick.Book.Client.Secret.Key" })}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={intl.formatMessage({
                      id: "Quick.Book.Client.Secret.Key",
                    })}
                    className={`form-control  ${getInputClasses("qbo_csk")}`}
                    name="qbo_csk"
                    {...formik.getFieldProps("qbo_csk")}
                  />
                  {formik.touched.qbo_csk && formik.errors.qbo_csk ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.qbo_csk}
                      </div>
                    </div>
                  ) : null}
                </Form.Group>
              </div>
            </div>
          </div>
        </div>
        <div className="offcanvas-footer">
          <div className="offcanvas-content text-right ">
            <button
              type="reset"
              className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
              onClick={() => canvaCancel("kt_quickbookdetail_panel_close")}
            >
              {intl.formatMessage({ id: "CANCEL" })}
            </button>
            <button
              type="submit"
              className="btn btn-primary font-weight-bolder font-size-sm mr-3 "
            >
              {intl.formatMessage({ id: "SAVE" })}
            </button>
          </div>
        </div>
      </form>
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </div>
  );
}
