import React, { useState, useEffect } from "react";
import { InputGroup } from "react-bootstrap";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  fetchOrderSummary,
  fetchSearchProduct,
} from "../../_redux/order/orderAction";
import { useOrderUIContext } from "../../OrderUIContext";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { useIntl } from "react-intl";
import { convertGBPtoPoundSymbol } from "../../../../utils/commonFunction";
import { billingCycleCoupons } from "../../_redux/order/orderCrud";

export const SearchProductList = ({
  orderDetail,
  orderInvoice,
  setFieldValue,
  fieldValue,
  handleCancel,
  setDialog,
}) => {
  const intl = useIntl();
  const { search } = useLocation();
  const query = queryString.parse(search);

  const orderUIContext = useOrderUIContext();
  const dispatch = useDispatch();
  const { searchProduct } = useSelector(
    (state) => ({
      searchProduct: state.order.searchProduct,
    }),
    shallowEqual
  );

  const [debounceTimer, setDebounceTimer] = useState(null);

  // Function to add parameters to the order
  const handleAddParams = (q, qty) => {
    const arrProId = [...orderUIContext?.state?.products_id];
    const arr = [...orderUIContext?.state?.productArrayList];
    // Check if the item is a plan and there is already a plan in the array
    if (q?.item?.type === "plan" && arr.some((e) => e.type === "plan")) {
      const index = arr.findIndex((e) => e.type === "plan");
      arrProId.splice(index, 1);
    }
    arrProId.push(q?.item?.id || "");
    dispatch(
      fetchOrderSummary(
        orderUIContext?.state?.deletedProduct,
        "edit_order",
        orderDetail?.subscription_data?.billing_period || "",
        orderDetail?.subscription_data?.billing_period_unit || "",
        arrProId,
        orderDetail?.franchise_id || "",
        orderDetail.customer_details.postcode || "",
        handleAddProduct,
        "order",
        qty
      )
    );
  };
  useEffect(() => {
    if (orderUIContext?.state?.adhoc?.id) {
      handleAddParams("", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderUIContext?.state?.adhoc?.id]);

  const handleBillingCycleCoupons = (billingCycle) => {
    billingCycleCoupons({
      id: orderDetail?.franchise_id,
      cycle: billingCycle.unit + " " + billingCycle.period,
    })
      .then((res) => {
        let arr = [...orderUIContext.state?.coupon];
        const removedBilling = arr.filter((e) => e.check !== "billing");
        arr.push(removedBilling);
        arr = removedBilling;
        for (let items of res.data.data)
          if (items.coupon.discount_type === "percentage") {
            arr.push({
              type: "percent",
              id: items.coupon.id,
              value: items.coupon.discount_percentage,
              name: items.name,
              check: "billing",
            });
          } else {
            arr.push({
              type: "fixed",
              id: items.coupon.id,
              value: items.coupon.discount_amount,
              name: items.name,
              check: "billing",
            });
          }

        orderUIContext.dispatchReducer({
          type: "SET_DATA",
          data: {
            ...orderUIContext.state,
            coupon: arr,
            initialBillingCheck: true,
          },
        });
      })
      .catch((error) => {
        // setSnack({
        //   call: true,
        //   type: "error",
        //   message: error?.response?.data?.message,
        //   error: error?.response?.data?.errors,
        // });
      });
  };

  // Function to add products to the order
  const handleAddProduct = (data, arrProId) => {
    const arr = [...orderUIContext?.state?.productArrayList];
    for (let items of data) {
      const pricePro = items?.price.find(
        (e) => e?.item_price?.currency_code === orderDetail?.currency
      );

      if (!arr.find((e) => e.id === items?.item?.id)) {
        if (items.item?.type === "plan") {
          arr.splice(
            arr.findIndex((e) => e.type === "plan"),
            1
          );
        }
        const dataPro = {
          id: items?.item?.id,
          name: items?.item?.name,
          price:
            pricePro?.item_price?.price === 0
              ? "0"
              : pricePro?.item_price?.price,
          quantity:
            items?.item?.metadata?.product_count ||
            items?.item?.metadata?.pods_count ||
            "1",
          total:
            pricePro?.item_price?.price === 0
              ? "0"
              : pricePro?.item_price?.price,
          type: items?.item?.type || "",
          item_price_id: pricePro?.item_price?.id || "",
          cover_list: pricePro?.cover_list?.list || [],
        };
        if (orderUIContext?.state?.adhoc?.id === items?.item?.id) {
          dataPro.price = orderUIContext?.state?.adhocCharge || "";
          dataPro.total = orderUIContext?.state?.adhocCharge || "";
        }
        if (items?.item?.metadata?.is_packaging_material === "1") {
          dataPro.is_packaging_material =
            items?.item?.metadata?.is_packaging_material;
        }
        arr.push(dataPro);
      }
    }
    const arrPlan = arr.filter((e) => e.type === "plan");
    const arrAddon = arr.filter((e) => e.type === "addon");
    const arrCharge = arr.filter((e) => e.type === "charge");

    const planAddon = arrPlan.concat(arrAddon);
    const sortOrder = planAddon.concat(arrCharge);

    orderUIContext.dispatchReducer({
      type: "SET_DATA",
      data: {
        ...orderUIContext.state,
        productArrayList: sortOrder,
        products_id: arrProId,
      },
    });

    setFieldValue("");
  };

  // UseEffect to check if there is a quantity in the query and add it to the order
  useEffect(() => {
    if (
      query["cm-qty"] &&
      orderInvoice &&
      orderInvoice.first_invoice &&
      orderInvoice.first_invoice.length > 0
    ) {
      const check =
        orderInvoice &&
        orderInvoice.first_invoice &&
        orderInvoice.first_invoice.length > 0 &&
        orderInvoice?.first_invoice[0]?.invoice?.line_items.some(
          (e) => e?.quantity === "1"
        );
      if (check) {
        const q = { item: { type: "plan" } };
        handleAddParams(q, query["cm-qty"]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderInvoice?.first_invoice, query?.cm]);

  useEffect(() => {
    const script = document.createElement("script");

    script.src =
      "https://calcumate-calculator-new-production.s3-ap-southeast-2.amazonaws.com/static/js/main.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (
      orderUIContext?.state?.productArrayList.length &&
      !orderUIContext?.state?.initialBillingCheck
    ) {
      const billingCycle = {
        unit: orderUIContext?.state?.params?.unit || "",
        period: orderUIContext?.state?.params?.period || "",
      };
      handleBillingCycleCoupons(billingCycle);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    orderUIContext.state.initialBillingCheck,
    orderUIContext.state.productArrayList.length,
  ]);

  const handleInputChange = (newInputValue) => {
    // setFieldValue(newInputValue);
    clearTimeout(debounceTimer);
    const timer = setTimeout(() => {
      dispatch(
        fetchSearchProduct(orderDetail?.franchise_id || "", newInputValue, {
          unit: "1",
        })
      );
    }, 500); // Adjust the debounce delay as needed (in milliseconds)
    setDebounceTimer(timer);
  };
  return (
    <div
      className="product-search wdt-100Im"
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      <InputGroup>
        <Autocomplete
          autoComplete={false}
          fullWidth
          loading={searchProduct.loading}
          filterSelectedOptions
          onChange={(e, q) => handleAddParams(q)}
          onInputChange={(event, newInputValue) => {
            setFieldValue(newInputValue);
            if (newInputValue !== "" && newInputValue.length > 2)
              handleInputChange(newInputValue);
          }}
          inputValue={fieldValue === "" ? "" : fieldValue}
          id="controllable-states-demo"
          options={searchProduct?.data || []}
          getOptionLabel={(option) => {
            return `${option?.item?.id} ${" | "}${
              option?.item?.name
            } ${" | "} ${option?.price[0]?.item_price?.price || "N/A"}`;
          }}
          renderOption={(props, option) => {
            const index = props?.price.findIndex(
              (e) => e?.item_price?.currency_code === orderDetail?.currency
            );

            return (
              <span>
                {props?.item?.id}&nbsp;|&nbsp;&nbsp;{props?.item?.name}
                &nbsp;|&nbsp;&nbsp;
                {(props &&
                  props.price &&
                  props.price.length &&
                  props.price[index] &&
                  props.price[index]?.item_price?.currency_code &&
                  convertGBPtoPoundSymbol(
                    props.price[index]?.item_price?.currency_code
                  ) + props.price[index]?.item_price?.price) ||
                  "N/A"}
              </span>
            );
          }}
          style={{ width: 270 }}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              label={"Search Product"}
              variant="outlined"
              className="form-control"
            />
          )}
        />
      </InputGroup>
      <div className="ml-3" style={{ width: "100%" }}>
        <button
          className="btn btn-secondary font-weight-bolder font-size-sm mr-3 "
          // id="kt_AddEditStorage_panel_toggle"
          onClick={() => setDialog(true)}
        >
          {intl.formatMessage({ id: "Add/Edit.Storage.Items" })}
        </button>
      </div>
    </div>
  );
};
