import { Card, CardBody } from "../../../../../../_metronic/_partials/controls"
import React, { useState } from "react";
import DeleteDialog from "../../../../../../_metronic/_partials/widgets/alert/DeleteDialog";
import NewBreadCrumbs from "../../../../../../_metronic/layout/components/subheader/components/NewBreadCrumbs";
import SnackBarTool from "../../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
// import { initialFilter } from "./CountryUIHelpers";
import { useDispatch } from "react-redux";
import { useLogsUIContext } from "./LogsUIContext";
import LogsTable from "./LogsTable";

const LogsCard = ({ permission, init, intl }) => {
  const LogsUIContext = useLogsUIContext();
  const dispatch = useDispatch();
  //  This state controls the delete dialog
  const [dialog, setDialog] = useState(false);
  //  This state controls the snackbar
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });

  //  This function handles deleting countries
  // const handleDelete = () => {
  //   //  Reset the snackbar
  //   setSnack({ call: false, type: "", message: "" });
  //   deleteCountry({ Logs_id: LogsUIContext.ids })
  //     .then((res) => {
  //       //  Display a success message in the snackbar
  //       setSnack({ call: true, type: "success", message: res.data.message });
  //       dispatch(fetchCountryList(initialFilter));

  //       //  Close the delete dialog
  //       setDialog(false);
  //       CountryUIContext.setIds([]);
  //     })
  //     .catch((error) => {
  //       //  Display an error message in the snackbar
  //       setSnack({
  //         call: true,
  //         type: "error",
  //         message: error.response.data.message,
  //       });
  //       //  Close the delete dialog
  //       setDialog(false);
  //     });
  // };
  return (
    <>
      {/* This div displays its children with a space between them */}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {/* This components shows breadcrumb */}
        <NewBreadCrumbs title={"QuickBook Logs"} />
        {/* This div contains the delete and new country buttons */}
        {/* <div>
            <button
              className="btn btn-primary mr-2"
              type="button"
              onClick={() => setDialog(true)}
              disabled={LogsUIContext.ids.length === 0}
            >
              Delete
            </button> */}

          {/* {permission?.setting_country?.create ? (
            <button
              id={init[0] + "_toggle"}
              className="btn btn-primary "
              type="button"
            >
              New Country
            </button>
          ) : (
            ""
          )} */}
        {/* </div> */}
      </div>
      {/* This card contains the country filter and table */}
      <Card>
        <CardBody>
            <>
              {/* <CountryFilter /> */}
              <LogsTable />
            </>
        </CardBody>
      </Card>
      {/* This dialog allows the user to delete items */}
      <DeleteDialog
        dialog={dialog}
        setDialog={setDialog}
        // handleDelete={handleDelete}
        title={"Logs"}
      />
      {/* This snackbar displays error and success messages to the user */}
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </>
  );
};

export default LogsCard;
