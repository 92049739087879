import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  table: {
    minWidth: 400,
  },
});
const TieredPriceAmount = ({ formik, getFieldError }) => {
  const classes = useStyles();
  return (
    <div>
      {/* <div style={{ display: "flex", justifyContent: "center", gap: "20px" }}> */}
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Pod (Units)</TableCell>
            <TableCell align="right">Price per Unit(&pound;/mo)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {formik.values.tieredPrice.map((price, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                {index + 1 === 7 ? "7+" : index + 1}
              </TableCell>
              <TableCell align="right" style={{ display: "flex" }}>
                <input
                  type="text"
                  name={`tieredPrice[${index}]`}
                  placeholder={"Price"}
                  className="form-control"
                  value={formik.values.tieredPrice[index] || ""}
                  onChange={(e) => {
                    formik.setFieldValue(
                      `tieredPrice[${index}]`,
                      e.target.value
                    );
                  }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {getFieldError("tieredPrice").message && (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block">
            {" "}
            {getFieldError("tieredPrice").message}
          </div>
        </div>
      )}
      {/* <div>
        <label>Number of Units</label>
        {formik.values.tieredPrice.map((item, index) => {
          return (
            <>
              <input
                className="form-control w-30 mt-1"
                disabled={
                  formik.values.tieredPrice[index].up_to === "inf"
                    ? true
                    : false
                }
                value={
                  formik.values.tieredPrice[index].up_to === "inf"
                    ? "∞"
                    : formik.values.tieredPrice[index].up_to
                }
              ></input>
            </>
          );
        })}
      </div>
      <div>
        <label>Unit Amount (&pound;)</label>
        {formik.values.tieredPrice.map((item, index) => {
          return (
            <>
              <input
                className="form-control w-30 mt-1"
                placeholder="&pound;"
              ></input>
            </>
          );
        })}
      </div> */}
    </div>
  );
};

export default TieredPriceAmount;
