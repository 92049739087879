import React, { useState } from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import { useIntl } from "react-intl";
import { capitalizeFirstLetter } from "../../../../../utils/canvaCloseFunction";
import { WorkingDaysCanva } from "../franchise-canva/WorkingDaysCanva";
import { useSelector, shallowEqual } from "react-redux";

export const WorkingDays = () => {
  const intl = useIntl();
  const [canva, setCanva] = useState(false);
  const { workingDays } = useSelector(
    (state) => ({
      workingDays: state.franchise.workingDays[0],
    }),
    shallowEqual
  );

  return (
    <div className="card card-custom  gutter-b mb-10">
      <div className="card-body">
        <div className="d-flex justify-content-between align-items-center w-100 ">
          <h3 className="card-title p-0 m-0 ">
            <span className="font-weight-bolder text-dark">
              {intl.formatMessage({ id: "Working.Days" })}
            </span>
          </h3>
          <span
            className="svg-icon svg-icon-sm svg-icon-primary ml-3"
            onClick={() => setCanva(true)}
            style={{ cursor: "pointer" }}
          >
            <SVG
              style={{ color: "orange" }}
              title="Edit"
              className="h-22 align-self-end"
              src={toAbsoluteUrl("/media/svg/icons/Design/Edit.svg")}
            ></SVG>
          </span>
        </div>
        <div className="row mt-4">
          {Object.entries(workingDays?.days || {}).map(
            ([keys, value], index) => {
              const day = capitalizeFirstLetter(keys);
              return (
                <div className="col-md-3 mb-5">
                  <div>
                    <strong>{intl.formatMessage({ id: day })}</strong>{" "}
                  </div>
                  <div className="text-muted">
                    {value === 1
                      ? intl.formatMessage({ id: "Open" })
                      : intl.formatMessage({ id: "Close" })}
                  </div>
                </div>
              );
            }
          )}
        </div>
      </div>
      {canva && (
        <>
          <WorkingDaysCanva workingDays={workingDays} setCanva={setCanva} />
          <div
            className="offcanvas-overlay"
            onClick={() => setCanva(false)}
          ></div>
        </>
      )}
    </div>
  );
};
