import { createSlice } from "@reduxjs/toolkit";

const initialProductsState = {
  list: {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    productForEdit: undefined,
    lastError: null,
  },
  detail: {},
  depotCount: {},
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const depotSlice = createSlice({
  name: "depot",
  initialState: initialProductsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.list.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.list.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getProductById
    productFetched: (state, action) => {
      state.actionsLoading = false;
      state.productForEdit = action.payload.productForEdit;
      state.error = null;
    },
    // findProducts
    productsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.list.listLoading = false;
      state.list.error = null;
      state.list.entities = entities;
      state.list.totalCount = totalCount;
    },
    depotListCountFetched: (state, action) => {
      const { totalCount } = action.payload;

      state.depotCount.totalCount = totalCount;
    },
    depotDetailFetched: (state, action) => {
      const { data } = action.payload;
      state.detail = data;
    },
    franchiseUsingDepotFetched: (state, action) => {
      const { data } = action.payload;
      state.franchiseUsingDepot = data;
    },
    franchiseDepotListFetched: (state, action) => {
      const { data } = action.payload;
      state.franchiseDepotList = data;
    },
    franchiseDepotListEmpty: (state, action) => {
      state.franchiseDepotList = {};
    },
    // createProduct
    productCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.product);
    },
    // updateProduct
    productUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.product.id) {
          return action.payload.product;
        }
        return entity;
      });
    },
    // deleteProduct
    productDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },
    // deleteProducts
    productsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
    },
    // productsUpdateState
    productsStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
  },
});
