import React, { useEffect } from "react";
import DatePicker from "react-datepicker";
// import { useFormik } from "formik";
import moment from "moment";
// import { calendarTest } from "../../../pages/redux/commonCrud";
import { FormControl, MenuItem, Select } from "@material-ui/core/";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
// import SVG from "react-inlinesvg";
// import { Form } from "react-bootstrap";
// import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { CalendarComponent } from "./CalendarComponent";
import { DropOff } from "./DropOff";
import { dealsFranchiseListing } from "../../ContactDeals/_redux/deals/dealsCrud";
import { searchBookingList } from "../redux/calendarCrud";
import { InputGroup } from "react-bootstrap";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import { useIntl } from "react-intl";
import { CalendarContainer } from "./CalendarContainer";
// import DateRangeIcon from "@material-ui/icons/DateRange";

export const CalendarDetail = ({
  keys,
  state,
  value,
  initialValues,
  setCanva,
  setFormData,
  formData,
  actionButton,
  setActionButton,
  setCalendarData,
  calendarData,
  handleApi,
  setFranchiseList,
  franchiseList,
  setSearchBooking,
  searchBooking,
  setToggleCalendar,
  toggleCalendar,
  keyCon,
  setContainerData,
  keyC,
  containerData,
}) => {
  const intl = useIntl();

  // const handleSubmit = (values) => {};
  // const formik = useFormik({
  //   initialValues: initialValues,
  //   enableReinitialize: true,
  //   onSubmit: handleSubmit,
  // });

  useEffect(() => {
    dealsFranchiseListing().then((res) => {
      setFranchiseList(res.data.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formData?.date && formData?.franchise && keyC !== "profile") {
      console.log("admin-1", formData?.date);
      handleApi("11");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData?.date]);
  useEffect(() => {
    if (formData?.date && formData?.franchise && keyC !== "profile") {
      console.log("admin-22",  actionButton);
      handleApi("12");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionButton]);
  useEffect(() => {
    if (formData?.date && formData?.franchise && keyC !== "profile") {
      console.log("admin-33",formData?.franchise);
      handleApi("13");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData?.franchise]);

  useEffect(() => {
    if (
      (containerData?.startOfWeek || formData?.date) &&
      formData?.franchise &&
      keyC === "profile"
    ) {
      console.log("admin-2");
      handleApi("2");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerData?.startOfWeek, formData?.franchise]);

  const handleSearchList = (value) => {
    if (value?.length > 2) {
      searchBookingList(value).then((res) => {
        setSearchBooking(res.data.data);
      });
    }
  };

  return (
    <div className=" px-10 ">
      <div className="row">
        <div className="col-sm-5">
          <div className=" collection-form mt-2 ">
            <div className="form-inline mb-5">
              <span className="mr-2 ">
                {intl.formatMessage({ id: "Franchise" })}
              </span>{" "}
              <FormControl fullWidth>
                <Select
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                  className="form-control w-100 "
                  IconComponent={(props) => (
                    <i {...props}>
                      <KeyboardArrowDownIcon />
                    </i>
                  )}
                  inputProps={{ "aria-label": "Without label" }}
                  displayEmpty
                  value={formData.franchise || ""}
                  onChange={(e) => {
                    setFormData({ ...formData, franchise: e.target.value });
                    setToggleCalendar(true);
                  }}
                >
                  <MenuItem value="">
                    {intl.formatMessage({ id: "Franchise" })}
                  </MenuItem>
                  {franchiseList.map((data, index) => (
                    <MenuItem key={index} value={data?.id}>
                      {data?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div className="ml-4">
                <button
                  type="button"
                  className={`btn  ${
                    toggleCalendar === true ? "btn-primaryCus" : "btn-dark"
                  }  font-weight-bolder font-size-sm mr-3 `}
                  onClick={(e) => setToggleCalendar(!toggleCalendar)}
                >
                  Calendar
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-7 hello ">
          <div className="product-search calendar-search">
            <InputGroup>
              <Autocomplete
                disabled={!formData?.franchise}
                // autoComplete={false}
                fullWidth
                // loading={searchProduct.loading}
                // filterSelectedOptions
                onChange={(e, q) =>
                  setFormData({
                    ...formData,
                    date: moment(q.collection_date).format("yyyy-MM-DD"),
                  })
                }
                onInputChange={(event, newInputValue) => {
                  handleSearchList(newInputValue);
                }}
                // inputValue={}
                id="controllable-states-demo"
                options={searchBooking || []}
                getOptionLabel={(option) => {
                  return `${option?.booking_reference} ${" | "}${option?.user
                    ?.first_name || "-"} ${option?.user?.last_name || "-"}`;
                }}
                style={{ width: 270 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label={"Search Customer | Order ID ( 3 min characters )"}
                    variant="outlined"
                    className="form-control fontCustome"
                    style={{ fontSize: "1px" }}
                    //   onChange={(e) => setSelectV(true)}
                  />
                )}
              />
            </InputGroup>
          </div>
        </div>
        <div className="col-xxl-4 col-xl-6 col-sm-12 mt-2">
          <div className="row">
            <div className="col-sm-12">
              <div
                className="mb-4 d-flex "
                style={{ justifyContent: "space-between" }}
              >
                <div>
                  {/* <button
                    className={`btn ${
                      actionButton.type === "booking"
                        ? "btn-primaryCus"
                        : "btn-dark"
                    } font-weight-bolder font-size-sm mr-3 `}
                    onClick={() =>
                      setActionButton({ ...actionButton, type: "booking" })
                    }
                    type="button"
                  >
                    {intl.formatMessage({ id: "Booking" })}
                  </button> */}
                  {/* <button
                    className={`btn ${
                      actionButton.type === "operation"
                        ? "btn-primaryCus"
                        : "btn-dark"
                    } font-weight-bolder font-size-sm mr-3 `}
                    type="button"
                    onClick={() =>
                      setActionButton({ ...actionButton, type: "operation" })
                    }
                  >
                    {intl.formatMessage({ id: "Operation" })}
                  </button> */}
                  {/* <button
                    type="button"
                    className={`btn  ${
                      toggleCalendar === true ? "btn-primaryCus" : "btn-dark"
                    }  font-weight-bolder font-size-sm mr-3 `}
                    onClick={(e) => setToggleCalendar(!toggleCalendar)}
                  >
                    Calendar
                  </button> */}
                </div>
              </div>
            </div>
            {toggleCalendar ? (
              <div className="col-sm-12 collection-date">
                <DatePicker
                  className="form-control"
                  dateFormat="dd-MM-Y"
                  selected={
                    formData?.date ? Date.parse(formData?.date) : new Date()
                  }
                  onChange={(date) =>
                    setFormData({
                      ...formData,
                      date: moment(date).format("yyyy-MM-DD"),
                    })
                  }
                  placeholderText={intl.formatMessage({
                    id: "Select.Collection.Date",
                  })}
                  inline
                  // minDate={moment().toDate()}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="mt-4 mb-4">
        <div
          className="mb-4 d-flex "
          style={{ justifyContent: "space-between" }}
        >
          <div class="row">
            {/* <div class="col-sm-5 d-flex ">
              <span class="grey"></span>&nbsp;
              <span className="lne">Collection</span>
              {keyCon === "container" &&<>&nbsp;

              <span class="grey1"></span>&nbsp;
              <span className="lne">MoveIn</span>
              </> }
            </div> */}
            <div class="col-12 d-flex ">
              <div class={keyCon === "container" ? "col-4" : "col-6"}>
                <span class="grey"></span>&nbsp;
                <span className="lne">Collection</span>
              </div>
              <div class="col-4">
                {keyCon === "container" && (
                  <>
                    &nbsp;
                    <span class="grey1"></span>&nbsp;
                    <span className="lne">MoveIn</span>
                  </>
                )}
              </div>
              <div class={keyCon === "container" ? "col-4" : "col-6"}>
                <span class="OB"></span>&nbsp;
                <span className="lne">Return</span>
              </div>
              <div class="col-4">
                {keyCon === "container" && (
                  <>
                    &nbsp;
                    <span class="OB1"></span>&nbsp;
                    <span className="lne">MoveOut</span>
                  </>
                )}
              </div>
            </div>

            {/* <div class="col-sm-4 d-flex ">
              <i
                class="fa fa-exclamation-circle "
                style={{ color: "#544b4b" }}
              ></i>
              &nbsp;
              <span className="lne">In Arrears</span>
            </div> */}
          </div>
          <div>
            {keyCon !== "container" && (
              <>
                <button
                  className={`btn ${"btn-primaryCus"} font-weight-bolder font-size-sm mr-3 `}
                  onClick={() => setCanva({ type: true, title: "travel" })}
                >
                  {intl.formatMessage({ id: "Travel" })}
                </button>
                <button
                  className={`btn ${"btn-dark"} font-weight-bolder font-size-sm mr-3 `}
                  onClick={() =>
                    setCanva({ type: true, title: "blocking", check: "van" })
                  }
                >
                  Van Blocking
                </button>
                <button
                  className={`btn ${"btn-dark"} font-weight-bolder font-size-sm mr-3 `}
                  onClick={() =>
                    setCanva({
                      type: true,
                      title: "blocking",
                      check: "warehouse",
                    })
                  }
                >
                  Warehouse Blocking
                </button>
              </>
            )}
          </div>
        </div>
        {calendarData && (
          <>
            {" "}
            {keyCon === "container" ? (
              <CalendarContainer
                type={"calendar"}
                collectionDate={Date.parse(formData?.date)}
                calendarData={calendarData}
                keys={keys}
                setActionButton={setActionButton}
                actionButton={actionButton}
                setFormData={setFormData}
                formData={formData}
                keyCon={keyCon}
                setContainerData={setContainerData}
              />
            ) : (
              <CalendarComponent
                type={"calendar"}
                collectionDate={Date.parse(formData?.date)}
                calendarData={calendarData}
                keys={keys}
                setActionButton={setActionButton}
                actionButton={actionButton}
                setFormData={setFormData}
                formData={formData}
                keyCon={keyCon}
                setContainerData={setContainerData}
              />
            )}
            {keyCon !== "container" && (
              <DropOff
                type={"calendar"}
                collectionDate={Date.parse(formData?.date)}
                calendarData={calendarData}
                keys={keys}
                setFormData={setFormData}
                formData={formData}
                setActionButton={setActionButton}
                actionButton={actionButton}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};
