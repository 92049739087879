import axios from "axios";
export const CREATE_TRAVEL_BLOCKING = "calendar/block-travel-calendar";
export const OPERATION_BOOKING = "calendar/get-operation-calendar?";
export const TIME_SLOT_UPDATE = "calendar/order-time-slots-update";
export const UNBLOCK = "calendar/unblock-time-slots";
export const SEARCH_BOOKING =
  "calendar/search-orders-on-calendar?search_keyword=";
export const PAY = "order/order-invoice-status?order_id=";
export const Container = "calendar/container-availability?"


export function containerCalendar(data) {
  return axios.get(
    `${process.env.REACT_APP_SITE_URL +
      Container +
      `franchise_id=${data.id}&start_date=${data.start_date}&end_date=${data.end_date}`}`
  );
}

export function createTravelBlocking(data) {
  return axios.post(
    `${process.env.REACT_APP_SITE_URL + CREATE_TRAVEL_BLOCKING}`,
    data
  );
}

export function operationCalendar(data) {
  return axios.get(
    `${process.env.REACT_APP_SITE_URL +
      OPERATION_BOOKING +
      `franchise_id=${data.id}&date=${data.date}&pod=${data.pod}&is_overwrite=${
        data.is_overwrite ? true : false
      }${data.search_keyword ? "&search_keyword=" + data.search_keyword : ""}${data?.is_calendar ? "&is_calendar="+data?.is_calendar :""}`}`
  );
}

export function timeSlotUpdate(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + TIME_SLOT_UPDATE, data);
}
export function unblockSlot(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + UNBLOCK, data);
}

export function searchBookingList(value) {
  return axios.get(process.env.REACT_APP_SITE_URL + SEARCH_BOOKING + value);
}

export function paymentDue(value, abortController) {
  return axios.get(process.env.REACT_APP_SITE_URL + PAY + value, {
    signal: abortController?.signal,
  });
}
