/* eslint-disable no-restricted-imports */
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import SnackBarTool from "../../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
// import { excemptPrice, requestPayment } from "../../../_redux/order/orderCrud";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import { fetchMyOrderDetailCard } from "../../../../Order/_redux/order/orderAction";
import { fetchClassList, fetchFranchisesDetail } from "../../../_redux/franchises/franchisesActions";
import { useFormik } from "formik";
import { createClass, setStairStepPrice } from "../../../_redux/franchises/franchisesCrud";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
const useStyles = makeStyles({
  table: {
    minWidth: 400,
  },
});

const StairStepModal = ({ dialog, setDialog, orderId , fraId , edit }) => {
  const classes = useStyles();

  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [loading , setLoading] = useState(false)
  const intl = useIntl();
  const dispatch = useDispatch();
  const { classList, classLoading } = useSelector(
    (state) => ({
      classList: state.franchise.classList,
      classLoading: state.franchise.classLoading,
    }),
    shallowEqual
  );
  const initialValues = {
    franchise_id: fraId || "",
    plan_type: dialog?.plan_type || "",
    tiers: dialog?.data && dialog.data.length > 0
    ? [
        ...dialog.data,
        ...Array.from({ length: Math.max(7 - dialog.data.length, 0) }, (_, index) => ({ tier: dialog.data.length + index + 1, price: '' }))
      ]
    : Array.from({ length: 7 }, (_, index) => ({ tier: index + 1, price: '' })),
};
  const handleSubmit = (values) => {
    setLoading(true)
    setSnack({ call: false, type: "", message: "" });
    setStairStepPrice(values)
      .then((res) => {
        setLoading(false)
    dispatch(fetchFranchisesDetail(values?.franchise_id));
        
        setSnack({ call: true, type: "success", message: res.data.message });
        setTimeout(() => {
            setDialog(false)
        }, 3000);
      })
      .catch((error) => {
        setLoading(false)
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
  };


  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  return (
    <Modal show={dialog.open}  aria-labelledby="example-modal-sizes-title-lg">
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">Stairstep pricing breakdown
        <div style={{margin:"-2% 0 2% 0"}}>

<span className="text-dark" style={{fontSize:"12px" , fontWeight:"400"}}>Prices displayed below are without the 50% discount</span>
</div> </Modal.Title>
        
      </Modal.Header>
      <Modal.Body style={{marginTop:"-5%"}}>
        <div>
        <div>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Pod (Units)</TableCell>
                <TableCell align="right">Price per Unit(&pound;/mo)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {formik.values.tiers.map((tier, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                   {index + 1}{index == 6?"+":""}
                  </TableCell>
                  <TableCell align="right" style={{display:"flex"}}>
                  
                    <input
                      type="text"
                      name={`tiers[${index}][price]`}
                      placeholder={"Price"}
                      className="form-control"
                      value={tier.price || ""}
                      onChange={formik.handleChange}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
                    </div>
 
          
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={() => setDialog(false)}
            className="btn btn-light btn-elevate"
          >
            Close
          </button>
          <> </>
          <button
                type="button"
                className="btn btn-delete btn-elevate"
                onClick={() => formik.submitForm()}
                disabled={loading}
              >
                {"ADD"}
                {loading && <span className="ml-1 spinner spinner-white"></span>}
              </button>
         
        </div>
      </Modal.Footer>
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </Modal>
  );
};

export default StairStepModal;
