/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

import * as Yup from "yup";

import React, { useEffect, useState } from "react";

import ActiveDialogModal from "../../../../../../_metronic/_partials/widgets/alert/ActiveDialogModal";
import { CountrySchema } from "../country-form/validateSchema";
import SnackBarTool from "../../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { addCountry } from "../../../_redux/country/countryCrud";
import { canvaCancel } from "../../../../../utils/canvaCloseFunction";
import countryApi from "../../../../../utils/countryApi.json";
import { fetchCountryList } from "../../../_redux/country/countryAction";
import { initialFilter } from "../ContainerUIHelpers";
import languageByCountryApi from "../../../../../utils/languageByCountryApi.json";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { useIntl } from "react-intl";
import ContainerForm from "../country-form/containerForm";
import { dealsFranchiseListing } from "../../../../ContactDeals/_redux/deals/dealsCrud";
import { createContainer } from "../../../_redux/container/containerCrud";
import { fetchContainerList } from "../../../_redux/container/containerAction";

export function NewContainerCanva({ openCanva, setOpenCanva }) {
  const intl = useIntl();
  const dispatch = useDispatch();
  //country list json
  const [active, setActive] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [formData, setFormData] = useState();
  const [languageData, setLanguageData] = useState([]);
  const [list, setList] = useState([]);
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });

  const initialValues = {
    franchise_id: openCanva.data?.franchise_id || "",
    container_size: openCanva.data?.container_size || "",
    deposit_value: openCanva.data?.deposit_value || "",
    return_fee: openCanva.data?.return_fee || "",
    collection_fee: openCanva.data?.collection_fee || "",
  };

  // Custom hook for handling form submission
  const handleSubmit = (values, { resetForm }) => {
    // Resets the snackbar state
    setSnack({ call: false, type: "", message: "" });

    // Calls the addCountry function and displays a success or error message in the snackbar
    createContainer(
      values,
      openCanva.data?.id ? `/${openCanva.data?.id}?_method=PATCH` : ""
    )
      .then((res) => {
        setSnack({ call: true, type: "success", message: res.data.message });
        dispatch(fetchContainerList(initialFilter));
        setOpenCanva({ check: false });
        // handleClose();
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
  };

  const handleReset = () => {};

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    onReset: handleReset,
  });

  // Custom hook that handles the selection of a country
  const handleSelectCountry = async (e) => {
    let data = countryApi.find((element) => element.name.common === e);
    // Sets the form data and formik field values for the selected country
    setFormData(data);
    formik.setFieldValue("name", e);
    formik.setFieldValue("short_name", data.cca3, false);

    await formik.setFieldValue(
      "currency",
      data && data.currencies && data.currencies.length
        ? data.currencies[0].name
        : ""
    );
    if (data.timezones.length === 1) {
      formik.setFieldValue("timezone", data.timezones[0]);
    } else {
      await formik.setFieldValue("timezone", "");
    }
  };

  useEffect(() => {
    if (openCanva.check) {
      dealsFranchiseListing().then((res) => {
        setList(res.data.data);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openCanva.check]);

  const handleClose = () => {
    // formik.resetForm();

    setOpenCanva(false);
  };

  return (
    <div
      id="kt_new_Container_panel"
      className={`offcanvas offcanvas-right pt-5 pb-10 offcanvas-on `}
    >
      {/*begin::Header*/}
      <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
        <div
          className="offcanvas-close mt-n1 pr-5"
          style={{ position: "absolute", top: "15px", right: "10px" }}
        >
          <span
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            // id="kt_new_Container_panel_close"
            onClick={handleClose}
          >
            <i className="ki ki-close icon-xs text-muted"></i>
          </span>
        </div>
      </div>

      {/*end::Header*/}

      {/*begin::Content*/}
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <div className="offcanvas-content px-10">
          <div>
            <h5>Container</h5>
          </div>
          <div className="tab-content">
            <div
              id="kt_quick_panel_logs_kt_new_Container_panel"
              role="tabpanel"
              className={`tab-pane fade pt-3 pr-5 mr-n5 scroll  active show `}
            >
              <ContainerForm
                intl={intl}
                formik={formik}
                countryApi={countryApi}
                handleSelectCountry={handleSelectCountry}
                languageData={languageData}
                formData={formData}
                setDialog={setDialog}
                list={list}
              />
            </div>
          </div>
        </div>
        <ActiveDialogModal
          active={active}
          setActive={setActive}
          dialog={dialog}
          setDialog={setDialog}
          formik={formik}
        />
        <div className="offcanvas-footer">
          <div className="offcanvas-content text-right ">
            <button
              type="reset"
              // id="kt_new_Container_panel_cancel"
              className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
              onClick={handleClose}
            >
              {intl.formatMessage({ id: "CANCEL" })}
            </button>
            <button
              type="submit"
              className="btn btn-primary font-weight-bolder font-size-sm "
            >
              {intl.formatMessage({ id: "SAVE" })}
            </button>
          </div>
        </div>
      </form>
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </div>
  );
}
