import React from "react";
import { ProductsCard } from "./ProductsCard";
import { ProductsUIProvider } from "./ProductsUIContext";

export function ProductsPage({ history }) {
  const productsUIEvents = {
    openUpdateProductsStatusDialog: () => {
      history.push("/settings/products/updateStatus");
    },
  };

  return (
    <>
      <ProductsUIProvider productsUIEvents={productsUIEvents}>
        <ProductsCard />
      </ProductsUIProvider>
    </>
  );
}
