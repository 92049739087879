/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

import * as Yup from "yup";

import React, { useState } from "react";

import { Form } from "react-bootstrap";
import SnackBarTool from "../../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { VanSchema } from "../franchise-form/validateSchema";
import { canvaCancel } from "../../../../../utils/canvaCloseFunction";
import { fetchFranchisesVan } from "../../../_redux/franchises/franchisesActions";
import { franchiseEditVan } from "../../../_redux/franchises/franchisesCrud";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { useIntl } from "react-intl";

export function EditVan({ type, data }) {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [snack, setSnack] = useState({ call: false, type: "", message: "" });

  const initialValues = {
    franchise_id: data && data.franchise_id ? data.franchise_id : "",
    make: data && data.make ? data.make : "",
    model: data && data.model ? data.model : "",
    van_name: data && data.van_name ? data.van_name : "",
    registration: data && data.registration ? data.registration : "",
    no_of_pods: data && data.no_of_pods ? data.no_of_pods : "",
  };

  const handleSubmit = (values, actions) => {
    setSnack({ call: false, type: "", message: "" });
    franchiseEditVan(values, data.id)
      .then((res) => {
        if (res.data.success) {
          dispatch(fetchFranchisesVan(data.franchise_id));
          setSnack({ call: true, type: "success", message: res.data.message });
          canvaCancel(`kt_editvan_panel${type}_close`);
        } else {
          setSnack({ call: true, type: "error", message: res.data.message });
        }
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
  };

  const handleReset = () => {};

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: VanSchema(intl, Yup),
    onSubmit: handleSubmit,
    onReset: handleReset,
  });

  return (
    <div
      id={`kt_editvan_panel${type}`}
      className="offcanvas offcanvas-right pt-5 pb-10 offcanvas-rightss"
    >
      {/*begin::Header*/}
      <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
        <div
          className="offcanvas-close mt-n1 pr-5"
          style={{ position: "absolute", top: "15px", right: "10px" }}
        >
          <a
            href="#"
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            id={`kt_editvan_panel${type}_close`}
          >
            <i className="ki ki-close icon-sm text-muted"></i>
          </a>
        </div>
      </div>
      {/*end::Header*/}
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <div className="offcanvas-content px-10 ">
          <div>
            <h5>{intl.formatMessage({ id: "Edit.Van" })}</h5>
          </div>
          <div
            id="kt_quick_panel_logs_kt_addvan_panel"
            className="fade pt-3 pr-5 mr-n5 scroll active show ps--active-y mt-10"
          >
            <div className="mt-10 pb-10">
              <div className="row">
                <div className="col-md-4">
                  <Form.Group className="mb-5">
                    <Form.Label>
                      {intl.formatMessage({ id: "Make" })}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      maxLength={"30"}
                      placeholder={intl.formatMessage({ id: "Make" })}
                      className={`form-control  ${getInputClasses("make")}`}
                      name="make"
                      {...formik.getFieldProps("make")}
                    />
                    {formik.touched.make && formik.errors.make ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.make}
                        </div>
                      </div>
                    ) : null}
                  </Form.Group>
                </div>
                <div className="col-md-4">
                  <Form.Group className="mb-5">
                    <Form.Label>
                      {intl.formatMessage({ id: "Model" })}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      maxLength={"30"}
                      placeholder={intl.formatMessage({ id: "Model" })}
                      className={`form-control  ${getInputClasses("model")}`}
                      name="model"
                      {...formik.getFieldProps("model")}
                    />
                    {formik.touched.model && formik.errors.model ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.model}
                        </div>
                      </div>
                    ) : null}
                  </Form.Group>
                </div>
                <div className="col-md-4">
                  <Form.Group className="mb-5">
                    <Form.Label>
                      {intl.formatMessage({ id: "Van.Name" })}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      maxLength={"30"}
                      placeholder={intl.formatMessage({ id: "Van.Name" })}
                      className={`form-control  ${getInputClasses("van_name")}`}
                      name="van_name"
                      {...formik.getFieldProps("van_name")}
                    />
                    {formik.touched.van_name && formik.errors.van_name ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.van_name}
                        </div>
                      </div>
                    ) : null}
                  </Form.Group>
                </div>
                <div className="col-md-4">
                  <Form.Group className="mb-5">
                    <Form.Label>
                      {intl.formatMessage({ id: "Registration" })}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      maxLength={"30"}
                      placeholder={intl.formatMessage({ id: "Registration" })}
                      className={`form-control  ${getInputClasses(
                        "registration"
                      )}`}
                      name="registration"
                      {...formik.getFieldProps("registration")}
                    />
                    {formik.touched.registration &&
                    formik.errors.registration ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.registration}
                        </div>
                      </div>
                    ) : null}
                  </Form.Group>
                </div>
                <div className="col-md-4">
                  <Form.Group className="mb-5">
                    <Form.Label>
                      {intl.formatMessage({ id: "#.of.Pods" })}
                    </Form.Label>
                    <Form.Control
                      type="string"
                      maxLength={"30"}
                      placeholder={intl.formatMessage({ id: "#.of.Pods" })}
                      className={`form-control  ${getInputClasses(
                        "no_of_pods"
                      )}`}
                      name="no_of_pods"
                      {...formik.getFieldProps("no_of_pods")}
                    />
                    {formik.touched.no_of_pods && formik.errors.no_of_pods ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.no_of_pods}
                        </div>
                      </div>
                    ) : null}
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="offcanvas-footer">
          <div className="offcanvas-content text-right ">
            <button
              type="reset"
              className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
              onClick={() => canvaCancel(`kt_editvan_panel${type}_close`)}
            >
              {intl.formatMessage({ id: "CANCEL" })}
            </button>
            <button
              type="submit"
              className="btn btn-primary font-weight-bolder font-size-sm "
            >
              {intl.formatMessage({ id: "SAVE" })}
            </button>
          </div>
        </div>
      </form>

      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </div>
  );
}
