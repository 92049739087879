import * as requestFromServer from "./containerCrud";
import { containerSlice, callTypes } from "./containerSlice";

const { actions } = containerSlice;
//This Function is for fetching active Container
export const fetchContainerFranchise = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getContainerFranchise(queryParams)
    .then((response) => {
      const { data, meta } = response.data;

      dispatch(actions.ContainerFetchedFranchise({ data: data, totalCount: meta.total, }));

    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchContainerList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.listAll }));
  return requestFromServer
    .containerList(queryParams)
    .then((response) => {
      const { meta, data } = response.data;

      dispatch(
        actions.ContainerFetchedList({
          totalCount: meta.total,
          entities: data,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.listAll }));
    });
};


