import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import React, { Fragment, useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";

import DealCard from "../Card/DealCard";
import NoRecord from "../../../../_metronic/_partials/widgets/NoRecord";
import SpinnerLoader from "../../../../_metronic/_partials/widgets/spinner";
import { dealStatus } from "../_redux/deals/dealsCrud";
import { useIntl } from "react-intl";

const DealsGrid = ({ entities }) => {
  const intl = useIntl();
  const [items, setItems] = useState();

  const { currentState, permission } = useSelector(
    (state) => ({
      currentState: state.deals_list.list,
      permission: state.auth.permission,
    }),
    shallowEqual
  );

  const onDragEnd = (result) => {
    if (result && result.destination && result.destination.droppableId) {
      const currentId = items.findIndex(
        (element) => element.id === Number(result.draggableId)
      );

      let arr = [...items];
      let temp_element = { ...arr[currentId] };

      temp_element.deal_status = result.destination.droppableId;
      arr.splice(currentId, 1);
      arr.unshift(temp_element);
      setItems(arr);
      const data = {
        id: result.draggableId,
        status: result.destination.droppableId,
      };
      dealStatus(data).then((res) => {});
    }
  };

  useEffect(() => {
    if (!currentState.loading) {
      setItems(entities);
    }
  }, [currentState.loading, entities, entities.length]);

  return (
    <>
      {currentState.loading ? (
        <SpinnerLoader />
      ) : (
        <>
          {items && items.length ? (
            <DragDropContext onDragEnd={onDragEnd}>
              <div
                className="row"
                style={{
                  pointerEvents:
                    permission?.deal.create || permission?.deal.create
                      ? ""
                      : "none",
                }}
              >
                <div className="col-md-12">
                  <div className="row deals-grid">
                    <div className="col-12 col-md-3">
                      <div className="kanban-board">
                        <div className="kanban-board-header">
                          <header>
                            <div className="kanban-title-board">
                              {intl.formatMessage({ id: "Open" })}
                            </div>
                          </header>
                        </div>

                        <Droppable droppableId="Open">
                          {(provided) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {items && items.length
                                ? items.map((item, index) => (
                                    <Fragment key={index}>
                                      {item.deal_status === "Open" ? (
                                        <Draggable
                                          key={String(item.id)}
                                          draggableId={String(item.id)}
                                          index={index}
                                        >
                                          {(provided, snapshot) => (
                                            <div
                                              ref={provided.innerRef}
                                              snapshot={snapshot}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              className="kanban-drag"
                                            >
                                              <DealCard item={item} />
                                            </div>
                                          )}
                                        </Draggable>
                                      ) : (
                                        ""
                                      )}
                                    </Fragment>
                                  ))
                                : ""}
                            </div>
                          )}
                        </Droppable>
                      </div>
                    </div>
                    <div className="col-12 col-md-3">
                      <div className="kanban-board">
                        <div className="kanban-board-header">
                          <header>
                            <div className="kanban-title-board">
                              {intl.formatMessage({ id: "Transit" })}
                            </div>
                          </header>
                        </div>

                        <Droppable droppableId="Transit">
                          {(provided) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {items && items.length
                                ? items.map((item, index) => (
                                    <Fragment key={index}>
                                      {item.deal_status === "Transit" ? (
                                        <Draggable
                                          key={String(item.id)}
                                          draggableId={String(item.id)}
                                          index={index}
                                        >
                                          {(provided, snapshot) => (
                                            <div
                                              ref={provided.innerRef}
                                              snapshot={snapshot}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              className="kanban-drag"
                                            >
                                              <DealCard item={item} />
                                            </div>
                                          )}
                                        </Draggable>
                                      ) : (
                                        ""
                                      )}
                                    </Fragment>
                                  ))
                                : ""}
                            </div>
                          )}
                        </Droppable>
                      </div>
                    </div>
                    <div className="col-12 col-md-3">
                      <div className="kanban-board">
                        <div className="kanban-board-header">
                          <header>
                            <div className="kanban-title-board">
                              {intl.formatMessage({ id: "Pipeline" })}
                            </div>
                          </header>
                        </div>

                        <Droppable droppableId="Pipeline">
                          {(provided) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {items && items.length
                                ? items.map((item, index) => (
                                    <Fragment key={index}>
                                      {item.deal_status === "Pipeline" ? (
                                        <Draggable
                                          key={String(item.id)}
                                          draggableId={String(item.id)}
                                          index={index}
                                        >
                                          {(provided, snapshot) => (
                                            <div
                                              ref={provided.innerRef}
                                              snapshot={snapshot}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              className="kanban-drag"
                                            >
                                              <DealCard item={item} />
                                            </div>
                                          )}
                                        </Draggable>
                                      ) : (
                                        ""
                                      )}
                                    </Fragment>
                                  ))
                                : ""}
                            </div>
                          )}
                        </Droppable>
                      </div>
                    </div>
                    <div className="col-12 col-md-3">
                      <div className="kanban-board">
                        <div className="kanban-board-header">
                          <header>
                            <div className="kanban-title-board">
                              {intl.formatMessage({ id: "Close-Won" })}
                            </div>
                          </header>
                        </div>

                        <Droppable droppableId="Close-Won">
                          {(provided) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {items && items.length
                                ? items.map((item, index) => (
                                    <Fragment key={index}>
                                      {item.deal_status === "Close-Won" ? (
                                        <Draggable
                                          key={String(item.id)}
                                          draggableId={String(item.id)}
                                          index={index}
                                          // isDragDisabled
                                        >
                                          {(provided, snapshot) => (
                                            <div
                                              ref={provided.innerRef}
                                              snapshot={snapshot}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              className="kanban-drag"
                                            >
                                              <DealCard item={item} />
                                            </div>
                                          )}
                                        </Draggable>
                                      ) : (
                                        ""
                                      )}
                                    </Fragment>
                                  ))
                                : ""}
                            </div>
                          )}
                        </Droppable>
                      </div>
                    </div>
                    <div className="col-12 col-md-3">
                      <div className="kanban-board">
                        <div className="kanban-board-header">
                          <header>
                            <div className="kanban-title-board">
                              {intl.formatMessage({ id: "Close-Lost" })}
                            </div>
                          </header>
                        </div>

                        <Droppable droppableId="Close-Lost">
                          {(provided) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {items && items.length
                                ? items.map((item, index) => (
                                    <Fragment key={index}>
                                      {item.deal_status === "Close-Lost" ? (
                                        <Draggable
                                          key={String(item.id)}
                                          draggableId={String(item.id)}
                                          index={index}
                                          // isDragDisabled
                                        >
                                          {(provided, snapshot) => (
                                            <div
                                              ref={provided.innerRef}
                                              snapshot={snapshot}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              className="kanban-drag"
                                            >
                                              <DealCard item={item} />
                                            </div>
                                          )}
                                        </Draggable>
                                      ) : (
                                        ""
                                      )}
                                    </Fragment>
                                  ))
                                : ""}
                            </div>
                          )}
                        </Droppable>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </DragDropContext>
          ) : (
            <NoRecord />
          )}
        </>
      )}
    </>
  );
};

export default DealsGrid;
