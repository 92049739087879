import React, { useRef, useState } from 'react'
import Autocomplete from "@material-ui/lab/Autocomplete";
import { updateFranchise } from '../../_redux/order/orderCrud';
import { useDispatch } from 'react-redux';
import { fetchNewCustomerCard, fetchSearchNewOrder } from '../../_redux/order/orderAction';
import { TextField } from "@material-ui/core";



export const AutoCompleteComponent = ({ dispatchReducer, state, role, setDialogType, cardDetail, searchNewOrder, intl }) => {
    const dispatch = useDispatch()
    const controllerRef = useRef(new AbortController());
    const [debounceTimer, setDebounceTimer] = useState(null);

    const handleChange = (event, newValue) => {
        if (newValue.plan_type && newValue.plan_type.length > 0) {
            dispatchReducer({
                type: "SET_DATA",   
                data: { ...state, plan_type: newValue.plan_type, containerTypeSelect: "pod" },
            });
            if (newValue.plan_type.length < 2 && role && role?.length && role[0] !== "super-admin") {
                if (newValue.plan_type[0] === "pod") {
                    dispatchReducer({
                        type: "SET_DATA",
                        data: { ...state, containerTypeSelect: "pod" },
                    });
                }else{
                    dispatchReducer({
                        type: "SET_DATA",
                        data: { ...state, containerTypeSelect: "container" },
                    });
                }
            } else {
                // setDialogType({ open: true });
            }
        }
        if (newValue?.id) {
            const data = {
                contact_id: newValue?.contact_id,
                postcode: newValue?.postcode,
            };
            updateFranchise(data)
                .then(() => {
                    dispatch(
                        fetchNewCustomerCard(
                            newValue?.id,
                            cardDetail &&
                            cardDetail.length > -1 &&
                            cardDetail[0]?.contact?.franchise_id
                        )
                    );
                })

                .catch(() => {
                    dispatch(
                        fetchNewCustomerCard(
                            newValue?.id,
                            cardDetail &&
                            cardDetail.length > -1 &&
                            cardDetail[0]?.contact?.franchise_id
                        )
                    );
                });
        }
        localStorage.removeItem("selectedItems");
        localStorage.removeItem("selectedUserItems");
    };
    const handleInputChange = (newInputValue) => {
        dispatchReducer({
            type: "SET_DATA",
            data: { ...state, customerValue: newInputValue },
        });
        clearTimeout(debounceTimer);
        const timer = setTimeout(() => {
            dispatch(fetchSearchNewOrder(newInputValue, controllerRef?.current));
        }, 500); // Adjust the debounce delay as needed (in milliseconds)
        setDebounceTimer(timer);
    };
    const filterOptions = () => {
        const { customerValue } = state;
        if (customerValue && customerValue.trim() !== "") {
            return searchNewOrder?.length > 0 ? searchNewOrder : [];
        }
        return [];
    };
    const getLabel = (option) => {
        const firstName = option?.first_name || "-";
        const lastName = option?.last_name || "";
        const email = option?.email || "";
        const phone = option?.phone || "";

        return `${firstName} ${lastName} | ${email} | ${phone}`;
    };
    return (
        <Autocomplete
            loading={true}
            autoComplete={false}
            onChange={(event, newValue) =>
                handleChange(event, newValue)
            }
            onInputChange={(event, newInputValue) => {
                if (
                    newInputValue?.length > 3 &&
                    event?.type !== "click"
                ) {
                    handleInputChange(newInputValue);
                }
            }}
            id="controllable-states-demo"
            options={filterOptions()}
            getOptionLabel={(option) =>
                getLabel(option)
            }
            style={{ width: 350 }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={intl.formatMessage({
                        id: "Search.Contact/Deal/Customer",
                    })}
                    variant="outlined"
                    className="form-control"
                // onChange={(e) => setSelectV(true)}
                />
            )}
        />
    )
}
