import React, { useMemo } from "react";

import { Formik } from "formik";
import { isEqual } from "lodash";
import { useFranchisesUIContext } from "../FranchisesUIContext";
import { useIntl } from "react-intl";
import { FranchiseFilterForm } from "./FranchiseFilterForm";

const prepareFilter = (queryParams, values, filterCountry) => {
  const { searchText } = values;
  const newQueryParams = { ...queryParams };
  const filter = {};
  // Filter by status
  // filter.status = status !== "" ? +status : undefined;
  // Filter by condition
  // filter.condition = condition !== "" ? +condition : undefined;
  // Filter by all fields

  filter.search = searchText ? searchText : "";
  // filter.search_country = filterCountry ? filterCountry : "";

  newQueryParams.filter = filter;
  return newQueryParams;
};

export function FranchisesFilter(props) {
  const intl = useIntl();

  // const [check, setCheck] = useState(false);

  // const filterCountry =
  //   props && props.state && props.state.country ? props.state.country : "";

  // Products UI Context
  const franchisesUIContext = useFranchisesUIContext();
  const franchisesUIProps = useMemo(() => {
    return {
      setQueryParams: franchisesUIContext.setQueryParams,
      queryParams: franchisesUIContext.queryParams,
    };
  }, [franchisesUIContext]);

  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(franchisesUIProps.queryParams, values);
    if (!isEqual(newQueryParams, franchisesUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      franchisesUIProps.setQueryParams(newQueryParams);
    }
  };

  // useEffect(() => {
  //   if (!check) {
  //     applyFilter({ status: "" });
  //     setCheck(true);
  //   }
  // }, [applyFilter, check]);

  return (
    <>
      <Formik
        initialValues={{
          // status: "", // values => All=""/Selling=0/Sold=1
          // condition: "", // values => All=""/New=0/Used=1
          searchText: "",
          // search_country: filterCountry,
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <FranchiseFilterForm
              intl={intl}
              handleBlur={handleBlur}
              values={values}
              setFieldValue={setFieldValue}
              handleSubmit={handleSubmit}
            />
          </form>
        )}
      </Formik>
    </>
  );
}
