import React from "react";
import { Modal } from "react-bootstrap";
import { useIntl } from "react-intl";

const DynamicAlert = ({
  dialog,
  setDialog,
  header,
  mainText,
  handleChange,handleSendUrlLink
}) => {
    console.log("hello-123",dialog );
    
  const intl = useIntl();
  return (
    <Modal show={dialog.open} aria-labelledby="example-modal-sizes-title-lg">
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{header}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>{mainText}</span>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={() => setDialog({ open: false })}
            className="btn btn-light btn-elevate"
          >
            {intl.formatMessage({ id: "No" })}
          </button>
          <> </>
          <button
            type="button"
            onClick={() => {if(dialog?.type){
                handleSendUrlLink(dialog?.data, dialog?.type)
                return
            }
            handleChange(dialog?.data)}}
            className="btn btn-delete btn-elevate"
          >
            {intl.formatMessage({ id: "Yes" })}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default DynamicAlert;
