import { createSlice } from "@reduxjs/toolkit";

const initialQuotesState = {
  list: {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    productForEdit: undefined,
    lastError: null,
    loading: false,
  },
  searchTask: [],
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const qTasksSlice = createSlice({
  name: "quotes",
  initialState: initialQuotesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    //task list
    tasksQFetched: (state, action) => {
      const { totalCount, entities, loading } = action.payload;
      state.list.listLoading = false;
      state.list.error = null;
      state.list.entities = entities;
      state.list.totalCount = totalCount;
      state.list.loading = loading;
    },
    searchTask: (state, action) => {
      const { data } = action.payload;
      state.searchTask = data;
    },
  },
});
