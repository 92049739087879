import React, { useState } from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { PackagingMaterialListModal } from "../modals/PackagingMaterialListModal";
export const PostcodeSearch = ({
  state,
  dispatchReducer
}) => {
  const [dialog, setDialog] = useState({ check: false });
  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="form-groupB w-25 d-flex">

          {1 === 2 ? (
            <div className="mt-2">
              <span className="symbol-label ml-2">
                <span
                  className="svg-icon svg-icon-lg svg-icon-primary"
                // onClick={() => handleGetAddress()}
                >
                  <SVG
                    className="h-25 align-self-end"
                    style={{ cursor: "pointer" }}
                    title=" "
                    src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")}
                  ></SVG>
                </span>
              </span>
            </div>
          ) : (
            ""
          )}
        </div>
        <div>
          <button
            className="btn btn-secondary font-weight-bolder font-size-sm mr-1 "
            onClick={() => setDialog({ check: true })}
          >
            Packaging Material List
          </button>
        </div>
        <PackagingMaterialListModal
          dialog={dialog}
          setDialog={setDialog}
        // cardDetail={cardDetail}
        // handleChangeOrder={handleChangeOrder}
        dispatchReducer={dispatchReducer}
        state={state}
        />
      </div>
    </>
  )
}