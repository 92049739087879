import React from "react";
import { Modal } from "react-bootstrap";
import { useIntl } from "react-intl";

const DeleteDialogApi = ({
  dialog,
  setDialog,
  status,
  handleChange,
  title,
  deleteSite
}) => {
  const intl = useIntl();
  return (
    <Modal show={dialog.modal} aria-labelledby="example-modal-sizes-title-lg">
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          {intl.formatMessage({ id: "Delete" })} {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>
          {intl.formatMessage({ id: "Are.You.Sure.Delete" })} {title} ?
        </span>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={() => setDialog({ modal: false })}
            className="btn btn-light btn-elevate"
          >
            {intl.formatMessage({ id: "No" })}
          </button>
          <> </>
          <button
            type="button"
            onClick={(e) => {
              if(deleteSite){
                handleChange(dialog.data)
                return
              }
              handleChange(e)}}
            className="btn btn-delete btn-elevate"
          >
            {intl.formatMessage({ id: "Yes" })}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteDialogApi;
