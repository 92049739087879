/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { Modal } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { useFormik } from "formik";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
// import { fetchFranchisesDepot } from "../../Settings/_redux/franchises/franchisesActions";
import { accessMonitor } from "../_redux/order/orderCrud";
import SnackBarTool from "../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import {
  fetchMyOrderDetailCard,
  fetchMyOrderTimeline,
  fetchBookingAccessCharges,
  fetchPaymentInvoice,
} from "../_redux/order/orderAction";
import DiscardDialog from "../../../../_metronic/_partials/widgets/alert/DiscardDialog";
import { BookingAccessModalSchema } from "../order-form/validationSchema";
import { LoadingCustome } from "../../../../_metronic/_partials/controls/LoadingCustome";
const BookingAccessModal = ({
  header,
  mainText,
  dialog,
  setDialog,
  handleAction,
  children,
  orderDetail,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [dialogDis, setDialogDis] = useState(false);
  const initialValues = {
    order_id: orderDetail?.id || "",
    subscription_id: orderDetail?.subscription_id || "",
    price_id: "",
    qty: "",
  };
  const { accessFeeList } = useSelector(
    (state) => ({
      accessFeeList: state.order.bookingAccessCharges,
    }),
    shallowEqual
  );

  // useEffect(() => {
  //   if (orderDetail?.franchise_id) {
  //     dispatch(fetchFranchisesDepot(orderDetail?.franchise_id));
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [orderDetail?.franchise_id]);

  //Set first charge type selected on booking access
  // useEffect(() => {
  //   if (accessFeeList.length > 0) {
  //     formik.setFieldValue("price_id", accessFeeList[0]["id"]);
  //   }
  // }, [accessFeeList.length, dialog]);

  // Call on mount of componet to get Booking
  useEffect(() => {
    if (orderDetail?.id) {
      dispatch(
        fetchBookingAccessCharges(orderDetail?.id, orderDetail?.franchise_id)
      );
    }
  }, [dispatch, orderDetail?.id]);

  const handleSubmit = (values) => {
    setIsLoading(true);
    setSnack({ call: false, type: "", message: "" });
    accessMonitor(values)
      .then((res) => {
        setIsLoading(false);
        dispatch(fetchMyOrderDetailCard(orderDetail?.id));
        dispatch(fetchMyOrderTimeline(orderDetail?.id));
        dispatch(
          fetchPaymentInvoice(
            orderDetail?.id,
            orderDetail?.franchise_id,
            orderDetail?.subscription_id
          )
        );
        setSnack({ call: true, type: "success", message: res.data.message });
        setTimeout(() => {
          setDialog({ check: false });
          setSnack({ call: false, type: "", message: "" });
          formik.resetForm();
        }, 3000);
      })
      .catch((error) => {
        setIsLoading(false);
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: BookingAccessModalSchema,
    onSubmit: handleSubmit,
  });

  const handleClose = () => {
    if (formik.dirty && Object.keys(formik.touched).length !== 0) {
      setDialogDis(true);
    } else {
      formik.resetForm();
      setDialog({ check: false });
    }
    setSnack({ call: false, type: "", message: "" });
  };
  return (
    <Modal
      show={dialog.check}
      size="lg"
      aria-labelledby="example-modal-sizes-title-lg"
      onHide={handleClose}
    >
      <Modal.Header className="d-flex justify-content-between">
        <Modal.Title id="example-modal-sizes-title-lg">
          Access charge
        </Modal.Title>
        <span
          className="btn btn-xs btn-icon btn-light btn-hover-primary"
          onClick={handleClose}
        >
          <i className="ki ki-close icon-sm text-muted"></i>
        </span>
      </Modal.Header>
      <form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          {isLoading && <LoadingCustome />}
          <span className="mt-3 ">Select Charge Type</span>
          <FormControl fullWidth>
            <Select
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              className="form-control"
              IconComponent={(props) => (
                <i {...props}>
                  <KeyboardArrowDownIcon />
                </i>
              )}
              inputProps={{ "aria-label": "Without label" }}
              displayEmpty
              // onChange={(e) => {
              //   setFieldValue("search_view", e.target.value);
              //   handleSubmit();
              // }}
              name="price_id"
              {...formik.getFieldProps("price_id")}
            >
              <MenuItem value="">Select</MenuItem>
              {accessFeeList && accessFeeList.length > 0
                ? accessFeeList.map((item, index) => (
                    <MenuItem value={item?.id} key={index}>
                      {item?.title || ""}&nbsp;{item?.price ? "(£" : ""}
                      {item?.price || ""}
                      {item?.price ? ")" : ""}
                    </MenuItem>
                  ))
                : null}
            </Select>
          </FormControl>
          {formik.touched.price_id && formik.errors.price_id ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.price_id}</div>
            </div>
          ) : null}
          <span className="mt-3 ">&nbsp;</span>
          <input
            type="text"
            name="qty"
            placeholder={"Quantity"}
            label="External Name"
            className={`form-control  `}
            max={3}
            maxLength="3"
            {...formik.getFieldProps("qty")}
          />
          {formik.touched.qty && formik.errors.qty ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.qty}</div>
            </div>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              onClick={() => handleClose()}
              className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
              disabled={isLoading}
            >
              No Charge
            </button>
            <button
              type="submit"
              className="btn btn-primary font-weight-bolder font-size-sm "
              disabled={isLoading}
            >
              Pay
            </button>
          </div>
        </Modal.Footer>
      </form>
      {snack.call ? <SnackBarTool {...snack} /> : ""}
      <DiscardDialog
        setDialogDis={setDialogDis}
        dialogDis={dialogDis}
        formik={formik}
        setCanva={setDialog}
      />
    </Modal>
  );
};

export default BookingAccessModal;
