/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { Modal } from "react-bootstrap";
import React, { useState } from "react";
import { useFormik } from "formik";
// import { useDispatch } from "react-redux";
import SnackBarTool from "../../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { LoadingCustome } from "../../../../../../_metronic/_partials/controls/LoadingCustome";
import { getInputClasses } from "../../../../../utils/commonFunction";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import { FormControl, MenuItem, Select } from "@material-ui/core";
import { chargebee } from "../../../_mocks/dummyData";
import { getProduct } from "../../../_redux/order/orderCrud";

const BillingCycleEditOrderModal = ({ dialog, setDialog, orderUIContext }) => {
  // const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const initialValues = {
    billing: "",
  };

  const handleSubmit = (values) => {
    setIsLoading(true);
    const data = {
      franchise_id: orderUIContext?.state?.orderData?.franchise_id || "",
      item_id: dialog?.subItem?.id || "",
      period:
        values?.billing == "13"
          ? 1
          : values?.billing == "12"
          ? 1
          : values?.billing || "",
      period_unit:
        values?.billing == "13"
          ? "week"
          : values?.billing == "12"
          ? "year"
          : "month" || "",
    };
    
    getProduct(data).then((res) => {
      const item =
        res.data.data && res.data.data.length > 0 && res.data.data[0];
      const arr = [...orderUIContext?.state?.productArray];
      const index = arr.findIndex((e) => e.id === dialog?.subItem?.id);
      if (index !== -1) {
        arr[index].price = item?.amount / 100 || "";
        arr[index].item_price_id = item?.item_price_id || "";
        arr[index].amount =
          Number(arr[index].quantity) * Number(item?.amount / 100) || "";
      }
      orderUIContext.handleAddProductEdit(arr);
      setIsLoading(false);
      handleClose();
    });
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    // validationSchema: CompetitorModalSchema,
    onSubmit: handleSubmit,
  });

  const handleClose = () => {
    setDialog({ check: false });
  };
  return (
    <Modal
      show={dialog?.check}
      aria-labelledby="example-modal-sizes-title-lg"
      onHide={handleClose}
    >
      <Modal.Header className="d-flex justify-content-between">
        <Modal.Title id="example-modal-sizes-title-lg">
          Configure '{dialog?.subItem?.name}'
        </Modal.Title>
        <span
          className="btn btn-xs btn-icon btn-light btn-hover-primary"
          onClick={handleClose}
        >
          <i className="ki ki-close icon-sm text-muted"></i>
        </span>
      </Modal.Header>
      <form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          {isLoading && <LoadingCustome />}
          <div className="row">
            <div className="col-12">
              <label>Billing frequency</label>
              <div className="form-groupB">
                <FormControl fullWidth>
                  <Select
                    name="billing"
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                    className={`form-control  ${getInputClasses(
                      formik,
                      "billing"
                    )}`}
                    IconComponent={(props) => (
                      <i {...props}>
                        <KeyboardArrowDownIcon />
                      </i>
                    )}
                    inputProps={{ "aria-label": "Without label" }}
                    displayEmpty
                    {...formik.getFieldProps("billing")}
                    // disabled={checked === "customers"}
                  >
                    <MenuItem value="">Select</MenuItem>
                    {chargebee?.map((item, index) => (
                      <MenuItem value={item?.id}>{item?.title}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {formik.touched.billing && formik.errors.billing ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.billing}</div>
                  </div>
                ) : null}
              </div>
            </div>{" "}
            
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              onClick={() => handleClose()}
              className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
              //   disabled={isLoading}
            >
              Dismiss
            </button>
            <button
              type="submit"
              className="btn btn-primary font-weight-bolder font-size-sm "
            >
              Save
            </button>
          </div>
        </Modal.Footer>
      </form>
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </Modal>
  );
};

export default BillingCycleEditOrderModal;
