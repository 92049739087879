import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState((prev) => ({
      ...prev,
      error,
      errorInfo,
    }));
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="container">
          <h1 className="text-center">Something went wrong </h1>
          <code className="text-center">
            Message - {JSON.stringify(this.state?.error?.message, null, 5)}
          </code>
          <br />
          <br />
          <br />
          <code className="text-center">
            Stack - {JSON.stringify(this.state?.error?.stack, null, 5)}
          </code>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
