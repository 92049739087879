import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import CompleteDialogApi from "../../../../../../_metronic/_partials/widgets/alert/CompleteDialogApiCall";
import SVG from "react-inlinesvg";
import { TaskEdit } from "../../../canvas/TaskEdit";
import { fetchContactTasksList } from "../../../_redux/contact/contactAction";
import { fetchDealTasksList } from "../../../_redux/deals/dealsAction";
import { initIds } from "../../../../../../_metronic/_partials/controls/RightPanel";
import moment from "moment";
import { taskStatus } from "../../../_redux/contact/contactCrud";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import { useIntl } from "react-intl";

export function EditTaskCreate({ data, ownerList, type, dealId, permissions }) {
  // Initialize ids for DOM elements
  const init = ["kt_taskedit_panel"];
  useEffect(() => {
    initIds(init);
  }, [init]);

  // State for the task edit canva
  const [canva, setCanva] = useState({ type: false, data: {} });
  // State for the dialog
  const [dialog, setDialog] = useState({ modal: false });

  // Use the useDispatch hook to access the dispatch function
  const dispatch = useDispatch();
  const intl = useIntl();

  // Use the useSelector hook to access the permission state
  const { permission } = useSelector(
    (state) => ({
      permission: state.auth.permission,
    }),
    shallowEqual
  );

  // Function to handle update status of task
  const handleUpdateStatus = () => {
    // Prepare data to be sent to the server
    const datas = {
      id: dialog.data.id,
      is_completed: dialog.data.e ? 1 : 0,
    };
    const dataDeal = {
      id: dialog.data.id,
      deal_id: dealId,
      is_completed: dialog.data.e ? 1 : 0,
    };
    // Make the API call to update the task status
    taskStatus(type === "Deal" ? dataDeal : datas)
      .then(() => {
        setDialog({ modal: false });
        // Dispatch the appropriate action to update the task list
        if (type === "Deal") {
          dispatch(fetchDealTasksList(data?.deal_id, data?.contact_id));
        } else {
          dispatch(fetchContactTasksList(data?.contact_id));
        }
      })
      .catch(() => {});
  };

  // JSX code to render the component
  return (
    <>
      <div className="fieldset-area">
        <fieldset className="mb-5">
          <legend>
            {" "}
            {moment(data?.created_at).format("DD-MM-YYYY")} at{" "}
            {moment(data?.created_at).format("hh:mm")}
          </legend>
          <div className="p-5">
            <div className=" d-flex justify-content-between mb-10">
              <div>
                <label className="checkbox">
                  <input
                    type="checkbox"
                    name="Checkboxes4"
                    checked={data.is_completed === 1 ? true : false}
                    onClick={(e) =>
                      setDialog({
                        modal: true,
                        status: data.is_completed,
                        data: {
                          e: e.target.checked,
                          id: data.id,
                          dealId: type === "Deal" ? data.deal_id : " ",
                        },
                      })
                    }
                    disabled={
                      type === "Deal"
                        ? !permission?.deal?.create || !permission?.deal?.edit
                        : !permission?.contact?.create ||
                          !permission?.contact?.edit
                    }
                  />
                  <span className="mr-2"></span>
                  {intl.formatMessage({ id: "Completed" })}
                </label>
              </div>
              <span className="symbol-label mr-2">
                {type === "Deal" &&
                (permission?.deal?.create || permission?.deal?.edit) ? (
                  <span
                    className="svg-icon svg-icon-sm svg-icon-primary"
                    id="kt_taskedit_panel_toggle"
                    onClick={() => setCanva({ type: true, data: data })}
                  >
                    <SVG
                      title=" "
                      className="h-25 align-self-end"
                      src={toAbsoluteUrl("/media/svg/icons/Design/Edit.svg")}
                    ></SVG>
                  </span>
                ) : (
                  ""
                )}
                {type === "Deal" ? (
                  permission?.deal?.create || permission?.deal?.edit
                ) : permission?.contact?.create || permission?.contact?.edit ? (
                  <span
                    className="svg-icon svg-icon-sm svg-icon-primary"
                    id="kt_taskedit_panel_toggle"
                    onClick={() => setCanva({ type: true, data: data })}
                  >
                    <SVG
                      title=" "
                      className="h-25 align-self-end"
                      src={toAbsoluteUrl("/media/svg/icons/Design/Edit.svg")}
                    ></SVG>
                  </span>
                ) : (
                  ""
                )}
              </span>
            </div>
            <div className="row">
              <div className="col-md-4 col-xxl-2">
                <h6>{intl.formatMessage({ id: "Task.Type" })}</h6>
                <p>{data?.task_type}</p>
              </div>
              <div className="col-md-4 col-xxl-2">
                <h6>{intl.formatMessage({ id: "Priority" })} </h6>
                <p>{data?.priority}</p>
              </div>
              <div className="col-md-4 col-xxl-2">
                <h6>{intl.formatMessage({ id: "Task.Name" })} </h6>
                <p>{data?.task_name}</p>
              </div>
              <div className="col-md-4 col-xxl-2">
                <h6>{intl.formatMessage({ id: "Time" })} </h6>
                <p>{data?.time}</p>
              </div>
              <div className="col-xxl-3 col-md-4">
                <h6>{intl.formatMessage({ id: "Due.Date" })} </h6>
                <p>{moment(data?.due_date).format("DD-MM-YYYY")}</p>
              </div>
              <div className="col-xxl-3 col-md-4">
                <h6>{intl.formatMessage({ id: "Task.Owner" })} </h6>

                <p>
                  {data && data.is_unassigned === 1 ? (
                    intl.formatMessage({ id: "Unassigned" })
                  ) : (
                    <>
                      {data?.owner_data?.first_name}&nbsp;
                      {data?.owner_data?.last_name}
                    </>
                  )}
                </p>
              </div>
            </div>

            <div className="mt-3">
              <h6>{intl.formatMessage({ id: "Task.Description" })} </h6>
              <p>{data?.task_description}</p>
            </div>
          </div>
        </fieldset>
      </div>
      {/* rendering Task edit canva  */}
      {canva.type ? (
        <>
          <TaskEdit
            setCanva={setCanva}
            canva={canva}
            ownerList={ownerList}
            type={type}
          />
          <div
            className="offcanvas-overlay"
            onClick={() => setCanva({ type: false, data: {} })}
          ></div>
        </>
      ) : (
        ""
      )}
      {/* rendering complete check modal  */}
      <CompleteDialogApi
        dialog={dialog}
        setDialog={setDialog}
        handleChange={handleUpdateStatus}
      />
    </>
  );
}
