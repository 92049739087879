import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { useSubheader } from "../../../../../_metronic/layout";

const NewBreadCrumbs = (props) => {
  useSubheader().setTitle(props.title);

  const { buttons } = props;
  const array = props.match.url.split("/");
  let temp = "";
  let bread = [{ name: "dashboard", url: "/" }];
  const capitalizeFirstLetter = (
    [first, ...rest],
    locale = navigator.language
  ) => first.toLocaleUpperCase(locale) + rest.join("");
  for (let item of array) {
    if (item) {
      bread.push({ name: capitalizeFirstLetter(item), url: `${temp}${item}` });
    }
    temp = `${temp}${item}/`;
  }

  return (
    <>
      <div className="d-flex justify-content-between align-items-center topbreadcumb">
        <ul className="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2">
          {bread && !props.breadcrumb
            ? bread.map((item, index, data) => {
                return (
                  <Fragment key={index}>
                    {index === 0 ? (
                      ""
                    ) : index === 4 ? (
                      ""
                    ) : (
                      <li className={"breadcrumb-item"}>
                        <Link
                          style={{ pointerEvents: index === 3 ? "none" : "" }}
                          className={
                            data && data.length
                              ? data.length === index + 1
                                ? "active"
                                : "text-muted"
                              : "text-muted"
                          }
                          to={item.url}
                        >
                          {item.name
                            .split("-")
                            .map((element) => {
                              return index === 4
                                ? ""
                                : element[0].toUpperCase() + element.slice(1);
                            })
                            .join("-")
                            .replace(/-/g, " ")}
                        </Link>
                      </li>
                    )}
                  </Fragment>
                );
              })
            : ""}
        </ul>
        <div>
          {buttons
            ? buttons.map((data, index) => (
                <Fragment key={index}>
                  {data.link ? (
                    <Link to={data.link}>
                      <button
                        type="button"
                        className={data.className}
                        id={data.id}
                        style={{ display: data.display ? "none" : "" }}
                        // onClick={productsUIProps.newProductButtonClick}
                      >
                        {data.name}
                      </button>
                    </Link>
                  ) : (
                    <button
                      type="button"
                      className={data.className}
                      id={data.id}
                      style={{ display: data.display ? "none" : "" }}
                      disabled={data.disabled ? data.disabled : false}
                      // onClick={productsUIProps.newProductButtonClick}
                    >
                      {data.name}
                    </button>
                  )}
                </Fragment>
              ))
            : ""}
        </div>
      </div>
    </>
  );
};

export default withRouter(NewBreadCrumbs);
