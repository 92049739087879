import FormCheckbox from "./FormCheckbox";
import React from "react";
import { useIntl } from "react-intl";

// The Checkbox component receives formik and handleCheck props
const Checkbox = ({ formik, handleCheck }) => {
  // useIntl hook is used to provide internationalization support
  const intl = useIntl();
  // The component returns a table with a list of checkboxes
  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">
              <span className="font-weight-bolder d-flex">
                <span className="mt-1">
                  {" "}
                  {intl.formatMessage({ id: "Permission" })}{" "}
                </span>
                {/* If the formik touched object has a permission key and the errors object has a permission key, display an error message */}
                {formik.touched.permission && formik.errors.permission ? (
                  <div className="fv-plugins-message-container ml-4">
                    <div className="fv-help-block">
                      {formik.errors.permission}
                    </div>
                  </div>
                ) : null}
              </span>
            </th>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {Object.entries({
            contact: intl.formatMessage({ id: "Contact" }),
            deal: intl.formatMessage({ id: "DEALS" }),
            task: intl.formatMessage({ id: "TASKS" }),
            customer: intl.formatMessage({ id: "CUSTOMERS" }),
            order: intl.formatMessage({ id: "ORDERS" }),
            calendar: intl.formatMessage({ id: "Calendars" }),
            setting: intl.formatMessage({ id: "SETTINGS" }),
            setting_franchise: intl.formatMessage({
              id: "Settings.Franchises",
            }),
            setting_product: intl.formatMessage({ id: "Settings.Products" }),
            setting_country: intl.formatMessage({ id: "Settings.Country" }),
            setting_depot: intl.formatMessage({ id: "Settings.Depots" }),
            setting_user: intl.formatMessage({ id: "Settings.Users" }),
            setting_reporting: intl.formatMessage({ id: "Settings.Reporting" }),
            setting_promotion: intl.formatMessage({
              id: "Settings.Promotions",
            }),
            setting_feed: intl.formatMessage({ id: "Settings.Feeds" }),

            dashboard: intl.formatMessage({ id: "Dashboards" }),
            new_order: intl.formatMessage({ id: "NEW.ORDER" }),
            reporting: intl.formatMessage({ id: "REPORTING" }),
          }).map(([permission, label]) => (
            <tr key={permission}>
              <th scope="row">
                <label className="font-weight-normal">{label}</label>
                {formik.errors?.permission?.[permission] ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.permission[permission]}
                    </div>
                  </div>
                ) : null}
              </th>
              <FormCheckbox
                handleCheck={handleCheck}
                fieldName={formik.values.permission?.[permission]}
                name={permission}
              />
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default Checkbox;
