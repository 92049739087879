/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */

import * as Yup from "yup";

import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { EditTaskCreate } from "./EditTaskCreate";
import SnackBarTool from "../../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { TaskCreate } from "../../../../../../_metronic/_partials/widgets/mixed/TaskCreate";
import { tasksSchema } from "../../../form/validationSchema";
import { useFormik } from "formik";
import { useIntl } from "react-intl";

export function Tasks({
  ID,
  ownerList,
  tasksList,
  type,
  dealId,
  submitFunction,
  listFunction,
  activeTab,
  permission,
}) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { admin } = useSelector(
    (state) => ({
      admin: state.auth.user,
    }),
    shallowEqual
  );

  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [date, setDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [createTask, setCreateTask] = useState(false);
  const initialValues = {
    contact_id: ID ? ID : "",
    task_type: "",
    task_name: "",
    due_date: "",
    task_owner_id: admin && admin.id ? admin.id : "",
    task_description: "",
    priority: "",
    time: "",
  };
  const intialDeal = {
    deal_id: dealId ? dealId : "",
    contact_id: ID ? ID : "",
    task_type: "",
    task_name: "",
    due_date: "",
    task_owner_id: admin && admin.id ? admin.id : "",
    task_description: "",
    priority: "",
    time: "",
  };

  const handleSubmit = (values, { resetForm }) => {
    setSnack({ call: false, type: "", message: "" });
    setLoading(true);
    submitFunction(values)
      .then((res) => {
        setSnack({ call: true, type: "success", message: res.data.message });
        setTimeout(() => {
          if (type === "Deal") {
            dispatch(listFunction(values.deal_id, values.contact_id));
          } else {
            dispatch(listFunction(values.contact_id));
          }
        }, 2000);
        resetForm();
        setDate("");
        setLoading(false);
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",

          error: error.response.data.errors,
        });
        setLoading(false);
      });
  };

  const handleReset = () => {};

  const formik = useFormik({
    initialValues: type === "Deal" ? intialDeal : initialValues,
    enableReinitialize: true,
    validationSchema: tasksSchema(intl, Yup),
    onSubmit: handleSubmit,
    onReset: handleReset,
  });

  useEffect(() => {
    if (activeTab === "tasks") {
      formik.resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  return (
    <>
      <div className="mt-10">
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset} >
          {permission ? (<>
            <button
                type="button"
                className={`btn  ${
                  createTask === true ? "btn-dark" :"btn-primaryCus"   
                }  font-weight-bolder font-size-sm mr-3 mb-1 `}
                onClick={(e) => setCreateTask(!createTask)}
              >
                CreateTask
            </button>
                { createTask ? 
                  <TaskCreate
                    formik={formik}
                    ownerList={ownerList}
                    date={date}
                    setDate={setDate}
                    loading={loading}
                    admin={admin}
                  />: ""}
            </>
          ) : (
            ""
          )}
        </form>
      </div>
      {tasksList && tasksList?.length
        ? tasksList.map((data, index) => (
            <div key={index}>
              <EditTaskCreate
                data={data}
                ownerList={ownerList}
                type={type}
                dealId={dealId}
                permissions={permission}
              />
            </div>
          ))
        : ""}

      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </>
  );
}
