export const view = [
  { id: "all_orders", title: "All Orders" },
  { id: "my_orders", title: "My Orders" },
  { id: "new_last_week", title: "New Last Week" },
  { id: "new_this_week", title: "New This Week" },
];

export const agent = [
  { id: 1, title: "Unassigned" },
  { id: 2, title: "Agent 1" },
  { id: 3, title: "Agent 2" },
  { id: 4, title: "Agent 3" },
  { id: 5, title: "Agent 4" },
  { id: 6, title: "Agent 5" },
  { id: 7, title: "Agent 6" },
  { id: 8, title: "Agent 7" },
  { id: 9, title: "James" },
];

export const franchise = [
  { id: 1, title: "Franchise 1" },
  { id: 2, title: "Franchise 2" },
  { id: 3, title: "Franchise 3" },
  { id: 4, title: "Franchise 4" },
  { id: 5, title: "Franchise 5" },
  { id: 6, title: "Franchise 6" },
  { id: 7, title: "Franchise 7" },
];

export const status = [
  { id: "0", title: "Collection" },
  { id: 1, title: "Access" },
  { id: 2, title: "Return" },
  { id: 3, title: "Cancelled" },
];

export const chargebee = [
  // { id: 13, title: "Weekly", unit: "1w" },
  { id: 1, title: "Monthly", unit: "1" },
  // { id: 2, title: "2 Months", unit: "2" },
  { id: 3, title: "3 Months", unit: "3" },
  // { id: 4, title: "4 Months", unit: "4" },
  // { id: 5, title: "5 Months", unit: "5" },
  { id: 6, title: "6 Months", unit: "6" },
  // { id: 7, title: "7 Months", unit: "7" },
  // { id: 8, title: "8 Months", unit: "8" },
  // { id: 9, title: "9 Months", unit: "9" },
  // { id: 10, title: "10 Months", unit: "10" },
  // { id: 11, title: "11 Months", unit: "11" },
  { id: 12, title: "Yearly", unit: "12" },
];

export const months = [
  { id: "1", value: "1" },
  { id: "2", value: "2" },
  { id: "3", value: "3" },
  { id: "4", value: "4" },
  { id: "5", value: "5" },
  { id: "6", value: "6" },
  { id: "7", value: "7" },
  { id: "8", value: "8" },
  { id: "9", value: "9" },
  { id: "10", value: "10" },
  { id: "11", value: "11" },
  { id: "12", value: "12" },
];

export const deleteStateData = [
  "test_clock",
  "default_settings",
  "id",
  "object",
  "completed_at",
  "current_phase",
  "status",
  "subscription",
  "livemode",
  "released_at",
  "customer",
  "released_subscription",
  "created",
  "canceled_at",
  "renewal_interval",
  "application",
];


export const planProductId =[
  process.env.REACT_APP_PRODUCT_PREMIUM,
  process.env.REACT_APP_PRODUCT_ECONOMY,
  process.env.REACT_APP_PRODUCT_FIRST_CLASS,
]

export const premiumPlanCo  = process.env.REACT_APP_PRODUCT_PREMIUM
export const ecoPlanCo  = process.env.REACT_APP_PRODUCT_ECONOMY
export const firstPlanCo  =process.env.REACT_APP_PRODUCT_FIRST_CLASS

