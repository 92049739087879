/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
import React, { useMemo } from "react";
import { Table } from "react-bootstrap";
import { InputGroup, FormControl } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import NoRecord from "../../../../../_metronic/_partials/widgets/NoRecord";
import { useIntl } from "react-intl";
import { convertGBPtoPoundSymbol } from "../../../../utils/commonFunction";
import { MenuItem, Select } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
// import { collectionExtraChage } from "../../_redux/order/orderCrud";
import { rearrangeArray } from "../../orderUtils";

export const SummaryTable = ({ orderUIContext, orderDetail }) => {
  const intl = useIntl();
  // const [isLoading, setIsLoading] = useState(false);
  const handleQty = (value, index, id, type) => {
    // setIsLoading(true);
    const arr = [...orderUIContext?.state?.productArrayList];
    arr[index].quantity = value < 1 && value !== "" ? 1 : value;
    arr[index].total = Number(value) * Number(arr[index].price);

    orderUIContext.dispatchReducer({
      type: "SET_DATA",
      data: {
        ...orderUIContext.state,
        productArrayList: arr,
      },
    });
    // if (type === "plan") {
    //   const queryParams = {
    //     id: orderDetail.id,
    //     pod: value,
    //   };
    //   // if (orderDetail?.is_student === 0) {
    //   //   collectionExtraChage(queryParams).then((res) => {
    //   //     orderUIContext.dispatchReducer({
    //   //       type: "SET_DATA",
    //   //       data: {
    //   //         ...orderUIContext.state,
    //   //         extraCollection: res.data.data,
    //   //       },
    //   //     });
    //   //     setIsLoading(false);
    //   //   });
    //   // } else {
    //   //   setIsLoading(false);
    //   // }
    // } else {
    //   setIsLoading(false);
    // }
  };
  //product table calculation got subtotal
  useMemo(() => {
    const totalPodCost = orderUIContext?.state?.productArrayList.find(
      (e) => e.type === "plan"
    );
    const totalPodValue = orderUIContext?.state?.productArrayList.find(
      (e) => e.type === "plan"
    );
    const billingCoupon = orderUIContext?.state?.coupon.filter(
      (ft) => ft.check === "billing"
    );
    const data = {
      subTotal: "",
      discount: 0,
      total: 0,
      podTotal:
        totalPodCost?.total && billingCoupon.length > 0
          ? Number(totalPodCost?.total)
          : 0,
      podDiscount: 0,
    };
    orderUIContext?.state?.productArrayList.map((item) => {
      data["subTotal"] = Number(data["subTotal"]) + Number(item.total);
      data["total"] = Number(data["total"]) + Number(item.total);
      return item;
    });

    if (orderUIContext?.state?.coupon?.length) {
      const mannualCalculation = orderUIContext?.state?.coupon.find(
        (ft) => ft.name === "manual"
      );
      const coupons = orderUIContext?.state?.coupon.filter(
        (ft) => ft.name !== "manual" && ft.check !== "billing"
      );
      if (mannualCalculation) coupons.unshift(mannualCalculation);
      const sortedBilingDiscount = rearrangeArray(billingCoupon);
      if (sortedBilingDiscount) {
        sortedBilingDiscount.map((offer) => {
          if (offer?.type === "percent" || offer?.type === "percentage") {
            data["discount"] =
              data["discount"] + (data["podTotal"] * offer.value) / 100;
            data["podDiscount"] =
              data["podDiscount"] + (data["podTotal"] * offer.value) / 100;
            data["podTotal"] =
              data["podTotal"] - (data["podTotal"] * offer.value) / 100;
          } else if (
            offer?.type === "fixed" ||
            offer?.type === "fixed_amount"
          ) {
            data["podTotal"] =
              +totalPodValue?.total > Number(offer.value)
                ? Number(data["podTotal"]) - Number(offer.value)
                : 0;
            data["discount"] =
              +totalPodValue?.total > Number(offer.value)
                ? data["discount"] + Number(offer.value)
                : +totalPodValue?.total;
            data["podDiscount"] =
              +totalPodValue?.total > Number(offer.value)
                ? data["podDiscount"] + Number(offer.value)
                : +totalPodValue?.total;
          }
          return offer;
        });
      }
      // else {
      // eslint-disable-next-line no-unused-expressions
      coupons.map((offer) => {
        if (offer?.type === "percent" || offer?.type === "percentage") {
          data["discount"] =
            data["discount"] + (data["total"] * offer.value) / 100;

          data["total"] = data["total"] - data["discount"];
        } else if (offer?.type === "fixed" || offer?.type === "fixed_amount") {
          data["discount"] = data["discount"] + Number(offer.value);
          data["total"] = data["total"] - data["discount"];
        }

        return offer;
      });
      // }
    }
    orderUIContext.dispatchReducer({
      type: "SET_DATA",
      data: {
        ...orderUIContext.state,
        productSubTotal: data?.subTotal === 0 ? "0" : data?.subTotal || "N/A",
        productDicountAmount: data?.discount,
        productPodDiscount: data?.podDiscount,
      },
    });
    return data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    orderUIContext.state.productArrayList,
    orderUIContext.state.coupon,
    orderUIContext.state.coupon.length,
    orderUIContext?.state?.cover?.cover,
  ]);

  // slected product delete
  const handleDeleteProduct = (id) => {
    const arr = [...orderUIContext.state.productArrayList];
    const arrProId = [...orderUIContext.state.products_id];
    const index = arrProId.findIndex((e) => e === id);
    const indexPro = arr.findIndex((e) => e.id === id);

    if (index > -1) {
      arrProId.splice(index, 1);
    }
    if (indexPro > -1) {
      arr.splice(indexPro, 1);
    }
    if (id === orderUIContext.state?.adhoc?.id) {
      orderUIContext.dispatchReducer({
        type: "SET_DATA",
        data: {
          ...orderUIContext.state,
          adhoc: {},
          adhocCharge: "",
          adhocChargeName: "",
          products_id: arrProId,
          productArrayList: arr,
        },
      });
    } else {
      orderUIContext.dispatchReducer({
        type: "SET_DATA",
        data: {
          ...orderUIContext.state,
          products_id: arrProId,
          productArrayList: arr,
        },
      });
    }
  };

  //delete discount
  // const handleDeleteDiscount = () => {
  //   const arr = [...orderUIContext.state?.coupon];
  //   const filteredCoupon = arr.filter((e) => e.check === "billing");
  //   orderUIContext.dispatchReducer({
  //     type: "SET_DATA",
  //     data: {
  //       ...orderUIContext.state,
  //       coupon: filteredCoupon,
  //       manualDiscount: {},
  //     },
  //   });
  // };
  const handleAddon = (e, coverData, addonId, f) => {
    const dd = Object.entries(coverData);
    let price = "";
    dd.find(([keys, value]) => {
      if (keys === e) {
        price = value;
        return value;
      }
    });
    let arr = [...orderUIContext?.state?.productArrayList];
    const index = arr.findIndex((e) => e?.id === addonId);
    if (index > -1) {
      arr[index].cover = e;
      arr[index].total = price;

      orderUIContext.dispatchReducer({
        type: "SET_DATA",
        data: {
          ...orderUIContext.state,
          cover: { cover: e, price: price },
          productArrayList: arr,
        },
      });
    }
  };

  // const handleCalculation = () => {
  //   const sub = orderUIContext?.state?.productSubTotal
  //     ? Number(orderUIContext?.state?.productSubTotal)
  //     : 0;
  //   const dis = orderUIContext?.state?.productDicountAmount
  //     ? Number(orderUIContext?.state?.productDicountAmount)
  //     : 0;
  //   const col = orderUIContext?.state?.extraCollection?.collectionCharge
  //     ? Number(orderUIContext?.state?.extraCollection?.collectionCharge)
  //     : 0;
  //   const ret = orderUIContext?.state?.extraCollection?.returnCharge
  //     ? Number(orderUIContext?.state?.extraCollection?.returnCharge)
  //     : 0;

  //   let total = 0;
  //   total = sub - dis + col + ret;

  //   return total.toFixed(2);
  // };
  return (
    <>
      <Table responsive className="summerytable">
        <thead>
          <tr>
            <th>{intl.formatMessage({ id: "Product.ID" })}</th>
            <th className="productsta">
              {intl.formatMessage({ id: "Product" })}
            </th>
            <th className="cost">{intl.formatMessage({ id: "Unit.Cost" })}</th>
            <th>{intl.formatMessage({ id: "Qty" })}</th>
            <th>{intl.formatMessage({ id: "Total" })}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {orderUIContext?.state?.productArrayList &&
          orderUIContext?.state?.productArrayList.length > 0 ? (
            orderUIContext?.state?.productArrayList?.map((data, index) => (
              <tr>
                <td className="w-25">
                  {data?.id || null}
                  <br />
                  <span></span>
                </td>
                <td> {data?.name || null}</td>
                <td className="w-10">
                  {(data?.price &&
                    orderDetail?.currency &&
                    convertGBPtoPoundSymbol(orderDetail?.currency)) ||
                    null}
                  {data?.price || "N/A"}
                </td>
                <td>
                  {data.type === "addon" && data?.id !== "Loose-Load" ? (
                    <Select
                      name="address"
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                      className={`form-control  `}
                      IconComponent={(props) => (
                        <i {...props}>
                          <KeyboardArrowDownIcon />
                        </i>
                      )}
                      inputProps={{ "aria-label": "Without label" }}
                      displayEmpty
                      value={data.cover}
                      onChange={(e) =>
                        handleAddon(
                          e.target.value,
                          data?.cover_list,
                          data?.id,
                          e
                        )
                      }
                    >
                      <MenuItem value=" "></MenuItem>
                      {Object.entries(data?.cover_list).map(
                        ([keys, values], index) => (
                          <MenuItem value={keys}>{keys}</MenuItem>
                        )
                      )}
                    </Select>
                  ) : (
                    <InputGroup className="qty-group">
                      <FormControl
                        type="number"
                        placeholder="Qty"
                        aria-label="Qty"
                        aria-describedby="basic-addon1"
                        max={"10"}
                        min={"1"}
                        maxLength="12"
                        step="1"
                        value={data?.quantity}
                        // disabled={isLoading}
                        onChange={(e) =>
                          handleQty(
                            e.target.value > 10 ? 10 : e.target.value,
                            index,
                            data?.id,
                            data?.type
                          )
                        }
                        style={{ paddingRight: "10px" }}
                      />
                    </InputGroup>
                  )}
                </td>
                <td>
                  {(data?.total &&
                    orderDetail?.currency &&
                    convertGBPtoPoundSymbol(orderDetail?.currency)) ||
                    null}
                  {data?.total === 0 ? "0" : data?.total || "N/A"}
                </td>
                <td className="cart-icon">
                  <span className="svg-icon svg-icon-sm svg-icon-primary ">
                    <SVG
                      title="Delete"
                      src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}
                      onClick={() => handleDeleteProduct(data?.id)}
                    />
                  </span>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={"5"}>
                <NoRecord />
              </td>
            </tr>
          )}

          {orderUIContext.state?.productArrayList.length > 0 ? (
            <>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  {" "}
                  <strong> {intl.formatMessage({ id: "Subtotal" })}</strong>
                </td>
                <td>
                  {(orderUIContext?.state?.productSubTotal &&
                    orderDetail?.currency &&
                    convertGBPtoPoundSymbol(orderDetail?.currency)) ||
                    null}
                  {Number(orderUIContext?.state?.productSubTotal).toFixed(2) ||
                    "N/A"}
                </td>
                {/* <td></td> */}
              </tr>

              {/* {orderUIContext?.state?.productPodDiscount ? (
                <tr>
                  <td className="bord-top"></td>
                  <td className="bord-top"></td>
                  <td className="bord-top"></td>
                  <td className="bord-top">
                    {" "}
                    <strong>Discount on Storage</strong>
                  </td>
                  <td className="bord-top">
                    {" "}
                    {orderDetail?.currency
                      ? convertGBPtoPoundSymbol(orderDetail?.currency)
                      : "-"}
                    {Number(orderUIContext?.state?.productPodDiscount).toFixed(
                      2
                    )}
                  </td>
               
                </tr>
              ) : null}
              {orderUIContext?.state?.productDicountAmount ? (
                <tr>
                  <td className="bord-top"></td>
                  <td className="bord-top"></td>
                  <td className="bord-top"></td>
                  <td className="bord-top">
                    {" "}
                    <strong>Total Discount</strong>
                  </td>
                  <td className="bord-top">
                    {" "}
                    {orderDetail?.currency
                      ? convertGBPtoPoundSymbol(orderDetail?.currency)
                      : "-"}
                    {Number(
                      orderUIContext?.state?.productDicountAmount
                    ).toFixed(2)}
                  </td>
                  <td className="bord-top cart-icon">
                    {orderUIContext?.state?.coupon.some(
                      (e) => e.check !== "billing"
                    ) ? (
                      <span
                        className="svg-icon svg-icon-sm svg-icon-primary"
                        onClick={() => handleDeleteDiscount()}
                      >
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/General/Trash.svg"
                          )}
                        />
                      </span>
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
              ) : null}
              <tr>
                <td className="bord-top"></td>
                <td className="bord-top"></td>
                <td className="bord-top"></td>
                <td className="bord-top">
                  {" "}
                  <strong>
                    {intl.formatMessage({ id: "VAT" })} (
                    {orderDetail?.franchise_details?.tax_rate + "%" || "-"})
                  </strong>
                </td>
                <td className="bord-top">
                  {(orderUIContext?.state?.productSubTotal &&
                    orderDetail?.currency &&
                    convertGBPtoPoundSymbol(orderDetail?.currency)) ||
                    "-"}

                  {(
                    (orderDetail?.franchise_details?.tax_rate / 100) *
                    orderUIContext?.state?.productSubTotal
                  ).toFixed(2)}
                </td>
                <td className="bord-top cart-icon"></td>
              </tr> */}

              {/* <tr>
                <td className="bord-top" colSpan={2}>
                  {orderUIContext?.state?.adhocChargeName
                    ? "Adhoc Charge -"
                    : ""}{" "}
                  {orderUIContext?.state?.adhocChargeName}
                </td>
                
                <td className="bord-top"></td>
                <td className="bord-top">
                  {" "}
                  <strong> {intl.formatMessage({ id: "Total" })}</strong>
                </td>
                <td className="bord-top" colSpan="2">
                  {(orderUIContext?.state?.productSubTotal &&
                    orderDetail?.currency &&
                    convertGBPtoPoundSymbol(orderDetail?.currency)) ||
                    "-"}
                  {handleCalculation()}
                </td>
              </tr> */}
            </>
          ) : null}
        </tbody>
      </Table>
    </>
  );
};
