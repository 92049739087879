/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import resourceTimelinePlugin from "@fullcalendar/resource-timeline"; // a plugin!
import moment from "moment";
import SnackBarTool from "../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { timeSlotUpdate, unblockSlot } from "../redux/calendarCrud";
import { Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import { capitalizeFirstLetter } from "../../../utils/canvaCloseFunction";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
// import { useHistory } from "react-router-dom";
export const DropOff = ({
  collectionDate,
  calendarData,
  keys,
  formik,
  activeEvent,
  setActiveEvent,
  actionButton,
  type,
  setActionButton,
  isOverWrite,
  state,
  orderDetail,
  depotList,
}) => {
  const intl = useIntl();
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [dialog, setDialog] = useState({ type: false, event: "", check: "" });
  const calendarRef = useRef();
  // const history = useHistory();
  const getCalendarApi = () => {
    const { current } = calendarRef;
    return current?.getApi();
  };
  const handleDate = () => {
    const calendarApi = getCalendarApi();
    if (calendarApi) {
      calendarApi.gotoDate(collectionDate);
    }
  };
  useEffect(() => {
    if (collectionDate) {
      handleDate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionDate]);
  const handleEventClick = (e) => {
    if (type === "calendar") {
      if (e?.event?.extendedProps?.hoverDetails?.order_id) {
        // history.push(
        //   `/order-details/${e.event.extendedProps.hoverDetails.order_id}`
        // );
        const url = `/order-details/${e.event.extendedProps.hoverDetails.order_id}`;
        window.open(url, "_blank");
      }
      return;
    }

    setSnack({
      call: false,
      type: "",
      message: "",
    });
    let eventObj = e.event;

    if (
      (type === "new_order" || type === "order") &&
      depotList?.findIndex(
        (e) => e.depot_id === Number(eventObj?._def?.resourceIds[0])
      ) === -1
    ) {
      setSnack({
        call: true,
        type: "error",
        message: `This Depot is Inactive`,
      });
      return;
    }
    if (
      type === "new_order" &&
      keys === "return" &&
      Number(state?.depotData?.depot_id) !==
        Number(eventObj?._def?.resourceIds[0])
    ) {
      setSnack({
        call: true,
        type: "error",
        message: `Please Select Time in respective ${state?.depotData?.name ||
          ""} depot`,
      });
      return;
    }
    if (
      type === "order" &&
      (keys === "return" || keys === "access") &&
      Number(orderDetail?.depot_id) !== Number(eventObj?._def?.resourceIds[0])
    ) {
      setSnack({
        call: true,
        type: "error",
        message: `Please Select Time in respective ${orderDetail?.depot_details
          ?.name || ""} depot`,
      });
      return;
    }
    if (activeEvent) {
      activeEvent.setProp("classNames", []);
    }
    eventObj.setProp("classNames", ["clicked-event"]);
    setActiveEvent(eventObj);
    if (eventObj?.backgroundColor === "white") {
      keys === "collection"
        ? formik.setValues({
            ...formik.values,
            collection_date: moment(eventObj?.startStr).format("YYYY-MM-DD"),
            start_time_slot: moment.utc(eventObj?.startStr).format("HH:mm"),
            end_time_slot: moment.utc(eventObj?.endStr).format("HH:mm"),
            depot_id: eventObj?._def?.resourceIds[0] || "",
            is_student: 1,
            is_return: "0",
          })
        : keys === "return"
        ? formik.setValues({
            ...formik.values,
            return_date: moment(eventObj?.startStr).format("YYYY-MM-DD"),
            return_start_time_slot: moment
              .utc(eventObj?.startStr)
              .format("HH:mm"),
            return_end_time_slot: moment.utc(eventObj?.endStr).format("HH:mm"),
            depot_id: eventObj?._def?.resourceIds[0] || "",
            is_student_return: 1,
            is_return: formik?.values?.is_return === "0" ? "0" : "1",
          })
        : keys === "access"
        ? formik.setValues({
            ...formik.values,
            access_date: moment(eventObj?.startStr).format("YYYY-MM-DD"),
            access_start_time_slot: moment
              .utc(eventObj?.startStr)
              .format("HH:mm"),
            access_end_time_slot: moment.utc(eventObj?.endStr).format("HH:mm"),
            is_student_return: 1,
          })
        : "";
    } else {
      // setSnack({ call: true, type: "error", message: "Can't add this" });
    }
  };

  const handleResize = (event, check) => {
    if (type !== "calendar") return;

    const value = event?.event;
    const data = {
      order_id: value?.extendedProps?.hoverDetails?.order_id || "",
      order_type:
        dialog.color === "#009933"
          ? "Collection"
          : dialog.color === "#ff6601"
          ? "Return"
          : dialog?.block === "Block"
          ? "Block"
          : dialog.block === "Travel"
          ? "Travel"
          : "",
      time_from: moment.utc(value?.startStr).format("HH:mm") || "",
      time_to: moment.utc(value?.endStr).format("HH:mm") || "",
    };
    if (dialog.block === "Travel" || dialog?.block === "Block") {
      data.block_id = value?.extendedProps?.hoverDetails?.blockId || "";
    }
    if (check === "drop") {
      data.van_id = event?.newResource?.id || "";
    }
    timeSlotUpdate(data).then((res) => {
      setActionButton({ ...actionButton, check: "resize" });
      setDialog({ type: false, event: "", check: "" });
    });
  };

  const handleDelete = (eventInfo) => {
    if (type !== "calendar") return;

    if (
      eventInfo?.event?.extendedProps?.block === "Block" ||
      eventInfo?.event?.extendedProps?.block === "Travel"
    ) {
      setSnack({
        call: false,
        type: "",
        message: "",
      });
      const data = {
        id: eventInfo?.event?.extendedProps?.hoverDetails?.blockId || "",
      };
      unblockSlot(data)
        .then((res) => {
          setActionButton({ ...actionButton, check: "resize" });
          setSnack({ call: true, type: "success", message: res.data.message });
          setDialog({ type: false, event: "", check: "" });
        })
        .catch((error) => {
          // On error, update snackbar message
          setSnack({
            call: true,
            type: "error",
            message: error.response.data.message,
          });
        });
    }
  };
  return (
    <>
      <div className="mt-2 ml-2" style={{ textAlign: "center" }}>
        <h2 className="fc-toolbar-title ml-2" style={{ marginLeft: "45px" }}>
          {keys ? capitalizeFirstLetter(keys) : ""}
        </h2>
      </div>
      <FullCalendar
        ref={calendarRef}
        editable={type === "calendar" ? true : false}
        // eventAllow={(dropInfo, event) => {
        //   if (event?.backgroundColor === "white") {
        //     return false;
        //   } else {
        //     return true;
        //   }
        // }}
        resourceAreaWidth="20%"
        contentHeight="auto"
        schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
        plugins={[resourceTimelinePlugin, interactionPlugin]}
        defaultView="resourceTimelineDay"
        timeZone="UTC"
        // editable={true}
        height={200}
        headerToolbar={false}
        initialView="resourceTimeline"
        slotMinTime="08:00:00"
        eventClick={(e) => handleEventClick(e)}
        eventResize={(event) => {
          setDialog({
            type: true,
            event,
            block: event?.event?.extendedProps?.block,
            color: event?.event?.backgroundColor,
          });
        }}
        eventDrop={(event) => {
          setDialog({
            type: true,
            event,
            check: "drop",
            block: event?.event?.extendedProps?.block,
            color: event?.event?.backgroundColor,
          });
        }}
        resourceAreaHeaderContent=" "
        resourcesInitiallyExpanded={false}
        resourceOrder="from"
        resources={calendarData?.data?.depot?.resources || []}
        events={calendarData?.data?.depot?.events || []}
        slotLabelInterval={
          actionButton?.type === "operation" ? "00:30:00" : "01:00:00"
        }
        slotMaxTime={
          type === "order" && keys === "access"
            ? "21:00:00"
            : actionButton?.type === "operation"
            ? "20:30:00"
            : "18:00:00"
        }
        eventContent={(eventInfo) => {
          return (
            <>
              <div
                className={`${
                  eventInfo.event?.extendedProps?.hoverDetails
                    ?.is_invoices_due > 0
                    ? "d-flex justify-content-center"
                    : ""
                }`}
              >
                {eventInfo.event?.extendedProps?.hoverDetails?.is_invoices_due >
                  0 && (
                  <i
                    class="fa fa-exclamation-circle mr-2"
                    style={{ color: "#544b4b" }}
                  ></i>
                )}
                {eventInfo.event?.extendedProps?.hoverDetails ? (
                  <div style={{ backgroundColor: `${eventInfo.event.color}` }}>
                    <OverlayTrigger
                      overlay={
                        <Tooltip
                          id="products-edit-tooltip"
                          className="tooltipwth"
                        >
                          <form style={{ width: "400px !important" }}>
                            <div className="row">
                              <>
                                {Object.entries(
                                  eventInfo.event?.extendedProps?.hoverDetails
                                ).map(([keys, value], index) => {
                                  if (
                                    keys === "order_id" ||
                                    keys === "driver" ||
                                    keys === "van" ||
                                    keys === "blockId"
                                  )
                                    return;

                                  return (
                                    <div
                                      className="col-12"
                                      key={index}
                                      style={{ width: "200px" }}
                                    >
                                      <div className="mt-4 d-flex  mb-2">
                                        {keys !== "blockId" &&
                                        keys !== "message" ? (
                                          <span
                                            className="font-weight-bolder "
                                            style={{ fontSize: "1em" }}
                                          >
                                            {capitalizeFirstLetter(
                                              keys.replace(/_/g, " ")
                                            )}{" "}
                                            :
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                        <span
                                          className="  ml-4"
                                          style={{ fontSize: "1em" }}
                                        >
                                          {value}
                                        </span>
                                      </div>
                                    </div>
                                  );
                                })}
                              </>
                            </div>
                          </form>
                        </Tooltip>
                      }
                    >
                      <div
                        className="fc-event-main-frame svg-icon svg-icon-md svg-icon-primary"
                        onDoubleClick={() => {
                          if (type !== "calendar") return;
                          if (eventInfo.backgroundColor === "#242939") {
                            setDialog({
                              type: true,
                              event: eventInfo,
                              block: eventInfo?.event?.extendedProps?.block,
                              // color: eventInfo?.backgroundColor,
                              check: "drop",
                              isDelete: true,
                            });
                          }
                        }}
                      >
                        <div className="fc-event-title-container">
                          <div
                            className="fc-event-title fc-sticky"
                            style={{ top: "0px" }}
                          >
                            {eventInfo?.event?.title}
                          </div>
                        </div>
                      </div>
                    </OverlayTrigger>
                  </div>
                ) : (
                  <div
                    className="fc-event-main-frame svg-icon svg-icon-md svg-icon-primary"
                    onDoubleClick={() => {
                      if (type !== "calendar") return;
                      if (eventInfo.backgroundColor === "#242939") {
                        setDialog({
                          type: true,
                          event: eventInfo,
                          block: eventInfo?.event?.extendedProps?.block,
                          // color: eventInfo?.backgroundColor,
                          check: "drop",
                          isDelete: true,
                        });
                      }
                    }}
                  >
                    <div className="fc-event-title-container">
                      <div
                        className="fc-event-title fc-sticky"
                        style={{ top: "0px" }}
                      >
                        {eventInfo?.event?.title}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          );
        }}
      />
      {snack.call ? <SnackBarTool {...snack} /> : ""}
      <Modal show={dialog?.type} aria-labelledby="example-modal-sizes-title-lg">
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            {isOverWrite ? (
              intl.formatMessage({ id: "Overwrite.Maximum.Slot" })
            ) : dialog?.isDelete ? (
              <>
                {dialog.block === "Travel"
                  ? intl.formatMessage({ id: "Unblock.Travel.Time" })
                  : dialog?.block === "Block"
                  ? intl.formatMessage({ id: "Unblocking.Slot" })
                  : ""}
              </>
            ) : (
              <>
                {dialog.color === "#ff6601" || dialog.color === "#009933"
                  ? intl.formatMessage({ id: "Change.Job.Time.Slot" })
                  : dialog.block === "Travel"
                  ? intl.formatMessage({ id: "Change.Travel.Time" })
                  : dialog?.block === "Block"
                  ? intl.formatMessage({ id: "Change.Slot.Time" })
                  : ""}
              </>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isOverWrite ? (
            intl.formatMessage({ id: "Have.you.approval.overwrite.time.slot" })
          ) : dialog?.isDelete ? (
            <span>
              {dialog.block === "Travel"
                ? intl.formatMessage({ id: "Are.unblock.travel.time" })
                : dialog?.block === "Block"
                ? intl.formatMessage({ id: "Are.unblock.slot?" })
                : ""}
            </span>
          ) : (
            <>
              <span>
                {dialog.color === "#ff6601" || dialog.color === "#009933"
                  ? intl.formatMessage({ id: "Are.Job.Time.Slot?" })
                  : dialog.block === "Travel"
                  ? intl.formatMessage({ id: "Are.travel.time" })
                  : dialog?.block === "Block"
                  ? intl.formatMessage({ id: "Are.slot.time" })
                  : ""}
              </span>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              onClick={() => {
                setDialog({ type: false, event: "", check: "" });
                if (isOverWrite) return;
                setActionButton({ ...actionButton, check: "resize" });
              }}
              className="btn btn-light btn-elevate"
            >
              {intl.formatMessage({ id: "No" })}
            </button>
            <> </>
            <button
              type="button"
              onClick={() => {
                isOverWrite
                  ? handleEventClick(dialog?.event)
                  : dialog?.isDelete
                  ? handleDelete(dialog?.event)
                  : handleResize(dialog?.event, dialog?.check);
              }}
              className="btn btn-delete btn-elevate"
            >
              {intl.formatMessage({ id: "Yes" })}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
