import React from 'react'

export const NetTotalPodTabel = () => {
  return (
    <>
    <div className="react-bootstrap-table table-responsive">
        <table className="table table-hover table table-head-custom table-vertical-center overflow-hidden">
            <tbody>
                <tr>
                    <td style={{ width: '138px' }}>Total</td>
                    <td style={{ width: '130px' }}>All</td>
                    <td style={{ width: '130px' }}>&nbsp;</td>
                    <td style={{ width: '65px' }}>2222</td>
                    <td style={{ width: '65px' }}>23</td>
                    <td style={{ width: '65px' }}>-20</td>
                    <td style={{ width: '65px' }}>222</td>
                </tr>
                <tr>
                    <td style={{ width: '138px' }}>Net Pod Growth</td>
                    <td style={{ width: '130px' }}>&nbsp;</td>
                    <td style={{ width: '130px' }}>&nbsp;</td>
                    <td style={{ width: '65px' }}>&nbsp;</td>
                    <td style={{ width: '65px' }}>&nbsp;</td>
                    <td style={{ width: '65px' }}>&nbsp;</td>
                    <td style={{ width: '65px' }}>300</td>
                </tr>
            </tbody>
        </table>
    </div>
    </>
  )
}
