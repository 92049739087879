/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

import React, { useEffect, useState } from "react";
import {
  createContainerFranchise,
  createFranchisePostCode,
  deleteFranchisePostCode,
  franchiseSectorStatus,
  getClassSiteList,
  getSiteList,
} from "../../../_redux/franchises/franchisesCrud";
import {
  fetchFranchisesPostCode,
  fetchFranchisesSectorPostCode,
} from "../../../_redux/franchises/franchisesActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import ActiveDialogApi from "../../../../../../_metronic/_partials/widgets/alert/ActiveDialogApiCall";
import DeleteDialogApi from "../franchise-active-dialog/DeleteDialog";
import { Form, Modal } from "react-bootstrap";
import SnackBarTool from "../../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { canvaCancel } from "../../../../../utils/canvaCloseFunction";
import { useFormik } from "formik";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { getInputClasses } from "../../../../../utils/commonFunction";
import { InputGroup } from "react-bootstrap";
import DatePicker from "react-datepicker";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import moment from "moment";
import { containerList } from "../../../../Order/_redux/order/orderCrud";
import { fetchContainerFranchise } from "../../../_redux/container/containerAction";

export function ContainerCanva({
  detailData,
  dialogCanva,
  setDialogCanva,
  fraId,
}) {
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [dialog, setDialog] = useState({ modal: false });
  const [qty, setQty] = useState(0);
  const [siteList, setSiteList] = useState([]);
  const [classList, setClassList] = useState([]);
  const [containerDList, setContainerDList] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const initialValues = {
    franchise_id: fraId ? fraId : "",
    site_id: "",
    site_class_id: 1,
    site_capacity: "",
    container_size: "",
    currently_on_site: 1,
    data: [],
  };

  const handleSubmit = (values, actions) => {
    setSnack({ call: false, type: "", message: "" });
    if (values.site_id) {
      values.site_capacity = siteList.find(
        (e) => e.id === values.site_id
      )?.capacity;
    }
    if (values.postcode !== "") {
      createContainerFranchise(values)
        .then((res) => {
      //     const data ={
      //       pageNumber:1,
      //       pageSize:20,
      //       franchise_id:fraId    }
      // dispatch(fetchContainerFranchise(data))
          setSnack({ call: true, type: "success", message: res.data.message });
          const data ={
            pageNumber:1,
            pageSize:20,
            franchise_id:fraId    }
      dispatch(fetchContainerFranchise(data))
          // dispatch(fetchFranchisesPostCode(detailData, ""));
          formik.resetForm();
          setDialogCanva(false);
        })
        .catch((error) => {
          setSnack({
            call: true,
            type: "error",
            message: error.response.data.message,
          });
        });
    } else {
      formik.resetForm();
      canvaCancel("kt_container_panel_close");
    }
  };

  const handleReset = () => {};

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: handleSubmit,
    onReset: handleReset,
  });

  const handleSiteList = async () => {
    await getSiteList(fraId).then((res) => {
      setSiteList(res.data.data);
    });
  };
  useEffect(() => {
    if (dialogCanva) handleSiteList();
  }, [dialogCanva]);

  const handleClassList = async (id) => {
    await getClassSiteList(id).then((res) => {
      setClassList(res.data.data);
    });
  };
  useEffect(() => {
    if (formik.values.site_id) {
      handleClassList(formik.values.site_id);
    }
  }, [formik.values.site_id]);

  const handleClose = () => {
    setSearch("");
    formik.resetForm();

    setDialogCanva(false);
  };

  useEffect(() => {
    setLoading(true);
    containerList()
      .then((res) => {
        setContainerDList(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);
  return (
    <Modal
      size="xl"
      fullscreen
      show={dialogCanva}
      dialogClassName="modal-90w"
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">Container</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/*end::Header*/}
        <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
          <div className="row">
            <div className="col-md-3 mb-3">
              <Form.Group className="mb-0">
                <Form.Label>Container size</Form.Label>
                <FormControl fullWidth>
                  <Select
                    name="container_size"
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                    className={`form-control  ${getInputClasses(
                      "container_size"
                    )}`}
                    IconComponent={(props) => (
                      <i {...props}>
                        <KeyboardArrowDownIcon />
                      </i>
                    )}
                    inputProps={{ "aria-label": "Without label" }}
                    displayEmpty
                    {...formik.getFieldProps("container_size")}

                    //   onBlur={handleBlur}
                    // value={values.container_size_id}
                  >
                    {loading ? (
                      <MenuItem value="">Loading</MenuItem>
                    ) : (
                      <MenuItem value="">Select</MenuItem>
                    )}
                    {!loading &&
                      containerDList &&
                      containerDList?.length > 0 &&
                      containerDList.map((e) => (
                        <MenuItem value={e.id}>{e.id}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
                {formik.touched.container_size &&
                formik.errors.container_size ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.container_size}
                    </div>
                  </div>
                ) : null}
              </Form.Group>
            </div>

            <div className="col-md-3 mb-3">
              <Form.Group className="mb-0">
                <Form.Label>Site ID</Form.Label>
                <FormControl fullWidth>
                  <Select
                    name="site_id"
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                    className={`form-control  ${getInputClasses("site_id")}`}
                    IconComponent={(props) => (
                      <i {...props}>
                        <KeyboardArrowDownIcon />
                      </i>
                    )}
                    inputProps={{ "aria-label": "Without label" }}
                    displayEmpty
                    {...formik.getFieldProps("site_id")}

                    //   onBlur={handleBlur}
                    // value={values.site_id_id}
                  >
                    <MenuItem value="">Select</MenuItem>
                    {siteList &&
                      siteList.map((e) => (
                        <MenuItem value={e.id}>{e.name}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
                {formik.touched.site_id && formik.errors.site_id ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.site_id}</div>
                  </div>
                ) : null}
              </Form.Group>
            </div>
            <div className="col-md-3 mb-3">
              <Form.Group className="mb-0">
                <Form.Label>Class ID</Form.Label>
                <FormControl fullWidth>
                  <Select
                    name="site_class_id"
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                    className={`form-control  ${getInputClasses(
                      "site_class_id"
                    )}`}
                    IconComponent={(props) => (
                      <i {...props}>
                        <KeyboardArrowDownIcon />
                      </i>
                    )}
                    inputProps={{ "aria-label": "Without label" }}
                    displayEmpty
                    {...formik.getFieldProps("site_class_id")}
                    //   onBlur={handleBlur}
                    // value={values.site_class_id_id}
                    disable={formik.values.site_id === ""}
                  >
                    <MenuItem value="">Select</MenuItem>
                    {classList &&
                      classList.map((e) => (
                        <MenuItem value={e.id}>{e.name}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
                {formik.touched.site_class_id && formik.errors.site_class_id ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.site_class_id}
                    </div>
                  </div>
                ) : null}
              </Form.Group>
            </div>
            {/* <div className="col-2 mb-4">
                          <Form.Group className="mb-0">
                            <Form.Label>Collection fee</Form.Label>
                            <FormControl fullWidth>
                              <input
                                type="text"
                                name="price_increase_value"
                                placeholder={"£"}
                                label="External Name"
                                className={`form-control  `}
                                // max={3}
                                // maxLength="3"
                                {...formik.getFieldProps(
                                  "price_increase_value"
                                )}
                              />
                            </FormControl>
                          </Form.Group>
                          {formik.touched.price_increase_value &&
                          formik.errors.price_increase_value ? (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                {formik.errors.price_increase_value}
                              </div>
                            </div>
                          ) : null}
                        </div> */}
            {/* <div className="col-2 mb-4">
                          <Form.Group className="mb-0">
                            <Form.Label>Return fee</Form.Label>
                            <FormControl fullWidth>
                              <input
                                type="text"
                                name="price_increase_value"
                                placeholder={"£"}
                                label="External Name"
                                className={`form-control  `}
                                // max={3}
                                // maxLength="3"
                                {...formik.getFieldProps(
                                  "price_increase_value"
                                )}
                              />
                            </FormControl>
                          </Form.Group>
                          {formik.touched.price_increase_value &&
                          formik.errors.price_increase_value ? (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                {formik.errors.price_increase_value}
                              </div>
                            </div>
                          ) : null}
                        </div> */}
            <div className="col-3 mb-4">
              <Form.Group className="mb-0">
                <Form.Label>Quantity</Form.Label>
                <FormControl fullWidth>
                  <input
                    type="number"
                    name="currently_on_site"
                    placeholder={"Quantity"}
                    label="External Name"
                    className={`form-control  `}
                    value={qty}
                    // max={3}
                    // maxLength="3"
                    {...formik.getFieldProps("currently_on_site")}
                    // onChange={(e) => setQty(e.target.value)}
                  />
                </FormControl>
              </Form.Group>
              {formik.touched.price_increase_value &&
              formik.errors.price_increase_value ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.price_increase_value}
                  </div>
                </div>
              ) : null}
            </div>
            {[
              ...Array(
                formik.values.currently_on_site
                  ? +formik.values.currently_on_site
                  : 0
              ),
            ].map((_, index) => (
              <div
                className="row mb-3"
                style={{ padding: "2%", margin: "1%", background: "#f3f3f3" }}
              >
                {/* <div className="col-md-1 mb-3">
                  <Form.Group className="mb-0">
                    <Form.Label>Type</Form.Label>
                    <FormControl fullWidth>
                      <Select
                        name={`data.${index}.type`}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                        className={`form-control  ${getInputClasses(
                          `data.${index}.type`
                        )}`}
                        IconComponent={(props) => (
                          <i {...props}>
                            <KeyboardArrowDownIcon />
                          </i>
                        )}
                        inputProps={{ "aria-label": "Without label" }}
                        displayEmpty
                        {...formik.getFieldProps(`data.${index}.type`)}

                        //   onBlur={handleBlur}
                        // value={values.country_id_id}
                      >
                        <MenuItem value="">Select</MenuItem>
                        <MenuItem value="ELE">ELE</MenuItem>
                        <MenuItem value="NON">NON</MenuItem>
                        <MenuItem value="POTENTIAL">POTENTIAL</MenuItem>
                      </Select>
                    </FormControl>
                    {formik.touched.country_id && formik.errors.country_id ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.country_id}
                        </div>
                      </div>
                    ) : null}
                  </Form.Group>
                </div> */}
                <div className="col-md-2 mb-3">
                  <Form.Group className="mb-0">
                    <Form.Label>Floor</Form.Label>
                    <FormControl fullWidth>
                      <Select
                        name={`data.${index}.floor`}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                        className={`form-control  ${getInputClasses(
                          `data.${index}.floor`
                        )}`}
                        IconComponent={(props) => (
                          <i {...props}>
                            <KeyboardArrowDownIcon />
                          </i>
                        )}
                        inputProps={{ "aria-label": "Without label" }}
                        displayEmpty
                        {...formik.getFieldProps(`data.${index}.floor`)}
                        onChange={(e) => {
                          const data = formik.values?.container_size.split(" ")
                          const cap = data && data.length > 0 && data[1];
                          const capInUpperCase = cap.toUpperCase();
                          const last = cap === "Non-Ele" ? "NON":cap === "Ele"?"ELE":"PONTENTIAL";
                          formik.setFieldValue(
                            `data.${index}.type`,
                            last
                          );
                          formik.setFieldValue(
                            `data.${index}.floor`,
                            e.target.value
                          );
                        }}
                        //   onBlur={handleBlur}
                        // value={values.country_id_id}
                      >
                        <MenuItem value="">Select</MenuItem>
                        <MenuItem value="G">Ground</MenuItem>
                        <MenuItem value="1F">1st</MenuItem>
                        <MenuItem value="2F">2nd</MenuItem>
                      </Select>
                    </FormControl>
                    {formik.touched.country_id && formik.errors.country_id ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.country_id}
                        </div>
                      </div>
                    ) : null}
                  </Form.Group>
                </div>
               
                <div className="col-md-2 mb-3">
                  <Form.Group className="mb-0">
                    <Form.Label>Store vehicle</Form.Label>
                    <FormControl fullWidth>
                      <Select
                        name={`data.${index}.store_vehicle`}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                        className={`form-control  ${getInputClasses(
                          `data.${index}.store_vehicle`
                        )}`}
                        IconComponent={(props) => (
                          <i {...props}>
                            <KeyboardArrowDownIcon />
                          </i>
                        )}
                        inputProps={{ "aria-label": "Without label" }}
                        displayEmpty
                        {...formik.getFieldProps(`data.${index}.store_vehicle`)}

                        //   onBlur={handleBlur}
                        // value={values.country_id_id}
                      >
                        <MenuItem value="">Select</MenuItem>
                        <MenuItem value="YES">Yes</MenuItem>
                        <MenuItem value="NO">No</MenuItem>
                      </Select>
                    </FormControl>
                    {formik.touched.country_id && formik.errors.country_id ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.country_id}
                        </div>
                      </div>
                    ) : null}
                  </Form.Group>
                </div>

                

                <div className="col-md-2 mb-3">
                  <Form.Group className="mb-0">
                    <Form.Label>Vehicle Access</Form.Label>
                    <FormControl fullWidth>
                      <Select
                        name={`data.${index}.vehicle_access`}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                        className={`form-control  ${getInputClasses(
                          `data.${index}.vehicle_access`
                        )}`}
                        IconComponent={(props) => (
                          <i {...props}>
                            <KeyboardArrowDownIcon />
                          </i>
                        )}
                        inputProps={{ "aria-label": "Without label" }}
                        displayEmpty
                        {...formik.getFieldProps(
                          `data.${index}.vehicle_access`
                        )}

                        //   onBlur={handleBlur}
                        // value={values.country_id_id}
                      >
                        <MenuItem value="">Select</MenuItem>
                        <MenuItem value="YES">Yes</MenuItem>
                        <MenuItem value="NO">No</MenuItem>
                      </Select>
                    </FormControl>
                    {formik.touched.country_id && formik.errors.country_id ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.country_id}
                        </div>
                      </div>
                    ) : null}
                  </Form.Group>
                </div>
                <div className="col-2 mb-4">
                  <Form.Group className="mb-0">
                    <Form.Label>Unique&nbsp;ID</Form.Label>
                    <FormControl fullWidth>
                      <input
                        type="text"
                        name={`data.${index}.reference_unique_id`}
                        placeholder={"ID"}
                        label="ID"
                        className={`form-control  `}
                        // disabled
                        // max={3}
                        // maxLength="3"
                        {...formik.getFieldProps(
                          `data.${index}.reference_unique_id`
                        )}
                      />
                    </FormControl>
                  </Form.Group>
                  {formik.touched.price_increase_value &&
                  formik.errors.price_increase_value ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.price_increase_value}
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="col-md-4 mb-5">
                  <label>Available from</label>
                  <InputGroup className="calendr">
                    <DatePicker
                      fullWidth
                      fixedHeight
                      className="form-control"
                      wrapperClassName="datepicker"
                      selected={
                        formik?.values.data.length &&
                        formik?.values.data[index] &&
                        Date.parse(formik?.values.data[index].available_from)
                      }
                      // startDate={Date.parse(formik?.values.blocking_date_from)}
                      // endDate={Date.parse(formik?.values.blocking_date_to)}
                      dateFormat="dd-MM-Y"
                      placeholderText="Select From Date"
                      onChange={(fromDate) => {
                        formik.setFieldValue(
                          `data.${index}.available_from`,
                          moment(fromDate).format("yyyy-MM-DD")
                        );
                      }}
                      minDate={new Date()}
                    />
                    <CalendarTodayIcon />
                  </InputGroup>
                  {formik.touched.blocking_date_from &&
                  formik.errors.blocking_date_from ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.blocking_date_from}
                      </div>
                    </div>
                  ) : null}
                  {formik.touched.blocking_date_to &&
                  !formik.errors.blocking_date_from &&
                  formik.errors.blocking_date_to ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.blocking_date_to}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            ))}
          </div>
        </form>
      </Modal.Body>
      {snack.call ? <SnackBarTool {...snack} /> : ""}
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={() => setDialogCanva(false)}
            className="btn btn-light btn-elevate"
          >
            Close
          </button>
          <> </>

          <button
            type="button"
            className="btn btn-light btn-elevate"
            onClick={() => formik.submitForm()}
          >
            Save
          </button>
        </div>
      </Modal.Footer>
      {/* <ActiveDialogApi
        dialog={dialogSector}
        setDialog={setDialogSector}
        title={"Sector"}
        handleChange={handleChange}
      /> */}
      {/* <DeleteDialogApi
        dialog={dialog}
        setDialog={setDialog}
        title={"Postcode"}
        handleChange={handleDelete}
      /> */}
      {/* {sectorDa.data ? (
        <>
          <EditSector
            type={sectorDa.type}
            data={sectorDa?.data}
            setSectorDa={setSectorDa}
          />
          <div
            className="offcanvas-overlay"
            onClick={() => setSectorDa({ type: "" })}
          ></div>
        </>
      ) : (
        ""
      )} */}
    </Modal>
  );
}
