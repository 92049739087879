import { Modal } from "react-bootstrap";
import React from "react";
import { useIntl } from "react-intl";

const ActiveDialog = ({
  active,
  setActive,
  dialog,
  setDialog,
  formik,
  type,
}) => {
  const intl = useIntl();
  const handleChange = () => {
    setActive(active ? false : true);
    formik.setFieldValue("status", active ? 0 : 1);
    setDialog(false);
  };
  return (
    <Modal show={dialog} aria-labelledby="example-modal-sizes-title-lg">
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          {active
            ? intl.formatMessage({ id: "Deactivate" })
            : intl.formatMessage({ id: "Activate" })}{" "}
          {type === "profile" ? "Profile" : "User"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>
          {intl.formatMessage({ id: "Are.you.sure" })}{" "}
          {active
            ? intl.formatMessage({ id: "deactivate" })
            : intl.formatMessage({ id: "activate" })}{" "}
          {type === "profile"
            ? "this profile ?"
            : intl.formatMessage({ id: "this.user" })}{" "}
        </span>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={() => setDialog(false)}
            className="btn btn-light btn-elevate"
          >
            {intl.formatMessage({ id: "No" })}
          </button>
          <> </>
          <button
            type="button"
            onClick={handleChange}
            className="btn btn-delete btn-elevate"
          >
            {intl.formatMessage({ id: "Yes" })}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ActiveDialog;
