import React from "react";
import { Modal } from "react-bootstrap";

const CompleteDialogApi = ({ dialog, setDialog, status, handleChange }) => {
  return (
    <Modal show={dialog.modal} aria-labelledby="example-modal-sizes-title-lg">
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          {dialog.status === 1 ? "Pending" : "Complete"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>
          Are you sure you want to make it
          {dialog.status === 1 ? " Pending" : " Complete"}?
        </span>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={() => setDialog({ modal: false })}
            className="btn btn-light btn-elevate"
          >
            No
          </button>
          <> </>
          <button
            type="button"
            onClick={() => handleChange()}
            className="btn btn-delete btn-elevate"
          >
            Yes
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default CompleteDialogApi;
