import React from "react";
import { Modal } from "react-bootstrap";
import { useIntl } from "react-intl";

const ActiveDialogModal = ({
  dialog,
  setDialog,
  formik,
  noFormik,
  depotDefault,
}) => {
  const intl = useIntl();
  const handleChange = () => {
    if (!noFormik) {
      formik.setFieldValue("status", formik.values.status === 1 ? 0 : 1);
    }
    setDialog(false);
  };
  return (
    <Modal show={dialog} aria-labelledby="example-modal-sizes-title-lg">
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          {formik.values.status === 1 ? "Deactivate" : "Activate"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>
          {intl.formatMessage({ id: "Are.you.sure" })}{" "}
          {formik.values.status === 1
            ? intl.formatMessage({ id: "deactivate" })
            : intl.formatMessage({ id: "activate" })}
          {formik.values.status === 1 && depotDefault
            ? " as this is a default depot for a franchise"
            : ""}
          ?
        </span>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={() => setDialog(false)}
            className="btn btn-light btn-elevate"
          >
            {intl.formatMessage({ id: "No" })}
          </button>
          <> </>
          <button
            type="button"
            onClick={handleChange}
            className="btn btn-delete btn-elevate"
          >
            {intl.formatMessage({ id: "Yes" })}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ActiveDialogModal;
