/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

import { Menu, MenuItem } from "@material-ui/core";
import React, { useState } from "react";

import { CreditNotes } from "./CreditNotes";
import InvoiceActionModal from "../order-modal/InvoiceActionModal";
import { LoadingCustome } from "../../../../_metronic/_partials/controls/LoadingCustome";
import { OfflinePayment } from "./OfflinePayment";
import { Refund } from "./Refund";
import SVG from "react-inlinesvg";
import SnackBarTool from "../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { Table } from "react-bootstrap";
import { canvaCancel } from "../../../utils/canvaCloseFunction";
import { collectionPaymentRetryCharge, downloadChargeBeeInvoice, downloadInvoice, getConditionStats, sendPayUrlForInvoice } from "../_redux/order/orderCrud";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { useIntl } from "react-intl";
import { convertGBPtoPoundSymbol } from "../../../utils/commonFunction";
import moment from "moment";
import {
  fetchBillingCardDetail,
  fetchInvoiceList,
  fetchPaymentInvoice,
} from "../_redux/order/orderAction";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import DynamicAlert from "../../../../_metronic/_partials/widgets/alert/DynamicAlert";

export function OrderInvoice({ invoiceList, paymentInvoiceList, setOpenInvoice, openInvoice,chargeBeeInvoiceList }) {
  const { orderDetail } = useSelector(
    (state) => ({
      orderDetail: state.order.orderDetail,
    }),
    shallowEqual
  );
  const intl = useIntl();
  const dispatch = useDispatch();

  console.log("invoiceList", invoiceList);
  console.log("paymentInvoiceList", paymentInvoiceList);
  console.log("openInvoice",openInvoice)

  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [canva, setCanva] = useState({ type: "", data: {}, check: false });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [checked, setCheck] = useState();
  const [dialog, setDialog] = useState({open:false})
  console.log("hello-123",dialog );
  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setCheck(index);
  };

  const handleClose = (type, data) => {
    setAnchorEl(null);

    if (type === "void") {
      setIsLoading(true);
      getConditionStats(data?.order_id, data?.invoice_id)
        .then((res) => {
          dispatch(
            fetchBillingCardDetail(
              orderDetail?.franchise_id,
              orderDetail?.subscription_data?.id,
              "",
              orderDetail?.customer_chargebee_id,
              orderDetail?.id
            )
          );
          const orderSub =
      orderDetail?.subscription_id || orderDetail?.subscription_scheduled_id;
    const is_subscription_id = orderDetail?.subscription_id ? 1 : "0";
          dispatch(
            fetchPaymentInvoice(
              orderDetail?.id,
              orderDetail?.franchise_id,
              orderSub,
              is_subscription_id,
              orderDetail?.invoice_id
            )
          );
          setTimeout(() => {
            dispatch(
              fetchInvoiceList(
                orderDetail?.franchise_id,
                orderDetail?.id,
                orderDetail?.subscription_id
              )
            );
          }, 2000);
          setIsLoading(false);
          setSnack({ call: true, type: "success", message: res.data.message });
        })
        .catch((error) => {
          setIsLoading(false);
          setSnack({
            call: true,
            type: "error",
            message: error.response.data.message,
          });
        });
      return;
    }
    setCanva({
      type: type,
      data: data,
      check: type !== "close" ? true : false,
    });
  };
const handleDownloadChargebeeInvoice = (id)=> {
  setIsLoading(true);
  downloadChargeBeeInvoice(id)
  .then((res)=>{
    setSnack({ call: true, type: "success", message: res.data.message });
    setIsLoading(false);
    window.open(res.data.data);
  })
  .catch((error) => {
    setIsLoading(false);
    setSnack({
      call: true,
      type: "error",
      message: error.response.data.message,
    });
  });
}

const handleCollectionPayment = (invoiceId)=> {
  setIsLoading(true);
  const data = {
    invoice_id: invoiceId
  }
  collectionPaymentRetryCharge(data)
  .then((res)=>{
    
    setIsLoading(false);
    setSnack({ call: true, type: "success", message: res.data.message });
    dispatch(
      fetchPaymentInvoice(
        orderDetail?.id,
        orderDetail?.franchise_id,
        orderDetail?.subscription_id
      )
    )
    setDialog({ open: false })
  })
 
  .catch((error) => {
    setDialog({ open: false })
    setIsLoading(false);
    setSnack({
      call: true,
      type: "error",
      message: error.response.data.message,
    });
  });
}

const handleSendUrlLink = (id, type)=> {
  setIsLoading(true);
  const data = {
    invoice_id :id,
type:type,
franchise_id: orderDetail?.franchise_id,
id: orderDetail?.customer_details?.id,
phone : orderDetail?.customer_details?.phone,
  }
  sendPayUrlForInvoice(data)
  .then((res)=>{
    setDialog({ open: false })
    setIsLoading(false);
    setSnack({ call: true, type: "success", message: res.data.message });
  })
  .catch((error) => {
    setDialog({ open: false })
    setIsLoading(false);
    setSnack({
      call: true,
      type: "error",
      message: error.response.data.message,
    });
  });
}

  const handleDownloadInvoice = (franchise, invoice, type) => {
    setIsLoading(true);
    downloadInvoice(franchise, invoice, type)
      .then((res) => {
        setSnack({ call: true, type: "success", message: res.data.message });

        setIsLoading(false);
        window.open(res.data.data);
      })
      .catch((error) => {
        setIsLoading(false);
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
  };

  const handleCloseCanva = () => {
    setOpenInvoice({open:false})
    canvaCancel("kt_OrderInvoice_panel_close");
  };

  return (
    <div
      id="kt_OrderInvoice_panel"
      className="offcanvas offcanvas-right pt-5 pb-10 offcanvas-on"
    >
      {isLoading ? <LoadingCustome /> : ""}
      {/*begin::Header*/}
      <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
        <div
          className="offcanvas-close mt-n1 pr-5"
          style={{ position: "absolute", top: "15px", right: "10px" }}
        >
          <span
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            onClick={() => handleCloseCanva()}
          >
            <i className="ki ki-close icon-sm text-muted"></i>
          </span>
        </div>
        <div id="kt_OrderInvoice_panel_close"></div>
      </div>
      {/*end::Header*/}
      <div className="offcanvas-content tableover px-10 ">
        <div>
          <div>
            <h5>{openInvoice.check === "chargebee" ?  "ChargeBee Invoices" : "Invoices"  }</h5>
          </div>
          <div
            id="kt_quick_panel_logs_kt_OrderInvoice_panel"
            className="fade pt-3 pr-5 mr-n5 scroll active show ps--active-y"
          >
            <div className="mt-10">
              <div>
                <Table responsive>
                  <thead>
                    <tr>
                    <th></th>
                      <th>{intl.formatMessage({ id: "Invoice.No" })}</th>
                      <th>{intl.formatMessage({ id: "Type" })}</th>
                      {openInvoice.check !== "chargebee" &&  <th>{intl.formatMessage({ id: "Amount" })}</th> }
                      {openInvoice.check !== "chargebee" && <th>{intl.formatMessage({ id: "Period" })}</th>}
                      {openInvoice.check !== "chargebee" && <th>{"Created At"}</th>}
                      <th>{intl.formatMessage({ id: "Status" })}</th>
                      
                    </tr>
                  </thead>
                  <tbody>
                    {/* {invoiceList &&
                      invoiceList.length > 0 &&
                      [
                        ...new Map(
                          invoiceList.map((item) => [item["invoice_id"], item])
                        ).values(),
                      ].map((item, index) => {
                        const data =
                          item?.status.charAt(0).toUpperCase() +
                          item?.status.slice(1);
                        const status = data.replace("_", " ");
                        const splitData = item?.period.split(" ");
                         const check = invoiceList.some((e) => e?.invoice_id); */}
                    { openInvoice.check === "chargebee" ? chargeBeeInvoiceList &&   chargeBeeInvoiceList.length > 0 &&  chargeBeeInvoiceList.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td style={{ wordWrap: "break-word" }}>
                            {item?.entity_id || "-"}
                          </td>
                          <td>{item?.entity_type ? "Invoice" : "Credit Note" || "-"}</td>
                         
                          {/* <td>
                            {item?.created_at
                              ? moment(item?.created_at).format("DD-MM-yyyy")
                              : "-"}
                          </td> */}
                          <td> {item?.s3_status === 1 ? "Paid" : "Unpaid"|| "-"}</td>
                          <td>
                            <span
                              className="symbol-label ml-2 pointer"
                              onClick={() =>
                                handleDownloadChargebeeInvoice(
                                  item?.id,
                                )
                              }
                            >
                              <span className="svg-icon svg-icon-sm svg-icon-primary">
                                <SVG
                                  title=" "
                                  className="h-25 align-self-end"
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/Files/Download.svg"
                                  )}
                                ></SVG>
                              </span>
                            </span>
                          </td>
                        </tr>
                      );
                    }) :  paymentInvoiceList &&
                      paymentInvoiceList.length > 0 && 
                      paymentInvoiceList.map((item, index) => {
                        return (
                          <tr key={index}>
                             {item.invoice_type === "Credit Note" &&
                            item?.status !== "issued" ? null : (
                              <td>
                                <span
                                  className="symbol-label ml-2 pointer"
                                  onClick={(e) => handleClick(e, index)}
                                >
                                  <span className="svg-icon svg-icon-sm svg-icon-primary">
                                    <SVG
                                      title=" "
                                      className="h-25 align-self-end"
                                      src={toAbsoluteUrl(
                                        "/media/svg/icons/General/Other2.svg"
                                      )}
                                    ></SVG>
                                  </span>
                                </span>
                                <div>
                                  <div>
                                    {checked === index ? (
                                      <Menu
                                        id="simple-menu"
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={Boolean(anchorEl)}
                                        onClose={() => handleClose("close", "")}
                                      >
                                        {console.log("item", item?.invoice_id && item?.invoice_id.includes("in_"))}
                                        {item?.invoice_id && item?.invoice_id.includes("in_") &&
                                        <MenuItem
                                          onClick={() =>
                                            handleClose("refund", item)
                                          }
                                          // id="kt_Refund_panel_toggle"
                                        >
                                          {intl.formatMessage({ id: "Refund" })}
                                        </MenuItem>
                      }
                                        {/* {item.invoice_type === "Credit Note" &&
                                        item?.status === "issued" ? (
                                          ""
                                        ) : (
                                          <MenuItem
                                            onClick={() =>
                                              handleClose("credit", item)
                                            }
                                          >
                                            {intl.formatMessage({
                                              id: "Credit.Note",
                                            })}
                                          </MenuItem>
                                        )} */}
                                        
                                        
                                        {item.invoice_type === "Credit Note" &&
                                        item?.status === "issued" ? (
                                          ""
                                        ) : (
                                          <MenuItem
                                            onClick={() =>
                                              handleClose("offline", item)
                                            }
                                          >
                                            {intl.formatMessage({
                                              id: "Offline",
                                            })}
                                          </MenuItem>
                                        )}
                                        {((item.invoice_type === "Credit Note" &&
                                        item?.status === "issued") || (item.invoice_type === "Invoice" && item.status === "paid" ))? (
                                          ""
                                        ) : (
                                          <>
                                            {item?.status !== "paid" &&
                                            item?.status !== "voided" ? (
                                              <MenuItem
                                                onClick={() =>
                                                  handleClose("void", item)
                                                }
                                              >
                                                Mark as Void
                                              </MenuItem>
                                            ) : (
                                              ""
                                            )}
                                          </>
                                        )}
                                        { item?.status === "open" && 
                                        <>
                                          <MenuItem
                                                onClick={() =>
                                                  {

                                                    setDialog({open:true, data:item?.invoice_id})
                                                    handleClose("close", "")
                                                  }
                                                }
                                                >
                                                {/* handleCollectionPayment(item?.invoice_id) */}
                                                Collect Payment
                                              </MenuItem>
                                              <MenuItem
                                                onClick={() =>
                                                 {

                                                   setDialog({open:true, data:item?.invoice_id, type:"sms"})
                                                   handleClose("close", "")
                                                 }
                                                }
                                                >
                                                {/* handleSendUrlLink(item?.invoice_id, "sms") */}
                                                SMS For Payment
                                              </MenuItem>
                                              <MenuItem
                                                onClick={() =>
                                                  {

                                                    setDialog({open:true, data:item?.invoice_id , type:"email"})
                                                    handleClose("close", "")
                                                  }
                                                }
                                                >
                                                 Email For Payment
                                              </MenuItem>
                                              </>  
                                        }
                                      </Menu>
                                    ) : null}
                                  </div>
                                </div>
                              </td>
                            )}
                            <td style={{ wordWrap: "break-word" }}>
                              {item?.booking_reference || "-"}
                            </td>
                            <td>{item?.invoice_type || "-"}</td>
                            <td>
                              {item?.currency
                                ? convertGBPtoPoundSymbol(item?.currency)
                                : null}

                              {item?.amount || "-"}
                            </td>
                            <td>{item?.period || "-"}</td>
                            <td>
                              {item?.created_at
                                ? moment(item?.created_at).format("DD-MM-yyyy")
                                : "-"}
                            </td>
                            <td> {item?.status || null}</td>
                            {item?.invoice_type !== "Refund" ?  <td>
                              <span
                                className="symbol-label ml-2 pointer"
                                onClick={() =>
                                  handleDownloadInvoice(
                                    item?.franchise_id,
                                    item?.invoice_id,
                                    item?.invoice_type
                                  )
                                }
                              >
                                <span className="svg-icon svg-icon-sm svg-icon-primary">
                                  <SVG
                                    title=" "
                                    className="h-25 align-self-end"
                                    src={toAbsoluteUrl(
                                      "/media/svg/icons/Files/Download.svg"
                                    )}
                                  ></SVG>
                                </span>
                              </span>
                            </td>: <td></td>}
                           
                           
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="offcanvas-footer">
        <div className="offcanvas-content text-right ">
          <button
            className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
            onClick={() => handleCloseCanva()}
          >
            {intl.formatMessage({ id: "CANCEL" })}
          </button>
        </div>
      </div>
      {snack.call ? <SnackBarTool {...snack} /> : ""}

      <InvoiceActionModal dialog={canva} setDialog={setCanva}>
        {canva.type === "refund" ? (
          <Refund
            setCanva={setCanva}
            canva={canva}
            orderDetailData={orderDetail}
          />
        ) : canva.type === "credit" ? (
          <CreditNotes
            setCanva={setCanva}
            canva={canva}
            orderDetailData={orderDetail}
          />
        ) : canva.type === "offline" ? (
          <OfflinePayment
            setCanva={setCanva}
            canva={canva}
            orderDetailData={orderDetail}
          />
        ) : (
          ""
        )}
     
      </InvoiceActionModal>
      <DynamicAlert
         dialog={dialog}
         setDialog={setDialog}
         header={"Alert"}
         mainText={"Are you sure you want to proceed with this action?"}
         handleChange={handleCollectionPayment}
         handleSendUrlLink={handleSendUrlLink}
        />
    </div>
  );
}
