/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

import React, { useEffect, useState } from "react";
import {
  fetchFranchiseDepotList,
  fetchFranchiseDepotListEmpty,
  fetchFranchiseUsingDepot,
} from "../../../_redux/depots/deoptsActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import SnackBarTool from "../../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { addDepotfranchise } from "../../../_redux/depots/depotsCrud";
import { canvaCancel } from "../../../../../utils/canvaCloseFunction";
import { useFormik } from "formik";
import { useIntl } from "react-intl";

export function LinkDepotToFranchiseCanvas(props) {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [arrayList, setArrayList] = useState([]);
  const [check, setCheck] = useState(false);

  const { detailData } = useSelector(
    (state) => ({
      detailData: state.depot.franchiseDepotList,
    }),
    shallowEqual
  );

  const initialValues = {
    franchise_id: [],
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleActive = () => {
    const arr = [...formik.values.franchise_id];
    detailData.selectedFranchise.forEach((item) => {
      arr.push(item.franchise_id);
    });
    setArrayList(arr);
    setCheck(true);
  };

  const handleSubmit = (values) => {
    const data = {
      depot_id:
        props && props.depotId && props.depotId.id ? props.depotId.id : "",
      franchise_id: values.franchise_id,
    };
    setSnack({ call: false, type: "", message: "" });
    addDepotfranchise(data)
      .then((res) => {
        setSnack({ call: true, type: "success", message: res.data.message });
        dispatch(fetchFranchiseUsingDepot(data.depot_id));
        dispatch(fetchFranchiseDepotList(data.depot_id));
        formik.resetForm();
        handleActive();
        canvaCancel("kt_link_depot_to_franchise_panel_close");
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
  };

  const handleReset = () => {};

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    onReset: handleReset,
  });

  useEffect(() => {
    if (detailData?.selectedFranchise && !check) {
      handleActive();
    }
  }, [check, detailData, handleActive]);

  useEffect(() => {
    return () => {
      dispatch(fetchFranchiseDepotListEmpty());
    };
  }, [dispatch]);

  const handleAdd = (e, id) => {
    const array = [...formik.values.franchise_id];
    const index = array.indexOf(id);

    if (index > -1) {
      array.splice(index, 1);
    } else {
      array.push(id);
    }

    formik.setFieldValue("franchise_id", array);
  };

  return (
    <div
      id="kt_link_depot_to_franchise_panel"
      className={`offcanvas offcanvas-right pt-5 pb-10 ${
        props.check ? "offcanvas-on" : ""
      }`}
    >
      {/*begin::Header*/}
      <div className=" container offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
        <div className="mt-4 mr-2" style={{ top: "15px", right: "10px" }}>
          <h5>{props.title}</h5>
        </div>
        <div
          className="offcanvas-close  pr-5"
          style={{ top: "15px", right: "10px" }}
        >
          <span
            onClick={() => formik.resetForm()}
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            id="kt_link_depot_to_franchise_panel_close"
          >
            <i className="ki ki-close icon-xs text-muted"></i>
          </span>
        </div>
      </div>

      {/*end::Header*/}

      {/*begin::Content*/}
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <div className="offcanvas-content px-10">
          <div className="tab-content">
            <div
              id="kt_quick_panel_logs_kt_link_depot_to_franchise_panel"
              role="tabpanel"
              className={`tab-pane fade pt-3 pr-5 mr-n5 scroll  active show `}
            >
              <div className="row">
                <div className="col-12">
                  {detailData &&
                  detailData.franchiseList &&
                  detailData.franchiseList.length
                    ? detailData?.franchiseList.map((data, index) => (
                        <div
                          className="col-12 col-sm-6 col-md-6 form-groupB row"
                          key={index}
                        >
                          <label className="col-6 col-sm-8 col-form-label ">
                            {data.name}
                          </label>
                          <div className="col-6 col-sm-4 text-right mt-1">
                            <span className="switch switch-outline switch-icon switch-primary">
                              <label>
                                <input
                                  type="checkbox"
                                  defaultChecked={arrayList.find(
                                    (element) => element === data.id
                                  )}
                                  disabled={arrayList.find(
                                    (element) => element === data.id
                                  )}
                                  name="quick_panel_notifications_2"
                                  onChange={(e) => handleAdd(e, data.id)}
                                />
                                <span></span>
                              </label>
                            </span>
                          </div>
                        </div>
                      ))
                    : ""}
                </div>
              </div>
            </div>
            {/*end::Content*/}
          </div>
        </div>
        <div className="offcanvas-footer">
          <div className="offcanvas-content text-right ">
            <button
              type="reset"
              className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
              onClick={() =>
                canvaCancel("kt_link_depot_to_franchise_panel_close")
              }
            >
              {intl.formatMessage({ id: "CANCEL" })}
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={!formik.dirty}
            >
              {intl.formatMessage({ id: "SAVE" })}
            </button>
          </div>
        </div>
      </form>

      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </div>
  );
}
