import React from "react";

export const LoadingCustome = () => {
  return (
    <div className="offcanvas-overlay " style={{zIndex:999999}}>
      <div className="customloader">
        <div className="spinner-border customSpinner" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  );
};
