import React from "react";
import { DashboardComponent } from "./DashboardComponent";

export function Dashboard() {
  return (
    <>
      <DashboardComponent />
    </>
  );
}
