/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { useSelector } from "react-redux";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import { initIds } from "../../../../_partials/controls/RightPanel";
import { useIntl } from "react-intl";

export function UserProfileDropdown() {
  const intl = useIntl();
  const init = ["kt_change_password_panel"];
  const [check, setCheck] = useState(false);

  useEffect(() => {
    if (check) initIds(init);
  }, [init, check]);
  const { user } = useSelector((state) => state.auth);

  return (
    <Dropdown
      drop="down"
      alignRight
      onClick={() => setCheck(check ? false : true)}
    >
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id="dropdown-toggle-user-profile"
      >
        <div
          className={
            "btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
          }
        >
          <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
            Hi,
          </span>{" "}
          <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
            {user && user.first_name ? user.first_name : ""}
          </span>
          <span className="symbol symbol-35 symbol-light-success">
            <span className="symbol-label font-size-h5 font-weight-bold">
              {user && user.first_name
                ? user.first_name.charAt(0).toUpperCase()
                : ""}
            </span>
          </span>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
        <div className="navi navi-spacer-x-0 pt-5">
          <a className="navi-item px-8">
            <div className="navi-link" id="kt_change_password_panel_toggle">
              <div className="navi-icon mr-2">
                <i className="flaticon2-hourglass text-primary"></i>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">
                  {intl.formatMessage({ id: "Change.Password" })}
                </div>
                <div className="text-muted">
                  {intl.formatMessage({ id: "Change.Your.Password" })}
                </div>
              </div>
            </div>
          </a>
          <div className="navi-separator mt-3"></div>

          <div className="navi-footer  px-8 py-5">
            <Link to="/logout" className="btn btn-primary font-weight-bold">
              {intl.formatMessage({ id: "Sign.Out" })}
            </Link>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
