/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { Modal, Table } from "react-bootstrap";
import React, { useState } from "react";
import { useFormik } from "formik";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import SnackBarTool from "../../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { LoadingSpinner } from "../../../../../../_metronic/_partials/controls/LoadingSpinner";
import { getInputClasses } from "../../../../../utils/commonFunction";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import { FormControl, MenuItem,  } from "@material-ui/core";
// import { chargebee } from "../../../_mocks/dummyData";
import { addProduct, getProduct } from "../../../_redux/order/orderCrud";
import { useEffect } from "react";
import { useOrderUIContext } from "../../../OrderUIContext";
import { getPruductObject } from "../../../EditOrderUtils";
// import { editProductCharge } from "../../../order-form/validationSchema";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  // FormLabel,
} from "@material-ui/core";
import NoRecord from "../../../../../../_metronic/_partials/widgets/NoRecord";
import { fetchOrderSummaryListStripe } from "../../../_redux/order/orderAction";
// import { TextField, Autocomplete } from "@material-ui/core";
import Select from 'react-select';


const AddProductModal = ({ dialog, setDialog, state, dispatchReducer }) => {
  const orderUIContext = useOrderUIContext();
  const dispatch = useDispatch();
  const { editOrderDetail, orderSummaryListStripe, callApi } = useSelector(
    (state) => ({
      editOrderDetail: state.order.editOrderDetail,
      orderSummaryListStripe: state.order.orderSummaryListStripe,
      callApi: state.order.callApi,
    }),
    shallowEqual
  );

  const [isLoading, setIsLoading] = useState(false);
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);
  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);
  const [isRtl, setIsRtl] = useState(false);
  const initialValues = {
    id: "",
    name: "",
    billing: "",
    days: "1",
    search: "",
    charge_once: "once",
    is_packaging_material: "",
  };

  const handleSubmit = (values) => {};

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    // validationSchema: dialog?.type === "charge" ? editProductCharge : "",
    onSubmit: handleSubmit,
  });

  const handleClose = () => {
    setDialog({ check: false });
  };
  console.log("orderSummaryListStripe", orderSummaryListStripe);
  useEffect(() => {
    // const delayDebounceFn = setTimeout(() => {
    dispatch(
      fetchOrderSummaryListStripe({
        franchise_id: editOrderDetail?.franchise_id,
        postcode: editOrderDetail?.postcode,
      })
    );
    // }, 1000);

    // return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editOrderDetail?.franchise_id]);

  const handleAddProduct = (item) => {
    console.log("item-90", item);
    const phaseIndex = state.phases.findIndex((e) => e.is_edit);
    let phase = state.phases.find((e) => e.is_edit);

    const arr = [...phase.items];
    const data = {
      productType: item?.type,
      billing_thresholds: null,
      discounts: [],
      metadata: [],
      plan: item?.stripe_product_id,
      price: item?.stripe_product_id,
      product_id: item?.stripe_product_id,
      product_name: item?.description,
      quantity: 1,
      tax_rates: [],
      unit_price:
        item?.product_prices?.length > 0 &&
        item?.product_prices[0]?.unit_amount,
    };
    arr.push(data);
    dispatchReducer({
      type: "SET_DATA",
      data: {
        ...state,
        phases: state.phases.map((phase, index) =>
          index === phaseIndex ? { ...phase, items: arr } : phase
        ),
        is_call_upcoming: true,
      },
    });
    setDialog({ check: false });
  };
  const handleChange = (selectedOption) => {
    console.log('Selected option:', selectedOption);
    // Perform your desired actions here
  };
  return (
    <Modal
      show={dialog?.check}
      aria-labelledby="example-modal-sizes-title-lg"
      onHide={handleClose}
    >
      <Modal.Header className="d-flex justify-content-between">
        <Modal.Title id="example-modal-sizes-title-lg">
          {dialog?.title}
        </Modal.Title>
        <span
          className="btn btn-xs btn-icon btn-light btn-hover-primary"
          onClick={handleClose}
        >
          <i className="ki ki-close icon-sm text-muted"></i>
        </span>
      </Modal.Header>
      <form onSubmit={formik.handleSubmit}>
        <Modal.Body
          style={{
            height: "60vh",
            overflow: "scroll",
          }}
        >
          <div className="row">
         

     
            {/* <div className="w-100 mb-4" style={{ position: "relative" }}>
              <input
                style={{ paddingRight: "40px" }}
                type="text"
                className="form-control"
                name="searchText"
                placeholder={"Search"}
                value={formik.values.search}
                onChange={(e) => {
                  formik.setFieldValue("search", e.target.value);
                  const value = e.target.value;
                  setTimeout(() => {
                    console.log("123123", value);
                    handleGetList(value);
                  }, 1500);
                  setFieldValue("searchTextBook", "");
                  setFieldValue("searchText", e.target.value);
                  setTimeout(() => {
                    handleSubmit();
                  }, 1000);
                }}
              />
              <i
                style={{
                  position: "absolute",
                  top: "50%",
                  transform: "translateY(-50%)",
                  right: "15px",
                }}
                className="fa fa-search"
                aria-hidden="true"
              ></i>
            </div> */}
            <div className="w-100">{callApi && <LoadingSpinner />}</div>
            {/* <Autocomplete
              id="combo-box-demo"
              options={orderSummaryListStripe?.allProducts ?? []} // Using optional chaining and nullish coalescing
              getOptionLabel={(option) => option?.description || ""} // Ensuring option and description are valid
              style={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Combo box" variant="outlined" />
              )}
            /> */}
            <div className="w-100"> <Select
        className="basic-single"
        classNamePrefix="select"
        // defaultValue={colourOptions[0]}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isClearable={isClearable}
        isRtl={isRtl}
        isSearchable={isSearchable}
        name="color"
        options={orderSummaryListStripe?.allProducts ?? []}
        getOptionLabel={(product) => product.name} 
        onChange={handleAddProduct}
      /></div>
            {/* <Table responsive className="summerytable customColor">
              <tbody>
                {orderSummaryListStripe &&
                orderSummaryListStripe?.allProducts?.length > 0 ? (
                  orderSummaryListStripe?.allProducts.map((item, index) => {
                    return (
                      <tr
                        onClick={() => {
                          handleAddProduct(item);
                        }}
                      >
                        <td>{item?.description}</td>
                      </tr>
                    );
                  })
                ) : (
                  <>{!isLoading && <NoRecord />}</>
                )}
              </tbody>
            </Table> */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              onClick={() => handleClose()}
              className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
              //   disabled={isLoading}
            >
              Dismiss
            </button>
            {/* <button
              type="submit"
              className="btn btn-primary font-weight-bolder font-size-sm "
              disabled={dialog?.type === "addon" && list && list.length === 0}
            >
              Save
            </button> */}
          </div>
        </Modal.Footer>
      </form>
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </Modal>
  );
};

export default AddProductModal;
