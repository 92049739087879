/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import { shallowEqual, useSelector } from "react-redux";

import DiscardDialog from "../../../../../../_metronic/_partials/widgets/alert/DiscardDialog";
import NoRecord from "../../../../../../_metronic/_partials/widgets/NoRecord";
import SVG from "react-inlinesvg";
import SnackBarTool from "../../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { canvaCancel } from "../../../../../utils/canvaCloseFunction";
import { fetchProductDetail } from "../../../_redux/products/productsActions";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import { updateFranchise } from "../../../_redux/products/productsCrud";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useFormik } from "formik";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));
export function LinkFranchiseCanva({ detailData }) {
  const intl = useIntl();
  // Declaring classes using useStyles hook
  const classes = useStyles();
  // Accessing dispatch function
  const dispatch = useDispatch();
  // Accessing franchiseList state
  const { franchiseList } = useSelector(
    (state) => ({
      franchiseList: state.products.franchiseList,
    }),
    shallowEqual
  );

  // Setting initial form values
  const initialValues = {
    franchise_charbee_key: [],
    sector_id: [],
  };

  // Declaring state variables for snackbar, dialog, check, loading and onFirst
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [dialogDis, setDialogDis] = useState(false);
  const [check, setCheck] = useState(false);
  const [loading, setLoading] = useState(false);
  const [onFirst, setOnFirst] = useState(false);

  // Handle submit form
  const handleSubmit = (values) => {
    setLoading(true);
    setSnack({ call: false, type: "", message: "" });
    // Calling updateFranchise API
    updateFranchise(values, detailData.id)
      .then((res) => {
        // Setting success message
        setSnack({ call: true, type: "success", message: res.data.message });
        dispatch(fetchProductDetail(detailData.id));

        setLoading(false);
        formik.resetForm();
        canvaCancel("kt_link_franchise_panel_close");
      })
      .catch((error) => {
        setLoading(false);
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
  };

  // Using useFormik hook to handle form
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,

    onSubmit: handleSubmit,
  });

  // Handle checkbox selection for franchise
  const handleFranchise = (e, data) => {
    setCheck(true);
    const arr = [...formik.values.franchise_charbee_key];
    if (e.target.checked) {
      arr.push(data.chargebee_key ? data.chargebee_key : data.id);
    } else {
      const charge = data.chargebee_key ? data.chargebee_key : data.id;
      const currentId = arr.findIndex((element) => element === charge);
      arr.splice(currentId, 1);
    }
    formik.setFieldValue("franchise_charbee_key", arr);
  };

  // Handle checkbox selection for franchise
  const handleSector = (e, data) => {
    setCheck(true);
    const arr = [...formik.values.sector_id];
    if (e.target.checked) {
      arr.push(data.id);
      formik.setFieldValue("sector_id", arr);
    } else {
      const charge = data.id;
      const currentId = arr.findIndex((element) => element === charge);
      arr.splice(currentId, 1);
      formik.setFieldValue("sector_id", arr);
    }
  };

  // Handle close event
  const handleClose = () => {
    if (check) {
      setDialogDis(true);
    } else {
      canvaCancel("kt_link_franchise_panel_close");
    }
  };

  // Handle select all sectors
  const handleAllSector = (e, data) => {
    if (e.target.checked) {
      const arr = [...formik.values.sector_id];

      for (let items of data.sector) {
        const check = formik.values.sector_id.find(
          (element) => element === items.id
        );
        if (!check) {
          arr.push(items.id);
        }
        formik.setFieldValue("sector_id", arr);
      }
    } else {
      const arr = [...formik.values.sector_id];

      for (let items of data.sector) {
        const check = arr.findIndex((element) => element === items.id);

        arr.splice(check, 1);
      }
      formik.setFieldValue("sector_id", arr);
    }
  };

  useEffect(() => {
    if (detailData?.metadata?.franchise && !dialogDis && onFirst) {
      formik.setFieldValue(
        "franchise_charbee_key",
        detailData?.metadata?.franchise
      );
    }
    if (detailData?.metadata?.sector && !dialogDis && onFirst) {
      formik.setFieldValue("sector_id", detailData?.metadata?.sector);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailData.metadata, dialogDis, detailData?.loading, onFirst]);

  useEffect(() => {
    if (detailData?.loading) {
      setOnFirst(true);
    }
  }, [detailData.loading]);
  return (
    <div
      id="kt_link_franchise_panel"
      className={`offcanvas offcanvas-right pt-5 pb-10 `}
    >
      {/*begin::Header*/}
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <div className=" container offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
          <div className="mt-4 mr-2" style={{ top: "15px", right: "10px" }}>
            <h5>Link Franchise</h5>
          </div>
          <div
            className="offcanvas-close  pr-5"
            style={{ top: "15px", right: "10px" }}
          >
            <span
              className="btn btn-xs btn-icon btn-light btn-hover-primary"
              onClick={() => handleClose()}
            >
              <i className="ki ki-close icon-xs text-muted"></i>
            </span>
            <div id="kt_link_franchise_panel_close"></div>
          </div>
        </div>

        {/*end::Header*/}

        {/*begin::Content*/}
        <div className="offcanvas-content px-10">
          <div className="tab-content">
            <div
              id="kt_quick_panel_logs_kt_link_franchise_panel"
              role="tabpanel"
              className={`tab-pane fade pt-3 pr-5 mr-n5 scroll  active show  `}
            >
              <div className="row">
                {franchiseList && franchiseList?.length > 0 ? (
                  franchiseList.map((data, index) => {
                    const charge = data.chargebee_key
                      ? data.chargebee_key
                      : data.id;
                    const datas = formik.values.franchise_charbee_key?.find(
                      (element) => element === charge
                    );
                    const sector = data.sector;
                    let all = [];
                    for (let items of sector) {
                      let itemCheck = "";
                      itemCheck = formik.values.sector_id.find(
                        (elemet) => elemet === items.id
                      );

                      if (itemCheck !== undefined) {
                        all.push(true);
                      } else {
                        all.push(false);
                      }
                    }

                    return (
                      <>
                        {data.sector && data.sector?.length ? (
                          <div
                            className="franchise"
                            style={{ width: "55%", marginLeft: "8px" }}
                          >
                            <div className={classes.root}>
                              <ExpansionPanel>
                                <ExpansionPanelSummary
                                  expandIcon={
                                    <span className="svg-icon svg-icon-sm svg-icon-primary">
                                      <SVG
                                        title=" "
                                        className="h-25 align-self-end"
                                        src={toAbsoluteUrl(
                                          "/media/svg/icons/Navigation/Angle-double-down.svg"
                                        )}
                                      ></SVG>
                                    </span>
                                  }
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <Typography>
                                    <label className="col-form-label">
                                      {data.name}
                                    </label>
                                  </Typography>
                                </ExpansionPanelSummary>
                                {data.sector.map((item, i) => {
                                  const charge = item.id;
                                  const datas = formik.values.sector_id?.find(
                                    (element) => element === charge
                                  );
                                  return (
                                    <>
                                      {i === 0 ? (
                                        <ExpansionPanelDetails>
                                          <div
                                            className="col-12 d-flex"
                                            style={{
                                              marginLeft: "-20px",
                                              marginTop: "-10px",
                                              marginBottom: "-10px",
                                            }}
                                          >
                                            {i === 0 ? (
                                              <>
                                                <label className="col-6 col-sm-6 col-md-6 col-form-label ">
                                                  All
                                                </label>
                                                <div className="col-6 col-sm-6 col-md-9 text-right mt-1">
                                                  <span className="switch switch-outline switch-icon switch-primary">
                                                    <label>
                                                      <input
                                                        type="checkbox"
                                                        name="quick_panel_notifications_2"
                                                        checked={all.every(
                                                          Boolean
                                                        )}
                                                        onClick={(e) =>
                                                          handleAllSector(
                                                            e,
                                                            data
                                                          )
                                                        }
                                                      />
                                                      <span></span>
                                                    </label>
                                                  </span>
                                                </div>
                                              </>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </ExpansionPanelDetails>
                                      ) : (
                                        ""
                                      )}

                                      <ExpansionPanelDetails>
                                        <div
                                          className="col-12 d-flex"
                                          style={{
                                            marginLeft: "-20px",
                                            marginTop: "-10px",
                                            marginBottom: "-10px",
                                          }}
                                        >
                                          <>
                                            <label className="col-6 col-sm-6 col-md-6 col-form-label ">
                                              {item.name}
                                            </label>
                                            <div className="col-6 col-sm-6 col-md-9 text-right mt-1">
                                              <span className="switch switch-outline switch-icon switch-primary">
                                                <label>
                                                  <input
                                                    type="checkbox"
                                                    name="quick_panel_notifications_2"
                                                    checked={
                                                      datas ? true : false
                                                    }
                                                    onClick={(e) =>
                                                      handleSector(e, item)
                                                    }
                                                  />
                                                  <span></span>
                                                </label>
                                              </span>
                                            </div>
                                          </>
                                        </div>
                                      </ExpansionPanelDetails>
                                    </>
                                  );
                                })}
                              </ExpansionPanel>
                            </div>
                          </div>
                        ) : (
                          <div className="col-12 d-flex">
                            <label className="col-6 col-sm-6 col-md-3 col-form-label ">
                              {data?.name}
                            </label>
                            <div className="col-6 col-sm-6 col-md-9 text-right mt-1">
                              <span className="switch switch-outline switch-icon switch-primary">
                                <label>
                                  <input
                                    type="checkbox"
                                    name="franchise_charbee_key"
                                    checked={datas ? true : false}
                                    onClick={(e) => handleFranchise(e, data)}
                                  />
                                  <span></span>
                                </label>
                              </span>
                            </div>
                          </div>
                        )}
                      </>
                    );
                  })
                ) : (
                  <div className="ml-6">
                    <NoRecord />
                  </div>
                )}
              </div>
            </div>
            {/*end::Content*/}
          </div>
        </div>
        <div className="offcanvas-footer">
          <div className="offcanvas-content text-right ">
            <button
              type="button"
              className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
              onClick={() => handleClose()}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary font-weight-bolder font-size-sm "
              type="submit"
            >
              {intl.formatMessage({ id: "SAVE" })}
              {loading && <span className="spinner spinner-white mr-4"></span>}
            </button>
          </div>
        </div>
      </form>
      <DiscardDialog
        setDialogDis={setDialogDis}
        dialogDis={dialogDis}
        formik={formik}
        closeId={"kt_link_franchise_panel_close"}
      />
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </div>
  );
}
