// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html

import * as actions from "../_redux/contact/contactAction";
import * as uiHelpers from "../ContactUIHelpers";

import React, { useEffect, useMemo, useState } from "react";
import {
  getHandlerTableChange,
  getSelectRow,
  headerSortingClasses,
  sortCaret,
} from "../../../../_metronic/_helpers";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { AssignContactCanvas } from "../canvas/AssignContactCanvas";
import BootstrapTable from "react-bootstrap-table-next";
import NoRecord from "../../../../_metronic/_partials/widgets/NoRecord";
import { Pagination } from "../../../../_metronic/_partials/controls";
import moment from "moment";
import { useContactUIContext } from "../ContactUIContext";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import VisibilityIcon from "@material-ui/icons/Visibility";

const ContactTable = () => {
  const intl = useIntl();

  // eslint-disable-next-line no-unused-vars
  const [show, setShow] = useState(true);

  let history = useHistory();

  const contactUIContext = useContactUIContext();
  const contactUIProps = useMemo(() => {
    return {
      ids: contactUIContext?.ids,
      setIds: contactUIContext?.setIds,
      queryParams: contactUIContext?.queryParams,
      setQueryParams: contactUIContext?.setQueryParams,
      openEditContactPage: contactUIContext?.openEditContactPage,
      openDeleteContactDialog: contactUIContext?.openDeleteContactDialog,
    };
  }, [contactUIContext]);

  const dispatch = useDispatch();

  const { currentState } = useSelector(
    (state) => ({
      currentState: state.contact.list,
      permission: state.auth.permission,
    }),
    shallowEqual
  );

  const { totalCount, entities, loading } = currentState;

  useEffect(() => {
    // clear selections list
    // server call by queryParams
    contactUIProps.setIds([]);
    dispatch(actions.fetchContact(contactUIProps.queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactUIProps.queryParams, dispatch]);

  useEffect(() => {
    contactUIProps.setQueryParams(uiHelpers.initialFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const columns = [
    {
      dataField: "users.first_name",
      text: intl.formatMessage({ id: "Contact.Name" }),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (e, data) => {
        return (
          <span>
            {data.user_data.first_name}&nbsp;{data.user_data.last_name}
          </span>
        );
      },
    },

    {
      dataField: "email",
      text: intl.formatMessage({ id: "EMAIL" }),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (e, data) => {
        return <span>{e && e?.length && e[0] ? e[0] : "-"}</span>;
      },
    },
    {
      dataField: "phone",
      text: intl.formatMessage({ id: "PHONE" }),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (e, data) => {
        return <span>{e && e?.length && e[0] ? e[0] : "-"}</span>;
      },
    },
    {
      dataField: "agent.first_name",
      text: intl.formatMessage({ id: "Agent" }),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (e, data) => {
        return (
          <span>
            {data && data.agent_data && data.agent_data.first_name
              ? data.agent_data.first_name
              : " - "}
          </span>
        );
      },
    },
    {
      dataField: "source",
      text: "Source",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (e, data) => {
        return <span>{(e && e) || "-"}</span>;
      },
    },
    {
      dataField: "last_activity_date",
      text: intl.formatMessage({ id: "Last.Activity.Date" }),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (e, data) => {
        return <span>{moment(e).format("DD-MM-YYYY")}</span>;
      },
    },
    {
      dataField: "created_at",
      text: intl.formatMessage({ id: "Create.Date" }),
      sort: true,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return <span>{moment(e).format("DD-MM-YYYY")}</span>;
      },
    },
    {
      // dataField: "created_at",
      text: "View",
      // sort: true,
      // sortCaret: sortCaret,
      formatter: (e, data) => {
        return (
          <span
            onClick={() => {
              history.push(`contact-details/${data.id}`);
              // window.open(`contact-details/${data.id}`, "_blank");
            }}
          >
            <VisibilityIcon />
          </span>
        );
      },
    },
  ];

  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: contactUIProps.queryParams?.pageSize,
    page: contactUIProps.queryParams?.pageNumber,
  };
  // const rowEvents = {
  //   onClick: (e, row, rowIndex) => {
  //     history.push(`contact-details/${row.id}`);
  //   },
  // };
  // const rowEvents = {
  //   onClick: (e, row, rowIndex) => {
  //     window.open(`contact-details/${row.id}`, "_blank");
  //   },
  // };

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <>
              <Pagination
                isLoading={loading}
                paginationProps={paginationProps}
                entities={entities}
              >
                <BootstrapTable
                  // rowEvents={rowEvents}
                  hover
                  wrapperClasses="table-responsive"
                  bordered={false}
                  classes="table table-head-custom table-vertical-center overflow-hidden"
                  bootstrap4
                  remote
                  keyField="id"
                  defaultSorted={uiHelpers.defaultSorted}
                  selectRow={getSelectRow({
                    entities,
                    ids: contactUIProps.ids,
                    setIds: contactUIProps.setIds,
                  })}
                  data={entities === null ? [] : entities}
                  onTableChange={getHandlerTableChange(
                    contactUIProps.setQueryParams
                  )}
                  columns={columns}
                  {...paginationTableProps}
                ></BootstrapTable>

                {(entities && entities?.length) || loading ? "" : <NoRecord />}
              </Pagination>
            </>
          );
        }}
      </PaginationProvider>

      <AssignContactCanvas setShow={setShow} />
    </>
  );
};

export default ContactTable;
