import axios from "axios";
export const COUNTRY = "countries";
export const ADD_COUNTRY = "country";
export const DELETE = "container/delete/master-container";
export const CONTAINER_FRANCHISE = "franchise-container/container-listing"
export const  CONTAINER_LIST = "container/container"

export function deleteContainerM(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + DELETE, data);
}
//api for active country
export function findActiveCountry() {
  return axios.get(process.env.REACT_APP_SITE_URL + COUNTRY);
}

//api for all type of country (active or deactive)
export function findCountryList(queryParams) {
  return axios.get(
    process.env.REACT_APP_SITE_URL +
      ADD_COUNTRY +
      `?page=${queryParams.pageNumber}&per_page=${queryParams.pageSize}&sort_by=${queryParams.sortField}&sort_dir=${queryParams.sortOrder}&search_keyword=${queryParams.filter.search}`
  );
}
//api for all type of country (active or deactive)
export function getContainerFranchise(queryParams) {
  return axios.get(
    process.env.REACT_APP_SITE_URL +
    CONTAINER_FRANCHISE +
      `?page=${queryParams.pageNumber}&per_page=${queryParams.pageSize}&franchise_id=${queryParams.franchise_id}`
  );
}
export function containerList(queryParams) {
  return axios.get(
    `${process.env.REACT_APP_SITE_URL + CONTAINER_LIST}?page=${
      queryParams.pageNumber
    }&per_page=${queryParams.pageSize}&sort_by=${
      queryParams.sortField
    }&sort_dir=${queryParams.sortOrder}`
  );
}

export function createContainer(data , edit) {
  return axios.post(process.env.REACT_APP_SITE_URL + CONTAINER_LIST+ edit, data);
}