/* eslint-disable array-callback-return */
import React, { useCallback, useEffect, useRef, useState } from "react";

import FullCalendar from "@fullcalendar/react"; // must go before plugins
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import moment from "moment";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline"; // a plugin!
import SnackBarTool from "../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { CustomeProgressBar } from "../../../../_metronic/_partials/controls/CustomProgressBar";
import { breakString } from "../../../utils/commonFunction";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { paymentDue, timeSlotUpdate, unblockSlot } from "../redux/calendarCrud";
import { Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import { capitalizeFirstLetter } from "../../../utils/canvaCloseFunction";
import AcUnitIcon from "@material-ui/icons/AcUnit";
import { LoadingSpinner } from "../../../../_metronic/_partials/controls/LoadingSpinner";
import dayGridPlugin from "@fullcalendar/daygrid";

export const CalendarComponent = ({
  collectionDate,
  calendarData,
  formik,
  keys,
  activeEvent,
  setActiveEvent,
  type,
  setActionButton,
  actionButton,
  isOverWrite,
  setFormData,
  formData,
  keyCon,
  setContainerData,
}) => {
  const calendarRef = useRef();
  const abortControllerRef = useRef(null);

  // const history = useHistory();
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [dialog, setDialog] = useState({ type: false, event: "", check: "" });
  const [invoice, setInvoice] = useState({ loading: false, is_invoice: 0 });

  const getCalendarApi = () => {
    const { current } = calendarRef;
    return current?.getApi();
  };
  const handleDate = useCallback(() => {
    const calendarApi = getCalendarApi();
    if (calendarApi) {
      calendarApi.gotoDate(collectionDate);
    }
  }, [collectionDate]);
  useEffect(() => {
    if (collectionDate) {
      handleDate();
    }
  }, [collectionDate, handleDate]);
  const handleEventClick = (e) => {
    if (type === "calendar") {
      // if (e?.event?.extendedProps?.hoverDetails?.order_id) {
      //   history.push(
      //     `/order-details/${e.event.extendedProps.hoverDetails.order_id}`
      //   );
      // }
      if (e?.event?.extendedProps?.hoverDetails?.order_id) {
        const url = `/order-details/${e.event.extendedProps.hoverDetails.order_id}`;
        window.open(url, "_blank");
      }
      return;
    }
    setSnack({
      call: false,
      type: "",
      message: "",
    });
    let eventObj = e.event;

    if (activeEvent) {
      activeEvent.setProp("classNames", []);
    }
    eventObj.setProp("classNames", ["clicked-event"]);
    setActiveEvent(eventObj);
    if (eventObj?.backgroundColor === "white") {
      keys === "collection"
        ? formik.setValues({
            ...formik.values,
            collection_date: moment(eventObj?.startStr).format("YYYY-MM-DD"),
            start_time_slot: moment.utc(eventObj?.startStr).format("HH:mm"),
            end_time_slot: moment.utc(eventObj?.endStr).format("HH:mm"),
            collection_van_id: eventObj?._def?.resourceIds[0] || "",
            van_id: eventObj?._def?.resourceIds[0] || "",
            is_student: "0",
            is_return: "0",
            max_pod: eventObj?.extendedProps?.totalPodsAvailable || "",
          })
        : formik.setValues({
            ...formik.values,
            return_date: moment(eventObj?.startStr).format("YYYY-MM-DD"),
            return_start_time_slot: moment
              .utc(eventObj?.startStr)
              .format("HH:mm"),
            return_end_time_slot: moment.utc(eventObj?.endStr).format("HH:mm"),
            return_van_id: eventObj?._def?.resourceIds[0] || "",
            van_id: eventObj?._def?.resourceIds[0] || "",
            is_student_return: "0",
            is_return: formik?.values?.is_return === "0" ? "0" : "1",

            // is_return: formik?.values?.is_return === "0" ? "0" : "1",
          });
      if (isOverWrite) {
        setDialog({ type: false, event: "", check: "" });
      }
    } else {
      // setSnack({ call: true, type: "error", message: "Can't add this" });
      if (isOverWrite) {
        setDialog({ type: false, event: "", check: "" });
      }
    }
  };

  const handleResourceLabelContent = (e) => {
    if (keyCon === "container") return;
    const podCount = e?.fieldValue.charAt(e?.fieldValue?.length - 3);
    const progress = Number(podCount) * 16.67;
    const string = breakString(e?.fieldValue, "Availability");
    return (
      <div>
        {string?.length > -1 &&
          string?.map((data, index) => (
            <div key={index}>
              {index === 0 ? (
                <h4 key={index}>{data}</h4>
              ) : (
                <span key={index}>{data}</span>
              )}
            </div>
          ))}
        <div className="mt-2" style={{ width: "80%" }}>
          <CustomeProgressBar variant={"warning"} now={progress} />
        </div>
      </div>
    );
  };

  const intl = useIntl();
  const handleResize = (event, check) => {
    const value = event?.event;
    const data = {
      order_id: value?.extendedProps?.hoverDetails?.order_id || "",
      order_type:
        dialog.color === "#009933"
          ? "Collection"
          : dialog.color === "#ff6601"
          ? "Return"
          : dialog.block === "Block"
          ? "Block"
          : dialog.block === "Travel"
          ? "Travel"
          : "",
      time_from: moment.utc(value?.startStr).format("HH:mm") || "",
      time_to: moment.utc(value?.endStr).format("HH:mm") || "",
    };
    if (dialog.block === "Travel" || dialog?.block === "Block") {
      data.block_id = value?.extendedProps?.hoverDetails?.blockId || "";
    }
    if (check === "drop") {
      data.van_id = event?.newResource?.id || "";
    }
    timeSlotUpdate(data).then((res) => {
      setActionButton({ ...actionButton, check: "resize" });
      setDialog({ type: false, event: "", check: "" });
    });
  };

  const handleDelete = (eventInfo) => {
    if (type !== "calendar") return;

    if (
      eventInfo?.event?.extendedProps?.block === "Block" ||
      eventInfo?.event?.extendedProps?.block === "Travel"
    ) {
      setSnack({
        call: false,
        type: "",
        message: "",
      });
      const data = {
        id: eventInfo?.event?.extendedProps?.hoverDetails?.blockId || "",
      };
      unblockSlot(data)
        .then((res) => {
          setActionButton({ ...actionButton, check: "resize" });
          setSnack({ call: true, type: "success", message: res.data.message });
          setDialog({ type: false, event: "", check: "" });
        })
        .catch((error) => {
          // On error, update snackbar message
          setSnack({
            call: true,
            type: "error",
            message: error.response.data.message,
          });
        });
    }
  };

  const [validRange, setValidRange] = useState({});

  useEffect(() => {
    const handleDates = (currentDate) => {
      const start = currentDate.toISOString().split("T")[0];
      setValidRange({ start });
    };

    const collectionDate = formik?.values?.collection_date;
    const currentDate = new Date();

    if (type !== "calendar") {
      if (collectionDate && keys === "return") {
        const nextDate = new Date(collectionDate);
        nextDate.setDate(nextDate.getDate() ); // Get the next date after the collection date
        handleDates(nextDate);
      } else if (collectionDate) {
        const dates = new Date(collectionDate);
        if (dates < currentDate) {
          handleDates(dates);
        } else {
          handleDates(currentDate);
        }
      } else {
        handleDates(currentDate); // Call handleDates once during initial render
      }
    }

    return () => {
      // Cleanup function
      setValidRange({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const eventRender = (event, element) => {
    // Add custom icon to the event element
    const iconClass = event.iconClass;
    // if (iconClass) {
    element
      .find("div.fc-timeline-event")
      .prepend(`<i className="fa fa-star"></i> `);
    // }
  };

  const handlePaymentDue = (value) => {
    setInvoice({
      loading: true,
      is_invoice: 0,
    });
    if (abortControllerRef?.current) {
      abortControllerRef.current.abort();
    }
    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    paymentDue(value, abortController).then((res) => {
      setInvoice({
        loading: false,
        is_invoice: res?.data?.data?.is_invoices_due === 1 ? 1 : 0,
      });
    });
  };
  const dummy = [
    {
      id: 37,
      title: "Class A",
      allotted_pods: null,
      pods_available: 6,
    },
    {
      id: 38,
      title: "Class B",
      allotted_pods: null,
      pods_available: 6,
    },
  ];
  const dummyEvent = [
    {
      resourceId: 37,
      title: "Guide 6 Pods 08:00-12:00",
      start: "2024-02-19T08:00:00",
      end: "2024-02-19T13:00:00",
      textColor: "black",
      color: "lightgrey",
      backgroundColor: "white",
      totalPodsAvailable: 6,
    },
    {
      resourceId: 37,
      title: "Guide 3 Pods 13:00-17:00",
      start: "2024-02-19T13:00:00+00:00",
      end: "2024-02-19T18:00:00+00:00",
      textColor: "black",
      color: "lightgrey",
      backgroundColor: "white",
      totalPodsAvailable: 3,
    },
    {
      resourceId: 38,
      title: "Guide 6 Pods 08:00-12:00",
      start: "2024-02-19T08:00:00",
      end: "2024-02-19T13:00:00",
      textColor: "black",
      color: "lightgrey",
      backgroundColor: "white",
      totalPodsAvailable: 6,
    },
    {
      resourceId: 38,
      title: "Guide 3 Pods 13:00-17:00",
      start: "2024-02-19T13:00:00+00:00",
      end: "2024-02-19T18:00:00+00:00",
      textColor: "black",
      color: "lightgrey",
      backgroundColor: "white",
      totalPodsAvailable: 3,
    },
  ];

  return (
    <>
      <FullCalendar
        className="hello "
        navLinks={false}
        ref={calendarRef}
        validRange={validRange}
        initialDate={moment(
          collectionDate ? collectionDate : new Date()
        ).format("YYYY-MM-DD")}
        eventAllow={(dropInfo, event) => {
          if (
            event?.backgroundColor === "white" ||
            event?.extendedProps?.hoverDetails?.is_completed === 1
          ) {
            return false;
          } else {
            return true;
          }
        }}
        editable={type === "calendar" ? true : false}
        // selectable={true}
        datesSet={(e) => {
          // if (keyCon === "container") {
          //   const startOfWeek = moment(e?.startStr)
          //     .startOf("week")
          //     .format("DD-MM-yyyy");
          //   const endOfWeek = moment(e?.startStr)
          //     .endOf("week")
          //     .format("DD-MM-yyyy");
          //   setContainerData({
          //     startOfWeek: startOfWeek,
          //     endOfWeek: endOfWeek,
          //   });
          //   return;
          // }
          if (type === "calendar") {
            return setFormData({
              ...formData,
              date: moment(e?.startStr).format("yyyy-MM-DD"),
            });
          } else if (
            (keys === "collection" && formik.values?.collection_date) ||
            (keys === "return" && formik.values?.return_date)
          ) {
            return formik?.setFieldValue(
              keys === "collection" ? "collection_date" : "return_date",
              moment(e?.startStr).format("yyyy-MM-DD")
            );
          }
        }}
        resourceAreaWidth="20%"
        contentHeight="auto"
        schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
        plugins={[resourceTimelinePlugin, interactionPlugin, dayGridPlugin]}
        defaultView="resourceTimelineDay"
        height={300}
        timeZone="UTC"
        initialView="resourceTimeline"
        slotMinTime="08:00:00"
        slotMaxTime={
          actionButton?.type === "operation" ? "20:30:00" : "18:00:00"
        }
        headerToolbar={{
          left: "",
          center: "prev,title,next",
          right: "",
        }}
        //customWeeks,customMonth,customDay
        views={{
          customWeeks: {
            type: "resourceTimeline",
            duration: { weeks: 1 },
            slotDuration: { days: 2 },
            buttonText: "Week",
          },

          customMonth: {
            type: "resourceTimeline",
            duration: { months: 1 },
            slotDuration: { days: 1 },
            buttonText: "Month",
          },
          customDay: {
            type: "resourceTimeline",
            duration: { days: 1 },
            slotDuration: { days: 1 },
            buttonText: "Day",
          },
        }}
        slotLabelInterval={
          actionButton?.type === "operation" ? "00:30:00" : "01:00:00"
        }
        // customButtons={customButtons}
        eventClick={(e) => {
          if (isOverWrite) {
            setDialog({
              type: true,
              event: e,
              check: "over",
            });
            return;
          }
          handleEventClick(e);
        }}
        eventResize={(event) => {
          setDialog({
            type: true,
            event,
            block: event?.event?.extendedProps?.block,
            color: event?.event?.backgroundColor,
          });
        }}
        eventDrop={(event) => {
          setDialog({
            type: true,
            event,
            check: "drop",
            block: event?.event?.extendedProps?.block,
            color: event?.event?.backgroundColor,
          });
          // handleResize(event, "drop");
        }}
        event
        resourceAreaHeaderContent=" "
        resourcesInitiallyExpanded={false}
        resourceOrder="from"
        resources={
           calendarData?.data?.vans?.resources
        }
        events={
          keyCon === "container"
            ? calendarData?.data?.site_class?.events
            : calendarData?.data?.vans?.events
        }
        eventRender={eventRender}
        resourceLabelContent={handleResourceLabelContent}
        eventContent={(eventInfo) => {
          return (
            <>
              <div>
                {eventInfo.event?.extendedProps?.hoverDetails ? (
                  <div
                    style={{ backgroundColor: `${eventInfo.event.color}` }}
                    onMouseEnter={() =>
                      handlePaymentDue(
                        eventInfo.event?.extendedProps?.hoverDetails?.order_id
                      )
                    }
                  >
                    <OverlayTrigger
                      overlay={
                        <Tooltip
                          id="products-edit-tooltip"
                          className="tooltipwth"
                        >
                          <form style={{ width: "400px !important" }}>
                            <div className="row">
                              <>
                               
                                {invoice?.is_invoice > 0 ? (
                                  <span
                                    className={`badge badge-pill ml-2 mb-4 ${"badge-danger"}`}
                                  >
                                    {"Payment due"}
                                  </span>
                                ) : invoice.loading ? (
                                  <span className="ml-2">
                                    <LoadingSpinner />
                                  </span>
                                ) : (
                                  ""
                                )}
                                {Object.entries(
                                  eventInfo?.event?.extendedProps?.hoverDetails
                                ).map(([keys, value], index) => {
                                  if (
                                    keys === "order_id" ||
                                    keys === "blockId" ||
                                    (keys === "is_invoices_due" && value < 1)
                                  ) {
                                    return;
                                  }
                                  return (
                                    <div
                                      className="col-12"
                                      key={index}
                                      style={{
                                        width: "200px",
                                        color:
                                          keys === "is_invoices_due" &&
                                          value > 0
                                            ? "red"
                                            : "",
                                      }}
                                    >
                                      <div className="mt-4 d-flex  mb-2">
                                        {keys !== "message" &&
                                          keys !== "blockId" && (
                                            <span
                                              className="font-weight-bolder "
                                              style={{ fontSize: "1em" }}
                                            >
                                              {keys === "is_invoices_due" &&
                                              value > 0 ? (
                                                <span
                                                  className={`badge badge-pill ${"badge-danger"}`}
                                                >
                                                  {"Payment due"}
                                                </span>
                                              ) : (
                                                capitalizeFirstLetter(
                                                  keys?.replace(/_/g, " ")
                                                ) + ":"
                                              )}{" "}
                                            </span>
                                          )}
                                        <span
                                          className="  ml-1"
                                          style={{ fontSize: "1em" }}
                                        >
                                          {keys === "is_invoices_due" &&
                                          value > 0
                                            ? ""
                                            : value}
                                        </span>
                                      </div>
                                    </div>
                                  );
                                })}
                              </>
                            </div>
                          </form>
                        </Tooltip>
                      }
                    >
                      <div
                        className="fc-event-main-frame svg-icon svg-icon-md svg-icon-primary"
                        onDoubleClick={() => {
                          if (type !== "calendar") return;
                          if (eventInfo?.backgroundColor === "#242939") {
                            setDialog({
                              type: true,
                              event: eventInfo,
                              block: eventInfo?.event?.extendedProps?.block,
                              // color: eventInfo?.backgroundColor,
                              check: "drop",
                              isDelete: true,
                            });
                          }
                        }}
                      >
                        <div className="fc-event-title-container">
                          <div
                            className="fc-event-title fc-sticky"
                            style={{ top: "0px" }}
                          >
                            {eventInfo.event?.extendedProps?.hoverDetails
                              ?.is_invoices_due > 0 && (
                              <i
                                className="fa fa-exclamation-circle mr-2"
                                style={{ color: "#544b4b" }}
                              ></i>
                            )}
                            {eventInfo?.event?.title}&nbsp;
                            {eventInfo?.event?.extendedProps?.block === "Block"
                              ? eventInfo?.event?.extendedProps?.hoverDetails
                                  ?.reason
                              : ""}
                          </div>
                        </div>
                      </div>
                    </OverlayTrigger>
                  </div>
                ) : (
                  <div
                    className="fc-event-main-frame svg-icon svg-icon-md svg-icon-primary"
                    onDoubleClick={() => {
                      if (type !== "calendar") return;
                      if (eventInfo.backgroundColor === "#242939") {
                        setDialog({
                          type: true,
                          event: eventInfo,
                          block: eventInfo?.event?.extendedProps?.block,
                          // color: eventInfo?.backgroundColor,
                          check: "drop",
                          isDelete: true,
                        });
                      }
                    }}
                  >
                    <div className="fc-event-title-container">
                      <div
                        className="fc-event-title fc-sticky"
                        style={{ top: "0px" }}
                      >
                        {eventInfo?.event?.title}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          );
        }}
        // viewSkeletonRender={handleViewRender}
      />
      {snack.call ? <SnackBarTool {...snack} /> : ""}
      <Modal show={dialog?.type} aria-labelledby="example-modal-sizes-title-lg">
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            {// isOverWrite ? (
            //   intl.formatMessage({ id: "Overwrite.Maximum.Slot" })
            // )
            // :
            dialog?.isDelete ? (
              <>
                {dialog.block === "Travel"
                  ? intl.formatMessage({ id: "Unblock.Travel.Time" })
                  : dialog?.block === "Block"
                  ? intl.formatMessage({ id: "Unblocking.Slot" })
                  : ""}
              </>
            ) : (
              <>
                {dialog.color === "#ff6601" || dialog.color === "#009933"
                  ? intl.formatMessage({ id: "Change.Job.Time.Slot" })
                  : dialog.block === "Travel"
                  ? intl.formatMessage({ id: "Change.Travel.Time" })
                  : dialog?.block === "Block"
                  ? intl.formatMessage({ id: "Change.Slot.Time" })
                  : ""}
              </>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {// isOverWrite ? (
          //   intl.formatMessage({ id: "Have.you.approval.overwrite.time.slot" })
          // )
          // :
          dialog?.isDelete ? (
            <span>
              {dialog.block === "Travel"
                ? intl.formatMessage({ id: "Are.unblock.travel.time" })
                : dialog?.block === "Block"
                ? intl.formatMessage({ id: "Are.unblock.slot?" })
                : ""}
            </span>
          ) : (
            <>
              <span>
                {dialog.color === "#ff6601" || dialog.color === "#009933"
                  ? intl.formatMessage({ id: "Are.Job.Time.Slot?" })
                  : dialog.block === "Travel"
                  ? intl.formatMessage({ id: "Are.travel.time" })
                  : dialog?.block === "Block"
                  ? intl.formatMessage({ id: "Are.slot.time" })
                  : ""}
              </span>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              onClick={() => {
                setDialog({ type: false, event: "", check: "" });
                if (isOverWrite) return;
                setActionButton({ ...actionButton, check: "resize" });
              }}
              className="btn btn-light btn-elevate"
            >
              {intl.formatMessage({ id: "No" })}
            </button>
            <> </>
            <button
              type="button"
              onClick={() => {
                isOverWrite
                  ? handleEventClick(dialog?.event)
                  : dialog?.isDelete
                  ? handleDelete(dialog?.event)
                  : handleResize(dialog?.event, dialog?.check);
              }}
              className="btn btn-delete btn-elevate"
            >
              {intl.formatMessage({ id: "Yes" })}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

// {
//   color: "grey",
//   end: "2023-02-03T17:05:00+00:00",
//   resourceId: 27,
//   start: "2023-02-03T08:00:00+00:00",
//   title: "08:00-16:05",
//   display: "block",
//   eventOverlap: false,
//   rendering: "background",
// },
