/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

import * as Yup from "yup";

import React, { useState } from "react";
import { Form, InputGroup } from "react-bootstrap";

import SnackBarTool from "../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { canvaCancel } from "../../../utils/canvaCloseFunction";
import { getInputClasses } from "../../../utils/commonFunction";
import { sendEmail } from "../form/validationSchema";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { useIntl } from "react-intl";

export function SendEmail({
  ID,
  submitFunction,
  listFunction,
  dealId,
  type,
  detailData,
}) {
  // Use the useIntl hook to access the intl object
  const intl = useIntl();

  // Initial values for the form
  const initialValues = {
    contact_id: ID ? ID : "",
    email_to:
      detailData && detailData.user_data && detailData.user_data.email
        ? detailData.user_data.email
        : "",

    subject: "easyStorage your enquiry",
    description: "",
  };

  // Initial values for the form when type is 'Deal'
  const initialDeals = {
    contact_id: ID ? ID : "",
    deal_id: dealId ? dealId : "",
    email_to:
      detailData && detailData.user_data && detailData.user_data.email
        ? detailData.user_data.email
        : "",
    subject: "easyStorage your enquiry",
    description: "",
  };

  // State for the snackbar
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  // State for the loading spinner
  const [loading, setLoading] = useState(false);
  // Use the useDispatch hook to access the dispatch function
  const dispatch = useDispatch();

  // Handles form submission
  const handleSubmit = (values, actions) => {
    setLoading(true);
    setSnack({ call: false, type: "", message: "" });
    submitFunction(values)
      .then((res) => {
        setSnack({ call: true, type: "success", message: res.data.message });
        // Dispatch the appropriate action depending on the type
        dispatch(type === "Deal" ? listFunction(dealId, ID) : listFunction(ID));
        canvaCancel("kt_SendEmail_panel_close");
        setLoading(false);
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
        setLoading(false);
      });
  };

  // Use the useFormik hook to setup the form
  const formik = useFormik({
    initialValues: type === "Deal" ? initialDeals : initialValues,
    enableReinitialize: true,
    validationSchema: sendEmail(intl, Yup),
    onSubmit: handleSubmit,
  });

  // Render the form
  return (
    <div
      id="kt_SendEmail_panel"
      className="offcanvas offcanvas-right pt-5 pb-10"
    >
      {/*begin::Header*/}
      <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
        <div
          className="offcanvas-close mt-n1 pr-5"
          style={{ position: "absolute", top: "15px", right: "10px" }}
        >
          <span
            onClick={() => formik.resetForm()}
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            id="kt_SendEmail_panel_close"
          >
            <i className="ki ki-close icon-sm text-muted"></i>
          </span>
        </div>
      </div>
      {/*end::Header*/}
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <div className="offcanvas-content px-10 ">
          <div>
            <h5>Send Email</h5>
          </div>
          <div
            className="fade pt-3 pr-5 mr-n5 scroll active show ps--active-y mt-10"
            id="kt_quick_panel_logs_kt_SendEmail_panel"
          >
            <div className="mb-3">
              <Form.Group className="mb-3">
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text id="inputGroupPrepend">
                      <strong>To</strong>
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    type="email"
                    disabled
                    placeholder="helpdesk@example.com"
                    aria-describedby="inputGroupPrepend"
                    className={`form-control  ${getInputClasses(
                      formik,
                      "email_to"
                    )}`}
                    name="email_to"
                    {...formik.getFieldProps("email_to")}
                  />
                  {formik.touched.email_to && formik.errors.email_to ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.email_to}
                      </div>
                    </div>
                  ) : null}
                </InputGroup>
              </Form.Group>
              <Form.Group>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text id="inputGroupPrepend">
                      <strong>Subject</strong>
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    type="text"
                    placeholder="Your easyStorage quote"
                    aria-describedby="inputGroupPrepend"
                    className={`form-control  ${getInputClasses(
                      formik,
                      "subject"
                    )}`}
                    name="subject"
                    {...formik.getFieldProps("subject")}
                  />
                </InputGroup>
                {formik.touched.subject && formik.errors.subject ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.subject}</div>
                  </div>
                ) : null}
              </Form.Group>
            </div>
            <div className="mt-10 pb-10">
              <Form.Label>
                {intl.formatMessage({ id: "Description" })}
              </Form.Label>
              <Form.Control
                as="textarea"
                rows="9"
                className={`form-control  ${getInputClasses(
                  formik,
                  "description"
                )}`}
                name="description"
                {...formik.getFieldProps("description")}
              />

              {formik.touched.description && formik.errors.description ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.description}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="offcanvas-footer">
          <div className="offcanvas-content text-right ">
            <button
              type="reset"
              className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
              onClick={() => canvaCancel("kt_SendEmail_panel_close")}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary font-weight-bolder font-size-sm "
              disabled={loading}
            >
              Send
              {loading && (
                <span className="ml-3 mr-3 spinner spinner-white"></span>
              )}
            </button>
          </div>
        </div>
      </form>
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </div>
  );
}
