import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import {initialFilter} from "../Logs component/LogsUIHelpers"

const LogsUIContext = createContext();

export function useLogsUIContext() {
  return useContext(LogsUIContext);
}

export const LogsUIConsumer = LogsUIContext.Consumer;

export function LogsUIProvider({ LogsUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    openEditLogPage: LogsUIEvents?.openEditLogPage,
    openDeleteLogDialog: LogsUIEvents?.openDeleteLogDialog,
    openDeleteLogsDialog: LogsUIEvents?.openDeleteLogsDialog,
    openFetchLogsDialog: LogsUIEvents?.openFetchlogsDialog,
    openUpdateLogsStatusDialog:
    LogsUIEvents?.openUpdateLogsStatusDialog,
  };

  return (
    <LogsUIContext.Provider value={value}>
      {children}
    </LogsUIContext.Provider>
  );
}
