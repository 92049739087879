import React, { Fragment } from "react";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function SnackBarTool({ type, message, error, time }) {
  const [open, setOpen] = React.useState(true);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  let errorArray = [];
  if (error) {
    errorArray = Object.keys(error);
  }
  return (
    <>
      {error ? (
        <>
          {errorArray &&
            errorArray.length &&
            errorArray.map((key, index) => {
              return (
                <Fragment key={index}>
                  <Snackbar
                    className={`mt-${
                      index === 0
                        ? "0"
                        : index === 1
                        ? "16"
                        : index === 2
                        ? "32"
                        : ""
                    }`}
                    open={open}
                    autoHideDuration={time ? +time : 4000}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  >
                    <Alert severity={"error"}>{error[key][0]}</Alert>
                  </Snackbar>
                </Fragment>
              );
            })}
        </>
      ) : (
        <Snackbar
          open={open}
          autoHideDuration={type === "error" ? 30000 : 4000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={handleClose} severity={type}>
            {message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}
