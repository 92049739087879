import axios from "axios";

export const DEALS_URL = "api/deals";
export const DEALS = "deal";
export const CONTACT_URL_LIVE = "contact";
export const CONTACT_AGENT = "agent-list";
export const DEALS_STATUS = "deal/update-deals-status";
export const DEALS_AGENT = "/assign-deal";
export const DEALS_CARD_UPDATE = "/details/update/";
export const DEALS_NOTES = "/add/notes";
export const DEALS_NOTES_LISTING = "/notes/list?";
export const DEALS_TASKS = "/add/tasks";
export const DEALS_TASKS_LIST = "/tasks/list";
export const SEND_EMAIL = "/email/send-contact-mail";
export const SEND_EMAIL_LISTING = "/email/list";
export const SMS_LISTING = "/sms/list";
export const SEND_SMS = "/add/sms";
export const DEAL_QUOTES = "/quotes/list?deal_id=";
export const DELETE = "/delete/deal";
export const CONTACT_QUOTE = "/quotes/deals?contact_id=";

export function getAllProducts() {
  return axios.get(DEALS_URL);
}
export function findDeals(queryParams) {
  return axios.post(`${DEALS}/find`, { queryParams });
}

//deal list
export function dealsListing(queryParams) {
  return axios.get(
    `${process.env.REACT_APP_SITE_URL + DEALS}?page=${
      queryParams.pageNumber
    }&per_page=${queryParams.pageSize}&sort_by=${
      queryParams.sortField
    }&sort_dir=${queryParams.sortOrder}&search_keyword=${encodeURIComponent(
      queryParams.filter.searchText
    )}&search_agent=${queryParams.filter.agent}&search_view=${
      queryParams.filter.view
    }&search_status=${queryParams.filter.dealStage}&search_franchise=${
      queryParams.filter.franchise
    }`
  );
}

//grid deal list api
export function dealsListingGrid(queryParams) {
  return axios.get(
    `${process.env.REACT_APP_SITE_URL +
      DEALS +
      "/all-deals/list"}?search_keyword=${
      queryParams.filter.searchText
    }&search_agent=${queryParams.filter.agent}&search_view=${
      queryParams.filter.view
    }&search_franchise=${queryParams.filter.franchise}`
  );
}

//deal page quotes api
export function dealQuotes(id, queryParams) {
  return axios.get(
    `${process.env.REACT_APP_SITE_URL + DEALS + DEAL_QUOTES}${id}`
  );
}
export function contactQuotes(id, queryParams) {
  return axios.get(
    `${process.env.REACT_APP_SITE_URL + "contact" + CONTACT_QUOTE}${id}&page=${
      queryParams.pageNumber
    }&per_page=${queryParams.pageSize}&sort_by=${
      queryParams.sortField
    }&sort_dir=${queryParams.sortOrder}`
  );
}

//deal detail api
export function dealDetail(id) {
  return axios.get(`${process.env.REACT_APP_SITE_URL + DEALS}/${id}`);
}

//feed list api on deal detail page
export function getDealsFeedList(contact_id, deal_id) {
  return axios.get(
    `${process.env.REACT_APP_SITE_URL +
      DEALS}/feeds/list?contact_id=${contact_id}&deal_id=${deal_id}`
  );
}

//deals franchise listing api
export function dealsFranchiseListing(queryParams) {
  return axios.get(
    `${process.env.REACT_APP_SITE_URL + "contact/postcode-franchise-list"}`
  );
}

//agent list
export function agentList(queryParams) {
  return axios.get(
    `${process.env.REACT_APP_SITE_URL + CONTACT_URL_LIVE}/${CONTACT_AGENT}`
  );
}

//deal status change api
export function dealStatus(data) {
  return axios.post(`${process.env.REACT_APP_SITE_URL + DEALS_STATUS}`, data);
}

//agent assigned to deal api
export function assignDealAgent(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + DEALS + DEALS_AGENT, data);
}

//deal notes api
export function createDealNotes(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + DEALS + DEALS_NOTES, data);
}

//deal detail card edit
export function updateDealCard(id, data) {
  return axios.post(
    process.env.REACT_APP_SITE_URL + DEALS + DEALS_CARD_UPDATE + id,
    data
  );
}

//notes
export function getDealNotesList(deal_id, id) {
  return axios.get(
    `${process.env.REACT_APP_SITE_URL +
      DEALS +
      DEALS_NOTES_LISTING}deal_id=${deal_id}&contact_id=${id}`
  );
}

//task
export function createDealTask(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + DEALS + DEALS_TASKS, data);
}

//tasks Listing
export function getDealTasksList(deal_id, id) {
  return axios.get(
    `${process.env.REACT_APP_SITE_URL +
      DEALS +
      DEALS_TASKS_LIST}?deal_id=${deal_id}&contact_id=${id}`
  );
}

//email
export function sendEmailDeals(data) {
  return axios.post(
    `${process.env.REACT_APP_SITE_URL + DEALS + SEND_EMAIL}`,
    data
  );
}
export function getDealEmailsList(deal_id, id) {
  return axios.get(
    `${process.env.REACT_APP_SITE_URL +
      DEALS +
      SEND_EMAIL_LISTING}?deal_id=${deal_id}&contact_id=${id}`
  );
}
//sms
export function sendSmsDeals(data) {
  return axios.post(
    `${process.env.REACT_APP_SITE_URL + DEALS + SEND_SMS}`,
    data
  );
}
export function getDealSmsList(deal_id, id) {
  return axios.get(
    `${process.env.REACT_APP_SITE_URL +
      DEALS +
      SMS_LISTING}?deal_id=${deal_id}&contact_id=${id}`
  );
}

//deal update api
export function dealsUpdate(id, data) {
  return axios.post(`${process.env.REACT_APP_SITE_URL + DEALS}/${id}`, data);
}
//delete
export function deleteDeal(data) {
  return axios.post(`${process.env.REACT_APP_SITE_URL + DEALS + DELETE}`, data);
}
