/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

import * as Yup from "yup";

import React, { useState } from "react";

import { ChargebeeSchema } from "../franchise-form/validateSchema";
import { Form } from "react-bootstrap";
import SnackBarTool from "../../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { canvaCancel } from "../../../../../utils/canvaCloseFunction";
import { editFranchisePopDetail } from "../../../_redux/franchises/franchisesCrud";
import { getInputClasses } from "../../../../../utils/commonFunction";
import { useFormik } from "formik";
import { useIntl } from "react-intl";
import { fetchFranchisesDetail } from "../../../_redux/franchises/franchisesActions";
import { useDispatch } from "react-redux";

export function ChargeDetail({ detailData }) {
  const intl = useIntl();
  const dispatch = useDispatch();

  // Initial values for the form
  const initialValues = {
    franchise_id: detailData && detailData.id ? detailData.id : "",

    chargebee_key:
      detailData && detailData.chargebee_key ? detailData.chargebee_key : "",
    chargebee_value:
      detailData && detailData.chargebee_value
        ? detailData.chargebee_value
        : "",
    type: "franchise_chargebee",
  };

  // Use state hook to manage snackbar state
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });

  // Event handler for form submission
  const handleSubmit = (values, actions) => {
    setSnack({ call: false, type: "", message: "" });

    editFranchisePopDetail(values)
      .then((res) => {
        "";
        if (res.data.success) {
          setSnack({
            call: true,
            type: "success",
            message: "Chargebee detail updated successfully",
          });
          dispatch(fetchFranchisesDetail(detailData?.id || ""));
          canvaCancel("kt_chargedetail_panel_close");
        } else {
          setSnack({ call: true, type: "error", message: res.data.message });
        }
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
  };

  const handleReset = () => {};

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: ChargebeeSchema(intl, Yup),
    onSubmit: handleSubmit,
    onReset: handleReset,
  });

  return (
    <div
      id="kt_chargedetail_panel"
      className="offcanvas offcanvas-right pt-5 pb-10"
    >
      {/*begin::Header*/}
      <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
        <div
          className="offcanvas-close mt-n1 pr-5"
          style={{ position: "absolute", top: "15px", right: "10px" }}
        >
          <span
            onClick={() => formik.resetForm()}
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            id="kt_chargedetail_panel_close"
          >
            <i className="ki ki-close icon-sm text-muted"></i>
          </span>
        </div>
      </div>
      {/*end::Header*/}
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <div className="offcanvas-content px-10 ">
          <div>
            <h5>Chargebee Detail</h5>
          </div>
          <div
            id="kt_quick_panel_logs_kt_chargedetail_panel"
            className="fade pt-3 pr-5 mr-n5 scroll active show ps--active-y mt-10"
          >
            <div className="mt-10 pb-10">
              <div>
                <Form.Group controlId="" className="mb-5">
                  <Form.Label>Chargebee Key</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={"Chargebee Key"}
                    className={`form-control  ${getInputClasses(
                      formik,
                      "chargebee_key"
                    )}`}
                    name="chargebee_key"
                    {...formik.getFieldProps("chargebee_key")}
                  />
                  {formik.touched.chargebee_key &&
                  formik.errors.chargebee_key ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.chargebee_key}
                      </div>
                    </div>
                  ) : null}
                </Form.Group>
              </div>
              <div>
                <Form.Group controlId="" className="mb-5">
                  <Form.Label>Chargebee Value</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={"Chargebee Value"}
                    className={`form-control  ${getInputClasses(
                      formik,
                      "chargebee_value"
                    )}`}
                    name="chargebee_value"
                    {...formik.getFieldProps("chargebee_value")}
                  />
                  {formik.touched.chargebee_value &&
                  formik.errors.chargebee_value ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.chargebee_value}
                      </div>
                    </div>
                  ) : null}
                </Form.Group>
              </div>
            </div>
          </div>
        </div>
        <div className="offcanvas-footer">
          <div className="offcanvas-content text-right ">
            <button
              type="reset"
              className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
              onClick={() => canvaCancel("kt_chargedetail_panel_close")}
            >
              {intl.formatMessage({ id: "CANCEL" })}
            </button>
            <button
              type="submit"
              className="btn btn-primary font-weight-bolder font-size-sm mr-3 "
            >
              {intl.formatMessage({ id: "SAVE" })}
            </button>
          </div>
        </div>
      </form>
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </div>
  );
}
