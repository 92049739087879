import { FormControl, MenuItem, Select } from "@material-ui/core";

import Checkbox from "../user-components/Checkbox";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import React from "react";
import { useIntl } from "react-intl";
import { getInputClasses } from "../../../../../utils/commonFunction";

const ProfileForm = ({ formik, active, setDialog, parentDrop }) => {
  const intl = useIntl();

  const handleCheck = (e, key, value) => {
    formik.setFieldValue(`permission.${key}.${value}`, e.target.checked);
    if (
      e.target.checked &&
      (value === "create" || value === "edit" || value === "delete")
    ) {
      formik.setFieldValue(`permission.${key}.view`, e.target.checked);
    }
  };

  return (
    <div className="row">
      <div className="col-12 col-sm-6">
        <div className="form-groupB">
          <label>Parent Profile</label>
          <FormControl fullWidth>
            <Select
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              className={`form-control  ${getInputClasses(
                formik,
                "parent_id"
              )}`}
              IconComponent={(props) => (
                <i {...props}>
                  <KeyboardArrowDownIcon />
                </i>
              )}
              inputProps={{ "aria-label": "Without label" }}
              displayEmpty
              name="parent_id"
              {...formik.getFieldProps("parent_id")}
              disabled={formik?.values?.profile_id === 1 ? true : false}

              //   onBlur={handleBlur}
              //   value={values.status}
            >
              <MenuItem value="">Parent Profile</MenuItem>
              {parentDrop &&
                parentDrop.map((data, index) => (
                  <MenuItem value={data.id} key={index}>
                    {data.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          {formik.touched.parent_id && formik.errors.parent_id ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.parent_id}</div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="col-12">
        <div className="form-groupB">
          <label>{intl.formatMessage({ id: "Profile.Name" })}</label>
          <input
            type="text"
            placeholder={intl.formatMessage({ id: "Profile.Name" })}
            className={`form-control  ${getInputClasses(formik, "name")}`}
            name="name"
            {...formik.getFieldProps("name")}
          />
          {formik.touched.name && formik.errors.name ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.name}</div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="col-12">
        <div className="form-groupB">
          <label>{intl.formatMessage({ id: "Profile.Description" })}</label>
          <input
            type="text"
            placeholder={intl.formatMessage({ id: "Profile.Description" })}
            className={`form-control  ${getInputClasses(
              formik,
              "description"
            )}`}
            name="description"
            {...formik.getFieldProps("description")}
          />
          {formik.touched.description && formik.errors.description ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.description}</div>
            </div>
          ) : null}
        </div>
      </div>

      <div className=" table-responsive col-12 mt-4">
        <Checkbox
          icon={
            <span className="switch switch-outline switch-icon switch-primary">
              <label>
                <input type="checkbox" name="quick_panel_notifications_2" />
                <span></span>
              </label>
            </span>
          }
          formik={formik}
          handleCheck={handleCheck}
          // handleCheckAll={handleCheckAll}
        />
      </div>
      <div className="col-12 col-sm-6">
        <div className="form-groupB row">
          <label className="col-4 col-form-label ">Active</label>
          <div className="col-3 text-right mt-1">
            <span className="switch switch-outline switch-icon switch-primary">
              <label>
                <input
                  type="checkbox"
                  checked={active}
                  name="quick_panel_notifications_2"
                  onChange={() => setDialog(true)}
                />
                <span></span>
              </label>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileForm;
