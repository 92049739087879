/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { Modal } from "react-bootstrap";
import React, { useState } from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import SnackBarTool from "../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { LoadingCustome } from "../../../../_metronic/_partials/controls/LoadingCustome";
import { getInputClasses } from "../../../utils/commonFunction";

import { dueInvoice } from "../_redux/order/orderCrud";
import {
  fetchMyOrderDetailCard,
  fetchMyOrderTimeline,
  fetchPaymentInvoice,
} from "../_redux/order/orderAction";
const DueInvoice = ({ dialog, setDialog, orderDetail }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const initialValues = {
    order_id: orderDetail?.id || "",
    reason: "",
  };

  const handleSubmit = (values) => {
    setIsLoading(true);
    dueInvoice(values)
      .then((res) => {
        setIsLoading(false);
        setSnack({ call: true, type: "success", message: res.data.message });
        dispatch(fetchMyOrderDetailCard(orderDetail?.id));
        dispatch(fetchMyOrderTimeline(orderDetail?.id));
        dispatch(
          fetchPaymentInvoice(
            orderDetail?.id,
            orderDetail?.franchise_id,
            orderDetail?.subscription_id
          )
        );
        handleClose();
        // setTimeout(() => {
        //   history.push(
        //     `/order-details/${orderUIContext?.state?.orderData?.order_id}`
        //   );
        // }, 3000);
      })
      .catch((error) => {
        setIsLoading(false);
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    // validationSchema: CompetitorModalSchema,
    onSubmit: handleSubmit,
  });
  const handleClose = () => {
    setDialog({ check: false });
  };
  return (
    <Modal
      show={dialog?.check}
      aria-labelledby="example-modal-sizes-title-lg"
      onHide={handleClose}
    >
      <Modal.Header className="d-flex justify-content-between">
        <Modal.Title id="example-modal-sizes-title-lg">
          Return Booking?
        </Modal.Title>
        <span
          className="btn btn-xs btn-icon btn-light btn-hover-primary"
          onClick={handleClose}
        >
          <i className="ki ki-close icon-sm text-muted"></i>
        </span>
      </Modal.Header>
      <form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          {isLoading && <LoadingCustome />}
          <div className="row">
            <div className="col-12 mt-2">
              <label>Reason</label>
              <div className="form-groupB">
                <textarea
                  className={`form-control ${getInputClasses("reason")}`}
                  placeholder="Add your reason here"
                  name="reason"
                  // value={formik.values.reason}
                  // maxLength={formik.discountType === "percentage" ? "3" : "4"}
                  {...formik.getFieldProps("reason")}
                />
                {formik.touched.reason && formik.errors.reason ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.reason}</div>
                  </div>
                ) : null}
              </div>
            </div>{" "}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              onClick={() => handleClose()}
              className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
              //   disabled={isLoading}
            >
              Dismiss
            </button>
            <button
              type="submit"
              className="btn btn-primary font-weight-bolder font-size-sm "
            >
              Save
            </button>
          </div>
        </Modal.Footer>
      </form>
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </Modal>
  );
};

export default DueInvoice;
