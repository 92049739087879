/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

import React, { useState } from "react";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import DiscardDialog from "../../../../../../_metronic/_partials/widgets/alert/DiscardDialog";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { ProductPricingSchema } from "../product-form/validationSchema";
import SnackBarTool from "../../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import currencyApi from "../../../../../utils/currencyApi.json";
import { fetchProductDetail } from "../../../_redux/products/productsActions";
import {
  convertGBPtoPoundSymbol,
  getInputClasses,
} from "../../../../../utils/commonFunction";
import { productEditValues } from "../ProductHelper";

import { useEffect } from "react";
import { useFormik } from "formik";
import { useIntl } from "react-intl";
import { updatePricing } from "../../../_redux/products/productsCrud";

export function PricingCanvas({ canva, setCanva }) {
  const intl = useIntl();

  // Use the useSelector hook to extract the detailData from the global state
  const { detailData } = useSelector(
    (state) => ({
      detailData: state.country.activeCountry,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();

  // Use the useState hook to initialize the snack, dialogDis, billing, loading, and error states
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [dialogDis, setDialogDis] = useState(false);
  const [billing, setBilling] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setErrors] = useState({ billing: "" });

  // Define the handleSubmit function that will be passed to the onSubmit prop of the formik hook
  const handleSubmit = (values) => {
    setLoading(true);
    setSnack({ call: false, type: "", message: "" });

    updatePricing(values, values?.mainId)
      .then((res) => {
        setSnack({ call: true, type: "success", message: res.data.message });
        // Dispatch the fetchProductDetail action
        dispatch(fetchProductDetail(values?.mainId));
        setTimeout(() => {
          setCanva({ type: false });
        }, 2000);
        setLoading(false);
        // Reset the formik form
        formik.resetForm();
      })
      .catch((error) => {
        setLoading(false);
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
  };

  // Use the useFormik hook to initialize the formik form with the productEditValues, validationSchema and onSubmit props
  const formik = useFormik({
    initialValues: productEditValues(canva),
    // enableReinitialize: true,
    validationSchema: ProductPricingSchema(intl),
    onSubmit: handleSubmit,
  });

  // Define the handleCurrency function that will set the currency_code field based on the country selected
  const handleCurrency = () => {
    const contryCurrency = detailData.filter(
      (element) => element.id === Number(canva.detailData.metadata.country)
    );

    if (contryCurrency?.length) {
      const currencyCode = currencyApi.filter(
        (element) => element.name === contryCurrency[0].currency
      );
      if (currencyCode?.length) {
        formik.setFieldValue("currency_code", currencyCode[0].cc);
      } else {
        formik.setFieldValue("currency_code", "");
      }
    }
  };

  // Use the useEffect hook to handle the side effect of setting the currency code when the country is selected
  useEffect(() => {
    if (
      canva?.detailData?.metadata &&
      canva?.detailData?.metadata?.country !== "" &&
      formik.values.currency_code === ""
    ) {
      handleCurrency();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canva.detailData.country]);

  const handleClose = () => {
    if (formik.dirty && Object.keys(formik.touched).length !== 0) {
      setDialogDis(true);
    } else {
      setCanva({ type: false });
      // canvaCancel("kt_plan_panel_close");
      formik.resetForm();
    }
  };

  useEffect(() => {
    if (formik?.values?.billing_cycles !== "") {
      setBilling("fixed");
    }
  }, [formik.values.billing_cycles]);

  useEffect(() => {
    if (formik?.values?.billing_cycles === "" && billing === "fixed") {
      setErrors({ billing: "Billing cycle is required" });
    } else {
      setErrors({ billing: "" });
    }
  }, [billing, formik.values.billing_cycles]);

  const handleSet = () => {
    if (formik.values.price === "") {
      formik.setFieldValue("price", 0);
    }
  };

  return (
    <>
      <div
        id="kt_pricing_panel"
        className="offcanvas offcanvas-right pt-5 pb-10 offcanvas-on"
      >
        {/*begin::Header*/}
        <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
          <div
            className="offcanvas-close mt-n1  pr-5"
            style={{ position: "absolute", top: "15px", right: "10px" }}
          >
            <button
              type="button"
              className="btn btn-xs btn-icon btn-light btn-hover-primary"
              onClick={() => handleClose()}
            >
              <i className="ki ki-close icon-xs text-muted"></i>
            </button>
            <div id="kt_pricing_panel_close"></div>
          </div>
        </div>

        {/*end::Header*/}

        {/*begin::Content*/}

        <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
          <div className="offcanvas-content px-10">
            <div>
              <h5>{canva?.title}</h5>
            </div>
            <div className="tab-content">
              <div
                id="kt_quick_panel_logs_kt_pricing_panel"
                role="tabpanel"
                className={`tab-pane fade pt-3 pr-5 mr-n5 scroll active show `}
              >
                <div className="row">
                  <div className="col-4">
                    <label>Price Id</label>
                    <div className="form-groupB">
                      <input
                        type="text"
                        // min="0"
                        name="id"
                        className={`form-control  ${getInputClasses(
                          formik,
                          "id"
                        )}`}
                        placeholder={`price id`}
                        {...formik.getFieldProps("id")}
                        onBlur={handleSet}
                      />

                      {formik.touched.id && formik.errors.id ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.id}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-4">
                    <label>Internal name</label>
                    <div className="form-groupB">
                      <input
                        type="text"
                        // min="0"
                        name="name"
                        className={`form-control  ${getInputClasses(
                          formik,
                          "name"
                        )}`}
                        placeholder={`internal name`}
                        {...formik.getFieldProps("name")}
                        onBlur={handleSet}
                      />

                      {formik.touched.name && formik.errors.name ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.name}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-4">
                    <label>External name</label>
                    <div className="form-groupB">
                      <input
                        type="text"
                        // min="0"
                        name="external_name"
                        className={`form-control  ${getInputClasses(
                          formik,
                          "external_name"
                        )}`}
                        placeholder={`External name`}
                        {...formik.getFieldProps("external_name")}
                        onBlur={handleSet}
                      />

                      {formik.touched.external_name &&
                      formik.errors.external_name ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.external_name}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="form-groupB">
                      <label>Pricing Model</label>
                      <FormControl fullWidth>
                        <Select
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          }}
                          className={`form-control  ${getInputClasses(
                            formik,
                            "pricing_model"
                          )}`}
                          IconComponent={(props) => (
                            <i {...props}>
                              <KeyboardArrowDownIcon />
                            </i>
                          )}
                          inputProps={{ "aria-label": "Without label" }}
                          displayEmpty
                          name="pricing_model"
                          {...formik.getFieldProps("pricing_model")}
                        >
                          <MenuItem value="">Pricing Model</MenuItem>
                          {canva?.detailData?.type !== "plan" && (
                            <MenuItem value="flat_fee">Flat Fee</MenuItem>
                          )}
                          <MenuItem value="per_unit">Per Unit</MenuItem>
                        </Select>
                      </FormControl>
                      {formik.touched.pricing_model &&
                      formik.errors.pricing_model ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.pricing_model}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-2">
                    <label>{convertGBPtoPoundSymbol("GBP")}</label>
                    <div className="form-groupB">
                      <input
                        type="text"
                        name="currency_code"
                        className={`form-control  ${getInputClasses(
                          formik,
                          "currency_code"
                        )}`}
                        placeholder={convertGBPtoPoundSymbol("GBP")}
                        value={
                          formik?.values?.currency_code &&
                          convertGBPtoPoundSymbol(formik?.values?.currency_code)
                        }
                        onChange={(e) =>
                          formik.setFieldValue("currency_code", e.target.value)
                        }
                        // {...formik.getFieldProps("currency_code")}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <label>Price</label>
                    <div className="form-groupB">
                      <input
                        type="text"
                        // min="0"
                        name="price"
                        className={`form-control  ${getInputClasses(
                          formik,
                          "price"
                        )}`}
                        placeholder={`00.00`}
                        {...formik.getFieldProps("price")}
                        onBlur={handleSet}
                      />

                      {formik.touched.price && formik.errors.price ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.price}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  {canva?.detailData?.type === "plan" ? (
                    <>
                      <div className="col-6">
                        <label>Billing Cycle</label>
                        <FormControl fullWidth>
                          <Select
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                            }}
                            className={`form-control  `}
                            IconComponent={(props) => (
                              <i {...props}>
                                <KeyboardArrowDownIcon />
                              </i>
                            )}
                            inputProps={{ "aria-label": "Without label" }}
                            displayEmpty
                            value={billing}
                            onChange={(e) => setBilling(e.target.value)}
                          >
                            <MenuItem value="">Forever</MenuItem>
                            <MenuItem value="fixed">Fixed</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      <div className="col-6">
                        <label> &nbsp;</label>
                        <div className="form-groupB">
                          <input
                            type="text"
                            name="billing_cycles"
                            className={`form-control  `}
                            placeholder="Billing cycles"
                            {...formik.getFieldProps("billing_cycles")}
                            disabled={billing === ""}
                          />
                          {error?.billing ? (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                {error.billing}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  <div className="col-12">
                    <label>Description</label>
                    <div className="form-groupB">
                      <textarea
                        type="textarea"
                        name="price_description"
                        {...formik.getFieldProps("price_description")}
                        className={`form-control  ${getInputClasses(
                          formik,
                          "price_description"
                        )}`}
                        placeholder="Description"
                        label="Description"
                        rows="4"
                      />
                      {formik.touched.price_description &&
                      formik.errors.price_description ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.price_description}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-12 mb-5">
                    <label className="checkbox">
                      <input
                        type="checkbox"
                        name="show_description_in_invoices"
                        checked={formik.values.show_description_in_invoices}
                        {...formik.getFieldProps(
                          "show_description_in_invoices"
                        )}
                      />
                      <span className="mr-2"></span>
                      Show {canva?.detailData?.type} description in Invoices
                    </label>
                  </div>
                  <div className="col-md-12 mb-5">
                    <label className="checkbox">
                      <input
                        type="checkbox"
                        name="show_description_in_quotes"
                        checked={formik.values.show_description_in_quotes}
                        {...formik.getFieldProps("show_description_in_quotes")}
                      />
                      <span className="mr-2"></span>
                      Show {canva?.detailData?.type} description in Quotes
                    </label>
                  </div>
                  <div className="col-12">
                    <label>Invoice Notes</label>
                    <div className="form-groupB">
                      <textarea
                        type="textarea"
                        name="invoice_notes"
                        className={`form-control  ${getInputClasses(
                          formik,
                          "invoice_notes"
                        )}`}
                        placeholder="Description"
                        label="Description"
                        rows="4"
                        {...formik.getFieldProps("invoice_notes")}
                      />
                      {formik.touched.invoice_notes &&
                      formik.errors.invoice_notes ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.invoice_notes}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-12 mb-5">
                    <label className="checkbox">
                      <input
                        type="checkbox"
                        name="is_taxable"
                        checked={
                          formik.values.is_taxable === "true" ||
                          formik.values.is_taxable === true
                            ? true
                            : false
                        }
                        onChange={(e) =>
                          formik.setFieldValue(
                            "is_taxable",
                            String(e.target.checked)
                          )
                        }
                      />
                      <span className="mr-2"></span>
                      This {canva?.detailData?.type} is subject to taxes
                    </label>
                  </div>
                </div>
              </div>
              {/*end::Content*/}
            </div>
          </div>

          <div className="offcanvas-footer">
            <div className="offcanvas-content text-right ">
              <button
                type="button"
                className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
                onClick={() => handleClose()}
              >
                Cancel
              </button>
              <button
                className="btn btn-primary font-weight-bolder font-size-sm "
                type="submit"
                disabled={error?.billing !== ""}
              >
                Save
                {loading && (
                  <span className="spinner spinner-white mr-4"></span>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
      <DiscardDialog
        setDialogDis={setDialogDis}
        dialogDis={dialogDis}
        formik={formik}
        closeId={"kt_pricing_panel"}
        setCanva={setCanva}
      />
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </>
  );
}
