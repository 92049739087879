/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

import React, { useEffect, useState } from "react";
import {
  createContainerFranchise,
  createFranchisePostCode,
  deleteFranchisePostCode,
  franchiseSectorStatus,
  getClassSiteList,
  getSiteList,
  updateContainerFranchise,
} from "../../../_redux/franchises/franchisesCrud";
import {
  fetchFranchisesPostCode,
  fetchFranchisesSectorPostCode,
} from "../../../_redux/franchises/franchisesActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import ActiveDialogApi from "../../../../../../_metronic/_partials/widgets/alert/ActiveDialogApiCall";
import DeleteDialogApi from "../franchise-active-dialog/DeleteDialog";
import { Form, Modal } from "react-bootstrap";
import { InputGroup } from "react-bootstrap";
import SVG from "react-inlinesvg";
import SnackBarTool from "../../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { canvaCancel } from "../../../../../utils/canvaCloseFunction";
import { initIds } from "../../../../../../_metronic/_partials/controls/RightPanel";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import { useFormik } from "formik";
import { useIntl } from "react-intl";
import {
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { getInputClasses } from "../../../../../utils/commonFunction";
import DatePicker from "react-datepicker";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import moment from "moment";
import { fetchContainerFranchise } from "../../../_redux/container/containerAction";

export function EditContainerModal({
  detailData,
  dialogCanva,
  setDialogCanva,
  fraId,
}) {
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [siteList, setSiteList] = useState([]);
  const [classList, setClassList] = useState([]);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const initialValues = {
    reference_unique_id:dialogCanva?.data?.reference_unique_id||"",
floor:dialogCanva?.data?.floor||"",
type:dialogCanva?.data?.type||"",
store_vehicle:dialogCanva?.data?.store_vehicle||"",
vehicle_access:dialogCanva?.data?.vehicle_access||"",
available_from:dialogCanva?.data?.available_from||"",
id:dialogCanva?.data?.id||"",
  };

  const handleSubmit = (values, actions) => {
    setSnack({ call: false, type: "", message: "" });
    if (values.postcode !== "") {
        updateContainerFranchise(values)
        .then((res) => {
          setSnack({ call: true, type: "success", message: res.data.message });
          // dispatch(fetchFranchisesPostCode(detailData, ""));
          // formik.resetForm();
          const data ={
            pageNumber:1,
            pageSize:20,
            franchise_id:fraId    }
      dispatch(fetchContainerFranchise(data))
          // dispatch(fetchFranchisesPostCode(detailData, ""));
          setTimeout(() => {
            
            setDialogCanva(false);
          }, 2000);
        })
        .catch((error) => {
          setSnack({
            call: true,
            type: "error",
            message: error.response.data.message,
          });
        });
    } else {
      formik.resetForm();
      canvaCancel("kt_container_panel_close");
    }
  };

  const handleReset = () => {};

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: handleSubmit,
    onReset: handleReset,
  });

  const handleSiteList = async () => {
    await getSiteList(fraId).then((res) => {
      setSiteList(res.data.data);
    });
  };
  useEffect(() => {
    if (dialogCanva) handleSiteList();
  }, [dialogCanva]);

  const handleClassList = async (id) => {
    await getClassSiteList(id).then((res) => {
      setClassList(res.data.data);
    });
  };
  useEffect(() => {
    if (formik.values.site_id) {
      handleClassList(formik.values.site_id);
    }
  }, [formik.values.site_id]);

  const handleClose = () => {
    setSearch("");
    formik.resetForm();

    setDialogCanva(false);
  };


  return (
    <Modal
      size="xl"
      fullscreen
      show={dialogCanva}
      dialogClassName="modal-90w"
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">Container</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/*end::Header*/}
        <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
          
           

            <div
              className="row mb-3"
              style={{ padding: "2%", margin: "1%", background: "#f3f3f3" }}
            >
              {/* <div className="col-md-1 mb-3">
                <Form.Group className="mb-0">
                  <Form.Label>Type</Form.Label>
                  <FormControl fullWidth>
                    <Select
                      name={`type`}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                      className={`form-control  ${getInputClasses(
                        `type`
                      )}`}
                      IconComponent={(props) => (
                        <i {...props}>
                          <KeyboardArrowDownIcon />
                        </i>
                      )}
                      inputProps={{ "aria-label": "Without label" }}
                      displayEmpty
                      {...formik.getFieldProps(`type`)}

                      //   onBlur={handleBlur}
                      // value={values.country_id_id}
                    >
                      <MenuItem value="">Select</MenuItem>
                      <MenuItem value="ELE">ELE</MenuItem>
                      <MenuItem value="NON">NON</MenuItem>
                      <MenuItem value="POTENTIAL">POTENTIAL</MenuItem>
                    </Select>
                  </FormControl>
                  {formik.touched.country_id && formik.errors.country_id ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.country_id}
                      </div>
                    </div>
                  ) : null}
                </Form.Group>
              </div> */}
              <div className="col-md-2 mb-3">
                <Form.Group className="mb-0">
                  <Form.Label>Floor</Form.Label>
                  <FormControl fullWidth>
                    <Select
                      name={`floor`}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                      className={`form-control  ${getInputClasses(
                        `floor`
                      )}`}
                      IconComponent={(props) => (
                        <i {...props}>
                          <KeyboardArrowDownIcon />
                        </i>
                      )}
                      inputProps={{ "aria-label": "Without label" }}
                      displayEmpty
                      {...formik.getFieldProps(`floor`)}

                      //   onBlur={handleBlur}
                      // value={values.country_id_id}
                    >
                      <MenuItem value="">Select</MenuItem>
                      <MenuItem value="G">Ground</MenuItem>
                      <MenuItem value="1F">1st</MenuItem>
                      <MenuItem value="2F">2nd</MenuItem>
                    </Select>
                  </FormControl>
                  {formik.touched.country_id && formik.errors.country_id ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.country_id}
                      </div>
                    </div>
                  ) : null}
                </Form.Group>
              </div>
              {/* <div className="col-md-2 mb-3">
                          <Form.Group className="mb-0">
                            <Form.Label>Currently on site</Form.Label>
                            <FormControl fullWidth>
                              <Select
                                name="country_id"
                                MenuProps={{
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                  },
                                  transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                  },
                                  getContentAnchorEl: null,
                                }}
                                className={`form-control  ${getInputClasses(
                                  "country_id"
                                )}`}
                                IconComponent={(props) => (
                                  <i {...props}>
                                    <KeyboardArrowDownIcon />
                                  </i>
                                )}
                                inputProps={{ "aria-label": "Without label" }}
                                displayEmpty
                                {...formik.getFieldProps("country_id")}
                              >
                                <MenuItem value="">Select</MenuItem>
                              </Select>
                            </FormControl>
                            {formik.touched.country_id &&
                            formik.errors.country_id ? (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                  {formik.errors.country_id}
                                </div>
                              </div>
                            ) : null}
                          </Form.Group>
                        </div> */}
              <div className="col-md-2 mb-3">
                <Form.Group className="mb-0">
                  <Form.Label>Store vehicle</Form.Label>
                  <FormControl fullWidth>
                    <Select
                      name={`store_vehicle`}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                      className={`form-control  ${getInputClasses(
                        `store_vehicle`
                      )}`}
                      IconComponent={(props) => (
                        <i {...props}>
                          <KeyboardArrowDownIcon />
                        </i>
                      )}
                      inputProps={{ "aria-label": "Without label" }}
                      displayEmpty
                      {...formik.getFieldProps(`store_vehicle`)}

                      //   onBlur={handleBlur}
                      // value={values.country_id_id}
                    >
                      <MenuItem value="">Select</MenuItem>
                      <MenuItem value="YES">Yes</MenuItem>
                      <MenuItem value="NO">No</MenuItem>
                    </Select>
                  </FormControl>
                  {formik.touched.country_id && formik.errors.country_id ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.country_id}
                      </div>
                    </div>
                  ) : null}
                </Form.Group>
              </div>

              {/* <div className="col-2 mb-4">
                          <Form.Group className="mb-0">
                            <Form.Label>Deposite Value</Form.Label>
                            <FormControl fullWidth>
                              <input
                                type="text"
                                name="price_increase_value"
                                placeholder={"£"}
                                label="External Name"
                                className={`form-control  `}
                                // max={3}
                                // maxLength="3"
                                {...formik.getFieldProps(
                                  "price_increase_value"
                                )}
                              />
                            </FormControl>
                          </Form.Group>
                          {formik.touched.price_increase_value &&
                          formik.errors.price_increase_value ? (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                {formik.errors.price_increase_value}
                              </div>
                            </div>
                          ) : null}
                        </div> */}

              <div className="col-md-2 mb-3">
                <Form.Group className="mb-0">
                  <Form.Label>Vehicle Access</Form.Label>
                  <FormControl fullWidth>
                    <Select
                      name={`vehicle_access`}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                      className={`form-control  ${getInputClasses(
                        `vehicle_access`
                      )}`}
                      IconComponent={(props) => (
                        <i {...props}>
                          <KeyboardArrowDownIcon />
                        </i>
                      )}
                      inputProps={{ "aria-label": "Without label" }}
                      displayEmpty
                      {...formik.getFieldProps(`vehicle_access`)}

                      //   onBlur={handleBlur}
                      // value={values.country_id_id}
                    >
                      <MenuItem value="">Select</MenuItem>
                      <MenuItem value="YES">Yes</MenuItem>
                      <MenuItem value="NO">No</MenuItem>
                    </Select>
                  </FormControl>
                  {formik.touched.country_id && formik.errors.country_id ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.country_id}
                      </div>
                    </div>
                  ) : null}
                </Form.Group>
              </div>
              <div className="col-2 mb-4">
                <Form.Group className="mb-0">
                  <Form.Label>Unique&nbsp;ID</Form.Label>
                  <FormControl fullWidth>
                    <input
                      type="text"
                      name={`reference_unique_id`}
                      placeholder={"ID"}
                      label="ID"
                      className={`form-control  `}
                      // disabled
                      // max={3}
                      // maxLength="3"
                      {...formik.getFieldProps(
                        `reference_unique_id`
                      )}
                    />
                  </FormControl>
                </Form.Group>
                {formik.touched.price_increase_value &&
                formik.errors.price_increase_value ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.price_increase_value}
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="col-md-4 mb-5">
                    <label>Available from</label>
                    <InputGroup className="calendr">
                      <DatePicker
                        fullWidth
                        fixedHeight
                        className="form-control"
                        wrapperClassName="datepicker"
                        selected={Date.parse(formik?.values.available_from)}
                        // startDate={Date.parse(formik?.values.blocking_date_from)}
                        // endDate={Date.parse(formik?.values.blocking_date_to)}
                        dateFormat="dd-MM-Y"
                        placeholderText="Select From Date"
                         onChange={(fromDate)=>{
                          
                      
                          formik.setFieldValue(
                            `available_from`,
                            moment(fromDate).format("yyyy-MM-DD")
                          );
                        }}
                        minDate={new Date()}
                      />
                      <CalendarTodayIcon />
                    </InputGroup>
                    {formik.touched.blocking_date_from &&
                    formik.errors.blocking_date_from ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.blocking_date_from}
                        </div>
                      </div>
                    ) : null}
                    {formik.touched.blocking_date_to &&
                    !formik.errors.blocking_date_from &&
                    formik.errors.blocking_date_to ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.blocking_date_to}
                        </div>
                      </div>
                    ) : null}
                  </div>
            </div>
         
        </form>
      </Modal.Body>
      {snack.call ? <SnackBarTool {...snack} /> : ""}
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={() => setDialogCanva(false)}
            className="btn btn-light btn-elevate"
          >
            Close
          </button>
          <> </>

          <button
            type="button"
            className="btn btn-light btn-elevate"
            onClick={() => formik.submitForm()}
          >
            Save
          </button>
        </div>
      </Modal.Footer>
      {/* <ActiveDialogApi
        dialog={dialogSector}
        setDialog={setDialogSector}
        title={"Sector"}
        handleChange={handleChange}
      /> */}
      {/* <DeleteDialogApi
        dialog={dialog}
        setDialog={setDialog}
        title={"Postcode"}
        handleChange={handleDelete}
      /> */}
      {/* {sectorDa.data ? (
        <>
          <EditSector
            type={sectorDa.type}
            data={sectorDa?.data}
            setSectorDa={setSectorDa}
          />
          <div
            className="offcanvas-overlay"
            onClick={() => setSectorDa({ type: "" })}
          ></div>
        </>
      ) : (
        ""
      )} */}
    </Modal>
  );
}
