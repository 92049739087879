/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React from "react";
export function PodInStorage() {
  // const [selectedTab, setSelectedTab] = useState("AuditLogs");

  return (
    <div
      id="kt_PodInStorage_panel"
      className="offcanvas offcanvas-right pt-5 pb-10"
    >
      {/*begin::Header*/}
      <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
        <div
          className="offcanvas-close mt-n1 pr-5"
          style={{ position: "absolute", top: "15px", right: "10px" }}
        >
          <a
            href="#"
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            id="kt_PodInStorage_panel_close"
          >
            <i className="ki ki-close icon-sm text-muted"></i>
          </a>
        </div>
      </div>
      {/*end::Header*/}
      <div className="offcanvas-content px-10 ">
        <div>
          <h5>Pods in Storage</h5>
        </div>
        <div id="kt_quick_panel_logs_kt_PodInStorage_panel" className="scroll">
          <div className="mt-10 pb-10">
            <h6 className="mb-5">Roles</h6>
            <div className="row">
              <div className="col-md-6">
                <div className="row align-items-center">
                  <div className="col-6">Super Admin</div>
                  <div className="col-6">
                    {" "}
                    <span className="switch switch-outline switch-icon switch-primary">
                      <label className="ml-5">
                        <input type="checkbox" />
                        <span></span>
                      </label>
                    </span>
                  </div>
                </div>
                <div className="row align-items-center">
                  <div className="col-6"> Admin</div>
                  <div className="col-6">
                    {" "}
                    <span className="switch switch-outline switch-icon switch-primary">
                      <label className="ml-5">
                        <input type="checkbox" />
                        <span></span>
                      </label>
                    </span>
                  </div>
                </div>
                <div className="row align-items-center">
                  <div className="col-6">Country Admin</div>
                  <div className="col-6">
                    {" "}
                    <span className="switch switch-outline switch-icon switch-primary">
                      <label className="ml-5">
                        <input type="checkbox" />
                        <span></span>
                      </label>
                    </span>
                  </div>
                </div>
                <div className="row align-items-center">
                  <div className="col-6">Sales Manager</div>
                  <div className="col-6">
                    {" "}
                    <span className="switch switch-outline switch-icon switch-primary">
                      <label className="ml-5">
                        <input type="checkbox" />
                        <span></span>
                      </label>
                    </span>
                  </div>
                </div>
                <div className="row align-items-center">
                  <div className="col-6">Agent</div>
                  <div className="col-6">
                    {" "}
                    <span className="switch switch-outline switch-icon switch-primary">
                      <label className="ml-5">
                        <input type="checkbox" />
                        <span></span>
                      </label>
                    </span>
                  </div>
                </div>
                <div className="row align-items-center">
                  <div className="col-6">Franchisee</div>
                  <div className="col-6">
                    {" "}
                    <span className="switch switch-outline switch-icon switch-primary">
                      <label className="ml-5">
                        <input type="checkbox" />
                        <span></span>
                      </label>
                    </span>
                  </div>
                </div>
                <div className="row align-items-center">
                  <div className="col-6">Franchisee Admin</div>
                  <div className="col-6">
                    {" "}
                    <span className="switch switch-outline switch-icon switch-primary">
                      <label className="ml-5">
                        <input type="checkbox" />
                        <span></span>
                      </label>
                    </span>
                  </div>
                </div>
                <div className="row align-items-center">
                  <div className="col-6">Marketing</div>
                  <div className="col-6">
                    {" "}
                    <span className="switch switch-outline switch-icon switch-primary">
                      <label className="ml-5">
                        <input type="checkbox" />
                        <span></span>
                      </label>
                    </span>
                  </div>
                </div>
                <div className="row align-items-center">
                  <div className="col-6">Finance</div>
                  <div className="col-6">
                    {" "}
                    <span className="switch switch-outline switch-icon switch-primary">
                      <label className="ml-5">
                        <input type="checkbox" />
                        <span></span>
                      </label>
                    </span>
                  </div>
                </div>
                <div className="row align-items-center">
                  <div className="col-6">Transport</div>
                  <div className="col-6">
                    {" "}
                    <span className="switch switch-outline switch-icon switch-primary">
                      <label className="ml-5">
                        <input type="checkbox" />
                        <span></span>
                      </label>
                    </span>
                  </div>
                </div>
                <div className="row align-items-center">
                  <div className="col-6">Warehouse</div>
                  <div className="col-6">
                    {" "}
                    <span className="switch switch-outline switch-icon switch-primary">
                      <label className="ml-5">
                        <input type="checkbox" />
                        <span></span>
                      </label>
                    </span>
                  </div>
                </div>
                <div className="row align-items-center">
                  <div className="col-6">Driver</div>
                  <div className="col-6">
                    {" "}
                    <span className="switch switch-outline switch-icon switch-primary">
                      <label className="ml-5">
                        <input type="checkbox" />
                        <span></span>
                      </label>
                    </span>
                  </div>
                </div>
                <div className="row align-items-center">
                  <div className="col-6">Loader</div>
                  <div className="col-6">
                    {" "}
                    <span className="switch switch-outline switch-icon switch-primary">
                      <label className="ml-5">
                        <input type="checkbox" />
                        <span></span>
                      </label>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <h6 className="mt-10 mb-5">Franchise</h6>
            <div className="row">
              <div className="col-md-6">
                <div className="row align-items-center">
                  <div className="col-6">HQ</div>
                  <div className="col-6">
                    {" "}
                    <span className="switch switch-outline switch-icon switch-primary">
                      <label className="ml-5">
                        <input type="checkbox" />
                        <span></span>
                      </label>
                    </span>
                  </div>
                </div>
                <div className="row align-items-center">
                  <div className="col-6"> Franchise 1</div>
                  <div className="col-6">
                    {" "}
                    <span className="switch switch-outline switch-icon switch-primary">
                      <label className="ml-5">
                        <input type="checkbox" />
                        <span></span>
                      </label>
                    </span>
                  </div>
                </div>
                <div className="row align-items-center">
                  <div className="col-6">Franchise 2</div>
                  <div className="col-6">
                    {" "}
                    <span className="switch switch-outline switch-icon switch-primary">
                      <label className="ml-5">
                        <input type="checkbox" />
                        <span></span>
                      </label>
                    </span>
                  </div>
                </div>
                <div className="row align-items-center">
                  <div className="col-6">Franchise 3</div>
                  <div className="col-6">
                    {" "}
                    <span className="switch switch-outline switch-icon switch-primary">
                      <label className="ml-5">
                        <input type="checkbox" />
                        <span></span>
                      </label>
                    </span>
                  </div>
                </div>
                <div className="row align-items-center">
                  <div className="col-6">Franchise 4</div>
                  <div className="col-6">
                    {" "}
                    <span className="switch switch-outline switch-icon switch-primary">
                      <label className="ml-5">
                        <input type="checkbox" />
                        <span></span>
                      </label>
                    </span>
                  </div>
                </div>
                <div className="row align-items-center">
                  <div className="col-6">Franchise 5</div>
                  <div className="col-6">
                    {" "}
                    <span className="switch switch-outline switch-icon switch-primary">
                      <label className="ml-5">
                        <input type="checkbox" />
                        <span></span>
                      </label>
                    </span>
                  </div>
                </div>
                <div className="row align-items-center">
                  <div className="col-6">Franchise 6</div>
                  <div className="col-6">
                    {" "}
                    <span className="switch switch-outline switch-icon switch-primary">
                      <label className="ml-5">
                        <input type="checkbox" />
                        <span></span>
                      </label>
                    </span>
                  </div>
                </div>
                <div className="row align-items-center">
                  <div className="col-6">Franchise 7</div>
                  <div className="col-6">
                    {" "}
                    <span className="switch switch-outline switch-icon switch-primary">
                      <label className="ml-5">
                        <input type="checkbox" />
                        <span></span>
                      </label>
                    </span>
                  </div>
                </div>
                <div className="row align-items-center">
                  <div className="col-6">Franchise 8</div>
                  <div className="col-6">
                    {" "}
                    <span className="switch switch-outline switch-icon switch-primary">
                      <label className="ml-5">
                        <input type="checkbox" />
                        <span></span>
                      </label>
                    </span>
                  </div>
                </div>
                <div className="row align-items-center">
                  <div className="col-6">Franchise 9</div>
                  <div className="col-6">
                    {" "}
                    <span className="switch switch-outline switch-icon switch-primary">
                      <label className="ml-5">
                        <input type="checkbox" />
                        <span></span>
                      </label>
                    </span>
                  </div>
                </div>
                <div className="row align-items-center">
                  <div className="col-6">Franchise 10</div>
                  <div className="col-6">
                    {" "}
                    <span className="switch switch-outline switch-icon switch-primary">
                      <label className="ml-5">
                        <input type="checkbox" />
                        <span></span>
                      </label>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="offcanvas-footer">
        <div className="offcanvas-content text-right ">
          <button className="btn btn-dark font-weight-bolder font-size-sm mr-3 ">
            Cancel
          </button>
          <button className="btn btn-primary font-weight-bolder font-size-sm ">
            Save
          </button>
        </div>
      </div>
    </div>
  );
}
