import React, { useEffect } from "react";

export const OrderSearchForm = ({
  intl,
  handleBlur,
  values,
  setFieldValue,
  handleSubmit,
}) => {
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      // Send Axios request here
      handleSubmit();
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.searchText]);
  return (
    <div style={{ position: "relative" }}>
      <input
        style={{ paddingRight: "40px" }}
        type="text"
        className="form-control"
        name="searchText"
        placeholder={intl.formatMessage({ id: "Search" })}
        onBlur={handleBlur}
        value={values.searchText}
        onChange={(e) => {
          setFieldValue("searchTextBook", "");
          setFieldValue("searchText", e.target.value);
          //   setTimeout(() => {
          //     handleSubmit();
          //   }, 1000);
        }}
      />
      <i
        style={{
          position: "absolute",
          top: "50%",
          transform: "translateY(-50%)",
          right: "15px",
        }}
        className="fa fa-search"
        aria-hidden="true"
      ></i>
    </div>
  );
};
