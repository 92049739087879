import React from "react";
import { useIntl } from "react-intl";
import { FormControl, MenuItem, Select } from "@material-ui/core/";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

export const WeekComponent = ({ handleHours, formik, depotOpenHours }) => {
  const intl = useIntl();

  return (
    <>
      <div className="col-sm-12 mb-3 ">
        <label>{intl.formatMessage({ id: "Monday" })}</label>
        <div className="row">
          <div className="col-6 ">
            <FormControl fullWidth>
              <Select
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                className="form-control w-100"
                IconComponent={(props) => (
                  <i {...props}>
                    <KeyboardArrowDownIcon />
                  </i>
                )}
                inputProps={{ "aria-label": "Without label" }}
                displayEmpty
                onChange={(e) => handleHours(e, 1, "open_time")}
                value={
                  formik.values.depot_hours &&
                  formik.values.depot_hours.length &&
                  formik.values.depot_hours[0].open_time !== null
                    ? formik.values.depot_hours[0].open_time
                    : ""
                }
              >
                <MenuItem checked value="">
                  Open
                </MenuItem>
                {depotOpenHours.map((data, index) => (
                  <MenuItem key={index} value={data.id}>
                    {data.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="col-6 ">
            <FormControl fullWidth>
              <Select
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                className="form-control w-100"
                IconComponent={(props) => (
                  <i {...props}>
                    <KeyboardArrowDownIcon />
                  </i>
                )}
                inputProps={{ "aria-label": "Without label" }}
                displayEmpty
                onChange={(e) => handleHours(e, 1, "close_time")}
                value={
                  formik.values.depot_hours &&
                  formik.values.depot_hours.length &&
                  formik?.values?.depot_hours[0].close_time !== null
                    ? formik.values.depot_hours[0].close_time
                    : ""
                }
                disabled={
                  !(
                    formik.values.depot_hours.length &&
                    formik.values.depot_hours[0]?.open_time
                  )
                }
              >
                <MenuItem checked value="">
                  Close
                </MenuItem>
                {depotOpenHours.map((data, index) => (
                  <MenuItem key={index} value={data.id}>
                    {data.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        {formik.touched.depot_hours &&
          formik.errors.depot_hours &&
          formik.errors.depot_hours[0] &&
          formik.errors.depot_hours[0].close_time && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik.errors.depot_hours[0].close_time}
              </div>
            </div>
          )}
      </div>
      <div className="col-sm-12 mb-3 ">
        <label>{intl.formatMessage({ id: "Tuesday" })}</label>
        <div className="row">
          <div className="col-6 ">
            <FormControl fullWidth>
              <Select
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                className="form-control w-100"
                IconComponent={(props) => (
                  <i {...props}>
                    <KeyboardArrowDownIcon />
                  </i>
                )}
                inputProps={{ "aria-label": "Without label" }}
                displayEmpty
                onChange={(e) => handleHours(e, 2, "open_time")}
                value={
                  formik.values.depot_hours &&
                  formik.values.depot_hours.length &&
                  formik.values.depot_hours[1].open_time !== null
                    ? formik.values.depot_hours[1].open_time
                    : ""
                }
              >
                <MenuItem checked value="">
                  Open
                </MenuItem>
                {depotOpenHours.map((data, index) => (
                  <MenuItem key={index} value={data.id}>
                    {data.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="col-6 ">
            <FormControl fullWidth>
              <Select
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                className="form-control w-100"
                IconComponent={(props) => (
                  <i {...props}>
                    <KeyboardArrowDownIcon />
                  </i>
                )}
                inputProps={{ "aria-label": "Without label" }}
                displayEmpty
                onChange={(e) => handleHours(e, 2, "close_time")}
                value={
                  formik.values.depot_hours &&
                  formik.values.depot_hours.length &&
                  formik?.values?.depot_hours[1].close_time !== null
                    ? formik.values.depot_hours[1].close_time
                    : ""
                }
                disabled={
                  !(
                    formik.values.depot_hours.length &&
                    formik.values.depot_hours[1]?.open_time
                  )
                }
              >
                <MenuItem checked value="">
                  Close
                </MenuItem>
                {depotOpenHours.map((data, index) => (
                  <MenuItem key={index} value={data.id}>
                    {data.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        {formik.touched.depot_hours &&
          formik.errors.depot_hours &&
          formik.errors.depot_hours[1] &&
          formik.errors.depot_hours[1].close_time && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik.errors.depot_hours[1].close_time}
              </div>
            </div>
          )}
      </div>
      <div className="col-sm-12 mb-3 ">
        <label>{intl.formatMessage({ id: "Wednesday" })}</label>
        <div className="row">
          <div className="col-6 ">
            <FormControl fullWidth>
              <Select
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                className="form-control w-100"
                IconComponent={(props) => (
                  <i {...props}>
                    <KeyboardArrowDownIcon />
                  </i>
                )}
                inputProps={{ "aria-label": "Without label" }}
                displayEmpty
                onChange={(e) => handleHours(e, 3, "open_time")}
                value={
                  formik.values.depot_hours &&
                  formik.values.depot_hours.length &&
                  formik.values.depot_hours[2] &&
                  formik.values.depot_hours[2].open_time !== null
                    ? formik.values.depot_hours[2].open_time
                    : ""
                }
              >
                <MenuItem checked value="">
                  Open
                </MenuItem>
                {depotOpenHours.map((data, index) => (
                  <MenuItem key={index} value={data.id}>
                    {data.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="col-6 ">
            <FormControl fullWidth>
              <Select
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                className="form-control w-100"
                IconComponent={(props) => (
                  <i {...props}>
                    <KeyboardArrowDownIcon />
                  </i>
                )}
                inputProps={{ "aria-label": "Without label" }}
                displayEmpty
                onChange={(e) => handleHours(e, 3, "close_time")}
                value={
                  formik.values.depot_hours &&
                  formik.values.depot_hours.length &&
                  formik?.values?.depot_hours[2] &&
                  formik?.values?.depot_hours[2].close_time !== null
                    ? formik.values.depot_hours[2].close_time
                    : ""
                }
                disabled={
                  !(
                    formik.values.depot_hours.length &&
                    formik.values.depot_hours[2]?.open_time
                  )
                }
              >
                <MenuItem checked value="">
                  Close
                </MenuItem>
                {depotOpenHours.map((data, index) => (
                  <MenuItem key={index} value={data.id}>
                    {data.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        {formik.touched.depot_hours &&
          formik.errors.depot_hours &&
          formik.errors.depot_hours[2] &&
          formik.errors.depot_hours[2].close_time && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik.errors.depot_hours[2].close_time}
              </div>
            </div>
          )}
      </div>
      <div className="col-sm-12 mb-3 ">
        <label>{intl.formatMessage({ id: "Thursday" })}</label>
        <div className="row">
          <div className="col-6 ">
            <FormControl fullWidth>
              <Select
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                className="form-control w-100"
                IconComponent={(props) => (
                  <i {...props}>
                    <KeyboardArrowDownIcon />
                  </i>
                )}
                inputProps={{ "aria-label": "Without label" }}
                displayEmpty
                onChange={(e) => handleHours(e, 4, "open_time")}
                value={
                  formik.values.depot_hours &&
                  formik.values.depot_hours.length &&
                  formik.values.depot_hours[3] &&
                  formik.values.depot_hours[3].open_time !== null
                    ? formik.values.depot_hours[3].open_time
                    : ""
                }
              >
                <MenuItem checked value="">
                  Open
                </MenuItem>
                {depotOpenHours.map((data, index) => (
                  <MenuItem key={index} value={data.id}>
                    {data.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="col-6 ">
            <FormControl fullWidth>
              <Select
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                className="form-control w-100"
                IconComponent={(props) => (
                  <i {...props}>
                    <KeyboardArrowDownIcon />
                  </i>
                )}
                inputProps={{ "aria-label": "Without label" }}
                displayEmpty
                onChange={(e) => handleHours(e, 4, "close_time")}
                value={
                  formik.values.depot_hours &&
                  formik.values.depot_hours.length &&
                  formik?.values?.depot_hours[3] &&
                  formik?.values?.depot_hours[3].close_time !== null
                    ? formik.values.depot_hours[3].close_time
                    : ""
                }
                disabled={
                  !(
                    formik.values.depot_hours.length &&
                    formik.values.depot_hours[3]?.open_time
                  )
                }
              >
                <MenuItem checked value="">
                  Close
                </MenuItem>
                {depotOpenHours.map((data, index) => (
                  <MenuItem key={index} value={data.id}>
                    {data.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        {formik.touched.depot_hours &&
          formik.errors.depot_hours &&
          formik.errors.depot_hours[3] &&
          formik.errors.depot_hours[3].close_time && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik.errors.depot_hours[3].close_time}
              </div>
            </div>
          )}
      </div>
      <div className="col-sm-12 mb-3 ">
        <label>{intl.formatMessage({ id: "Friday" })}</label>
        <div className="row">
          <div className="col-6 ">
            <FormControl fullWidth>
              <Select
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                className="form-control w-100"
                IconComponent={(props) => (
                  <i {...props}>
                    <KeyboardArrowDownIcon />
                  </i>
                )}
                inputProps={{ "aria-label": "Without label" }}
                displayEmpty
                onChange={(e) => handleHours(e, 5, "open_time")}
                value={
                  formik.values.depot_hours &&
                  formik.values.depot_hours.length &&
                  formik.values.depot_hours[4] &&
                  formik.values.depot_hours[4].open_time !== null
                    ? formik.values.depot_hours[4].open_time
                    : ""
                }
              >
                <MenuItem checked value="">
                  Open
                </MenuItem>
                {depotOpenHours.map((data, index) => (
                  <MenuItem key={index} value={data.id}>
                    {data.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="col-6 ">
            <FormControl fullWidth>
              <Select
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                className="form-control w-100"
                IconComponent={(props) => (
                  <i {...props}>
                    <KeyboardArrowDownIcon />
                  </i>
                )}
                inputProps={{ "aria-label": "Without label" }}
                displayEmpty
                onChange={(e) => handleHours(e, 5, "close_time")}
                value={
                  formik.values.depot_hours &&
                  formik.values.depot_hours.length &&
                  formik.values.depot_hours[4] &&
                  formik?.values?.depot_hours[4].close_time !== null
                    ? formik.values.depot_hours[4].close_time
                    : ""
                }
                disabled={
                  !(
                    formik.values.depot_hours.length &&
                    formik.values.depot_hours[4]?.open_time
                  )
                }
              >
                <MenuItem checked value="">
                  Close
                </MenuItem>
                {depotOpenHours.map((data, index) => (
                  <MenuItem key={index} value={data.id}>
                    {data.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        {formik.touched.depot_hours &&
          formik.errors.depot_hours &&
          formik.errors.depot_hours[4] &&
          formik.errors.depot_hours[4].close_time && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik.errors.depot_hours[4].close_time}
              </div>
            </div>
          )}
      </div>
      <div className="col-sm-12 mb-3 ">
        <label>{intl.formatMessage({ id: "Saturday" })}</label>
        <div className="row">
          <div className="col-6 ">
            <FormControl fullWidth>
              <Select
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                className="form-control w-100"
                IconComponent={(props) => (
                  <i {...props}>
                    <KeyboardArrowDownIcon />
                  </i>
                )}
                inputProps={{ "aria-label": "Without label" }}
                displayEmpty
                onChange={(e) => handleHours(e, 6, "open_time")}
                value={
                  formik.values.depot_hours &&
                  formik.values.depot_hours.length &&
                  formik.values.depot_hours[5] &&
                  formik.values.depot_hours[5].open_time !== null
                    ? formik.values.depot_hours[5].open_time
                    : ""
                }
              >
                <MenuItem checked value="">
                  Open
                </MenuItem>
                {depotOpenHours.map((data, index) => (
                  <MenuItem key={index} value={data.id}>
                    {data.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="col-6 ">
            <FormControl fullWidth>
              <Select
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                className="form-control w-100"
                IconComponent={(props) => (
                  <i {...props}>
                    <KeyboardArrowDownIcon />
                  </i>
                )}
                inputProps={{ "aria-label": "Without label" }}
                displayEmpty
                onChange={(e) => handleHours(e, 6, "close_time")}
                value={
                  formik.values.depot_hours &&
                  formik.values.depot_hours.length &&
                  formik.values.depot_hours[5] &&
                  formik?.values?.depot_hours[5].close_time !== null
                    ? formik.values.depot_hours[5].close_time
                    : ""
                }
                disabled={
                  !(
                    formik.values.depot_hours.length &&
                    formik.values.depot_hours[5]?.open_time
                  )
                }
              >
                <MenuItem checked value="">
                  Close
                </MenuItem>
                {depotOpenHours.map((data, index) => (
                  <MenuItem key={index} value={data.id}>
                    {data.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        {formik.touched.depot_hours &&
          formik.errors.depot_hours &&
          formik.errors.depot_hours[5] &&
          formik.errors.depot_hours[5].close_time && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik.errors.depot_hours[5].close_time}
              </div>
            </div>
          )}
      </div>
      <div className="col-sm-12 mb-3 ">
        <label>{intl.formatMessage({ id: "Sunday" })}</label>
        <div className="row">
          <div className="col-6 ">
            <FormControl fullWidth>
              <Select
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                className="form-control w-100"
                IconComponent={(props) => (
                  <i {...props}>
                    <KeyboardArrowDownIcon />
                  </i>
                )}
                inputProps={{ "aria-label": "Without label" }}
                displayEmpty
                onChange={(e) => handleHours(e, 7, "open_time")}
                value={
                  formik.values.depot_hours &&
                  formik.values.depot_hours.length &&
                  formik.values.depot_hours[6] &&
                  formik?.values?.depot_hours[6].open_time !== null
                    ? formik.values.depot_hours[6].open_time
                    : ""
                }
              >
                <MenuItem checked value="">
                  Open
                </MenuItem>
                {depotOpenHours.map((data, index) => (
                  <MenuItem key={index} value={data.id}>
                    {data.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="col-6 ">
            <FormControl fullWidth>
              <Select
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                className="form-control w-100"
                IconComponent={(props) => (
                  <i {...props}>
                    <KeyboardArrowDownIcon />
                  </i>
                )}
                inputProps={{ "aria-label": "Without label" }}
                displayEmpty
                onChange={(e) => handleHours(e, 7, "close_time")}
                value={
                  formik.values.depot_hours &&
                  formik.values.depot_hours.length &&
                  formik.values.depot_hours[6] &&
                  formik?.values?.depot_hours[6].close_time !== null
                    ? formik.values.depot_hours[6].close_time
                    : ""
                }
                disabled={
                  !(
                    formik.values.depot_hours?.length &&
                    formik.values.depot_hours[6]?.open_time
                  )
                }
              >
                <MenuItem checked value="">
                  Close
                </MenuItem>
                {depotOpenHours.map((data, index) => (
                  <MenuItem key={index} value={data.id}>
                    {data.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        {formik.touched.depot_hours &&
          formik.errors.depot_hours &&
          formik.errors.depot_hours[6] &&
          formik.errors.depot_hours[6].close_time && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik.errors.depot_hours[6].close_time}
              </div>
            </div>
          )}
      </div>
    </>
  );
};
