import { Card, CardBody } from "../../../../../_metronic/_partials/controls";
import React, { useEffect, useState } from "react";

import DeleteDialog from "../../../../../_metronic/_partials/widgets/alert/DeleteDialog";
import NewBreadCrumbs from "../../../../../_metronic/layout/components/subheader/components/NewBreadCrumbs";
import { NewProductCanvas } from "./product-canvas/NewProductCanvas";
import { ProductsFilter } from "./products-filter/ProductsFilter";
import { ProductsTable } from "./products-table/ProductsTable";
import SnackBarTool from "../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { deleteProduct } from "../../_redux/products/productsCrud";
import { fetchProducts } from "../../_redux/products/productsActions";
import { initIds } from "../../../../../_metronic/_partials/controls/RightPanel";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { useProductsUIContext } from "./ProductsUIContext";
import NotAuthorized from "../../../../../_metronic/_partials/widgets/NotAuthorized";
import { shallowEqual, useSelector } from "react-redux";

export function ProductsCard(props) {
  const intl = useIntl();
  const params = useParams();
  const ProductsUIContext = useProductsUIContext();
  const [canva, setCanva] = useState({ type: false, data: {} });
  const [statusResult, setStatusResult] = useState(null);
  const init = [
    "kt_product_category_panel",
    "kt_plan_panel",
    "kt_addons_panel",
    "kt_new_charges_panel",
  ];
  const { permission } = useSelector(
    (state) => ({
      permission: state.auth.permission,
    }),
    shallowEqual
  );

  console.log("permission",permission )
  useEffect(() => {
    initIds(init);
  }, [init]);
  const dispatch = useDispatch();
  const [dialog, setDialog] = useState(false);
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const handleDelete = () => {
    setSnack({ call: false, type: "", message: "" });
    const formData = new FormData();
    ProductsUIContext.ids.forEach((data, index) => {
      formData.append(`product_id[${index}]`, data);
    });
    deleteProduct(formData)
      .then((res) => {
        setSnack({ call: true, type: "success", message: res.data.message });
        dispatch(fetchProducts(ProductsUIContext.queryParams));
        // // resetForm();
        setDialog(false);
        ProductsUIContext.setIds([]);
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
        setDialog(false);
      });
  };

  const checkProductStatus = (products) => {
    if (!Array.isArray(products) || !Array.isArray(ProductsUIContext.ids)) {
      throw new Error("Invalid input: products and ids should be arrays.");
    }

    const hasStatusZero = products.some(
      (product) =>
        ProductsUIContext.ids.includes(product.stripe_product_id) &&
        product.status === 0
    );
    return hasStatusZero;
  };
  const handleStatusResult = (result) => {
    setStatusResult(result);
  };

  return (
    <>
      {/* Display a header with breadcrumbs and buttons for creating new plans, addons, and charges */}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <NewBreadCrumbs title="Products" />
        <div className="text-right mb-4">
          {permission?.setting_product?.delete ? (
            <button
              className="btn btn-primary mr-2"
              type="button"
              onClick={() => setDialog(true)}
              disabled={
                ProductsUIContext.ids?.length === 0
                  ? true
                  : statusResult
                  ? true
                  : false
              }
            >
              Delete
            </button>
          ) : (
            ""
          )}
          {permission?.setting_product?.create ? (
            <>
              {/* <button
                className="btn btn-primary mr-3"
                type="button"
                onClick={() =>
                  setCanva({
                    type: true,
                    data: { title: "New Plan", type: "plan" },
                  })
                }
              >
                {intl.formatMessage({ id: "New.Plan" })}
              </button>
              <button
                className="btn btn-primary mr-3"
                type="button"
                onClick={() =>
                  setCanva({
                    type: true,
                    data: { title: "New Addons", type: "addon" },
                  })
                }
              >
                {intl.formatMessage({ id: "New.Addons" })}
              </button>
              <button
                onClick={() =>
                  setCanva({
                    type: true,
                    data: { title: "New Charges", type: "charge" },
                  })
                }
                className="btn btn-primary mr-3"
                type="button"
              >
                {intl.formatMessage({ id: "New.Charges" })}
              </button> */}
              {params.id ? (
                ""
              ) : (
                <button
                  onClick={() =>
                    setCanva({
                      type: true,
                      data: { title: "Stripe Product", type: "stripeProduct" },
                    })
                  }
                  className="btn btn-primary"
                  type="button"
                >
                  {intl.formatMessage({ id: "New Product" })}
                </button>
              )}
            </>
          ) : (
            ""
          )}
        </div>
      </div>
      {/* Display a card with a filter and a table of products */}
      <Card>
        <CardBody>
          {/* {permission?.setting_product?.view ? (
            <> */}
              <ProductsFilter />

              <ProductsTable
                getData={checkProductStatus}
                onStatusResult={handleStatusResult}
              />
            {/* </>
          ) : (
            <NotAuthorized />
          )} */}
        </CardBody>
      </Card>
      {/* Display the new product canvas */}
      {canva.type ? (
        <>
          <NewProductCanvas setCanva={setCanva} canva={canva} />
          <div
            className="offcanvas-overlay"
            onClick={() => setCanva({ type: false, data: {} })}
          ></div>
        </>
      ) : (
        ""
      )}
      {/* Display the delete dialog */}
      <DeleteDialog
        dialog={dialog}
        setDialog={setDialog}
        handleDelete={handleDelete}
        title={"Product"}
      />
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </>
  );
}
