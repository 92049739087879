import React, { useMemo } from "react";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { useReportingUIContext } from "../ReportingUIContext";
import { MenuItem, FormControl, Select } from "@material-ui/core";
import DatePicker from "react-datepicker";
import { InputGroup } from "react-bootstrap";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
const prepareFilter = (queryParams, values) => {
  const { type, searchText, deal, franchise } = values;
  const newQueryParams = { ...queryParams };

  const filter = {};
  // Filter by status
  // filter.status = status !== "" ? +status : undefined;
  // Filter by condition
  filter.agent = type !== "" ? type : undefined;
  filter.dealStage = deal !== "" ? deal : undefined;
  filter.franchise = franchise !== "" ? franchise : undefined;
  // Filter by all fields
  // filter.model = searchText;
  if (searchText) {
    filter.email = searchText;
    // filter.VINCode = searchText;
  }
  newQueryParams.filter = filter;
  return newQueryParams;
};
export function ReportingFilter() {
  const reportingUIContext = useReportingUIContext();
  const reportingUIProps = useMemo(() => {
    return {
      setQueryParams: reportingUIContext.setQueryParams,
      queryParams: reportingUIContext.queryParams,
    };
  }, [reportingUIContext]);
  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(reportingUIProps.queryParams, values);

    if (!isEqual(newQueryParams, reportingUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      reportingUIProps.setQueryParams(newQueryParams);
    }
  };
  return (
    <>
      <Formik
        initialValues={{
          status: "", // values => All=""/Susspended=0/Active=1/Pending=2
          type: "", // values => All=""/Business=0/Individual=1
          searchText: "",
          from: "",
          to: "",
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,

          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row d-flex justify-content-between">
              <div className="col-lg-10">
                <div className="row">
                  <div className="col-lg-3 d-flex podfilter">
                    <span className="mt-3">Franchise:&nbsp; </span>
                    <FormControl fullWidth>
                      <Select
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                        className="form-control"
                        onChange={(e) => {
                          setFieldValue("franchise", e.target.value);

                          handleSubmit();
                        }}
                        onBlur={handleBlur}
                        value={values.type}
                        displayEmpty
                        IconComponent={(props) => (
                          <i {...props}>
                            <KeyboardArrowDownIcon />
                          </i>
                        )}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem value="">All</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-lg-3 d-flex podfilter">
                    <span className="mt-3">Depot:&nbsp; </span>
                    <FormControl fullWidth>
                      <Select
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                        className="form-control"
                        onChange={(e) => {
                          setFieldValue("franchise", e.target.value);

                          handleSubmit();
                        }}
                        onBlur={handleBlur}
                        value={values.type}
                        displayEmpty
                        IconComponent={(props) => (
                          <i {...props}>
                            <KeyboardArrowDownIcon />
                          </i>
                        )}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem value="">All</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-lg-3 date-picker d-flex podfilter">
                    <span className="mt-3">From&nbsp;:&nbsp; </span>
                    {/* <input className="form-control" type="date" /> */}
                    <InputGroup className="calendr">
                      <DatePicker
                        fullWidth
                        fixedHeight
                        className="form-control"
                        wrapperClassName="datepicker"
                        dateFormat="dd-MM-Y"
                        placeholderText="MM/DD/YYYY"
                        selected={values.from}
                        onChange={(date) => setFieldValue("from", date)}
                        // popperClassName="date-picker-reports"
                      />
                      <CalendarTodayIcon />
                    </InputGroup>
                  </div>
                  <div className="col-lg-3 date-picker d-flex podfilter">
                    <span className="mt-3">To&nbsp;:&nbsp; </span>
                    <InputGroup className="calendr">
                      <DatePicker
                        fullWidth
                        fixedHeight
                        className="form-control"
                        dateFormat="dd-MM-Y"
                        selected={values.to}
                        onChange={(date) => setFieldValue("to", date)}
                        popperClassName="date-picker-reports"
                        placeholderText="MM/DD/YYYY"
                      />
                      <CalendarTodayIcon />
                    </InputGroup>
                    {/* <div
                      className="col-sm-4 collection-date"
                      style={{ width: "50%" }}
                    >
                      <DatePicker
                        className="form-control"
                        dateFormat="dd-MM-Y"
                        excludeDates={[new Date("06/25/2022")]}
                        selected={values.to}
                        onChange={(date) => setFieldValue("to", date)}
                        // onChange={(date) => setCollectionDate(date)}
                        placeholderText="Select Collection Date"
                        // inline
                      />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
