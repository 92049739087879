import * as requestFromServer from "./dealsCrud";

import { callTypes, dealsSlice } from "./dealsSlice";

const { actions } = dealsSlice;

//deal page list
export const fetchDeals = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  dispatch(
    actions.dealsFetched({ totalCount: 0, entities: [], loading: true })
  );
  return requestFromServer
    .dealsListing(queryParams)
    .then((response) => {
      const { meta, data } = response.data;
      dispatch(
        actions.dealsFetched({
          totalCount: meta.total,
          entities: data,
          loading: false,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

//grid view deal list
export const fetchDealsGrid = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  dispatch(
    actions.dealsFetched({ totalCount: 0, entities: [], loading: true })
  );
  return requestFromServer
    .dealsListingGrid(queryParams)
    .then((response) => {
      const { data } = response.data;
      dispatch(
        actions.dealsFetched({
          totalCount: 0,
          entities: data,
          loading: false,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

//quotes list
export const fetchQuotes = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .dealQuotes(queryParams)
    .then((response) => {
      const { meta, data } = response.data;
      dispatch(actions.dealsQuotes({ totalCount: meta.total, entities: data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const fetchContactQuotes = (id, queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .contactQuotes(id, queryParams)
    .then((response) => {
      const { total, data } = response.data;
      dispatch(actions.dealsQuotes({ totalCount: total, entities: data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

//deal detail
export const fetchDealDetail = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  dispatch(actions.dealDetailFetched({ data: {}, loading: true }));
  return requestFromServer
    .dealDetail(queryParams)
    .then((response) => {
      const { data } = response.data;

      dispatch(actions.dealDetailFetched({ data, loading: false }));
    })
    .catch((error) => {
      if (error?.response?.status === 404 || error?.response?.status === 400) {
        // Redirect to the error page
        window.location.href = "/error/error-v1"; // Replace '/error' with the actual route of your error page
      }

      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

//feed list
export const fetchDealfeedList = (queryParams, id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getDealsFeedList(queryParams, id)
    .then((response) => {
      const { data } = response.data;

      dispatch(actions.dealFeedList({ data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

//notes list
export const fetchDealNotesList = (queryParams, id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  dispatch(actions.dealNotesList({ data: [], loading: true }));
  return requestFromServer
    .getDealNotesList(queryParams, id)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.dealNotesList({ data, loading: false }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

//tasks list
export const fetchDealTasksList = (queryParams, id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  dispatch(actions.dealTasksList({ data: [], loading: true }));

  return requestFromServer
    .getDealTasksList(queryParams, id)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.dealTasksList({ data, loading: false }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

//email
export const fetchDealEmailList = (queryParams, id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  dispatch(actions.dealEmailsList({ data: [], loading: true }));

  return requestFromServer
    .getDealEmailsList(queryParams, id)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.dealEmailsList({ data, loading: false }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

//sms
export const fetchDealSmsList = (queryParams, id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  dispatch(actions.dealSmsList({ data: [], loading: true }));

  return requestFromServer
    .getDealSmsList(queryParams, id)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.dealSmsList({ data, loading: false }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
