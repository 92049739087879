import React from "react";
import { Modal } from "react-bootstrap";

const InactiveDialog = ({ job, setJob }) => {
  return (
    <Modal show={job.modal} aria-labelledby="example-modal-sizes-title-lg">
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Jobs booked on this van!
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>{job.message}</span>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={() => setJob({ modal: false, message: "" })}
            className="btn btn-light btn-elevate"
          >
            close
          </button>
          <> </>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default InactiveDialog;
