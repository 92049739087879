import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import LogsCard from "./LogsCard";
// import { fetchPermission } from "../../../../pages/redux/permission";
import {initIds} from "../../../../../../_metronic/_partials/controls/RightPanel";
import { useIntl } from "react-intl";
import { LogsUIProvider } from "./LogsUIContext";

export const LogsPage = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const init = ["kt_new_Logs_panel"];
  useEffect(() => {
    initIds(init);
  }, [init]);
  return (
    <>
      <LogsUIProvider>
        <LogsCard intl={intl} init={init} />
      </LogsUIProvider>
    </>
  );
};

export default LogsPage;