// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html

import * as actions from "../../../_redux/country/countryAction";
import * as columnFormatters from "./column-formatters";
import * as uiHelpers from "../CountryUIHelpers";

import React, { useEffect, useMemo } from "react";
import {
  getHandlerTableChange,
  getSelectRow,
  headerSortingClasses,
  sortCaret,
} from "../../../../../../_metronic/_helpers";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import BootstrapTable from "react-bootstrap-table-next";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import { useCountryUIContext } from "../CountryUIContext";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";

const CountryTable = ({ permission }) => {
  const intl = useIntl();
  let history = useHistory();
  const CountryUIContext = useCountryUIContext();
  const CountryUIProps = useMemo(() => {
    return {
      ids: CountryUIContext?.ids,
      setIds: CountryUIContext?.setIds,
      queryParams: CountryUIContext?.queryParams,
      setQueryParams: CountryUIContext?.setQueryParams,
      openEditOrderPage: CountryUIContext?.openEditOrderPage,
      openDeleteOrderDialog: CountryUIContext?.openDeleteOrderDialog,
    };
  }, [CountryUIContext]);
  const dispatch = useDispatch();
  const { currentState } = useSelector(
    (state) => ({
      currentState: state.country.list,
      permission: state.auth.permission,
    }),
    shallowEqual
  );

  const { totalCount, entities } = currentState;

  useEffect(() => {
    // clear selections list
    // server call by queryParams
    CountryUIProps.setIds([]);
    dispatch(actions.fetchCountryList(CountryUIProps.queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CountryUIProps.queryParams, dispatch]);

  const columns = [
    {
      dataField: "name",
      text: intl.formatMessage({ id: "COUNTRY" }),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "short_name",
      text: intl.formatMessage({ id: "Country.Short" }),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },

    {
      dataField: "language",
      text: intl.formatMessage({ id: "Language" }),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "currency",
      text: intl.formatMessage({ id: "Currency" }),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "timezone",
      text: intl.formatMessage({ id: "TimeZone" }),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "Status" }),
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.StatusColumnFormatter,
    },
  ];

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: CountryUIProps.queryParams?.pageSize,
    page: CountryUIProps.queryParams?.pageNumber,
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      history.push(`country/country-details/${row.id}`);
    },
  };

  const selectRow = {
    mode: "checkbox",
    hideSelectAll: true,
    hideSelectColumn: true,
  };

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={false}
              paginationProps={paginationProps}
              entities={entities}
            >
              <BootstrapTable
                rowEvents={rowEvents}
                wrapperClasses="table-responsive"
                hover
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="id"
                selectRow={
                  permission?.setting_country?.delete
                    ? getSelectRow({
                        entities,
                        ids: CountryUIProps.ids,
                        setIds: CountryUIProps.setIds,
                      })
                    : selectRow
                }
                defaultSorted={uiHelpers.defaultSorted}
                data={entities === null ? [] : entities}
                onTableChange={getHandlerTableChange(
                  CountryUIProps.setQueryParams
                )}
                columns={columns}
                {...paginationTableProps}
              ></BootstrapTable>

              {entities && entities?.length ? (
                ""
              ) : (
                <div style={{ textAlign: "center" }}>
                  <p>{intl.formatMessage({ id: "No.Record.Found" })}</p>
                </div>
              )}
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
};

export default CountryTable;
