import React, { useEffect } from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { useIntl } from "react-intl";
import { initIds } from "../../../../../_metronic/_partials/controls/RightPanel";
import { CircularProgress } from "@material-ui/core";
import { removeLastComma } from "../../../../utils/commonFunction";
import { useState } from "react";
import SnackBarTool from "../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";

export function CustomerDetailCard({
  firstName,
  lastName,
  address,
  city,
  postcode,
  email,
  phone,
  franchise,
  agent,
  setOpenCanva,
  agentLast,
  detailData,
  orderDetail,
  type,
  orderDataLoading,
  state,
}) {
  const init = ["kt_quick_panelone"];
  useEffect(() => {
    initIds(init);
  }, [init]);
  const intl = useIntl();
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  useEffect(() => {
    if (snack.message !== "") {
      const timeoutId = setTimeout(() => {
        setSnack((prevSnack) => ({ call: false, type: "", message: "" }));
      }, 5000);

      return () => clearTimeout(timeoutId);
    }
  }, [snack.message]);

  return (
    <>
      {type === "newOrder" && orderDataLoading ? (
        <div className="text-center mt-8">
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className="d-flex ">
            <div>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1">
                  <div className="d-flex align-items-center justify-content-between flex-wrap mt-2">
                    <div className="mr-3">
                      <h5 className="card-title align-items-start flex-column mb-0">
                        <span className="font-weight-bolder text-dark">
                          {!detailData?.order?.id && type === "newOrder"
                            ? "Contact Details"
                            : intl.formatMessage({ id: "Customer.Details" })}
                        </span>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <span className="symbol-label pointer">
                <button
                  className="btn btn-link"
                  onClick={() => {
                    if (
                      state?.collectionRetrun?.collection_date &&
                      type === "newOrder"
                    ) {
                      setSnack({
                        call: true,
                        type: "error",
                        message:
                          "Postcode can not change after choose collection date. Please reset the calendar details",
                      });
                    } else {
                      setOpenCanva(true);
                    }
                  }}
                >
                  <span className="svg-icon svg-icon-sm svg-icon-primary">
                    <SVG
                      title=" "
                      className="h-25 align-self-end"
                      src={toAbsoluteUrl("/media/svg/icons/Design/Edit.svg")}
                    ></SVG>
                  </span>
                </button>
              </span>
            </div>
          </div>
          <div className="d-flex align-items-center flex-wrap customer-details-sec">
            <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
              <span className="mr-4">
                <span className="svg-icon svg-icon-2x svg-icon-gray-500 mr-1">
                  <SVG
                    title=" "
                    src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}
                  ></SVG>
                </span>
              </span>
              <div className="d-flex flex-column">
                <span className=" font-size-h6">
                  {detailData?.first_name || "-"}&nbsp;
                  {detailData?.last_name || ""}
                </span>
              </div>
            </div>

            <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
              <span className="d-flex linktxt">
                <span className="mr-4">
                  <span className="svg-icon svg-icon-2x svg-icon-gray-500 mr-1">
                    <SVG
                      title=" "
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Communication/Mail.svg"
                      )}
                    ></SVG>
                  </span>
                </span>
                <div className="d-flex flex-column ">
                  <a
                    className="font-size-h6 text-hover-primary"
                    style={{ color: "#3F4254" }}
                    href={`mailto:${detailData?.email || ""}`}
                  >
                    {detailData?.email || "-"}
                  </a>
                </div>{" "}
              </span>
            </div>

            <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
              <span className="d-flex linktxt">
                <span className="mr-4">
                  <span className="svg-icon svg-icon-2x svg-icon-gray-500 mr-1">
                    <SVG
                      title=" "
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Communication/Active-call.svg"
                      )}
                    ></SVG>
                  </span>
                </span>
                <div className="d-flex flex-column ">
                  <a
                    className=" font-size-h6 text-hover-primary"
                    style={{ color: "#3F4254" }}
                    href={`tel:${detailData?.phone || ""}`}
                  >
                    {detailData?.phone || "-"}
                  </a>
                </div>
              </span>
            </div>

            <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
              <span className="mr-4">
                <span className="svg-icon svg-icon-2x svg-icon-gray-500 mr-1">
                  <SVG
                    title=" "
                    src={toAbsoluteUrl("/media/svg/icons/Home/Building.svg")}
                  ></SVG>
                </span>
              </span>
              <div className="d-flex flex-column flex-lg-fill">
                <span className=" font-size-h6">
                  {" "}
                  {franchise ? franchise : "-"}
                </span>
              </div>
            </div>
            <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
              <span className="mr-4">
                <span className="svg-icon svg-icon-2x svg-icon-gray-500 mr-1">
                  <SVG
                    title=" "
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Group.svg"
                    )}
                  ></SVG>
                </span>
              </span>
              <div className="d-flex flex-column">
                <span className="font-size-h6">
                  {" "}
                  {type === "newOrder" && agent ? (
                    agent + " " + agentLast
                  ) : orderDetail?.created_by &&
                    orderDetail?.created_by === orderDetail?.user_id ? (
                    "Online"
                  ) : (
                    <>{orderDetail?.created_by_name || "-"} &nbsp;</>
                  )}
                </span>
              </div>
            </div>

            {orderDetail?.contact_owner_name && (
              <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
                <span className="mr-4">
                  <span className="svg-icon svg-icon-2x svg-icon-gray-500 mr-1">
                    <SVG
                      title=" "
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Communication/Group.svg"
                      )}
                    ></SVG>
                  </span>
                </span>
                <div className="d-flex flex-column">
                  <span className="font-size-h6">
                    {orderDetail?.contact_owner_name}
                  </span>
                </div>
              </div>
            )}
            <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
              <span className="mr-4">
                <span className="svg-icon svg-icon-2x svg-icon-gray-500 mr-1">
                  <SVG
                    title=" "
                    src={toAbsoluteUrl("/media/svg/icons/Map/Marker1.svg")}
                  ></SVG>
                </span>
              </span>
              <div className="d-flex flex-column text-dark-75">
                <span className="font-size-h6">
                  {type === "order" && orderDetail?.collection_address_line
                    ? "Collection Address: " +
                      removeLastComma(
                        orderDetail?.collection_address_line?.replaceAll(
                          ",",
                          ", "
                        )
                      )
                    : detailData?.address
                    ? "Collection Address: " +
                      detailData?.address
                        .replace(/,,/g, ", ")
                        .replace(/,,/g, ", ")
                        ?.replaceAll(",", ", ")
                    : "-"}
                  {type === "order"
                    ? ""
                    : detailData?.city
                    ? ", " + detailData?.city
                    : ""}
                  {type === "order" && orderDetail?.collection_postcode
                    ? ", " + orderDetail?.collection_postcode + "."
                    : detailData?.postcode
                    ? ", " + detailData?.postcode + "."
                    : ""}
                </span>
              </div>
            </div>
          </div>
          {snack.call ? <SnackBarTool {...snack} /> : ""}
        </>
      )}
    </>
  );
}
