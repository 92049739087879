import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./ReportingUIHelpers";

const ReportingUIContext = createContext();

export function useReportingUIContext() {
  return useContext(ReportingUIContext);
}

export const ReportingUIConsumer = ReportingUIContext.Consumer;

export function ReportingUIProvider({ ReportingUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    openEditProductPage: ReportingUIEvents?.openEditProductPage,
    openDeleteProductDialog: ReportingUIEvents?.openDeleteProductDialog,
    openDeleteReportingDialog: ReportingUIEvents?.openDeleteReportingDialog,
    openFetchReportingDialog: ReportingUIEvents?.openFetchReportingDialog,
    openUpdateReportingStatusDialog:
      ReportingUIEvents?.openUpdateReportingStatusDialog,
  };

  return (
    <ReportingUIContext.Provider value={value}>
      {children}
    </ReportingUIContext.Provider>
  );
}
