import * as requestFromServer from "./productsCrud";
import { productsSlice, callTypes } from "./productsSlice";

const { actions } = productsSlice;

//product list
export const fetchProducts = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findProduct(queryParams)
    .then((response) => {
      const { data, meta } = response.data;
      dispatch(
        actions.productFetched({
          totalCount: meta.total,
          entities: data,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(
        actions.productFetched({ totalCount: 0, entities: [], loading: false })
      );
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

//product detail
export const fetchProductDetail = (queryParams, franchiseId = "") => (
  dispatch
) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  dispatch(actions.productDetailFetched({ data: { loading: true } }));
  return requestFromServer
    .getProductDetail(queryParams, franchiseId)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.productDetailFetched({ data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(
        actions.productDetailFetched({
          data: { loading: true, title: "No Record Found" },
        })
      );
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

//franchise list
export const fetchFranchise = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  dispatch(actions.productFranchiseFetched({ data: [] }));
  return requestFromServer
    .getfranchiseList(queryParams)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.productFranchiseFetched({ data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

//product detail timeline

export const fetchMyProductTimeline = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findMyProductTimeline(queryParams)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.productTimelineFetched({ data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
