// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html

import * as uiHelpers from "../TasksUIHelpers";

import React, { useEffect, useMemo, useState } from "react";
import {
  getHandlerTableChange,
  getSelectRow,
  sortCaret,
} from "../../../../_metronic/_helpers";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import BootstrapTable from "react-bootstrap-table-next";
import { ChangeTasksCanva } from "../tasks-canvas/ChangeTasksCanva";
import NoRecord from "../../../../_metronic/_partials/widgets/NoRecord";
import NotAuthorized from "../../../../_metronic/_partials/widgets/NotAuthorized";
import { Pagination } from "../../../../_metronic/_partials/controls";
// import { fetchAgent } from "../../ContactDeals/_redux/contact/contactAction";
import { fetchTasks } from "../_redux/TasksAction";
import moment from "moment";
import { useIntl } from "react-intl";
import { useTasksUIContext } from "../TasksUIContext";
import ContactDealTaskModal from "../../../../_metronic/_partials/widgets/alert/ContactDealTaskModal";

const TasksTable = ({ show, setShow, permission }) => {
  const intl = useIntl();
  const TasksUIContext = useTasksUIContext();
  const [dialog, setDialog] = useState({ type: "", open: false, id: "" });
  const TasksUIProps = useMemo(() => {
    return {
      ids: TasksUIContext?.ids,
      setIds: TasksUIContext?.setIds,
      queryParams: TasksUIContext?.queryParams,
      setQueryParams: TasksUIContext?.setQueryParams,
      openEditContactPage: TasksUIContext?.openEditContactPage,
      openDeleteContactDialog: TasksUIContext?.openDeleteContactDialog,
    };
  }, [TasksUIContext]);

  const dispatch = useDispatch();

  const { currentState } = useSelector(
    (state) => ({ currentState: state.tasks.list }),
    shallowEqual
  );

  const { totalCount, entities, loading } = currentState;

  useEffect(() => {
    // clear selections list
    // server call by queryParams
    TasksUIProps.setIds([]);
    dispatch(fetchTasks(TasksUIProps.queryParams));
    // dispatch(fetchAgent());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TasksUIProps.queryParams, dispatch]);

  useEffect(() => {
    TasksUIProps.setQueryParams(uiHelpers.initialFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const columns = [
    {
      dataField: "task_type",
      text: intl.formatMessage({ id: "Task.Type" }),
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "priority",
      text: intl.formatMessage({ id: "Priority" }),
      sort: true,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return (
          <span
            className={`label label-lg label-light-${
              e === "Hot"
                ? "success"
                : e === "Medium"
                ? "warning"
                : e === "Low"
                ? "danger"
                : ""
            } label-inline`}
          >
            {e ? e : "-"}
          </span>
        );
      },
    },

    {
      dataField: "task_name",
      text: intl.formatMessage({ id: "Task.Name" }),
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "task_owner_id",
      text: intl.formatMessage({ id: "Task.Owner" }),
      sort: true,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return (
          <span>
            {data && data.is_unassigned === 0 ? (
              <>
                {data && data.owner_data && data.owner_data.first_name
                  ? data.owner_data.first_name
                  : " - "}
                &nbsp;
                {data?.owner_data?.last_name}
              </>
            ) : (
              "Unassigned"
            )}
          </span>
        );
      },
    },
    {
      dataField: "",
      text: intl.formatMessage({ id: "NAME" }),
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return (
          <span>
            {data?.deal_user === "" ? data?.contact_user : data?.deal_user}
          </span>
        );
      },
    },
    {
      dataField: "franchise",
      text: intl.formatMessage({ id: "FRANCHISES" }),
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return (
          <span>
            {data.deal_data
              ? data.deal_data.franchise &&
                data.deal_data.franchise.name &&
                data.deal_data.franchise.name
                ? data.deal_data.franchise.name
                : " - "
              : data &&
                data.contact_data &&
                data.contact_data.franchise &&
                data.contact_data.franchise.name
              ? data.contact_data.franchise.name
              : " - "}
          </span>
        );
      },
    },
    {
      dataField: "agent",
      text: intl.formatMessage({ id: "Agent" }),
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return (
          <span>
            {data.deal_data
              ? data.deal_data.agent &&
                data.deal_data.agent.first_name &&
                data.deal_data.agent.first_name
                ? data.deal_data.agent.first_name
                : " - "
              : data &&
                data.contact_data &&
                data.contact_data.agent &&
                data.contact_data.agent.first_name
              ? data.contact_data.agent.first_name
              : " - "}
          </span>
        );
      },
    },

    {
      dataField: "stage",
      text: "Type",
      sort: false,
      sortCaret: sortCaret,
    },
    {
      dataField: "created_at",
      text: intl.formatMessage({ id: "Create.Date" }),
      sort: true,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return <span>{moment(e).format("DD-MM-YYYY")}</span>;
      },
    },
    {
      dataField: "due_date",
      text: intl.formatMessage({ id: "Due.Date" }),
      sort: true,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return <span>{moment(e).format("DD-MM-YYYY")}</span>;
      },
    },
    {
      dataField: "time",
      text: "Time",
      sort: true,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return <span>{e ? e : "-"}</span>;
      },
    },
  ];

  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: TasksUIProps.queryParams?.pageSize,
    page: TasksUIProps.queryParams?.pageNumber,
  };
  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      if (row?.stage === "Contact") {
        setDialog({ type: "contact", open: true, contact_id: row.contact_id });
        // history.push(`/contact-details/${row.contact_id}`);
      } else {
        setDialog({
          type: "deal",
          open: true,
          deal_id: row.deal_id,
          contact_id: row.contact_id,
          from_tasks: true,
        });
        // history.push(`/deal-view/${row.deal_id}`);
      }
    },
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={loading}
              paginationProps={paginationProps}
              entities={entities}
            >
              {show && (
                <BootstrapTable
                  wrapperClasses="table-responsive"
                  bordered={false}
                  rowEvents={rowEvents}
                  classes="table table-head-custom table-vertical-center overflow-hidden"
                  bootstrap4
                  remote
                  hover
                  keyField="id"
                  selectRow={getSelectRow({
                    entities,
                    ids: TasksUIProps.ids,
                    setIds: TasksUIProps.setIds,
                  })}
                  defaultSorted={uiHelpers.defaultSorted}
                  data={
                    !permission?.task?.view || entities === null ? [] : entities
                  }
                  onTableChange={getHandlerTableChange(
                    TasksUIProps.setQueryParams
                  )}
                  columns={columns}
                  {...paginationTableProps}
                ></BootstrapTable>
              )}
              {permission?.task?.view ? (
                <>
                  {(entities && entities?.length) || loading ? (
                    ""
                  ) : (
                    <NoRecord />
                  )}
                </>
              ) : (
                <NotAuthorized />
              )}
            </Pagination>
          );
        }}
      </PaginationProvider>
      <ChangeTasksCanva setShow={setShow} />
      <ContactDealTaskModal dialog={dialog} setDialog={setDialog} />
    </>
  );
};

export default TasksTable;
