import { createSlice } from "@reduxjs/toolkit";

const initialContactState = {
  list: {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    productForEdit: undefined,
    lastError: null,
    loading: false,
  },
  dealList: {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    productForEdit: undefined,
    lastError: null,
  },
  detail: {},
  notesList: { entities: [], loading: false },
  tasksList: { entities: [], loading: false },
  emailList: { entities: [], loading: false },
  smsList: { entities: [], loading: false },
  feedList: [],
  callList: [],
  agent: [],
  revertEmail: {
    entities: [],
  },
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const contactSlice = createSlice({
  name: "contact",
  initialState: initialContactState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.list.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.list.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    //contact list
    contactFetched: (state, action) => {
      const { totalCount, entities, loading } = action.payload;
      state.list.listLoading = false;
      state.list.error = null;
      state.list.entities = entities;
      state.list.totalCount = totalCount;
      state.list.loading = loading;
    },

    //contact deal list
    contactDealFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.dealList.listLoading = false;
      state.dealList.error = null;
      state.dealList.entities = entities;
      state.dealList.totalCount = totalCount;
    },

    contactCallFetched: (state, action) => {
      const { data } = action.payload;
      state.callList = data[0].calls;
    },
    //agent list
    agentFetched: (state, action) => {
      const { data } = action.payload;
      state.agent = data;
    },
    // contail detail
    contactDetailFetched: (state, action) => {
      const { data, loading } = action.payload;
      state.detail = data;
      state.detail.loading = loading;
    },
    //notes list
    contactNotesList: (state, action) => {
      const { data, loading } = action.payload;
      state.notesList.entities = data;
      state.notesList.loading = loading;
    },
    //tasks list
    contactTasksList: (state, action) => {
      const { data, loading } = action.payload;
      state.tasksList.entities = data;
      state.tasksList.loading = loading;
    },
    //email list
    contactEmailList: (state, action) => {
      const { data, loading } = action.payload;
      state.emailList.entities = data;
      state.emailList.loading = loading;
    },
    //revert email list
    contactRevertEmailFetched: (state, action) => {
      const { data } = action.payload;
      state.revertEmail.entities = data;
    },
    //sms list
    contactSmsList: (state, action) => {
      const { data, loading } = action.payload;
      state.smsList.entities = data;
      state.smsList.loading = loading;
    },
    //feed list
    contactFeedList: (state, action) => {
      const { data } = action.payload;
      state.feedList = data;
    },
  },
});
