import React, { useMemo } from "react";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import { shallowEqual, useSelector } from "react-redux";

import { Formik } from "formik";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { isEqual } from "lodash";
import { useContactUIContext } from "../ContactUIContext";
import { useIntl } from "react-intl";
import { view } from "../_mocks/staticData";
import { CustomeSearch } from "../../../../_metronic/_partials/controls/CustomeSearch";
import { InputGroup } from "react-bootstrap";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import DatePicker from "react-datepicker";
import moment from "moment";

const prepareFilter = (queryParams, values) => {
  const { searchText, view, agent, to_date, from_date } = values;

  const newQueryParams = { ...queryParams };
  const filter = {};
  filter.search_agent = agent ? agent : "";
  filter.search_view = view ? view : "";
  filter.search = searchText ? searchText : "";
  filter.to_date = to_date ? to_date : "";
  filter.from_date = from_date ? from_date : "";

  newQueryParams.filter = filter;

  return newQueryParams;
};
export function ContactFilter() {
  const contactUIContext = useContactUIContext();

  const contactUIProps = useMemo(() => {
    return {
      setQueryParams: contactUIContext.setQueryParams,
      queryParams: contactUIContext.queryParams,
    };
  }, [contactUIContext]);

  const { currentState } = useSelector(
    (state) => ({ currentState: state.contact.agent }),
    shallowEqual
  );

  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(contactUIProps.queryParams, values);

    if (!isEqual(newQueryParams, contactUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      contactUIProps.setQueryParams(newQueryParams);
    }
  };

  const intl = useIntl();

  return (
    <>
      <Formik
        initialValues={{
          view: "", // values => All=""/Susspended=0/Active=1/Pending=2
          agent: "", // values => All=""/Business=0/Individual=1
          searchText: "",
          from_date: "",
          to_date: "",
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,

          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row d-flex justify-content-between">
              <div className="col-lg-10">
                <div className="row">
                  <div className="col-lg-3">
                    <FormControl fullWidth>
                      <Select
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                        className="form-control"
                        IconComponent={(props) => (
                          <i {...props}>
                            <KeyboardArrowDownIcon />
                          </i>
                        )}
                        inputProps={{ "aria-label": "Without label" }}
                        displayEmpty
                        onChange={(e) => {
                          setFieldValue("view", e.target.value);
                          handleSubmit();
                        }}
                        onBlur={handleBlur}
                        value={values.view}
                      >
                        <MenuItem value="">
                          {intl.formatMessage({ id: "View" })}
                        </MenuItem>
                        {view(intl).map((data, index) => (
                          <MenuItem key={index} value={data.value}>
                            {data.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-lg-3 mb-3 order-flt">
                    <span className="mt-3">
                      {intl.formatMessage({ id: "Agent" })}:&nbsp;{" "}
                    </span>
                    <FormControl fullWidth>
                      <Select
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                        className="form-control"
                        onChange={(e) => {
                          setFieldValue("agent", e.target.value);
                          handleSubmit();
                        }}
                        onBlur={handleBlur}
                        value={values.agent}
                        displayEmpty
                        IconComponent={(props) => (
                          <i {...props}>
                            <KeyboardArrowDownIcon />
                          </i>
                        )}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem value="">
                          {intl.formatMessage({ id: "All" })}
                        </MenuItem>
                        {currentState &&
                          currentState.map((data, index) => (
                            <MenuItem key={index} value={data.id}>
                              {data.first_name +
                                " " +
                                (data.last_name ? data.last_name : "")}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-lg-5  d-flex order-flt mb-4">
                    <span className="mt-3">
                      Created&nbsp;By&nbsp;Date:&nbsp;
                    </span>{" "}
                    <InputGroup className="calendr">
                      <DatePicker
                        fullWidth
                        fixedHeight
                        className="form-control"
                        wrapperClassName="datepicker"
                        dateFormat="dd-MM-yyyy"
                        placeholderText="From Date"
                        selected={Date.parse(values?.from_date)}
                        startDate={Date.parse(values?.from_date)}
                        endDate={Date.parse(values?.to_date)}
                        // selectsRange
                        // isClearable
                        onChange={(dates) => {
                          // const [start, end] = dates;
                          setFieldValue(
                            "from_date",
                            dates ? moment(dates).format("yyyy-MM-DD") : ""
                          );

                          if (dates === null) {
                            handleSubmit();
                          }
                          // setFieldValue(
                          //   "to_date",
                          //   end ? moment(end).format("yyyy-MM-DD") : ""
                          // );
                          // if (end || (start === null && end === null)) {
                          //   handleSubmit();
                          // }
                        }}
                      />
                      {/*  <DatePicker
                        fullWidth
                        fixedHeight
                        className="form-control"
                        wrapperClassName="datepicker"
                        dateFormat="dd-MM-yyyy"
                        placeholderText="dd-mm-yyyy - dd-mm-yyyy"
                        selected={Date.parse(values?.to_date)}
                        startDate={Date.parse(values?.from_date)}
                        endDate={Date.parse(values?.to_date)}
                        // selectsRange
                        onChange={(dates) => {
                          // setFieldValue(
                          //   "from_date",
                          //   start ? moment(start).format("yyyy-MM-DD") : ""
                          // );
                          setFieldValue(
                            "to_date",
                            dates ? moment(dates).format("yyyy-MM-DD") : ""
                          );
                          // if (dates || (start === null && end === null)) {
                          handleSubmit();
                          // }
                        }}
                      /> */}
                      {/* <DatePicker
                        fullWidth
                        fixedHeight
                        className="form-control"
                        wrapperClassName="datepicker"
                        dateFormat="dd-MM-yyyy"
                        placeholderText="dd-mm-yyyy - dd-mm-yyyy"
                        selected={Date.parse(values?.from_date)}
                        startDate={Date.parse(values?.from_date)}
                        endDate={Date.parse(values?.to_date)}
                        selectsRange
                        onChange={(dates) => {
                          const [start, end] = dates;
                          setFieldValue(
                            "from_date",
                            start ? moment(start).format("yyyy-MM-DD") : ""
                          );
                          setFieldValue(
                            "to_date",
                            end ? moment(end).format("yyyy-MM-DD") : ""
                          );
                          if (end || (start === null && end === null)) {
                            handleSubmit();
                          }
                        }}
                        // minDate={new Date()}
                      /> */}
                      <CalendarTodayIcon />
                    </InputGroup>
                    <InputGroup className="calendr">
                      <DatePicker
                        fullWidth
                        fixedHeight
                        className="form-control"
                        wrapperClassName="datepicker"
                        dateFormat="dd-MM-yyyy"
                        placeholderText="To Date"
                        selected={Date.parse(values?.to_date)}
                        startDate={Date.parse(values?.from_date)}
                        endDate={Date.parse(values?.to_date)}
                        // selectsRange
                        onChange={(dates) => {
                          // setFieldValue(
                          //   "from_date",
                          //   start ? moment(start).format("yyyy-MM-DD") : ""
                          // );
                          setFieldValue(
                            "to_date",
                            dates ? moment(dates).format("yyyy-MM-DD") : ""
                          );
                          // if (dates || (start === null && end === null)) {
                          handleSubmit();
                          // }
                        }}
                      />

                      <CalendarTodayIcon />
                    </InputGroup>
                  </div>
                  {/* <div className="col-lg-3  d-flex order-flt mb-4">
                    <span className="mt-3">To:&nbsp;</span>{" "}
                    <InputGroup className="calendr">
                      <DatePicker
                        fullWidth
                        fixedHeight
                        className="form-control"
                        wrapperClassName="datepicker"
                        dateFormat="dd-MM-yyyy"
                        placeholderText="Select Date"
                        selected={Date.parse(values?.to_date)}
                        onChange={(dates) => {
                          setFieldValue(
                            "to_date",
                            moment(dates).format("yyyy-MM-DD")
                          );
                          handleSubmit();
                        }}
                      />
                      <CalendarTodayIcon />
                    </InputGroup>
                  </div> */}
                </div>
              </div>
              <div className="col-lg-2 mb-3">
                <CustomeSearch
                  intl={intl}
                  handleBlur={handleBlur}
                  values={values}
                  setFieldValue={setFieldValue}
                  handleSubmit={handleSubmit}
                />
                {/* <div style={{ position: "relative" }}>
                  <input
                    style={{ paddingRight: "40px" }}
                    type="text"
                    className="form-control"
                    name="searchText"
                    placeholder={intl.formatMessage({ id: "Search" })}
                    onBlur={handleBlur}
                    value={values.searchText}
                    onChange={(e) => {
                      setFieldValue("searchText", e.target.value);
                      handleSubmit();
                    }}
                  />
                  <i
                    style={{
                      position: "absolute",
                      top: "50%",
                      transform: "translateY(-50%)",
                      right: "15px",
                    }}
                    className="fa fa-search"
                    aria-hidden="true"
                  ></i>
                </div> */}
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
