/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";
import moment from "moment";
import { useIntl } from "react-intl";
export function CallsWidget({ data }) {
  let minutes =
    +data?.recording_duration < 60
      ? data?.recording_duration
      : (+data?.recording_duration / 60).toFixed(2);
  // eslint-disable-next-line no-unused-vars
  const secondsToHms = (data) => {
    data = Number(data);
    var h = Math.floor(data / 3600);
    var m = Math.floor((data % 3600) / 60);
    var s = Math.floor((data % 3600) % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return hDisplay + mDisplay + sDisplay;
  };
  const intl = useIntl();
  return (
    <>
      <div className="fieldset-area">
        <fieldset className="mb-5">
          <legend>
            {" "}
            {moment(data?.start_time).format("DD-MM-YYYY")} at{" "}
            {moment(data?.start_time).format("hh:mm")}
          </legend>
          <div className="p-5">
            <h5>
              {intl.formatMessage({ id: "Call" })} :{" "}
              {data?.answered ? "Connected" : "Not Connected"}{" "}
              <span className="symbol-label">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={data?.recording_player}
                  className="svg-icon svg-icon-lg svg-icon-primary"
                >
                  <SVG
                    className="h-75 align-self-end"
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Devices/Headphones.svg"
                    )}
                  ></SVG>
                </a>
              </span>{" "}
            </h5>

            <p>
              {intl.formatMessage({ id: "Duration" })} : {minutes}{" "}
              {+data?.recording_duration < 60 ? "sec" : "min"}
            </p>
          </div>
        </fieldset>
      </div>
    </>
  );
}
