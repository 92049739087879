import React, { useLayoutEffect } from "react";
import { KTUtil } from "../../_assets/js/components/util";
import KTLayoutHeader from "../../_assets/js/layout/base/header";
import KTLayoutHeaderMenu from "../../_assets/js/layout/base/header-menu";
import KTLayoutHeaderTopbar from "../../_assets/js/layout/base/header-topbar";
import KTLayoutBrand from "../../_assets/js/layout/base/brand";
import KTLayoutAside from "../../_assets/js/layout/base/aside";
import KTLayoutAsideToggle from "../../_assets/js/layout/base/aside-toggle";
import KTLayoutAsideMenu from "../../_assets/js/layout/base/aside-menu";
import KTLayoutContent from "../../_assets/js/layout/base/content";
import KTLayoutFooter from "../../_assets/js/layout/base/footer";
import KTLayoutSubheader from "../../_assets/js/layout/base/subheader";
import KTLayoutScrolltop from "../../_assets/js/layout/extended/scrolltop";
import KTLayoutStickyCard from "../../_assets/js/layout/base/sticky-card";
import KTLayoutStretchedCard from "../../_assets/js/layout/base/stretched-card";
import KTLayoutQuickPanel from "../../_assets/js/layout/extended/quick-panel";
import KTLayoutQuickUser from "../../_assets/js/layout/extended/quick-user";
import { useParams } from "react-router-dom";

export function LayoutInit() {
  const router = useParams();
  useLayoutEffect(() => {
    // Initialization
    KTUtil.ready(function() {
      ////////////////////////////////////////////////////
      // Layout Base Partials(mandatory for core layout)//
      ////////////////////////////////////////////////////
      // Init Desktop & Mobile Headers
      KTLayoutHeader.init("kt_header", "kt_header_mobile");

      // Init Header Menu
      KTLayoutHeaderMenu.init("kt_header_menu", "kt_header_menu_wrapper");
      // Init Header Topbar For Mobile Mode
      KTLayoutHeaderTopbar.init("kt_header_mobile_topbar_toggle");
      // Init Brand Panel For Logo
      KTLayoutBrand.init("kt_brand");
      // Init Aside
      KTLayoutAside.init("kt_aside");

      // Init Aside Menu Toggle
      KTLayoutAsideToggle.init("kt_aside_toggle");
      //
      // Init Aside Menu
      KTLayoutAsideMenu.init("kt_aside_menu");

      // Init Content
      KTLayoutContent.init("kt_content");

      // Init Footer
      KTLayoutFooter.init("kt_footer");

      //////////////////////////////////////////////
      // Layout Extended Partials(optional to use)//
      //////////////////////////////////////////////
      KTLayoutSubheader.init("kt_subheader");

      // Init Scrolltop
      KTLayoutScrolltop.init("kt_scrolltop");

      // Init Sticky Card
      KTLayoutStickyCard.init("kt_page_sticky_card");

      // Init Stretched Card
      KTLayoutStretchedCard.init("kt_page_stretched_card");

      // Init Quick Offcanvas Panel
      KTLayoutQuickPanel.init("kt_quick_panel");
      // KTLayoutQuickPanel.init("kt_quick_panelone");
      KTLayoutQuickPanel.init("kt_assign_panel");
      KTLayoutQuickPanel.init("kt_deals_panel");
      KTLayoutQuickPanel.init("kt_deals_assign_panel");
      // KTLayoutQuickPanel.init("kt_new_product_panel");
      // KTLayoutQuickPanel.init("kt_product_category_panel");
      KTLayoutQuickPanel.init("kt_demo_panel");
      KTLayoutQuickPanel.init("kt_customerdetails_panel");
      // KTLayoutQuickPanel.init("kt_collectionedit_panel");
      KTLayoutQuickPanel.init("kt_sendquote_panel");
      KTLayoutQuickPanel.init("kt_orderpayment_panel");
      // KTLayoutQuickPanel.init("kt_distanceduration_panel");
      KTLayoutQuickPanel.init("kt_contactdetails_panel");
      KTLayoutQuickPanel.init("kt_taskedit_panel");
      KTLayoutQuickPanel.init("kt_addattachment_panel");
      KTLayoutQuickPanel.init("kt_dealsuserdetails_panel");
      // KTLayoutQuickPanel.init("kt_franchisesdetailsedit_panel");
      KTLayoutQuickPanel.init("kt_addvan_panel");
      KTLayoutQuickPanel.init("kt_editvan_panel");
      KTLayoutQuickPanel.init("kt_adddepot_panel");
      KTLayoutQuickPanel.init("kt_depotdetailsedit_panel");
      // KTLayoutQuickPanel.init("kt_franchisepostcodes_panel");
      // KTLayoutQuickPanel.init("kt_addsector_panel");
      // KTLayoutQuickPanel.init("kt_editsector_panel");
      // KTLayoutQuickPanel.init("kt_stripedetail_panel");
      // KTLayoutQuickPanel.init("kt_quickbookdetail_panel");
      // KTLayoutQuickPanel.init("kt_termscondition_panel");
      // KTLayoutQuickPanel.init("kt_cookiepolicy_panel");
      // KTLayoutQuickPanel.init("kt_privacypolicy_panel");
      // KTLayoutQuickPanel.init("kt_insurance_panel");
      KTLayoutQuickPanel.init("kt_franchisepricing_panel");
      KTLayoutQuickPanel.init("kt_customernotes_panel");
      KTLayoutQuickPanel.init("kt_customerattachment_panel");
      KTLayoutQuickPanel.init("kt_StorageDetail_panel");
      KTLayoutQuickPanel.init("kt_OrderCardDetailEdit_panel");
      // KTLayoutQuickPanel.init("kt_OrderInvoice_panel");
      KTLayoutQuickPanel.init("kt_SendInvoice_panel");
      // KTLayoutQuickPanel.init("kt_PartPayment_panel");
      // KTLayoutQuickPanel.init("kt_PaymentPlan_panel");
      // KTLayoutQuickPanel.init("kt_Refund_panel");

      // KTLayoutQuickPanel.init("kt_CreditNotes_panel");

      // KTLayoutQuickPanel.init("kt_PutOnHold_panel");
      KTLayoutQuickPanel.init("kt_OrderNotes_panel");
      KTLayoutQuickPanel.init("kt_EditOrderDetailSummary_panel");
      KTLayoutQuickPanel.init("kt_PodInStorage_panel");
      KTLayoutQuickPanel.init("kt_NewDistanceDuration_panel");
      // KTLayoutQuickPanel.init("kt_SendEmail_panel");
      KTLayoutQuickPanel.init("kt_AddEditStorage_panel");
      KTLayoutQuickPanel.init("kt_EditOrderNotes_panel");
      KTLayoutQuickPanel.init("kt_DriverNotes_panel");

      // Init Quick User Panel
      KTLayoutQuickUser.init("kt_quick_user");
    });
  }, [router]);
  return <></>;
}
