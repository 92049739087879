const phoneRegExp = /^\+[1-9]{1}[0-9]{3,14}$/;

export const CreateContactSchema = (intl, yp) => {
  return yp.object().shape({
    first_name: yp
      .string()
      .trim()
      .matches(/^[aA-zZ\s]+$/, intl.formatMessage({ id: "Only.alphabets" }))
      .required(intl.formatMessage({ id: "First.Name.Required" })),
    // last_name: yp
    //   .string()
    //   .trim()
    //   .matches(/^[aA-zZ\s]+$/, intl.formatMessage({ id: "Only.alphabets" }))
    //   .required(intl.formatMessage({ id: "Last.Name.Required" })),
    email: yp
      .array()
      .of(
        yp
          .string()
          .trim()
          .email(intl.formatMessage({ id: "Wrong.email.format" }))
          .max(50, intl.formatMessage({ id: "Maximum.50.symbols" }))
      )
      .required(intl.formatMessage({ id: "Email.Required" })),
    phone: yp
      .array()
      .of(
        yp
          .string()
          // .matches(
          //   phoneRegExp,
          //   intl.formatMessage({ id: "Country.Code.valid" })
          // )
          // .trim()
          // .min(12, intl.formatMessage({ id: "Country.Code.valid" }))
          .min(10, "Invalid Phone Number")
      )
      .required(intl.formatMessage({ id: "Phone.Number.Required" })),
    postcode: yp
      .string()
      .trim()
      .matches(/^[aA-zZ-Z0-9\s]+$/, "Only alphabets and Number are allowed ")
      .required("Post Code is Required"),
    // address: yp
    //   .string()
    //   .trim()
    //   .required("Address is Required"),
    // city: yp
    //   .string()
    //   .trim()
    //   .required("City is Required"),
    // franchise_id: yp
    //   .string()

    //   .matches(/^[aA-zZ-Z0-9\s]+$/, "Only alphabets and Number are allowed ")
    //   .required("Franchise is Required"),
    // contact_owner_id: yp
    //   .string()
    //   .matches(/^[aA-zZ-Z0-9\s]+$/, "Only alphabets and Number are allowed ")
    //   .required("Contact Owner is Required"),
  });
};
export const CreateContactOnFraSchema = (intl, yp) => {
  return yp.object().shape({
    first_name: yp
      .string()
      .trim()
      .matches(/^[aA-zZ\s]+$/, intl.formatMessage({ id: "Only.alphabets" }))
      .required(intl.formatMessage({ id: "First.Name.Required" })),
    // last_name: yp
    //   .string()
    //   .trim()
    //   .matches(/^[aA-zZ\s]+$/, intl.formatMessage({ id: "Only.alphabets" }))
    //   .required(intl.formatMessage({ id: "Last.Name.Required" })),
    email: yp
      .array()
      .of(
        yp
          .string()
          .trim()
          .email(intl.formatMessage({ id: "Wrong.email.format" }))
          .max(50, intl.formatMessage({ id: "Maximum.50.symbols" }))
      )
      .required(intl.formatMessage({ id: "Email.Required" })),
    phone: yp
      .array()
      .of(
        yp
          .string()
          // .matches(
          //   phoneRegExp,
          //   intl.formatMessage({ id: "Country.Code.valid" })
          // )
          // .trim()
          // .min(12, intl.formatMessage({ id: "Country.Code.valid" }))
          .min(10, "Invalid Phone Number")
      )
      .required(intl.formatMessage({ id: "Phone.Number.Required" })),
    postcode: yp
      .string()
      .trim()
      .matches(/^[aA-zZ-Z0-9\s]+$/, "Only alphabets and Number are allowed ")
      .required("Post Code is Required"),
    // address: yp
    //   .string()
    //   .trim()
    //   .required("Address is Required"),
    // city: yp
    //   .string()
    //   .trim()
    //   .required("City is Required"),
    franchise_id: yp
      .string()

      .matches(/^[aA-zZ-Z0-9\s]+$/, "Only alphabets and Number are allowed ")
      .required("Franchise is Required"),
    // contact_owner_id: yp
    //   .string()
    //   .matches(/^[aA-zZ-Z0-9\s]+$/, "Only alphabets and Number are allowed ")
    //   .required("Contact Owner is Required"),
  });
};
// export const CreateOnContactSchema = (intl, yp) => {
//   // Validation schema for the "email" field
//   const emailSchema = yp
//     .string()
//     .trim()
//     .email(intl.formatMessage({ id: "Wrong.email.format" }))
//     .max(50, intl.formatMessage({ id: "Maximum.50.symbols" }))
//     .nullable()
//     .test("email-or-phone", "Either email or phone is required", function(
//       value
//     ) {
//       const { phone } = this.parent;
//       return (value && value.length > 0) || (phone && phone.length > 0);
//     });

//   // Validation schema for the "phone" field
//   const phoneSchema = yp
//     .string()
//     .min(10, "Invalid Phone Number")
//     .test("email-or-phone", "Either email or phone is required", function(
//       value
//     ) {
//       const { email } = this.parent;
//       return (value && value.length > 0) || (email && email.length > 0);
//     });

//   return yp.object().shape({
//     email: emailSchema,
//     phone: yp.string().when("email", {
//       is: (email) => !email || email.length === 0,
//       then: phoneSchema,
//       else: yp.string().nullable(),
//     }),
//   });
// };
export const CreateOnContactSchema = (intl, yp) => {
  return yp.object().shape({
    email: yp
      .array()

      .of(
        yp
          .string()
          .trim()
          .email(intl.formatMessage({ id: "Wrong.email.format" }))
          .max(50, intl.formatMessage({ id: "Maximum.50.symbols" }))
          .nullable()
      )
      .test("email-or-phone", "Either email or phone is required", function(
        value
      ) {
        const { phone } = this.parent;
        return (value && value.length > 0) || (phone && phone.length > 0);
      }),

    phone: yp
      .array()
      .of(yp.string().min(10, "Invalid Phone Number"))
      .test("email-or-phone", "Either email or phone is required", function(
        value
      ) {
        const { email } = this.parent;
        return (value && value.length > 0) || (email && email.length > 0);
      }),
  });
};
export const UpdateContactSchema = (intl, Yup) => {
  return Yup.object().shape({
    first_name: Yup.string()
      .max(100, "Maximum 100 Characters")
      .matches(/^[aA-zZ\s]+$/, intl.formatMessage({ id: "Only.alphabets" }))
      .trim()
      .required(intl.formatMessage({ id: "First.Name.Required" })),
    // last_name: Yup.string()
    //   .max(25, "Maximum 25 Characters")
    //   .matches(/^[aA-zZ\s]+$/, intl.formatMessage({ id: "Only.alphabets" }))
    //   .trim()
    //   .required(intl.formatMessage({ id: "Last.Name.Required" })),
    email: Yup.array()
      .of(
        Yup.string()
          .trim()
          .email(intl.formatMessage({ id: "Wrong.email.format" }))
          .max(50, intl.formatMessage({ id: "Maximum.50.symbols" }))
          .nullable()
      )
      .required(intl.formatMessage({ id: "Email.Required" })),
    phone: Yup.array()
      .of(
        Yup.string()
          // .matches(
          //   phoneRegExp,
          //   intl.formatMessage({ id: "Country.Code.valid" })
          // )
          // .trim()
          // .nullable()
          .min(10, "Invalid Phone Number")
      )
      .required(intl.formatMessage({ id: "Phone.Number.Required" })),
    postcode: Yup.string()
      .matches(
        /^[aA-zZ-Z0-9\s]+$/,
        intl.formatMessage({ id: "Only.alphabets.Number" })
      )
      .trim()
      .required(intl.formatMessage({ id: "Post.Code.Required" })),
    // address: Yup.string()
    //   .trim()
    //   .required(intl.formatMessage({ id: "Address.Required" })),
    // city: Yup.string()
    //   .trim()
    //   .required(intl.formatMessage({ id: "City.Required" })),
  });
};
export const CreateDealSchema = (intl, Yup) => {
  return Yup.object().shape({
    fname: Yup.string().required(
      intl.formatMessage({ id: "First.Name.Required" })
    ),
    lname: Yup.string().required(
      intl.formatMessage({ id: "Last.Name.Required" })
    ),
    email: Yup.string()
      .email(intl.formatMessage({ id: "Wrong.email.format" }))
      .min(3, intl.formatMessage({ id: "Minimum.3.symbols" }))
      .max(50, intl.formatMessage({ id: "Maximum.50.symbols" }))
      .required(intl.formatMessage({ id: "Email.Required" })),
    phone: Yup.string()
      .matches(phoneRegExp, intl.formatMessage({ id: "Phone.Number.Required" }))
      .required(intl.formatMessage({ id: "Phone.Number.Required" })),
  });
};
export const notesSchema = (intl, Yup) => {
  return Yup.object().shape({
    note: Yup.string().required(intl.formatMessage({ id: "Notes.Required" })),
  });
};
export const tasksSchema = (intl, Yup) => {
  return Yup.object().shape({
    task_type: Yup.string().required(
      intl.formatMessage({ id: "Task.Type.Required" })
    ),
    task_name: Yup.string().required(
      intl.formatMessage({ id: "Task.Name.Required" })
    ),
    due_date: Yup.string().required(
      intl.formatMessage({ id: "Due.Date.Required" })
    ),
    task_owner_id: Yup.string().required(
      intl.formatMessage({ id: "Task.Owner.Required" })
    ),
    task_description: Yup.string().required(
      intl.formatMessage({ id: "Description.Required" })
    ),
    priority: Yup.string().required(
      intl.formatMessage({ id: "Priority.required" })
    ),
    time: Yup.string().required(intl.formatMessage({ id: "Time.required" })),
  });
};
export const smsSchema = (intl, Yup) => {
  return Yup.object().shape({
    sms_to: Yup.string().required(
      intl.formatMessage({ id: "This.Field.Required" })
    ),
    description: Yup.string().required(
      intl.formatMessage({ id: "This.Field.Required" })
    ),
  });
};

export const AssignContactSchema = (intl, Yup) => {
  return Yup.object().shape({
    assined_user_agent_id: Yup.string().required(
      intl.formatMessage({ id: "Owner.Required" })
    ),
  });
};
export const AssignDealSchema = (intl, Yup) => {
  return Yup.object().shape({
    contact_owner_id: Yup.string().required(
      intl.formatMessage({ id: "Agent.Required" })
    ),
  });
};

export const sendEmail = (intl, Yup) => {
  return Yup.object().shape({
    email_to: Yup.string().required(
      intl.formatMessage({ id: "Email.Required" })
    ),
    subject: Yup.string().required(
      intl.formatMessage({ id: "Subject is Required" })
    ),
    description: Yup.string().required(
      intl.formatMessage({ id: "Description.Required" })
    ),
  });
};
