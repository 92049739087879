import React from "react";
import { Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import { canvaCancel } from "../../../../app/utils/canvaCloseFunction";

const DiscardDialog = ({
  setDialogDis,
  dialogDis,
  closeId,
  formik,
  setActive,
  setCanva,
  setCheck,
  type,
}) => {
  const intl = useIntl();

  const handleClose = () => {
    if (type === "order") {
      setDialogDis({ ...dialogDis, discard: false });

      return;
    }
    setDialogDis(false);
  };
  const handleSuccess = () => {
    if (type === "order") {
      setDialogDis({ ...dialogDis, discard: false, returnWizard: false });
      setActive(false);
      return;
    }
    setDialogDis(false);

    if (closeId) {
      canvaCancel(closeId);
    }
    if (setCanva) {
      setCanva({ type: false });
    }
    if (setActive) {
      setActive(false);
    }
    if (setCheck) {
      setCheck(true);
    }
    // if(type ===)
    formik.resetForm();
  };

  return (
    <Modal
      show={type === "order" ? dialogDis?.discard : dialogDis}
      //   onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {/* {isLoading && <ModalProgressBar variant="query" />} */}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Close Window
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* {!isLoading && ( */}
        <span>Do you want to discard changes ?</span>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="reset"
            onClick={() => handleClose()}
            className="btn btn-light btn-elevate"
          >
            {intl.formatMessage({ id: "No" })}
          </button>
          <> </>
          <button
            type="submit"
            onClick={() => handleSuccess()}
            className="btn btn-delete btn-elevate"
          >
            {intl.formatMessage({ id: "Yes" })}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default DiscardDialog;
