/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useIntl } from "react-intl";
// import { useDispatch } from "react-redux";
import SnackBarTool from "../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import ManualDiscount from "../../order-canva/ManualDiscount";
// import { fetchOrderSummary } from "../../_redux/order/orderAction";
import { findAdHoc } from "../../_redux/order/orderCrud";

export const SummaryButtons = ({ orderUIContext, orderDetail }) => {
  // const dispatch = useDispatch();
  const intl = useIntl();
  const [formData, setFormData] = useState({ offerCode: "", adhocPrice: "" });
  const [openCanva, setOpenCanva] = useState(false);
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  //apply code functionality
  // const handleApplyOffer = (value) => {
  //   setSnack({ call: false, type: "", message: "" });

  //   findOfferCode(value, orderDetail?.franchise_id)
  //     .then((res) => {
  //       const arr = [...orderUIContext?.state?.coupon];

  //       const val = res.data.data[0].coupon;
  //       const check =
  //         val?.item_constraints?.some(
  //           (e) => e.item_type === "plan" && e.constraint === "all"
  //         ) || false;
  //       if (res.data.data[0].coupon.discount_type === "percentage") {
  //         if (val?.apply_on === "each_specified_item" && check) {
  //           arr.push({
  //             type: "percent",
  //             id: res.data.data[0].coupon.id,
  //             value: res.data.data[0].coupon.discount_percentage,
  //             name: value,
  //             check: "billing",
  //           });
  //         } else {
  //           arr.push({
  //             type: "percent",
  //             id: res.data.data[0].coupon.id,
  //             value: res.data.data[0].coupon.discount_percentage,
  //             name: value,
  //           });
  //         }
  //       } else {
  //         if (val?.apply_on === "each_specified_item" && check) {
  //           arr.push({
  //             type: "fixed",
  //             id: res.data.data[0].coupon.id,
  //             value: res.data.data[0].coupon.discount_amount,
  //             name: value,
  //             check: "billing",
  //           });
  //         } else {
  //           arr.push({
  //             type: "fixed",
  //             id: res.data.data[0].coupon.id,
  //             value: res.data.data[0].coupon.discount_amount,
  //             name: value,
  //           });
  //         }
  //       }
  //       orderUIContext.dispatchReducer({
  //         type: "SET_DATA",
  //         data: { ...orderUIContext.state, coupon: arr },
  //       });
  //       setFormData({ offerCode: "" });
  //       setSnack({
  //         call: true,
  //         type: "success",
  //         message: res.data.message,
  //       });
  //     })
  //     .catch((error) => {
  //       setSnack({
  //         call: true,
  //         type: "error",
  //         message: error?.response?.data?.message,
  //         error: error?.response?.data?.errors,
  //       });
  //     });
  // };
  //manual discount apply code functionality
  const handleAddOfferManual = (values) => {
    const arr = [...orderUIContext?.state?.coupon];
    const data = {
      id: "manual",
      name: "manual",
      type: values?.discountType || "",
      value: values?.enterDiscount || "",
    };
    arr.push(data);
    orderUIContext.dispatchReducer({
      type: "SET_DATA",
      data: { ...orderUIContext.state, manualDiscount: values, coupon: arr },
    });
  };

  //add hoc add functionality
  const handleAdHoc = () => {
    setSnack({ call: false, type: "", message: "" });
    const ids = [...orderUIContext.state?.products_id];
    findAdHoc(orderDetail?.franchise_id)
      .then((res) => {
        if (res.data.data?.id) {
          const arr = [...orderUIContext?.state?.productArrayList];
          const index = arr.findIndex((e) => e.id === res.data.data?.id);
          if (index > 0) {
            arr[index].price = formData?.adhocPrice || "";
            arr[index].total =
              Number(arr[index]?.quantity) * Number(formData?.adhocPrice || "");
          } else {
            let data = {
              id: res.data.data?.id || "",
              name: res.data.data?.name || "",
              quantity: "1",
              price: formData?.adhocPrice || "",
              total: formData?.adhocPrice || "",
            };
            arr.push(data);
            ids.push(res.data.data?.id || "");
          }

          orderUIContext.dispatchReducer({
            type: "SET_DATA",
            data: {
              ...orderUIContext.state,
              adhoc: res.data.data,
              adhocCharge: formData?.adhocPrice || "",
              // productArrayList: arr,
              adhocChargeName: formData?.adhocChargeName || "",
              products_id: ids,
            },
          });
        } else {
          setSnack({
            call: true,
            type: "error",
            message: "Ad Hoc Charge not Found for this Franchise",
            // error: error?.response?.data?.errors,
          });
        }
      })
      .catch((err) =>
        setSnack({
          call: true,
          type: "error",
          message: err?.response?.data?.message,
          // error: error?.response?.data?.errors,
        })
      );
  };
  return (
    <>
      <div className="summeryromtbut">
        <div className="adhoc-charge mt-2 row">
          <div className="form-inline col-12">
            <input
              type="text"
              // pattern="[0-9]{5}"
              // maxLength={"3"}
              className={`form-control  mt-2`}
              placeholder="Adhoc Charge"
              value={formData?.adhocChargeName}
              onChange={(e) => {
                setFormData({ ...formData, adhocChargeName: e.target.value });
                // orderUIContext.dispatchReducer({
                //   type: "adhocChargeName",
                //   value: e.target.value,
                // });
              }}
            />
            {/* </div> */}
            {/* <div className="form-inline"> */}
            <input
              type="text"
              pattern="[0-9]{5}"
              maxLength={"5"}
              className={`form-control addhoc mt-2  `}
              placeholder="£ 0.00"
              value={formData?.adhocPrice}
              onChange={(e) => {
                if (e.target.value === "+" && e.target.value === "0") return;
                setFormData({ ...formData, adhocPrice: e.target.value });
              }}
            />
            {/* </div>
          <div className="form-inline" style={{ width: "100%" }}> */}
            <button
              type="submit"
              style={{ width: "27%" }}
              className="btn btn-primary font-weight-bolder font-size-sm mr-3 mt-2 ml-2 "
              onClick={handleAdHoc}
              disabled={
                formData.adhocPrice && Number(formData.adhocPrice) > 0
                  ? false
                  : true
              }
            >
              {intl.formatMessage({ id: "Add.Charge" })}
            </button>
          </div>
          {/* <div className="mt-2 col-12 d-flex">
            <div className="form-inline">
              {" "}
              <input
                type="text"
                className={`form-control offerCode mr-1 
               
                `}
                placeholder={intl.formatMessage({ id: "Offer.Code" })}
                name="offerCode"
                value={formData?.offerCode || ""}
                maxLength="40"
                onChange={(e) =>
                  setFormData({ ...formData, offerCode: e.target.value })
                }
              />
              {orderUIContext?.state?.coupon?.some(
                (e) => e.name === formData?.offerCode
              ) && (
                <span style={{ color: "red" }}>
                  {intl.formatMessage({ id: "Already.used" })}
                </span>
              )}
            </div>

            <div className="ml-3">
              <button
                type="button"
                onClick={() => handleApplyOffer(formData?.offerCode || "")}
                className="btn btn-primary font-weight-bolder font-size-sm mr-3 sapply-code "
                disabled={
                  formData?.offerCode === "" ||
                  orderUIContext?.state?.coupon?.some(
                    (e) => e.name === formData?.offerCode
                  )
                }
              >
                {intl.formatMessage({ id: "Apply.Code" })}
              </button>
            </div>
          </div> */}
          {/* </div> */}
        </div>
      </div>
      {/* <div className="">
        <button
          type="submit"
          className="btn btn-primary font-weight-bolder font-size-sm  mt-2 mr-2 "
          id="kt_Manual_Discount_panel_toggle"
          onClick={() => setOpenCanva(true)}
          style={{ width: "132px" }}
        >
          {orderUIContext?.state?.manualDiscount?.discountType
            ? "Edit Discount"
            : intl.formatMessage({ id: "Add.Discount" })}
        </button>
      </div> */}
      {openCanva ? (
        <>
          <ManualDiscount
            setOpenCanva={setOpenCanva}
            state={orderUIContext?.state}
            dispatchReducer={orderUIContext?.dispatchReducer}
            type={"Order"}
            handleAddOfferManual={handleAddOfferManual}
          />
          <div
            className="offcanvas-overlay"
            onClick={() => setOpenCanva(false)}
          ></div>
        </>
      ) : (
        ""
      )}
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </>
  );
};
