// export const planValues = {
//   type: "plan",
//   external_name: "",
//   name: "",
//   country: "",
//   description: "",
//   enabled_in_portal: true,
//   enabled_for_checkout: true,
//   id: "",
//   // product_count: "",
// };

import { currency } from "../../../__mocks__/dummyData";

// export const chargeValues = {
//   type: "charge",
//   external_name: "",
//   name: "",
//   description: "",
//   id: "",
//   enabled_in_portal: true,
//   pricing_model: "",
//   price: 0,
//   price_description: "",
//   show_description_in_invoices: "",
//   show_description_in_quotes: "",
//   invoice_notes: "",
//   is_taxable: true,
//   country: "",
//   category_type: "",
// };

// export const addonValues = {
//   type: "addon",
//   external_name: "",
//   name: "",
//   country: "",
//   description: "",
//   id: "",
//   unit: "",
//   enabled_in_portal: true,
//   currency_code: "",
// };

export const ProductStripeValues = (editvalues, sectorId) => {
  const isSectorPrice =
    editvalues &&
    editvalues.product_prices.length &&
    editvalues.product_prices.filter((e) => {
      return e.sector_id === sectorId;
    });

  const tieredPrice =
    editvalues &&
    editvalues.product_prices.length > 2 &&
    editvalues.product_prices.filter((e) => {
      return e.is_sector === 0;
    });
  return {
    id:
      editvalues && editvalues.stripe_product_id
        ? editvalues.stripe_product_id
        : "",
    productName: editvalues && editvalues.name ? editvalues.name : "",
    description:
      editvalues && editvalues.description ? editvalues.description : "",
    productType: editvalues && editvalues.type ? editvalues.type : "",
    productPlan:
      editvalues && editvalues.product_plan ? editvalues.product_plan : "",
    franchiseCheck:
      editvalues && editvalues.for_franchise
        ? editvalues.for_franchise === 1
          ? true
          : false
        : false,
    is_packaging_material:
      editvalues && editvalues.category_type && editvalues.category_type !== ""
        ? true
        : false,
    packaging_category:
      editvalues && editvalues.category_type ? editvalues.category_type : "",
    priceType:
      editvalues && editvalues.recurring
        ? editvalues.recurring === 1
          ? "recurring"
          : "one_time"
        : "",
    currency: "GBP",
    amount:
      editvalues &&
      editvalues.product_prices &&
      editvalues.product_prices.length === 1
        ? editvalues.product_prices[0].unit_amount
        : "",
    tieredPrice: sectorId
      ? isSectorPrice && isSectorPrice.length > 1
        ? [
            isSectorPrice[0]?.unit_amount,
            isSectorPrice[1]?.unit_amount,
            isSectorPrice[2]?.unit_amount,
            isSectorPrice[3]?.unit_amount,
            isSectorPrice[4]?.unit_amount,
            isSectorPrice[5]?.unit_amount,
            isSectorPrice[6]?.unit_amount,
          ]
        : ["", "", "", "", "", "", ""]
      : tieredPrice
      ? [
          tieredPrice[0]?.unit_amount,
          tieredPrice[1]?.unit_amount,
          tieredPrice[2]?.unit_amount,
          tieredPrice[3]?.unit_amount,
          tieredPrice[4]?.unit_amount,
          tieredPrice[5]?.unit_amount,
          tieredPrice[6]?.unit_amount,
        ]
      : ["", "", "", "", "", "", ""],
    // billingPeriod: "",
    // interval_count: "",
    pricingModel: sectorId
      ? "tiered"
      : editvalues && typeof editvalues === "object"
      ? editvalues.category_type && editvalues.category_type !== ""
        ? "per_unit"
        : editvalues.product_prices &&
          Array.isArray(editvalues.product_prices) &&
          editvalues.product_prices.length
        ? editvalues.product_prices.length > 2
          ? "tiered"
          : "per_unit"
        : ""
      : "",
  };
};
