/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React from "react";

export function CustomerNotes() {
  return (
    <div
      id="kt_customernotes_panel"
      className="offcanvas offcanvas-right pt-5 pb-10"
    >
      {/*begin::Header*/}
      <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
        <div
          className="offcanvas-close mt-n1 pr-5"
          style={{ position: "absolute", top: "15px", right: "10px" }}
        >
          <a
            href="#"
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            id="kt_customernotes_panel_close"
          >
            <i className="ki ki-close icon-sm text-muted"></i>
          </a>
        </div>
      </div>
      {/*end::Header*/}
      <div className="offcanvas-content px-10 ">
        <div>
          <h5>Notes</h5>
        </div>
        <div id="kt_quick_panel_logs_kt_customernotes_panel" className="scroll">
          <div className="mt-10">
            <div className="card ml-1">
              <div className="card-body">
                Sed tempus lorem urna, ac efficitur odio elementum id.
                Suspendisse pharetra, turpis eget commodo sodales, elit nibh
                accumsan justo, at eleifend est velit sit amet velit. Sed a
                dictum enim. Etiam luctus vel libero et sollicitudin. Aenean non
                nibh gravida, lacinia tortor vel, sagittis lorem. Maecenas
                lacinia augue ac rutrum rutrum.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="offcanvas-footer">
        <div className="offcanvas-content text-right ">
          <button className="btn btn-dark font-weight-bolder font-size-sm mr-3 ">
            Cancel
          </button>
          <button className="btn btn-primary font-weight-bolder font-size-sm ">
            Save
          </button>
        </div>
      </div>
    </div>
  );
}
