import { Card, CardBody } from "../../../_metronic/_partials/controls";
import React, { useState } from "react";

import { CustomerFilter } from "./Filter/Customer";
import CustomerTable from "./Table/CustomerTable";
import DeleteDialog from "../../../_metronic/_partials/widgets/alert/DeleteDialog";
import NewBreadCrumbs from "../../../_metronic/layout/components/subheader/components/NewBreadCrumbs";
import SnackBarTool from "../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { deleteCustomer } from "./_redux/customer/customerCrud";
import { fetchCustomerList } from "./_redux/customer/customerAction";
import { useCustomerUIContext } from "./CustomerUIContext";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useIntl } from "react-intl";
import NotAuthorized from "../../../_metronic/_partials/widgets/NotAuthorized";

export const CustomerPage = () => {
  // UI context for customer page
  const customerUIContext = useCustomerUIContext();
  const intl = useIntl();
  // Redux dispatch
  const dispatch = useDispatch();
  const { permission } = useSelector(
    (state) => ({
      permission: state.auth.permission,
    }),
    shallowEqual
  );
  // State for delete dialog
  const [dialog, setDialog] = useState(false);
  // State for snackbar
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });

  // Handles deleting customer
  const handleDelete = () => {
    // Reset snackbar
    setSnack({ call: false, type: "", message: "" });
    // Send delete request to server with selected customer ids
    deleteCustomer({ user_id: customerUIContext.ids })
      .then((res) => {
        // Update snackbar with success message
        setSnack({ call: true, type: "success", message: res.data.message });
        // Refresh customer list with current query params
        dispatch(fetchCustomerList(customerUIContext.queryParams));
        // Close delete dialog
        setDialog(false);
        // Reset selected customer ids
        customerUIContext.setIds([]);
      })
      .catch((error) => {
        // Update snackbar with error message
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
        // Close delete dialog
        setDialog(false);
      });
  };

  return (
    <>
      {/* Page header */}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <NewBreadCrumbs breadcrumb={true} title="Customers" />
        <div className="text-right mb-5">
          {/* Delete button */}
          {permission?.customer?.delete ? (
            <button
              className="btn btn-primary mr-2"
              type="button"
              onClick={() => setDialog(true)}
              disabled={customerUIContext.ids.length === 0}
            >
              {intl.formatMessage({ id: "Delete" })}
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
      <Card>
        <CardBody>
          {permission?.customer?.view ? (
            <>
              <CustomerFilter />
              <CustomerTable />
            </>
          ) : (
            <NotAuthorized />
          )}
        </CardBody>
      </Card>
      <DeleteDialog
        dialog={dialog}
        setDialog={setDialog}
        handleDelete={handleDelete}
        title={"Customer"}
      />
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </>
  );
};
