import { FormControl, MenuItem, Select } from "@material-ui/core";
import React, { useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { Formik } from "formik";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { dealsFranchiseListing } from "../../ContactDeals/_redux/deals/dealsCrud";
import { fetchAgent } from "../../ContactDeals/_redux/contact/contactAction";
import { isEqual } from "lodash";
import { useCustomerUIContext } from "../CustomerUIContext";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import { useState } from "react";
import { CustomeSearch } from "../../../../_metronic/_partials/controls/CustomeSearch";

const prepareFilter = (queryParams, values) => {
  const { search_franchise, searchText, search_view, search_agent } = values;
  const newQueryParams = { ...queryParams };
  const filter = {};

  filter.search = searchText ? searchText : "";
  filter.search_view = search_view ? search_view : "";
  filter.search_agent = search_agent ? search_agent : "";
  filter.search_franchise = search_franchise ? search_franchise : "";
  newQueryParams.filter = filter;
  return newQueryParams;
};
export function CustomerFilter() {
  const intl = useIntl();

  const customerUIContext = useCustomerUIContext();

  const customerUIProps = useMemo(() => {
    return {
      setQueryParams: customerUIContext.setQueryParams,
      queryParams: customerUIContext.queryParams,
    };
  }, [customerUIContext]);

  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(customerUIProps.queryParams, values);

    if (!isEqual(newQueryParams, customerUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      customerUIProps.setQueryParams(newQueryParams);
    }
  };

  const dispatch = useDispatch();

  const [franchises, setFranchise] = useState([]);

  const { currentState } = useSelector(
    (state) => ({ currentState: state.contact.agent }),
    shallowEqual
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(fetchAgent());
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [dispatch]);

  const handleFranchiseList = () => {
    dealsFranchiseListing().then((res) => {
      setFranchise(res.data.data);
    });
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      handleFranchiseList();
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <>
      <Formik
        initialValues={{
          status: "", // values => All=""/Susspended=0/Active=1/Pending=2
          type: "", // values => All=""/Business=0/Individual=1
          searchText: "",
          search_view: "",
          search_agent: "",
          search_franchise: "",
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,

          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row d-flex justify-content-between">
              <div className="col-lg-8">
                <div className="row">
                  <div className="col-lg-4 ">
                    <FormControl fullWidth>
                      <Select
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                        className="form-control"
                        IconComponent={(props) => (
                          <i {...props}>
                            <KeyboardArrowDownIcon />
                          </i>
                        )}
                        inputProps={{ "aria-label": "Without label" }}
                        displayEmpty
                        onChange={(e) => {
                          setFieldValue("search_view", e.target.value);
                          handleSubmit();
                        }}
                        onBlur={handleBlur}
                        value={values.search_view}
                      >
                        <MenuItem value="">
                          {intl.formatMessage({ id: "View" })}
                        </MenuItem>
                        <MenuItem value={"all_customers"}>
                          {intl.formatMessage({ id: "All.Customers" })}
                        </MenuItem>
                        <MenuItem value={"my_customers"}>
                          {intl.formatMessage({ id: "My.Customers" })}
                        </MenuItem>
                        <MenuItem value={"new_last_week"}>
                          {intl.formatMessage({ id: "New.Last.Week" })}
                        </MenuItem>
                        <MenuItem value={"new_this_week"}>
                          {intl.formatMessage({ id: "New.This.Week" })}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-lg-4  order-flt  ">
                    <span className="mt-3">
                      {intl.formatMessage({ id: "Franchise" })}:&nbsp;{" "}
                    </span>
                    <FormControl fullWidth>
                      <Select
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                        className="form-control"
                        onChange={(e) => {
                          setFieldValue("search_franchise", e.target.value);

                          handleSubmit();
                        }}
                        onBlur={handleBlur}
                        value={values.search_franchise}
                        displayEmpty
                        IconComponent={(props) => (
                          <i {...props}>
                            <KeyboardArrowDownIcon />
                          </i>
                        )}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem value="">All franchises</MenuItem>
                        {franchises.map((data, index) => (
                          <MenuItem key={index} value={data.id}>
                            {data.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-lg-4 mb-2 order-flt ">
                    <span className="mt-3">
                      {intl.formatMessage({ id: "Agent" })}:&nbsp;{" "}
                    </span>
                    <FormControl fullWidth>
                      <Select
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                        className="form-control"
                        onChange={(e) => {
                          setFieldValue("search_agent", e.target.value);
                          handleSubmit();
                        }}
                        onBlur={handleBlur}
                        value={values.search_agent}
                        IconComponent={(props) => (
                          <i {...props}>
                            <KeyboardArrowDownIcon />
                          </i>
                        )}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem value="">
                          {intl.formatMessage({ id: "All" })}
                        </MenuItem>
                        {currentState &&
                          currentState.map((data, index) => (
                            <MenuItem key={index} value={data.id}>
                              {data.first_name +
                                " " +
                                (data.last_name ? data.last_name : "")}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
              <div className="col-lg-2">
                <CustomeSearch
                  intl={intl}
                  handleBlur={handleBlur}
                  values={values}
                  setFieldValue={setFieldValue}
                  handleSubmit={handleSubmit}
                />
                {/* <div style={{ position: "relative" }}>
                  <input
                    style={{ paddingRight: "40px" }}
                    type="text"
                    className="form-control"
                    name="searchText"
                    placeholder={intl.formatMessage({ id: "Search" })}
                    onBlur={handleBlur}
                    value={values.searchText}
                    onChange={(e) => {
                      setFieldValue("searchText", e.target.value);
                      handleSubmit();
                    }}
                  />
                  <i
                    style={{
                      position: "absolute",
                      top: "50%",
                      transform: "translateY(-50%)",
                      right: "15px",
                    }}
                    className="fa fa-search"
                    aria-hidden="true"
                  ></i>
                </div> */}
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
