import React, { useState } from "react";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { StripeBillingMode } from "./StripeBillingMode";
import { BillingPeriod } from "./BillingPeriodStripe";
import { StripeAmount } from "./StripeAmount";
import { PricingModalArr } from "../../../__mocks__/dummyData";
import { MultiFieldsStyle, infoBoxstyles } from "./stripeFormStyle";
import { Link } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import { CheckBox, Close } from "@material-ui/icons";
import { useIntl } from "react-intl";
import TieredPriceAmount from "./TieredPriceAmount";

const ProductFormStripe = ({
  formik,
  active,
  setDialog,
  type,
  editValue,
  canva,
}) => {
  const intl = useIntl();
  const { values, touched, handleChange, errors, setFieldValue } = formik;

  const getFieldError = (fieldName) => {
    return {
      errClass: errors[fieldName] && touched[fieldName] ? "is-invalid" : "",
      message: errors[fieldName] && touched[fieldName] ? errors[fieldName] : "",
    };
  };

  //   const handleMoreDetails = () => {
  //     setShowMoreDetails(!showMoreDetails);
  //   };

  // const addTieredPriceField = () => {
  //   formik.setFieldValue("tieredPrice", [
  //     ...formik.values.tieredPrice,
  //     { key: "", value: "" },
  //   ]);
  // };
  // const removeTieredPriceField = (index) => {
  //   const updatedTieredPrice = formik.values.tieredPrice.filter(
  //     (_, i) => i !== index
  //   );
  //   formik.setFieldValue("tieredPrice", updatedTieredPrice);
  // };

  return (
    <div className="row">
      {type === "priceEdit" ? null : (
        <>
          <div className="col-sm-6 ">
            <div className="form-groupB">
              <label>Product Id</label>
              <input
                value={values.id}
                type="text"
                onChange={handleChange}
                disabled={editValue ? true : false}
                placeholder="Product Id"
                className={`form-control   ${getFieldError("id").errClass}`}
                name="id"
              />
              {getFieldError("id").message && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {getFieldError("id").message}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col-sm-6 ">
            <div className="form-groupB">
              <label>Product Name</label>
              <input
                value={values.productName}
                type="text"
                onChange={handleChange}
                placeholder="Product Name"
                className={`form-control   ${
                  getFieldError("productName").errClass
                }`}
                name="productName"
              />
              {getFieldError("productName").message && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {getFieldError("productName").message}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-groupB">
              <label>Description</label>
              <textarea
                type="text"
                value={values.description}
                placeholder="Description"
                className={`form-control   ${
                  getFieldError("description").errClass
                }`}
                onChange={handleChange}
                name="description"
              />
              {getFieldError("productName").message && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {getFieldError("productName").message}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col-lg-3">
            <FormControl fullWidth>
              <label>Product Type</label>
              <Select
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                IconComponent={(props) => (
                  <i {...props}>
                    <KeyboardArrowDownIcon />
                  </i>
                )}
                disabled={
                  type === "productEdit"
                    ? true
                    : values.is_packaging_material === true
                    ? true
                    : false
                }
                inputProps={{ "aria-label": "Without label" }}
                displayEmpty
                name="productType"
                className={`form-control w-100  ${
                  getFieldError("productType").errClass
                }`}
                {...formik.getFieldProps("productType")}
                onChange={(e) => {
                  setFieldValue("productType", e.target.value);
                  if (e.target.value === "charge") {
                    setFieldValue("priceType", "one_time");
                    setFieldValue("pricingModel", "per_unit");
                    setFieldValue("tieredPrice", ["", "", "", "", "", "", ""]);
                  } else {
                    setFieldValue("priceType", "recurring");
                  }
                }}
              >
                <MenuItem value="">
                  {intl.formatMessage({ id: "Select" })}
                </MenuItem>
                <MenuItem value="charge">Charge</MenuItem>
                <MenuItem value="addon">Add-On</MenuItem>
                <MenuItem value="plan">Plan</MenuItem>
              </Select>
            </FormControl>
            {getFieldError("productType").message && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  {getFieldError("productType").message}
                </div>
              </div>
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "baseline",
              gap: "30px",
            }}
          >
            {!values.is_packaging_material && (
              <div>
                <div className="form-groupB" style={{ marginTop: "-7px" }}>
                  <label className="col-form-label">Use for Franchise</label>
                  <div className="text-right mt-1">
                    <span className="switch switch-outline switch-icon switch-primary">
                      <label>
                        <input
                          type="checkbox"
                          disabled={type === "productEdit" ? true : false}
                          checked={values.franchiseCheck}
                          name="franchiseCheck"
                          onChange={(e) => {
                            setFieldValue("franchiseCheck", e.target.checked);
                            if (e.target.checked) {
                              setFieldValue("priceType", "");
                              setFieldValue("amount", "");
                              setFieldValue("billingPeriod", "");
                              setFieldValue("interval_count", "");
                              setFieldValue("currency", "");
                              setFieldValue("pricingModel", "");
                              setFieldValue("tieredPrice", [
                                "",
                                "",
                                "",
                                "",
                                "",
                                "",
                                "",
                              ]);
                            }
                          }}
                        />
                        <span></span>
                      </label>
                    </span>
                  </div>
                </div>
              </div>
            )}

            {!values.franchiseCheck && (
              <div>
                <div className="form-groupB" style={{ marginTop: "-7px" }}>
                  <label className="col-form-label">Packaging Material</label>
                  <div className="text-right mt-1">
                    <span className="switch switch-outline switch-icon switch-primary">
                      <label>
                        <input
                          type="checkbox"
                          disabled={type === "productEdit" ? true : false}
                          checked={values.is_packaging_material}
                          name="is_packaging_material"
                          onChange={(e) => {
                            setFieldValue(
                              "is_packaging_material",
                              e.target.checked
                            );
                            if (e.target.checked) {
                              setFieldValue("productType", "charge");
                              setFieldValue("pricingModel", "per_unit");
                            }
                          }}
                        />
                        <span></span>
                      </label>
                    </span>
                  </div>
                </div>
              </div>
            )}
            <div>
              <FormControl>
                <label>Product Plan</label>
                <Select
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                  IconComponent={(props) => (
                    <i {...props}>
                      <KeyboardArrowDownIcon />
                    </i>
                  )}
                  disabled={type === "productEdit" ? true : false}
                  inputProps={{ "aria-label": "Without label" }}
                  displayEmpty
                  name="productPlan"
                  className={`form-control w-100  ${
                    getFieldError("productPlan").errClass
                  }`}
                  {...formik.getFieldProps("productPlan")}
                  onChange={(e) => {
                    setFieldValue("productPlan", e.target.value);
                  }}
                >
                  <MenuItem value="">
                    {intl.formatMessage({ id: "Select" })}
                  </MenuItem>
                  <MenuItem value="pod">Pod</MenuItem>
                  <MenuItem value="container">Container</MenuItem>
                </Select>
              </FormControl>
              {getFieldError("productPlan").message && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {getFieldError("productPlan").message}
                  </div>
                </div>
              )}
            </div>
          </div>
          {values.is_packaging_material && (
            <div className="col-lg-12">
              <FormControl fullWidth>
                <label>Packaging category type</label>
                <Select
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                  IconComponent={(props) => (
                    <i {...props}>
                      <KeyboardArrowDownIcon />
                    </i>
                  )}
                  inputProps={{ "aria-label": "Without label" }}
                  displayEmpty
                  // disabled={
                  //   type === "priceEdit" && editValue.product_prices?.length > 0
                  // }
                  name="packaging_category"
                  className={`form-control w-100  ${
                    getFieldError("packaging_category").errClass
                  }`}
                  {...formik.getFieldProps("packaging_category")}
                  onChange={(e) => {
                    setFieldValue("packaging_category", e.target.value);
                  }}
                >
                  <MenuItem value="">
                    {intl.formatMessage({ id: "Select" })}
                  </MenuItem>
                  <MenuItem value="Boxes">Boxes</MenuItem>
                  <MenuItem value="Paper, Blankets & Bubbles">
                    Paper, Blankets & Bubbles
                  </MenuItem>
                  <MenuItem value="Tape, Pens & Misc">
                    Tape, Pens & Misc
                  </MenuItem>
                  <MenuItem value="Multi Packs">Multi Packs</MenuItem>
                  <MenuItem value="Other Packaging">Other Packaging</MenuItem>
                </Select>
              </FormControl>
              {getFieldError("packaging_category").message && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {getFieldError("packaging_category").message}
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      )}

      {/* <div className="col-lg-3">
                <label>Product Type</label>
                <input
                type="text"
                readOnly
                className='form-control'
                value="One-Off"
                ></input>
                  </div> */}
      {/* <div className="col-sm-12 my-2">
                     <div style={{ color: 'purple', fontWeight: 'bold' }} onClick={handleMoreDetails}>
                        <span>More Details</span>
                        <span className="svg-icon svg-icon-lg svg-icon-primary">
                            {showMoreDetails ? (
                                <KeyboardArrowUpIcon style={{ cursor: "pointer" }} />
                            ) : (
                                <KeyboardArrowDownIcon style={{ cursor: "pointer" }} />
                            )}
                        </span>
                    </div>
             </div>
              {showMoreDetails && (
                <>
                    <div className="col-sm-12 mt-3" >
                        <label><h3>Metadata</h3></label>
                        <p>Store additional, structured information.</p>
                        {formik.values.metadata.map((field, index) => (
                            <div className='row mt-1' style={{alignItems: "baseline"}}>
                                <div key={index} className="col-sm-5" style={MultiFieldsStyle.section}>
                                    <input
                                        name={`metadata[${index}].key`}
                                        className={`form-control `}
                                        value={field.key}
                                        placeholder="Key"
                                        onChange={e => {
                                            const newMetadata = [...formik.values.metadata];
                                            newMetadata[index].key = e.target.value;
                                            formik.setFieldValue('metadata', newMetadata);
                                        }}
                                    />
                                </div>
                                <div key={index} className="col-sm-5" style={MultiFieldsStyle.section}>
                                    <input
                                        name={`metadata[${index}].value`}
                                        value={field.value}
                                        placeholder="Value"
                                        className={`form-control `}
                                        onChange={e => {
                                            const newMetadata = [...formik.values.metadata];
                                            newMetadata[index].value = e.target.value;
                                            formik.setFieldValue('metadata', newMetadata);
                                        }}
                                    />
                                </div>
                                <div className='col-sm-1'> <CloseIcon style={{ cursor: "pointer" }}  onClick={() => removeMetadataField(index)} /></div>

                            </div>
                        ))}
                        <button className='btn btn-primary font-weight-bolder font-size-sm mt-2' type="button" onClick={addMetadataField}>
                            Add More
                        </button>
                    </div>
                </>
             )} */}
      <hr />
      {/* <div className="col-sm-12">
                <input id='franchiseCheck' checked={values.franchiseCheck} type='checkbox' onChange={(e)=> {
                    setFieldValue("franchiseCheck",e.target.checked)
                    if(values.franchiseCheck === true){
                        setFieldValue("priceType", ""); 
                        setFieldValue("amount", ""); 
                        setFieldValue("billingPeriod", "");
                        setFieldValue("interval_count", "");  
                        setFieldValue("currency", "");  
                        setFieldValue("pricingModel", "");  
                    }
                }}    />
                <label id="franchiseCheck">Franchise Check</label>
             </div> */}
      {values.franchiseCheck && type !== "priceEdit" ? (
        ""
      ) : (
        <>
          {/* <div className='col-sm-12'>
                <StripeBillingMode 
                formik={formik} />
             </div> */}
          <div className="col-lg-12">
            <FormControl fullWidth>
              <label>Pricing Model</label>
              <Select
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                IconComponent={(props) => (
                  <i {...props}>
                    <KeyboardArrowDownIcon />
                  </i>
                )}
                inputProps={{ "aria-label": "Without label" }}
                displayEmpty
                disabled={
                  type === "productEdit"
                    ? true
                    : type === "priceEdit" &&
                      editValue.product_prices.length > 0
                    ? true
                    : canva.data.sectorId
                    ? true
                    : values.is_packaging_material === true
                    ? true
                    : false
                }
                name="pricingModel"
                className={`form-control w-100  ${
                  getFieldError("pricingModel").errClass
                }`}
                {...formik.getFieldProps("pricingModel")}
                onChange={(e) => {
                  setFieldValue("pricingModel", e.target.value);
                }}
              >
                <MenuItem value="">
                  {intl.formatMessage({ id: "Select" })}
                </MenuItem>
                <MenuItem value="per_unit">
                  <strong> Flat Rate</strong> :- Offer a fixed price for a
                  single unit or package
                </MenuItem>
                <MenuItem value="tiered">
                  <strong> Tiered Pricing</strong> :- Offer different price
                  points based on unit quantity
                </MenuItem>
              </Select>
            </FormControl>
            {getFieldError("pricingModel").message && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  {getFieldError("pricingModel").message}
                </div>
              </div>
            )}
          </div>
          {values.pricingModel === "tiered" ? (
            <div className="col-lg-12 mt-2">
              <TieredPriceAmount
                formik={formik}
                getFieldError={getFieldError}
              />
            </div>
          ) : (
            <div className="col-sm-12 mt-2">
              <div>
                <StripeAmount formik={formik} getFieldError={getFieldError} />
              </div>
            </div>
          )}
          {/* {values.productType === "plan" || values.productType === "addon" ? (
            <div className="col-sm-12 mt-2">
              <BillingPeriod formik={formik} getFieldError={getFieldError} />
            </div>
          ) : (
            ""
          )} */}
        </>
      )}
    </div>
  );
};

export default ProductFormStripe;
