import React from "react"

export const StripeAmount = ({ formik, getFieldError }) => {

    return (<>
        <div className="form-groupB">
            <label>&pound;Amount(required)</label>
            <input
                type="text"
                placeholder="&pound; Amount"
                className={`form-control  ${getFieldError("amount").errClass}`}
                name="amount"
                value={formik.values.amount}
                onChange={formik.handleChange}
            />
               {getFieldError('amount').message && (
                        <div className="fv-plugins-message-container">
                        <div className="fv-help-block"> {getFieldError('amount').message} </div>
          </div>
        )}
        </div>
    </>)
}