/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { Modal } from "react-bootstrap";
import React, { useEffect, useMemo, useState } from "react";
import { useFormik } from "formik";
// import { useDispatch } from "react-redux";
import SnackBarTool from "../../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { LoadingCustome } from "../../../../../../_metronic/_partials/controls/LoadingCustome";
import { getInputClasses } from "../../../../../utils/commonFunction";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import { FormControl, MenuItem, Select } from "@material-ui/core";
import { chargebee } from "../../../_mocks/dummyData";
import { getBreakDownData, getProduct, getScheduleChanges, updatePlan } from "../../../_redux/order/orderCrud";
import { getPruductObject } from "../../../EditOrderUtils";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import DoneIcon from "@material-ui/icons/Done";
import { useIntl } from "react-intl";
import moment from "moment";
import { fetchMyOrderDetailCard, fetchMyOrderTimeline, fetchPaymentInvoice } from "../../../_redux/order/orderAction";

const ConfirmationDialog = ({
  dialog,
  setDialog,
  formData,
  orderDetail,
  handleChange,
}) => {
  const intl = useIntl();
  const podPlanType = [{id:1, label:"Economy-POD", type:"Economy" },{id:2 , label:"POD", type:"Premium"},{id:3, label:"First-Class", type:"First Class"}]
  const podType = podPlanType.find((e)=>e.type ===orderDetail?.order_details?.pod_plan_type)?.id
  const newType = podPlanType.find((e)=>e.label === formData.planData?.product_id )?.id
  const nextDate = useMemo(() => {
    if (orderDetail && orderDetail.subscription_data) {
      // Create a new Date object with the next billing timestamp
      let date = new Date(orderDetail.subscription_data.next_billing_at * 1000); // Convert timestamp to milliseconds

      switch (orderDetail.subscription_data.billing_period_unit) {
        case 'month':
          date.setMonth(date.getMonth() + orderDetail.subscription_data.billing_period);
          break;
        case 'week':
          date.setDate(date.getDate() + (orderDetail.subscription_data.billing_period * 7));
          break;
        case 'year':
          date.setFullYear(date.getFullYear() + orderDetail.subscription_data.billing_period);
          break;
        default:
          break;
      }
      
      return moment(date).format("DD/MM/YYYY");
    }
    return '';
  }, [orderDetail]);
  return (
    <Modal show={dialog.modal} aria-labelledby="example-modal-sizes-title-lg">
      <Modal.Header closeButton>
      {podType>newType  && orderDetail.subscription_data.status=="active" && orderDetail.is_plan_downgrade_scheduled === 1 ?"":
        <Modal.Title id="example-modal-sizes-title-lg">{formData?.planData?.external_name}&nbsp;&pound;{formData?.planData?.amount/100}</Modal.Title>}
      </Modal.Header>
      <Modal.Body>
      <div>
        {podType>newType  && orderDetail.subscription_data.status=="active" && orderDetail.is_plan_downgrade_scheduled === 1 ?
        <span>
          Your change of subscription is already scheduled, please contact customer care on 0800 061 4091 if you wish to make further changes.
        </span>:
        <>
      {formData !== null && podType>newType  ? (
        <div>
          {/* <p style={{ fontWeight: 600 }}>{formData.planData?.external_name} £{formData.planData?.amount / 100}</p> */}
          <p>
            £{formData?.subData?.invoice_estimate?.amount_due !== undefined ? formData?.subData.invoice_estimate.amount_due / 100 : '00.0'} Amount will be deducted at {moment(formData?.subData?.next_invoice_estimate?.date * 1000).format("DD/MM/YYYY")}
          </p>
          {orderDetail.is_plan_upgrade_scheduled === 1 && podType>newType   ? (
            <p>
              The downgrade will only be applicable from {nextDate}.
            </p>
          ) : (
            <p>
              The downgrade will only be applicable from {moment(formData?.subData?.next_invoice_estimate?.date * 1000).format("DD/MM/YYYY")}.
            </p>
          )}
        </div>
      ) : (
        <div>
          <p>
            £{formData?.subData?.invoice_estimate?.amount_due !== undefined ? formData?.subData.invoice_estimate.amount_due / 100 : '00.0'} Amount will be deducted at this moment.
          </p>
          {orderDetail.subscription_data.status === 'active' && (
            <p>
              Next invoice of £{formData?.subData?.next_invoice_estimate?.total / 100} will be raised on {moment(formData?.subData?.next_invoice_estimate?.date * 1000).format("DD/MM/YYYY")}.
            </p>
          )}
        </div>
      )}
        </>}
      
    </div>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={() => setDialog({ modal: false })}
            className="btn btn-light btn-elevate"
          >
            Close
          </button>
          <> </>
          {podType>newType  && orderDetail.subscription_data.status=="active" && orderDetail.is_plan_downgrade_scheduled === 1 ?"":
          <button
            type="button"
            onClick={() => handleChange()}
            className="btn btn-delete btn-elevate"
            disabled={podType>newType  && orderDetail.subscription_data.status=="active" && orderDetail.is_plan_downgrade_scheduled === 1}
          >
            Update
          </button>
}
        </div>
      </Modal.Footer>
    </Modal>
  );
};

const ChangePlanModal = ({
  dialog,
  setDialog,
  orderUIContext,
  orderDetail,
  setScheduleData
}) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(null);
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [modal, setModal] = useState({modal:false})
  const initialValues = {
    plan: "",
  };
  const { editOrderDetail } = useSelector(
    (state) => ({
      editOrderDetail: state.order.editOrderDetail,
    }),
    shallowEqual
  );

  const handleSubmit = (values) => {};

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    // validationSchema: CompetitorModalSchema,
    onSubmit: handleSubmit,
  });

  const handleClose = () => {
    setDialog({ open: false });
  };
  const podPlanType = [{id:1, label:"Economy-POD", type:"Economy" },{id:2 , label:"POD", type:"Premium"},{id:3, label:"First-Class", type:"First Class"}]

  const handleGetProduct = (id) => {
    setIsLoading(true)
    const podType = podPlanType.find((e)=>e.type ===orderDetail?.order_details?.pod_plan_type)?.id
    const newType = podPlanType.find((e)=>e.label === id )?.id
    const data = {
      franchise_id: orderDetail?.franchise_id || "",
      item_id: id || "",
      period: orderDetail?.subscription_data?.billing_period,
      period_unit: orderDetail?.subscription_data?.billing_period_unit,
      order_id:orderDetail?.id
    };
    getProduct(data).then((res) => {
      const item =
        res.data.data && res.data.data.length > 0 && res.data.data[0];

      setFormData({ ...formData, planData: item });
    const productArray = orderDetail?.subscription_data?.subscription_items.filter((e)=>e?.item_type !== "plan")
      const updatedArray = productArray.map((e)=>{return{
        amount: +e?.amount/100,
              id: e.product_id,
              item_price_id:e.item_price_id,
              name:e?.external_name,
              price: +e?.amount/100,
              pricing_model: e.pricing_model,
              quantity: e?.quantity,
              type: e?.item_type,
      }})
      
      const podArray = res.data.data.map((e) => {
        const data = {
          amount: +e?.amount/100,
          id: e.product_id,
          item_price_id: e.item_price_id,
          name: e?.external_name,
          price: +e?.amount/100,
          pricing_model: e.pricing_model,
          quantity: orderDetail?.pod,
          type: e?.item_type,
        };
        return data;
      })
      const breakeDown = {
        apply_manual_discounts: [],
        coupon_id: orderDetail?.subscription_data?.coupons?.map((e) => {
          return e.coupon_id;
        }),

        // depend on plan
        end_of_term: podType>newType  ? true : false,
        invoicing_option: podType>newType? false : true,
        // depend on plan

        franchise_id: orderDetail?.franchise_id,
        items: [...podArray, ...updatedArray],
        prorate: true,
        status: orderDetail?.subscription_data?.status,
        subscription_billing_period:
          orderDetail?.subscription_data?.billing_period,
        subscription_billing_period_unit:
          orderDetail?.subscription_data?.billing_period_unit,
        subscription_id: orderDetail?.subscription_id,
      };
      setSnack({
        call: false,
        type: "",
        message: "",
      });
      getBreakDownData(breakeDown).then((response) => {
        setFormData({ ...formData, planData: item, subData:response.data.data.breakdown });
        setModal({modal:true})
        setIsLoading(false)
      }).catch((error)=>{
        setIsLoading(false)
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
    });
  };

  const handleUpdate =()=>{
    const podType = podPlanType.find((e)=>e.type ===orderDetail?.order_details?.pod_plan_type)?.id
    const newType = podPlanType.find((e)=>e.label === formData?.planData?.product_id )?.id
    const productArray = orderDetail?.subscription_data?.subscription_items.filter((e)=>e?.item_type !== "plan")
    const updatedArray = productArray.map((e)=>{return{
      amount: +e?.amount/100,
            id: e.product_id,
            item_price_id:e.item_price_id,
            name:e?.external_name,
            price: +e?.amount/100,
            pricing_model: e.pricing_model,
            quantity: e?.quantity,
            type: e?.item_type,
    }})
    setIsLoading(true)
    setSnack({ call: false, type: "", message: "" });
    const data = {
      franchise_id: orderDetail?.franchise_id || "",
      order_id: orderDetail.id,
      price_id: formData?.planData.item_price_id,
      quantity: orderDetail.pod,
      pod_plan_type: formData?.planData?.product_id === "Economy-POD" ? "Economy":formData?.planData?.product_id === "POD"?"Premium":formData?.planData?.product_id === "First-Class" ? "First Class":"",
      items: [{
        amount: +formData?.planData?.amount/100,
            id: formData?.planData.product_id,
            item_price_id:formData?.planData.item_price_id,
            name:formData?.planData?.external_name,
            price: +formData?.planData?.amount/100,
            pricing_model: formData?.planData.pricing_model,
            quantity: orderDetail?.pod,
            type: formData?.planData?.item_type,
      }, ...updatedArray],
      next_billing_at:moment(orderDetail.subscription_data.next_billing_at*1000).format("YYYY/MM/DD"),
      
      // depend on plan
      end_of_term: (orderDetail.subscription_data.status=="active" && podType>newType) ? true : false ,
      invoicing_option: (orderDetail.subscription_data.status=="active" && podType>newType) ? false : true,
      // depend on plan
      prorate : (orderDetail.subscription_data.status=="active" && podType>newType) ? false : true,
      status: orderDetail.subscription_data.status,
      is_downgrade:(orderDetail.subscription_data.status=="active" && podType>newType) ? 1 : 0,
      is_upgrade:(podType>newType) ? 0 : 1
      
   };
   updatePlan(data).then((res)=>{
    dispatch(fetchMyOrderDetailCard(orderDetail?.id));
    // dispatch(fetchOrderSummaryList(orderDetail?.id, ""));
    dispatch(fetchMyOrderTimeline(orderDetail?.id));
    dispatch(fetchPaymentInvoice(orderDetail?.id));
    setModal({modal:false})
    setSnack({ call: true, type: "success", message: res.data.message });
    setTimeout(() => {
      setDialog({open:false})
    }, 3000);
    setTimeout(() => {
      
      getScheduleChanges(
        orderDetail?.franchise_id,
        orderDetail?.subscription_id
      )
        .then((res) => setScheduleData(res.data.data))
        .catch((error) => setScheduleData(null));
    }, 5000);
    setIsLoading(false)
    
    
   }).catch((error) => {
    setIsLoading(false);
    setSnack({
      call: true,
      type: "error",
      message: error.response.data.message,
    });
  });
  }
  useEffect(() => {
    if (snack?.call) {
      const timeoutId = setTimeout(() => {
        setSnack({ call: false, type: "", message: "" });
      }, 3000);
  
      // Clear the timeout when the component unmounts or when `snack.call` changes
      return () => clearTimeout(timeoutId);
    }
  }, [snack?.call]);
  return (
    <Modal
      show={dialog?.open}
      aria-labelledby="example-modal-sizes-title-lg"
      // size="lg"
      // dialogClassName="my-modal60"
      contentClassName="modal-height"
      onHide={handleClose}
    >
      <Modal.Header className="d-flex justify-content-between">
        <Modal.Title id="example-modal-sizes-title-lg">
          Change Plan
        </Modal.Title>
        <span
          className="btn btn-xs btn-icon btn-light btn-hover-primary"
          onClick={handleClose}
        >
          <i className="ki ki-close icon-sm text-muted"></i>
        </span>
      </Modal.Header>
      <form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          {isLoading && <LoadingCustome />}
          <div className="row" style={{ justifyContent: "left" , paddingLeft:"3%"}}>
            
            
            {orderDetail?.order_details?.pod_plan_type !== "Economy" && (
              <>
              <div
                style={{
                  width: "92%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <h5>Economy</h5>
                  <p style={{ color: "green" }}>
                    <DoneIcon />
                    Secure storage unit 70 sq ft.
                  </p>
                  <p style={{ color: "green" }}>
                    <DoneIcon />
                    Professional loaders
                  </p>
                  <p style={{ color: "green" }}>
                    <DoneIcon />
                    Free fuel and van
                  </p>
                  <p style={{ color: "green" }}>
                    <DoneIcon />
                    Paid access
                  </p>
                </div>
                <div>
                  <button
                    onClick={() => handleGetProduct("Economy-POD")}
                    type="submit"
                    style={{width:"110px"}}
                    className="btn btn-primary font-weight-bolder font-size-sm "
                  >
                    Downgrade
                  </button>
                </div>
              </div>
              <hr className="w-100"/>
              </>
            )}
            {orderDetail?.order_details?.pod_plan_type !== "Premium" && (
              <>
              <div
                style={{
                  width: "92%",
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop:"2%"
                }}
              >
                <div>
                  <h5>Premium</h5>
                  <p style={{ color: "green" }}>
                    <DoneIcon />
                    Secure storage unit 70 sq ft.
                  </p>
                  <p style={{ color: "green" }}>
                    <DoneIcon />
                    Professional loaders
                  </p>
                  <p style={{ color: "green" }}>
                    <DoneIcon />
                    Free fuel and van
                  </p>
                  <p style={{ color: "green" }}>
                    <DoneIcon />
                    Free unlimited access
                  </p>
                  <p style={{ color: "green" }}>
                    <DoneIcon />
                    Free cancellation
                  </p>
                </div>
                <div>
                  <button
                    type="button"
                    style={{width:"110px"}}
                    onClick={() => handleGetProduct("POD")}
                    className="btn btn-primary font-weight-bolder font-size-sm "
                  >
                    {orderDetail?.order_details?.pod_plan_type === "Economy"
                      ? "Upgrade"
                      :"Downgrade" }
                  </button>
                </div>
              </div>
              <hr className="w-100"/>
              </>
            )}
            {orderDetail?.order_details?.pod_plan_type !== "First Class" && (
              <>
              <div
                style={{
                  width: "92%",
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop:"2%"
                }}
              >
                <div>
                  <h5>First Class</h5>
                  <p style={{ color: "green" }}>
                    <DoneIcon />
                    Secure storage unit 70 sq ft.
                  </p>
                  <p style={{ color: "green" }}>
                    <DoneIcon />
                    Professional loaders
                  </p>
                  <p style={{ color: "green" }}>
                    <DoneIcon />
                    Free fuel and van
                  </p>
                  <p style={{ color: "green" }}>
                    <DoneIcon />
                    Free unlimited access
                  </p>
                  <p style={{ color: "green" }}>
                    <DoneIcon />
                    Free cancellation
                  </p>
                  <p style={{ color: "green" }}>
                    <DoneIcon />
                    Unlimited packing help & supplies
                  </p>
                  <p style={{ color: "green" }}>
                    <DoneIcon />
                    Concierge service
                  </p>
                  <p style={{ color: "green" }}>
                    <DoneIcon />
                    Additional stops
                  </p>
                </div>
                <div>
                  <button
                    type="button"
                    style={{width:"110px"}}
                    onClick={() => handleGetProduct("First-Class")}
                    className="btn btn-primary font-weight-bolder font-size-sm "
                  >
                    Upgrade
                  </button>
                </div>
              </div>
              <hr className="w-100"/>
              </>
            )}
            {modal.modal &&
            <ConfirmationDialog dialog={modal} setDialog={setModal} formData={formData} orderDetail={orderDetail} handleChange={handleUpdate} />
}
{isLoading && 
  <div className="offcanvas-overlay ">
      <div className="customloader">
        <div className="spinner-border customSpinner" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </div>
    }
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              onClick={() => handleClose()}
              className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
              //   disabled={isLoading}
            >
              Dismiss
            </button>
            {/* <button
              type="submit"
              className="btn btn-primary font-weight-bolder font-size-sm "
              disabled={formik.values.plan === ""}
            >
              Save
            </button> */}
          </div>
        </Modal.Footer>
      </form>
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </Modal>
  );
};

export default ChangePlanModal;
