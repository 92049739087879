import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import SnackBarTool from "../../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { excemptPrice, requestPayment, sendLabel } from "../../../_redux/order/orderCrud";
import { fetchMyOrderDetailCard } from "../../../_redux/order/orderAction";
import { useDispatch } from "react-redux";
import {Radio, RadioGroup, FormControlLabel, FormControl, FormLabel} from '@material-ui/core';
import { LoadingCustome } from "../../../../../../_metronic/_partials/controls/LoadingCustome";


const SendBoxLabelModal = ({
  dialog,
  setDialog,
  orderId
}) => {
    const [snack, setSnack] = useState({ call: false, type: "", message: "" });
    const [label, setLabel] = useState("EasyStorage")
    const [loading, setLoading] = useState(false)

  const intl = useIntl();
  const dispatch = useDispatch()
  const handleChange = () => {
    setSnack({ call: false, type: "", message: "" });
    setLoading(true)
    sendLabel({order_id:orderId, shipmentType :label})
      .then((res) => {
    setLoading(false)

        // dispatch(fetchMyOrderDetailCard(orderId));
        // dispatch(fetchMyOrderTimeline(orderDetail?.id));
        setSnack({ call: true, type: "success", message: res.data.message });
        setTimeout(() => {
            setDialog(false)
        }, 3000);
      })
      .catch((error) => {
    setLoading(false)
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
  };
  return (
    <Modal show={dialog} aria-labelledby="example-modal-sizes-title-lg">
             {loading &&
            <LoadingCustome />
}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">Generate label and send email</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormControl component="fieldset">
      <RadioGroup row aria-label="position" name="position" defaultValue={label} onChange={(e)=>setLabel(e.target.value)}>
      <FormControlLabel value="EasyStorage" control={<Radio color="primary" />} label="EasyStorage" />
        <FormControlLabel value="Customer" control={<Radio color="primary" />} label="Customer" />
      </RadioGroup>
    </FormControl>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={() =>setDialog(false)}
            className="btn btn-light btn-elevate"
          >
            {intl.formatMessage({ id: "No" })}
          </button>
          <> </>
          <button
            type="button"
            onClick={() => handleChange()}
            className="btn btn-delete btn-elevate"
          >
       
            {intl.formatMessage({ id: "Yes" })}
          </button>
        </div>
      </Modal.Footer>
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </Modal>
  );
};

export default SendBoxLabelModal;
