import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { DepotUIProvider } from "./DepotsUIContext";
import { DepotsCard } from "./DepotsCard";
import { NewDepotCanvas } from "./depot-canvas/NewDepotCanvas";
import { fetchPermission } from "../../../../pages/redux/permission";
import { initIds } from "../../../../../_metronic/_partials/controls/RightPanel";
import { useIntl } from "react-intl";

export function DepotsPage({ history }) {
  const dispatch = useDispatch();
  const intl = useIntl();
  const init = ["kt_new_depot_panel"];

  useEffect(() => {
    initIds(init);
  }, [init]);

  const { permission } = useSelector(
    (state) => ({
      permission: state.auth.permission,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(fetchPermission());
  }, [dispatch]);

  return (
    <>
      <DepotUIProvider>
        <DepotsCard permission={permission} />
        <NewDepotCanvas title={intl.formatMessage({ id: "New.Depot" })} />
      </DepotUIProvider>
    </>
  );
}
