/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */

import React, { memo, useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import {
  createFranchiseDepotOnSearch,
  createFranchiseDepotStatus,
} from "../../../_redux/franchises/franchisesCrud";
import {
  fetchFranchisesDepot,
  fetchFranchisesDepotSearch,
} from "../../../_redux/franchises/franchisesActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import ActiveDialogApi from "../../../../../../_metronic/_partials/widgets/alert/ActiveDialogApiCall";
import { Button } from "react-bootstrap";
import { AddDepot, DepotDetailsEdit } from "../franchise-canva";
import SVG from "react-inlinesvg";
import Select from "react-select";
import SnackBarTool from "../../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { Table } from "react-bootstrap";
import { initIds } from "../../../../../../_metronic/_partials/controls/RightPanel";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import { useIntl } from "react-intl";
import { defaultDepot } from "../../../_redux/depots/depotsCrud";
import DynamicDialog from "../../../../../../_metronic/_partials/widgets/alert/DynamicDialog";
import { CircularProgress, Switch } from "@material-ui/core";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

const Depots = ({ franch, permission }) => {
  // Array to store the ids of the panels to be initialized
  const intl = useIntl();

  const init = ["kt_adddepot_panel"];

  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [dialog, setDialog] = useState({ modal: false });
  const [dialogDefault, setDialogDefault] = useState({ modal: false });
  const [toggleCheck, setToggleCheck] = useState(false);

  // Get the detailData and searchData from the store
  const dispatch = useDispatch();

  const { detailData, searchData, depotLoading } = useSelector(
    (state) => ({
      depotLoading: state.franchise.depotLoading,
      detailData: state.franchise.depot,
      searchData: state.franchise.depotSearch,
    }),
    shallowEqual
  );

  // Function to handle the change of status of the depot
  const handleChange = (e) => {
    setSnack({ call: false, type: "", message: "" });
    const data = {
      depot_id: e.id,
      franchise_id: franch?.id,
    };
    createFranchiseDepotOnSearch(data)
      .then((res) => {
        if (res.data.success) {
          // Fetch the updated data and display success message
          dispatch(fetchFranchisesDepot(franch?.id));
          setSnack({ call: true, type: "success", message: res.data.message });
        } else {
          // Display error message
          setSnack({ call: true, type: "error", message: res.data.message });
        }
      })
      .catch((error) => {
        // Display error message
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
  };

  // Function to update the status of the depot
  const handleUpdateStatus = () => {
    setSnack({ call: false, type: "", message: "" });
    const data = {
      id: dialog.data.id,
      status: dialog.data.e ? 1 : 0,
    };
    createFranchiseDepotStatus(data)
      .then((res) => {
        dispatch(fetchFranchisesDepot(dialog.data.franchise));
        setSnack({ call: true, type: "success", message: res.data.message });
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
    setDialog({ modal: false });
  };
  const handleSetDefault = (id) => {
    setSnack({ call: false, type: "", message: "" });
    const data = {
      id: id,
      franchise_id: franch?.id,
    };
    defaultDepot(data)
      .then((res) => {
        if (res.data.success) {
          // Fetch the updated data and display success message
          dispatch(fetchFranchisesDepot(franch?.id));
          setSnack({ call: true, type: "success", message: res.data.message });
          setDialogDefault({ modal: false });
        } else {
          // Display error message
          setDialogDefault({ modal: false });
          setSnack({ call: true, type: "error", message: res.data.message });
        }
      })
      .catch((error) => {
        // Display error message
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
  };
  const [debounceTimer, setDebounceTimer] = useState(null);
  // useEffect(() => {
  //   const delay = 2000; // 2 seconds

  //   const timer = setTimeout(() => {
  //     dispatch(fetchFranchisesDepotSearch(""));
  //   }, delay);

  //   return () => {
  //     clearTimeout(timer);
  //   };
  // }, [dispatch]);
  const handleInputChange = (newInputValue) => {
    clearTimeout(debounceTimer);
    const timer = setTimeout(() => {
      dispatch(fetchFranchisesDepotSearch(newInputValue));
    }, 500); // Adjust the debounce delay as needed (in milliseconds)
    setDebounceTimer(timer);
  };
  useEffect(() => {
    if (detailData && detailData?.length) {
      for (let i = 0; i < detailData?.length; i++) {
        init.push(`kt_depotdetailsedit_panel${i}`);
      }
    }
  }, [detailData, init]);

  useEffect(() => {
    initIds(init);
  }, [init]);
  useEffect(() => {
    if (toggleCheck) {
      dispatch(fetchFranchisesDepot(franch?.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleCheck]);

  return (
    <>
      <div className="card card-custom  gutter-b mb-10 depots-pannel">
        <div className="card-body ">
          <div className="d-flex justify-content-between align-items-center w-100 depot-fr">
            <h3 className="card-title p-0 m-0 ">
              <span className="font-weight-bolder text-dark">
                {intl.formatMessage({ id: "DEPOTS" })}
              </span>
              <span>
                {toggleCheck ? (
                  <ExpandLessIcon
                    style={{
                      fontSize: "3rem",
                      margin: " 0 10px",
                      cursor: "pointer",
                    }}
                    onClick={() => setToggleCheck(!toggleCheck)}
                  />
                ) : (
                  <KeyboardArrowRightIcon
                    style={{
                      fontSize: "3rem",
                      margin: " 0 10px",
                      cursor: "pointer",
                    }}
                    onClick={() => setToggleCheck(!toggleCheck)}
                  />
                )}
              </span>
              {/* <Switch
                checked={toggleCheck}
                onChange={() => setToggleCheck(!toggleCheck)}
                color="primary"
                name="checkedB"
                inputProps={{ "aria-label": "primary checkbox" }}
              /> */}
            </h3>
            <div>
              <div className="d-flex deport-srch">
                {toggleCheck && (
                  <div className="mr-2 d-flex">
                    <label className="mt-1">Search&nbsp;</label>
                    <Form.Group className="mb-0">
                      <InputGroup>
                        <Select
                          className="basic-single w-100"
                          classNamePrefix="select"
                          placeholder={""}
                          defaultValue={[""]}
                          isSearchable={true}
                          getOptionLabel={(option) => option?.name}
                          getOptionValue={(option) => option?.id}
                          options={searchData ? searchData : []}
                          onChange={handleChange}
                          onInputChange={(e) => {
                            if (e?.length > 3) {
                              handleInputChange(e);
                            }
                          }}
                          value={""}
                        />
                      </InputGroup>
                    </Form.Group>
                  </div>
                )}
                <div>
                  {permission?.setting_depot?.create ? (
                    <Button
                      variant="primary"
                      size="sm"
                      id="kt_adddepot_panel_toggle"
                    >
                      {intl.formatMessage({ id: "Add.Depot" })}
                    </Button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          {toggleCheck && (
            <div>
              {detailData && detailData.length && !depotLoading ? (
                <Table responsive className="mt-5">
                  <thead>
                    <tr>
                      <th>{intl.formatMessage({ id: "Storage.Name" })}</th>
                      <th>{intl.formatMessage({ id: "Location" })}</th>
                      <th>{intl.formatMessage({ id: "MANAGER.NAME" })}</th>

                      <th style={{ width: "20px" }}></th>
                      <th style={{ width: "20px" }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {detailData
                      ? detailData.map((data, index) => (
                          <tr key={index}>
                            <td className="breakeWrap">
                              {data.name} {/* {data?.is_default && ( */}
                              {data.status === 1 && (
                                <div
                                  className={`${
                                    data?.is_default
                                      ? "text-primary"
                                      : "text-muted-grey text-hover-primary"
                                  }`}
                                  onClick={() => {
                                    if (data?.is_default) return;
                                    setDialogDefault({
                                      modal: true,
                                      funData: data?.id,
                                    });
                                  }}
                                >
                                  {data?.is_default
                                    ? "Default"
                                    : "Set as default"}
                                </div>
                              )}
                              {/* )} */}
                            </td>

                            <td className="breakeWrap">{data.location}</td>

                            <td>{data.manager_name}</td>

                            <td>
                              {permission?.setting_depot?.edit ? (
                                <span
                                  className="symbol-label ml-2 pointer"
                                  id={`kt_depotdetailsedit_panel${index}_toggle`}
                                >
                                  <span className="svg-icon svg-icon-sm svg-icon-primary">
                                    <SVG
                                      className="h-25 align-self-end"
                                      title="Edit"
                                      src={toAbsoluteUrl(
                                        "/media/svg/icons/Design/Edit.svg"
                                      )}
                                    ></SVG>
                                  </span>
                                </span>
                              ) : (
                                ""
                              )}
                            </td>
                            <td>
                              <span className="switch switch-outline switch-icon switch-primary">
                                <label>
                                  <input
                                    type="checkbox"
                                    checked={data.status === 1 ? true : false}
                                    onChange={(e) =>
                                      setDialog({
                                        status: data.status,
                                        modal: true,
                                        data: {
                                          e: e.target.checked,
                                          id: data.id,
                                          franchise: data.franchise_id,
                                          is_default: data.is_default,
                                        },
                                      })
                                    }
                                  />
                                  <span></span>
                                </label>
                              </span>
                            </td>
                            <td>
                              <DepotDetailsEdit
                                type={index}
                                data={data}
                                check={"franchise"}
                              />
                            </td>
                          </tr>
                        ))
                      : ""}
                  </tbody>
                </Table>
              ) : (
                <>
                  {depotLoading ? (
                    <div className="text-center mt-8">
                      <CircularProgress />
                    </div>
                  ) : (
                    <div className="mt-6" style={{ textAlign: "center" }}>
                      <p>{intl.formatMessage({ id: "No.Record.Found" })}</p>
                    </div>
                  )}
                </>
              )}
              <AddDepot fData={franch} />
            </div>
          )}
        </div>
      </div>
      {snack.call ? <SnackBarTool {...snack} /> : ""}
      <ActiveDialogApi
        dialog={dialog}
        setDialog={setDialog}
        handleChange={handleUpdateStatus}
      />
      <DynamicDialog
        dialog={dialogDefault}
        setDialog={setDialogDefault}
        header={"Set Default"}
        mainText={"Do you want to set as default depot"}
        handleChange={handleSetDefault}
      />
    </>
  );
};

export default memo(Depots);
