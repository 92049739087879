/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

import "rc-time-picker/assets/index.css";

import * as Yup from "yup";

import React, { useEffect, useRef, useState } from "react";
import { FormControl, MenuItem, Select } from "@material-ui/core/";
import { fetchSearchTask, fetchTasks } from "../_redux/TasksAction";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import Autocomplete from "@material-ui/lab/Autocomplete";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import { CreateTasksSchema } from "../tasks-form/validationSchema";
import DatePicker from "react-datepicker";
import { InputGroup } from "react-bootstrap";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import SnackBarTool from "../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { TextField } from "@material-ui/core";
import TimePicker from "rc-time-picker";
import { canvaCancel } from "../../../utils/canvaCloseFunction";
import { createTask } from "../_redux/TasksCrud";
import { getInputClasses } from "../../../utils/commonFunction";
import { initialFilter } from "../TasksUIHelpers";
import moment from "moment";
import { useCallback } from "react";
import { useFormik } from "formik";
import { useIntl } from "react-intl";

export function CreateTasksCanva() {
  const ref = useRef();
  const intl = useIntl();

  const { searchTask, admin } = useSelector(
    (state) => ({
      searchTask: state.tasks.searchTask,
      admin: state.auth.user,
    }),
    shallowEqual
  );

  const [value, setValue] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [inputValue, setInputValue] = useState("");
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(false);
  const [debounceTimer, setDebounceTimer] = useState(null);

  const { currentState } = useSelector(
    (state) => ({ currentState: state.contact.agent }),
    shallowEqual
  );

  const initialValues = {
    contact_id: "",
    task_type: "",
    task_name: "",
    task_owner_id: admin && admin.id ? admin.id : "",
    task_description: "",
    due_date: "",
    is_completed: 0,
    deal_id: "",
    priority: "",
    time: "",
  };

  const dispatch = useDispatch();

  const handleSubmit = (values, actions) => {
    setLoading(true);
    setSnack({ call: false, type: "", message: "" });
    createTask(values)
      .then((res) => {
        setValue(null);
        setSnack({ call: true, type: "success", message: res.data.message });
        dispatch(fetchTasks(initialFilter));
        formik.resetForm();
        setLoading(false);
        canvaCancel("kt_create_tasks_panel_close");
      })
      .catch((error) => {
        setLoading(false);
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
  };
  const handleReset = () => {};

  const formik = useFormik({
    initialValues,
    validationSchema: CreateTasksSchema(intl, Yup),
    onSubmit: handleSubmit,
    onReset: handleReset,
  });

  const handleValue = (newValue) => {
    setValue(newValue);
    if (newValue.contact_id) {
      formik.setFieldValue("contact_id", newValue.contact_id);
      formik.setFieldValue("deal_id", newValue.id);
    } else {
      formik.setFieldValue("contact_id", newValue.id);
    }
  };

  // useEffect(() => {
  //   dispatch(fetchSearchTask(""));
  // }, [dispatch]);
  const handleSetDate = (e) => {
    const formated = moment(e).format("YYYY-MM-DD");

    formik.setFieldValue("due_date", formated);
  };

  const handleScroll = useCallback(
    (e) => {
      setTimer(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [timer]
  );
  useEffect(() => {
    if (timer) {
      document
        .getElementById("kt_quick_panel_logs_kt_create_tasks_panel")
        .addEventListener("scroll", handleScroll);
    } else {
      document
        .getElementById("kt_quick_panel_logs_kt_create_tasks_panel")
        .removeEventListener("scroll", handleScroll);
    }

    return () => {
      document
        .getElementById("kt_quick_panel_logs_kt_create_tasks_panel")
        .removeEventListener("scroll", handleScroll);
    };
  }, [timer, handleScroll]);
  const handleInputChange = (newInputValue) => {
    setInputValue(newInputValue);
    clearTimeout(debounceTimer);
    const timer = setTimeout(() => {
      dispatch(fetchSearchTask(newInputValue));
    }, 500); // Adjust the debounce delay as needed (in milliseconds)
    setDebounceTimer(timer);
  };
  return (
    <div
      id="kt_create_tasks_panel"
      className="offcanvas offcanvas-right pt-5 pb-10"
    >
      {/*begin::Header*/}
      <div className="offcanvas-header offcanvas-header d-flex align-items-center justify-content-between mb-5">
        <div
          className="offcanvas-close mt-n1 pr-5"
          style={{ position: "absolute", top: "15px", right: "10px" }}
        >
          <span
            onClick={() => formik.resetForm()}
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            id="kt_create_tasks_panel_close"
          >
            <i className="ki ki-close icon-sm text-muted"></i>
          </span>
        </div>
      </div>
      {/*end::Header*/}
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <div
          className="offcanvas-content date-picker  fade pt-3 pr-5 mr-n5 scroll active show ps--active-y "
          id="kt_quick_panel_logs_kt_create_tasks_panel"
          ref={ref}
        >
          <div>
            <h5>{intl.formatMessage({ id: "Create.Task" })}</h5>
          </div>
          <div className="row mt-10">
            <div className="col-md-9 mb-5 mr-4">
              <div style={{ position: "relative" }}>
                <Autocomplete
                  value={value}
                  onChange={(event, newValue) => {
                    handleValue(newValue);
                  }}
                  // inputValue={inputValue}
                  onInputChange={(event, newInputValue) => {
                    if (newInputValue?.length > 3) {
                      handleInputChange(newInputValue);
                    }

                    // setInputValue(newInputValue);
                  }}
                  id="controllable-states-demo"
                  options={searchTask}
                  getOptionLabel={(option) =>
                    `${
                      option?.user
                        ? option?.user?.first_name
                        : option?.contact
                        ? option?.contact?.user?.first_name
                        : ""
                    } ${
                      option?.user
                        ? option?.user?.last_name
                        : option?.contact
                        ? option?.contact?.user?.last_name
                        : ""
                    } | ${
                      option?.user ? "Contact" : option?.contact ? "Deal" : ""
                    } | ${option.id}`
                  }
                  style={{ width: 270 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={intl.formatMessage({
                        id: "Search.Contact.Deals.Customers",
                      })}
                      variant="outlined"
                    />
                  )}
                />
              </div>
            </div>
            <div className="col-md-4 mb-5">
              <FormControl fullWidth>
                <label>{intl.formatMessage({ id: "Task.Type" })}</label>
                <Select
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                  IconComponent={(props) => (
                    <i {...props}>
                      <KeyboardArrowDownIcon />
                    </i>
                  )}
                  inputProps={{ "aria-label": "Without label" }}
                  displayEmpty
                  name="task_type"
                  className={`form-control w-100  ${getInputClasses(
                    formik,
                    "task_type"
                  )}`}
                  {...formik.getFieldProps("task_type")}
                >
                  <MenuItem value="">
                    {intl.formatMessage({ id: "Select" })}
                  </MenuItem>
                  <MenuItem value="Call">
                    {" "}
                    {intl.formatMessage({ id: "Call" })}
                  </MenuItem>
                  <MenuItem value="Email">
                    {intl.formatMessage({ id: "Email" })}
                  </MenuItem>
                  <MenuItem value="SMS">
                    {intl.formatMessage({ id: "SMS" })}
                  </MenuItem>
                </Select>
              </FormControl>
              {formik.touched.task_type && formik.errors.task_type ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.task_type}</div>
                </div>
              ) : null}
            </div>

            <div className="col-md-4 mb-5">
              <FormControl fullWidth>
                <label>{intl.formatMessage({ id: "Priority" })}</label>
                <Select
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                  IconComponent={(props) => (
                    <i {...props}>
                      <KeyboardArrowDownIcon />
                    </i>
                  )}
                  inputProps={{ "aria-label": "Without label" }}
                  displayEmpty
                  name="priority"
                  className={`form-control w-100  ${getInputClasses(
                    formik,
                    "priority"
                  )}`}
                  {...formik.getFieldProps("priority")}
                >
                  <MenuItem value="">
                    {intl.formatMessage({ id: "Select" })}
                  </MenuItem>
                  <MenuItem value="Hot">
                    {intl.formatMessage({ id: "Hot" })}
                  </MenuItem>
                  <MenuItem value="Medium">
                    {intl.formatMessage({ id: "Medium" })}
                  </MenuItem>
                  <MenuItem value="Low">
                    {intl.formatMessage({ id: "Low" })}{" "}
                  </MenuItem>
                </Select>
              </FormControl>
              {formik.touched.priority && formik.errors.priority ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.priority}</div>
                </div>
              ) : null}
            </div>
            <div className="col-md-4 mb-5">
              <label>{intl.formatMessage({ id: "Task.Name" })}</label>
              <input
                type="text"
                name="task_name"
                className={`form-control  ${getInputClasses(
                  formik,
                  "task_name"
                )}`}
                placeholder={intl.formatMessage({ id: "Task.Name" })}
                {...formik.getFieldProps("task_name")}
              />
              {formik.touched.task_name && formik.errors.task_name ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.task_name}</div>
                </div>
              ) : null}
            </div>
            <div className="col-md-4 mb-5">
              <FormControl fullWidth>
                <label>{intl.formatMessage({ id: "Task.Owner" })}</label>
                <Select
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                  IconComponent={(props) => (
                    <i {...props}>
                      <KeyboardArrowDownIcon />
                    </i>
                  )}
                  inputProps={{ "aria-label": "Without label" }}
                  displayEmpty
                  name="task_owner_id"
                  className={`form-control w-100  ${getInputClasses(
                    formik,
                    "task_owner_id"
                  )}`}
                  {...formik.getFieldProps("task_owner_id")}
                >
                  <MenuItem value="">
                    {intl.formatMessage({ id: "Select" })}
                  </MenuItem>
                  <MenuItem value="is_unassigned">
                    {intl.formatMessage({ id: "Unassigned" })}
                  </MenuItem>
                  <MenuItem value={admin.id}>
                    {admin && admin.first_name ? admin.first_name : " "}&nbsp;
                    {admin && admin.last_name ? admin.last_name : " "}
                  </MenuItem>
                  {currentState &&
                    currentState.map((data, index) => (
                      <MenuItem
                        key={index}
                        value={data.id}
                        disabled={data?.is_available !== 1 ? true : false}
                      >
                        {data && data.first_name ? data.first_name : " "}&nbsp;
                        {data && data.last_name ? data.last_name : " "}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              {formik.touched.task_owner_id && formik.errors.task_owner_id ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.task_owner_id}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="col-md-4 mb-5">
              <label>{intl.formatMessage({ id: "Time" })}</label>
              <div>
                <TimePicker
                  defaultValue={moment()}
                  value={moment(
                    `${moment().format("yyyy-MM-DD")} ${
                      formik.values.time === "" ? "00:00" : formik.values.time
                    }`
                  )}
                  showSecond={false}
                  open={timer}
                  onOpen={() => setTimer(true)}
                  onClose={() => setTimer(false)}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "time",
                      moment(e).format("HH:mm") === "Invalid date"
                        ? "00:00"
                        : moment(e).format("HH:mm")
                    )
                  }
                  focusOnOpen
                  clearIcon
                />
              </div>

              {formik.touched.time && formik.errors.time ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.time}</div>
                </div>
              ) : null}
            </div>
            <div className="col-md-4">
              <div className="form-groupB">
                <label>{intl.formatMessage({ id: "Due.Date" })}</label>
                <InputGroup className="calendr">
                  <DatePicker
                    fullWidth
                    fixedHeight
                    className="form-control w-100"
                    wrapperClassName="datepicker"
                    dateFormat="dd-MM-yyyy"
                    minDate={new Date()}
                    selected={Date.parse(formik.values?.due_date)}
                    onChange={(date) => handleSetDate(date)}
                    placeholderText="DD-MM-YYYY"
                  />
                  <CalendarTodayIcon />
                </InputGroup>
              </div>
              {formik.touched.due_date && formik.errors.due_date ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.due_date}</div>
                </div>
              ) : null}
            </div>

            <div className="col-md-12 mb-5">
              <label htmlFor="exampleFormControlTextarea1">
                {intl.formatMessage({ id: "Task.Description" })}
              </label>
              <textarea
                style={{ width: "100%" }}
                id="exampleFormControlTextarea1"
                rows="3"
                name="task_description"
                className={`form-control  ${getInputClasses(
                  formik,
                  "task_description"
                )}`}
                {...formik.getFieldProps("task_description")}
              ></textarea>
              {formik.touched.task_description &&
              formik.errors.task_description ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.task_description}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="col-md-12 mb-5">
              <label className="checkbox">
                <input
                  type="checkbox"
                  name="Checkboxes4"
                  onChange={(e) =>
                    formik.setFieldValue(
                      "is_completed",
                      e.target.checked ? 1 : 0
                    )
                  }
                />
                <span className="mr-2"></span>
                {intl.formatMessage({ id: "Completed" })}
              </label>
            </div>
          </div>
        </div>

        <div className="offcanvas-footer">
          <div className="offcanvas-content text-right ">
            <button
              type="reset"
              className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
              onClick={() => canvaCancel("kt_create_tasks_panel_close")}
            >
              {intl.formatMessage({ id: "CANCEL" })}
            </button>
            <button
              type="submit"
              className="btn btn-primary font-weight-bolder font-size-sm "
              disabled={loading}
            >
              {intl.formatMessage({ id: "SAVE" })}
              {loading && (
                <span className="ml-2 mr-2 spinner spinner-white"></span>
              )}
            </button>
          </div>
        </div>
      </form>

      {/* snack bar component */}
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </div>
  );
}
