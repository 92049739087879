/**
 * Entry application component used to compose providers and render Routes.
 * */

import { LayoutSplashScreen, MaterialThemeProvider } from "../_metronic/layout";

import { BrowserRouter } from "react-router-dom";
import { I18nProvider } from "../_metronic/i18n";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import React from "react";
import { Routes } from "../app/Routes";
import { Helmet } from "react-helmet";
// import { useEffect } from "react";
// import { loadUserGuidingScript } from "./utils/commonFunction";

export default function App({ store, persistor, basename }) {
  // useEffect(() => {
  //   loadUserGuidingScript();
  // }, []);
  // window.userGuiding.identify(userId*, attributes)  // example with
  // attributeswindow.userGuiding.identify('1Ax69i57j0j69i60l4', {  email: 'mailto:user@awesome.com',  name: 'Awesome User',  created_at: 1644403436643,})// or just send userId without attributeswindow.userGuiding.identify('1Ax69i57j0j69i60l4')
  return (
    /* Provide Redux store */
    <Provider store={store}>
      <Helmet>
        <script>
          {`(function(g, u, i, d, e, s) {
            g[e] = g[e] || [];
            var f = u.getElementsByTagName(i)[0];
            var k = u.createElement(i);
            k.async = true;
            k.src =
              "https://static.userguiding.com/media/user-guiding-" +
              s +
              "-embedded.js";
            f.parentNode.insertBefore(k, f);
            if (g[d]) return;
            var ug = (g[d] = { q: [] });
            ug.c = function(n) {
              return function() {
                ug.q.push([n, arguments]);
              };
            };
            var m = [
              "previewGuide",
              "finishPreview",
              "track",
              "identify",
              "triggerNps",
              "hideChecklist",
              "launchChecklist",
            ];
            for (var j = 0; j < m.length; j += 1) {
              ug[m[j]] = ug.c(m[j]);
            }
          })(
            window,
            document,
            "script",
            "userGuiding",
            "userGuidingLayer",
            "766230265ID"
          )`}
        </script>
      </Helmet>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
        {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
        <React.Suspense fallback={<LayoutSplashScreen />}>
          {/* Override `basename` (e.g: `homepage` in `package.json`) */}
          <BrowserRouter basename={basename}>
            {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
            <MaterialThemeProvider>
              {/* Provide `react-intl` context synchronized with Redux state.  */}
              <I18nProvider>
                {/* Render routes with provided `Layout`. */}
                <Routes />
              </I18nProvider>
            </MaterialThemeProvider>
          </BrowserRouter>
        </React.Suspense>
      </PersistGate>
    </Provider>
  );
}
