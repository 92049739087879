import axios from "axios";
export const TIMELINE = "timeline";
export const TIMELINE_LIST = "timeline/get-selected-timelines";
export const TIMELINE_PROFILE = "timeline/profile/list";

export function feedsCreate(data) {
  return axios.post(`${process.env.REACT_APP_SITE_URL + TIMELINE}`, data);
}
export function feedsUpdate(id, data) {
  return axios.post(`${process.env.REACT_APP_SITE_URL + TIMELINE}/${id}`, data);
}
export function feedsList() {
  return axios.get(`${process.env.REACT_APP_SITE_URL + TIMELINE_LIST}`);
}
export function feedsProfileList() {
  return axios.get(`${process.env.REACT_APP_SITE_URL + TIMELINE_PROFILE}`);
}
