import CheckboxTableCheck from "./CheckboxTabelCheck";
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import { useIntl } from "react-intl";

const CheckboxDetail = ({ permissArray }) => {
  const intl = useIntl();
  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">
              <span className="font-weight-bolder ">
                {intl.formatMessage({ id: "Permission" })}
              </span>
            </th>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col"></th>
          </tr>
        </thead>

        <tbody>
          {Object.entries({
            contact: intl.formatMessage({ id: "Contact" }),
            deal: intl.formatMessage({ id: "DEALS" }),
            task: intl.formatMessage({ id: "TASKS" }),
            customer: intl.formatMessage({ id: "CUSTOMERS" }),
            order: intl.formatMessage({ id: "ORDERS" }),
            calendar: intl.formatMessage({ id: "Calendars" }),
            setting: intl.formatMessage({ id: "SETTINGS" }),
            setting_franchise: intl.formatMessage({
              id: "Settings.Franchises",
            }),
            setting_product: intl.formatMessage({ id: "Settings.Products" }),
            setting_country: intl.formatMessage({ id: "Settings.Country" }),
            setting_depot: intl.formatMessage({ id: "Settings.Depots" }),
            setting_user: intl.formatMessage({ id: "Settings.Users" }),
            setting_reporting: intl.formatMessage({ id: "Settings.Reporting" }),
            setting_promotion: intl.formatMessage({
              id: "Settings.Promotions",
            }),
            setting_feed: intl.formatMessage({ id: "Settings.Feeds" }),

            dashboard: intl.formatMessage({ id: "Dashboards" }),
            new_order: intl.formatMessage({ id: "NEW.ORDER" }),
            reporting: intl.formatMessage({ id: "REPORTING" }),
          }).map(([permission, label]) => (
            <tr>
              <th scope="row">
                {" "}
                <label className="font-weight-normal">{label}</label>
              </th>
              <th scope="row">
                <span
                  className={`svg-icon  svg-icon-${
                    permissArray?.[permission]?.create ||
                    permissArray?.[permission]?.view ||
                    permissArray?.[permission]?.edit ||
                    permissArray?.[permission]?.delete
                      ? "success svg-icon-lg"
                      : "danger closeicon"
                  } ml-3 `}
                  style={{ cursor: "pointer" }}
                >
                  <SVG
                    title=" "
                    className="h-22 align-self-end"
                    src={toAbsoluteUrl(
                      permissArray?.[permission]?.create ||
                        permissArray?.[permission]?.view ||
                        permissArray?.[permission]?.edit ||
                        permissArray?.[permission]?.delete
                        ? "/media/svg/icons/Code/Done-circle.svg"
                        : "/media/svg/icons/Navigation/Minus.svg"
                    )}
                  ></SVG>
                </span>
              </th>
              <CheckboxTableCheck permissArray={permissArray?.[permission]} />
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default CheckboxDetail;
