import React from "react";
import { Modal } from "react-bootstrap";
// import { useIntl } from "react-intl";

const CheckoutDialog = ({ dialog, setDialog, handleSend, title, mainText }) => {
  // const intl = useIntl();

  return (
    <Modal show={dialog} aria-labelledby="example-modal-sizes-title-lg">
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <div className="mb-4">
          <button
            type="button"
            onClick={() => handleSend()}
            className="btn btn-light btn-elevate w-50"
          >
            Retry Payment
          </button>
        </div>
        <div className="mb-4">
          <button
            type="button"
            onClick={() => handleSend("quote")}
            className="btn btn-light btn-elevate  w-50"
          >
            Send Quote
          </button>
        </div>
        <div className="mb-4">
          <button
            type="button"
            onClick={() => handleSend("draft")}
            className="btn btn-light btn-elevate  w-50"
          >
            Save Draft
          </button>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={() => setDialog(false)}
            className="btn btn-light btn-elevate"
          >
            Close
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default CheckoutDialog;
