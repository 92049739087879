import React, { useEffect } from "react";
import { useSubheader } from "../../_metronic/layout";
import { Setting } from "../../_metronic/_partials/settings/Setting";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { fetchPermission } from "./redux/permission";

export const Settings = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchPermission());
  }, [dispatch]);
  const intl = useIntl();
  useSubheader().setTitle(intl.formatMessage({ id: "SETTINGS" }));
  return <Setting />;
};
