import { createSlice } from "@reduxjs/toolkit";

const initialFeedsState = {
  list: { entities: [] },
  activeCountry: [],
  profilelist: [],
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const feedsSlice = createSlice({
  name: "Feeds",
  initialState: initialFeedsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.list.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.list.listLoading = true;
      } else {
        state.list.actionsLoading = true;
      }
    },

    feedList: (state, action) => {
      const { data } = action.payload;
      state.list.entities = data;
    },
    feedProfileList: (state, action) => {
      const { data } = action.payload;
      state.profilelist = data;
    },
  },
});
