import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./OrderUIHelpers";
import { useContextState } from "./reducer/orderDetailReducer";
import { getBreadDownPayload } from "./EditOrderUtils";
import { getBreakDownData } from "./_redux/order/orderCrud";
import { useEffect } from "react";
import SnackBarTool from "../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { LoadingCustome } from "../../../_metronic/_partials/controls/LoadingCustome";

const OrderUIContext = createContext();

export function useOrderUIContext() {
  return useContext(OrderUIContext);
}

export const OrderUIConsumer = OrderUIContext.Consumer;

export function OrderUIProvider({ OrderUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const { state, dispatchReducer } = useContextState();
  const handleAddProductEdit = useCallback(
    (product, call) => {
      if (call === "noCall") {
        dispatchReducer({
          type: "SET_DATA",
          data: { ...state, productArray: product },
        });
      } else {
        dispatchReducer({
          type: "SET_DATA",
          data: { ...state, productArray: product, isBreakeDownApiCall: true },
        });
      }
    },
    [dispatchReducer, state]
  );

  const handleBreakDownApiCall = useCallback(() => {
    setSnack({ call: false, type: "", message: "" });
    setIsLoading(true);
    const dataObject = getBreadDownPayload(state);
    getBreakDownData(dataObject)
      .then((res) => {
        dispatchReducer({
          type: "SET_DATA",
          data: {
            ...state,
            breakdown: res.data.data.breakdown,
            isBreakeDownApiCall: false,
          },
        });
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
  }, [dispatchReducer, state]);
  useEffect(() => {
    if (state.isBreakeDownApiCall) {
      handleBreakDownApiCall();
    }
  }, [handleBreakDownApiCall, state.isBreakeDownApiCall]);

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    openEditProductPage: OrderUIEvents?.openEditProductPage,
    openDeleteProductDialog: OrderUIEvents?.openDeleteProductDialog,
    openDeleteOrderDialog: OrderUIEvents?.openDeleteOrderDialog,
    openFetchOrderDialog: OrderUIEvents?.openFetchOrderDialog,
    openUpdateOrderStatusDialog: OrderUIEvents?.openUpdateOrderStatusDialog,
    state,
    dispatchReducer,
    handleAddProductEdit,
    handleBreakDownApiCall,
  };

  return (
    <OrderUIContext.Provider value={value}>
      {children}
      {isLoading && <LoadingCustome />}
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </OrderUIContext.Provider>
  );
}
