import { createSlice } from "@reduxjs/toolkit";

const initialContainerState = {
  list: {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    productForEdit: undefined,
    lastError: null,
  },
  conLoading: false,
  containerFranchise: { list: [], totalCount: 0 },
};
export const callTypes = {
  list: "list",
  listAll: "listAll",
  action: "action",
};

export const containerSlice = createSlice({
  name: "Container",
  initialState: initialContainerState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.conLoading = false;
      } else if (action.payload.callType === callTypes.listAll) {
        state.list.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.conLoading = true;
      } else if (action.payload.callType === callTypes.listAll) {
        state.list.listLoading = true;
      } else {
        state.list.actionsLoading = true;
      }
    },

    //Container list
    ContainerFetchedList: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.list.listLoading = false;
      state.list.error = null;
      state.list.entities = entities;
      state.list.totalCount = totalCount;
    },

    ContainerFetchedFranchise: (state, action) => {
      const { data, totalCount } = action.payload;
      state.containerFranchise.list = data;
      state.containerFranchise.totalCount = totalCount
      state.conLoading = false
    },
  },
});
