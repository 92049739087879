import * as Yup from "yup";
// eslint-disable-next-line no-useless-escape
// const phoneRegExp = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[123456789]\d{9}$/;

export const NewDepotSchema = (intl, yp) => {
  return Yup.object().shape({
    name: Yup.string()
      // .matches(/^[aA-zZ\s]+$/, intl.formatMessage({ id: "Only.alphabets" }))
      .trim()
      .required(intl.formatMessage({ id: "Depot.Name.Required" })),
    short_name: Yup.string()
      // .matches(
      //   /^[aA-zZ-Z0-9\s]+$/,
      //   intl.formatMessage({ id: "Only.alphabets.Number" })
      // )
      .trim()
      .required(intl.formatMessage({ id: "Depot.Short.Required" })),
    depot_reference_id: Yup.string().required("Depot ID is Required"),
    address_line_1: Yup.string()
      // .matches(
      //   /^[aA-zZ-Z0-9,-.\s]+$/,
      //   intl.formatMessage({ id: "Only.alphabets.Number" })
      // )
      .trim()
      .required(intl.formatMessage({ id: "Address.Required" })),
    // address_line_2: Yup.string().matches(
    //   /^[aA-zZ-Z0-9,-.\s]+$/,
    //   intl.formatMessage({ id: "Only.alphabets.Number" })
    // ),

    // address_line_3: Yup.string().matches(
    //   /^[aA-zZ-Z0-9,-.\s]+$/,
    //   intl.formatMessage({ id: "Only.alphabets.Number" })
    // ),

    country: Yup.string().required(
      intl.formatMessage({ id: "CountryRequired" })
    ),
    // latitude: Yup.string()
    //   .matches(
    //     /^[0-9.-\s]+$/,
    //     intl.formatMessage({ id: "Only.Numbers.allowed" })
    //   )
    //   .trim()
    //   .required(intl.formatMessage({ id: "Latitude.Required" })),
    // longitude: Yup.string()
    //   .matches(
    //     /^[0-9.-\s]+$/,
    //     intl.formatMessage({ id: "Only.Numbers.allowed" })
    //   )
    //   .trim()
    //   .required(intl.formatMessage({ id: "Longitude.Required" })),
    manager_name: Yup.string()
      // .matches(/^[aA-zZ\s]+$/, intl.formatMessage({ id: "Only.alphabets" }))
      .trim()
      .required(intl.formatMessage({ id: "Manager.Name.Required" })),
    manager_email: Yup.string()
      .email(intl.formatMessage({ id: "Wrong.email.format" }))
      .min(3, intl.formatMessage({ id: "Minimum.3.symbols" }))
      .max(50, intl.formatMessage({ id: "Maximum.50.symbols" }))
      .required(intl.formatMessage({ id: "Email.Required" })),
    phone: Yup.string()
      // .matches(phoneRegExp, "Phone number must be of 10 digit")
      .required(intl.formatMessage({ id: "Phone.Number.Required" })),
    // additional_emails: Yup.string()
    //   .email(intl.formatMessage({ id: "Wrong.email.format" }))
    //   .min(3, intl.formatMessage({ id: "Minimum.3.symbols" }))
    //   .max(50, intl.formatMessage({ id: "Maximum.50.symbols" })),

    postcode: Yup.string()
      // .matches(
      //   /^[aA-zZ-Z0-9\s]+$/,
      //   intl.formatMessage({ id: "Only.alphabets.Number" })
      // )
      .trim()
      .required(intl.formatMessage({ id: "Post.Code.Required" })),
    depot_hours: Yup.array().of(
      Yup.object().shape({
        open_time: Yup.string().nullable(),
        close_time: Yup.string()
          .nullable()
          .when("open_time", {
            is: (val) => !!val,
            then: Yup.string().required("Close time is required"),
          }),
      })
    ),
    // depotAddress: Yup.string().required("Depot Address is Required"),
    city: Yup.string()
      // .matches(
      //   /^[aA-zZ-Z0-9.\s]+$/,
      //   intl.formatMessage({ id: "Only.alphabets.Number" })
      // )
      .trim()
      .required(intl.formatMessage({ id: "City.Required" })),
  });
};
