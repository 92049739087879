/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { FormControl, MenuItem, Select } from "@material-ui/core/";
import { Form } from "react-bootstrap";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { getInputClasses } from "../../../utils/commonFunction";
import { InputGroup } from "react-bootstrap";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import DatePicker from "react-datepicker";
import TimePicker from "rc-time-picker";
import moment from "moment";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { fetchFranchisesDepot } from "../../Settings/_redux/franchises/franchisesActions";
import { getActiveVan } from "../../../pages/redux/commonCrud";
import { createTravelBlocking } from "../redux/calendarCrud";
import SnackBarTool from "../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { BlockSchema, TravelBlock } from "../calendar-form/validationSchema";
import { useIntl } from "react-intl";
import { capitalizeFirstLetter } from "../../../utils/canvaCloseFunction";
import DiscardDialog from "../../../../_metronic/_partials/widgets/alert/DiscardDialog";
export function TravelBlockingCanva({ formData, canva, setCanva, handleApi }) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [activeVan, setActiveVan] = useState([]);
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [dialog, setDialog] = useState(false);

  const initialValues = {
    van_id: "",
    depot_id: "",
    blocking_date_from: "",
    blocking_date_to: "",
    time_from: "08:00",
    time_to: "08:00",
    reason: "",
    is_travel: canva.title === "travel" ? "1" : "0",
  };
  const { depotFraList } = useSelector(
    (state) => ({
      depotFraList: state.franchise.depot,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (formData?.franchise) {
      dispatch(fetchFranchisesDepot(formData?.franchise));
      handleActiveVan();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData?.franchise]);
  const handleSubmit = (values) => {
    setSnack({
      call: false,
      type: "",
      message: "",
    });
    if (
      new Date(values?.blocking_date_from).getDate() ===
        new Date(values?.blocking_date_to).getDate() &&
      values?.time_from > values?.time_to
    ) {
      setSnack({
        call: true,
        type: "error",
        message: "From time can't be greater then To time",
      });
      return;
    }
    createTravelBlocking(values)
      .then((res) => {
        handleApi();
        setSnack({ call: true, type: "success", message: res.data.message });
        setTimeout(() => {
          setCanva({ type: false, title: "" });
        }, 2000);
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
  };

  // Formik form configuration
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: canva.title === "travel" ? TravelBlock : BlockSchema,
    onSubmit: handleSubmit,
  });

  const handleSetDate = (fromDate) => {
    formik.setValues({
      ...formik.values,
      blocking_date_from: moment(fromDate).format("yyyy-MM-DD"),
    });

    formik.setFieldValue(
      "blocking_date_from",
      moment(fromDate).format("yyyy-MM-DD")
    );
  };

  const handleToDate = (toDate) => {
    formik.setValues({
      ...formik.values,
      blocking_date_to: moment(toDate).format("yyyy-MM-DD"),
    });

    formik.setFieldValue(
      "blocking_date_to",
      moment(toDate).format("yyyy-MM-DD")
    );
  };

  const handleActiveVan = () => {
    getActiveVan(formData?.franchise).then((res) =>
      setActiveVan(res.data.data)
    );
  };

  function disabledHours() {
    return [0, 1, 2, 3, 4, 5, 6, 7, 18, 19, 20, 21, 22, 23, 24];
  }
  // handleClose is called when the form is closed
  const handleClose = () => {
    if (formik.dirty && Object.keys(formik.touched)?.length !== 0) {
      setDialog(true);
    } else {
      formik.resetForm();
      setCanva(false);
    }
  };

  return (
    <div
      id="kt_collection_calendar_panel"
      className="offcanvas offcanvas-right pt-5 pb-10 offcanvas-on "
    >
      {/*begin::Header*/}
      <div className=" container offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
        <div className="mt-4 mr-2" style={{ top: "15px", right: "10px" }}>
          <h2>
            {canva?.title === "blocking"
              ? "Block"
              : capitalizeFirstLetter(canva.title)}
          </h2>
        </div>
        <div
          className="offcanvas-close  pr-5"
          style={{ top: "15px", right: "10px" }}
        >
          <button
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            type="button"
            onClick={
              () => handleClose()
              // setCanva({ type: false, title: "" })
            }
          >
            <i className="ki ki-close icon-xs text-muted"></i>
            <div id="kt_collection_calendar_panel_close"></div>
          </button>
        </div>
      </div>

      {/*begin::Content*/}
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <div className="offcanvas-content date-picker">
          <div
            id="kt_quick_panel_logs_kt_collection_calendar_panel"
            className={` fade pt-3 pr-5 mr-n5 mt-8  scroll active show ps--active-y `}
          >
            <div className="row mt-10">
              {formik.values.depot_id === "" && (canva.check === "van" || !canva?.check)  && (
                <div className="col-md-12 mb-5">
                  <label>{intl.formatMessage({ id: "Van" })}</label>
                  {/* contact owner selection field */}
                  <FormControl fullWidth>
                    <Select
                      name="van_id"
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                      className={`form-control  ${getInputClasses(
                        formik,
                        "van_id"
                      )}`}
                      IconComponent={(props) => (
                        <i {...props}>
                          <KeyboardArrowDownIcon />
                        </i>
                      )}
                      inputProps={{ "aria-label": "Without label" }}
                      displayEmpty
                      {...formik.getFieldProps("van_id")}
                    >
                      <MenuItem value="">
                        {intl.formatMessage({ id: "Select.Van" })}
                      </MenuItem>
                      {activeVan && activeVan?.length > 0
                        ? activeVan.map((item, index) => (
                            <MenuItem value={item?.id} key={index}>
                              {item?.van_name || ""}
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                    {formik.touched.van_id && formik.errors.van_id ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.van_id}
                        </div>
                      </div>
                    ) : null}
                  </FormControl>
                </div>
              )}
              {canva?.title === "blocking" &&
                canva.check === "warehouse" &&
                formik.values.van_id === "" && (
                  <div className="col-md-12 mb-5">
                    <label>{intl.formatMessage({ id: "Warehouse" })}</label>
                    {/* contact owner selection field */}
                    <FormControl fullWidth>
                      <Select
                        name="depot_id"
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                        className={`form-control  ${getInputClasses(
                          formik,
                          "depot_id"
                        )}`}
                        IconComponent={(props) => (
                          <i {...props}>
                            <KeyboardArrowDownIcon />
                          </i>
                        )}
                        inputProps={{ "aria-label": "Without label" }}
                        displayEmpty
                        {...formik.getFieldProps("depot_id")}
                      >
                        <MenuItem value="">
                          {intl.formatMessage({ id: "Select.Warehouse" })}
                        </MenuItem>
                        {depotFraList && depotFraList?.length > 0
                          ? depotFraList.map((item, index) => (
                              <MenuItem value={item?.depot_id} key={index}>
                                {item?.name || ""}
                              </MenuItem>
                            ))
                          : null}
                        {/* {currentState &&
                    currentState.map((data, index) => (
                      <MenuItem key={index} value={data.id}>
                        {data && data.first_name ? data.first_name : " "}{" "}
                        {data && data.last_name ? data.last_name : " "}
                      </MenuItem>
                    ))} */}
                      </Select>
                      {formik.touched.depot_id && formik.errors.depot_id ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.depot_id}
                          </div>
                        </div>
                      ) : null}
                    </FormControl>
                  </div>
                )}
              <div className="col-md-6 mb-5">
                <label>{intl.formatMessage({ id: "From.Date" })}</label>
                <InputGroup className="calendr">
                  <DatePicker
                    fullWidth
                    fixedHeight
                    className="form-control"
                    wrapperClassName="datepicker"
                    selected={Date.parse(formik?.values.blocking_date_from)}
                    startDate={Date.parse(formik?.values.blocking_date_from)}
                    // endDate={Date.parse(formik?.values.blocking_date_to)}
                    dateFormat="dd-MM-Y"
                    placeholderText="Select From Date"
                    onChange={handleSetDate}
                    minDate={new Date()}
                  />
                  <CalendarTodayIcon />
                </InputGroup>
                {formik.touched.blocking_date_from &&
                formik.errors.blocking_date_from ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.blocking_date_from}
                    </div>
                  </div>
                ) : null}
                {formik.touched.blocking_date_to &&
                !formik.errors.blocking_date_from &&
                formik.errors.blocking_date_to ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.blocking_date_to}
                    </div>
                  </div>
                ) : null}
              </div>

              <div className="col-md-6 mb-5">
                <label>{intl.formatMessage({ id: "To.Date" })}</label>
                <InputGroup className="calendr">
                  <DatePicker
                    fullWidth
                    fixedHeight
                    className="form-control"
                    wrapperClassName="datepicker"
                    dateFormat="dd-MM-Y"
                    selected={Date.parse(formik?.values.blocking_date_to)}
                    // startDate={Date.parse(formik?.values.blocking_date_from)}
                    // endDate={Date.parse(formik?.values.blocking_date_to)}
                    placeholderText="Select To Date"
                    onChange={handleToDate}
                    minDate={Date.parse(formik?.values.blocking_date_from)}
                  />
                  <CalendarTodayIcon />
                </InputGroup>
                {formik.touched.blocking_date_from &&
                formik.errors.blocking_date_from ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.blocking_date_from}
                    </div>
                  </div>
                ) : null}
                {formik.touched.blocking_date_to &&
                !formik.errors.blocking_date_from &&
                formik.errors.blocking_date_to ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.blocking_date_to}
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="col-md-6 mb-5">
                <label>{intl.formatMessage({ id: "From" })}</label>

                <div>
                  <TimePicker
                    defaultValue={moment()}
                    value={moment(
                      `${moment().format("yyyy-MM-DD")} ${
                        formik.values.time_from === ""
                          ? "00:00"
                          : formik.values.time_from
                      }`
                    )}
                    showSecond={false}
                    // open={timer}
                    // onOpen={() => setTimer(true)}
                    // onClose={() => setTimer(false)}
                    onChange={(e) =>
                      formik.setFieldValue(
                        "time_from",
                        moment(e).format("HH:mm")
                      )
                    }
                    focusOnOpen
                    clearIcon
                    disabledHours={disabledHours}
                  />
                </div>
                {formik.touched.time_from && formik.errors.time_from ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.time_from}
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="col-md-6 mb-5">
                <label>{intl.formatMessage({ id: "To" })}</label>
                <div>
                  <TimePicker
                    defaultValue={moment()}
                    value={moment(
                      `${moment().format("yyyy-MM-DD")} ${
                        formik.values.time_to === ""
                          ? "00:00"
                          : formik.values.time_to
                      }`
                    )}
                    showSecond={false}
                    // open={timer}
                    // onOpen={() => setTimer(true)}
                    // onClose={() => setTimer(false)}
                    onChange={(e) =>
                      formik.setFieldValue("time_to", moment(e).format("HH:mm"))
                    }
                    focusOnOpen
                    clearIcon
                    disabledHours={disabledHours}
                  />
                </div>
                {formik.touched.time_to && formik.errors.time_to ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.time_to}</div>
                  </div>
                ) : null}
              </div>

              {canva?.title === "blocking" ? (
                <div className="col-md-12 mb-5">
                  <Form.Group>
                    <Form.Label>Reason For Blocking</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows="3"
                      placeholder="Reason For Blocking"
                      className={` form-control  ${getInputClasses(
                        formik,
                        "reason"
                      )}`}
                      maxLength="100"
                      name="reason"
                      {...formik.getFieldProps("reason")}
                    />
                    {formik.touched.reason && formik.errors.reason ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.reason}
                        </div>
                      </div>
                    ) : null}
                  </Form.Group>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          {/*end::Content*/}
        </div>
        <div className="offcanvas-footer">
          <div className="offcanvas-content text-right ">
            <button
              type="button"
              className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
              onClick={
                () => handleClose()
                //  setCanva({ type: false, title: "" })
              }
            >
              {intl.formatMessage({ id: "CANCEL" })}
            </button>
            <button
              type="submit"
              className="btn btn-primary font-weight-bolder font-size-sm "
            >
              {intl.formatMessage({ id: "Add" })}
            </button>
          </div>
        </div>
      </form>
      <DiscardDialog
        setDialogDis={setDialog}
        dialogDis={dialog}
        formik={formik}
        setActive={setCanva}
      />
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </div>
  );
}
