/* eslint-disable no-restricted-imports */

import React, { useEffect } from "react";
import { Card, CardBody } from "../../../../_metronic/_partials/controls";
import {
  fetchAgent,
  // fetchContactCall,
  // fetchContactRevertEmail,
} from "../_redux/contact/contactAction";
import {
  fetchDealDetail,
  // fetchDealEmailList,
  // fetchDealNotesList,
  // fetchDealSmsList,
  // fetchDealTasksList,
  fetchDealfeedList,
  fetchQuotes,
} from "../_redux/deals/dealsAction";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import AdjustIcon from "@material-ui/icons/Adjust";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import DealsTable from "../Table/DealTable";
import { DealsTabs } from "../components/DealsTabs";
import { DealsTop } from "../components/DealsTop";
import { DealsUsers } from "../components/DealsUsers";
import NoRecord from "../../../../_metronic/_partials/widgets/NoRecord";
import PropTypes from "prop-types";
import SnackBarTool from "../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import Step from "@material-ui/core/Step";
import StepConnector from "@material-ui/core/StepConnector";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import clsx from "clsx";
import { dealStatus } from "../_redux/deals/dealsCrud";
import { initIds } from "../../../../_metronic/_partials/controls/RightPanel";
import { useIntl } from "react-intl";
import { useSubheader } from "../../../../_metronic/layout";

export const Deals = (props) => {
  const intl = useIntl();
  const suhbeader = useSubheader();
  suhbeader.setTitle(intl.formatMessage({ id: "DEALS" }));

  const dispatch = useDispatch();

  function getSteps() {
    return [
      intl.formatMessage({ id: "Open" }),
      intl.formatMessage({ id: "Transit" }),
      intl.formatMessage({ id: "Pipeline" }),
      intl.formatMessage({ id: "Close-Won" }),
      intl.formatMessage({ id: "Close-Lost" }),
    ];
  }

  const [activeStep, setActiveStep] = React.useState(0);
  const [borderColor, setBorderColor] = React.useState();
  const [activeTab, setActiveTab] = React.useState("feeds");
  const [snack, setSnack] = React.useState({
    call: false,
    type: "",
    message: "",
  });

  const steps = getSteps();

  const QontoConnector = withStyles({
    alternativeLabel: {
      top: 10,
      left: "calc(-50% + 16px)",
      right: "calc(50% + 16px)",
      color: "red",
    },
    active: {
      "& $line": {
        // borderColor: "#fda168",
        borderColor:
          borderColor === "red"
            ? "red !important"
            : borderColor === "green"
            ? "green !important"
            : "#fda168",
      },
    },
    completed: {
      "& $line": {
        borderColor:
          borderColor === "red"
            ? "red !important"
            : borderColor === "green"
            ? "green !important"
            : "#fda168",
      },
    },
    line: {
      borderColor: "#eaeaf0",
      borderTopWidth: 3,
      borderRadius: 1,
    },
  })(StepConnector);

  const handleNext = (index, step) => {
    setSnack({ call: false, type: "", message: "" });

    const data = {
      id: props?.match?.params?.id || props?.ids,
      status:
        index === 0
          ? "Open"
          : index === 1
          ? "Transit"
          : index === 2
          ? "Pipeline"
          : index === 3
          ? "Close-Won"
          : "Close-Lost",
    };
    dealStatus(data)
      .then((res) => {
        setSnack({ call: true, type: "success", message: res.data.message });
        dispatch(fetchDealDetail(props?.match?.params?.id || props?.ids));
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
    setActiveStep(index);
  };

  const useQontoStepIconStyles = makeStyles({
    root: {
      color: "#eaeaf0",
      display: "flex",
      height: 22,
      alignItems: "center",
    },
    active: {
      color: "#784af4",
    },
    circle: {
      width: 8,
      height: 8,
      borderRadius: "50%",
      backgroundColor: "currentColor",
    },
    completed: {
      // color: "#ff6601 !important",
      color:
        borderColor === "red"
          ? "red !important"
          : borderColor === "green"
          ? "green !important"
          : "#ff6601",
      zIndex: 1,
      fontSize: 18,
    },
  });

  function QontoStepIcon(props, index) {
    const classes = useQontoStepIconStyles();
    const { active, completed } = props;
    if (props.icon === 4 && props.active && index === 3) {
      setBorderColor("green");
    } else if (props.icon === 5 && props.active && index === 4) {
      setBorderColor("red");
    } else if (
      props.icon !== 4 &&
      props.active &&
      props.icon !== 5 &&
      props.active
    ) {
      setBorderColor("redy");
    }

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
        })}
      >
        {active && props.icon === 5 ? (
          <CancelIcon
            className={classes.completed}
            style={{ color: "#ff6601 !important", fontSize: "3rem" }}
          />
        ) : active ? (
          <CheckCircleIcon
            className={classes.completed}
            style={{ color: "#ff6601 !important", fontSize: "3rem" }}
          />
        ) : (
          <AdjustIcon
            // className={classes.completed}
            style={{
              color: `${
                completed && borderColor === "green"
                  ? "green "
                  : completed && borderColor === "red"
                  ? "red"
                  : completed
                  ? "#ff6601"
                  : "grey"
              }`,
              fontSize: "2rem",
            }}
          />
        )}
      </div>
    );
  }

  QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
  };
  const {
    dealDetail,
    feedList,
    notesList,
    agentList,
    tasksList,
    emailsList,
    smsList,
    quotesList,
    revertEmail,
    callList,
    permission,
  } = useSelector(
    (state) => ({
      dealDetail: state.deals_list.dealDetail,
      feedList: state.deals_list.feedList,
      notesList: state.deals_list.notesList,
      agentList: state.contact.agent,
      tasksList: state.deals_list.tasksList,
      emailsList: state.deals_list.emailsList,
      smsList: state.deals_list.smsList,
      quotesList: state.deals_list.quotesList,
      revertEmail: state.contact.revertEmail,
      callList: state.contact.callList,
      permission: state.auth.permission,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (props?.match?.params?.id || props?.ids) {
      dispatch(fetchDealDetail(props?.match?.params?.id || props?.ids));
      dispatch(fetchQuotes(props?.match?.params?.id || props?.ids));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, props?.match?.params?.id]);

  useEffect(() => {
    if (dealDetail?.contact_id || props?.ids) {
      dispatch(fetchAgent());
      // dispatch(
      //   fetchDealNotesList(
      //     props?.match?.params?.id || props.ids,
      //     dealDetail?.contact_id || props.contactIds
      //   )
      // );
      // dispatch(
      //   fetchDealTasksList(
      //     props?.match?.params?.id || props.ids,
      //     dealDetail?.contact_id || props.contactIds
      //   )
      // );
      // dispatch(
      //   fetchDealEmailList(
      //     props?.match?.params?.id || props.ids,
      //     dealDetail?.contact_id || props.contactIds
      //   )
      // );
      // dispatch(
      //   fetchDealSmsList(
      //     props?.match?.params?.id || props.ids,
      //     dealDetail?.contact_id || props.contactIds
      //   )
      // );
      // dispatch(
      //   fetchContactRevertEmail(dealDetail?.contact_id || props.contactIds)
      // );
      dispatch(
        fetchDealfeedList(
          dealDetail?.contact_id || props.contactIds,
          props?.match?.params?.id || props.ids
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealDetail?.contact_id, dispatch, props?.match?.params?.id]);

  // useEffect(() => {
  //   if (
  //     dealDetail &&
  //     dealDetail.contact_data &&
  //     dealDetail.contact_data.phone &&
  //     dealDetail.contact_data.phone.length &&
  //     dealDetail.contact_data.phone[0]
  //   ) {
  //     dispatch(fetchContactCall(dealDetail.contact_data.phone[0]));
  //   }
  // }, [dealDetail, dispatch]);

  useEffect(() => {
    if (dealDetail?.deal_status) {
      setActiveStep(
        dealDetail?.deal_status === "Open"
          ? 0
          : dealDetail?.deal_status === "Transit"
          ? 1
          : dealDetail?.deal_status === "Pipeline"
          ? 2
          : dealDetail?.deal_status === "Close-Won"
          ? 3
          : 4
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealDetail?.deal_status]);

  const init = ["kt_quick_panelone"];

  useEffect(() => {
    initIds(init);
  }, [init]);

  return (
    <>
      <DealsTop
        setActiveTab={setActiveTab}
        dealDetail={dealDetail}
        ids={props?.ids}
      />
      <div
        className="steaperui"
        style={{
          pointerEvents:
            permission?.deal?.create || permission?.deal?.edit ? "" : "none",
        }}
      >
        <Stepper
          className="mt-4"
          alternativeLabel
          activeStep={activeStep}
          connector={<QontoConnector />}
        >
          {steps.map((label, index) => (
            <Step key={label} onClick={() => handleNext(index, steps)}>
              <StepLabel
                StepIconComponent={(props) => QontoStepIcon(props, index)}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>

      <div className="row mt-5 dealuseer">
        <div
          className={`col-md-12  ${
            props.type === "customer" ? "" : "col-lg-4"
          }  gutter-c`}
        >
          <DealsUsers dealDetail={dealDetail} />
        </div>
        <div
          className={`col-md-12 ${
            props.type === "customer" ? "" : "col-lg-8"
          }  mb-7`}
        >
          <DealsTabs
            feedList={feedList}
            dealDetail={dealDetail}
            notesList={notesList}
            agentList={agentList}
            tasksList={tasksList}
            emailsList={emailsList}
            smsList={smsList}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            revertEmail={revertEmail}
            callList={callList}
            permission={permission}
            apiCallDeal={dealDetail?.contact_id || props.contactIds}
            apiCall={props?.match?.params?.id || props.ids}
          />
        </div>
      </div>
      {props?.from_task !== true ? (
        <div className=" order-2 order-xxl-1 mt-5">
          <Card>
            <CardBody>
              <h3 className="card-title align-items-start flex-column">
                <span className="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary">
                  {intl.formatMessage({ id: "Quotes" })}
                </span>
              </h3>
              {quotesList &&
              quotesList.entities &&
              quotesList.entities.length ? (
                <DealsTable
                  checkbox={true}
                  dealList={quotesList}
                  type="Deal"
                  isDeal={props?.isDeal || ""}
                />
              ) : (
                <NoRecord />
              )}
            </CardBody>
          </Card>
        </div>
      ) : (
        ""
      )}

      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </>
  );
};
