/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */

import * as Yup from "yup";

import React, { useEffect, useState } from "react";

import { AddNotes } from "./AddNotes";
import { Notes } from "./Notes";
import SnackBarTool from "../../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { notesSchema } from "../../../form/validationSchema";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { useIntl } from "react-intl";

export function NotesWidget({
  ID,
  dealId,
  type,
  notesList,
  submitFunction,
  listFunction,
  activeTab,
  permission,
}) {
  // Use the useDispatch hook to access the dispatch function
  const dispatch = useDispatch();
  const intl = useIntl();

  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [loading, setLoading] = useState(false);

  // Initial values for the form
  const initialValues = {
    contact_id: ID ? ID : "",
    note: "",
  };

  const initialDeals = {
    contact_id: ID ? ID : "",
    deal_id: dealId ? dealId : "",
    note: "",
  };

  // Function to handle form submission
  const handleSubmit = (values, { resetForm }) => {
    setSnack({ call: false, type: "", message: "" });
    setLoading(true);
    submitFunction(values)
      .then((res) => {
        setSnack({ call: true, type: "success", message: res.data.message });
        setTimeout(() => {
          if (type === "Deal") {
            dispatch(listFunction(values.deal_id, values.contact_id));
          } else {
            dispatch(listFunction(values.contact_id));
          }
        }, 2000);
        resetForm();
        setLoading(false);
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",

          error: error.response.data.errors,
        });
        setLoading(false);
      });
  };

  // Function to handle form reset
  const handleReset = () => {};

  // Use the useFormik hook to handle form state
  const formik = useFormik({
    initialValues: type === "Deal" ? initialDeals : initialValues,
    enableReinitialize: true,
    validationSchema: notesSchema(intl, Yup),
    onSubmit: handleSubmit,
    onReset: handleReset,
  });

  // Reset form when active tab changes
  useEffect(() => {
    if (activeTab === "notes") {
      formik.resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  // JSX to render form
  return (
    <>
      <div className="mt-10">
        <div>
          <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
            {permission ? <AddNotes formik={formik} loading={loading} /> : ""}
          </form>
        </div>
        {notesList && notesList?.length ? (
          notesList.map((data, index) => (
            <div key={index}>
              <Notes data={data} />
            </div>
          ))
        ) : (
          <div style={{ textAlign: "center" }}></div>
        )}
      </div>
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </>
  );
}
