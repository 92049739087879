/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */

import React from "react";
import SVG from "react-inlinesvg";
import { contactActionarray } from "../ContactUIHelpers";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export function ContactActionDropDown({ setActiveTab, Dropdown, setCanva }) {
  return (
    <>
      {/*begin::Navigation*/}
      <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
        {contactActionarray.map((item, index) => (
          <Dropdown.Item onClick={() => setActiveTab(item.id)} key={index}>
            <span className="navi-link">
              <span className="navi-icon">
                <span className="svg-icon svg-icon-sm svg-icon-light-dark">
                  <SVG
                    title=" "
                    className="h-25 align-self-end"
                    src={toAbsoluteUrl(item.img)}
                  ></SVG>
                </span>
              </span>
              <span className="navi-text">{item.title}</span>
            </span>
          </Dropdown.Item>
        ))}
        {/* <Dropdown.Item onClick={() => setCanva({ type: true })}>
          <span className="navi-link">
            <span className="navi-icon">
              <span className="svg-icon svg-icon-sm svg-icon-light-dark">
                <SVG
                  title=" "
                  className="h-25 align-self-end"
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Chat5.svg"
                  )}
                ></SVG>
              </span>
            </span>
            <span className="navi-text">Merge Contact</span>
          </span>
        </Dropdown.Item> */}
      </Dropdown.Menu>
      {/*end::Navigation*/}
    </>
  );
}
