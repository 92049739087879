import { Card, CardBody } from "../../../../_metronic/_partials/controls";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { ContactFilter } from "../Filter/ContactFilter";
import ContactTable from "../Table/ContactTable";
import { CreateContactOffCanvas } from "../canvas/CreateContactOffCanvas";
import DeleteDialog from "../../../../_metronic/_partials/widgets/alert/DeleteDialog";
import NotAuthorized from "../../../../_metronic/_partials/widgets/NotAuthorized";
import SnackBarTool from "../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { deleteContact } from "../_redux/contact/contactCrud";
import { fetchContact } from "../_redux/contact/contactAction";
import { fetchPermission } from "../../../pages/redux/permission";
import { initIds } from "../../../../_metronic/_partials/controls/RightPanel";
import { useContactUIContext } from "../ContactUIContext";
import { useIntl } from "react-intl";
import { useSubheader } from "../../../../_metronic/layout";

export const ContactPage = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const contactUIContext = useContactUIContext();

  const suhbeader = useSubheader();
  suhbeader.setTitle("Contacts");

  const init = ["kt_quick_panelone"];

  const [dialog, setDialog] = useState(false);
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [openCanva, setOpenCanva] = useState(false);

  const handleDelete = () => {
    setSnack({ call: false, type: "", message: "" });
    deleteContact({ contact_id: contactUIContext.ids })
      .then((res) => {
        setSnack({ call: true, type: "success", message: res.data.message });
        contactUIContext.setIds([]);
        dispatch(fetchContact(contactUIContext.queryParams));
        setDialog(false);
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
        setDialog(false);
      });
  };

  useEffect(() => {
    initIds(init);
  }, [init]);

  const { permission } = useSelector(
    (state) => ({
      permission: state.auth.permission,
    }),
    shallowEqual
  );
  useEffect(() => {
    dispatch(fetchPermission());
  }, [dispatch]);

  return (
    <>
      <div className="text-right mb-5">
        {permission?.contact?.delete ? (
          <button
            className="btn btn-primary mr-2"
            type="button"
            onClick={() => setDialog(true)}
            disabled={contactUIContext.ids.length === 0}
          >
            Delete
          </button>
        ) : (
          ""
        )}
        <button
          id="kt_assign_panel_toggle"
          className="btn btn-primary mr-3"
          type="button"
          disabled={contactUIContext.ids.length > 0 ? false : true}
        >
          {intl.formatMessage({ id: "Assign.Selected" })}
        </button>
        {permission?.contact?.create ? (
          <button
            className="btn btn-primary "
            type="button"
            onClick={() => setOpenCanva(true)}
          >
            {intl.formatMessage({ id: "Create.Contact" })}
          </button>
        ) : (
          ""
        )}
      </div>
      <Card>
        <CardBody>
          {permission?.contact?.view ? (
            <>
              <ContactFilter />
              <ContactTable />
            </>
          ) : (
            <NotAuthorized />
          )}
        </CardBody>
      </Card>
      {openCanva ? (
        <>
          <CreateContactOffCanvas
            title={intl.formatMessage({ id: "Create.Contact" })}
            setOpenCanva={setOpenCanva}
          />
          <div
            className="offcanvas-overlay"
            onClick={() => setOpenCanva(false)}
          ></div>
        </>
      ) : (
        ""
      )}
      <DeleteDialog
        dialog={dialog}
        setDialog={setDialog}
        handleDelete={handleDelete}
        title={"Contact"}
      />
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </>
  );
};
