import React, { useEffect, useState, useRef } from "react";
import {
  // fetchBillingCardDetail,
  // fetchCardDetail,
  fetchDriverLoader,
  fetchMyOrderDetailCard,
  fetchMyOrderTimeline,
  fetchNewFlags,
  fetchOrderInvoice,
  fetchPaymentInvoice,
  fetchSavedDriverLoader,
  fetchTransferTable,
} from "./_redux/order/orderAction";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { AddEditStorage } from "./order-canva/AddEditStorage";
import { CustomerAttachment } from "../../../_metronic/layout/components/extras/offcanvas/Customers/CustomerAttachment";
import { CustomerBilling } from "./components/CustomerBillingComponents/CustomerBilling";
import { CustomerDetailEdit } from "./order-canva/CustomerDetailEdit";
import { CustomerOrderSummary } from "./components/CustomerOrderSummary";
import { DriverNotes } from "./order-canva/DriverNotes";
import { Feeds } from "../../../_metronic/_partials/widgets";
import { LoadingCustome } from "../../../_metronic/_partials/controls/LoadingCustome";
import { OrderCardWidget } from "./components/OrderCardWidget";
import { OrderCollection } from "./components/OrderCollection";
import OrderDetailDistance from "./components/OrderDetailDistance";
import { OrderDetailNotes } from "./components/OrderDetailNotes";
import { OrderInfo } from "../../../_metronic/_partials/widgets/order/OrderInfo";
import { OrderNotes } from "./order-canva/OrderNotes";
import { Payments } from "../../../_metronic/_partials/widgets/lists/Payments";
import SnackBarTool from "../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { StorageDetail } from "./order-canva/StorageDetail";
import { useLocation } from "react-router-dom";
import { getHasArrear, getScheduleChanges } from "./_redux/order/orderCrud";
import { OrderContainerCollection } from "./components/OrderContainerCollection";
import TransferTable from "./components/TransferTable";
// import { abortApi } from "../../utils/commonFunction";

export const OrdersDetails = (props) => {
  const controllerRef = useRef(new AbortController());
  const location = useLocation();
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [keyDis, setKeyDis] = useState("collection");
  const [scheduleData, setScheduleData] = useState(null);
  const [pagination, setPagination] = useState({starting_after:"", limit:20, currentPage: 1, ending_before:"",});
  const [hasArrear, setHasArrear] = useState(null);


  const dispatch = useDispatch();
  const {
    orderDetail,
    timeline,
    cardDetailArray,
    billingCard,
    paymnetInvoiceList,
    editOrderDetail,
    orderInvoice,
    orderInvoiceLoading,
    driverLoader,
    detailDriverLoader,
    cardDetailCheck,
    transferList,traLoading
  } = useSelector(
    (state) => ({
      orderDetail: state.order.orderDetail,
      editOrderDetail: state.order.editOrderTable,
      timeline: state.order.timeline,
      cardDetailArray: state.order.cardDetailArray,
      cardDetailCheck: state.order.cardDetailCheck,
      billingCard: state.order.billingCard,
      paymnetInvoiceList: state.order.paymentInvoiceList,
      orderInvoice: state.order.orderInvoice.data,
      orderInvoiceLoading: state.order.orderInvoice.loading,
      driverLoader: state.order.driverLoader,
      detailDriverLoader: state.order.savedDriverLoader,
      transferList:state.order.transferList,
      traLoading:state.order.traLoading
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(fetchMyOrderDetailCard(props?.match?.params?.id));
    dispatch(fetchNewFlags(props?.match?.params?.id));
    // dispatch(fetchOrderSummaryList(props?.match?.params?.id, ""));
    dispatch(fetchMyOrderTimeline(props?.match?.params?.id));
    // dispatch(fetchPaymentInvoice(props?.match?.params?.id));
    dispatch(fetchOrderInvoice(props?.match?.params?.id, controllerRef.current))
      .then(() => {})
      .catch((error) =>
        setSnack({
          call: true,
          type: "error",
          message: error?.response?.data?.message,
          error: error?.response?.data?.errors,
        })
      );
    dispatch(fetchSavedDriverLoader(props?.match?.params?.id));
  }, [dispatch, props.match.params.id]);

  useEffect(() => {
    const orderSub =
      orderDetail?.subscription_id || orderDetail?.subscription_scheduled_id;
    const is_subscription_id = orderDetail?.subscription_id ? 1 : "0";
  if(orderDetail.franchise_id){
    dispatch(
      fetchPaymentInvoice(
        props?.match?.params?.id,
        orderDetail?.franchise_id,
        orderSub,
        is_subscription_id,
        orderDetail?.invoice_id
      )
    );
  }
    if (
      orderDetail?.subscription_id ||
      orderDetail?.subscription_scheduled_id
    ) {
      dispatch(fetchDriverLoader(orderDetail?.franchise_id));
      getScheduleChanges(orderDetail?.franchise_id, orderSub)
        .then((res) => setScheduleData(res.data.data))
        .catch((error) => setScheduleData(null));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    orderDetail?.subscription_items?.next_billing_at,
    orderDetail?.customer_chargebee_id,
  ]);

  console.log("orderDetail-123", transferList);
  

  useEffect(() => {
  if(orderDetail?.customer_chargebee_id){
    const data ={
      customer_id:orderDetail?.customer_chargebee_id,
      starting_after:pagination.starting_after,
      ending_before: pagination.ending_before,
      limit:pagination.limit
    }
    dispatch(fetchTransferTable(data));
  }
}, [orderDetail?.customer_chargebee_id, pagination])

  useEffect(() => {
    const abortController = controllerRef.current;
    return () => {
      abortController.abort();
    };
  }, []);
  const [callApiHas, setcallApiHas]= useState(true)
  useEffect(() => {
    if (orderDetail?.customer_old_chargebee_id&& callApiHas) {
      getHasArrear(orderDetail?.customer_old_chargebee_id).then((res) => {
        setHasArrear(res.data.data);
        setcallApiHas(false)
        console.log("helloop", res.data.data);
      });
    }
  }, [orderDetail?.customer_old_chargebee_id, callApiHas]);
  return (
    <>
      <div className="mt-5">
        <OrderInfo orderDetail={orderDetail} location={location} />
      </div>
      <div className="row">
        <div className="col-lg-6">
          <CustomerOrderSummary
            orderDetail={orderDetail}
            editOrderDetail={editOrderDetail}
            orderInvoice={orderInvoice}
            orderInvoiceLoading={orderInvoiceLoading}
            scheduleData={scheduleData}
            setScheduleData={setScheduleData}
          />
        </div>
        <div className="col-lg-6">
          {orderDetail?.is_container === 1 ? (
            <OrderContainerCollection orderDetail={orderDetail} />
          ) : (
            <div className="customercoll">
              <OrderCollection
                orderDetail={orderDetail}
                driverLoader={driverLoader}
                detailDriverLoader={detailDriverLoader}
                orderInvoice={orderInvoice}
                billingCard={billingCard}
                hasArrear={hasArrear}
              />
            </div>
          )}
          <div>
            <OrderDetailDistance
              orderDetail={orderDetail}
              type="order"
              keyDis={keyDis}
              setKeyDis={setKeyDis}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-4 col-md-12">
          <div className="customerbilling">
            <CustomerBilling
              billingCard={billingCard}
              paymentInvoice={paymnetInvoiceList}
              orderDetail={orderDetail}
              orderInvoice={orderInvoice}
              cardDetailArray={cardDetailArray}
              hasArrear={hasArrear}
              setcallApiHas={setcallApiHas}
            />
          </div>
          <div className="customercarddtl">
            <OrderCardWidget
              cardDetailArray={cardDetailArray}
              cardDetailCheck={cardDetailCheck}
              orderDetail={orderDetail}
            />
          </div>
        </div>
        <div className="col-xl-4 col-md-12 ">
          <Payments
            className="card-stretchs gutter-b"
            paymnetInvoiceList={paymnetInvoiceList}
          />
        </div>
        <div className="col-xl-4 col-md-12 ">
          <Feeds
            classNameSend="card-stretchs"
            data={timeline}
            type={"customer"}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <OrderDetailNotes orderDetail={orderDetail} title={"Driver Note"} />
        </div>
        <div className="col-6">
          <OrderDetailNotes
            title={"Driver Note from Mavis"}
            type={"note"}
            orderDetail={orderDetail}
          />
        </div>
      </div>
      <div>
      <TransferTable transferList={transferList} setPagination={setPagination} pagination={pagination} traLoading={traLoading}/>
      </div>

      <CustomerDetailEdit />

      <StorageDetail orderDetail={orderDetail} />

      <OrderNotes />
      <DriverNotes />

      <CustomerAttachment />
      <AddEditStorage />

      {orderDetail?.id ? "" : <LoadingCustome />}
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </>
  );
};
