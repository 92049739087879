import axios from "axios";
export const COUNTRY = "countries";
export const ADD_COUNTRY = "country";
export const DELETE = "delete/country";

//api for active country
export function findActiveCountry() {
  return axios.get(process.env.REACT_APP_SITE_URL + COUNTRY);
}

//api for all type of country (active or deactive)
export function findCountryList(queryParams) {
  return axios.get(
    process.env.REACT_APP_SITE_URL +
      ADD_COUNTRY +
      `?page=${queryParams.pageNumber}&per_page=${queryParams.pageSize}&sort_by=${queryParams.sortField}&sort_dir=${queryParams.sortOrder}&search_keyword=${queryParams.filter.search}`
  );
}

//api for country detail
export function findCountryDetail(id) {
  return axios.get(process.env.REACT_APP_SITE_URL + ADD_COUNTRY + `/${id}`);
}

//api for adding country in db
export function addCountry(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + ADD_COUNTRY, data);
}

//api for updating the country data
export function editCountry(data, id) {
  return axios.post(
    process.env.REACT_APP_SITE_URL + ADD_COUNTRY + `/${id}?_method=PATCH`,
    data
  );
}
//api for country deletion
export function deleteCountry(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + DELETE, data);
}
