/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import { useFormik } from "formik";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import SnackBarTool from "../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import {
  fetchMyOrderDetailCard,
  fetchMyOrderTimeline,
} from "../_redux/order/orderAction";
import { orderDetailUpdate } from "../_redux/order/orderCrud";
import { useDispatch } from "react-redux";
import { canvaCancel } from "../../../utils/canvaCloseFunction";
import { DriverSchema } from "../order-form/validationSchema";
import DiscardDialog from "../../../../_metronic/_partials/widgets/alert/DiscardDialog";

export function EditOrderNotes({ orderDetail }) {
  const dispatch = useDispatch();

  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [dialogDis, setDialogDis] = useState(false);

  const initialValues = {
    order_id: orderDetail?.id || "",
    driver_note: orderDetail?.driver_note || "",
  };

  const handleSubmit = (values, actions) => {
    setSnack({ call: false, type: "", message: "" });
    orderDetailUpdate(values)
      .then((res) => {
        dispatch(fetchMyOrderDetailCard(values.order_id));
        dispatch(fetchMyOrderTimeline(values.order_id));
        setSnack({
          call: true,
          type: "success",
          message: res?.data?.message || "",
        });
        canvaCancel("kt_EditOrderNotes_panel_close");
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",
          message: error?.response?.data?.message || "",
        });
      });
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: DriverSchema,
    onSubmit: handleSubmit,
  });

  const handleClose = () => {
    if (formik.dirty && Object.keys(formik.touched).length !== 0) {
      setDialogDis(true);
    } else {
      formik.setFieldValue("driver_note", formik.initialValues.driver_note);
      canvaCancel("kt_EditOrderNotes_panel_close");
      formik.resetForm();
    }
  };

  return (
    <div
      id="kt_EditOrderNotes_panel"
      className="offcanvas offcanvas-right pt-5 pb-10"
    >
      {/*begin::Header*/}
      <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
        <div
          className="offcanvas-close mt-n1 pr-5"
          style={{ position: "absolute", top: "15px", right: "10px" }}
        >
          <span
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            onClick={handleClose}
          >
            <i className="ki ki-close icon-sm text-muted"></i>
          </span>
          <div id="kt_EditOrderNotes_panel_close"></div>
        </div>
      </div>
      {/*end::Header*/}
      <form onSubmit={formik.handleSubmit}>
        <div className="offcanvas-content px-10 ">
          <div>
            <h5>Edit Note </h5>
          </div>
          <div
            className="fade pt-3 pr-5 mr-n5 scroll active show ps--active-y mt-5"
            id="kt_quick_panel_logs_kt_EditOrderNotes_panel"
          >
            <div>
              {" "}
              <Form.Control
                as="textarea"
                rows="6"
                className={`form-control  ${getInputClasses("driver_note")}`}
                {...formik.getFieldProps("driver_note")}
              />
              {formik.touched.driver_note && formik.errors.driver_note ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.driver_note}
                  </div>
                </div>
              ) : null}
            </div>

            <div className="row"></div>
          </div>
        </div>
        <div className="offcanvas-footer">
          <div className="offcanvas-content text-right ">
            <button
              type="button"
              className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary font-weight-bolder font-size-sm "
            >
              Save
            </button>
          </div>
        </div>
      </form>
      <DiscardDialog
        setDialogDis={setDialogDis}
        dialogDis={dialogDis}
        formik={formik}
        closeId={"kt_EditOrderNotes_panel_close"}
      />
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </div>
  );
}
