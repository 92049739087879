/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

import * as Yup from "yup";

import { FormControl, MenuItem, Select } from "@material-ui/core/";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { depotOpenHours } from "../../../__mocks__/dummyProductData";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import ActiveDialogModal from "../../../../../../_metronic/_partials/widgets/alert/ActiveDialogModal";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { NewDepotSchema } from "../depot-form/validationSchema";
import SnackBarTool from "../../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { canvaCancel } from "../../../../../utils/canvaCloseFunction";
import { createDepot } from "../../../_redux/depots/depotsCrud";
import { fetchActiveCountry } from "../../../_redux/country/countryAction";
import { fetchProducts } from "../../../_redux/depots/deoptsActions";
import { getInputClasses } from "../../../../../utils/commonFunction";
import { initialFilter } from "../DepotsUIHelpers";
import { useFormik } from "formik";
import { useIntl } from "react-intl";
import CountryPhoneNumber from "../../../../../../_metronic/layout/components/CountryPhoneNumber";

export function NewDepotCanvas(props) {
  // Dispatch and selector hooks
  const dispatch = useDispatch();
  const intl = useIntl();
  const ref = useRef();
  const { detailData, currentState } = useSelector(
    (state) => ({
      detailData: state.country.activeCountry,
      currentState: state.depot.depotCount,
    }),
    shallowEqual
  );
  // State variables for active, dialog, snackbar, loading, and formik ref
  const [active, setActive] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [loading, setLoading] = useState(false);

  // Initial form values
  const initialValues = {
    status: 0,
    name: "",
    short_name: "",
    depot_reference_id: "",
    address_line_1: "",
    address_line_2: "",
    address_line_3: "",
    city: "",
    postcode: "",
    country: "",
    latitude: "",
    longitude: "",
    manager_name: "",
    manager_email: "",
    additional_emails: "",
    phone: "",
    depot_hours: [
      { day: "1", open_time: "", close_time: "" },
      { day: "2", open_time: "", close_time: "" },
      { day: "3", open_time: "", close_time: "" },
      { day: "4", open_time: "", close_time: "" },
      { day: "5", open_time: "", close_time: "" },
      { day: "6", open_time: "", close_time: "" },
      { day: "7", open_time: "", close_time: "" },
    ],
  };

  // Handles form submission
  const handleSubmit = (values) => {
    setLoading(true);
    setSnack({ call: false, type: "", message: "" });
    createDepot(values)
      .then((res) => {
        setSnack({ call: true, type: "success", message: res.data.message });
        dispatch(fetchProducts(initialFilter));
        setLoading(false);
        formik.resetForm();
        canvaCancel("kt_new_depot_panel_close");
      })
      .catch((error) => {
        setLoading(false);
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
  };

  // Formik hook for handling form validation and submission
  const formik = useFormik({
    initialValues,
    validationSchema: NewDepotSchema(intl, Yup),
    onSubmit: handleSubmit,
  });

  // Handles updating the depot reference id
  const handleUpdateField = useCallback(() => {
    formik.setFieldValue(
      "depot_reference_id",
      String(currentState.totalCount + 1)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentState.totalCount]);

  useEffect(() => {
    if (formik?.values?.short_name) {
      handleUpdateField();
    }
  }, [currentState.totalCount, formik.values.short_name, handleUpdateField]);

  const handleHours = (e, type, target) => {
    const arr = [...formik.values.depot_hours];

    if (arr.length > 0) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].day === type) {
          if (target === "open_time") {
            if (!e.target.value) {
              arr[i].open_time = e.target.value;
              arr[i].close_time = e.target.value;
            } else {
              arr[i].open_time = e.target.value;
            }
            arr[i].open_time = e.target.value;
          } else {
            arr[i].close_time = e.target.value;
          }
          formik.setFieldValue("depot_hours", arr);
          return;
        }
      }
    }
    let tempObj = {
      day: type,
      open_time: target === "open_time" ? e.target.value : "",
      close_time: target === "close_time" ? e.target.value : "",
    };
    arr.push(tempObj);

    formik.setFieldValue("depot_hours", arr);
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (e.target.className === "offcanvas-overlay") {
        formik.resetForm();
      }
    };

    document.addEventListener("click", checkIfClickedOutside, true);
    return () => {
      document.removeEventListener("click", checkIfClickedOutside, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(fetchActiveCountry());
  }, [dispatch]);

  return (
    <>
      <div
        id="kt_new_depot_panel"
        className={`offcanvas offcanvas-right offcanvas-rightss pt-5 pb-10 ${
          props.check ? "offcanvas-on" : ""
        }`}
        ref={ref}
      >
        {/*begin::Header*/}
        <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
          <div
            className="offcanvas-close mt-n1  pr-5"
            style={{ position: "absolute", top: "15px", right: "10px" }}
          >
            <span
              className="btn btn-xs btn-icon btn-light btn-hover-primary"
              id="kt_new_depot_panel_close"
              onClick={() => formik.resetForm()}
            >
              <i className="ki ki-close icon-xs text-muted"></i>
            </span>
          </div>
        </div>

        {/*end::Header*/}

        {/*begin::Content*/}
        <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
          <div className=" px-10">
            <div>
              <h5>{props.title}</h5>
            </div>
            <div className="tab-content">
              <div
                id="kt_quick_panel_logs_kt_new_depot_panel"
                className=" fade pt-3 pr-5 mr-n5 scroll  active show "
              >
                <div className="row">
                  <div className="col-sm-12 col-md-4">
                    <div className="form-groupB">
                      <label>{intl.formatMessage({ id: "Depot.Name" })}</label>
                      <input
                        type="text"
                        maxLength={"30"}
                        className={`form-control  ${getInputClasses(
                          formik,
                          "name"
                        )}`}
                        placeholder={intl.formatMessage({ id: "Depot.Name" })}
                        name="name"
                        {...formik.getFieldProps("name")}
                      />
                      {formik.touched.name && formik.errors.name ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.name}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <div className="form-groupB">
                      <label>{intl.formatMessage({ id: "Depot.Short" })}</label>
                      <input
                        type="text"
                        maxLength={"30"}
                        className={`form-control  ${getInputClasses(
                          formik,
                          "short_name"
                        )}`}
                        placeholder={intl.formatMessage({ id: "Depot.Short" })}
                        name="short_name"
                        {...formik.getFieldProps("short_name")}
                      />
                      {formik.touched.short_name && formik.errors.short_name ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.short_name}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <div className="form-groupB">
                      <label>{intl.formatMessage({ id: "Depot.ID" })}</label>
                      <input
                        type="text"
                        className={`form-control  ${getInputClasses(
                          formik,
                          "depot_reference_id"
                        )}`}
                        placeholder={intl.formatMessage({ id: "Depot.ID" })}
                        name="depot_reference_id"
                        {...formik.getFieldProps("depot_reference_id")}
                        disabled
                      />
                      {formik.touched.depot_reference_id &&
                      formik.errors.depot_reference_id ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.depot_reference_id}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12">
                    <div className="form-groupB">
                      <label>
                        {intl.formatMessage({ id: "Depot.Address.Line1" })}
                      </label>
                      <input
                        type="text"
                        className={`form-control  ${getInputClasses(
                          formik,
                          "address_line_1"
                        )}`}
                        placeholder={intl.formatMessage({
                          id: "Depot.Address.Line1",
                        })}
                        name="address_line_1"
                        {...formik.getFieldProps("address_line_1")}
                      />
                      {formik.touched.address_line_1 &&
                      formik.errors.address_line_1 ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.address_line_1}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12">
                    <div className="form-groupB">
                      <label>
                        {intl.formatMessage({ id: "Depot.Address.Line2" })}
                      </label>
                      <input
                        type="text"
                        className={`form-control  ${getInputClasses(
                          formik,
                          "address_line_2"
                        )}`}
                        placeholder={intl.formatMessage({
                          id: "Depot.Address.Line2",
                        })}
                        name="address_line_2"
                        {...formik.getFieldProps("address_line_2")}
                      />
                      {formik.touched.address_line_2 &&
                      formik.errors.address_line_2 ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.address_line_2}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12">
                    <div className="form-groupB">
                      <label>
                        {intl.formatMessage({ id: "Depot.Address.Line3" })}
                      </label>
                      <input
                        type="text"
                        className={`form-control  ${getInputClasses(
                          formik,
                          "address_line_3"
                        )}`}
                        placeholder={intl.formatMessage({
                          id: "Depot.Address.Line3",
                        })}
                        name="address_line_3"
                        {...formik.getFieldProps("address_line_3")}
                      />
                      {formik.touched.address_line_3 &&
                      formik.errors.address_line_3 ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.address_line_3}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-sm-12 col-md-4">
                    <div className="form-groupB">
                      <label>{intl.formatMessage({ id: "CITY" })}</label>
                      <input
                        type="text"
                        maxLength={"30"}
                        className={`form-control  ${getInputClasses(
                          formik,
                          "city"
                        )}`}
                        placeholder={intl.formatMessage({ id: "CITY" })}
                        name="city"
                        {...formik.getFieldProps("city")}
                      />
                      {formik.touched.city && formik.errors.city ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.city}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <div className="form-groupB">
                      <label>{intl.formatMessage({ id: "POSTCODE" })}</label>
                      <input
                        type="text"
                        maxLength={"10"}
                        className={`form-control  ${getInputClasses(
                          formik,
                          "postcode"
                        )}`}
                        placeholder={intl.formatMessage({ id: "POSTCODE" })}
                        name="postcode"
                        {...formik.getFieldProps("postcode")}
                      />
                      {formik.touched.postcode && formik.errors.postcode ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.postcode}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <div className="form-groupB">
                      <label>{intl.formatMessage({ id: "COUNTRY" })}</label>
                      <FormControl fullWidth>
                        <Select
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          }}
                          className={`form-control w-100  ${getInputClasses(
                            formik,
                            "country"
                          )}`}
                          IconComponent={(props) => (
                            <i {...props}>
                              <KeyboardArrowDownIcon />
                            </i>
                          )}
                          inputProps={{ "aria-label": "Without label" }}
                          displayEmpty
                          name="country"
                          {...formik.getFieldProps("country")}
                        >
                          <MenuItem checked value="">
                            {intl.formatMessage({ id: "COUNTRY" })}
                          </MenuItem>
                          {detailData &&
                            detailData.map((data, index) => (
                              <MenuItem key={index} value={data.id}>
                                {data.name}&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                                <span style={{ color: "lightgrey" }}>
                                  {data.timezone}
                                </span>
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                      {formik.touched.country && formik.errors.country ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.country}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <div className="form-groupB">
                      <label>{intl.formatMessage({ id: "Latitude" })}</label>
                      <input
                        type="text"
                        maxLength={"30"}
                        className={`form-control  ${getInputClasses(
                          formik,
                          "latitude"
                        )}`}
                        placeholder={intl.formatMessage({ id: "Latitude" })}
                        name="latitude"
                        {...formik.getFieldProps("latitude")}
                      />
                      {formik.touched.latitude && formik.errors.latitude ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.latitude}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <div className="form-groupB">
                      <label>{intl.formatMessage({ id: "Longitude" })}</label>
                      <input
                        type="text"
                        maxLength={"30"}
                        className={`form-control  ${getInputClasses(
                          formik,
                          "longitude"
                        )}`}
                        placeholder={intl.formatMessage({ id: "Longitude" })}
                        name="longitude"
                        {...formik.getFieldProps("longitude")}
                      />
                      {formik.touched.longitude && formik.errors.longitude ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.longitude}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-sm-12 col-md-4">
                    <div className="form-groupB">
                      <label>
                        {intl.formatMessage({ id: "MANAGER.NAME" })}
                      </label>
                      <input
                        type="text"
                        maxLength={"30"}
                        className={`form-control  ${getInputClasses(
                          formik,
                          "manager_name"
                        )}`}
                        placeholder={intl.formatMessage({ id: "MANAGER.NAME" })}
                        name="manager_name"
                        {...formik.getFieldProps("manager_name")}
                      />
                      {formik.touched.manager_name &&
                      formik.errors.manager_name ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.manager_name}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <div className="form-groupB">
                      <label>
                        {intl.formatMessage({ id: "Manager.Email" })}
                      </label>
                      <input
                        type="text"
                        className={`form-control  ${getInputClasses(
                          formik,
                          "manager_email"
                        )}`}
                        placeholder={intl.formatMessage({
                          id: "Manager.Email",
                        })}
                        name="manager_email"
                        {...formik.getFieldProps("manager_email")}
                      />
                      {formik.touched.manager_email &&
                      formik.errors.manager_email ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.manager_email}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <div className="form-groupB">
                      <label>
                        {intl.formatMessage({ id: "Manager.Phone" })}
                      </label>
                      <CountryPhoneNumber
                        countryPhoneValue={formik?.values?.phone}
                        formik={formik}
                        fieldKey={"phone"}
                      />
                      {/* <input
                        type="text"
                        className={`form-control  ${getInputClasses(
                          formik,
                          "phone"
                        )}`}
                        placeholder={intl.formatMessage({
                          id: "Manager.Phone",
                        })}
                        name="phone"
                        {...formik.getFieldProps("phone")}
                      /> */}
                      {formik.touched.phone && formik.errors.phone ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.phone}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12">
                    <div className="form-groupB">
                      <label>
                        {intl.formatMessage({ id: "Additional.Emails" })}
                      </label>
                      <input
                        type="text"
                        placeholder={intl.formatMessage({
                          id: "Additional.Emails",
                        })}
                        className={`form-control  ${getInputClasses(
                          formik,
                          "additional_emails"
                        )}`}
                        name="additional_emails"
                        {...formik.getFieldProps("additional_emails")}
                      />
                      {formik.touched.additional_emails &&
                      formik.errors.additional_emails ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.additional_emails}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 m-1">
                    <label>
                      <strong>
                        {intl.formatMessage({ id: "Opening.Hours" })}
                      </strong>
                    </label>
                  </div>
                  <div className="col-sm-12 mb-3 ">
                    <label> {intl.formatMessage({ id: "Monday" })}</label>
                    <div className="row">
                      <div className="col-6 ">
                        <FormControl fullWidth>
                          <Select
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                            }}
                            className="form-control w-100"
                            IconComponent={(props) => (
                              <i {...props}>
                                <KeyboardArrowDownIcon />
                              </i>
                            )}
                            inputProps={{ "aria-label": "Without label" }}
                            displayEmpty
                            onChange={(e) => handleHours(e, "1", "open_time")}
                            value={
                              formik.values.depot_hours.length
                                ? formik.values.depot_hours[0].open_time
                                : ""
                            }
                          >
                            <MenuItem checked value="">
                              {intl.formatMessage({ id: "Open" })}
                            </MenuItem>
                            {depotOpenHours.map((data, index) => (
                              <MenuItem key={index} value={data.id}>
                                {data.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="col-6 ">
                        <FormControl fullWidth>
                          <Select
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                            }}
                            className="form-control w-100"
                            IconComponent={(props) => (
                              <i {...props}>
                                <KeyboardArrowDownIcon />
                              </i>
                            )}
                            inputProps={{ "aria-label": "Without label" }}
                            displayEmpty
                            disabled={
                              formik.values.depot_hours.length &&
                              formik.values.depot_hours[0].open_time !== ""
                                ? false
                                : true
                            }
                            onChange={(e) => handleHours(e, "1", "close_time")}
                            value={
                              formik.values.depot_hours.length
                                ? formik.values.depot_hours[0].close_time
                                : ""
                            }
                          >
                            <MenuItem checked value="">
                              {intl.formatMessage({ id: "Close" })}
                            </MenuItem>
                            {depotOpenHours.map((data, index) => (
                              <MenuItem key={index} value={data.id}>
                                {data.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    {formik.touched.depot_hours &&
                      formik.errors.depot_hours &&
                      formik.errors.depot_hours[0] &&
                      formik.errors.depot_hours[0].close_time && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.depot_hours[0].close_time}
                          </div>
                        </div>
                      )}
                  </div>

                  <div className="col-sm-12 mb-3 ">
                    <label>{intl.formatMessage({ id: "Tuesday" })}</label>
                    <div className="row">
                      <div className="col-6 ">
                        <FormControl fullWidth>
                          <Select
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                            }}
                            className="form-control w-100"
                            IconComponent={(props) => (
                              <i {...props}>
                                <KeyboardArrowDownIcon />
                              </i>
                            )}
                            inputProps={{ "aria-label": "Without label" }}
                            displayEmpty
                            onChange={(e) => handleHours(e, "2", "open_time")}
                            value={
                              formik?.values?.depot_hours[1]
                                ? formik.values.depot_hours[1].open_time
                                : ""
                            }
                          >
                            <MenuItem checked value="">
                              {intl.formatMessage({ id: "Open" })}
                            </MenuItem>
                            {depotOpenHours.map((data, index) => (
                              <MenuItem key={index} value={data.id}>
                                {data.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="col-6 ">
                        <FormControl fullWidth>
                          <Select
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                            }}
                            className="form-control w-100"
                            IconComponent={(props) => (
                              <i {...props}>
                                <KeyboardArrowDownIcon />
                              </i>
                            )}
                            inputProps={{ "aria-label": "Without label" }}
                            displayEmpty
                            onChange={(e) => handleHours(e, "2", "close_time")}
                            value={
                              formik?.values?.depot_hours[1]
                                ? formik.values.depot_hours[1].close_time
                                : ""
                            }
                            disabled={
                              formik.values.depot_hours.length &&
                              formik.values.depot_hours[1].open_time !== ""
                                ? false
                                : true
                            }
                          >
                            <MenuItem checked value="">
                              {intl.formatMessage({ id: "Close" })}
                            </MenuItem>
                            {depotOpenHours.map((data, index) => (
                              <MenuItem key={index} value={data.id}>
                                {data.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    {formik.touched.depot_hours &&
                      formik.errors.depot_hours &&
                      formik.errors.depot_hours[1] &&
                      formik.errors.depot_hours[1].close_time && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.depot_hours[1].close_time}
                          </div>
                        </div>
                      )}
                  </div>

                  <div className="col-sm-12 mb-3 ">
                    <label>{intl.formatMessage({ id: "Wednesday" })}</label>
                    <div className="row">
                      <div className="col-6 ">
                        <FormControl fullWidth>
                          <Select
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                            }}
                            className="form-control w-100"
                            IconComponent={(props) => (
                              <i {...props}>
                                <KeyboardArrowDownIcon />
                              </i>
                            )}
                            inputProps={{ "aria-label": "Without label" }}
                            displayEmpty
                            onChange={(e) => handleHours(e, "3", "open_time")}
                            value={
                              formik?.values?.depot_hours[2]
                                ? formik.values.depot_hours[2].open_time
                                : ""
                            }
                          >
                            <MenuItem checked value="">
                              {intl.formatMessage({ id: "Open" })}
                            </MenuItem>
                            {depotOpenHours.map((data, index) => (
                              <MenuItem key={index} value={data.id}>
                                {data.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="col-6 ">
                        <FormControl fullWidth>
                          <Select
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                            }}
                            className="form-control w-100"
                            IconComponent={(props) => (
                              <i {...props}>
                                <KeyboardArrowDownIcon />
                              </i>
                            )}
                            inputProps={{ "aria-label": "Without label" }}
                            displayEmpty
                            onChange={(e) => handleHours(e, "3", "close_time")}
                            value={
                              formik?.values?.depot_hours[2]
                                ? formik.values.depot_hours[2].close_time
                                : ""
                            }
                            disabled={
                              formik.values.depot_hours.length &&
                              formik.values.depot_hours[2].open_time !== ""
                                ? false
                                : true
                            }
                          >
                            <MenuItem checked value="">
                              {intl.formatMessage({ id: "Close" })}
                            </MenuItem>
                            {depotOpenHours.map((data, index) => (
                              <MenuItem key={index} value={data.id}>
                                {data.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    {formik.touched.depot_hours &&
                      formik.errors.depot_hours &&
                      formik.errors.depot_hours[2] &&
                      formik.errors.depot_hours[2].close_time && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.depot_hours[2].close_time}
                          </div>
                        </div>
                      )}
                  </div>
                  <div className="col-sm-12 mb-3 ">
                    <label>{intl.formatMessage({ id: "Thursday" })}</label>
                    <div className="row">
                      <div className="col-6 ">
                        <FormControl fullWidth>
                          <Select
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                            }}
                            className="form-control w-100"
                            IconComponent={(props) => (
                              <i {...props}>
                                <KeyboardArrowDownIcon />
                              </i>
                            )}
                            inputProps={{ "aria-label": "Without label" }}
                            displayEmpty
                            onChange={(e) => handleHours(e, "4", "open_time")}
                            value={
                              formik?.values?.depot_hours[3]
                                ? formik.values.depot_hours[3].open_time
                                : ""
                            }
                          >
                            <MenuItem checked value="">
                              {intl.formatMessage({ id: "Open" })}
                            </MenuItem>
                            {depotOpenHours.map((data, index) => (
                              <MenuItem key={index} value={data.id}>
                                {data.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="col-6 ">
                        <FormControl fullWidth>
                          <Select
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                            }}
                            className="form-control w-100"
                            IconComponent={(props) => (
                              <i {...props}>
                                <KeyboardArrowDownIcon />
                              </i>
                            )}
                            inputProps={{ "aria-label": "Without label" }}
                            displayEmpty
                            onChange={(e) => handleHours(e, "4", "close_time")}
                            value={
                              formik?.values?.depot_hours[3]
                                ? formik.values.depot_hours[3].close_time
                                : ""
                            }
                            disabled={
                              formik.values.depot_hours.length &&
                              formik.values.depot_hours[3].open_time !== ""
                                ? false
                                : true
                            }
                          >
                            <MenuItem checked value="">
                              {intl.formatMessage({ id: "Close" })}
                            </MenuItem>
                            {depotOpenHours.map((data, index) => (
                              <MenuItem key={index} value={data.id}>
                                {data.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    {formik.touched.depot_hours &&
                      formik.errors.depot_hours &&
                      formik.errors.depot_hours[3] &&
                      formik.errors.depot_hours[3].close_time && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.depot_hours[3].close_time}
                          </div>
                        </div>
                      )}
                  </div>
                  <div className="col-sm-12 mb-3 ">
                    <label>{intl.formatMessage({ id: "Friday" })}</label>
                    <div className="row">
                      <div className="col-6 ">
                        <FormControl fullWidth>
                          <Select
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                            }}
                            className="form-control w-100"
                            IconComponent={(props) => (
                              <i {...props}>
                                <KeyboardArrowDownIcon />
                              </i>
                            )}
                            inputProps={{ "aria-label": "Without label" }}
                            displayEmpty
                            onChange={(e) => handleHours(e, "5", "open_time")}
                            value={
                              formik?.values?.depot_hours[4]
                                ? formik.values.depot_hours[4].open_time
                                : ""
                            }
                          >
                            <MenuItem checked value="">
                              {intl.formatMessage({ id: "Open" })}
                            </MenuItem>
                            {depotOpenHours.map((data, index) => (
                              <MenuItem key={index} value={data.id}>
                                {data.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="col-6 ">
                        <FormControl fullWidth>
                          <Select
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                            }}
                            className="form-control w-100"
                            IconComponent={(props) => (
                              <i {...props}>
                                <KeyboardArrowDownIcon />
                              </i>
                            )}
                            inputProps={{ "aria-label": "Without label" }}
                            displayEmpty
                            onChange={(e) => handleHours(e, "5", "close_time")}
                            value={
                              formik?.values?.depot_hours[4]
                                ? formik.values.depot_hours[4].close_time
                                : ""
                            }
                            disabled={
                              formik.values.depot_hours.length &&
                              formik.values.depot_hours[4].open_time !== ""
                                ? false
                                : true
                            }
                          >
                            <MenuItem checked value="">
                              {intl.formatMessage({ id: "Close" })}
                            </MenuItem>
                            {depotOpenHours.map((data, index) => (
                              <MenuItem key={index} value={data.id}>
                                {data.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    {formik.touched.depot_hours &&
                      formik.errors.depot_hours &&
                      formik.errors.depot_hours[4] &&
                      formik.errors.depot_hours[4].close_time && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.depot_hours[4].close_time}
                          </div>
                        </div>
                      )}
                  </div>
                  <div className="col-sm-12 mb-3 ">
                    <label>{intl.formatMessage({ id: "Saturday" })}</label>
                    <div className="row">
                      <div className="col-6 ">
                        <FormControl fullWidth>
                          <Select
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                            }}
                            className="form-control w-100"
                            IconComponent={(props) => (
                              <i {...props}>
                                <KeyboardArrowDownIcon />
                              </i>
                            )}
                            inputProps={{ "aria-label": "Without label" }}
                            displayEmpty
                            onChange={(e) => handleHours(e, "6", "open_time")}
                            value={
                              formik?.values?.depot_hours[5]
                                ? formik.values.depot_hours[5].open_time
                                : ""
                            }
                          >
                            <MenuItem checked value="">
                              {intl.formatMessage({ id: "Open" })}
                            </MenuItem>
                            {depotOpenHours.map((data, index) => (
                              <MenuItem key={index} value={data.id}>
                                {data.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="col-6 ">
                        <FormControl fullWidth>
                          <Select
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                            }}
                            className="form-control w-100"
                            IconComponent={(props) => (
                              <i {...props}>
                                <KeyboardArrowDownIcon />
                              </i>
                            )}
                            inputProps={{ "aria-label": "Without label" }}
                            displayEmpty
                            onChange={(e) => handleHours(e, "6", "close_time")}
                            value={
                              formik?.values?.depot_hours[5]
                                ? formik.values.depot_hours[5].close_time
                                : ""
                            }
                            disabled={
                              formik.values.depot_hours.length &&
                              formik.values.depot_hours[5].open_time !== ""
                                ? false
                                : true
                            }
                          >
                            <MenuItem checked value="">
                              {intl.formatMessage({ id: "Close" })}
                            </MenuItem>
                            {depotOpenHours.map((data, index) => (
                              <MenuItem key={index} value={data.id}>
                                {data.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    {formik.touched.depot_hours &&
                      formik.errors.depot_hours &&
                      formik.errors.depot_hours[5] &&
                      formik.errors.depot_hours[5].close_time && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.depot_hours[5].close_time}
                          </div>
                        </div>
                      )}
                  </div>
                  <div className="col-sm-12 mb-3 ">
                    <label>{intl.formatMessage({ id: "Sunday" })}</label>
                    <div className="row">
                      <div className="col-6 ">
                        <FormControl fullWidth>
                          <Select
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                            }}
                            className="form-control w-100"
                            IconComponent={(props) => (
                              <i {...props}>
                                <KeyboardArrowDownIcon />
                              </i>
                            )}
                            inputProps={{ "aria-label": "Without label" }}
                            displayEmpty
                            onChange={(e) => handleHours(e, "7", "open_time")}
                            value={
                              formik?.values?.depot_hours[6]
                                ? formik.values.depot_hours[6].open_time
                                : ""
                            }
                          >
                            <MenuItem checked value="">
                              {intl.formatMessage({ id: "Open" })}
                            </MenuItem>
                            {depotOpenHours.map((data, index) => (
                              <MenuItem key={index} value={data.id}>
                                {data.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="col-6 ">
                        <FormControl fullWidth>
                          <Select
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                            }}
                            className="form-control w-100"
                            IconComponent={(props) => (
                              <i {...props}>
                                <KeyboardArrowDownIcon />
                              </i>
                            )}
                            inputProps={{ "aria-label": "Without label" }}
                            displayEmpty
                            onChange={(e) => handleHours(e, "7", "close_time")}
                            value={
                              formik?.values?.depot_hours[6]
                                ? formik.values.depot_hours[6].close_time
                                : ""
                            }
                            disabled={
                              formik.values.depot_hours.length &&
                              formik.values.depot_hours[6].open_time !== ""
                                ? false
                                : true
                            }
                          >
                            <MenuItem checked value="">
                              {intl.formatMessage({ id: "Close" })}
                            </MenuItem>
                            {depotOpenHours.map((data, index) => (
                              <MenuItem key={index} value={data.id}>
                                {data.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    {formik.touched.depot_hours &&
                      formik.errors.depot_hours &&
                      formik.errors.depot_hours[6] &&
                      formik.errors.depot_hours[6].close_time && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.depot_hours[6].close_time}
                          </div>
                        </div>
                      )}
                  </div>

                  <div className="col-sm-12 col-md-12"></div>
                  <div className="col-sm-12 col-md-4">
                    <div className="form-groupB row">
                      <label className="col-6 col-sm-8 col-md-8 col-form-label ">
                        {intl.formatMessage({ id: "ACTIVE" })}
                      </label>
                      <div className="col-6 col-sm-8 col-md-4 text-right mt-1">
                        <span className="switch switch-outline switch-icon switch-primary">
                          <label>
                            <input
                              type="checkbox"
                              checked={
                                formik.values.status === 1 ? true : false
                              }
                              name="quick_panel_notifications_2"
                              onChange={() => setDialog(true)}
                            />
                            <span></span>
                          </label>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*end::Content*/}
            </div>
          </div>

          <div className="offcanvas-footer">
            <div className="offcanvas-content text-right ">
              <button
                type="reset"
                className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
                onClick={() => canvaCancel("kt_new_depot_panel_close")}
              >
                {intl.formatMessage({ id: "CANCEL" })}
              </button>
              <button
                type="submit"
                className="btn btn-primary font-weight-bolder font-size-sm "
              >
                <span>{intl.formatMessage({ id: "SAVE" })}</span>
                {loading && (
                  <span className="spinner spinner-white mr-4"></span>
                )}
              </button>
            </div>
          </div>
          <ActiveDialogModal
            active={active}
            setActive={setActive}
            dialog={dialog}
            setDialog={setDialog}
            formik={formik}
          />
        </form>
      </div>
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </>
  );
}
