import Card from "../../../../../_metronic/_partials/settings/components/Card";
import NewBreadCrumbs from "../../../../../_metronic/layout/components/subheader/components/NewBreadCrumbs";
import { PodInStorage } from "../../../../../_metronic/layout/components/extras/offcanvas/Reporting/PodInStorage";
import React from "react";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";

export function ReportingPage() {
  const des =
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s";
  return (
    <>
      <NewBreadCrumbs title={"Settings"} />
      <div className="row">
        <div className="col-md-4 pointer" id="kt_PodInStorage_panel_toggle">
          <Card
            title={"Pods in Storage"}
            url={"#"}
            description={des}
            svgPath={toAbsoluteUrl("/media/svg/icons/Shopping/Chart-pie.svg")}
          />
        </div>
        <div className="col-md-4 pointer">
          <Card
            title={"Report 2"}
            url={"#"}
            description={des}
            svgPath={toAbsoluteUrl("/media/svg/icons/Shopping/Chart-pie.svg")}
          />
        </div>
        <div className="col-md-4 pointer">
          <Card
            title={"Report 3"}
            url={"#"}
            description={des}
            svgPath={toAbsoluteUrl("/media/svg/icons/Shopping/Chart-pie.svg")}
          />
        </div>
        <div className="col-md-4 pointer">
          <Card
            title={"Report 4"}
            url={"#"}
            description={des}
            svgPath={toAbsoluteUrl("/media/svg/icons/Shopping/Chart-pie.svg")}
          />
        </div>
        <div className="col-md-4 pointer">
          <Card
            title={"Report 5"}
            url={"#"}
            description={des}
            svgPath={toAbsoluteUrl("/media/svg/icons/Shopping/Chart-pie.svg")}
          />
        </div>
        <div className="col-md-4 pointer">
          <Card
            title={"Report 6"}
            url={"#"}
            description={des}
            svgPath={toAbsoluteUrl("/media/svg/icons/Shopping/Chart-pie.svg")}
          />
        </div>
        <div className="col-md-4 pointer">
          <Card
            title={"Report 7"}
            url={"#"}
            description={des}
            svgPath={toAbsoluteUrl("/media/svg/icons/Shopping/Chart-pie.svg")}
          />
        </div>
        <div className="col-md-4 pointer">
          <Card
            title={"Report 8"}
            url={"#"}
            description={des}
            svgPath={toAbsoluteUrl("/media/svg/icons/Shopping/Chart-pie.svg")}
          />
        </div>
      </div>
      <PodInStorage />
    </>
  );
}
