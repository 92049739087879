/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { Modal } from "react-bootstrap";
import React, { useState } from "react";
import { useFormik } from "formik";
// import { useDispatch } from "react-redux";
import SnackBarTool from "../../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { LoadingCustome } from "../../../../../../_metronic/_partials/controls/LoadingCustome";
import { getInputClasses } from "../../../../../utils/commonFunction";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { Form } from "react-bootstrap";

import { FormControl, MenuItem, Select } from "@material-ui/core";
// import { chargebee } from "../../../_mocks/dummyData";
// import { findOfferCode } from "../../../_redux/order/orderCrud";
import { useIntl } from "react-intl";
// import { useEffect } from "react";
import { AddDiscountEdit } from "../../../order-form/validationSchema";

const AddManualDiscountModal = ({ dialog, setDialog, orderUIContext }) => {
  const intl = useIntl();
  // const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  // const [dialogDis, setDialogDis] = useState(false);

  const initialValues = {
    discount_type: orderUIContext?.manualDiscount?.discount_type
      ? orderUIContext?.manualDiscount?.discount_type
      : "",
    discount_amount: orderUIContext?.manualDiscount?.discount_amount
      ? orderUIContext?.manualDiscount?.discount_amount
      : "",
    apply_on: "invoice_amount",

    duration_type: "one_time",
    manual_discount_period: orderUIContext?.manualDiscount
      ?.manual_discount_period
      ? orderUIContext?.manualDiscount?.manual_discount_period
      : "",
    manual_discount_period_unit: orderUIContext?.manualDiscount
      ?.manual_discount_period_unit
      ? orderUIContext?.manualDiscount?.manual_discount_period_unit
      : "",
    manual_discount_item_price_id: "",
    operation_type: "add",
  };

  const handleSubmit = (values) => {
    setIsLoading(true);
    const arr = [...orderUIContext.state.manualDiscount, values];
    setSnack({
      call: true,
      type: "success",
      message: "Manual Discount Applied",
    });
    // arr.push(values);
    orderUIContext.dispatchReducer({
      type: "SET_DATA",
      data: {
        ...orderUIContext.state,
        manualDiscount: arr,
        isBreakeDownApiCall: true,
      },
    });
    setTimeout(() => {
      setDialog({ manual: false });
      setSnack({ call: false, type: "", message: "" });
    }, 3000);
    setIsLoading(true);
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: AddDiscountEdit,
    onSubmit: handleSubmit,
  });

  const handleClose = () => {
    setDialog({ manual: false });
  };
  const handleApplyOn = (value, type) => {
    if (type && type === "duration") {
      formik.setFieldValue("duration_type", value);
    } else {
      formik.setFieldValue("apply_on", value);
    }
  };

  const handleEnterDistcount = (e) => {
    if (e.target.value === "0") {
      formik.setFieldValue("discount_amount", 1);
    } else {
      formik.setFieldValue("discount_amount", e.target.value);
    }
  };
  return (
    <Modal
      show={dialog?.manual}
      aria-labelledby="example-modal-sizes-title-lg"
      onHide={handleClose}
    >
      <Modal.Header className="d-flex justify-content-between">
        <Modal.Title id="example-modal-sizes-title-lg">
          Add manual discount
        </Modal.Title>
        <span
          className="btn btn-xs btn-icon btn-light btn-hover-primary"
          onClick={handleClose}
        >
          <i className="ki ki-close icon-sm text-muted"></i>
        </span>
      </Modal.Header>
      <form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          {isLoading && <LoadingCustome />}
          <div className="row">
            <div className="col-md-7">
              <FormControl fullWidth>
                <label>{intl.formatMessage({ id: "Discount.Type" })}</label>
                <Select
                  name="discount_type"
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                  className={`form-control ${getInputClasses(
                    "discount_type"
                  )} `}
                  IconComponent={(props) => (
                    <i {...props}>
                      <KeyboardArrowDownIcon />
                    </i>
                  )}
                  inputProps={{ "aria-label": "Without label" }}
                  displayEmpty
                  {...formik.getFieldProps("discount_type")}
                >
                  <MenuItem value="">
                    {intl.formatMessage({ id: "Discount.Type" })}
                  </MenuItem>
                  <MenuItem value="percentage">
                    {intl.formatMessage({ id: "Percentage" })}
                  </MenuItem>
                  <MenuItem value="fixed_amount">
                    {intl.formatMessage({ id: "Fixed.Amount" })}
                  </MenuItem>
                </Select>
              </FormControl>
              {formik.touched.discount_type && formik.errors.discount_type ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.discount_type}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="col-7 mt-2">
              <label>{intl.formatMessage({ id: "Enter.discount" })}</label>
              <div className="form-groupB">
                <input
                  type="text"
                  className={`form-control  ${getInputClasses(
                    "discount_amount"
                  )}`}
                  placeholder={intl.formatMessage({ id: "Enter.discount" })}
                  name="discount_amount"
                  value={formik.values.discount_amount}
                  maxLength={formik.discountType === "percentage" ? "3" : "4"}
                  onChange={(e) => handleEnterDistcount(e)}
                />
                {formik.touched.discount_amount &&
                formik.errors.discount_amount ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.discount_amount}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-12">
              <label>Apply on</label>
              <div className="mb-7 payment-radio">
                <Form.Check
                  className="mb-1"
                  type="radio"
                  label={"Invoice total"}
                  name="invoice_amount"
                  id="invoice_amount"
                  checked={
                    formik.values.apply_on === "invoice_amount" ? true : false
                  }
                  onClick={() => handleApplyOn("invoice_amount", "apply")}
                />
                <Form.Check
                  className="mb-1"
                  type="radio"
                  label={"Specific line item in invoice"}
                  name="specific_item_price"
                  id="specific_item_price"
                  checked={
                    formik.values.apply_on === "specific_item_price"
                      ? true
                      : false
                  }
                  onClick={() => handleApplyOn("specific_item_price", "apply")}
                />
              </div>
              {formik.values.apply_on === "specific_item_price" && (
                <>
                  <FormControl fullWidth>
                    <label>Choose plan/addon or charge</label>
                    <Select
                      name="manual_discount_item_price_id"
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                      className={`form-control ${getInputClasses(
                        "manual_discount_item_price_id"
                      )} `}
                      IconComponent={(props) => (
                        <i {...props}>
                          <KeyboardArrowDownIcon />
                        </i>
                      )}
                      inputProps={{ "aria-label": "Without label" }}
                      displayEmpty
                      {...formik.getFieldProps("manual_discount_item_price_id")}
                    >
                      <MenuItem value="">
                        {intl.formatMessage({ id: "Discount.Type" })}
                      </MenuItem>
                      {orderUIContext?.state?.productArray.map((item) => (
                        <MenuItem value={item?.item_price_id}>
                          {item?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {formik.touched.manual_discount_item_price_id &&
                  formik.errors.manual_discount_item_price_id ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.manual_discount_item_price_id}
                      </div>
                    </div>
                  ) : null}
                </>
              )}
            </div>
            <div className="col-12">
              <label>{intl.formatMessage({ id: "Duration" })}</label>
              <div className="mb-7 payment-radio">
                <Form.Check
                  className="mb-1"
                  type="radio"
                  label={intl.formatMessage({ id: "One.Time" })}
                  name="one_time"
                  id="one_time"
                  checked={
                    formik.values.duration_type === "one_time" ? true : false
                  }
                  onClick={() => handleApplyOn("one_time", "duration")}
                />
                <Form.Check
                  className="mb-1"
                  type="radio"
                  label={intl.formatMessage({ id: "Forever" })}
                  name="forever"
                  id="forever"
                  checked={
                    formik.values.duration_type === "forever" ? true : false
                  }
                  onClick={() => handleApplyOn("forever", "duration")}
                />
                <Form.Check
                  className="mb-1"
                  type="radio"
                  label={intl.formatMessage({ id: "Limited.Period" })}
                  name="formHorizontalRadios2"
                  id="formHorizontalRadios2"
                  checked={
                    formik.values.duration_type === "limited_period"
                      ? true
                      : false
                  }
                  onClick={() => handleApplyOn("limited_period", "duration")}
                />
              </div>
            </div>
            {formik.values.duration_type === "limited_period" ? (
              <>
                <div className="col-6">
                  <div className="form-groupB">
                    <input
                      type="text"
                      className={`form-control  `}
                      placeholder={intl.formatMessage({ id: "Limited.Period" })}
                      name="manual_discount_period"
                      value={formik?.values?.manual_discount_period || ""}
                      onChange={(e) => {
                        if (
                          e.target.value !== "" &&
                          Number(e.target.value) === 0
                        )
                          return;
                        formik.setFieldValue(
                          "manual_discount_period",
                          e.target.value
                        );
                      }}
                      // {...formik.getFieldProps("manual_discount_period")}
                    />
                  </div>
                  {formik.touched.manual_discount_period &&
                  formik.errors.manual_discount_period ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.manual_discount_period}
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="col-6">
                  <FormControl fullWidth>
                    <Select
                      name="manual_discount_period_unit"
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                      className={`form-control `}
                      IconComponent={(props) => (
                        <i {...props}>
                          <KeyboardArrowDownIcon />
                        </i>
                      )}
                      inputProps={{ "aria-label": "Without label" }}
                      displayEmpty
                      {...formik.getFieldProps("manual_discount_period_unit")}
                    >
                      <MenuItem value="day">
                        {intl.formatMessage({ id: "Days" })}
                      </MenuItem>
                      <MenuItem value="week">
                        {intl.formatMessage({ id: "Weeks" })}
                      </MenuItem>
                      <MenuItem value="month">
                        {intl.formatMessage({ id: "Months" })}
                      </MenuItem>
                      <MenuItem value="year">
                        {intl.formatMessage({ id: "Years" })}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
          {formik.touched.duration && formik.errors.duration ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.duration}</div>
            </div>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              onClick={() => handleClose()}
              className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
              //   disabled={isLoading}
            >
              Dismiss
            </button>
            <button
              type="submit"
              className="btn btn-primary font-weight-bolder font-size-sm "
            >
              Save
            </button>
          </div>
        </Modal.Footer>
      </form>
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </Modal>
  );
};

export default AddManualDiscountModal;
