/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

import * as Yup from "yup";

import React, { useState } from "react";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import SnackBarTool from "../../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { Term } from "../franchise-form/validateSchema";
import { canvaCancel } from "../../../../../utils/canvaCloseFunction";
import { editFranchisePopDetail } from "../../../_redux/franchises/franchisesCrud";
import { useFormik } from "formik";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { fetchFranchisesDetail } from "../../../_redux/franchises/franchisesActions";
// import { SourceEditing } from "@ckeditor/ckeditor5-source-editing";
// import { Markdown } from "@ckeditor/ckeditor5-markdown-gfm";
import Editor from 'react-simple-wysiwyg';

export function TermsConditions({ detailData }) {
  const dispatch = useDispatch();
  const intl = useIntl();
  const initialValues = {
    franchise_id: detailData && detailData.id ? detailData.id : "",
    term_and_conditions:
      detailData && detailData.term_and_conditions
        ? detailData.term_and_conditions
        : "",
    cookie_policy:
      detailData && detailData.cookie_policy ? detailData.cookie_policy : "",
    privacy_policy:
      detailData && detailData.privacy_policy ? detailData.privacy_policy : "",

    summary_of_insurance:
      detailData && detailData.summary_of_insurance
        ? detailData.summary_of_insurance
        : "",
    type: "term_and_conditions",
  };

  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [editor, setEditor] = useState();
 
  const handleSubmit = (values, actions) => {
    setSnack({ call: false, type: "", message: "" });
    editFranchisePopDetail(values)
      .then((res) => {
        "";
        if (res.data.success) {
          setSnack({
            call: true,
            type: "success",
            message: "Term and Conditions updated uuccessfully",
          });
          canvaCancel("kt_termscondition_panel_close");
          dispatch(fetchFranchisesDetail(values?.franchise_id));
        } else {
          setSnack({ call: true, type: "error", message: res.data.message });
        }
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
  };

  const handleReset = () => {};

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: Term(intl, Yup),
    onSubmit: handleSubmit,
    onReset: handleReset,
  });

  return (
    <div
      id="kt_termscondition_panel"
      className="offcanvas offcanvas-right pt-5 pb-10"
    >
      {/*begin::Header*/}
      <div className="container offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
        <div
          className="offcanvas-close mt-n1 pr-5"
          style={{ position: "absolute", top: "15px", right: "10px" }}
        >
          <span
            onClick={() => formik.resetForm()}
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            id="kt_termscondition_panel_close"
          >
            <i className="ki ki-close icon-sm text-muted"></i>
          </span>
        </div>
      </div>
      {/*end::Header*/}
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <div className="offcanvas-content date-picker px-10 ">
          <div>
            <h5>{intl.formatMessage({ id: "Terms.&.Conditions" })}</h5>
          </div>
          <div
            id="kt_quick_panel_logs_kt_termscondition_panel"
            className="fade pt-3 pr-5 mr-n5 scroll active show ps--active-y mt-10"
          >
            <div className="mt-10 pb-10">
              <div className="mb-4">
                <span>{intl.formatMessage({ id: "Terms.&.Conditions" })}</span>
                <Editor value={formik.values.term_and_conditions}  onChange={(event) => {
                    
                    formik.setFieldValue("term_and_conditions", event.target.value);
                  }}  />
                {/* <CKEditor
                  editor={ClassicEditor}
                 
                  data={formik.values.term_and_conditions}
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    formik.setFieldValue("term_and_conditions", data);
                  }}
                  onBlur={(event, editor) => {}}
                  onFocus={(event, editor) => {}}
                /> */}
                {formik.touched.term_and_conditions &&
                formik.errors.term_and_conditions ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.term_and_conditions}
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="mb-4">
                <span>{intl.formatMessage({ id: "Cookie.Policy" })}</span>
                <CKEditor
                  editor={ClassicEditor}
                  data={formik.values.cookie_policy}
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    formik.setFieldValue("cookie_policy", data);
                  }}
                  onBlur={(event, editor) => {}}
                  onFocus={(event, editor) => {}}
                />
                {formik.touched.cookie_policy && formik.errors.cookie_policy ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.cookie_policy}
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="mb-4">
                <span>{intl.formatMessage({ id: "Insurance" })}</span>
                <CKEditor
                  editor={ClassicEditor}
                  data={formik.values.summary_of_insurance}
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    formik.setFieldValue("summary_of_insurance", data);
                  }}
                  onBlur={(event, editor) => {}}
                  onFocus={(event, editor) => {}}
                />
                {formik.touched.summary_of_insurance &&
                formik.errors.summary_of_insurance ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.summary_of_insurance}
                    </div>
                  </div>
                ) : null}
              </div>
              {/* <div className="mb-4">
                <span>{intl.formatMessage({ id: "Insurance" })}</span>
                <CKEditor
                  editor={ClassicEditor}
                  data={formik.values.summary_of_insurance}
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    formik.setFieldValue("summary_of_insurance", data);
                  }}
                  onBlur={(event, editor) => {}}
                  onFocus={(event, editor) => {}}
                />
                {formik.touched.summary_of_insurance &&
                formik.errors.summary_of_insurance ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.summary_of_insurance}
                    </div>
                  </div>
                ) : null}
              </div> */}
              <div className="mb-4">
                <span>{intl.formatMessage({ id: "Privacy.Policy" })}</span>
                <CKEditor
                  editor={ClassicEditor}
                  data={formik.values.privacy_policy}
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    formik.setFieldValue("privacy_policy", data);
                  }}
                  onBlur={(event, editor) => {}}
                  onFocus={(event, editor) => {}}
                />
                {formik.touched.privacy_policy &&
                formik.errors.privacy_policy ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.privacy_policy}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="offcanvas-footer">
          <div className="offcanvas-content text-right ">
            <button
              type="reset"
              className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
              onClick={() => canvaCancel("kt_termscondition_panel_close")}
            >
              {intl.formatMessage({ id: "CANCEL" })}
            </button>
            <button
              type="submit"
              className="btn btn-primary font-weight-bolder font-size-sm mr-3 "
            >
              {intl.formatMessage({ id: "SAVE" })}
            </button>
          </div>
        </div>
      </form>
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </div>
  );
}
