/* eslint-disable array-callback-return */
import { Modal } from "react-bootstrap";
import React, { useState, useEffect } from "react";
// import { FormControl, InputGroup } from "react-bootstrap";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import NoRecord from "../../../../_metronic/_partials/widgets/NoRecord";
import {
  CircularProgress,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
  makeStyles,
} from "@material-ui/core";

import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { fetchPakagingMaterialList } from "../_redux/order/orderAction";
import { convertGBPtoPoundSymbol } from "../../../utils/commonFunction";
// import { truncateString } from "../../../utils/canvaCloseFunction";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const PackagingMaterialListModal = ({
  dialog,
  cardDetail,
  setDialog,
  handleChangeOrder,
  dispatchReducer,
  state,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [packageArray, setPackageArray] = useState([]);
  const [qtyData, setQtyData] = useState([]);
  const { packagingList, packagingLoading } = useSelector(
    (state) => ({
      packagingList: state.order.packagingMaterial,
      packagingLoading: state.order.packagingLoading,
    }),
    shallowEqual
  );

  const handleClose = () => {
    setDialog({ check: false });
  };

  const handleAddPackage = (item) => {
    const arr = [...packageArray];
    const packageIndex = arr.findIndex((e) => e === item);
    if (packageIndex !== -1) {
      arr.splice(packageIndex, 1);
    } else {
      arr.push(item);
    }
    setPackageArray(arr);
  };
  const handleQty = (qty, id) => {
    if (+qty < 1) return;
    const arr = [...qtyData];
    // const check = packageArray.findIndex((e) => e === id);
    const check = arr.findIndex((e) => e?.id === id);
    if (check !== -1) {
      arr[check].id = id;
      arr[check].qty = qty;
    } else {
      const data = {
        id,
        qty,
      };
      arr.push(data);
    }
    dispatchReducer({
      type: "packageMaterial",
      value: arr,
    });
    dispatchReducer({
      type: "packageMaterialAdd",
      value: true,
    });
    setQtyData(arr);
  };
  const handleSave = () => {
    handleChangeOrder(packageArray, "packaging", qtyData);
    handleClose();
  };
  useEffect(() => {
    if (cardDetail && cardDetail.length && dialog?.check) {
      dispatch(
        fetchPakagingMaterialList(
          cardDetail &&
            cardDetail?.length &&
            cardDetail[0].contact &&
            cardDetail[0].contact?.franchise?.id
            ? cardDetail[0].contact?.franchise?.id
            : ""
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardDetail, dialog?.check, dispatch]);

  return (
    <Modal
      show={dialog.check}
      size="xl"
      aria-labelledby="example-modal-sizes-title-lg"
      onHide={handleClose}
    >
      <Modal.Header className="d-flex justify-content-between">
        <Modal.Title id="example-modal-sizes-title-lg">
          Packaging Material List
        </Modal.Title>
        <span
          class="btn btn-xs btn-icon btn-light btn-hover-primary"
          onClick={handleClose}
        >
          <i class="ki ki-close icon-sm text-muted"></i>
        </span>
      </Modal.Header>
      <Modal.Body>
        {Object.entries(packagingList) &&
        Object.entries(packagingList).length > 0 &&
        !packagingLoading ? (
          Object.entries(packagingList).map(([category, items]) => (
            <div className={`${classes.root} mt-5`}>
              <ExpansionPanel>
                <ExpansionPanelSummary
                  expandIcon={
                    <span className="svg-icon svg-icon-sm svg-icon-primary">
                      <SVG
                        title=" "
                        className="h-25 align-self-end"
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Navigation/Angle-double-down.svg"
                        )}
                      ></SVG>
                    </span>
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    {category}
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div className="w-100">
                    {Object.entries(items).map(([key, value]) => (
                      <div className="d-flex mb-2">
                        <label
                          className="col-md-6 col-form-label text-hover-primary"
                          style={{ cursor: "pointer", paddingLeft: "12px" }}
                        >
                          {value.title}
                        </label>
                        <button
                          type="button"
                          onClick={() => handleAddPackage(key)}
                          style={{ height: "20%", marginTop: "1px" }}
                          className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
                          // disabled={isLoading}
                        >
                          {packageArray.some((e) => e === key)
                            ? "Remove"
                            : "Add"}
                        </button>
                        {packageArray.some((e) => e === key) && (
                          <div className=" ml-1">
                            {/* <InputGroup className="qty-group"> */}
                            <input
                              className="form-control"
                              type="number"
                              placeholder="Qty"
                              aria-label="Qty"
                              // aria-describedby="basic-addon1"
                              // max={"10"}
                              maxLength="12"
                              step="1"
                              value={
                                (state?.packageMaterial &&
                                  state?.packageMaterial?.find(
                                    (e) => e.id === key
                                  )?.qty) ||
                                1
                              }
                              onChange={(e) => handleQty(e.target.value, key)}
                              style={{ paddingRight: "10px", width: "100px" }}
                              disabled={!packageArray.some((e) => e === key)}
                            />
                            {/* </InputGroup> */}
                          </div>
                        )}
                        <div className="ml-2 col-md-6 row col-form-label text-hover-primary">
                          <div
                            className="col-md-3"
                            style={{ marginTop: "-10px" }}
                          >
                            <div>
                              <span>
                                <strong>Unit Price</strong>
                              </span>
                            </div>
                            <label style={{ cursor: "pointer" }}>
                              {state?.currencyCode
                                ? convertGBPtoPoundSymbol(state.currencyCode)
                                : ""}{" "}
                              {value?.price || ""}
                            </label>
                          </div>
                          <div
                            className="col-md-6"
                            style={{ marginTop: "-10px" }}
                          >
                            <div>
                              <span>
                                <strong>Total</strong>
                              </span>
                            </div>

                            <label style={{ cursor: "pointer" }}>
                              {state?.currencyCode
                                ? convertGBPtoPoundSymbol(state.currencyCode)
                                : ""}{" "}
                              {state?.packageMaterial?.find((e) => e.id === key)
                                ?.qty
                                ? Number(
                                    state?.packageMaterial?.find(
                                      (e) => e.id === key
                                    )?.qty
                                  ) * Number(value.price)
                                : value.price}
                            </label>
                          </div>
                        </div>
                        {/* <label
                          
                          style={{ cursor: "pointer", paddingLeft: "45px" }}
                        >
                        {state?.currencyCode
                            ? convertGBPtoPoundSymbol(state.currencyCode)
                            : ""} {  value?.price || ""} |  { state?.currencyCode
                            ? convertGBPtoPoundSymbol(state.currencyCode)
                            : ""} { state?.packageMaterial?.find((e) => e.id === key)
                              ?.qty ? Number(state?.packageMaterial?.find((e) => e.id === key)
                              ?.qty)*Number(value.price): value.price}
                        </label> */}
                      </div>
                    ))}
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </div>
          ))
        ) : (
          <>
            {packagingLoading ? (
              <div className="text-center mt-8">
                <CircularProgress />
              </div>
            ) : (
              <NoRecord />
            )}
          </>
        )}

        {/* <ul>
          {Object.entries(packagingList) &&
          Object.entries(packagingList).length > 0 ? (
            Object.entries(packagingList).map(([category, items]) => (
              <li key={category}>
                <h3>{category}</h3>
                <ul>
                  {items.map((item) => (
                    <li key={item}>{item}</li>
                  ))}
                </ul>
              </li>
            ))
          ) : (
            <NoRecord />
          )}
        </ul> */}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={() => handleClose()}
            className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
            // disabled={isLoading}
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={() => handleSave()}
            className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
            // disabled={isLoading}
          >
            Save
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default PackagingMaterialListModal;
