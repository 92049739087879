import { CircularProgress } from "@material-ui/core";
import React from "react";

export const LoadingSpinner = () => {
  return (
    <div className="text-center mt-8">
      <CircularProgress />
    </div>
  );
};
