import React, { useEffect } from "react";
import {
  countryDetailEmpty,
  fetchCountryDetail,
} from "../../_redux/country/countryAction";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { EditCountryCanva } from "./country-canva/EditCountry";
import NewBreadCrumbs from "../../../../../_metronic/layout/components/subheader/components/NewBreadCrumbs";
import SVG from "react-inlinesvg";
import { initIds } from "../../../../../_metronic/_partials/controls/RightPanel";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";

const CountryDetailPage = (props) => {
  const intl = useIntl(); // get the internationalization hook
  let history = useHistory(); // get the history hook
  const init = ["kt_edit_country_panel"]; // initialize the init variable with an array of strings

  // use the useEffect hook to run the initIds function with the init variable as an argument
  useEffect(() => {
    initIds(init);
  }, [init]);

  // get the dispatch hook from the useDispatch hook and the detailData and permission variables from the useSelector hook
  const dispatch = useDispatch();
  const { detailData, permission } = useSelector(
    (state) => ({
      detailData: state.country.countryDetail,
      permission: state.auth.permission,
    }),
    shallowEqual
  );

  // define the handleRedirect function which pushes the current pathname to the history object with a query string and location state
  const handleRedirect = () => {
    history.push(`/settings/franchises`);
    history.push({
      pathname: "/settings/franchises",
      search: `?country=${props?.match?.params?.id}`, // query string
      state: {
        // location state
        country: props?.match?.params?.id,
      },
    });
  };

  // use the useEffect hook to run the countryDetailEmpty action when the component unmounts
  useEffect(() => {
    return () => {
      dispatch(countryDetailEmpty());
    };
  }, [dispatch]);

  // use the useEffect hook to run the fetchCountryDetail action with the id from the url params as an argument
  useEffect(() => {
    dispatch(fetchCountryDetail(props?.match?.params?.id));
  }, [dispatch, props.match.params.id]);
  return (
    <>
      {/* Ternary operator that renders the JSX below if detailData is truthy */}
      {detailData ? (
        <>
          <div>
            {/* Renders a breadcrumb navigation bar */}
            <NewBreadCrumbs title={intl.formatMessage({ id: "COUNTRY" })} />
            <div className="row">
              <div className="col-lg-12 col-xl-12 col-md-12">
                <div className="card card-custom card-stretch">
                  <div className="card-body">
                    <div className="mt-4 font-weight-bolder font-size-h5 text-dark-75 text-hover-primary ">
                      <div className="d-flex ">
                        {/* Renders the country name and status */}
                        <h3 className="card-title align-items-start flex-column d-flex">
                          <span className="font-weight-bolder text-dark">
                            {detailData?.name}
                          </span>
                          <span
                            className={`text-${
                              detailData?.status === 1 ? "success" : "danger"
                            } font-weight-bold font-size-sm mt-3`}
                          >
                            {detailData?.status === 1 ? "Active" : "Inactive"}
                          </span>
                        </h3>
                        {/* Renders an edit button if the user has permission */}
                        {permission?.setting_country?.edit ? (
                          <span
                            className="svg-icon svg-icon-sm svg-icon-primary ml-3"
                            id="kt_edit_country_panel_toggle"
                            style={{ cursor: "pointer" }}
                          >
                            <SVG
                              style={{ color: "orange" }}
                              title="Edit"
                              className="h-22 align-self-end"
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Design/Edit.svg"
                              )}
                            ></SVG>
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div>
                      <span className="font-weight-bolder ">
                        {intl.formatMessage({ id: "Country.Short" })}
                      </span>
                      <br />
                      {/* Renders the short name of the country */}
                      <span className="text-muted">
                        {detailData?.short_name}
                      </span>
                    </div>
                    {/* Renders the language of the country */}
                    <div className="mt-4">
                      <span className="font-weight-bolder ">
                        {intl.formatMessage({ id: "Language" })}
                      </span>
                      <br />
                      <span className="text-muted">{detailData?.language}</span>
                    </div>
                    {/* Renders the currency of the country */}
                    <div className="mt-4">
                      <span className="font-weight-bolder ">
                        {intl.formatMessage({ id: "Currency" })}
                      </span>
                      <br />
                      <span className="text-muted">{detailData?.currency}</span>
                    </div>
                    {/* Renders the time zone of the country */}
                    <div className="mt-4">
                      <span className="font-weight-bolder ">
                        {intl.formatMessage({ id: "Time.Zone" })}
                      </span>
                      <br />
                      <span className="text-muted">{detailData?.timezone}</span>
                    </div>
                    {/* Renders a button that redirects to a list of franchises */}
                    <button
                      className="btn btn-primary  mt-6 mb-4"
                      style={{ width: "30%" }}
                      onClick={handleRedirect}
                    >
                      {intl.formatMessage({ id: "FRANCHISES" })}
                    </button>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-12 col-xl-6">
                <div className="container card card-custom card-stretch">
                  // Renders a list of metrics and actions for the country 
                  <h5 className="mt-4">
                    {intl.formatMessage({ id: "Metrics" })}
                  </h5>
                  <div className="row">
                    <div className="col-6">
                      <img
                        src={toAbsoluteUrl("/media/chart/bar.png")}
                        alt="img"
                      />
                    </div>
                    <div className="col-6">
                      {" "}
                      <img
                        src={toAbsoluteUrl("/media/chart/bar2.png")}
                        alt="img"
                      />
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          {/* Renders a edit country form canva */}
          <EditCountryCanva detailData={detailData} />
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default CountryDetailPage;
