import React from "react";
import { Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import { LoadingCustome } from "../../controls/LoadingCustome";

const DynamicDialog = ({
  dialog,
  setDialog,
  header,
  mainText,
  handleChange,
  loading
}) => {
  console.log("DIL", dialog)
  const intl = useIntl();
  return (
    
    <Modal show={dialog.modal} aria-labelledby="example-modal-sizes-title-lg">
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{header}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>{mainText}</span>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={() => setDialog({ modal: false })}
            className="btn btn-light btn-elevate"
            disabled={loading}

          >
            {intl.formatMessage({ id: "No" })}

          </button>
          <> </>
          <button
            type="button"
            onClick={() => handleChange(dialog?.funData)}
            className="btn btn-delete btn-elevate"
            disabled={loading}
          >
            {intl.formatMessage({ id: "Yes" })}
            {loading && (
                <span className="ml-2 mr-2 spinner "></span>
              )}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default DynamicDialog;
