export const view = (intl) => {
  return [
    {
      id: 1,
      title: intl.formatMessage({ id: "All.Contacts" }),
      value: "all_contacts",
    },
    {
      id: 2,
      title: intl.formatMessage({ id: "My.Contacts" }),
      value: "my_contacts",
    },
    {
      id: 3,
      title: intl.formatMessage({ id: "Unassigned" }),
      value: "unassigned",
    },
    {
      id: 4,
      title: intl.formatMessage({ id: "New.Last.Week" }),
      value: "new_last_week",
    },
    {
      id: 5,
      title: intl.formatMessage({ id: "New.This.Week" }),
      value: "new_this_week",
    },
    {
      id: 6,
      title: intl.formatMessage({ id: "Recently.Created.Contacts" }),
      value: "recently_created",
    },
    {
      id: 7,
      title: intl.formatMessage({ id: "Recently.Modified.Contacts" }),
      value: "recently_modified",
    },
    {
      id: 8,
      title: intl.formatMessage({ id: "Not.Yet.Viewed" }),
      value: "not_yet_viewed",
    },
  ];
};
export const viewDeal = (intl) => {
  return [
    {
      id: 1,
      title: intl.formatMessage({ id: "All.Deals" }),
      value: "all_contacts",
    },
    {
      id: 2,
      title: intl.formatMessage({ id: "My.Deals" }),
      value: "my_contacts",
    },
    {
      id: 3,
      title: intl.formatMessage({ id: "Unassigned" }),
      value: "unassigned",
    },
    {
      id: 4,
      title: intl.formatMessage({ id: "New.Last.Week" }),
      value: "new_last_week",
    },
    {
      id: 5,
      title: intl.formatMessage({ id: "New.This.Week" }),
      value: "new_this_week",
    },
    {
      id: 6,
      title: intl.formatMessage({ id: "Recently.Created.Deals" }),
      value: "recently_created",
    },
    {
      id: 7,
      title: intl.formatMessage({ id: "Recently.Modified.Deals" }),
      value: "recently_modified",
    },
    {
      id: 8,
      title: intl.formatMessage({ id: "Not.Yet.Viewed" }),
      value: "not_yet_viewed",
    },
  ];
};

export const agent = [
  { id: 1, title: "Unassigned" },
  { id: 2, title: "Agent 1" },
  { id: 3, title: "Agent 2" },
  { id: 4, title: "Agent 3" },
  { id: 5, title: "Agent 4" },
  { id: 6, title: "Agent 5" },
  { id: 7, title: "Agent 6" },
  { id: 8, title: "Agent 7" },
  { id: 9, title: "James" },
];

export const deal = (intl) => {
  return [
    { id: "Open", title: intl.formatMessage({ id: "Open" }) },
    { id: "Transit", title: intl.formatMessage({ id: "Transit" }) },
    { id: "Pipeline", title: intl.formatMessage({ id: "Pipeline" }) },
    { id: "Close-Won", title: intl.formatMessage({ id: "Close-Won" }) },
    { id: "Close-Lost", title: intl.formatMessage({ id: "Close-Lost" }) },
  ];
};

export const franchise = [
  { id: 1, title: "HL" },
  { id: 2, title: "DOR" },
  { id: 3, title: "BBC" },
  { id: 4, title: "BRK" },
];
