export const CreateTasksSchema = (intl, Yup) => {
  return Yup.object().shape({
    task_type: Yup.string().required(
      intl.formatMessage({ id: "Task.Required" })
    ),
    task_name: Yup.string().required(
      intl.formatMessage({ id: "TaskName.Required" })
    ),
    task_owner_id: Yup.string().required(
      intl.formatMessage({ id: "Task.Owner.Required" })
    ),
    task_description: Yup.string().required(
      intl.formatMessage({ id: "Task.Description.Required" })
    ),
    due_date: Yup.string().required(
      intl.formatMessage({ id: "Due.Date.Required" })
    ),
    priority: Yup.string().required(
      intl.formatMessage({ id: "Priority.required" })
    ),
    time: Yup.string().required(intl.formatMessage({ id: "Time.required" })),
  });
};
export const ChangeTasksSchema = (intl, Yup) => {
  return Yup.object().shape({
    task_owner_id: Yup.string().required(
      intl.formatMessage({ id: "Owner.Required" })
    ),
  });
};
