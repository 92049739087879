import React from "react";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  // FormLabel,
} from "@material-ui/core";
import { useEffect } from "react";
// import { useCallback } from "react";
import moment from "moment/moment";

export const ApplyChangesCard = ({ orderUIContext }) => {
  const [value, setValue] = React.useState("immediately");
  const [valueInvoice, setValueInvoice] = React.useState("immediately");
  const [prorate, setProrate] = React.useState(true);
  const [check, setCheck] = React.useState(false);

  const handleChange = (event, type) => {
    if (type === "gender2") {
      setValueInvoice(event.target.value);
    } else if (type === "prorate") {
      setProrate(event.target.checked);
    } else {
      setValue(event.target.value);
    }
    setCheck(true);
  };
  const handleApplyChanges = () => {
    const data = {};
    if (value === "immediately") {
      data.end_of_term = false;
      data.invoicing_option = valueInvoice === "immediately" ? true : false;
      data.prorate = prorate;
    } else if (value === "next_renewal") {
      data.end_of_term = true;
    }
    orderUIContext.dispatchReducer({
      type: "SET_DATA",
      data: {
        ...orderUIContext?.state,
        applyChanges: data,
        isBreakeDownApiCall: true,
      },
    });
  };

  useEffect(() => {
    if (check) handleApplyChanges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prorate, value, valueInvoice]);
  return (
    <>
      <div class="card-header align-items-center border-0 mt-4 mb-0">
        <div class="d-flex justify-content-between w-100 align-items-center">
          <h3 class="card-title align-items-start flex-column">
            <span class="font-weight-bolder text-dark">Apply Changes </span>
          </h3>
        </div>
      </div>
      <div className="card-body pt-0">
        <div className="py-4">
          <div class=" mb-4">
            <strong>When do you want to apply the changes?</strong>
            <p>
              If there are any charges, you can apply them immediately or on
              next renewal.
            </p>
          </div>
          <div>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="gender"
                name="gender1"
                value={value}
                onChange={(e) => handleChange(e, "gender")}
              >
                <FormControlLabel
                  value="immediately"
                  control={<Radio />}
                  label="Immediately"
                />
                {value === "immediately" && (
                  <div className=" ml-6 mb-4">
                    <div className="d-flex">
                      <label className="checkbox">
                        {/* {state.competitorPrice?.price && ( */}
                        <>
                          <input
                            type="checkbox"
                            name="enabled_for_checkout"
                            checked={prorate}
                            onChange={(e) => {
                              handleChange(e, "prorate");
                              setProrate(e.target.checked);
                            }}
                          />
                          <span className="mr-2"></span>
                        </>
                        {/* )} */}
                        <strong>Apply prorated credits and charges</strong>
                      </label>
                    </div>
                    <div className="ml-8 mt-2">
                      <div>
                        Enable to apply pro-rated credits to the subscription
                        and create a new invoice with the pro-rated charges.
                      </div>
                      <div>
                        * Pro-rated charges will not be applicable if there is a
                        change in the billing term (say, monthly to annual).
                      </div>
                      <div>
                        * For PHYSICAL GOODS, disable the checkbox, unless
                        explicitly required.
                      </div>
                    </div>
                  </div>
                )}

                <FormControlLabel
                  value="next_renewal"
                  control={<Radio />}
                  label={`On next renewal(${moment(
                    +orderUIContext?.state?.orderData?.next_billing_at * 1000
                  ).format("DD-MM-yyyy")})`}
                />
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      </div>
      {value === "immediately" && (
        <>
          <div class="card-header align-items-center border-0 mt-4 mb-0">
            <div class="d-flex justify-content-between w-100 align-items-center">
              <h3 class="card-title align-items-start flex-column">
                <span class="font-weight-bolder text-dark">
                  Invoicing Options{" "}
                </span>
              </h3>
            </div>
          </div>
          <div className="card-body pt-0">
            <div className="py-4">
              <div class=" mb-4">
                <strong>When should the invoice be generated?</strong>
                <p>
                  If there are any charges, you can generate an invoice
                  immediately, or add them to unbilled charges and invoice them
                  later.
                </p>
              </div>
              <div>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="gender"
                    name="gender2"
                    value={valueInvoice}
                    onChange={(e) => handleChange(e, "gender2")}
                  >
                    <FormControlLabel
                      value="immediately"
                      control={<Radio />}
                      label="Immediately"
                    />

                    <FormControlLabel
                      value="next_renewal"
                      control={<Radio />}
                      label="Add to unbilled charges"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
