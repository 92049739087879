import React from "react";
import { Dashboard } from "../../_metronic/_partials";
import { useSubheader } from "../../_metronic/layout";
import { useIntl } from "react-intl";

export function DashboardPage() {
  const intl = useIntl();
  const suhbeader = useSubheader();
  suhbeader.setTitle(intl.formatMessage({ id: "DASHBOARD" }));
  return <Dashboard />;
}
