import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import SnackBarTool from "../../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
// import { excemptPrice, requestPayment } from "../../../_redux/order/orderCrud";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import SVG from "react-inlinesvg";
import { Button, Table } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import { fetchMyOrderDetailCard } from "../../../../Order/_redux/order/orderAction";
import { fetchClassList } from "../../../_redux/franchises/franchisesActions";
import { useFormik } from "formik";
import { createClass } from "../../../_redux/franchises/franchisesCrud";
import { CircularProgress } from "@material-ui/core";

const ClassModal = ({ dialog, setDialog, orderId , fraId , edit }) => {
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [loading , setLoading] = useState(false)
  const intl = useIntl();
  const dispatch = useDispatch();
  const { classList, classLoading } = useSelector(
    (state) => ({
      classList: state.franchise.classList,
      classLoading: state.franchise.classLoading,
    }),
    shallowEqual
  );
  const initialValues = {
    franchise_id:fraId||"",
    site_id: edit === "edit"?dialog.data.site_id:dialog.data?.id || "",
    name:edit === "edit"? dialog.data.name:"",
  };
  const handleSubmit = (values) => {
    setLoading(true)
    setSnack({ call: false, type: "", message: "" });
    createClass(values , edit , dialog.data?.id)
      .then((res) => {
        setLoading(false)
        formik.resetForm();
        if(edit === "edit"){

          dispatch(fetchClassList(dialog.data?.id));
        }else{

          dispatch(fetchClassList(fraId));
        }
        setSnack({ call: true, type: "success", message: res.data.message });
        setTimeout(() => {
            setDialog(false)
        }, 3000);
      })
      .catch((error) => {
        setLoading(false)
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
  };
  // useEffect(() => {
  //   if (dialog.check) {
  //     console.log("dialog", dialog);
  //     dispatch(fetchClassList(dialog.data.id));
  //   }
  // }, [dialog.check]);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    // validationSchema: AutomateValidation,
    onSubmit: handleSubmit,
  });

  return (
    <Modal show={dialog} aria-labelledby="example-modal-sizes-title-lg">
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">Class</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div>
            <span className="mt-3 ">Class Name</span>
            <input
              type="text"
              name="name"
              placeholder={"Class Name"}
              label="External Name"
              className={`form-control  `}
              // max={3}
              // maxLength="3"
              {...formik.getFieldProps("name")}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.name}</div>
              </div>
            ) : null}
            <div className="mt-2" style={{ textAlign: "end" }}>
             
            </div>
          </div>
          {/* <div>
            {classLoading && (
              <div className="text-center mt-8">
                <CircularProgress />
              </div>
            )}
            {!classLoading && (
              <Table responsive className="mt-5">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th style={{ width: "20px" }}></th>
                    <th style={{ width: "20px" }}></th>
                  </tr>
                </thead>
                <tbody>
                  {classList
                    ? classList.map((data, index) => (
                        <tr key={index}>
                          <td>{data.name}</td>

                          <td>
                            <span
                              className="symbol-label ml-2 pointer"
                              onClick={() =>
                                setDialog({ check: true, data: data })
                              }
                            >
                              <span className="svg-icon svg-icon-sm svg-icon-primary">
                                <SVG
                                  className="h-25 align-self-end"
                                  title="Edit"
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/Design/Edit.svg"
                                  )}
                                ></SVG>
                              </span>
                            </span>
                          </td>
                          <td></td>
                          <td></td>
                        </tr>
                      ))
                    : ""}
                </tbody>
              </Table>
            )}
          </div> */}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={() => setDialog(false)}
            className="btn btn-light btn-elevate"
          >
            Close
          </button>
          <> </>
          <button
                type="button"
                className="btn btn-delete btn-elevate"
                onClick={() => formik.submitForm()}
                disabled={loading}
              >
                {edit === "edit"?"Edit":"ADD"}
                {loading && <span className="ml-1 spinner spinner-white"></span>}
              </button>
         
        </div>
      </Modal.Footer>
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </Modal>
  );
};

export default ClassModal;
