import axios from "axios";
// Get the input classes for a field based on its validation status
export const getInputClasses = (formik, fieldname) => {
  if (!fieldname) return "";
  if (formik?.touched[fieldname] && formik?.errors[fieldname]) {
    return "is-invalid";
  }

  if (formik?.touched[fieldname] && !formik?.errors[fieldname]) {
    return "is-valid";
  }

  return "";
};

export const breakString = (str, word) => {
  let index = str.indexOf(word);
  if (index === -1) {
    return str;
  } else {
    return [str.substring(0, index), str.substring(index)];
  }
};

export const convertKilometersToMiles = (kilometers) => {
  const km = kilometers.split(" ");
  const miles = Number(km[0]) * 0.621371;
  let rounded = Math.round(miles * 10) / 10;

  return rounded;
};

export const calculateExtraPrice = (miles, priceItem) => {
  const price = miles * priceItem;
  return price;
};

export function convertGBPtoPoundSymbol(gbpString) {
  const poundSymbol = "£";
  const regex = /GBP/gi;
  const regexG = /GB/gi;
  const data = gbpString.replace(
    gbpString === "GBP" ? regex : regexG,
    poundSymbol
  );
  return data;
}
export const fulfillmentStatus = (fulfillment_stage) => {
  const number = Number(fulfillment_stage);
  switch (number) {
    case 1:
      return { now: 40, title: "Col" };
    case 2:
      return { now: 60, title: "Sto" };
    case 3:
      return { now: 60, title: "Acc" };
    case 4:
      return { now: 100, title: "Part Ret" };
    case 5:
      return { now: 100, title: "Ret" };
    case 6:
      return { now: 100, title: "Can" };
    case 7:
      return { now: 100, title: "Col-Ret" };

    default:
      break;
  }
};

export const WeekCounter = (startDate, endDate) => {
  // Calculate the number of weeks between the start and end date
  const diffInDays =
    (new Date(endDate) - new Date(startDate)) / (1000 * 3600 * 24);
  const numOfWeeks = Math.ceil(diffInDays / 7);

  // Render the result
  return numOfWeeks;
};

export const totalCalculation = (array) => {
  const sum = array.reduce((total, discount) => {
    return total + discount.amount;
  }, 0);
  const data = Number(sum) / 100;
  let twoDigit = Number(data).toFixed(2);
  return twoDigit;
};

export const insuranceCalculation = (qty) => {
  if (qty === 1) {
    return 4000;
  } else if (qty > 1) {
    return 4000 + (qty - 1) * 2000;
  } else {
    // Handle invalid quantity input
    return 4000;
  }
};

export const abortApi = (URLs) => {
  const controller = new AbortController();

  axios
    .get(URLs, {
      signal: controller.signal,
    })
    .then(function (response) {
      //...
    });
  // cancel the request
  controller.abort();
};

export const loadUserGuidingScript = () => {
  window.userGuiding = window.userGuiding || [];
  const script = document.createElement("script");
  script.async = true;
  script.src = `https://static.userguiding.com/media/user-guiding-766230265ID-embedded.js`;
  const firstScriptTag = document.getElementsByTagName("script")[0];
  firstScriptTag.parentNode.insertBefore(script, firstScriptTag);
  if (window.userGuidingLayer) return;
  window.userGuidingLayer = window.userGuidingLayer || {};
  const methods = [
    "previewGuide",
    "finishPreview",
    "track",
    "identify",
    "triggerNps",
    "hideChecklist",
    "launchChecklist",
  ];
  methods.forEach((method) => {
    window.userGuidingLayer[method] = function () {
      window.userGuiding.push([method, arguments]);
    };
  });
};

export const matchRemoveHash = (value) => {
  const str = value;
  const regex = /##(.*?)##/;
  const match = str.match(regex);
  if (match) {
    const data = match[1];
    return data;
  } else {
    return "";
  }
};

export const addTwoValue = (a, b) => {
  return +a - +b;
};

export const removeLastComma = (address) => {
  const lastIndex = address?.lastIndexOf(",") || "";
  if (lastIndex !== -1) {
    const updatedAddress =
      address?.substring(0, lastIndex) + address?.substring(lastIndex + 1);
    return updatedAddress;
  }
  return address;
};

export const divideByHundred = (value) => {
  const data = value ? Number(value) / 100 : 0;
  return data;
};

export const isDateWithinNext3Days = (dateToCheck) => {
  if (!dateToCheck) return;

  // Get the current date without the time component
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  // Calculate the date 3 business days in the future from the current date
  let threeBusinessDaysInFuture = new Date(currentDate);
  let businessDaysCount = 0;

  while (businessDaysCount < 2) {
    threeBusinessDaysInFuture.setDate(threeBusinessDaysInFuture.getDate() + 1);

    // Check if the day is not Saturday (6) or Sunday (0)
    if (
      threeBusinessDaysInFuture.getDay() !== 0 &&
      threeBusinessDaysInFuture.getDay() !== 6
    ) {
      businessDaysCount++;
    }
  }

  // Convert the input date string to a Date object and remove the time component
  const inputDate = new Date(dateToCheck);
  inputDate.setHours(0, 0, 0, 0);

  // Compare the input date with the calculated business date range
  return inputDate > currentDate && inputDate <= threeBusinessDaysInFuture;
};
export const isDateToday = (dateToCheck) => {
  if (!dateToCheck) return false;

  // Get the current date without the time component
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  // Convert the input date string to a Date object and remove the time component
  const inputDate = new Date(dateToCheck);
  inputDate.setHours(0, 0, 0, 0);

  // Compare the input date with today's date
  return inputDate.getTime() === currentDate.getTime();
};
const isWeekend = (date) => {
  const day = date.getDay();
  return day === 0 || day === 6; // Sunday (0) or Saturday (6)
};

// Function to check if given date is one weekday after the current date
export const isOneWeekdayGap = (givenDate) => {
  const given = new Date(givenDate);
  const today = new Date();

  // Calculate the difference in milliseconds between the two dates
  const difference = given.getTime() - today.getTime();
  // Calculate the number of days between the two dates
  const daysDifference = Math.ceil(difference / (1000 * 60 * 60 * 24));

  if (daysDifference === 1) {
    return true;
  }
  // Check if the given date is exactly one day after today and consider weekend adjustments
  if (daysDifference === 2 || daysDifference === 3) {
    // If today is Friday, then given date cannot be Saturday (weekend adjustment)
    if (today.getDay() === 5 && (given.getDay() === 1 || given.getDay() === 6 || given.getDay() === 0)) {

      return true;
    }
    // If today is Saturday, then given date can be Monday
    if (today.getDay() === 6 && (given.getDay() === 1 || given.getDay() === 6 || given.getDay() === 0)) {

      return true;
    }
    // If today is Sunday, then given date can be Monday
    if (today.getDay() === 0 && (given.getDay() === 1 || given.getDay() === 6 || given.getDay() === 0)) {

      return true;
    }
    // If neither date is on the weekend, return true
    return false;
  }

  // Return false if the days difference is not exactly one
  return false;
};
export async function imageUrlToFile(imageUrl, index) {
  try {
    // Fetch the image from the URL
    const response = await fetch(imageUrl);

    // Check if the request was successful
    if (!response.ok) {
      throw new Error('Failed to fetch image');
    }

    // Convert the image response to a blob (binary data)
    const blob = await response.blob();

    // Create a File object from the blob with a unique name (e.g., 'image.jpg')
    const file = new File([blob], `${Math.random() * 10000}.jpg`, { type: 'image/jpeg' });

    return file;
  } catch (error) {
    console.error('Error converting image URL to file:', error);
    throw error; // Rethrow the error to handle it outside of this function
  }
}


export function isWithinTwoWorkingDaysFromToday(futureDate, currentDate = new Date()) {
  function getLastWorkingDateBefore(futureDate) {
    const future = new Date(futureDate);

    // Initialize a counter for skipped days
    let skippedDays = 0;

    // Iterate backwards from the future date
    for (let i = 1; i <= 2 + skippedDays; i++) {
      const workingDate = new Date(future);
      workingDate.setDate(future.getDate() - i);

      // Check if the working date is not a weekend (Saturday or Sunday)
      const dayOfWeek = workingDate.getDay();
      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        continue; // Skip non-weekend days
      } else {
        skippedDays++; // Increment the counter for skipped days
      }
    }

    // Return the last working date before the future date
    const lastWorkingDate = new Date(future);
    lastWorkingDate.setDate(future.getDate() - (2 + skippedDays));
    return lastWorkingDate;
  }
  const lastWorkingDate = getLastWorkingDateBefore(futureDate);
  return currentDate >= lastWorkingDate;
}



// Example usage:
// const futureDate = '2024-05-20'; // Replace '2024-05-20' with your future date
// const currentDate = new Date('2024-05-18'); // Replace '2024-05-17' with the current date
// console.log(isWithinTwoWorkingDaysFromToday(futureDate, currentDate)); // Output: true or false
