/* eslint-disable jsx-a11y/anchor-is-valid */

import NoRecord from "../../../../_metronic/_partials/widgets/NoRecord";
import React from "react";
import { Table } from "react-bootstrap";
import { divideByHundred } from "../../../utils/canvaCloseFunction";
import { useIntl } from "react-intl";
import {
  convertGBPtoPoundSymbol,
  matchRemoveHash,
} from "../../../utils/commonFunction";
// import SVG from "react-inlinesvg";
// import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
// import { Link } from "react-router-dom";

export function OrderSummaryDetailTable({
  orderDetail,
  value,
  type,
  orderInvoice,
  orderInvoiceLoading,
}) {
  const intl = useIntl();

  // const handleDelete = (id) => {
  //   const arr = [...value?.line_items];
  //   const filteredArr = arr.filter((item) => item.product_id !== id);
  //   const mappedArr = filteredArr.map((item) => item.product_id);
  // };
  return (
    <div className={type ? "customCheck" : ""}>
      {type === "first_invoice" &&
        !value?.line_items &&
        !orderInvoiceLoading && (
          <a
            href={`https://book.easystorage.com/backoffice/orders/pdf/${orderDetail?.id}?v1=true&order_id=${orderDetail?.id}`}
          >
            {orderDetail?.booking_reference || "-"}: Download Invoice
          </a>
        )}
      {/* {(value?.line_items && value?.line_items?.length > 0) ||
      orderInvoiceLoading ? ( */}
      {type === "first_invoice" &&
      !value?.line_items &&
      !orderInvoiceLoading ? (
        ""
      ) : (
        <Table responsive className="summerytable customColor">
          <thead>
            <tr>
              {/* {type !== "last" ? 

            <th>{intl.formatMessage({ id: "ID" })}</th>
             : ""} */}
              {type !== "last" ? (
                <th colSpan={2}>{intl.formatMessage({ id: "Product" })}</th>
              ) : (
                <th colSpan={2}>{intl.formatMessage({ id: "Product" })}</th>
              )}
              {/* <th></th> */}
              <th style={{ textAlign: "right" }}>
                {intl.formatMessage({ id: "Qty" })}
              </th>
              <th style={{ textAlign: "right" }}>
                {intl.formatMessage({ id: "Total" })}
              </th>
            </tr>
          </thead>
          <tbody>
            {value?.line_items ? (
              value?.line_items?.map((item, index) => (
                <tr key={index}>
                  {/* {type !== "last" ? <td>{item?.product_id || "-"}</td> : ""} */}
                  {type !== "last" ? (
                    <td colSpan={2} git>
                      {item?.product_name || "-"}
                    </td>
                  ) : (
                    <td colSpan={2}>
                      {item?.product_name +
                        " " +
                        `(${item?.description || ""})` || "-"}
                    </td>
                  )}
                  <td style={{ textAlign: "right" }}>
                    {item ? item?.quantity : "N/A"}
                  </td>
                  <td className="">
                    {orderDetail?.currency
                      ? convertGBPtoPoundSymbol(orderDetail?.currency)
                      : "-"}

                    <span style={{ float: "right" }}>
                      {" "}
                      {item?.amount === 0
                        ? 0
                        : divideByHundred(item?.amount || null)}
                    </span>
                  </td>
                  {/* <td className="cart-icon">
                  {item?.entity_type === "addon_item_price" && (
                    <span className="svg-icon svg-icon-sm svg-icon-primary ">
                      <SVG
                        title="Delete"
                        src={toAbsoluteUrl(
                          "/media/svg/icons/General/Trash.svg"
                        )}
                        onClick={() => handleDelete(item?.product_id)}
                      />
                    </span>
                  )}
                </td> */}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={"5"}>
                  {orderInvoiceLoading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        height: "50%",
                      }}
                    >
                      <span className="ml-2 mr-2 mt-4 spinner spinner-black"></span>
                    </div>
                  ) : (
                    <>
                      <NoRecord />
                    </>
                  )}
                </td>
              </tr>
            )}
            {value?.line_items && value?.line_items.length > 0 ? (
              <>
                <tr>
                  <td></td>
                  {/* <td></td> */}
                  <td className="text-right" colSpan={2}>
                    <strong> {intl.formatMessage({ id: "Subtotal" })}</strong>
                  </td>
                  <td className="text-left">
                    {}
                    {orderDetail?.currency
                      ? convertGBPtoPoundSymbol(orderDetail?.currency)
                      : "-"}
                    <span style={{ float: "right" }}>
                      {(
                        Number(divideByHundred(value?.sub_total)) -
                        (value?.tax ? Number(divideByHundred(value?.tax)) : 0)
                      ).toFixed(2)
                      // +
                      //   (value?.discounts?.length > 0
                      //     ? Number(totalCalculation(value?.discounts))
                      //     : 0)
                      }{" "}
                      {}{" "}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="bord-top"></td>
                  {/* <td className="bord-top"></td> */}
                  {/* <td ></td> */}
                  <td className="bord-top text-right" colSpan={2}>
                    <strong>
                      {intl.formatMessage({ id: "VAT" })} (
                      {orderDetail?.franchise_details?.tax_rate +
                        "% exclusive" || "-"}
                      )
                    </strong>
                  </td>
                  <td className="bord-top ">
                    {orderDetail?.currency
                      ? convertGBPtoPoundSymbol(orderDetail?.currency)
                      : "-"}

                    <span style={{ float: "right" }}>
                      {" "}
                      {divideByHundred(value?.tax)}{" "}
                    </span>
                  </td>
                </tr>
                {value?.discounts?.map((item, index) => (
                  <tr>
                    <td className="bord-top"></td>
                    {/* <td className="bord-top"></td> */}

                    <td className="bord-top text-right" colSpan={2}>
                      <strong> {item?.description || null}</strong>
                    </td>
                    <td className="bord-top text-left">
                      {orderDetail?.currency
                        ? convertGBPtoPoundSymbol(orderDetail?.currency)
                        : "-"}

                      <span style={{ float: "right" }}>
                        {" "}
                        {divideByHundred(item?.amount)}{" "}
                      </span>
                    </td>
                    <td className="bord-top"></td>
                  </tr>
                )) || null}
                <tr>
                  <td className="bord-top">
                    {(value?.notes?.length > 0 &&
                      matchRemoveHash(value?.notes[0]?.note)) ||
                      ""}
                  </td>
                  {/* <td className="bord-top"></td> */}
                  <td className="bord-top  text-right" colSpan={2}>
                    <strong> {intl.formatMessage({ id: "Total" })}</strong>
                  </td>
                  <td className="bord-top text-left">
                    {orderDetail?.currency
                      ? convertGBPtoPoundSymbol(orderDetail?.currency)
                      : "-"}

                    <span style={{ float: "right" }}>
                      {" "}
                      {divideByHundred(value?.total)}{" "}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="bord-top"></td>
                  {/* <td className="bord-top"></td> */}
                  <td className="bord-top  text-right" colSpan={2}>
                    <strong>
                      {" "}
                      {intl.formatMessage({ id: "Amount.Paid" })}
                    </strong>
                  </td>
                  <td className="bord-top  d-flex justify-content-between ">
                    {orderDetail?.currency
                      ? convertGBPtoPoundSymbol(orderDetail?.currency)
                      : "-"}

                    {/* {value?.amount_paid ? "-" : null} */}
                    <span>
                      {" "}
                      {value?.amount_paid
                        ? "-" + divideByHundred(value?.amount_paid)
                        : "0"}{" "}
                    </span>
                  </td>
                </tr>
                {value?.credits_applied ? (
                  <tr>
                    <td className="bord-top"></td>
                    {/* <td className="bord-top"></td> */}
                    <td className="bord-top  text-right" colSpan={2}>
                      <strong>
                        {intl.formatMessage({ id: "Credits.Applied" })}
                      </strong>
                    </td>
                    <td className="bord-top text-left d-flex ">
                      {orderDetail?.currency
                        ? convertGBPtoPoundSymbol(orderDetail?.currency)
                        : "-"}
                      <span style={{ float: "right" }}>
                        -{divideByHundred(value?.credits_applied)}{" "}
                      </span>
                    </td>
                  </tr>
                ) : null}
              </>
            ) : null}
          </tbody>
        </Table>
      )}
    </div>
  );
}
