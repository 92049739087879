/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

import { FormControl, MenuItem, Select } from "@material-ui/core/";

import { CustomerDetailSchema } from "../order-form/validationSchema";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { useFormik } from "formik";

export function CustomerDetailEdit() {
  const initialValues = {
    fname: "",
    lname: "",
    email: "",
    phone: "",
    postCode: "",
    city: "",
    address: "",
    franchise: "",
  };

  const handleSubmit = (values, actions) => {
    actions.resetForm({ values: "" });
  };

  const handleReset = () => {};

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: CustomerDetailSchema,
    onSubmit: handleSubmit,
    onReset: handleReset,
  });

  return (
    <div
      id="kt_customerdetails_panel"
      className="offcanvas offcanvas-right pt-5 pb-10 "
    >
      {/*begin::Header*/}
      <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
        <div
          className="offcanvas-close mt-n1 pr-5"
          style={{ position: "absolute", top: "15px", right: "10px" }}
        >
          <a
            href="#"
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            id="kt_customerdetails_panel_close"
          >
            <i className="ki ki-close icon-sm text-muted"></i>
          </a>
        </div>
      </div>
      {/*end::Header*/}
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <div className="offcanvas-content px-10 ">
          <div>
            <h5>Customer Details</h5>
          </div>
          <div
            className={`tab-pane fade pt-3 pr-5 mr-n5 scroll  active show `}
            id="kt_quick_panel_logs_kt_customerdetails_panel"
          >
            <div className="row mt-10">
              <div className="col-6">
                <label>First Name</label>
                <div className="form-groupB">
                  <input
                    type="text"
                    className={`form-control  ${getInputClasses("fname")}`}
                    placeholder="First name"
                    name="fname"
                    {...formik.getFieldProps("fname")}
                  />
                  {formik.touched.fname && formik.errors.fname ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.fname}</div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-6">
                <label>Last Name</label>
                <div className="form-groupB">
                  <input
                    type="text"
                    className={`form-control  ${getInputClasses("lname")}`}
                    placeholder="Last name"
                    name="lname"
                    {...formik.getFieldProps("lname")}
                  />
                  {formik.touched.lname && formik.errors.lname ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.lname}</div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-12 ">
                <label>Email</label>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="form-groupB w-100">
                    <input
                      type="email"
                      className={`form-control  ${getInputClasses("email")}`}
                      placeholder="Email"
                      name="email"
                      {...formik.getFieldProps("email")}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.email}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div
                    className={
                      formik.touched.email && formik.errors.email
                        ? "mb-8"
                        : "mb-2"
                    }
                  >
                    <span className="symbol-label ml-2">
                      <span
                        className="svg-icon svg-icon-lg svg-icon-primary"
                        id="kt_contactdetails_panel_toggle"
                      >
                        <SVG
                          title=" "
                          className="h-25 align-self-end"
                          src={toAbsoluteUrl("/media/svg/icons/Code/Plus.svg")}
                        ></SVG>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <label>Phone</label>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="form-groupB w-100">
                    <input
                      type="text"
                      className={`form-control  ${getInputClasses("phone")}`}
                      placeholder="Phone"
                      name="phone"
                      {...formik.getFieldProps("phone")}
                    />
                    {formik.touched.phone && formik.errors.phone ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.phone}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div
                    className={
                      formik.touched.phone && formik.errors.phone
                        ? "mb-8"
                        : "mb-2"
                    }
                  >
                    <span className="symbol-label ml-2">
                      <span
                        className="svg-icon svg-icon-lg svg-icon-primary"
                        id="kt_contactdetails_panel_toggle"
                      >
                        <SVG
                          title=" "
                          className="h-25 align-self-end"
                          src={toAbsoluteUrl("/media/svg/icons/Code/Plus.svg")}
                        ></SVG>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <label>Post Code</label>
                <div className="row align-items-center">
                  <div className="col-6 d-flex justify-content-between align-items-center ">
                    <div className="form-groupB w-100">
                      <input
                        type="text"
                        className={`form-control  ${getInputClasses(
                          "postCode"
                        )}`}
                        placeholder="Post Code"
                        name="postCode"
                        {...formik.getFieldProps("postCode")}
                      />
                    </div>
                    <div className="mb-2">
                      <span className="symbol-label ml-2">
                        <span
                          className="svg-icon svg-icon-lg svg-icon-primary"
                          id="kt_contactdetails_panel_toggle"
                        >
                          <SVG
                            title=" "
                            className="h-25 align-self-end"
                            src={toAbsoluteUrl(
                              "/media/svg/icons/General/Search.svg"
                            )}
                          ></SVG>
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6 mb-2 ">
                    <a href="">Enter Manually</a>
                  </div>
                  {formik.touched.postCode && formik.errors.postCode ? (
                    <div className=" ml-4 mb-4 fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.postCode}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-12">
                <label>Address</label>
                <div className="form-groupB">
                  <FormControl fullWidth>
                    <Select
                      name="address"
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                      className={`form-control  ${getInputClasses("address")}`}
                      IconComponent={(props) => (
                        <i {...props}>
                          <KeyboardArrowDownIcon />
                        </i>
                      )}
                      inputProps={{ "aria-label": "Without label" }}
                      displayEmpty
                      {...formik.getFieldProps("address")}
                    >
                      <MenuItem value=""> Address</MenuItem>
                      <MenuItem value="1"> Address 1</MenuItem>
                    </Select>
                  </FormControl>
                  {formik.touched.address && formik.errors.address ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.address}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-12">
                <label>City</label>
                <div className="form-groupB">
                  <input
                    type="text"
                    className={`form-control  ${getInputClasses("city")}`}
                    placeholder="City"
                    name="city"
                    {...formik.getFieldProps("city")}
                  />
                  {formik.touched.city && formik.errors.city ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.city}</div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-6">
                <label>Agent</label>
                <div className="form-groupB">
                  <FormControl fullWidth>
                    <Select
                      name="franchise"
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                      className={`form-control  ${getInputClasses(
                        "franchise"
                      )}`}
                      IconComponent={(props) => (
                        <i {...props}>
                          <KeyboardArrowDownIcon />
                        </i>
                      )}
                      inputProps={{ "aria-label": "Without label" }}
                      displayEmpty
                      {...formik.getFieldProps("franchise")}
                    >
                      <MenuItem value=""> Agent</MenuItem>
                      <MenuItem value="1"> Agent 1</MenuItem>
                    </Select>
                  </FormControl>
                  {formik.touched.franchise && formik.errors.franchise ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.franchise}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-6">
                <label>Franchise</label>
                <div className="form-groupB">
                  <FormControl fullWidth>
                    <Select
                      name="franchise"
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                      className={`form-control  ${getInputClasses(
                        "franchise"
                      )}`}
                      IconComponent={(props) => (
                        <i {...props}>
                          <KeyboardArrowDownIcon />
                        </i>
                      )}
                      inputProps={{ "aria-label": "Without label" }}
                      displayEmpty
                      {...formik.getFieldProps("franchise")}
                    >
                      <MenuItem value=""> Franchise</MenuItem>
                      <MenuItem value="1"> Franchise 1</MenuItem>
                    </Select>
                  </FormControl>
                  {formik.touched.franchise && formik.errors.franchise ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.franchise}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="offcanvas-footer">
          <div className="offcanvas-content text-right ">
            <button
              type="reset"
              className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary font-weight-bolder font-size-sm "
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
