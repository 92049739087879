import { createSlice } from "@reduxjs/toolkit";

const initialPromotionsState = {
  list: {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    productForEdit: undefined,
    lastError: null,
    nextoffset: [],
    loading: false,
  },
  detail: {},
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const promotionsSlice = createSlice({
  name: "Promotions",
  initialState: initialPromotionsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    // findProducts
    promotionsFetched: (state, action) => {
      const { totalCount, entities, nextoffset, loading } = action.payload;
      state.list.listLoading = false;
      state.list.error = null;
      state.list.entities = entities;
      state.list.totalCount = totalCount;
      state.list.nextoffset = nextoffset;
      state.list.loading = loading;
    },

    promoDetails: (state, action) => {
      const { data } = action.payload;
      state.detail = data;
    },
  },
});
