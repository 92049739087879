import { Card, CardBody } from "../../../_metronic/_partials/controls";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import NewBreadCrumbs from "../../../_metronic/layout/components/subheader/components/NewBreadCrumbs";
import NotAuthorized from "../../../_metronic/_partials/widgets/NotAuthorized";
import SnackBarTool from "../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { fetchPermission } from "../../pages/redux/permission";
import { fetchQTasks } from "./_redux/QTasksAction";
import { initIds } from "../../../_metronic/_partials/controls/RightPanel";
import { initialFilter } from "./TasksUIHelpers";
import { TasksFilter } from "./tasks-filter/TasksFilter";
import { useIntl } from "react-intl";
import { useQTasksUIContext } from "./TasksUIContext";
import QuotesTable from "./tasks-table/QuotesTable";

export const QuotesPage = () => {
  // Use context for managing UI state for tasks
  const tasksUIContext = useQTasksUIContext();
  // Use the `intl` hook multilanguage
  const intl = useIntl();

  // Use the `dispatch` hook for dispatching actions
  const dispatch = useDispatch();

  // Get the current user's permissions from the redux store
  const { permission } = useSelector(
    (state) => ({
      permission: state.auth.permission,
    }),
    shallowEqual
  );

  // Initialize list of panel ids to toggle
  const init = ["kt_create_tasks_panel"];
  const inits = ["kt_change_tasks_panel"];

  // Initialize state variables
  const [check, setCheck] = useState(false);
  const [active, setActive] = useState(false);
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [show, setShow] = useState(true);

  // Toggle panel ids when `check` state changes
  useEffect(() => {
    if (check) {
      initIds(inits);
    }
    initIds(init);
  }, [check, init, inits]);

  // Handle completing tasks
  const handleComplete = (submitFunction) => {
    setShow(false);
    setSnack({ call: false, type: "", message: "" });
    const data = {
      task_id: tasksUIContext.ids,
    };
    submitFunction(data)
      .then((res) => {
        // Display success message and fetch updated tasks
        setSnack({ call: true, type: "success", message: res.data.message });
        dispatch(fetchQTasks(initialFilter));
        tasksUIContext.setIds([]);
        setShow(true);
      })
      .catch(() => {
        // Set `show` to true if there is an error
        setShow(true);
      });
  };

  // Handle task selection for certain actions
  const handleSelect = (action, taskSelect) => {
    setSnack({ call: false, type: "", message: "" });
    if (taskSelect?.length === 0) {
      // Display error message if no tasks are selected
      setSnack({
        call: true,
        type: "error",
        message: "Please Select a Task",
      });
    } else {
      if (action !== "change") {
        setActive({ type: true, action: action });
      }
    }
    setTimeout(() => {
      setSnack({ call: false, type: "", message: "" });
    }, 2000);
  };

  // Fetch the current user's permissions
  useEffect(() => {
    dispatch(fetchPermission());
  }, [dispatch]);

  return (
    <>
      <div className="d-flex pb-5" style={{ justifyContent: "space-between" }}>
        <NewBreadCrumbs breadcrumb={true} title="Quotes" />
        <div
          style={{
            pointerEvents:
              permission?.task?.create || permission?.task?.edit ? "" : "none",
          }}
        ></div>
      </div>
      <Card>
        <CardBody>
          {permission?.task?.view ? (
            <>
              <TasksFilter />
              <QuotesTable
                show={show}
                setShow={setShow}
                permission={permission}
              />
            </>
          ) : (
            <NotAuthorized />
          )}{" "}
        </CardBody>
      </Card>
      {/* modal for save dialog */}
      {/* <SaveDialog
        setActive={setActive}
        active={active}
        handleComplete={handleComplete}
      /> */}
      {/* task create canva modal */}
      {/* <CreateTasksCanva /> */}
      {/* snack bar component */}
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </>
  );
};
