import React from "react";
import { Modal } from "react-bootstrap";

const ActiveDialog = ({ active, setActive, dialog, setDialog, formik }) => {
  const handleChange = () => {
    formik.setFieldValue("status", active ? "archived" : "active");
    setActive(active ? false : true);
    setDialog(false);
  };
  return (
    <Modal show={dialog} aria-labelledby="example-modal-sizes-title-lg">
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          {active ? "Deactivate" : "Activate"} Promotion
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>
          Are you sure you want to {active ? "deactivate" : "activate"} this
          Promotion?
        </span>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={() => setDialog(false)}
            className="btn btn-light btn-elevate"
          >
            No
          </button>
          <> </>
          <button
            type="button"
            onClick={handleChange}
            className="btn btn-delete btn-elevate"
          >
            Yes
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ActiveDialog;
