import { createSlice } from "@reduxjs/toolkit";

const initialProductsState = {
  list: {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    productForEdit: undefined,
    lastError: null,
    nextoffset: "",
    loading: false,
  },
  detail: { loading: false },
  franchiseList: [],
  timeline: [],
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const productsSlice = createSlice({
  name: "products",
  initialState: initialProductsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    productFetched: (state, action) => {
      const { totalCount, entities, nextoffset, loading } = action.payload;
      state.list.listLoading = false;
      state.list.error = null;
      state.list.entities = entities;
      state.list.totalCount = totalCount;
      state.list.nextoffset = nextoffset;
      state.list.loading = loading;
    },
    productDetailFetched: (state, action) => {
      const { data } = action.payload;

      state.detail = data;
    },
    productFranchiseFetched: (state, action) => {
      const { data } = action.payload;

      state.franchiseList = data;
    },
    productTimelineFetched: (state, action) => {
      const { data } = action.payload;
      state.timeline = data;
    },
  },
});
