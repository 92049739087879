import React from "react";
import moment from "moment";
import NoRecord from "../NoRecord";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { capitalizeFirstLetter } from "../../../../app/utils/canvaCloseFunction";
export function Feeds({ data, type, check, classNameSend , validation }) {

  const intl = useIntl();
  const history = useHistory();
  const handleRedirect = (id, check) => {
    if (check === "customer") {
      history.push(`/order-details/${id}`);
    }
  };
  return (
    <>
      <div
        className={`card card-custom ${
          classNameSend ? classNameSend : "card-stretch"
        } gutter-b `}
      >
        <div className="card-header align-items-center border-0 mt-4">
          <h3 className="card-title align-items-start flex-column">
            <span className="font-weight-bolder text-dark">
              {intl.formatMessage({ id: "Timeline" })}
            </span>
            {/* <span className="text-muted mt-3 font-weight-bold font-size-sm">
              890,344 Sales
            </span> */}
          </h3>
        </div>
        <div className="card-body pt-4 timelinesect">
          <div
            className={data && data.length ? "timeline timeline-6 mt-3 " : ""}
          >
            <div className="timelinelist">
              {data && data.length ? (
                data.map((item, index) => (
                  <div className="timeline-item align-items-start" key={index}>
                    <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg">
                      {new Date(item.created_at).getDate() ===
                        new Date().getDate() &&
                      new Date(item.created_at).getMonth() ===
                        new Date().getMonth()
                        ? intl.formatMessage({ id: "Today" }) +
                          " " +
                          moment(item.created_at).format("HH:mm")
                        : new Date(item.created_at).getDate() ===
                            new Date().getDate() - 1 &&
                          new Date(item.created_at).getMonth() ===
                            new Date().getMonth()
                        ? intl.formatMessage({ id: "Yesterday" }) +
                          " " +
                          moment(item.created_at).format("HH:mm")
                        : moment(item.created_at).format("DD-MM-YYYY HH:mm")}
                    </div>

                    <div className="timeline-badge">
                      <i className="fa fa-genderless text-primary icon-xl"></i>
                    </div>

                    {type === "contact" && item.properties.deal_id ? (
                      <Link
                        to={
                          item && item.properties
                            ? `/deal-view/${item.properties.deal_id}`
                            : "sss"
                        }
                        className="timeline-content font-weight-bolder font-size-lg  text-dark-75 pl-3"
                      >
                        {item?.description}
                        <span className="text-primary">
                          {" "}
                          {/* {item && item.properties && item.properties.deal_id
                            ? "(Deal)"
                            : "(Contact)"} */}
                        </span>
                      </Link>
                    ) : (
                      <div className="timeline-content font-weight-bolder font-size-lg  text-dark-75 pl-3">
                        {type === "customer" && validation === "franchise" ?
                         <div
                            // onClick={() =>
                            //   handleRedirect(item?.subject_id, check)
                            // }
                            style={{ cursor: "pointer" }}
                          >
                            {item?.description?.split("#")?.[0] || ""}
                            <div className="font-weight-light font-size-sm">
                              #
                              {item?.properties.price_increase_type ? "Price increase type: "+ capitalizeFirstLetter(item?.properties.price_increase_type) :""} &nbsp;&nbsp;{item?.properties.price_increase_value ? `Price increase value: ${item?.properties.price_increase_type === "fixed"?"£":"%"}`+ capitalizeFirstLetter(item?.properties.price_increase_value) :""}
                            </div>
                          </div>:type === "customer" ? (
                          <div
                            onClick={() =>
                              handleRedirect(item?.subject_id, check)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {item?.description?.split("#")?.[0] || ""}
                            {item?.subject?.booking_reference &&
                            <div className="font-weight-light font-size-sm">
                              #
                              {item?.description?.split("#")?.[1] ||
                                item?.subject?.booking_reference ||
                                "-"}
                            </div>
}
                          </div>
                        ) : (
                          <>{item?.description}</>
                        )}
                        {/* <span className="text-primary"> (Deal)</span> */}
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <NoRecord />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
