/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

import React, { useEffect, useState } from "react";
import {
  fetchMyOrderDetailCard,
  fetchMyOrderTimeline,
  fetchVersion,
  // fetchVersion,
} from "../_redux/order/orderAction";

import DiscardDialog from "../../../../_metronic/_partials/widgets/alert/DiscardDialog";
import { Form } from "react-bootstrap";
import SnackBarTool from "../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { canvaCancel } from "../../../utils/canvaCloseFunction";
import { containerEdit, deleteContainer, updateStorageDetail } from "../_redux/order/orderCrud";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useFormik } from "formik";
import EditDepotModal from "../order-modal/EditDepotModal";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Pagination, Navigation } from "swiper";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/navigation/navigation.min.css";
import DeleteDialog from "../../../../_metronic/_partials/widgets/alert/DeleteDialog";
// import { StorageSchema } from "../order-form/validationSchema";
// import "swiper/css/pagination";
// import "swiper/css/navigation";

export function StorageDetail({ orderDetail }) {
  const dispatch = useDispatch();

  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [dialogDis, setDialogDis] = useState(false);
  const [dialog, setDialog] = useState({ check: false });
  const [check, setCheck] = useState(false);
  const [editCon, setEditCon] = useState(null);
  const [dialogDelete, setDialogDelete] = useState(false)
  const [containerDelete, setContainerDelete] = useState(null)

  const initialValues = {
    order_id: orderDetail?.id || "",
    pod_data: [],
  };

  const { storageImages, containerDetail } = useSelector(
    (state) => ({
      storageImages: state.order.versionDetail.storageImages,
      containerDetail: state.order.versionDetail?.containerDetails,
    }),
    shallowEqual
  );
  // useEffect(() => {
  //   if (orderDetail.id) {
  //     dispatch(fetchVersion(orderDetail.franchise_id, orderDetail.id));
  //   }
  // }, [dispatch, orderDetail.franchise_id, orderDetail.id]);
  const handleContainerUpdate = () => {
    setSnack({ call: false, type: "", message: "" });
    const data = {
      order_id: orderDetail.id,
      franchise_container_id: editCon?.value?.id,
      reference_unique_id: editCon?.reference_unique_id,
    };
    containerEdit(data)
      .then((res) => {
        setEditCon(null);
        setSnack({ call: true, type: "success", message: res.data.message });
        dispatch(fetchVersion(orderDetail.franchise_id, orderDetail.id));
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
  };


  const handleSubmit = (values) => {
    setSnack({ call: false, type: "", message: "" });

    updateStorageDetail(values)
      .then((res) => {
        dispatch(fetchMyOrderDetailCard(orderDetail?.id));
        dispatch(fetchMyOrderTimeline(orderDetail?.id));
        setSnack({ call: true, type: "success", message: res.data.message });
        canvaCancel("kt_StorageDetail_panel_close");
        formik.setTouched({});
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
  };
  const handleReset = () => {};

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    // validationSchema: StorageSchema,
    onSubmit: handleSubmit,
    onReset: handleReset,
  });

  const handleSetData = (value) => {
    const arr = [];
    value.forEach((e, index) => {
      arr.push({
        id: e?.id || "",
        pod_number: e?.pod_number || "",
        seal_number_one: e?.seal_number_one || "",
        seal_number_two: e?.seal_number_two || "",
      });
    });

    formik.setFieldValue("pod_data", arr);
  };

  useEffect(() => {
    if (
      orderDetail?.pod_storage_details &&
      orderDetail?.pod_storage_details.length > 0
    ) {
      handleSetData(orderDetail?.pod_storage_details);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    orderDetail?.pod_storage_details,
    formik?.values?.pod_data?.length,
    check,
  ]);

  const handleDiscard = () => {
    if (
      formik.dirty &&
      Object.keys(formik.touched).length !== 0 &&
      formik.isValid
    ) {
      setDialogDis(true);
    } else {
      canvaCancel("kt_StorageDetail_panel_close");
      formik.resetForm();
    }
  };

  const handleDelete=()=>{
    setSnack({ call: false, type: "", message: "" });
const data={
  order_id:orderDetail.id,
franchise_container_id:containerDelete?.value?.id
}
    deleteContainer(data)
      .then((res) => {
        dispatch(fetchMyOrderTimeline(orderDetail?.id));
        dispatch(fetchVersion(orderDetail.franchise_id, orderDetail.id));
        setSnack({ call: true, type: "success", message: res.data.message });
        setDialogDelete(false)
      })
      .catch((error) => {
        setDialogDelete(false)

        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
  }

  return (
    <div
      id="kt_StorageDetail_panel"
      className="offcanvas offcanvas-right pt-5 pb-10"
    >
      {/*begin::Header*/}
      <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
        <div
          className="offcanvas-close mt-n1 pr-5"
          style={{ position: "absolute", top: "15px", right: "10px" }}
        >
          <span
            onClick={() => handleDiscard()}
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
          >
            <i className="ki ki-close icon-sm text-muted"></i>
          </span>
        </div>
        <div id="kt_StorageDetail_panel_close"></div>
      </div>
      {/*end::Header*/}
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <div className=" px-10">
          <div>
            <h5>Storage Details</h5>
          </div>
          <div
            id="kt_quick_panel_logs_kt_StorageDetail_panel"
            className=" fade pt-3 pr-5 mr-n5 scroll active show ps--active-y"
          >
            {orderDetail?.is_container === 1 ? (
              <div className="mt-10">
                {containerDetail &&
                  containerDetail.length > 0 &&
                  containerDetail.map((e, index) => (
                    <div className="d-flex align-items-center  storage-option">
                      <div>Container Reference Number:&nbsp;</div>
                      {editCon?.edit === index ? (
                        <div>
                          <Form.Control
                            type="text"
                            name={`pod_data`}
                            value={
                              editCon?.reference_unique_id 
                            }
                            onChange={(e) =>
                              setEditCon({
                                ...editCon,
                                reference_unique_id: e.target.value,
                              })
                            }
                          />
                        </div>
                      ) : (
                        <div>
                          <strong>{e.reference_unique_id}</strong>
                        </div>
                      )}
                      <div>
                        &nbsp;
                        {editCon?.edit === index ? (
                          <button
                            type="button"
                            className="btn btn-primary font-weight-bolder font-size-sm "
                            onClick={() => {
                              handleContainerUpdate();
                            }}
                          >
                            Save
                          </button>
                        ) : (
                          <>
                          <span className="symbol-label ml-2">
                            <span
                              className="svg-icon svg-icon-sm svg-icon-primary "
                              role="button"
                              onClick={() => {
                                setEditCon({ value: e, edit: index , reference_unique_id:e?.reference_unique_id});
                              }}
                            >
                              <SVG
                                title=" "
                                className="h-25 align-self-end"
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Design/Edit.svg"
                                )}
                              ></SVG>
                            </span>
                          </span>
                           <span className="symbol-label ml-2">
                           <span
                             className="svg-icon svg-icon-sm svg-icon-primary "
                             role="button"
                             onClick={() => {
                              setDialogDelete(true)
                              setContainerDelete({ value: e });
                             }}
                           >
                             <SVG
                               title=" "
                               className="h-25 align-self-end"
                               src={toAbsoluteUrl(
                                 "/media/svg/icons/General/Trash.svg"
                               )}
                             ></SVG>
                           </span>
                         </span>
                         </>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            ) : (
              <div className="mt-10">
                {formik?.values?.pod_data &&
                  formik.values?.pod_data.length > 0 &&
                  formik.values?.pod_data.map((item, index) => (
                    <div
                      className="row storage mb-5 mr-2 ml-2"
                      key={index}
                      style={
                        orderDetail?.pod_storage_details[index]?.is_initial ===
                        1
                          ? { backgroundColor: "#c8e1c8" }
                          : orderDetail?.pod_storage_details[index]
                              ?.is_initial === 2 ||
                            orderDetail?.pod_storage_details[index]
                              ?.is_return === 1
                          ? { backgroundColor: "#e9bebe" }
                          : {}
                      }
                    >
                      <div className="col-sm-4 mt-2 mb-2">
                        <div className="d-flex justify-content-between align-items-center storage-option">
                          <div>
                            {" "}
                            {orderDetail?.is_container === 1
                              ? "Container"
                              : "Pod"}{" "}
                            Number:
                          </div>
                          <div>
                            <Form.Control
                              type="text"
                              name={`pod_data[${index}].pod_number`}
                              values={item.pod_number}
                              {...formik.getFieldProps(
                                `pod_data[${index}].pod_number`
                              )}
                              disabled={
                                orderDetail?.pod_storage_details[index]
                                  ?.is_initial === 2
                              }
                            />
                          </div>
                        </div>

                        {formik.touched.pod_data && formik.errors.pod_data ? (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              {formik?.errors?.pod_data[index]?.pod_number ||
                                ""}
                            </div>
                          </div>
                        ) : null}
                      </div>
                      <div className="col-sm-4 mt-2 mb-2">
                        <div className="d-flex justify-content-between align-items-center storage-option">
                          <div>Seal Number 1: </div>
                          <div>
                            <Form.Control
                              type="text"
                              values={item.seal_number_one}
                              name={`pod_data[${index}].seal_number_one`}
                              {...formik.getFieldProps(
                                `pod_data[${index}].seal_number_one`
                              )}
                              disabled={
                                orderDetail?.pod_storage_details[index]
                                  ?.is_initial === 2
                              }
                            />
                          </div>
                        </div>
                        {formik.touched.pod_data && formik.errors.pod_data ? (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              {formik?.errors?.pod_data[index]
                                ?.seal_number_one || ""}
                            </div>
                          </div>
                        ) : null}
                      </div>
                      <div className="col-sm-4 mt-2 mb-2">
                        <div className="d-flex justify-content-between align-items-center storage-option">
                          <div>Seal Number 2:</div>
                          <div>
                            <Form.Control
                              type="text"
                              values={item.seal_number_two}
                              name={`pod_data[${index}].seal_number_two`}
                              {...formik.getFieldProps(
                                `pod_data[${index}].seal_number_two`
                              )}
                              disabled={
                                orderDetail?.pod_storage_details[index]
                                  ?.is_initial === 2
                              }
                            />
                          </div>
                        </div>
                        {formik.touched.pod_data && formik.errors.pod_data ? (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              {formik?.errors?.pod_data[index]
                                ?.seal_number_two || ""}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ))}

                <div className="d-flex justify-content-between w-50">
                  <h5>Depot</h5>
                  <span className="symbol-label pointer">
                    <button
                      type="button"
                      className="btn btn-link"
                      onClick={() => setDialog({ check: true })}
                    >
                      <span className="svg-icon svg-icon-sm svg-icon-primary">
                        <SVG
                          title=" "
                          className="h-25 align-self-end"
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Design/Edit.svg"
                          )}
                        ></SVG>
                      </span>
                    </button>
                  </span>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <p className="marginBottom0">
                      <strong>Name:-</strong>{" "}
                      {orderDetail?.depot_details?.name || ""}
                    </p>
                    <p className="marginBottom0">
                      <strong>Address:-</strong>{" "}
                      {orderDetail?.depot_details?.address_line_1 || ""}
                    </p>

                    <p className="marginBottom0">
                      {orderDetail?.depot_details?.address_line_2 || ""}
                    </p>

                    <p className="marginBottom0">
                      {orderDetail?.depot_details?.address_line_3 || ""}
                    </p>

                    <p className="marginBottom0">
                      {orderDetail?.depot_details?.city || ""}
                    </p>

                    <p className="marginBottom0">
                      {orderDetail?.depot_details?.postcode || ""}
                    </p>
                  </div>
                </div>
                <Swiper
                  pagination={{
                    el: ".swiper-custom-pagination",
                    type: "fraction",
                  }}
                  navigation={true}
                  modules={[Pagination, Navigation]}
                  className="mySwiper"
                >
                  {storageImages && storageImages.length > -1
                    ? storageImages.map((item, index) => (
                        <SwiperSlide key={index}>
                          <img src={item?.image} alt="hello" />
                        </SwiperSlide>
                      ))
                    : ""}

                  {/* <SwiperSlide>Slide 2</SwiperSlide>
                <SwiperSlide>Slide 3</SwiperSlide>
                <SwiperSlide>Slide 4</SwiperSlide>
                <SwiperSlide>Slide 5</SwiperSlide>
                <SwiperSlide>Slide 6</SwiperSlide>
                <SwiperSlide>Slide 7</SwiperSlide>
                <SwiperSlide>Slide 8</SwiperSlide>
                <SwiperSlide>Slide 9</SwiperSlide> */}
                </Swiper>
                <div className="swiper-custom-pagination text-center" />
              </div>
            )}
          </div>
        </div>
        <div className="offcanvas-footer">
          <div className="offcanvas-content text-right ">
            <button
              className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
              type="button"
              onClick={() => handleDiscard()}
            >
              Cancel
            </button>
            {orderDetail?.is_container !== 1 &&
            <button
              type="submit"
              className="btn btn-primary font-weight-bolder font-size-sm "
            >
              Save
            </button>
}
          </div>
        </div>
      </form>
      <DiscardDialog
        setDialogDis={setDialogDis}
        dialogDis={dialogDis}
        formik={formik}
        setCheck={setCheck}
        closeId={"kt_StorageDetail_panel_close"}
      />
      <EditDepotModal
        orderDetail={orderDetail}
        dialog={dialog}
        setDialog={setDialog}
      />
      <DeleteDialog
      dialog={dialogDelete} setDialog={setDialogDelete} handleDelete={handleDelete} title={"Container"} 
      />
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </div>
  );
}
