import { createSlice } from "@reduxjs/toolkit";

const initialCountryState = {
  list: {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    productForEdit: undefined,
    lastError: null,
  },
  activeCountry: [],
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const countrySlice = createSlice({
  name: "Country",
  initialState: initialCountryState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.list.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.list.listLoading = true;
      } else {
        state.list.actionsLoading = true;
      }
    },

    //country list
    countryFetchedList: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.list.listLoading = false;
      state.list.error = null;
      state.list.entities = entities;
      state.list.totalCount = totalCount;
    },
    //country detail
    countryDetail: (state, action) => {
      const { data } = action.payload;
      state.countryDetail = data;
    },
    //active country list
    activeCountryFetched: (state, action) => {
      const { data } = action.payload;
      state.activeCountry = data;
    },
  },
});
