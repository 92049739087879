export const CountrySchema = (intl, Yup) => {
  return Yup.object().shape({
    name: Yup.string().required(intl.formatMessage({ id: "CountryRequired" })),

    language: Yup.string().required(
      intl.formatMessage({ id: "Language.Required" })
    ),

    timezone: Yup.string().required(
      intl.formatMessage({ id: "Time.Required" })
    ),
  });
};
