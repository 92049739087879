export const promotions = [
  { id: "name", title: "Offer Code" },

  { id: "discountType", title: "Discount Type" },

  { id: "createdAt", title: "Valid From" },

  { id: "status", title: "Status" },
];

export const country = [{ id: 1, title: "India" }];
export const countryShort = [{ id: 1, title: "Ind" }];
export const language = [{ id: 1, title: "Hindi" }];
export const currency = [{ id: 1, title: "Rupee" }];
export const timeZone = [{ id: 1, title: "India" }];

export const BillingCycle = [
  { id: "day", value: "Daily", interval: 1, period: "day" },
  { id: "week", value: "Weekly", interval: 1, period: "week" },
  { id: "month", value: "Monthly", interval: 1, period: "month" },
  { id: "quarter", value: "Every 3 months", interval: 3, period: "month" },
  { id: "semiannual", value: "Every 6 months", interval: 6, period: "month" },
  { id: "year", value: "Yearly", interval: 1, period: "year" },
  { id: "custom", value: "Custom", interval: "", period: "custom" },
];
export const customBillingArr = [
  { id: "day", value: "Days" },
  { id: "week", value: "Weeks" },
  { id: "month", value: "Months" },
  { id: "year", value: "Years" },
];
// export const PricingModalArr =[
//   {id:'flatRate',value:'Flat Rate',description:'Offer a fixed price for a single unit or package', apiValue: "per_unit"},
//   // {id:'packagePricing',value:'Package Pricing',description:'Price by package,bundle and unit'},
//   {id:'tieredPricing',value:'Tiered Pricing',description:'Offer different price points based on unit quantity',  apiValue: "tiered"},
//   // {id:'usageBased',value:'Usage-Based',description:'Pay-as-you-go billing based on metered usage'},
// ]

export const productFormDataValues = [
  { apiValue: "id", formikValue: "id" },
  { apiValue: "name", formikValue: "productName" },
  { apiValue: "description", formikValue: "description" },
  { apiValue: "type", formikValue: "productType" },
  { apiValue: "for_franchise", formikValue: "franchiseCheck" },
  { apiValue: "price[unit_amount]", formikValue: "amount" },
  { apiValue: "price[billing_scheme]", formikValue: "pricingModel" },
  { apiValue: "category_type", formikValue: "packaging_category" },
  { apiValue: "is_packaging_material", formikValue: "is_packaging_material" },
  { apiValue: "product_plan", formikValue: "productPlan" },
];

export const promotionFormDataValues = [
  { apiValue: "offer_code", formikValue: "offer_code" },
  { apiValue: "offer_description", formikValue: "offer_description" },
  { apiValue: "offer_type", formikValue: "offer_type" },
  { apiValue: "currency_code", formikValue: "currency_code" },
  { apiValue: "value", formikValue: "value" },
  { apiValue: "apply_on", formikValue: "apply_on" },
  { apiValue: "duration_type", formikValue: "duration_type" },
  { apiValue: "duration", formikValue: "duration" },
  { apiValue: "promotion_id", formikValue: "promotion_id" },
];

export const productPriceFormDataValues = [
  { apiValue: "type", formikValue: "productType" },
  { apiValue: "unit_amount", formikValue: "amount" },
  { apiValue: "currency", formikValue: "currency" },
  { apiValue: "billing_scheme", formikValue: "pricingModel" },
];
