import { FormControl, MenuItem, Select } from "@material-ui/core/";
import React, { useState } from "react";

import { AddDiscount } from "../order-form/validationSchema";
import DiscardDialog from "../../../../_metronic/_partials/widgets/alert/DiscardDialog";
import { Form } from "react-bootstrap";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import SnackBarTool from "../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { useFormik } from "formik";
import { useIntl } from "react-intl";

const ManualDiscount = ({
  setOpenCanva,
  state,
  dispatchReducer,
  type,
  handleAddOfferManual,
  handleUpdateManualAllDiscount,
}) => {
  const intl = useIntl();

  const initialValues = {
    discountType: state?.manualDiscount?.discountType
      ? state?.manualDiscount?.discountType
      : "",
    enterDiscount: state?.manualDiscount?.enterDiscount
      ? state?.manualDiscount?.enterDiscount
      : "",
    applyOn: "invoice_amount",
    productDiscount: state?.manualDiscount?.productDiscount
      ? state?.manualDiscount?.productDiscount
      : "",
    duration: state?.manualDiscount?.duration
      ? state?.manualDiscount?.duration
      : "",
    limitedPeriod: state?.manualDiscount?.limitedPeriod
      ? state?.manualDiscount?.limitedPeriod
      : "",
    time: state?.manualDiscount?.time ? state?.manualDiscount?.time : "",
  };

  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [dialogDis, setDialogDis] = useState(false);

  const handleSubmit = (values, actions) => {
    setSnack({ call: false, type: "", message: "" });
    if (type === "Order") {
      handleAddOfferManual(values);
    } else {
      const arr = [...state.coupon_id];
      const dd = arr.findIndex((e) => e.name === "manual");
      arr.splice(dd, 1);
      dispatchReducer({ type: "manualDiscount", manualDiscount: values });
      dispatchReducer({ type: "coupon", value: [] });
      handleUpdateManualAllDiscount(values);
    }
    setSnack({
      call: true,
      type: "success",
      message: "Manual Discount Applied",
    });
    setTimeout(() => {
      setOpenCanva(false);
    }, 3000);
  };

  const handleReset = () => {};

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: AddDiscount,
    onSubmit: handleSubmit,
    onReset: handleReset,
  });

  const handleApplyOn = (value, type) => {
    if (type && type === "duration") {
      formik.setFieldValue("duration", value);
    } else {
      formik.setFieldValue("applyOn", value);
    }
  };

  const handleDiscard = () => {
    if (formik.dirty && Object.keys(formik.touched).length !== 0) {
      setDialogDis(true);
    } else {
      setOpenCanva(false);
      formik.resetForm();
    }
  };

  const handleEnterDistcount = (e) => {
    if (e.target.value === "0") {
      formik.setFieldValue("enterDiscount", 1);
    } else {
      formik.setFieldValue("enterDiscount", e.target.value);
    }
  };
  return (
    <div
      id="kt_Manual_Discount_panel"
      className="offcanvas offcanvas-right pt-5 pb-10 offcanvas-on"
    >
      {/*begin::Header*/}
      <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
        <div
          className="offcanvas-close mt-n1 pr-5"
          style={{ position: "absolute", top: "15px", right: "10px" }}
        >
          <span
            onClick={() => handleDiscard()}
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            id="kt_Manual_Discount_panel_close"
          >
            <i className="ki ki-close icon-sm text-muted"></i>
          </span>
        </div>
      </div>
      {/*end::Header*/}
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <div className="offcanvas-content px-10 ">
          <div>
            <h5>{intl.formatMessage({ id: "Add.manual.discount" })}</h5>
          </div>
          <div
            className="fade pt-3 pr-5 mr-n5 scroll active show ps--active-y mt-10 row"
            id="kt_quick_panel_logs_kt_Manual_Discount_panel"
          >
            <div className="col-md-7">
              <FormControl fullWidth>
                <label>{intl.formatMessage({ id: "Discount.Type" })}</label>
                <Select
                  name="discountType"
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                  className={`form-control ${getInputClasses("discountType")} `}
                  IconComponent={(props) => (
                    <i {...props}>
                      <KeyboardArrowDownIcon />
                    </i>
                  )}
                  inputProps={{ "aria-label": "Without label" }}
                  displayEmpty
                  {...formik.getFieldProps("discountType")}
                >
                  <MenuItem value="">
                    {intl.formatMessage({ id: "Discount.Type" })}
                  </MenuItem>
                  <MenuItem value="percentage">
                    {intl.formatMessage({ id: "Percentage" })}
                  </MenuItem>
                  <MenuItem value="fixed_amount">
                    {intl.formatMessage({ id: "Fixed.Amount" })}
                  </MenuItem>
                </Select>
              </FormControl>
              {formik.touched.discountType && formik.errors.discountType ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.discountType}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="col-7 mt-2">
              <label>{intl.formatMessage({ id: "Enter.discount" })}</label>
              <div className="form-groupB">
                <input
                  type="text"
                  className={`form-control  ${getInputClasses(
                    "enterDiscount"
                  )}`}
                  placeholder={intl.formatMessage({ id: "Enter.discount" })}
                  name="enterDiscount"
                  value={formik.values.enterDiscount}
                  maxLength={formik.discountType === "percentage" ? "3" : "4"}
                  onChange={(e) => handleEnterDistcount(e)}
                />
                {formik.touched.enterDiscount && formik.errors.enterDiscount ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.enterDiscount}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>

            <div className="col-12">
              <label>{intl.formatMessage({ id: "Duration" })}</label>
              <div className="mb-7 payment-radio">
                <Form.Check
                  className="mb-1"
                  type="radio"
                  label={intl.formatMessage({ id: "One.Time" })}
                  name="formHorizontalRadios2"
                  id="formHorizontalRadios2"
                  checked={formik.values.duration === "one_time" ? true : false}
                  onClick={() => handleApplyOn("one_time", "duration")}
                />
                <Form.Check
                  className="mb-1"
                  type="radio"
                  label={intl.formatMessage({ id: "Forever" })}
                  name="formHorizontalRadios2"
                  id="formHorizontalRadios2"
                  checked={formik.values.duration === "forever" ? true : false}
                  onClick={() => handleApplyOn("forever", "duration")}
                />
                <Form.Check
                  className="mb-1"
                  type="radio"
                  label={intl.formatMessage({ id: "Limited.Period" })}
                  name="formHorizontalRadios2"
                  id="formHorizontalRadios2"
                  checked={
                    formik.values.duration === "limited_period" ? true : false
                  }
                  onClick={() => handleApplyOn("limited_period", "duration")}
                />
              </div>
            </div>
            {formik.values.duration === "limited_period" ? (
              <>
                <div className="col-6">
                  <div className="form-groupB">
                    <input
                      type="text"
                      className={`form-control  `}
                      placeholder={intl.formatMessage({ id: "Limited.Period" })}
                      name="limitedPeriod"
                      value={formik?.values?.limitedPeriod || ""}
                      onChange={(e) => {
                        if (
                          e.target.value !== "" &&
                          Number(e.target.value) === 0
                        )
                          return;
                        formik.setFieldValue("limitedPeriod", e.target.value);
                      }}
                      // {...formik.getFieldProps("limitedPeriod")}
                    />
                  </div>
                  {formik.touched.limitedPeriod &&
                  formik.errors.limitedPeriod ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.limitedPeriod}
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="col-6">
                  <FormControl fullWidth>
                    <Select
                      name="time"
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                      className={`form-control `}
                      IconComponent={(props) => (
                        <i {...props}>
                          <KeyboardArrowDownIcon />
                        </i>
                      )}
                      inputProps={{ "aria-label": "Without label" }}
                      displayEmpty
                      {...formik.getFieldProps("time")}
                    >
                      <MenuItem value="day">
                        {intl.formatMessage({ id: "Days" })}
                      </MenuItem>
                      <MenuItem value="week">
                        {intl.formatMessage({ id: "Weeks" })}
                      </MenuItem>
                      <MenuItem value="month">
                        {intl.formatMessage({ id: "Months" })}
                      </MenuItem>
                      <MenuItem value="year">
                        {intl.formatMessage({ id: "Years" })}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
          {formik.touched.duration && formik.errors.duration ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.duration}</div>
            </div>
          ) : null}
        </div>
        <div className="offcanvas-footer">
          <div className="offcanvas-content text-right ">
            <button
              onClick={() => handleDiscard()}
              type="reset"
              className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
            >
              {intl.formatMessage({ id: "CANCEL" })}
            </button>
            <button
              type="submit"
              className="btn btn-primary font-weight-bolder font-size-sm "
            >
              {intl.formatMessage({ id: "Add" })}
            </button>
          </div>
        </div>
      </form>
      {snack.call ? <SnackBarTool {...snack} /> : ""}
      <DiscardDialog
        setDialogDis={setDialogDis}
        dialogDis={dialogDis}
        formik={formik}
        setActive={setOpenCanva}
      />
    </div>
  );
};

export default ManualDiscount;
