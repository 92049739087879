export const init = {
  totalData: { discountValue: 0 },
  productArray: [],
  manualDiscount: {},
  adHoc: {},
  adhocGbp: 0,
  adhocGbpDummy: 0,
  adhocChargeName: "",
  coupon_id: [],
  currencyCode: "",
  driver_note: "",
  subTotal: "",
  paymentIntent: {},
  isShip: "0",
  afterDiscount: "",
  params: {},
  total: "",
  vat: "",
  vatTotal: "",
  quantity: [],
  collectionRetrun: {},
  depotData: {},
  postcode: {},
  cover: {},
  initialBilingCycle: false,
  paramsData: {},
  isSave: false,
  packageMaterial: [],
  packageMaterialAdd: false,
  competitorPrice: {},
  isQuote: false,
  deletedProduct: [],
  containerTypeSelect: "",
  siteSelect: {},
  plan_type: [],
  containerList: [],
  containerPlanList: [],
  containerListSelect: "",
  containerPlanListSelect: "",
  classSiteList: [],
  availData: {},
  is_plan_replaced: 0,
  franchise_id: null,
};

export const reducer = (state, action) => {

  switch (action.type) {
    case "Add":
      return {
        ...state,
        all: [...state.all, action.alls],
      };
    case "planReplaced":
      return { ...state, is_plan_replaced: action.value };
    case "franchise_id":
      return { ...state, franchise_id: action.value };
    case "deletedProduct":
      return { ...state, deletedProduct: action.value };
    case "packageMaterial":
      return { ...state, packageMaterial: action.value };
    case "classSiteList":
      return { ...state, classSiteList: action.value };
    case "plan_type":
      return { ...state, plan_type: action.value };
    case "avail":
      return { ...state, availData: action.value };
    case "containerList":
      return { ...state, containerList: action.value };
    case "containerPlanList":
      return { ...state, containerPlanList: action.value };
    case "containerListSelect":
      return { ...state, containerListSelect: action.value };
    case "containerPlanListSelect":
      return { ...state, containerPlanListSelect: action.value };
    case "containerTypeSelect":
      return { ...state, containerTypeSelect: action.value };
    case "siteSelect":
      return { ...state, siteSelect: action.value };
    case "packageMaterialAdd":
      return { ...state, packageMaterialAdd: action.value };
    case "productArray":
      return { ...state, productArray: action.productArray };
    case "isQuote":
      return { ...state, isQuote: action.value };
    case "postcode":
      return { ...state, postcode: action.postcode };
    case "manualDiscount":
      return { ...state, manualDiscount: action.manualDiscount };
    case "totalDisVal":
      return {
        ...state,
        totalData: { discountValue: action.discountValue },
      };
    case "setDiscountValue":
      return {
        ...state,
        totalData: { discountValue: 0 },
      };
    case "adHoc":
      return { ...state, adHoc: action.adhoc };
    case "adhocGbp":
      return { ...state, adhocGbp: action.value };
    case "adhocGbpDummy":
      return { ...state, adhocGbpDummy: action.value };
    case "adhocChargeName":
      return { ...state, adhocChargeName: action.value };
    case "coupon":
      return { ...state, coupon_id: action.value };
    case "currency":
      return { ...state, currencyCode: action.value };
    case "driverNote":
      return { ...state, driver_note: action.value };
    case "subTotal":
      return { ...state, subTotal: action.value };
    case "afterDiscount":
      return { ...state, afterDiscount: action.value };
    case "paymentIntent":
      return { ...state, paymentIntent: action.value };
    case "isShip":
      return { ...state, isShip: action.value };
    case "params":
      return { ...state, params: action.value };
    case "paramsData":
      return { ...state, paramsData: action.value };
    case "competitorPrice":
      return { ...state, competitorPrice: action.value };
    case "total":
      return { ...state, total: action.value };
    case "qty":
      return { ...state, quantity: action.value };
    case "cover":
      return { ...state, cover: action.value };
    case "vat":
      return { ...state, vat: action.value };
    case "vatTotal":
      return { ...state, vatTotal: action.value };
    case "quantity":
      return { ...state, quantity: action.value };
    case "collectionRetrun":
      return {
        ...state,
        collectionRetrun: { ...state.collectionRetrun, ...action.value },
      };
    case "collectionRetrunEmpty":
      return {
        ...state,
        collectionRetrun: action.value,
      };
    case "depotDataForMap":
      return { ...state, depotData: action.value };
    case "initialBilingCycle":
      return { ...state, initialBilingCycle: action.value };
    case "isSave":
      return { ...state, isSave: action.value };
    case "customReset":
      return {
        ...state,
        productArray: [],
        manualDiscount: {},
        totalData: { discountValue: 0 },
        adhocGbp: 0,
        coupon_id: [],
        collectionRetrun: {},
        depotData: {},
        quantity: [],
        initialBilingCycle: false,
      };
    case "allSet":
      return action.value;
    case "reset":
      return { state };

    default:
      return state;
  }
};
