import React from "react";
import { FormControl, MenuItem, Select } from "@material-ui/core";

import { Form } from "react-bootstrap";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { getInputClasses } from "../../../../../utils/commonFunction";

const ContainerForm = ({
  intl,
  formik,
  countryApi,
  handleSelectCountry,
  languageData,
  formData,
  setDialog,
  list
}) => {
  return (
    <div className="row">
      {/* Country Select Field */}
      <div className="col-12">
        <div className="form-groupB">
          <label>Franchise</label>
          <FormControl fullWidth>
            <Select
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              className={`form-control  ${getInputClasses(formik, "name")}`}
              IconComponent={(props) => (
                <i {...props}>
                  <KeyboardArrowDownIcon />
                </i>
              )}
              inputProps={{ "aria-label": "Without label" }}
              displayEmpty
              name="franchise_id"
              value={formik.values.franchise_id}
              // {...formik.getFieldProps("name")}
              //   onBlur={handleBlur}
              //   value={values.status}
              {...formik.getFieldProps(`franchise_id`)}
            >
              <MenuItem value="">
               Select
              </MenuItem>
              {list.map((data, index) => (
                <MenuItem value={data.id} key={index}>
                  {data.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {formik.touched.name && formik.errors.name ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.name}</div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="col-12">
        <div className="form-groupB">
          <label>Container</label>
          <FormControl fullWidth>
            <Select
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              className={`form-control  ${getInputClasses(formik, "name")}`}
              IconComponent={(props) => (
                <i {...props}>
                  <KeyboardArrowDownIcon />
                </i>
              )}
              inputProps={{ "aria-label": "Without label" }}
              displayEmpty
              name="container_size"
              {...formik.getFieldProps(`container_size`)}
              // {...formik.getFieldProps("name")}
              //   onBlur={handleBlur}
              //   value={values.status}
            >
              <MenuItem value="">
               Select
              </MenuItem>
              <MenuItem value="10">
               10 sqft
              </MenuItem>
              <MenuItem value="20">
              20 sqft
              </MenuItem>
              <MenuItem value="30">
              30 sqft
              </MenuItem>
              {/* {countryApi.map((data, index) => (
                <MenuItem value={data.name.common} key={index}>
                  {data.name.common}
                </MenuItem>
              ))} */}
            </Select>
          </FormControl>
          {formik.touched.name && formik.errors.name ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.name}</div>
            </div>
          ) : null}
        </div>
      </div>
      {/* Short Name Input Field */}
      <div className="col-12">
        <div className="form-groupB">
          <label>Deposite value</label>
          <Form.Control
            type="text"
            placeholder="Deposite value"
            className={`form-control  ${getInputClasses(formik, "deposit_value")}`}
            name="deposit_value"
            {...formik.getFieldProps("deposit_value")}
          />
          {formik.touched.deposit_value && formik.errors.deposit_value ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.deposit_value}</div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="col-12">
        <div className="form-groupB">
          <label>Collection Fee</label>
          <Form.Control
            type="text"
            placeholder="Collection Fee"
            className={`form-control  ${getInputClasses(formik, "collection_fee")}`}
            name="collection_fee"
            {...formik.getFieldProps("collection_fee")}
          />
          {formik.touched.collection_fee && formik.errors.collection_fee ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.collection_fee}</div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="col-12">
        <div className="form-groupB">
          <label>Return fee</label>
          <Form.Control
            type="text"
            placeholder="Return fee"
            className={`form-control  ${getInputClasses(formik, "return_fee")}`}
            name="return_fee"
            {...formik.getFieldProps("return_fee")}
          />
          {formik.touched.return_fee && formik.errors.return_fee ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.return_fee}</div>
            </div>
          ) : null}
        </div>
      </div>
      {/* Language Select Field */}
      
      {/* Currency Select Field */}
  
      {/* Time Zone Select Field */}
      
      {/* <div className="col-6">
        <div className="form-groupB row">
          <label className="col-5 col-form-label ">
            {intl.formatMessage({ id: "ACTIVE" })}
          </label>
          <div className="col-7 text-right mt-1">
            <span className="switch switch-outline switch-icon switch-primary">
              <label>
                <input
                  type="checkbox"
                  checked={formik.values.status === 1 ? true : false}
                  name="quick_panel_notifications_2"
                  onChange={() => setDialog(true)}
                />
                <span></span>
              </label>
            </span>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default ContainerForm;
