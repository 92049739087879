import * as requestFromServer from "./contactCrud";
import { contactSlice, callTypes } from "./contactSlice";

const { actions } = contactSlice;

export const fetchContact = (queryParams) => (dispatch) => {
  // Dispatch action to indicate the start of a call
  dispatch(actions.startCall({ callType: callTypes.list }));
  // Dispatch action to update the contact state with loading flag
  dispatch(
    actions.contactFetched({ totalCount: 0, entities: [], loading: true })
  );
  // Make a request to the server to find a contact
  return requestFromServer
    .fetchContactList(queryParams)
    .then((response) => {
      // Get the meta and data properties from the server response
      const { meta, data } = response.data;
      // Dispatch action to update the contact state with new data and loading flag
      dispatch(
        actions.contactFetched({
          totalCount: meta.total,
          entities: data,
          loading: false,
        })
      );
    })
    .catch((error) => {
      // Dispatch action to update the contact state with no data and loading flag
      dispatch(
        actions.contactFetched({ totalCount: 0, entities: [], loading: false })
      );
      error.clientMessage = "Can't find products";
      // Dispatch action to indicate that an error has occurred
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchAgent = (queryParams) => (dispatch) => {
  // Dispatch an action to indicate that a call of type 'list' has started
  dispatch(actions.startCall({ callType: callTypes.list }));
  // Make a request to the server for the list of agents using the provided query parameters
  return requestFromServer
    .agentList(queryParams)
    .then((response) => {
      // Extract the data from the server response
      const { data } = response.data;
      // Dispatch an action to indicate that the agents have been fetched and pass the data along
      dispatch(actions.agentFetched({ data }));
    })
    .catch((error) => {
      // Add a message to the error object to indicate that the products could not be found
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchContactDetail = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  // Dispatch an action to indicate that the contact detail fetch has started and set loading to true
  dispatch(actions.contactDetailFetched({ data: {}, loading: true }));
  // Make a request to the server for the contact detail using the provided query parameters
  return requestFromServer
    .contactDetail(queryParams)
    .then((response) => {
      // Extract the data from the server response
      const { data } = response.data;
      // Dispatch an action to indicate that the contact detail has been fetched and pass the data along, and set loading to false
      dispatch(actions.contactDetailFetched({ data, loading: false }));
    })
    .catch((error) => {
      if (error?.response?.status === 404 || error?.response?.status === 400) {
        // Redirect to the error page
        window.location.href = "/error/error-v1"; // Replace '/error' with the actual route of your error page
      }

      // Add a message to the error object to indicate that the products could not be found
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const fetchContactNotesList = (queryParams) => (dispatch) => {
  // Dispatch an action to indicate the start of a server call
  dispatch(actions.startCall({ callType: callTypes.list }));
  // Dispatch an action to indicate the loading state of contact notes list
  dispatch(actions.contactNotesList({ data: [], loading: true }));

  return requestFromServer
    .getContactNotesList(queryParams)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.contactNotesList({ data, loading: false }));
    })
    .catch((error) => {
      // Add a message to the error object to indicate that the products could not be found
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

//contact detail tasks list
export const fetchContactTasksList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  dispatch(actions.contactTasksList({ data: [], loading: true }));

  // Make a request to the server for the contact detail using the provided query parameters
  return requestFromServer
    .getContactTasksList(queryParams)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.contactTasksList({ data, loading: false }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

//contact detail page email list
export const fetchContactEmailList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  dispatch(actions.contactEmailList({ data: [], loading: true }));

  // Make a request to the server for the contact detail using the provided query parameters
  return requestFromServer
    .getContactEmailList(queryParams)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.contactEmailList({ data, loading: false }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

//const detail page sms list
export const fetchContactSmsList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  dispatch(actions.contactSmsList({ data: [], loading: true }));

  // Make a request to the server for the contact detail using the provided query parameters
  return requestFromServer
    .getContactSmsList(queryParams)
    .then((response) => {
      const { data } = response.data;

      dispatch(actions.contactSmsList({ data, loading: false }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

//contact detail feed list
export const fetchContactfeedList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  // Make a request to the server for the contact detail using the provided query parameters
  return requestFromServer
    .getContactFeedList(queryParams)
    .then((response) => {
      const { data } = response.data;

      dispatch(actions.contactFeedList({ data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

//contact page deal list
export const fetchContactDeal = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  // Make a request to the server for the contact detail using the provided query parameters
  return requestFromServer
    .contactDealList(queryParams)
    .then((response) => {
      const { meta, data } = response.data;
      dispatch(
        actions.contactDealFetched({ totalCount: meta.total, entities: data })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

//contact detail call data
export const fetchContactCall = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  // Make a request to the server for the contact detail using the provided query parameters
  return requestFromServer
    .contactCallList(queryParams)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.contactCallFetched({ data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

//contact detail revert email data
export const fetchContactRevertEmail = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  // Make a request to the server for the contact detail using the provided query parameters
  return requestFromServer
    .contactRevertEmail(queryParams)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.contactRevertEmailFetched({ data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
