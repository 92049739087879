import axios from "axios";
export const Tasks_URL = "api/quotes-list";
export const TASKS = "quotes-list";
export const SEARCH = "/search-contact-deals";
export const Quote_LIST = "quotes-list";

export function getAllProducts() {
  return axios.get(Tasks_URL);
}
export function findQTasks(queryParams) {
  // Make an HTTP GET request to the tasks endpoint with query parameters
  return axios.get(
    `${process.env.REACT_APP_SITE_URL + Quote_LIST}?page=${queryParams.pageNumber
    }&per_page=${queryParams.pageSize}&sort_by=${queryParams.sortField
    }&sort_dir=${queryParams.sortOrder}&search_keyword=${queryParams.filter.search
    }&search_source=${queryParams?.filter?.source}`
  );
}
