import React, { useEffect, useMemo, useState } from "react";
import { FormControl, MenuItem, Select } from "@material-ui/core/";

import { Formik } from "formik";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { isEqual } from "lodash";
import { useDepotUIContext } from "../DepotsUIContext";
import { useIntl } from "react-intl";
import { CustomeSearch } from "../../../../../../_metronic/_partials/controls/CustomeSearch";
import { dealsFranchiseListing } from "../../../../ContactDeals/_redux/deals/dealsCrud";

const prepareFilter = (queryParams, values) => {
  const { status, searchText, search_franchise } = values;
  const newQueryParams = { ...queryParams };
  const filter = {};
  filter.status = status !== "" ? +status : "";
  // Filter by all fields
  filter.search_keyword = searchText ? searchText : "";
  filter.search_franchise = search_franchise ? search_franchise : "";
  newQueryParams.filter = filter;
  return newQueryParams;
};

export function DepotsFilter({ listLoading }) {
  const [franchises, setFranchise] = useState([]);
  // Products UI Context
  const depotsUIContext = useDepotUIContext();
  const depotsUIProps = useMemo(() => {
    return {
      setQueryParams: depotsUIContext.setQueryParams,
      queryParams: depotsUIContext.queryParams,
    };
  }, [depotsUIContext]);

  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(depotsUIProps.queryParams, values);
    if (!isEqual(newQueryParams, depotsUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      depotsUIProps.setQueryParams(newQueryParams);
    }
  };
  const handleFranchiseList = () => {
    dealsFranchiseListing().then((res) => {
      setFranchise(res.data.data);
    });
  };
  useEffect(() => {
    const timeout = setTimeout(() => {
      handleFranchiseList();
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);
  const intl = useIntl();

  return (
    <>
      <Formik
        initialValues={{
          status: "", // values => All=""/Selling=0/Sold=1
          condition: "", // values => All=""/New=0/Used=1
          searchText: "",
          search_franchise: "",
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row d-flex justify-content-between">
              <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
                <FormControl fullWidth>
                  <Select
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                    className="form-control"
                    IconComponent={(props) => (
                      <i {...props}>
                        <KeyboardArrowDownIcon />
                      </i>
                    )}
                    inputProps={{ "aria-label": "Without label" }}
                    displayEmpty
                    onChange={(e) => {
                      setFieldValue("status", e.target.value);
                      handleSubmit();
                    }}
                    onBlur={handleBlur}
                    value={values.status}
                  >
                    <MenuItem value="">
                      {intl.formatMessage({ id: "All.Depots" })}
                    </MenuItem>
                    <MenuItem value={1}>
                      {intl.formatMessage({ id: "Depots.Active" })}
                    </MenuItem>
                    <MenuItem value={0}>
                      {intl.formatMessage({ id: "Depots.Inactive" })}
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-lg-4  order-flt marginLeft-18  ">
                <span className="mt-3">
                  {intl.formatMessage({ id: "Franchise" })}:&nbsp;{" "}
                </span>
                <FormControl fullWidth>
                  <Select
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                    className="form-control"
                    onChange={(e) => {
                      setFieldValue("search_franchise", e.target.value);

                      handleSubmit();
                    }}
                    onBlur={handleBlur}
                    value={values.search_franchise}
                    displayEmpty
                    IconComponent={(props) => (
                      <i {...props}>
                        <KeyboardArrowDownIcon />
                      </i>
                    )}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="">All franchises</MenuItem>
                    {franchises.map((data, index) => (
                      <MenuItem key={index} value={data.id}>
                        {data.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-sm-6 col-md-2 col-lg-2 mb-5">
                <CustomeSearch
                  intl={intl}
                  handleBlur={handleBlur}
                  values={values}
                  setFieldValue={setFieldValue}
                  handleSubmit={handleSubmit}
                />
                {/* <input
                  type="text"
                  style={{ paddingRight: "30px" }}
                  className="form-control"
                  name="searchText"
                  placeholder={intl.formatMessage({ id: "Search" })}
                  onBlur={handleBlur}
                  value={values.searchText}
                  onChange={(e) => {
                    setFieldValue("searchText", e.target.value);
                    handleSubmit();
                  }}
                />
                <i
                  style={{
                    position: "absolute",
                    top: "50%",
                    transform: "translateY(-50%)",
                    right: "20px",
                  }}
                  className="fa fa-search"
                  aria-hidden="true"
                ></i> */}
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
