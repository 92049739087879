/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { Modal } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { useFormik } from "formik";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import SnackBarTool from "../../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";

import DiscardDialog from "../../../../../../_metronic/_partials/widgets/alert/DiscardDialog";
import { LoadingCustome } from "../../../../../../_metronic/_partials/controls/LoadingCustome";
import { automatePrice } from "../../../_redux/franchises/franchisesCrud";
import { AutomateValidation } from "../franchise-form/validateSchema";
import { fetchFranchisesDetail } from "../../../_redux/franchises/franchisesActions";
const AutomatePriceModal = ({
  header,
  mainText,
  dialog,
  setDialog,
  franchiseId,
  user_id,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [dialogDis, setDialogDis] = useState(false);
  const initialValues = {
    franchise_id: franchiseId,
    price_increase_type: "",
    price_increase_value: "",
  };

  const handleSubmit = (values) => {
    if (user_id) {
      values.user_id = user_id;
      values.type = "customer";
    } else {
      values.type = "franchise";
    }
    setIsLoading(true);
    setSnack({ call: false, type: "", message: "" });
    automatePrice(values, user_id)
      .then((res) => {
        setIsLoading(false);
        // dispatch(fetchMyOrderDetailCard(orderDetail?.id));
        dispatch(fetchFranchisesDetail(values?.franchise_id));
        setSnack({ call: true, type: "success", message: res.data.message });
        setTimeout(() => {
          setDialog({ check: false });
          setSnack({ call: false, type: "", message: "" });
          formik.resetForm();
        }, 3000);
      })
      .catch((error) => {
        setIsLoading(false);
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: AutomateValidation,
    onSubmit: handleSubmit,
  });

  const handleClose = () => {
    if (formik.dirty && Object.keys(formik.touched).length !== 0) {
      setDialogDis(true);
    } else {
      formik.resetForm();
      setDialog({ check: false });
    }
    setSnack({ call: false, type: "", message: "" });
  };
  return (
    <Modal
      show={dialog.check}
      size="lg"
      aria-labelledby="example-modal-sizes-title-lg"
      onHide={handleClose}
    >
      <Modal.Header className="d-flex justify-content-between">
        <Modal.Title id="example-modal-sizes-title-lg">
          Automated Price Increase
        </Modal.Title>
        <span
          className="btn btn-xs btn-icon btn-light btn-hover-primary"
          onClick={handleClose}
        >
          <i className="ki ki-close icon-sm text-muted"></i>
        </span>
      </Modal.Header>
      <form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          {isLoading && <LoadingCustome />}
          <div className="row">
            <div className="col-6">
              <span className="mt-3 ">Price increase type</span>
              <FormControl fullWidth>
                <Select
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                  className="form-control"
                  IconComponent={(props) => (
                    <i {...props}>
                      <KeyboardArrowDownIcon />
                    </i>
                  )}
                  inputProps={{ "aria-label": "Without label" }}
                  displayEmpty
                  // onChange={(e) => {
                  //   setFieldValue("search_view", e.target.value);
                  //   handleSubmit();
                  // }}
                  name="price_increase_type"
                  {...formik.getFieldProps("price_increase_type")}
                >
                  <MenuItem value="">Select</MenuItem>
                  <MenuItem value="fixed">Fixed</MenuItem>
                  <MenuItem value="percentage">Percentage</MenuItem>
                </Select>
              </FormControl>
              {formik.touched.price_increase_type &&
              formik.errors.price_increase_type ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.price_increase_type}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="col-6">
              <span className="mt-3 ">
                Price increase{" "}
                {formik.values.price_increase_type === "percent"
                  ? "Percentage"
                  : "Value"}{" "}
              </span>
              <input
                type="text"
                name="price_increase_value"
                placeholder={
                  formik.values.price_increase_type === "percent"
                    ? "Percentage"
                    : "Value"
                }
                label="External Name"
                className={`form-control  `}
                // max={3}
                // maxLength="3"
                {...formik.getFieldProps("price_increase_value")}
              />
              {formik.touched.price_increase_value &&
              formik.errors.price_increase_value ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.price_increase_value}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              onClick={() => handleClose()}
              className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
              disabled={isLoading}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary font-weight-bolder font-size-sm "
              disabled={isLoading}
            >
              Submit
            </button>
          </div>
        </Modal.Footer>
      </form>
      {snack.call ? <SnackBarTool {...snack} /> : ""}
      <DiscardDialog
        setDialogDis={setDialogDis}
        dialogDis={dialogDis}
        formik={formik}
        setCanva={setDialog}
      />
    </Modal>
  );
};

export default AutomatePriceModal;
