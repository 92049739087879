/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */

import { Button, Table } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import ActiveDialogApi from "../../../../../../_metronic/_partials/widgets/alert/ActiveDialogApiCall";
import { AddVan, EditVan } from "../franchise-canva";
import SVG from "react-inlinesvg";
import SnackBarTool from "../../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { createFranchiseVanStatus } from "../../../_redux/franchises/franchisesCrud";
import { fetchFranchisesVan } from "../../../_redux/franchises/franchisesActions";
import { initIds } from "../../../../../../_metronic/_partials/controls/RightPanel";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import { useIntl } from "react-intl";
import InactiveDialog from "../franchise-active-dialog/InactiveDialog";
import { CircularProgress, Switch } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

export function Vans({ permission, franchData }) {
  const intl = useIntl();
  // Use the dispatch hook to dispatch actions
  const dispatch = useDispatch();

  // Use state hook to manage snackbar state
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  // Use state hook to manage dialog state
  const [dialog, setDialog] = useState({ modal: false });
  const [job, setJob] = useState({ modal: false, message: "" });
  const [toggleCheck, setToggleCheck] = useState(false);

  // Use the useSelector hook to access the state
  const { detailData, vanLoading, detailDataFra } = useSelector(
    (state) => ({
      detailData: state.franchise.van,
      detailDataFra: state.franchise.detail,
      vanLoading: state.franchise.vanLoading,
    }),
    shallowEqual
  );

  // Initial id's
  const init = ["kt_addvan_panel", "kt_editvan_panel"];

  // Use effect hook to update the init array with the number of vans
  useEffect(() => {
    if (detailData && detailData?.length) {
      for (let i = 0; i < detailData?.length; i++) {
        init.push(`kt_editvan_panel${i}`);
      }
    }
  }, [detailData, init]);

  // Use effect hook to call the initIds function
  useEffect(() => {
    initIds(init);
  }, [init]);

  // Event handler for changing the van status
  const handleChange = () => {
    setSnack({ call: false, type: "", message: "" });

    const data = {
      id: dialog.data.id,
      status: dialog.data.e ? 1 : 0,
    };
    // Call the createFranchiseVanStatus function to update the van status
    createFranchiseVanStatus(data)
      .then((res) => {
        if (res.data.success) {
          if (res.data.data?.statusChangeAllowed !== 1) {
            setJob({ modal: true, message: res.data.message });
            return;
          }
          // Dispatch an action to fetch updated van data
          dispatch(fetchFranchisesVan(dialog.data.franchise));
          setSnack({
            call: true,
            type: "success",
            message: res.data.message,
          });
        } else {
          setSnack({ call: true, type: "error", message: res.data.message });
        }
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });

    setDialog({ modal: false });
  };
  // Render the JSX for the component

  useEffect(() => {
    if (toggleCheck) {
      dispatch(fetchFranchisesVan(franchData?.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleCheck]);
  return (
    <>
      <div className="card card-custom  gutter-b mb-10">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center w-100 ">
            <h3 className="card-title p-0 m-0 ">
              <span className="font-weight-bolder text-dark">
                {intl.formatMessage({ id: "Vans" })}
              </span>
              <span>
                {toggleCheck ? (
                  <ExpandLessIcon
                    style={{
                      fontSize: "3rem",
                      margin: " 0 10px",
                      cursor: "pointer",
                    }}
                    onClick={() => setToggleCheck(!toggleCheck)}
                  />
                ) : (
                  <KeyboardArrowRightIcon
                    style={{
                      fontSize: "3rem",
                      margin: " 0 10px",
                      cursor: "pointer",
                    }}
                    onClick={() => setToggleCheck(!toggleCheck)}
                  />
                )}
              </span>

              {/* <Switch
                checked={toggleCheck}
                onChange={() => setToggleCheck(!toggleCheck)}
                color="primary"
                name="checkedB"
                inputProps={{ "aria-label": "primary checkbox" }}
              /> */}
            </h3>
            <div>
              {/* {permission?.setting_franchise?.create ||
              permission?.setting_franchise?.edit ? ( */}
              <Button variant="primary" size="sm" id="kt_addvan_panel_toggle">
                {intl.formatMessage({ id: "Add.Van" })}
              </Button>
              {/* ) : (
                ""
              )} */}
            </div>
          </div>
          {toggleCheck && (
            <>
              {detailData && detailData.length ? (
                <Table responsive className="mt-5">
                  <thead>
                    <tr>
                      <th>{intl.formatMessage({ id: "Make" })}</th>
                      <th>{intl.formatMessage({ id: "Model" })}</th>
                      <th>{intl.formatMessage({ id: "Van.Name" })}</th>
                      <th>{intl.formatMessage({ id: "Registration" })}</th>
                      <th>{intl.formatMessage({ id: "#.of.Pods" })}</th>
                      <th style={{ width: "20px" }}></th>
                      <th style={{ width: "20px" }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {detailData
                      ? detailData.map((data, index) => (
                          <tr key={index}>
                            <td>{data.make}</td>
                            <td>{data.model}</td>
                            <td>{data.van_name}</td>
                            <td>{data.registration}</td>
                            <td>{data.no_of_pods}</td>

                            <td>
                              {permission?.setting_franchise?.create ||
                              permission?.setting_franchise?.edit ? (
                                <span
                                  className="symbol-label ml-2 pointer"
                                  id={`kt_editvan_panel${index}_toggle`}
                                >
                                  <span className="svg-icon svg-icon-sm svg-icon-primary">
                                    <SVG
                                      className="h-25 align-self-end"
                                      title="Edit"
                                      src={toAbsoluteUrl(
                                        "/media/svg/icons/Design/Edit.svg"
                                      )}
                                    ></SVG>
                                  </span>
                                </span>
                              ) : (
                                ""
                              )}
                            </td>
                            <td>
                              <span className="switch switch-outline switch-icon switch-primary">
                                <label>
                                  <input
                                    type="checkbox"
                                    checked={data.status === 1 ? true : false}
                                    onChange={(e) =>
                                      setDialog({
                                        status: data.status,
                                        modal: true,
                                        data: {
                                          e: e.target.checked,
                                          id: data.id,
                                          franchise: data.franchise_id,
                                        },
                                      })
                                    }
                                  />
                                  <span></span>
                                </label>
                              </span>
                            </td>
                            <td>
                              <EditVan type={index} data={data} />
                            </td>
                          </tr>
                        ))
                      : ""}
                  </tbody>
                </Table>
              ) : (
                <>
                  {vanLoading ? (
                    <div className="text-center mt-8">
                      <CircularProgress />
                    </div>
                  ) : (
                    <div className="mt-4" style={{ textAlign: "center" }}>
                      <p>{intl.formatMessage({ id: "No.Record.Found" })}</p>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
      {snack.call ? <SnackBarTool {...snack} /> : ""}
      <ActiveDialogApi
        dialog={dialog}
        setDialog={setDialog}
        handleChange={handleChange}
      />
      <AddVan detailData={detailDataFra} />
      <InactiveDialog setJob={setJob} job={job} />
    </>
  );
}
