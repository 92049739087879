/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

import * as Yup from "yup";

import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import {
  createFranchiseSectorPostCode,
  deleteFranchisePostCode,
} from "../../../_redux/franchises/franchisesCrud";
import {
  fetchFranchisesPostCode,
  fetchFranchisesSectorPostCode,
} from "../../../_redux/franchises/franchisesActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import DeleteDialogApi from "../franchise-active-dialog/DeleteDialog";
import { FranchiseSectorPostCodeSchema } from "../franchise-form/validateSchema";
import SnackBarTool from "../../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { canvaCancel } from "../../../../../utils/canvaCloseFunction";
import { useFormik } from "formik";
import { useIntl } from "react-intl";

export function AddSector({ detailData }) {
  const intl = useIntl();
  // Use state hook to manage snackbar state
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  // Use state hook to manage search state
  const [search, setSearch] = useState("");
  // Use state hook to manage error state
  const [error, setError] = useState({ type: false, search: "" });
  // Use state hook to manage dialog state
  const [dialog, setDialog] = useState({ modal: false });

  // Initial values for the form
  const initialValues = {
    franchise_id: detailData ? detailData : "",
    name: "",
    status: 1,
    postcode: [],
    price: "",
  };

  // Use the useDispatch hook to dispatch actions
  const dispatch = useDispatch();

  // Use the useSelector hook to access the state
  const { postcodeList, postcodeTotal } = useSelector(
    (state) => ({
      postcodeList: state.franchise.postcode,
      postcodeTotal: state.franchise.poscodeTotal,
    }),
    shallowEqual
  );

  // Use effect hook to fetch initial postcode data
  // useEffect(() => {
  //   if (detailData) {
  //     dispatch(fetchFranchisesPostCode(detailData, ""));
  //   }
  // }, [detailData, dispatch]);

  // Use effect hook to fetch postcode data based on search
  useEffect(() => {
    if (detailData && search !== "") {
      dispatch(fetchFranchisesPostCode(detailData, search));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, search]);

  // Event handler for form submission
  const handleSubmit = (values, actions) => {
    setSnack({ call: false, type: "", message: "" });
    // Call the createFranchiseSectorPostCode function to create a new postcode
    createFranchiseSectorPostCode(values)
      .then((res) => {
        setSnack({ call: true, type: "success", message: res.data.message });
        // Dispatch an action to fetch updated postcode data
        dispatch(fetchFranchisesSectorPostCode(formik.values.franchise_id));
        canvaCancel("kt_addsector_panel_close");
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
  };

  // Event handler for deleting a postcode
  const handleDelete = () => {
    setSnack({ call: false, type: "", message: "" });
    // Call the deleteFranchisePostCode function to delete a postcode
    deleteFranchisePostCode(dialog.data.id)
      .then((res) => {
        setSnack({ call: true, type: "success", message: res.data.message });
        // Dispatch an action to fetch updated postcode data
        dispatch(fetchFranchisesPostCode(detailData, ""));
        setDialog({ modal: false });
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
        setDialog({ modal: false });
      });
    setDialog({ modal: false });
  };

  const handleReset = () => {};

  // Use the useFormik hook to handle form logic
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: FranchiseSectorPostCodeSchema(intl, Yup),
    onSubmit: handleSubmit,
    onReset: handleReset,
  });

  useEffect(() => {
    if (search !== "" && postcodeList && postcodeList.length < 1) {
      setError({ type: true, search: "Postcode not found" });
    } else {
      setError({ type: false, search: "" });
    }
  }, [postcodeList, search]);

  const handleAdd = (name) => {
    let arr = [...formik.values.postcode];
    if (arr.length) {
      let newArr = [];
      let isExists = false;
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].postcode !== name) {
          newArr.push(arr[i]);
        } else {
          isExists = true;
        }
      }
      if (!isExists) newArr.push({ postcode: name });
      arr = newArr;
    } else {
      arr.push({ postcode: name });
    }

    formik.setFieldValue("postcode", arr);
  };

  return (
    <div
      id="kt_addsector_panel"
      className="offcanvas offcanvas-right pt-5 pb-10"
    >
      {/*begin::Header*/}
      <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
        <div
          className="offcanvas-close mt-n1 pr-5"
          style={{ position: "absolute", top: "15px", right: "10px" }}
        >
          <span
            onClick={() => formik.resetForm()}
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            id="kt_addsector_panel_close"
          >
            <i className="ki ki-close icon-sm text-muted"></i>
          </span>
        </div>
      </div>
      {/*end::Header*/}
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <div className="offcanvas-content px-10 ">
          <div>
            <h5>{intl.formatMessage({ id: "Add.Sector" })}</h5>
          </div>
          <div
            id="kt_quick_panel_logs_kt_addsector_panel"
            className="fade pt-3 pr-5 mr-n5 scroll active show ps--active-y mt-10"
          >
            <div className="mt-10 pb-10">
              <div className="pr-5">
                {" "}
                <div className="row">
                  <div className="col-md-4 ">
                    <Form.Group className="mb-0">
                      <InputGroup className="search-input">
                        <Form.Control
                          type="text"
                          placeholder={intl.formatMessage({ id: "Search" })}
                          aria-describedby="inputGroupPrepend"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                        />
                        <i className="fa fa-search "></i>
                      </InputGroup>
                      {error.type ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{error.search}</div>
                        </div>
                      ) : null}
                    </Form.Group>
                  </div>
                  <div className="col-md-4">
                    <Form.Control
                      type="text"
                      placeholder={intl.formatMessage({ id: "Sector.Name" })}
                      name="name"
                      {...formik.getFieldProps("name")}
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.name}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-md-4">
                    <Form.Control
                      type="text"
                      placeholder={"Price"}
                      name="price"
                      {...formik.getFieldProps("price")}
                    />
                    {formik.touched.price && formik.errors.price ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.price}
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <div className="col-md-4"></div>
                </div>
              </div>
              <div
                className="mt-10 scroll scroll-pull "
                id="kt_quick_panel_notifications_kt_addsector_panel"
                style={{ width: "90%" }}
              >
                <div className="postcodelist">
                  {postcodeList && postcodeList.length ? (
                    postcodeList.map((data, index) => (
                      <div key={index}>
                        <Button
                          variant={
                            formik.values.postcode.find(
                              (element) => element.postcode === data.postcode
                            )
                              ? ""
                              : "outline-secondary"
                          }
                          className={
                            formik.values.postcode.find(
                              (element) => element.postcode === data.postcode
                            )
                              ? "btnClick w-100"
                              : "w-100 "
                          }
                          onClick={() => handleAdd(data.postcode)}
                        >
                          {data.postcode}&nbsp;&nbsp;&nbsp;
                        </Button>
                      </div>
                    ))
                  ) : (
                    <span>No Record Found</span>
                  )}
                </div>
              </div>

              <div className="mt-5">
                {intl.formatMessage({ id: "Total.Postcode" })} :{" "}
                {postcodeTotal ? postcodeTotal : "0"}
              </div>
            </div>
          </div>
        </div>
        <div className="offcanvas-footer">
          <div className="offcanvas-content text-right ">
            <button
              type="reset"
              className="btn btn-outline-dark font-weight-bolder font-size-sm mr-3 "
              onClick={() => canvaCancel("kt_addsector_panel_close")}
            >
              {intl.formatMessage({ id: "CANCEL" })}
            </button>
            <button
              type="submit"
              className="btn btn-primary font-weight-bolder font-size-sm "
            >
              {intl.formatMessage({ id: "SAVE" })}
            </button>
          </div>
        </div>
      </form>
      <DeleteDialogApi
        dialog={dialog}
        setDialog={setDialog}
        title={"Postcode"}
        handleChange={handleDelete}
      />
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </div>
  );
}
