import React from "react";
import { Card, CardBody } from "../../../_metronic/_partials/controls";
import { ReportingFilter } from "./reporting-filter/Reporting";
import ReportingTable from "./reporting-table/ReportingTable";

const ReportingCard = () => {
  return (
    <Card>
      <CardBody>
        <ReportingFilter />
        <ReportingTable />
      </CardBody>
    </Card>
  );
};

export default ReportingCard;
