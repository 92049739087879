export const ProductStatusCssClasses = ["success", "danger", ""];
export const ProductStatusTitles = ["Active", "Inactive"];
export const ProductConditionCssClasses = ["success", "danger", ""];
export const ProductConditionTitles = ["New", "Used"];
export const defaultSorted = [{ dataField: "id", order: "asc" }];
export const sizePerPageList = [
  { text: "5", value: 5 },
  { text: "10", value: 10 },
  { text: "20", value: 20 },
  { text: "50", value: 50 },
];
export const initialFilter = {
  filter: {
    search: "",
    search_by: "",
    search_value: "",
  },
  sortOrder: "", // asc||desc
  sortField: "",
  pageSize: 10,
  pageNumber:1
};

export const initialValues = {
  code: "",
  description: "",
  offer_type: "",
  value: "",
  valid_from: "",
  valid_to: "",
  status: "",
  is_all_franchises: "",
};

export const promoInitialValues = (editPromoData) => {
  return {
  offer_code: editPromoData && editPromoData.name ? editPromoData.name : "",
  offer_description: editPromoData && editPromoData.metadata && editPromoData.metadata.description ? editPromoData.metadata.description : "",
  offer_type: editPromoData && editPromoData.metadata && editPromoData.metadata.offer_type ? editPromoData.metadata.offer_type : "",
  currency_code: "GBP",
  value: editPromoData && editPromoData.percent_off
  ? editPromoData.percent_off
  : editPromoData && editPromoData.amount_off
  ? editPromoData.amount_off
  : "",
  apply_on: "invoice_amount",
  duration_type: editPromoData && editPromoData.metadata && editPromoData.metadata.duration_type ? editPromoData.metadata.duration_type : "",
  duration: editPromoData && editPromoData.duration_in_months ? editPromoData.duration_in_months : "",
  promotion_id: editPromoData && editPromoData.id ? editPromoData.id : "",
}
};
