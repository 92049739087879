/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

import React, { useEffect, useState } from "react";
import { createProfile, updateProfile } from "../../../_redux/user/userCrud";
import {
  fetchParentProfileDrop,
  fetchProfile,
  fetchProfileDetail,
} from "../../../_redux/user/userAction";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import ActiveDialog from "../user-dialog/ActiveDialog";
import DiscardDialog from "../../../../../../_metronic/_partials/widgets/alert/DiscardDialog";
import { NewProfileSchema } from "../user-form/validateSchema";
import ProfileForm from "../user-form/ProfileForm";
import SnackBarTool from "../../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { canvaCancel } from "../../../../../utils/canvaCloseFunction";
import { useFormik } from "formik";
import { useIntl } from "react-intl";
import { useUserUIContext } from "../UserUIContext";

// This component is a canvas that allows a user to create or edit a profile
// It takes in 5 props: check, title, type, permissArray, detailData
export function NewProfileCanva({
  check,
  title,
  type,
  permissArray,
  detailData,
}) {
  // intl is for internationalization
  const intl = useIntl();
  // useDispatch hook for dispatching actions
  const dispatch = useDispatch();
  const userUIContext = useUserUIContext();

  // active state for toggling the canvas
  const [active, setActive] = useState(false);
  // dialog state for toggling a confirmation dialog
  const [dialog, setDialog] = useState(false);
  // snack state for toggling a snackbar notification
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  // loading state for toggling a loading spinner
  const [loading, setLoading] = useState(false);
  // dialogDis state for toggling a discard changes dialog
  const [dialogDis, setDialogDis] = useState(false);

  // initialValues for the form fields
  const initialValues = {
    name: "",
    parent_id: "",
    description: "",
    permission: {},
    status: 0,
  };

  // editValues for the form fields when in edit mode
  const editValues = {
    name: detailData && detailData.name ? detailData.name : "",
    description:
      detailData && detailData.description ? detailData.description : "",
    permission: permissArray ? permissArray : "",
    status: detailData && detailData.status ? detailData.status : "0",
    parent_id: detailData && detailData.parent_id ? detailData.parent_id : "",
  };

  // handleSubmit function for submitting the form data
  const handleSubmit = (values, actions) => {
    setLoading(true);
    // reset the snackbar notification
    setSnack({ call: false, type: "" });
    const arr = Object.keys(values.permission);
    const array = [];
    for (const items of arr) {
      const tt = Object.keys(values.permission[items]);
      for (const it of tt) {
        if (values.permission[items][it]) {
          array.push(`${items}_${it}`);
        }
      }
    }

    const data = {
      name: values.name,
      description: values.description,
      permissions: array,
      status: values.status,
      parent_id: values.parent_id,
    };
    if (type === "edit") {
      updateProfile(data, detailData.id)
        .then((res) => {
          // canvaCancel("kt_create_tasks_panel_close");
          setSnack({ call: true, type: "success", message: res.data.message });
          canvaCancel("kt_new_profile_panel_close");

          dispatch(fetchProfileDetail(detailData.id));
          setLoading(false);
          formik.resetForm();
        })
        .catch((error) => {
          setSnack({
            call: true,
            type: "error",
            message: error?.response?.data?.message,
            errors: error?.response?.data?.message,
          });
          setLoading(false);
        });
    } else {
      createProfile(data)
        .then((res) => {
          // canvaCancel("kt_create_tasks_panel_close");
          setSnack({ call: true, type: "success", message: res.data.message });
          canvaCancel("kt_new_profile_panel_close");

          dispatch(fetchProfile(userUIContext.queryParams));
          setLoading(false);
          formik.resetForm();
        })
        .catch((error) => {
          setSnack({
            call: true,
            type: "error",
            message: error?.response?.data?.message,
            errors: error?.response?.data?.message,
          });
          setLoading(false);
        });
    }
  };

  const handleReset = () => {};

  // formik hook for handling form state, validation, and submission
  const formik = useFormik({
    initialValues: type === "edit" ? editValues : initialValues,
    enableReinitialize: true,
    validationSchema: NewProfileSchema(intl),
    onSubmit: handleSubmit,
    onReset: handleReset,
  });

  useEffect(() => {
    if (formik.values.status && type === "edit") {
      setActive(formik.values.status === 1 ? true : false);
    }
  }, [formik.values.status, type]);

  useEffect(() => {
    dispatch(fetchParentProfileDrop());
  }, [dispatch]);

  const { parentDrop } = useSelector(
    (state) => ({
      parentDrop: state.userDetail.profile.parentDrop,
    }),
    shallowEqual
  );

  const handleDiscard = () => {
    if (formik.dirty) {
      setDialogDis(true);
    } else {
      canvaCancel("kt_new_profile_panel_close");
      formik.resetForm();
    }
  };

  return (
    <div
      id="kt_new_profile_panel"
      className={`offcanvas offcanvas-right pt-5 pb-10 ${
        check ? "offcanvas-on" : ""
      }`}
    >
      {/* Header section with close button */}
      <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
        <div
          className="offcanvas-close mt-n1  pr-5"
          style={{ position: "absolute", top: "15px", right: "10px" }}
        >
          <span
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            onClick={() => handleDiscard()}
          >
            <i className="ki ki-close icon-xs text-muted"></i>
          </span>
        </div>
        <div id="kt_new_profile_panel_close"></div>
      </div>

      {/*end::Header*/}

      {/* Form for creating or editing a profile */}
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <div className="offcanvas-content px-10 newprofiel">
          {/* Title */}
          <div>
            <h5>{title}</h5>
          </div>
          <div className="tab-content">
            <div
              id="kt_quick_panel_logs_kt_new_profile_panel"
              role="tabpanel"
              className={`fade pt-3 pr-5 mr-n5 scroll  active show `}
            >
              <ProfileForm
                formik={formik}
                active={active}
                setDialog={setDialog}
                parentDrop={parentDrop}
              />
            </div>
          </div>
        </div>
        {/* Footer with save and cancel buttons */}
        <div className="offcanvas-footer">
          <div className="offcanvas-content text-right ">
            <button
              type="button"
              className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
              onClick={() => handleDiscard()}
            >
              {intl.formatMessage({ id: "CANCEL" })}
            </button>
            <button
              type="submit"
              className="btn btn-primary font-weight-bolder font-size-sm "
              disabled={loading}
            >
              {intl.formatMessage({ id: "SAVE" })}
              {loading && (
                <span className="ml-2 mr-2 spinner spinner-white"></span>
              )}
            </button>
          </div>
        </div>
      </form>
      <ActiveDialog
        active={active}
        setActive={setActive}
        dialog={dialog}
        setDialog={setDialog}
        formik={formik}
        type={"profile"}
      />
      <DiscardDialog
        setDialogDis={setDialogDis}
        dialogDis={dialogDis}
        formik={formik}
        closeId={"kt_new_profile_panel_close"}
      />
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </div>
  );
}
