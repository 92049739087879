/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

import "rc-time-picker/assets/index.css";

import * as Yup from "yup";

import React, { useEffect, useRef, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { FormControl, MenuItem, Select } from "@material-ui/core/";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import CancelDialog from "../dialog/CancelDialog";
import DatePicker from "react-datepicker";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import SnackBarTool from "../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import TimePicker from "rc-time-picker";
import { fetchContactTasksList } from "../_redux/contact/contactAction";
import { fetchDealTasksList } from "../_redux/deals/dealsAction";
import { getInputClasses } from "../../../utils/commonFunction";
import { initialValuesDealTasks } from "../ContactHelper";
import { initialValuesTasks } from "../ContactHelper";
import moment from "moment";
import { tasksSchema } from "../form/validationSchema";
import { updateTask } from "../_redux/contact/contactCrud";
import { useCallback } from "react";
import { useFormik } from "formik";
import { useIntl } from "react-intl";

export function TaskEdit({ setCanva, canva, ownerList, type }) {
  // Create a ref to access the DOM element
  const ref = useRef();
  // Use the useIntl hook to access the intl object
  const intl = useIntl();
  // Use the useDispatch hook to access the dispatch function
  const dispatch = useDispatch();

  // Use the useSelector hook to access the admin from the state
  const { admin } = useSelector(
    (state) => ({
      admin: state.auth.user,
    }),
    shallowEqual
  );

  // State for the snackbar
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  // State for the dialog
  const [dialog, setDialog] = useState(false);
  // State for the timer
  const [timer, setTimer] = useState(false);

  // Handles form submission
  const handleSubmit = (values, { resetForm }) => {
    setSnack({ call: false, type: "", message: "" });
    updateTask(canva.data.id, values)
      .then((res) => {
        setSnack({ call: true, type: "success", message: res.data.message });
        resetForm();
        // Dispatch the appropriate action depending on the type
        if (type === "Deal") {
          dispatch(fetchDealTasksList(values.deal_id, values.contact_id));
        } else {
          dispatch(fetchContactTasksList(values.contact_id));
        }
        setCanva({ type: false, data: {} });
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",
          error: error.response.data.errors,
        });
      });
  };
  const handleReset = () => {};

  // Use the useFormik hook to setup the form
  const formik = useFormik({
    initialValues:
      type === "Deal"
        ? initialValuesDealTasks(canva)
        : initialValuesTasks(canva),
    enableReinitialize: true,
    validationSchema: tasksSchema(intl, Yup),
    onSubmit: handleSubmit,
    onReset: handleReset,
  });
  const handleSetDate = (e) => {
    const formated = moment(e).format("YYYY-MM-DD");

    formik.setFieldValue("due_date", formated);
  };

  const handleClose = () => {
    if (formik.dirty) {
      setDialog(true);
    } else {
      setCanva({ type: false, data: {} });
      formik.resetForm();
    }
  };

  const handleScroll = useCallback(
    (e) => {
      setTimer(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [timer]
  );

  useEffect(() => {
    if (timer) {
      document
        .getElementById("kt_quick_panel_logs_kt_taskedit_panel")
        .addEventListener("scroll", handleScroll);
    } else {
      document
        .getElementById("kt_quick_panel_logs_kt_taskedit_panel")
        .removeEventListener("scroll", handleScroll);
    }

    return () => {
      document
        .getElementById("kt_quick_panel_logs_kt_taskedit_panel")
        .removeEventListener("scroll", handleScroll);
    };
  }, [timer, handleScroll]);

  return (
    <div
      id="kt_taskedit_panel"
      className="offcanvas offcanvas-right pt-5 pb-10 offcanvas-on"
    >
      {/*begin::Header*/}
      <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
        <div
          className="offcanvas-close mt-n1 pr-5"
          style={{ position: "absolute", top: "15px", right: "10px" }}
        >
          <span
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            id="kt_taskedit_panel_close"
            onClick={() => handleClose()}
          >
            <i className="ki ki-close icon-sm text-muted"></i>
          </span>
        </div>
      </div>
      {/*end::Header*/}
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <div className="offcanvas-content px-10 date-picker ">
          <div>
            <h5>{intl.formatMessage({ id: "Task.Edit" })}</h5>
          </div>
          <div className="tab-content">
            <div
              className={`tab-pane fade pt-3 pr-5 mr-n5 scroll active show overflowCanva`}
              id="kt_quick_panel_logs_kt_taskedit_panel"
              ref={ref}
              role="tabpanel"
            >
              <div className="row">
                <div className="col-md-4">
                  <div className="form-groupB" controlId="formBasicEmail">
                    <Form.Label>
                      {intl.formatMessage({ id: "Task.Type" })}
                    </Form.Label>
                    <FormControl fullWidth>
                      <Select
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                        className={`form-control w-100  ${getInputClasses(
                          formik,
                          "task_type"
                        )}`}
                        name="task_type"
                        {...formik.getFieldProps("task_type")}
                        IconComponent={(props) => (
                          <i {...props}>
                            <KeyboardArrowDownIcon />
                          </i>
                        )}
                        inputProps={{ "aria-label": "Without label" }}
                        displayEmpty
                      >
                        <MenuItem value="">Select</MenuItem>
                        <MenuItem value={"Call"}>Call</MenuItem>
                        <MenuItem value={"Email"}>Email</MenuItem>
                        <MenuItem value={"SMS"}>SMS</MenuItem>
                      </Select>
                    </FormControl>
                    {formik.touched.task_type && formik.errors.task_type ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.task_type}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-4 mb-5">
                  <FormControl fullWidth>
                    <label>{intl.formatMessage({ id: "Priority" })}</label>
                    <Select
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                      IconComponent={(props) => (
                        <i {...props}>
                          <KeyboardArrowDownIcon />
                        </i>
                      )}
                      inputProps={{ "aria-label": "Without label" }}
                      displayEmpty
                      name="priority"
                      className={`form-control w-100  ${getInputClasses(
                        formik,
                        "priority"
                      )}`}
                      {...formik.getFieldProps("priority")}
                    >
                      <MenuItem value="">
                        {intl.formatMessage({ id: "Select" })}
                      </MenuItem>
                      <MenuItem value="Hot">
                        {intl.formatMessage({ id: "Hot" })}
                      </MenuItem>
                      <MenuItem value="Medium">
                        {intl.formatMessage({ id: "Medium" })}
                      </MenuItem>
                      <MenuItem value="Low">
                        {intl.formatMessage({ id: "Low" })}{" "}
                      </MenuItem>
                    </Select>
                  </FormControl>
                  {formik.touched.priority && formik.errors.priority ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.priority}
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="col-md-4">
                  <div
                    className="form-groupB"
                    controlId="exampleForm.ControlSelect1"
                  >
                    <Form.Label>
                      {intl.formatMessage({ id: "Task.Name" })}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      className={`form-control  ${getInputClasses(
                        formik,
                        "task_name"
                      )}`}
                      name="task_name"
                      {...formik.getFieldProps("task_name")}
                    />
                    {formik.touched.task_name && formik.errors.task_name ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.task_name}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-groupB">
                    <Form.Label>
                      {intl.formatMessage({ id: "Due.Date" })}
                    </Form.Label>
                    <InputGroup className="calendr">
                      <DatePicker
                        fullWidth
                        fixedHeight
                        className="form-control"
                        wrapperClassName="datepicker"
                        dateFormat="dd-MM-yyyy"
                        selected={Date.parse(formik.values?.due_date)}
                        onChange={(date) => handleSetDate(date)}
                        // popperClassName="date-picker-reports"
                        placeholderText="DD-MM-YYYY"
                      />
                      <CalendarTodayIcon />
                    </InputGroup>
                    {formik.touched.due_date && formik.errors.due_date ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.due_date}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-4 mb-5">
                  <label>{intl.formatMessage({ id: "Time" })}</label>
                  <div>
                    <TimePicker
                      defaultValue={moment()}
                      value={moment(
                        `${formik.values.due_date} ${formik.values.time}`
                      )}
                      showSecond={false}
                      open={timer}
                      onOpen={() => setTimer(true)}
                      onClose={() => setTimer(false)}
                      onChange={(e) =>
                        formik.setFieldValue(
                          "time",
                          moment(e).format("HH:mm") === "Invalid date"
                            ? "00:00"
                            : moment(e).format("HH:mm")
                        )
                      }
                      focusOnOpen
                      clearIcon
                    />
                  </div>
                  {formik.touched.time && formik.errors.time ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.time}</div>
                    </div>
                  ) : null}
                </div>
                <div className="col-md-4">
                  <div className="form-groupB" controlId="formBasicEmail">
                    <Form.Label>
                      {intl.formatMessage({ id: "Task.Owner" })}
                    </Form.Label>
                    <FormControl fullWidth>
                      <Select
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                        IconComponent={(props) => (
                          <i {...props}>
                            <KeyboardArrowDownIcon />
                          </i>
                        )}
                        inputProps={{ "aria-label": "Without label" }}
                        displayEmpty
                        className={`form-control w-100  ${getInputClasses(
                          formik,
                          "task_owner_id"
                        )}`}
                        name="task_owner_id"
                        {...formik.getFieldProps("task_owner_id")}
                      >
                        <MenuItem value="">Select</MenuItem>
                        <MenuItem value="is_unassigned">
                          {intl.formatMessage({ id: "Unassigned" })}
                        </MenuItem>
                        <MenuItem value={admin.id}>
                          {admin && admin.first_name ? admin.first_name : " "}
                          &nbsp;
                          {admin && admin.last_name ? admin.last_name : " "}
                        </MenuItem>
                        {ownerList &&
                          ownerList.map((data, index) => (
                            <MenuItem
                              key={index}
                              value={data.id}
                              disabled={data?.is_available !== 1 ? true : false}
                            >
                              {data && data.first_name ? data.first_name : " "}
                              &nbsp;
                              {data && data.last_name ? data.last_name : " "}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    {formik.touched.task_owner_id &&
                    formik.errors.task_owner_id ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.task_owner_id}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-groupB" controlId="formBasicEmail">
                    <Form.Label>
                      {intl.formatMessage({ id: "Task.Description" })}
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows="9"
                      className={`form-control  ${getInputClasses(
                        formik,
                        "task_description"
                      )}`}
                      name="task_description"
                      {...formik.getFieldProps("task_description")}
                    />
                    {formik.touched.task_description &&
                    formik.errors.task_description ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.task_description}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="offcanvas-footer">
          <div className="offcanvas-content text-right ">
            <button
              type="button"
              className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
              onClick={() => handleClose()}
            >
              {intl.formatMessage({ id: "CANCEL" })}
            </button>
            <button
              type="submit"
              className="btn btn-primary font-weight-bolder font-size-sm "
            >
              {intl.formatMessage({ id: "SAVE" })}
            </button>
          </div>
        </div>
      </form>
      {snack.call ? <SnackBarTool {...snack} /> : ""}
      <CancelDialog
        setDialog={setDialog}
        dialog={dialog}
        formik={formik}
        type={"task"}
        setCanva={setCanva}
      />
    </div>
  );
}
