export const view = (intl) => {
  return [
    { id: "all_tasks", title: intl.formatMessage({ id: "All.Tasks" }) },
    { id: "my_tasks", title: intl.formatMessage({ id: "My.Tasks" }) },
    { id: "new_last_week", title: intl.formatMessage({ id: "New.Last.Week" }) },
    { id: "new_this_week", title: intl.formatMessage({ id: "New.This.Week" }) },
  ];
};

export const allTasks = (intl) => {
  return [
    { id: "Call", title: intl.formatMessage({ id: "Call" }) },
    { id: "Email", title: intl.formatMessage({ id: "Email" }) },
    { id: "SMS", title: intl.formatMessage({ id: "SMS" }) },
  ];
};

export const stages = (intl) => {
  return [
    { id: "contact", title: intl.formatMessage({ id: "Contact" }) },
    { id: "deal", title: intl.formatMessage({ id: "DEALS" }) },
    { id: "customer", title: intl.formatMessage({ id: "Customer" }) },
  ];
};
