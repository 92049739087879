/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

import React, { useEffect, useState } from "react";
import {
  createFranchisePostCode,
  deleteFranchisePostCode,
  franchiseSectorStatus,
} from "../../../_redux/franchises/franchisesCrud";
import {
  fetchFranchisesPostCode,
  fetchFranchisesSectorPostCode,
} from "../../../_redux/franchises/franchisesActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import ActiveDialogApi from "../../../../../../_metronic/_partials/widgets/alert/ActiveDialogApiCall";
import { Button } from "react-bootstrap";
import DeleteDialogApi from "../franchise-active-dialog/DeleteDialog";
import EditSector from "./EditSector";
import { Form } from "react-bootstrap";
import { InputGroup } from "react-bootstrap";
import SVG from "react-inlinesvg";
import SnackBarTool from "../../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { canvaCancel } from "../../../../../utils/canvaCloseFunction";
import { initIds } from "../../../../../../_metronic/_partials/controls/RightPanel";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import { useFormik } from "formik";
import { useIntl } from "react-intl";
import { CircularProgress } from "@material-ui/core";
import StairStepModal from "../franchise-modal/SectorPriceModal";

export function FranchisePostcodes({ detailData, detail, setOpenCanva }) {
  const intl = useIntl();
  const init = ["kt_addsector_panel", "kt_editsector_panel"];

  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [search, setSearch] = useState("");
  const [dialog, setDialog] = useState({ modal: false });
  const [dialogSector, setDialogSector] = useState({ modal: false });
  const [error, setError] = useState({ type: false, search: "" });
  const [sectorDa, setSectorDa] = useState({ type: "" });
  const [sectorPrice, setSectorPrice] = useState({ open: false })

  const dispatch = useDispatch();

  const {
    postcodeList,
    postcodeTotal,
    sectorList,
    secPostcodeLoading,
    fraPostcodeLoading,
  } = useSelector(
    (state) => ({
      postcodeList: state.franchise.postcode,
      postcodeTotal: state.franchise.poscodeTotal,
      sectorList: state.franchise.sectorList,
      secPostcodeLoading: state.franchise.secPostcodeLoading,
      fraPostcodeLoading: state.franchise.fraPostcodeLoading,
    }),
    shallowEqual
  );

  useEffect(() => {
    initIds(init);
  }, [init]);

  useEffect(() => {
    // console.log("hello1", detailData?.id);
    if (detailData) {
      dispatch(fetchFranchisesPostCode(detailData, search));
    }
  }, [detailData, dispatch, search]);

  useEffect(() => {
    if (detailData) {
      dispatch(fetchFranchisesSectorPostCode(detailData));
    }
  }, [detailData, dispatch]);

  const initialValues = {
    franchise_id: detailData ? detailData : "",
    postcode: "",
    is_temp_postcode: "0",
  };

  const handleSubmit = (values, actions) => {
    setSnack({ call: false, type: "", message: "" });
    if (values.postcode !== "") {
      createFranchisePostCode(values)
        .then((res) => {
          setSnack({ call: true, type: "success", message: res.data.message });
          dispatch(fetchFranchisesPostCode(detailData, ""));
          formik.resetForm();
        })
        .catch((error) => {
          setSnack({
            call: true,
            type: "error",
            message: error.response.data.message,
          });
        });
    } else {
      formik.resetForm();
      canvaCancel("kt_franchisepostcodes_panel_close");
    }
  };

  const handleReset = () => { };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: handleSubmit,
    onReset: handleReset,
  });

  useEffect(() => {
    if (search !== "" && postcodeList && postcodeList.length < 1) {
      setError({ type: true, search: "Postcode not found" });
    } else {
      setError({ type: false, search: "" });
    }
  }, [postcodeList, search]);

  const handleChange = () => {
    setSnack({ call: false, type: "", message: "" });

    const data = {
      id: dialogSector.data.id,
      status: dialogSector.data.e ? 1 : 0,
    };
    franchiseSectorStatus(data)
      .then((res) => {
        if (res.data.success) {
          dispatch(fetchFranchisesSectorPostCode(detailData));
          setSnack({
            call: true,
            type: "success",
            message: res.data.message,
          });
          setDialogSector({ modal: false });
        } else {
          setSnack({ call: true, type: "error", message: res.data.message });
          setDialogSector({ modal: false });
        }
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
    // }
    setDialogSector({ modal: false });
  };

  const handleDelete = () => {
    setSnack({ call: false, type: "", message: "" });
    deleteFranchisePostCode(dialog.data.id)
      .then((res) => {
        setSnack({ call: true, type: "success", message: res.data.message });
        dispatch(fetchFranchisesPostCode(detailData, ""));
        setDialog({ modal: false });
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
        setDialog({ modal: false });
      });
    setDialog({ modal: false });
  };
  const handleClose = () => {
    setSearch("");
    formik.resetForm();

    setOpenCanva({ fraPod: false });
  };

  return (
    <div
      id="kt_franchisepostcodes_panel"
      className="offcanvas offcanvas-right pt-5 pb-10 offcanvas-on "
    >
      {/*begin::Header*/}
      <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
        <div
          className="offcanvas-close mt-n1 pr-5"
          style={{ position: "absolute", top: "15px", right: "10px" }}
        >
          <span
            onClick={handleClose}
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            id="kt_franchisepostcodes_panel_close"
          >
            <i className="ki ki-close icon-sm text-muted"></i>
          </span>
        </div>
      </div>
      {/*end::Header*/}
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <div className="offcanvas-content px-10 ">
          <div>
            <h5>{intl.formatMessage({ id: "Franchise.Postcodes" })}</h5>
          </div>
          <div className="tab-content">
            <div
              id="kt_quick_panel_logs_kt_franchisepostcodes_panel"
              className="tab-pane fade pt-3 pr-5 mr-n5 custom-scroll  active show"
              role="tabpanel"
            >
              <div className=" pb-10">
                <div className="pr-5">
                  <div className="row">
                    <div className="col-md-4 ">
                      <Form.Group className="mb-0">
                        <InputGroup className="search-input">
                          <Form.Control
                            type="text"
                            placeholder={intl.formatMessage({ id: "Search" })}
                            aria-describedby="inputGroupPrepend"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                          />
                          <i className="fa fa-search "></i>
                        </InputGroup>
                        {error.type ? (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{error.search}</div>
                          </div>
                        ) : null}
                      </Form.Group>
                    </div>
                    <div className="col-md-4">
                      <Form.Control
                        type="text"
                        placeholder={intl.formatMessage({ id: "Add.Postcode" })}
                        name="postcode"
                        maxLength={"10"}
                        {...formik.getFieldProps("postcode")}
                      />
                      {formik.touched.postcode && formik.errors.postcode ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.postcode}
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-4">
                      <div className="d-flex align-items-center">
                        <span>
                          {intl.formatMessage({ id: "Temp.Postcode" })}
                        </span>
                        <span className="switch switch-outline switch-icon switch-primary">
                          <label className="ml-5">
                            <input
                              type="checkbox"
                              checked={
                                formik.values.is_temp_postcode === 1
                                  ? true
                                  : false
                              }
                              onChange={(e) =>
                                formik.setFieldValue(
                                  "is_temp_postcode",
                                  e.target.checked ? 1 : 0
                                )
                              }
                            />
                            <span></span>
                          </label>
                        </span>
                      </div>
                      {formik.touched.tempPostCode &&
                        formik.errors.tempPostCode ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.tempPostCode}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div
                  className="mt-10 scroll scroll-pull heightCon customeScrollAuto"
                  id="kt_quick_panel_notifications_kt_franchisepostcodes_panel"
                  style={{ width: "88%", height: "100px !important" }}
                >
                  <div className="postcodelist">
                    {postcodeList &&
                      !fraPostcodeLoading &&
                      postcodeList.length ? (
                      postcodeList.map((data, index) => (
                        <div key={index}>
                          <Button
                            variant={
                              data.is_temp_postcode === 1
                                ? "outline-secondarys"
                                : "primary"
                            }
                            className="w-100"
                          >
                            {data.postcode}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <i
                              className="fas fa-times "
                              onClick={() =>
                                setDialog({
                                  modal: true,
                                  data: {
                                    id: data.id,
                                  },
                                })
                              }
                            ></i>
                          </Button>
                        </div>
                      ))
                    ) : (
                      <>
                        {fraPostcodeLoading ? (
                          <>
                            <div
                              className="text-center mt-8"
                              style={{ width: "100%", overflow: "hidden" }}
                            >
                              <CircularProgress />
                            </div>
                          </>
                        ) : (
                          <div style={{ textAlign: "center" }}>
                            <p>
                              {intl.formatMessage({ id: "No.Record.Found" })}
                            </p>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>

                <div>
                  {intl.formatMessage({ id: "Total.Postcode" })} :{" "}
                  {postcodeTotal ? postcodeTotal : "0"}
                </div>

                <div className="mt-10">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="d-flex justify-content-between align-items-center mb-5">
                        <div>
                          <h4>
                            {intl.formatMessage({ id: "Franchise.Sectors" })}
                          </h4>
                          <span>The price defined here is 'Per Pod Per Month Storage without the 50% Discount</span>
                        </div>
                        <div>
                          <Button
                            variant="primary"
                            type="button"
                            className="btn-sm"
                            id="kt_addsector_panel_toggle"
                            onClick={() => setSearch("")}
                          >
                            {intl.formatMessage({ id: "Add.Sector" })}{" "}
                          </Button>
                        </div>
                      </div>
                      {sectorList &&
                        !secPostcodeLoading &&
                        sectorList.length ? (
                        sectorList.map((data, index) => (
                          <div
                            className="d-flex justify-content-between align-items-center mt-1"
                            key={index}
                          >
                            <div>{data.name}</div>
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="mr-5">
                                {/* <Button variant="primary" size="sm" onClick={() => {
                                  setSectorPrice({ open: true })
                                  //   setDialogStair({open:true, data:detailData?.plan_pricing_economy, plan_type:"Economy-POD"})
                                }} >
                                  Add
                                </Button> */}
                                <span
                                  className="symbol-label ml-2"
                                  onClick={() =>
                                    setSectorDa({ type: index, data: data })
                                  }
                                >
                                  <span
                                    id={`kt_editsector_panel_toggle`}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <span className="svg-icon svg-icon-sm svg-icon-primary">
                                      <SVG
                                        title="edit"
                                        className="h-25 align-self-end"
                                        src={toAbsoluteUrl(
                                          "/media/svg/icons/Design/Edit.svg"
                                        )}
                                      ></SVG>
                                    </span>
                                  </span>
                                </span>
                              </div>
                              <div>
                                {" "}
                                <span className="switch switch-outline switch-icon switch-primary">
                                  <label className="ml-5">
                                    <input
                                      type="checkbox"
                                      checked={data.status === 1 ? true : false}
                                      onChange={(e) =>
                                        setDialogSector({
                                          status: data.status,
                                          modal: true,
                                          data: {
                                            e: e.target.checked,
                                            id: data.id,
                                            franchise: "",
                                          },
                                        })
                                      }
                                    />
                                    <span></span>
                                  </label>
                                </span>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <>
                          {secPostcodeLoading ? (
                            <div
                              className="text-center mt-8"
                              style={{ width: "100%", overflow: "hidden" }}
                            >
                              <CircularProgress />
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="offcanvas-footer">
          <div className="offcanvas-content text-right ">
            <button
              type="reset"
              className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
              onClick={handleClose}
            >
              {intl.formatMessage({ id: "CANCEL" })}
            </button>
            <button
              type="submit"
              className="btn btn-primary font-weight-bolder font-size-sm "
            >
              {intl.formatMessage({ id: "SAVE" })}
            </button>
          </div>
        </div>
      </form>
      {snack.call ? <SnackBarTool {...snack} /> : ""}
      <ActiveDialogApi
        dialog={dialogSector}
        setDialog={setDialogSector}
        title={"Sector"}
        handleChange={handleChange}
      />
      <DeleteDialogApi
        dialog={dialog}
        setDialog={setDialog}
        title={"Postcode"}
        handleChange={handleDelete}
      />
      {sectorDa.data ? (
        <>
          <EditSector
            type={sectorDa.type}
            data={sectorDa?.data}
            setSectorDa={setSectorDa}
          />
          <div
            className="offcanvas-overlay"
            onClick={() => setSectorDa({ type: "" })}
          ></div>
        </>
      ) : (
        ""
      )}
      {sectorPrice.open &&
        <StairStepModal dialog={sectorPrice} setDialog={setSectorPrice} />
      }
    </div>
  );
}
