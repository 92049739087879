// / eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef /

import { AddEditSchema } from "../order-form/validationSchema";
import React from "react";
import { useEffect } from "react";
import { useFormik } from "formik";

export function AddEditStorage() {
  useEffect(() => {
    const script = document.createElement("script");

    script.src =
      "https://calcumate-calculator-new-production.s3-ap-southeast-2.amazonaws.com/static/js/main.js";
    // script.src =
    //   "https://calcumate-calculator-new-production.s3-ap-southeast-2.amazonaws.com/static/js/main.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const initialValues = {
    notes: "",
    weight: "",
    pods: "",
  };

  const handleSubmit = (values, actions) => {
    actions.resetForm({ values: "" });
  };

  const handleReset = () => {};

  const formik = useFormik({
    initialValues,
    validationSchema: AddEditSchema,
    onSubmit: handleSubmit,
    onReset: handleReset,
  });

  return (
    <div
      id="kt_AddEditStorage_panel"
      className="offcanvas offcanvas-right pt-5 pb-10"
    >
      {/*begin::Header*/}
      <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
        <div
          className="offcanvas-close mt-n1 pr-5"
          style={{ position: "absolute", top: "15px", right: "10px" }}
        >
          <a
            href="/"
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            id="kt_AddEditStorage_panel_close"
          >
            <i className="ki ki-close icon-sm text-muted"></i>
          </a>
        </div>
      </div>
      {/*end::Header*/}
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <div className="offcanvas-content px-10 ">
          <div>
            <h5>Add/Edit Storage </h5>
          </div>
          <div
            className="fade pt-3 pr-5 mr-n5 scroll active show ps--active-y mt-5"
            id="kt_quick_panel_logs_kt_AddEditStorage_panel"
          >
            {/* <div
              id="calcumate-root"
              data-integration="vaultstaging.easystorage.com"
              data-integration-2=""
              data-ref="AQICAHhReAOexSsfBeJ/FMNVdjLFzyMEhuepXqwRfRaqckbrnAESlsoBmb9EsncZ0DZ0abC4AAAAgzCBgAYJKoZIhvcNAQcGoHMwcQIBADBsBgkqhkiG9w0BBwEwHgYJYIZIAWUDBAEuMBEEDHSkv4dRtUzuPMaHegIBEIA/OBbz8iH6BRRHFQQ6g1u5XYOHwc2+rQAV8cMIrnx9+91Af4Cnyo9MCQOLVC616BZ/jrs09iJPWAZ86AX117fd"
              data-int="fc40a500-d54e-47fa-a264-ccf58648d909"
            ></div> */}
            {/* <div
              id="calcumate-root"
              data-integration="vaultmigration.easystorage.com"
              data-integration-2=""
              data-ref="AQICAHhReAOexSsfBeJ/FMNVdjLFzyMEhuepXqwRfRaqckbrnAESlsoBmb9EsncZ0DZ0abC4AAAAgzCBgAYJKoZIhvcNAQcGoHMwcQIBADBsBgkqhkiG9w0BBwEwHgYJYIZIAWUDBAEuMBEEDHSkv4dRtUzuPMaHegIBEIA/OBbz8iH6BRRHFQQ6g1u5XYOHwc2+rQAV8cMIrnx9+91Af4Cnyo9MCQOLVC616BZ/jrs09iJPWAZ86AX117fd"
              data-int="4ed1b849-5cbc-4776-968d-da28b8ced006"
            ></div> */}
            <div
              id="calcumate-root"
              data-integration="vault.easystorage.com"
              data-integration-2=""
              data-ref="AQICAHhReAOexSsfBeJ/FMNVdjLFzyMEhuepXqwRfRaqckbrnAESlsoBmb9EsncZ0DZ0abC4AAAAgzCBgAYJKoZIhvcNAQcGoHMwcQIBADBsBgkqhkiG9w0BBwEwHgYJYIZIAWUDBAEuMBEEDHSkv4dRtUzuPMaHegIBEIA/OBbz8iH6BRRHFQQ6g1u5XYOHwc2+rQAV8cMIrnx9+91Af4Cnyo9MCQOLVC616BZ/jrs09iJPWAZ86AX117fd"
              data-int="6a4f5fe9-c197-4499-a166-8f60fb5cefaa"
            ></div>
          </div>
        </div>
        <div className="offcanvas-footer">
          <div className="offcanvas-content text-right ">
            <button
              type="reset"
              className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary font-weight-bolder font-size-sm "
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
