/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

import React from "react";
import { OrderNotes } from "../../Order/components/OrderNotes";

export function AddAttachment() {
  return (
    <div
      id="kt_addattachment_panel"
      className="offcanvas offcanvas-right pt-5 pb-10"
    >
      {/*begin::Header*/}
      <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
        <div
          className="offcanvas-close mt-n1 pr-5"
          style={{ position: "absolute", top: "15px", right: "10px" }}
        >
          <a
            href="#"
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            id="kt_addattachment_panel_close"
          >
            <i className="ki ki-close icon-sm text-muted"></i>
          </a>
        </div>
      </div>
      {/*end::Header*/}
      <div className="offcanvas-content px-10 ">
        <div>
          <h5>Add Attachment</h5>
        </div>
        <div className="scroll" id="kt_quick_panel_logs_kt_addattachment_panel">
          <div className="mt-10 mb-15 addattachement">
            <p className="form">
              <input type="text" id="path" />
              <label className="btn btn-primary add-photo-btn">
                Browse
                <span>
                  <input
                    type="file"
                    id="myfile"
                    name="myfile"
                    className="form-control"
                  />
                </span>
              </label>
            </p>
            <div className="clearfix"></div>
          </div>

          <OrderNotes />
        </div>
      </div>
      <div className="offcanvas-footer">
        <div className="offcanvas-content text-right ">
          <button className="btn btn-dark font-weight-bolder font-size-sm mr-5 ">
            Cancel
          </button>
          <button className="btn btn-primary font-weight-bolder font-size-sm ">
            Save
          </button>
        </div>
      </div>
    </div>
  );
}
