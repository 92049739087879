import { Card, CardBody } from "../../../../../_metronic/_partials/controls";
import React, { useState } from "react";

import { CountryFilter } from "./country-filter/Country";
import CountryTable from "./country-table/CountryTable";
import DeleteDialog from "../../../../../_metronic/_partials/widgets/alert/DeleteDialog";
import NewBreadCrumbs from "../../../../../_metronic/layout/components/subheader/components/NewBreadCrumbs";
import NotAuthorized from "../../../../../_metronic/_partials/widgets/NotAuthorized";
import SnackBarTool from "../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { deleteCountry } from "../../_redux/country/countryCrud";
import { fetchCountryList } from "../../_redux/country/countryAction";
import { initialFilter } from "./CountryUIHelpers";
import { useCountryUIContext } from "./CountryUIContext";
import { useDispatch } from "react-redux";

const CountryCard = ({ permission, init, intl }) => {
  const CountryUIContext = useCountryUIContext();
  const dispatch = useDispatch();
  //  This state controls the delete dialog
  const [dialog, setDialog] = useState(false);
  //  This state controls the snackbar
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });

  //  This function handles deleting countries
  const handleDelete = () => {
    //  Reset the snackbar
    setSnack({ call: false, type: "", message: "" });
    deleteCountry({ country_id: CountryUIContext.ids })
      .then((res) => {
        //  Display a success message in the snackbar
        setSnack({ call: true, type: "success", message: res.data.message });
        dispatch(fetchCountryList(initialFilter));

        //  Close the delete dialog
        setDialog(false);
        CountryUIContext.setIds([]);
      })
      .catch((error) => {
        //  Display an error message in the snackbar
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
        //  Close the delete dialog
        setDialog(false);
      });
  };
  return (
    <>
      {/* This div displays its children with a space between them */}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {/* This components shows breadcrumb */}
        <NewBreadCrumbs title={intl.formatMessage({ id: "COUNTRY" })} />
        {/* This div contains the delete and new country buttons */}
        <div>
          {permission?.setting_country?.delete ? (
            <button
              className="btn btn-primary mr-2"
              type="button"
              onClick={() => setDialog(true)}
              disabled={CountryUIContext.ids.length === 0}
            >
              Delete
            </button>
          ) : (
            ""
          )}
          {permission?.setting_country?.create ? (
            <button
              id={init[0] + "_toggle"}
              className="btn btn-primary "
              type="button"
            >
              New Country
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
      {/* This card contains the country filter and table */}
      <Card>
        <CardBody>
          {permission?.setting_country?.view ? (
            <>
              <CountryFilter />
              <CountryTable permission={permission} />
            </>
          ) : (
            <NotAuthorized />
          )}
        </CardBody>
      </Card>
      {/* This dialog allows the user to delete items */}
      <DeleteDialog
        dialog={dialog}
        setDialog={setDialog}
        handleDelete={handleDelete}
        title={"Country"}
      />
      {/* This snackbar displays error and success messages to the user */}
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </>
  );
};

export default CountryCard;
