import React, { useEffect, useMemo, useState } from "react";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import { deal, viewDeal } from "../_mocks/staticData";
import { shallowEqual, useSelector } from "react-redux";

import { Formik } from "formik";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { dealsFranchiseListing } from "../_redux/deals/dealsCrud";
import { isEqual } from "lodash";
import { useDealsUIContext } from "../DealsUIContext";
import { useIntl } from "react-intl";
import { CustomeSearch } from "../../../../_metronic/_partials/controls/CustomeSearch";

const prepareFilter = (queryParams, values) => {
  const { agent, view, dealStage, searchText, franchise } = values;
  const newQueryParams = { ...queryParams };

  const filter = {};
  filter.agent = agent !== "" ? agent : "";
  filter.dealStage = dealStage !== "" ? dealStage : "";
  filter.franchise = franchise !== "" ? franchise : "";
  filter.searchText = searchText !== "" ? searchText : "";
  filter.view = view !== "" ? view : "";

  newQueryParams.filter = filter;
  return newQueryParams;
};
export function DealsFilter({ check }) {
  const dealsUIContext = useDealsUIContext();
  const intl = useIntl();

  const [franchises, setFranchise] = useState([]);

  const { currentState } = useSelector(
    (state) => ({ currentState: state.contact.agent }),
    shallowEqual
  );

  const dealsUIProps = useMemo(() => {
    return {
      setQueryParams: dealsUIContext.setQueryParams,
      queryParams: dealsUIContext.queryParams,
    };
  }, [dealsUIContext]);

  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(dealsUIProps.queryParams, values);

    if (!isEqual(newQueryParams, dealsUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      dealsUIProps.setQueryParams(newQueryParams);
    }
  };

  const handleFranchiseList = () => {
    dealsFranchiseListing().then((res) => {
      setFranchise(res.data.data);
    });
  };

  useEffect(() => {
    handleFranchiseList();
  }, []);

  return (
    <>
      <Formik
        initialValues={{
          agent: "",
          dealStage: "",
          franchise: "",
          view: "",
          searchText: "",
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,

          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row d-flex justify-content-between ">
              <div className="col-lg-10">
                <div className="row">
                  <div className="col-lg-2">
                    <FormControl fullWidth>
                      <Select
                        name="view"
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                        className="form-control"
                        IconComponent={(props) => (
                          <i {...props}>
                            <KeyboardArrowDownIcon />
                          </i>
                        )}
                        inputProps={{ "aria-label": "Without label" }}
                        displayEmpty
                        onChange={(e) => {
                          setFieldValue("view", e.target.value);
                          handleSubmit();
                        }}
                        // value={""}
                        onBlur={handleBlur}
                        value={values.view}
                      >
                        <MenuItem value="">
                          {intl.formatMessage({ id: "View" })}
                        </MenuItem>
                        {viewDeal(intl).map((data, index) => (
                          <MenuItem key={index} value={data.value}>
                            {data.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  {check ? (
                    ""
                  ) : (
                    <div className="col-lg-3 order-flt">
                      <span className="mt-3">
                        {intl.formatMessage({ id: "Deal" })}&nbsp;
                        {intl.formatMessage({ id: "Stage" })}:&nbsp;{" "}
                      </span>
                      <Select
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                        className="form-control"
                        placeholder="Filter by Type"
                        name="dealStage"
                        onBlur={handleBlur}
                        displayEmpty
                        onChange={(e) => {
                          setFieldValue("dealStage", e.target.value);
                          handleSubmit();
                        }}
                        value={values.dealStage}
                        IconComponent={(props) => (
                          <i {...props}>
                            <KeyboardArrowDownIcon />
                          </i>
                        )}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem defaultChecked value="">
                          {intl.formatMessage({ id: "All" })}
                        </MenuItem>
                        {deal(intl).map((data, index) => (
                          <MenuItem key={index} value={data.id}>
                            {data.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  )}
                  <div className="col-lg-3 order-flt">
                    <span className="mt-3">
                      {intl.formatMessage({ id: "Franchise" })}:&nbsp;{" "}
                    </span>
                    <FormControl fullWidth>
                      <Select
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                        className="form-control"
                        onChange={(e) => {
                          setFieldValue("franchise", e.target.value);

                          handleSubmit();
                        }}
                        name="franchise"
                        onBlur={handleBlur}
                        value={values.franchise}
                        displayEmpty
                        IconComponent={(props) => (
                          <i {...props}>
                            <KeyboardArrowDownIcon />
                          </i>
                        )}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem value="">
                          {intl.formatMessage({ id: "All" })}{" "}
                          {intl.formatMessage({ id: "Franchise" })}
                        </MenuItem>
                        {franchises.map((data, index) => (
                          <MenuItem key={index} value={data.id}>
                            {data.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-lg-3 order-flt mb-3">
                    <span className="mt-3">
                      {intl.formatMessage({ id: "Agent" })}:&nbsp;{" "}
                    </span>
                    <FormControl fullWidth>
                      <Select
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                        className="form-control"
                        onChange={(e) => {
                          setFieldValue("agent", e.target.value);
                          handleSubmit();
                        }}
                        name="agent"
                        onBlur={handleBlur}
                        value={values.agent}
                        displayEmpty
                        IconComponent={(props) => (
                          <i {...props}>
                            <KeyboardArrowDownIcon />
                          </i>
                        )}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem value="">
                          {intl.formatMessage({ id: "All" })}
                        </MenuItem>
                        {currentState &&
                          currentState.map((data, index) => (
                            <MenuItem key={index} value={data.id}>
                              {data.first_name +
                                " " +
                                (data.last_name ? data.last_name : "")}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
              <div className="col-lg-2">
                {/* <span className="d-none d-sm-none d-md-none d-lg-block">
                  &nbsp;{" "}
                </span> */}
                <CustomeSearch
                  intl={intl}
                  handleBlur={handleBlur}
                  values={values}
                  setFieldValue={setFieldValue}
                  handleSubmit={handleSubmit}
                />
                {/* <div style={{ position: "relative" }}>
                  <input
                    style={{ paddingRight: "40px" }}
                    type="text"
                    className="form-control"
                    name="searchText"
                    placeholder={intl.formatMessage({ id: "Search" })}
                    onBlur={handleBlur}
                    value={values.searchText}
                    onChange={(e) => {
                      setFieldValue("searchText", e.target.value);
                      handleSubmit();
                    }}
                  />
                  <i
                    style={{
                      position: "absolute",
                      top: "50%",
                      transform: "translateY(-50%)",
                      right: "15px",
                    }}
                    className="fa fa-search"
                    aria-hidden="true"
                  ></i>
                </div> */}
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
