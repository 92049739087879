import * as requestFromServer from "./QTasksCrud";
import { qTasksSlice, callTypes } from "./QTasksSlice";

const { actions } = qTasksSlice;

// Action Creator: fetches a list of tasks from the server
export const fetchQTasks = (queryParams) => (dispatch) => {
  // Dispatch action to indicate an API call has started
  dispatch(actions.startCall({ callType: callTypes.list }));
  // Dispatch action to update the task list with loading state
  // dispatch(actions.findQTasks({ totalCount: 0, entities: [], loading: true }));
  // Make a GET request to the server to fetch tasks
  dispatch(
    actions.tasksQFetched({ totalCount: 0, entities: [], loading: true })
  );

  return requestFromServer
    .findQTasks(queryParams)
    .then((response) => {
      // Extract meta data and task list from server response
      const { meta, data } = response.data;

      // Dispatch action to update the task list with the fetched data
      dispatch(
        actions.tasksQFetched({
          totalCount: meta.total,
          entities: data,
          loading: false,
        })
      );
    })
    .catch((error) => {
      // Add a client message to the error object
      error.clientMessage = "Can't find products";
      // Dispatch action to handle the error
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
