import { FormControl, MenuItem, Select } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";

import { Formik } from "formik";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { isEqual } from "lodash";
import { useIntl } from "react-intl";
import { useUserUIContext } from "../UserUIContext";
import { CustomeSearch } from "../../../../../../_metronic/_partials/controls/CustomeSearch";
import { dealsFranchiseListing } from "../../../../ContactDeals/_redux/deals/dealsCrud";

const prepareFilter = (queryParams, values) => {
  const { searchText, deal, franchise, status, search_franchise } = values;
  const newQueryParams = { ...queryParams };

  const filter = {};
  // Filter by status

  // Filter by condition
  filter.search_keyword = searchText !== "" ? searchText : "";
  filter.dealStage = deal !== "" ? deal : undefined;
  filter.franchise = franchise !== "" ? franchise : undefined;
  filter.status = status !== "" ? status : "";
  filter.search_franchise = search_franchise ? search_franchise : "";

  if (searchText) {
    filter.email = searchText;
  }
  newQueryParams.filter = filter;
  return newQueryParams;
};
export function UserFilter() {
  const intl = useIntl();
  const [franchises, setFranchise] = useState([]);

  const userUIContext = useUserUIContext();

  const userUIProps = useMemo(() => {
    return {
      setQueryParams: userUIContext.setQueryParams,
      queryParams: userUIContext.queryParams,
    };
  }, [userUIContext]);

  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(userUIProps.queryParams, values);

    if (!isEqual(newQueryParams, userUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      userUIProps.setQueryParams(newQueryParams);
    }
  };
  const handleFranchiseList = () => {
    dealsFranchiseListing().then((res) => {
      setFranchise(res.data.data);
    });
  };
  useEffect(() => {
    const timeout = setTimeout(() => {
      handleFranchiseList();
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <>
      <Formik
        initialValues={{
          status: "", // values => All=""/Susspended=0/Active=1/Pending=2
          type: "", // values => All=""/Business=0/Individual=1
          searchText: "",
          search_franchise: "",
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,

          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row d-flex justify-content-between">
              <div className="col-lg-10 mb-3">
                <div className="row">
                  <div className="col-lg-4">
                    <FormControl fullWidth>
                      <Select
                        name="status"
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                        className="form-control"
                        IconComponent={(props) => (
                          <i {...props}>
                            <KeyboardArrowDownIcon />
                          </i>
                        )}
                        inputProps={{ "aria-label": "Without label" }}
                        displayEmpty
                        onChange={(e) => {
                          setFieldValue("status", e.target.value);
                          handleSubmit();
                        }}
                        onBlur={handleBlur}
                        value={values.status}
                      >
                        <MenuItem value="">
                          {intl.formatMessage({ id: "All.User" })}
                        </MenuItem>
                        <MenuItem value="1">
                          {intl.formatMessage({ id: "ACTIVE" })}
                        </MenuItem>

                        <MenuItem value="0">
                          {intl.formatMessage({ id: "INACTIVE" })}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-lg-4  order-flt   ">
                    <span className="mt-3">
                      {intl.formatMessage({ id: "Franchise" })}:&nbsp;{" "}
                    </span>
                    <FormControl fullWidth>
                      <Select
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                        className="form-control"
                        onChange={(e) => {
                          setFieldValue("search_franchise", e.target.value);

                          handleSubmit();
                        }}
                        onBlur={handleBlur}
                        value={values.search_franchise}
                        displayEmpty
                        IconComponent={(props) => (
                          <i {...props}>
                            <KeyboardArrowDownIcon />
                          </i>
                        )}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem value="">All franchises</MenuItem>
                        {franchises.map((data, index) => (
                          <MenuItem key={index} value={data.id}>
                            {data.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
              <div className="col-lg-2">
                <CustomeSearch
                  intl={intl}
                  handleBlur={handleBlur}
                  values={values}
                  setFieldValue={setFieldValue}
                  handleSubmit={handleSubmit}
                />
                {/* <div style={{ position: "relative" }}>
                  <input
                    style={{ paddingRight: "40px" }}
                    type="text"
                    className="form-control"
                    name="searchText"
                    placeholder={intl.formatMessage({ id: "Search" })}
                    onBlur={handleBlur}
                    value={values.searchText}
                    onChange={(e) => {
                      setFieldValue("searchText", e.target.value);
                      handleSubmit();
                    }}
                  />
                  <i
                    style={{
                      position: "absolute",
                      top: "50%",
                      transform: "translateY(-50%)",
                      right: "15px",
                    }}
                    className="fa fa-search"
                    aria-hidden="true"
                  ></i>
                </div> */}
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
