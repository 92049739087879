import React from "react";
import { useIntl } from "react-intl";

const FormCheckbox = ({ handleCheck, fieldName, name }) => {
  const intl = useIntl();
  return (
    <>
      <th scope="row">
        <div>
          <label className="checkbox">
            <input
              type="checkbox"
              name="Checkboxes4"
              checked={fieldName?.create}
              onChange={(e) => handleCheck(e, name, "create")}
            />
            <span className="mr-2"></span>
            {intl.formatMessage({ id: "Create" })}
          </label>
        </div>
      </th>
      <th scope="row">
        <div>
          <label className="checkbox">
            <input
              type="checkbox"
              name="Checkboxes4"
              checked={fieldName?.view}
              disabled={
                fieldName?.create || fieldName?.edit || fieldName?.delete
              }
              onChange={(e) => handleCheck(e, name, "view")}
            />
            <span className="mr-2"></span>
            {intl.formatMessage({ id: "View" })}
          </label>
        </div>
      </th>
      <th scope="row">
        <div>
          <label className="checkbox">
            <input
              type="checkbox"
              name="Checkboxes4"
              checked={fieldName?.edit}
              onChange={(e) => handleCheck(e, name, "edit")}
            />
            <span className="mr-2"></span>
            {intl.formatMessage({ id: "Edit" })}
          </label>
        </div>
      </th>
      <th scope="row">
        <div>
          <label className="checkbox">
            <input
              type="checkbox"
              name="Checkboxes4"
              checked={fieldName?.delete}
              onChange={(e) => handleCheck(e, name, "delete")}
            />
            <span className="mr-2"></span>
            {intl.formatMessage({ id: "Delete" })}
          </label>
        </div>
      </th>
    </>
  );
};

export default FormCheckbox;
