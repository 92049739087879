import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { NewPromotionsCanva } from "./promotions-canva/NewPromotions";
import PromotionsCard from "./PromotionsCard";
import { PromotionsUIProvider } from "./PromotionsUIContext";
import { fetchPermission } from "../../../../pages/redux/permission";
import { initIds } from "../../../../../_metronic/_partials/controls/RightPanel";

export const PromotionsPage = () => {
  const dispatch = useDispatch();
  const init = ["kt_new_promotions_panel"];

  useEffect(() => {
    initIds(init);
  }, [init]);

  const { permission } = useSelector(
    (state) => ({
      permission: state.auth.permission,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(fetchPermission());
  }, [dispatch]);

  return (
    <>
      <PromotionsUIProvider>
        <PromotionsCard permission={permission} />
        <NewPromotionsCanva />
      </PromotionsUIProvider>
    </>
  );
};
