/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Table } from "react-bootstrap";
// import { canvaCancel } from "../../../utils/canvaCloseFunction";
import { recurring_check } from "../_redux/order/orderCrud";
import { useIntl } from "react-intl";
import { useEffect } from "react";
import moment from "moment";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { convertGBPtoPoundSymbol } from "../../../utils/commonFunction";
import { capitalizeFirstLetter } from "../../../utils/canvaCloseFunction";

export function OrderRecurring({ dialogRecur, setDialogRecur }) {
  const intl = useIntl();
  const [recurringList, setRecurringList] = useState({});

  const handleCloseCanva = () => {
    setDialogRecur({ dialog: false });
  };

  const initialCall = () => {
    recurring_check(dialogRecur?.orderId)
      .then((res) => {
        setRecurringList(res.data);
      })
      .catch((error) => {
        console.log("err on recurring", error);
      });
  };

  useEffect(() => {
    if (dialogRecur?.dialog) {
      initialCall();
    }
  }, [dialogRecur?.dialog]);
  const tablelist = ["mavis", "recurring", "return", "update"];

  const printUrl = (type, item) => {
    const urlMap = {
      mavis: `${process.env.REACT_APP_SITE_URL + "v1-invoices/tablet/"}${
        item?.id
      }`,
      recurring: `${process.env.REACT_APP_SITE_URL + "v1-invoices/recurring/"}${
        item?.id
      }`,
      // return: `${process.env.REACT_APP_SITE_URL}/pret/${item?.id}`,
      return: `${process.env.REACT_APP_SITE_URL + "v1-invoices/return/"}${
        item?.id
      }`,

      update: `${process.env.REACT_APP_SITE_URL + "v1-invoices/additional/"}${
        item?.invoice_ref_no
      }`,
    };

    return urlMap[type] || "";
  };
  const valueCheck = (type, item) => {
    if (type === "return") {
      return item?.total_amount ? Number(item?.total_amount).toFixed(2) : "-";
    } else {
      return item?.amount ? Number(item?.amount).toFixed(2) : "-";
    }
  };
  return (
    <div
      id="kt_OrderRec_panel"
      className={`offcanvas offcanvas-right pt-5 pb-10 offcanvas-on `}
    >
      {/*begin::Header*/}
      <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
        <div
          className="offcanvas-close mt-n1 pr-5"
          style={{ position: "absolute", top: "15px", right: "10px" }}
        >
          <span
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            onClick={() => handleCloseCanva()}
          >
            <i className="ki ki-close icon-sm text-muted"></i>
          </span>
        </div>
        <div id="kt_OrderRec_panel_close"></div>
      </div>
      {/*end::Header*/}
      <div className=" offcanvas-content tableover px-10  ">
        <div>
          <div>
            <h5>Initial Invoice</h5>
          </div>
          <div className="tab-content">
            <div
              id="kt_quick_panel_logs_kt_OrderRec_panel"
              className="tab-pane fade pt-3 pr-5 mr-n5 custom-scroll  active show"
            >
              <div>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Invoice Ref. No. </th>
                      <th>{intl.formatMessage({ id: "Type" })}</th>
                      <th>{"Created At"}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr key={dialogRecur?.bookRef}>
                      <td>{dialogRecur?.bookRef}</td>
                      <td>Initial</td>
                      <td>
                        {dialogRecur?.ordCreatedAt
                          ? moment(dialogRecur?.ordCreatedAt).format(
                              "DD-MM-yyyy"
                            )
                          : ""}
                      </td>
                      <td>
                        <a
                          href={
                            `${process.env.REACT_APP_SITE_URL +
                              "v1-invoices/initial"}` +
                            "?order_id=" +
                            dialogRecur?.orderId
                          }
                        >
                          <span className="symbol-label ml-2 pointer">
                            <span className="svg-icon svg-icon-sm svg-icon-primary">
                              <SVG
                                title=" "
                                className="h-25 align-self-end"
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Files/Download.svg"
                                )}
                              ></SVG>
                            </span>
                          </span>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              {tablelist.map((value) => (
                <>
                  {recurringList &&
                    recurringList[value] &&
                    recurringList[value]?.length > 0 && (
                      <div className="mt-8">
                        <div>
                          <h5>{capitalizeFirstLetter(value)} Invoices</h5>
                        </div>

                        <div style={{ height: "250px" }}>
                          <Table responsive>
                            <thead>
                              <tr>
                                <th>Invoice Ref. No. </th>
                                <th>{intl.formatMessage({ id: "Type" })}</th>
                                <th>{intl.formatMessage({ id: "Amount" })}</th>
                                <th>{"Created At"}</th>

                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {recurringList[value] &&
                              recurringList[value]?.length > 0 ? (
                                recurringList[value].map((item, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>
                                        {value === "return"
                                          ? item?.orderpartial
                                              ?.booking_reference
                                          : item?.invoice_ref_no}
                                      </td>
                                      <td>{capitalizeFirstLetter(value)}</td>
                                      <td>
                                        {convertGBPtoPoundSymbol("GBP")}
                                        {valueCheck(value, item)}
                                      </td>
                                      <td>
                                        {item?.created_at
                                          ? moment(item?.created_at).format(
                                              "DD-MM-yyyy"
                                            )
                                          : "-"}
                                      </td>

                                      <td>
                                        <a href={printUrl(value, item)}>
                                          <span className="symbol-label ml-2 pointer">
                                            <span className="svg-icon svg-icon-sm svg-icon-primary">
                                              <SVG
                                                title=" "
                                                className="h-25 align-self-end"
                                                src={toAbsoluteUrl(
                                                  "/media/svg/icons/Files/Download.svg"
                                                )}
                                              ></SVG>
                                            </span>
                                          </span>
                                        </a>
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td colSpan={5} align="center">
                                    No Record Found
                                  </td>{" "}
                                </tr>
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    )}
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="offcanvas-footer">
        <div className="offcanvas-content text-right ">
          <button
            className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
            onClick={() => handleCloseCanva()}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}
