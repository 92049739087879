import React, { useMemo } from "react";
import objectPath from "object-path";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { Topbar } from "./Topbar";
//import {HeaderMenuWrapper} from "./header-menu/HeaderMenuWrapper";
import { AnimateLoading } from "../../../_partials/controls";
import { useSubheader } from "../../_core/MetronicSubheader";
// import { initIds } from "../../../_partials/controls/RightPanel";
import { ChangePasswordCanvas } from "../extras/offcanvas/ChangePassword";
export function Header() {
  const uiService = useHtmlClassService();
  const subheader = useSubheader();

  const layoutProps = useMemo(() => {
    return {
      headerClasses: uiService.getClasses("header", true),
      headerAttributes: uiService.getAttributes("header"),
      headerContainerClasses: uiService.getClasses("header_container", true),
      menuHeaderDisplay: objectPath.get(
        uiService.config,
        "header.menu.self.display"
      ),
    };
  }, [uiService]);

  return (
    <>
      {/*begin::Header*/}
      <div
        className={`header ${layoutProps.headerClasses}`}
        id="kt_header"
        {...layoutProps.headerAttributes}
      >
        {/*begin::Container*/}
        <div
          className={` ${layoutProps.headerContainerClasses} d-flex align-items-center justify-content-between`}
        >
          <AnimateLoading />
          {/*begin::Header Menu Wrapper*/}
          {/*{layoutProps.menuHeaderDisplay && <HeaderMenuWrapper />}
          {!layoutProps.menuHeaderDisplay && <div />}*/}
          {/*end::Header Menu Wrapper*/}

          {/*begin::Page Title*/}

          <h5 className="text-dark font-weight-bold my-2 mr-5">
            <>{subheader.title}</>
            {/*<small></small>*/}
          </h5>
          {/*begin::Page Title*/}
          {/*begin::Topbar*/}
          <Topbar />
          {/*end::Topbar*/}
        </div>
        {/*end::Container*/}
      </div>
      {/*end::Header*/}
      <ChangePasswordCanvas />
    </>
  );
}
