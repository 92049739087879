// / eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef /

import React, { useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { fetchVersion } from "../_redux/order/orderAction";
import GetAppIcon from "@material-ui/icons/GetApp";

export function DamageWaiverCanva({ setOpenDamageCanva, orderDetail }) {
  const dispatch = useDispatch();
  const { damageDetails } = useSelector(
    (state) => ({
      damageDetails: state.order.versionDetail.damageDetails,
    }),
    shallowEqual
  );
  useEffect(() => {
    if (orderDetail.id) {
      dispatch(fetchVersion(orderDetail.franchise_id, orderDetail.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, orderDetail.franchise_id, orderDetail.id]);
  // https://s3-ap-southeast-1.amazonaws.com/tksproduction/bmtimages/pY3BnhPQYpTxasKfx.jpeg
  const fetchImage = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();

    return blob;
  };
  const imageDownloader = async (url) => {
    const imageBlob = await fetchImage(url);
    const imageBase64 = URL.createObjectURL(imageBlob);

    const a = document.createElement("a");
    a.style.setProperty("display", "none");
    document.body.appendChild(a);
    // eslint-disable-next-line no-useless-escape
    a.download = url.replace(/^.*[\\\/]/, "");
    a.href = imageBase64;
    a.click();
    a.remove();
  };

  return (
    <div className="offcanvas offcanvas-right pt-5 pb-10 offcanvas-on">
      {/*begin::Header*/}
      <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
        <div
          className="offcanvas-close mt-n1 pr-5"
          style={{ position: "absolute", top: "15px", right: "10px" }}
        >
          <button
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            onClick={() => setOpenDamageCanva(false)}
          >
            <i className="ki ki-close icon-sm text-muted"></i>
          </button>
        </div>
      </div>
      {/*end::Header*/}
      <div className="offcanvas-content px-10 ">
        <div>
          <h5>Damage / Waiver Report </h5>
        </div>
        <div className="fade pt-3 pr-5 mr-n5 scroll active show ps--active-y mt-5 custom-scroll">
          {damageDetails && damageDetails.length > -1
            ? damageDetails.map((item, index) => (
                <div
                  className="card card-custom gutter-b customer-duration "
                  key={index}
                >
                  <div className="card-body">
                    <div className="d-flex mt-2">
                      <span className=" ">Pod Number:&nbsp;</span>
                      <span className="">{item?.pod_number || "-"}</span>
                    </div>
                    <div className="d-flex mt-2">
                      <span className=" ">Damage Type:&nbsp;</span>
                      <span className="">{item?.damage_type || "-"}</span>
                    </div>
                    <div className="d-flex mt-2">
                      <span className=" ">Acknowledgement:&nbsp;</span>
                      <span className="">{item?.acknowledgement || "-"}</span>
                    </div>
                    <div className="d-flex mt-2">
                      <span className=" ">
                        Comment&nbsp;About&nbsp;Damage/Waiver:&nbsp;
                      </span>
                      <span className="">{item?.comment || "-"}</span>
                    </div>
                    <div className="mt-2">
                      <span className=" ">Item Images :&nbsp;</span>
                      <div className="row">
                        {item?.images && item?.images.length > -1
                          ? item?.images?.map((value, index) => (
                              <div className="col-md-3 mt-2">
                                <div className="cardDamage">
                                  <div
                                    class="overlay py-9"
                                    onClick={() => imageDownloader(value.image)}
                                  >
                                    <GetAppIcon />
                                  </div>
                                  <img
                                    src={value.image}
                                    alt="damage_image"
                                    className="damageImg mr-2"
                                  />
                                </div>
                              </div>
                            ))
                          : ""}
                      </div>
                    </div>
                    <div className=" mt-2">
                      <span className=" ">Signature:&nbsp;</span>
                      <div className="cardDamage w-15" style={{ width: "15%" }}>
                        <div
                          class="overlay py-16"
                          onClick={() => imageDownloader(item?.signature_image)}
                        >
                          <GetAppIcon />
                        </div>
                        <img
                          src={item?.signature_image}
                          alt="signature_image"
                        />
                      </div>
                      <div className="d-flex">
                        <span className=" ">Name:&nbsp;</span>
                        <span>{item?.name}</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : ""}
        </div>
      </div>
      <div className="offcanvas-footer">
        <div className="offcanvas-content text-right ">
          <button
            type="button"
            onClick={() => setOpenDamageCanva(false)}
            className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}
