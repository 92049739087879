// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html

import * as actions from "../_redux/deals/dealsAction";
import * as columnFormatters from "./column-formatters";
import * as uiHelpers from "../DealsUIHelpers";

import React, { useEffect, useMemo, useState } from "react";
import {
  getHandlerTableChange,
  getSelectRow,
  sortCaret,
} from "../../../../_metronic/_helpers";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import BootstrapTable from "react-bootstrap-table-next";
import DealsGrid from "../dealsView/DealsGrid";
import NoRecord from "../../../../_metronic/_partials/widgets/NoRecord";
import { Pagination } from "../../../../_metronic/_partials/controls";
import { fetchAgent } from "../_redux/contact/contactAction";
import moment from "moment";
import { useDealsUIContext } from "../DealsUIContext";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";

const DealsTable = ({ checkbox, dealsPage, dealList, type, check, isDeal, hat }) => {
  let history = useHistory();
  const dealsUIContext = useDealsUIContext();

  const dealsUIProps = useMemo(() => {
    return {
      ids: dealsUIContext?.ids,
      setIds: dealsUIContext?.setIds,
      queryParams: dealsUIContext?.queryParams,
      setQueryParams: dealsUIContext?.setQueryParams,
      openEditContactPage: dealsUIContext?.openEditContactPage,
      openDeleteContactDialog: dealsUIContext?.openDeleteContactDialog,
    };
  }, [dealsUIContext]);

  const [apiCAll, SetApiCall] = useState(false);

  const dispatch = useDispatch();

  const { currentState } = useSelector(
    (state) => ({
      currentState: state.deals_list.list,
      permission: state.auth.permission,
    }),
    shallowEqual
  );

  const { totalCount, entities, loading } = currentState;

  useEffect(() => {
    dealsUIProps.setIds([]);

    dispatch(fetchAgent());
    if (type === "contact" || type === "Deal") return;
    dispatch(actions.fetchDeals(dealsUIProps.queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealsUIProps.queryParams, dispatch]);

  useEffect(() => {
    if (check?.check) {
      dispatch(
        actions.fetchDealsGrid(
          apiCAll ? dealsUIProps.queryParams : uiHelpers.initialFilter
        )
      );
      SetApiCall(true);
    } else if (check?.check === false && apiCAll) {
      dispatch(actions.fetchDeals(dealsUIProps.queryParams));
    }
  }, [apiCAll, check, dealsUIProps.queryParams, dispatch]);

  useEffect(() => {
    dealsUIProps.setQueryParams(uiHelpers.initialFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const intl = useIntl();
  const columns = [
    {
      dataField: "users.first_name",
      text:
        intl.formatMessage({ id: "Deal" }) +
        " " +
        intl.formatMessage({ id: "Name" }),
      sort: true,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return (
          <span>
            {data.user_data.first_name}&nbsp;{data.user_data.last_name}
          </span>
        );
      },
    },

    {
      dataField: "deal_status",
      text:
        intl.formatMessage({ id: "Deal" }) +
        " " +
        intl.formatMessage({ id: "Stage" }),
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "closed_at",
      text: intl.formatMessage({ id: "Closing.Date" }),
      sort: true,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return <span>{e ? moment(e).format("DD-MM-YYYY") : " - "}</span>;
      },
    },
    {
      dataField: "franchises.name",
      text: intl.formatMessage({ id: "Franchise" }),
      sort: true,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return (
          <span>
            {data && data.franchise_data && data.franchise_data.name
              ? data.franchise_data.name
              : " - "}
          </span>
        );
      },
    },
    {
      dataField: "agent.first_name",
      text: intl.formatMessage({ id: "Agent" }),
      sort: true,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return (
          <span>
            {data && data.agent_data && data.agent_data.first_name
              ? data.agent_data.first_name
              : " - "}
          </span>
        );
      },
    },
    {
      dataField: "amount",
      text: intl.formatMessage({ id: "Amount" }),
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.PriceColumnFormatter,
    },
    {
      dataField: "created_at",
      text: intl.formatMessage({ id: "Create.Date" }),
      sort: true,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return <span>{moment(e).format("DD-MM-YYYY")}</span>;
      },
    },
  ];

  const columnsDeals = [
    {
      dataField: "id",
      text: "Deal ID",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "user_data",
      text:
        intl.formatMessage({ id: "Deal" }) +
        " " +
        intl.formatMessage({ id: "Name" }),
      sort: true,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return (
          <span>
            {e.first_name}&nbsp;{e.last_name}
          </span>
        );
      },
    },

    {
      dataField: "deal_status",
      text:
        intl.formatMessage({ id: "Deal" }) +
        " " +
        intl.formatMessage({ id: "Stage" }),
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "closed_at",
      text: "Closing Date",
      sort: true,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return <span>{e ? moment(e).format("DD-MM-YYYY") : "-"}</span>;
      },
    },
    {
      dataField: "franchise_data",
      text: intl.formatMessage({ id: "Franchise" }),
      sort: true,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return <span>{e?.name}</span>;
      },
    },
    {
      dataField: "agent_data",
      text: intl.formatMessage({ id: "Agent" }),
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return (
          <span>
            {e?.first_name || "-"}&nbsp;{e?.last_name || ""}
          </span>
        );
      },
    },
    {
      dataField: "amount",
      text: intl.formatMessage({ id: "Amount" }),
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.PriceColumnFormatter,
    },
    {
      dataField: "created_at",
      text: intl.formatMessage({ id: "Create.Date" }),
      sort: true,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return <span>{moment(e).format("DD-MM-YYYY")}</span>;
      },
    },
  ];
  const columnsQuotes = [
    {
      dataField: "deal_id",
      text: "Deal ID",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "id",
      text: "Quote ID",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "deal_name",
      text:
        intl.formatMessage({ id: "Deal" }) +
        " " +
        intl.formatMessage({ id: "Name" }),
      sort: false,
      sortCaret: sortCaret,
    },
    {
      dataField: "franchises.name",
      text: intl.formatMessage({ id: "Franchise" }),
      sort: true,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return <span>{data?.franchise_name}</span>;
      },
    },
    {
      dataField: "users.first_name",
      text: intl.formatMessage({ id: "Agent" }),
      sort: true,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return <span>{data?.agent_name}</span>;
      },
    },
    {
      dataField: "source",
      text: intl.formatMessage({ id: "Source" }),
      sort: true,
      sortCaret: sortCaret,
      // formatter: columnFormatters.PriceColumnFormatter,
    },
    {
      dataField: "amount",
      text: intl.formatMessage({ id: "Amount" }),
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.PriceColumnFormatter,
    },
    {
      dataField: "created_at",
      text: intl.formatMessage({ id: "Create.Date" }),
      sort: true,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return <span>{moment(e).format("DD-MM-YYYY")}</span>;
      },
    },
  ];

  const paginationOptions = {
    custom: true,
    totalSize:
      type === "contact" || type === "Deal" ? dealList.totalCount : totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: dealsUIProps.queryParams?.pageSize,
    page: dealsUIProps.queryParams?.pageNumber,
  };
  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      history.push(`/deal-view/${row.id}`);
    },
  };
  const rowEventsQuote = {
    onClick: (e, row, rowIndex) => {
      // if(hat === "quoteContact"){
      //   console.log("row-123", row.quote_id);
      //   history.push(`/new-order?quote`, {
      //     franchise_id: row.franchise_id,
      //     quote: +row.quote_id,
      //   });
      // }
      if (type === "contact") {
        history.push(`/deal-view/${row.id}`);
        return;
      }
      history.push(`/new-order-stripe?quote`, {
        franchise_id: row.franchise_id,
        quote: row.quote_data?.id||row.quote_id||row.id,
      });

      if (isDeal === "modal") history.go(0);
    },
  };
  return (
    <>
      {!check?.check ? (
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => {
            return (
              <>
                <Pagination
                  isLoading={loading}
                  paginationProps={paginationProps}
                  entities={checkbox ? dealList && dealList.entities : entities}
                >
                  {checkbox ? (
                    <>
                      <BootstrapTable
                        wrapperClasses="table-responsive"
                        bordered={false}
                        rowEvents={rowEventsQuote}
                        hover
                        classes="table table-head-custom table-vertical-center overflow-hidden"
                        bootstrap4
                        remote
                        keyField="id"
                        defaultSorted={uiHelpers.defaultSorted}
                        data={
                          type === "contact" || type === "Deal"
                            ? dealList.entities === null
                              ? []
                              : dealList.entities
                            : entities === null
                            ? []
                            : entities
                        }
                        onTableChange={getHandlerTableChange(
                          dealsUIProps.setQueryParams
                        )}
                        columns={
                          type === "contact"
                            ? columnsDeals
                            : type === "Deal"
                            ? columnsQuotes
                            : columns
                        }
                        {...paginationTableProps}
                      ></BootstrapTable>{" "}
                      {dealList && dealList.entities && dealList.entities.length
                        ? ""
                        : ""
                          // <NoRecord />
                      }
                    </>
                  ) : (
                    <BootstrapTable
                      wrapperClasses="table-responsive"
                      bordered={false}
                      rowEvents={rowEvents}
                      classes="table table-head-custom table-vertical-center overflow-hidden"
                      bootstrap4
                      remote
                      hover
                      keyField="id"
                      selectRow={getSelectRow({
                        entities,
                        ids: dealsUIProps.ids,
                        setIds: dealsUIProps.setIds,
                      })}
                      defaultSorted={uiHelpers.defaultSorted}
                      data={entities === null ? [] : entities}
                      onTableChange={getHandlerTableChange(
                        dealsUIProps.setQueryParams
                      )}
                      columns={columns}
                      {...paginationTableProps}
                    ></BootstrapTable>
                  )}

                  {(entities && entities?.length) ||
                  loading ||
                  (dealList &&
                    dealList.entities &&
                    dealList.entities.length) ? (
                    ""
                  ) : (
                    <NoRecord />
                  )}
                </Pagination>
              </>
            );
          }}
        </PaginationProvider>
      ) : (
        <DealsGrid entities={entities} />
      )}
    </>
  );
};

export default DealsTable;
