import React from "react";
import moment from "moment";
import { useIntl } from "react-intl";

export function Notes({ data }) {
  const intl = useIntl();

  return (
    <>
      <div className="fieldset-area">
        <fieldset className="mb-5">
          <legend>
            {moment(data?.created_at).format("DD-MM-YYYY")} at{" "}
            {moment(data?.created_at).format("hh:mm")}
          </legend>
          <div className="p-5">
            <h5>
              {intl.formatMessage({ id: "Note.by" })}{" "}
              {data?.user.first_name + " " + data?.user.last_name}
            </h5>
            <p>{data?.note}</p>
          </div>
        </fieldset>
      </div>
    </>
  );
}
