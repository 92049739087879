import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { AssignDealsCanvas } from "../canvas/AssignDealCanvas";
import { DealsFilter } from "../Filter/DealsFilter";
import DealsList from "../dealsView/DealsList";
import DeleteDialog from "../../../../_metronic/_partials/widgets/alert/DeleteDialog";
import NotAuthorized from "../../../../_metronic/_partials/widgets/NotAuthorized";
import SVG from "react-inlinesvg";
import SnackBarTool from "../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { deleteDeal } from "../_redux/deals/dealsCrud";
import { fetchDeals } from "../_redux/deals/dealsAction";
import { fetchPermission } from "../../../pages/redux/permission";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { useDealsUIContext } from "../DealsUIContext";
import { useIntl } from "react-intl";
import { useSubheader } from "../../../../_metronic/layout";

export const DealsPage = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const dealUIContext = useDealsUIContext();

  const [check, setCheck] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });

  const suhbeader = useSubheader();
  suhbeader.setTitle(intl.formatMessage({ id: "DEALS" }));

  const handleCheck = (value) => {
    switch (value) {
      case "list":
        setCheck(false);
        break;
      case "card":
        setCheck(true);
        break;

      default:
        break;
    }
  };

  const { permission } = useSelector(
    (state) => ({
      permission: state.auth.permission,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(fetchPermission());
  }, [dispatch]);

  const handleDelete = () => {
    setSnack({ call: false, type: "", message: "" });
    deleteDeal({ deal_id: dealUIContext.ids })
      .then((res) => {
        setSnack({ call: true, type: "success", message: res.data.message });
        dealUIContext.setIds([]);
        dispatch(fetchDeals(dealUIContext.queryParams));
        // // resetForm();
        setDialog(false);
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
        setDialog(false);
      });
  };

  return (
    <>
      <div className="text-right mb-5">
        {permission?.deal?.delete ? (
          <button
            className="btn btn-primary mr-2"
            type="button"
            onClick={() => setDialog(true)}
            disabled={dealUIContext.ids.length === 0}
          >
            Delete
          </button>
        ) : (
          ""
        )}
        <button
          id="kt_deals_assign_panel_toggle"
          type="button"
          className="btn btn-primary mr-3"
          disabled={dealUIContext.ids.length > 0 ? false : true}
        >
          {intl.formatMessage({ id: "Assign.Selected" })}
        </button>
      </div>
      <Card>
        <div className="d-flex ml-6 mt-4">
          <div>
            <span
              className="symbol-label ml-2 pointer"
              onClick={() => handleCheck("list")}
            >
              <span className="svg-icon svg-icon-xl svg-icon-primary">
                <SVG
                  title=" "
                  className="h-25 align-self-end"
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Layout/Layout-left-panel-2.svg"
                  )}
                ></SVG>
              </span>
            </span>
          </div>
          <div>
            <span
              className="symbol-label ml-2 pointer"
              onClick={() => handleCheck("card")}
            >
              <span className="svg-icon svg-icon-xl svg-icon-primary">
                <SVG
                  title=" "
                  className="h-25 align-self-end"
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Layout/Layout-top-panel-1.svg"
                  )}
                ></SVG>
              </span>
            </span>
          </div>
        </div>
        <CardHeader></CardHeader>
        <CardBody>
          {permission?.deal?.view ? (
            <>
              <DealsFilter check={check} />
              <DealsList check={check} />
            </>
          ) : (
            <NotAuthorized />
          )}
        </CardBody>
      </Card>
      <AssignDealsCanvas />
      <DeleteDialog
        dialog={dialog}
        setDialog={setDialog}
        handleDelete={handleDelete}
        title={"Deal"}
      />
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </>
  );
};
