import { actions } from "../../modules/Auth/_redux/authRedux";
import * as requestFromServer from "./commonCrud";
export const fetchPermission = () => (dispatch) => {
  function display(x) {
    const obj = {};
    for (let item of x) {
      const split = item.split("_");
      let key = "";
      for (let i = 0; i < split.length - 1; i++) {
        key += `${split[i]}_`;
      }

      obj[key.substring(0, key?.length - 1)] = {
        ...obj[key.substring(0, key?.length - 1)],
        [split[split.length - 1]]: true,
      };
    }
    return obj;
  }
  return requestFromServer
    .permissionFetch()
    .then(async (response) => {
      const { data } = response.data;
      const permission = await display(data.permissions);

      dispatch(actions.permission(permission));
      //   dispatch(actions.dashboardFeeds({ data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
    });
};
