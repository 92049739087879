import * as requestFromServer from "./promotionsCrud";
import { promotionsSlice, callTypes } from "./promotionsSlice";

const { actions } = promotionsSlice;

export const fetchPromotions = (queryParams, nextoffset) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  dispatch(
    actions.promotionsFetched({ totalCount: 0, entities: [], loading: true })
  );
  return requestFromServer
    .findPromotions(queryParams, nextoffset)
    .then((response) => {
      const { total, data, nextoffset } = response.data;
      dispatch(
        actions.promotionsFetched({
          totalCount: total,
          entities: data,
          nextoffset: nextoffset,
          loading: false,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchPromoDetail = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  dispatch(actions.promoDetails({ data: { loading: true } }));
  return requestFromServer
    .promoDetail(queryParams)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.promoDetails({ data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
