/* eslint-disable no-restricted-imports */
// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html

import * as actions from "../../../_redux/franchises/franchisesActions";
import * as uiHelpers from "../Logs component/LogsUIHelpers";

import React, { useEffect, useMemo } from "react";
import {
  getHandlerTableChange,
  getSelectRow,
  headerSortingClasses,
  sortCaret,
} from "../../../../../../_metronic/_helpers";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import BootstrapTable from "react-bootstrap-table-next";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
// import { useCountryUIContext } from "../CountryUIContext";
// import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { useLogsUIContext } from "./LogsUIContext";
import { useParams } from "react-router-dom";
import { Tooltip } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(() => ({
  tooltip: {
    fontSize: '1rem', // Increase the font size here
  },
}));

const LogsTable = ({ permission }) => {
  const intl = useIntl();
  const params = useParams();
  const classes = useStyles();
  // let history = useHistory();
  const LogsUIContext = useLogsUIContext();
  const LogsUIProps = useMemo(() => {
    return {
      ids: LogsUIContext?.ids,
      setIds: LogsUIContext?.setIds,
      queryParams: LogsUIContext?.queryParams,
      setQueryParams: LogsUIContext?.setQueryParams,
      openEditOrderPage: LogsUIContext?.openEditOrderPage,
      openDeleteOrderDialog: LogsUIContext?.openDeleteOrderDialog,
    };
  }, [LogsUIContext]);
  const dispatch = useDispatch();
  const { currentState } = useSelector(
    (state) => ({
      currentState: state.franchise.list,
      permission: state.auth.permission,
    }),
    shallowEqual
  );

  const { totalCount, entities } = currentState;
  useEffect(() => {
    // clear selections list
    // server call by queryParams
    LogsUIProps.setIds([]);
    dispatch(actions.fetchFranchisesLogs(LogsUIProps.queryParams, params.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [LogsUIProps.queryParams, dispatch]);

  const columns = [
    {
      dataField: "stripe_object",
      text: "Stripe Object",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "stripe_id",
      text: "Stripe ID",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (e, data) => {
        return (
          <a href={data?.stripe_link} >{e ? e : " - "}</a>
        );
      },
    },
    // {
    //   dataField: "stripe_link",
    //   text: "Stripe Link",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   formatter: (e, data) => {
    //     return (
    //       <a href={e} >{e ? e : " - "}</a>
    //     );
    //   },
    // },
    // {
    //   dataField: "quickbooks_link",
    //   text: "QuickBooks Link",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   formatter: (e, data) => {
    //     return <a href={e}>{e ? e : " - "}</a>;
    //   },
    // },
    {
      dataField: "last_attempt_sync",
      text: "Last Attempted Sync",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (e, data) => {
        return (
          <span
            className={`label label-lg label-light-${
              e === "Success"
                ? "success"
                : e === "Medium"
                ? "warning"
                : e === "Failed"
                ? "danger"
                : ""
            } label-inline`}
          >
            {e ? e : "-"}
          </span>
        );
      },
    },
    {
      dataField: "quickbooks_id",
      text: "QuickBooks ID",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (e, data) => {
        return <a href={data?.quickbooks_link}>{e ? e : " - "}</a>;
      },
    },
    {
      dataField: "details",
      text: "Details",
      sort: true,
      sortCaret: sortCaret,
      formatter: (e, data) => {
            return  <Tooltip title={e} placement="bottom"  classes={{ tooltip: classes.tooltip }}>
    <span>{e ? (e.length > 20 ? `${e.slice(0, 20)}...` : e) : " - "}</span>
          </Tooltip>
          },
    },
  ];

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: LogsUIProps.queryParams?.pageSize,
    page: LogsUIProps.queryParams?.pageNumber,
  };

  // const rowEvents = {
  //   onClick: (e, row, rowIndex) => {
  //     history.push(`country/country-details/${row.id}`);
  //   },
  // };

  // const selectRow = {
  //   mode: "checkbox",
  //   hideSelectAll: true,
  //   hideSelectColumn: true,
  // };

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={false}
              paginationProps={paginationProps}
              entities={entities}
            >
              <BootstrapTable
                // rowEvents={rowEvents}
                wrapperClasses="table-responsive"
                hover
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="id"
                selectRow={getSelectRow({
                  entities,
                  ids: LogsUIProps.ids,
                  setIds: LogsUIProps.setIds,
                })}
                defaultSorted={uiHelpers.defaultSorted}
                data={entities === null ? [] : entities}
                onTableChange={getHandlerTableChange(
                  LogsUIProps.setQueryParams
                )}
                columns={columns}
                {...paginationTableProps}
              ></BootstrapTable>

              {entities && entities?.length ? (
                ""
              ) : (
                <div style={{ textAlign: "center" }}>
                  <p>{intl.formatMessage({ id: "No.Record.Found" })}</p>
                </div>
              )}
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
};

export default LogsTable;
