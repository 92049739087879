import { Card, CardBody } from "../../../../../_metronic/_partials/controls";
import React, { useState } from "react";

import DeleteDialog from "../../../../../_metronic/_partials/widgets/alert/DeleteDialog";
import NewBreadCrumbs from "../../../../../_metronic/layout/components/subheader/components/NewBreadCrumbs";
import NotAuthorized from "../../../../../_metronic/_partials/widgets/NotAuthorized";
import { PromotionsFilter } from "./promotions-filter/Promotions";
import PromotionsTable from "./promotions-table/PromotionsTable";
import SnackBarTool from "../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { deletePromotion } from "../../_redux/promotions/promotionsCrud";
import { fetchPromotions } from "../../_redux/promotions/promotionsAction";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { usePromotionsUIContext } from "./PromotionsUIContext";

const PromotionsCard = ({ permission }) => {
  const PromotionsUIContext = usePromotionsUIContext();
  const intl = useIntl();
  const dispatch = useDispatch();

  const [dialog, setDialog] = useState(false);
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });

  const handleDelete = () => {
    setSnack({ call: false, type: "", message: "" });
    const formData = new FormData();
    PromotionsUIContext.ids.forEach((id, index)=>{
       formData.append(`promotion_id[${index}]`, id);
    })
    deletePromotion(formData)
      .then((res) => {
        setSnack({ call: true, type: "success", message: res.data.message });
        dispatch(fetchPromotions(PromotionsUIContext.queryParams));
        setDialog(false);
        PromotionsUIContext.setIds([]);
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
        setDialog(false);
      });
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <NewBreadCrumbs title={intl.formatMessage({ id: "PROMOTIONS" })} />
        <div>
          {permission?.setting_promotion?.delete ? (
            <button
              className="btn btn-primary mr-2"
              type="button"
              onClick={() => setDialog(true)}
              disabled={PromotionsUIContext.ids?.length === 0}
            >
              Delete
            </button>
          ) : (
            ""
          )}
          {permission?.setting_promotion?.create ? (
            <button
              className="btn btn-primary mr-2"
              type="button"
              id="kt_new_promotions_panel_toggle"
            >
              {intl.formatMessage({ id: "New.Promotion" })}
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
      <Card>
        <CardBody>
          {permission?.setting_promotion?.view ? (
            <>
              <PromotionsFilter />
              <PromotionsTable />
            </>
          ) : (
            <NotAuthorized />
          )}
        </CardBody>
      </Card>
      <DeleteDialog
        dialog={dialog}
        setDialog={setDialog}
        handleDelete={handleDelete}
        title={"Promotions"}
      />
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </>
  );
};

export default PromotionsCard;
