import React, { useEffect } from "react";

export const FranchiseFilterForm = ({
  intl,
  handleBlur,
  values,
  setFieldValue,
  handleSubmit,
}) => {
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      // Send Axios request here
      handleSubmit();
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.searchText]);
  return (
    <div className="form-group row d-flex" style={{ justifyContent: "right" }}>
      <div className="col-lg-2">
        <input
          type="text"
          style={{ paddingRight: "40px" }}
          className="form-control"
          name="searchText"
          placeholder={intl.formatMessage({ id: "Search" })}
          onBlur={handleBlur}
          value={values.searchText}
          onChange={(e) => {
            setFieldValue("searchText", e.target.value);
            // handleSubmit();
          }}
        />
        <i
          style={{
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            right: "25px",
          }}
          className="fa fa-search"
          aria-hidden="true"
        ></i>
      </div>
    </div>
  );
};
