/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */

import * as Yup from "yup";

import React, { useEffect, useState } from "react";

import { SMSWidget } from "../../../../../_metronic/_partials/widgets/mixed/SMSWidget";
import { SmsWidget } from "./sms/SmsWidget";
import SnackBarTool from "../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { smsSchema } from "../../form/validationSchema";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { useIntl } from "react-intl";

export function Sms({
  ID,
  smsList,
  submitFunction,
  listFunction,
  dealId,
  type,
  detailData,
  activeTab,
  permission,
}) {
  // Use the useIntl hook to access the intl object for translations
  const intl = useIntl();
  // Use the useDispatch hook to access the dispatch function
  const dispatch = useDispatch();

  // Use the useState hook to create a state variable for snackbar
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  // Use the useState hook to create a state variable for loading state
  const [loading, setLoading] = useState(false);

  // Create an object with initial values for the form
  const initialValues = {
    contact_id: ID ? ID : "",
    sms_to:
      detailData && detailData.user_data && detailData.user_data.phone
        ? detailData.user_data.phone
        : "",
    description: "",
  };

  // Create an object with initial values for the form for deals
  const initialDeals = {
    contact_id: ID ? ID : "",
    deal_id: dealId ? dealId : "",
    sms_to:
      detailData && detailData.user_data && detailData.user_data.phone
        ? detailData.user_data.phone
        : "",
    description: "",
  };

  // Handle form submission
  const handleSubmit = (values, { resetForm }) => {
    // Update snackbar state to not show
    setSnack({ call: false, type: "", message: "" });
    // Update loading state to true
    setLoading(true);
    // Call submitFunction, which should be passed as a prop
    submitFunction(values)
      .then((res) => {
        // Update snackbar state to show success message
        setSnack({ call: true, type: "success", message: res.data.message });
        // After 2 seconds, reset the form and dispatch the appropriate action
        setTimeout(() => {
          resetForm();
          dispatch(
            type === "Deal" ? listFunction(dealId, ID) : listFunction(ID)
          );
        }, 2000);
        // Update loading state to false
        setLoading(false);
      })
      .catch((error) => {
        // Update loading state to false
        setLoading(false);
        // Update snackbar state to show error message
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
  };

  const handleReset = () => {};

  // Use the useFormik hook to create a formik object
  const formik = useFormik({
    initialValues: type === "Deal" ? initialDeals : initialValues,
    enableReinitialize: true,
    validationSchema: smsSchema(intl, Yup),
    onSubmit: handleSubmit,
    onReset: handleReset,
  });

  useEffect(() => {
    if (activeTab === "sms") {
      formik.resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  return (
    <>
      <div>
        <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
          {permission ? (
            <SMSWidget
              formik={formik}
              loading={loading}
              detailData={detailData}
            />
          ) : (
            ""
          )}
        </form>
      </div>

      <div>
        {smsList && smsList?.length
          ? smsList.map((data, index) => (
              <div key={index}>
                <SmsWidget data={data} />
              </div>
            ))
          : ""}
      </div>
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </>
  );
}
