import { Modal } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  CircularProgress,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
  makeStyles,
} from "@material-ui/core";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import NoRecord from "../../../../../_metronic/_partials/widgets/NoRecord";
import { isItemExists, transformDataToAddProduct } from "../NewOrderStripeUtils";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));
export const PackagingMaterialListModal = ({ setDialog, dialog, state,
  dispatchReducer }) => {
  const classes = useStyles();
  const [packagingMaterial, setpackagingMaterial] = useState([])
  const [selectedPackageItemsData, setSelectedPackageItems] = useState([])
  const [packageArray, setPackageArray] = useState([]);
  const [qtyData, setQtyData] = useState([]);
  const handleClose = () => {
    setDialog({ check: false });
  };
  const { packagingList } = useSelector(
    (state) => ({
      packagingList: state.order.orderSummaryListStripe,

    }),
    shallowEqual
  );
  useEffect(() => {
    if (packagingList && packagingList?.packagingMaterial) {
      let { packagingMaterial } = packagingList
      setpackagingMaterial(packagingMaterial)
      let allItems = [];

      Object.entries(packagingMaterial).forEach(([category, items]) => {
        Object.entries(items).forEach(([key, value]) => {
          let selectedItem = {
            id: key,
            title: value.title,
            price: value.price
          }
          allItems.push(selectedItem);
        });
      });
      setSelectedPackageItems(allItems)
    }
  }, [packagingList])
  const handleAddPackage = (item) => {
    const arr = [...packageArray];
    const packageIndex = arr.findIndex((e) => e === item);
    if (packageIndex !== -1) {
      arr.splice(packageIndex, 1);
      let updatedQtyArr = qtyData.filter(ele=>ele?.id!==item)
      dispatchReducer({
        type: "SET_DATA",
        data: {
          ...state,
          packageMaterial: [...updatedQtyArr],
          packageMaterialAdd: true
        },
      });
    } else {
      handleQty(1,item)
      arr.push(item);
    }



    setPackageArray(arr);
  };
  const handleQty = (qty, id) => {
    if (+qty < 1) return;
    const arr = [...qtyData];
    const check = arr.findIndex((e) => e?.id === id);
    let product =selectedPackageItemsData.find(el=>el?.id===id)
    if (check !== -1) {  
      arr[check].id = id;
      arr[check].qty = qty;
      arr[check].name=product.title
    } else {
      const data = {
        id,
        qty,
        name:product.title
      };
      arr.push(data);
    }
    dispatchReducer({
      type: "SET_DATA",
      data: {
        ...state,
        packageMaterial: arr,
        packageMaterialAdd: true
      },
    });

    setQtyData(arr);
  };
 
    const handleSave = () => {

     transformDataToPackage()
     
      handleClose();
    };
    function transformDataToPackage() {

        let result = [];
        let existingProducts = [...state?.product_array]
        const packageArray = [...state?.packageMaterial]
        if (Array.isArray(packageArray)) {
          packageArray.map((pac) => {
            let packageId = pac.id
            let itemQty = pac?.qty;
            let itemPrice = selectedPackageItemsData.find((e) => e?.id === packageId)?.price;
            let itemName = selectedPackageItemsData.find((e) => e?.id === packageId)?.title;
            let totalCharge = parseFloat(itemPrice * itemQty)
            let itemExistinState = packageArray.find((el) => el?.id === packageId)

            if (Object.keys(itemExistinState).length >= -1) {
              let itemId = itemExistinState.id;
              let itemName = selectedPackageItemsData.find((el) => el?.id === itemId)?.title
              existingProducts = existingProducts.filter((el) => el.name !== itemName);
            }
            let data = {
              id: null,
              name: itemName,
              type: 'charge',
              quantity: itemQty || 1,
              price_id: pac.id,
              cost: itemPrice || null,
              total: totalCharge || null,
              product_prices: null
            };
            result.push(data)
          })
          const filteredProducts = existingProducts.filter(el => {
            const inSelectedData = selectedPackageItemsData.some(item => item.title === el.name);
            const inPackageArray = packageArray.some(item =>  item.name === el.name);
            return !(inSelectedData && !inPackageArray);
        });
          const updatedProductArray = [...filteredProducts, ...result];

          // Remove duplicates based on 'id' or 'name' if 'id' is null
          const uniqueProductArray = Array.from(new Map(
            updatedProductArray.map(item => [item.id || item.name, item])
          ).values());
          // dispatchReducer({
          //   type: "SET_DATA",
          //   data: {
          //     ...state,
              
          
          //   },
          // });
          const shipProduct = packagingList.allProducts.find((e)=>e.stripe_product_id === process.env.REACT_APP_PRODUCT_SHIPPING_FEE)
          const dataShipProduct = transformDataToAddProduct(shipProduct)
          const addUniqueProducts = isItemExists(state.product_array, dataShipProduct)
          if(!addUniqueProducts){
            dispatchReducer({
              type: "SET_DATA",
              data: { ...state, product_array: [...uniqueProductArray, dataShipProduct], is_packaging_check: true,},
            });
          }else{

            dispatchReducer({
              type: "SET_DATA",
              data: { ...state, product_array: uniqueProductArray, is_packaging_check: true,},
            });
          }
        } 
    }
  return (
    <Modal
      show={dialog.check}
      size="xl"
      aria-labelledby="example-modal-sizes-title-lg"
      onHide={handleClose}
    >
      <Modal.Header className="d-flex justify-content-between">
        <Modal.Title id="example-modal-sizes-title-lg">
          Packaging Material List
        </Modal.Title>
        <span
          class="btn btn-xs btn-icon btn-light btn-hover-primary"
          onClick={handleClose}
        >
          <i class="ki ki-close icon-sm text-muted"></i>
        </span>
      </Modal.Header>
      <Modal.Body>
        {packagingMaterial && Object.entries(packagingMaterial) &&
          Object.entries(packagingMaterial).length > 0 ? (
          Object.entries(packagingMaterial).map(([category, items]) => (
            <div className={` mt-5`}>
              <ExpansionPanel>
                <ExpansionPanelSummary
                  expandIcon={
                    <span className="svg-icon svg-icon-sm svg-icon-primary">
                      <SVG
                        title=" "
                        className="h-25 align-self-end"
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Navigation/Angle-double-down.svg"
                        )}
                      ></SVG>
                    </span>
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography
                    className={classes.heading}
                  >
                    {category}
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div className="w-100">
                    {Object.entries(items).map(([key, value]) => (
                      <div className="d-flex mb-2">
                        <label
                          className="col-md-6 col-form-label text-hover-primary"
                          style={{ cursor: "pointer", paddingLeft: "12px" }}
                        >
                          {value.title}

                        </label>
                        <button
                          type="button"
                          onClick={() => handleAddPackage(key)}
                          style={{ height: "20%", marginTop: "1px" }}
                          className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
                        // disabled={isLoading}
                        >
                          {packageArray.some((e) => e === key)
                            ? "Remove"
                            : "Add"}

                        </button>
                        {packageArray.some((e) => e === key) && (
                          <div className=" ml-1">
                            {/* <InputGroup className="qty-group"> */}
                            <input
                              className="form-control"
                              type="number"
                              placeholder="Qty"
                              aria-label="Qty"
                              // aria-describedby="basic-addon1"
                              // max={"10"}
                              maxLength="12"
                              step="1"
                              value={
                                (state?.packageMaterial &&
                                  state?.packageMaterial?.find(
                                    (e) => e.id === key
                                  )?.qty) ||
                                1

                              }
                              onChange={(e) => handleQty(e.target.value, key)}
                              style={{ paddingRight: "10px", width: "100px" }}
                              disabled={!packageArray.some((e) => e === key)}
                            />
                            {/* </InputGroup> */}
                          </div>
                        )}
                        <div className="ml-2 col-md-6 row col-form-label text-hover-primary">
                          <div
                            className="col-md-3"
                            style={{ marginTop: "-10px" }}
                          >
                            <div>
                              <span>
                                <strong>Unit Price</strong>
                              </span>
                            </div>
                            <label style={{ cursor: "pointer" }}>
                              {/* {state?.currencyCode
                                    ? convertGBPtoPoundSymbol(state.currencyCode)
                                    : ""}{" "}
                                  */}
                              &pound;  {value?.price || ""}
                              {""}
                            </label>
                          </div>
                          <div
                            className="col-md-6"
                            style={{ marginTop: "-10px" }}
                          >
                            <div>
                              <span>
                                <strong>Total</strong>
                              </span>
                            </div>

                            <label style={{ cursor: "pointer" }}>
                              {/* {state?.currencyCode
                                    ? convertGBPtoPoundSymbol(state.currencyCode)
                                    : ""}{" "} */}
                              &pound;  {state?.packageMaterial?.find((e) => e.id === key)
                                ?.qty
                                ? Number(
                                  state?.packageMaterial?.find(
                                    (e) => e.id === key
                                  )?.qty
                                ) * Number(value.price)
                                : value.price}

                            </label>
                          </div>
                        </div>
                        {/* <label
                              
                              style={{ cursor: "pointer", paddingLeft: "45px" }}
                            >
                            {state?.currencyCode
                                ? convertGBPtoPoundSymbol(state.currencyCode)
                                : ""} {  value?.price || ""} |  { state?.currencyCode
                                ? convertGBPtoPoundSymbol(state.currencyCode)
                                : ""} { state?.packageMaterial?.find((e) => e.id === key)
                                  ?.qty ? Number(state?.packageMaterial?.find((e) => e.id === key)
                                  ?.qty)*Number(value.price): value.price}
                            </label> */}
                      </div>
                    ))}
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </div>
          ))
        ) : (
          <>
            {1 > 2 ? (
              <div className="text-center mt-8">
                <CircularProgress />
              </div>
            ) : (
              <NoRecord />
            )}
          </>
        )}

        {/* <ul>
              {Object.entries(packagingList) &&
              Object.entries(packagingList).length > 0 ? (
                Object.entries(packagingList).map(([category, items]) => (
                  <li key={category}>
                    <h3>{category}</h3>
                    <ul>
                      {items.map((item) => (
                        <li key={item}>{item}</li>
                      ))}
                    </ul>
                  </li>
                ))
              ) : (
                <NoRecord />
              )}
            </ul> */}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={() => handleClose()}
            className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
          // disabled={isLoading}
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={() => handleSave()}
            className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
          // disabled={isLoading}
          >
            Save
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}