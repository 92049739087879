import * as requestFromServer from "./customerCrud";
import { customerSlice, callTypes } from "./customerSlice";

const { actions } = customerSlice;

// Action creator for fetching customer data from the server
export const fetchCustomer = (queryParams) => (dispatch) => {
  // Dispatch the 'startCall' action to indicate that a request is being made
  dispatch(actions.startCall({ callType: callTypes.action }));
  // Make a request to the server to find a customer with the given query parameters
  return requestFromServer
    .findCustomer(queryParams)
    .then((response) => {
      // Extract the total count and the entities from the response data
      const { totalCount, entities } = response.data;
      // Dispatch the 'customerFetched' action with the total count and entities
      dispatch(actions.customerFetched({ totalCount, entities }));
    })
    .catch((error) => {
      // Set a client message on the error object and dispatch the 'catchError' action
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
// Action creator for fetching a list of customers from the server
export const fetchCustomerList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findCustomerList(queryParams)
    .then((response) => {
      // Extract the data and meta from the response data
      const { data, meta } = response.data;
      dispatch(
        actions.customerFetchedList({ totalCount: meta.total, entities: data })
      );
    })
    .catch((error) => {
      // Set a client message on the error object and dispatch the 'catchError' action
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

//customer detail
export const fetchCustomerOrderList = (queryParams) => (dispatch) => {
  // Dispatch the 'startCall' action to indicate that a request is being made
  dispatch(actions.startCall({ callType: callTypes.action }));
  dispatch(
    actions.customerFetchedOrderList({
      data: [],
      loading: true,
    })
  );
  // Make a request to the server to find a list of customers with the given query parameters
  return requestFromServer
    .findCustomerOrderList(queryParams)
    .then((response) => {
      const { data } = response.data;

      dispatch(
        actions.customerFetchedOrderList({
          data,
          loading: false,
        })
      );
    })
    .catch((error) => {
      if (error?.response?.status === 404 || error?.response?.status === 400) {
        // Redirect to the error page
        window.location.href = "/error/error-v1"; // Replace '/error' with the actual route of your error page
      }
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

//customer detail timeline
export const fetchCustomerTimeLine = (queryParams, id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .findCustomerTimeLine(queryParams, id)
    .then((response) => {
      const { data } = response.data;

      dispatch(
        actions.customerFetchedTimeLine({
          data,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
