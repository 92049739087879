/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import * as _redux from "../redux";

import { AuthPage, Logout } from "./modules/Auth";
import React, { useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";

import BasePage from "./BasePage";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import { Layout } from "../_metronic/layout";
import SnackBarTool from "../_metronic/_partials/widgets/snack-bar/SnackBar";
import axios from "axios";
import { useHistory } from "react-router-dom";
// import CheckoutPage from "./pages/CheckoutPage";

export function Routes() {
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });

  const { isAuthorized } = useSelector(
    ({ auth, order }) => ({
      isAuthorized: auth.user != null,
      charge: order.carDetail,
    }),
    shallowEqual
  );

  const history = useHistory();
  _redux.setupAxiosResponse(axios, history, setSnack);

  return (
    <Switch>
      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/" />
      )}

      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />

      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/login" />
      ) : (
        <Layout>
          <BasePage />
        </Layout>
      )}
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </Switch>
  );
}
