import * as Yup from "yup";
export const TravelBlock = Yup.object().shape({
  van_id: Yup.string().required("Van is required"),
  time_from: Yup.string().required("Start Time is required"),
  time_to: Yup.string().required("End Time is required"),
  blocking_date_from: Yup.string().required("Start Date is required"),
  blocking_date_to: Yup.string().required("End Date is required"),
  // notes: Yup.string().required("Notes is Required"),
});

export const BlockSchema = Yup.object().shape({
  time_from: Yup.string().required("Start Time required"),
  time_to: Yup.string().required("End Time is required"),
  blocking_date_from: Yup.string().required("Start Date is required"),
  blocking_date_to: Yup.string().required("End Date is required"),
  depot_id: Yup.string().when("van_id", {
    is: (value) => !value,
    then: Yup.string()
    .trim()
    .required("Warehouse is Required"),
    otherwise: Yup.string().nullable(),
  }),
  reason: Yup.string().required("Blocking reason is required"),
  // van_id: Yup.string().when("depot_id", {
  //   is: (values) => !values,
  //   then: Yup.string()
  //     .trim()
  //     .required("Van is Required"),
  //   otherwise: Yup.string().nullable(),
  // }),
  // depot_id: Yup.string().required("End Date is required"),

  // notes: Yup.string().required("Notes is Required"),
});
