/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import NewBreadCrumbs from "../../../../../_metronic/layout/components/subheader/components/NewBreadCrumbs";
import { NewUserCanva } from "./user-canva/NewUser";
import SVG from "react-inlinesvg";
import { fetchUserDetail } from "../../_redux/user/userAction";
import { initIds } from "../../../../../_metronic/_partials/controls/RightPanel";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { useIntl } from "react-intl";

const UserDetailPage = (props) => {
  // Initialize `intl` hook for internationalization
  const intl = useIntl();
  // Array of ids for DOM elements that need to be initialized
  const init = ["kt_new_user_panel"];

  // Initialize DOM elements with `init` ids
  useEffect(() => {
    initIds(init);
  }, [init]);

  // Get the `dispatch` function from the Redux store
  const dispatch = useDispatch();

  // Get `detailData` and `permission` from the Redux store
  const { detailData, permission } = useSelector(
    (state) => ({
      detailData: state.userDetail.user.detail,
      permission: state.auth.permission,
    }),
    shallowEqual
  );

  // Fetch user detail data when the component mounts and the `props.match.params.id` value changes
  useEffect(() => {
    if (props?.match?.params?.id) {
      dispatch(fetchUserDetail(props?.match?.params?.id));
    }
  }, [dispatch, props.match.params.id]);

  return (
    <>
      <div>
        {/* Render the breadcrumbs and tab switching buttons */}
        <NewBreadCrumbs title={intl.formatMessage({ id: "USERS" })} />

        <div className="row">
          <div className="col-lg-12 col-xl-12">
            <div className="container card card-custom card-stretch">
              <div className="card-body">
                <div className="mt-4">
                  <div className="d-flex ">
                    <h3 className="card-title align-items-start flex-column d-flex">
                      {" "}
                      <span className="font-weight-bolder text-dark">
                        {detailData?.first_name}{" "}
                        {detailData && detailData.last_name
                          ? detailData.last_name
                          : ""}
                      </span>
                      <span
                        className={`text-${
                          detailData &&
                          detailData.status &&
                          detailData.status === 1
                            ? "success"
                            : "danger"
                        } font-weight-bold font-size-sm mt-3`}
                      >
                        {detailData &&
                        detailData.status &&
                        detailData.status === 1
                          ? "Active"
                          : "Inactive"}
                      </span>
                      <span className="font-weight-bold font-size-sm mt-3">
                        {detailData &&
                        detailData.is_available &&
                        detailData.is_available === 1
                          ? intl.formatMessage({ id: "Available" })
                          : "Unavailable"}
                        {}
                      </span>
                    </h3>
                    {permission?.setting_user?.edit ? (
                      <span
                        className="svg-icon svg-icon-sm svg-icon-primary ml-3"
                        id="kt_new_user_panel_toggle"
                        style={{ cursor: "pointer" }}
                      >
                        <SVG
                          style={{ color: "orange" }}
                          title="edit"
                          className="h-22 align-self-end"
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Design/Edit.svg"
                          )}
                        ></SVG>
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="mt-4">
                  <span className="font-weight-bolder ">
                    {intl.formatMessage({ id: "User.ID" })}
                  </span>
                  <br />
                  <span className="text-muted"> {detailData?.id}</span>
                </div>
                <div className="mt-4">
                  <span className="font-weight-bolder ">
                    {intl.formatMessage({ id: "Profile" })}
                  </span>
                  <br />
                  <span className="text-muted">
                    {detailData && detailData.profile
                      ? detailData.profile.charAt(0).toUpperCase() +
                        detailData.profile.slice(1).replace(/-/g, " ")
                      : "-"}
                  </span>
                </div>
                <div className="mt-4">
                  <span className="font-weight-bolder ">
                    {intl.formatMessage({ id: "FRANCHISES" })}
                  </span>
                  <br />
                  <span className="text-muted">
                    {detailData && detailData.franchise
                      ? detailData.franchise
                      : "-"}
                  </span>
                </div>
                {/* <div className="mt-4">
                  <span className="font-weight-bolder ">
                    {intl.formatMessage({ id: "COUNTRY" })}
                  </span>
                  <br />
                  <span className="text-muted">
                    {detailData &&
                    detailData?.country &&
                    detailData.country.name
                      ? detailData.country.name
                      : "-"}
                  </span>
                </div> */}
                <div className="mt-4">
                  <span className="font-weight-bolder ">
                    {intl.formatMessage({ id: "Email" })}
                  </span>
                  <br />
                  <span className="text-muted">{detailData?.email}</span>
                </div>
                <div className="mt-4 mb-4">
                  <span className="font-weight-bolder ">
                    {intl.formatMessage({ id: "PHONE" })}
                  </span>
                  <br />
                  <span className="text-muted">{detailData?.phone} </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Render the new user Canva */}
      <NewUserCanva
        title={intl.formatMessage({ id: "Edit.User" })}
        type={"edit"}
        detailData={detailData.loading ? {} : detailData}
      />
    </>
  );
};

export default UserDetailPage;
