import { Card, CardBody } from "../../../_metronic/_partials/controls";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { CreateTasksCanva } from "./tasks-canvas/CreateTasksCanva";
import { Dropdown } from "react-bootstrap";
import NewBreadCrumbs from "../../../_metronic/layout/components/subheader/components/NewBreadCrumbs";
import NotAuthorized from "../../../_metronic/_partials/widgets/NotAuthorized";
import SaveDialog from "./tasks-dialog/SaveDialog";
import SnackBarTool from "../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { TasksFilter } from "./tasks-filter/TasksFilter";
import TasksTable from "./tasks-table/TasksTable";
import { fetchPermission } from "../../pages/redux/permission";
import { fetchTasks } from "./_redux/TasksAction";
import { initIds } from "../../../_metronic/_partials/controls/RightPanel";
import { initialFilter } from "./TasksUIHelpers";
import { useIntl } from "react-intl";
import { useTasksUIContext } from "./TasksUIContext";

export const TasksPage = () => {
  // Use context for managing UI state for tasks
  const tasksUIContext = useTasksUIContext();
  // Use the `intl` hook multilanguage
  const intl = useIntl();

  // Use the `dispatch` hook for dispatching actions
  const dispatch = useDispatch();

  // Get the current user's permissions from the redux store
  const { permission } = useSelector(
    (state) => ({
      permission: state.auth.permission,
    }),
    shallowEqual
  );

  // Initialize list of panel ids to toggle
  const init = ["kt_create_tasks_panel"];
  const inits = ["kt_change_tasks_panel"];

  // Initialize state variables
  const [check, setCheck] = useState(false);
  const [active, setActive] = useState(false);
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [show, setShow] = useState(true);

  // Toggle panel ids when `check` state changes
  useEffect(() => {
    if (check) {
      initIds(inits);
    }
    initIds(init);
  }, [check, init, inits]);

  // Handle completing tasks
  const handleComplete = (submitFunction) => {
    setShow(false);
    setSnack({ call: false, type: "", message: "" });
    const data = {
      task_id: tasksUIContext.ids,
    };
    submitFunction(data)
      .then((res) => {
        // Display success message and fetch updated tasks
        setSnack({ call: true, type: "success", message: res.data.message });
        dispatch(fetchTasks(initialFilter));
        tasksUIContext.setIds([]);
        setShow(true);
      })
      .catch(() => {
        // Set `show` to true if there is an error
        setShow(true);
      });
  };

  // Handle task selection for certain actions
  const handleSelect = (action, taskSelect) => {
    setSnack({ call: false, type: "", message: "" });
    if (taskSelect?.length === 0) {
      // Display error message if no tasks are selected
      setSnack({
        call: true,
        type: "error",
        message: "Please Select a Task",
      });
    } else {
      if (action !== "change") {
        setActive({ type: true, action: action });
      }
    }
    setTimeout(() => {
      setSnack({ call: false, type: "", message: "" });
    }, 2000);
  };

  // Fetch the current user's permissions
  useEffect(() => {
    dispatch(fetchPermission());
  }, [dispatch]);

  return (
    <>
      <div className="d-flex pb-5" style={{ justifyContent: "space-between" }}>
        <NewBreadCrumbs breadcrumb={true} title="Tasks" />
        <div
          style={{
            pointerEvents:
              permission?.task?.create || permission?.task?.edit ? "" : "none",
          }}
        >
          <Dropdown
            className="dropdown-inline"
            drop="down"
            alignRight
            onClick={() => setCheck(check ? false : true)}
          >
            <Dropdown.Toggle
              id="dropdown-toggle-top2"
              variant="transparent"
              className="btn btn-light  font-size-sm font-weight-bolder dropdown-toggle text-dark-75"
              title={
                <span>
                  <i className="fad fa-newspaper"></i> Dropdown
                </span>
              }
            >
              {intl.formatMessage({ id: "Actions" })}
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
              <ul className="navi navi-hover">
                <li
                  className="navi-item"
                  onClick={() => handleSelect("complete", tasksUIContext.ids)}
                >
                  <span
                    className="navi-link pointer"
                    style={{
                      pointerEvents: tasksUIContext?.ids?.length
                        ? "all"
                        : "none",
                    }}
                  >
                    <span className="navi-text">
                      {intl.formatMessage({ id: "Mark.selected.as.Completed" })}
                    </span>
                  </span>
                </li>
                <li
                  className="navi-item"
                  onClick={() => handleSelect("delete", tasksUIContext.ids)}
                >
                  <span
                    className="navi-link pointer"
                    style={{
                      pointerEvents: tasksUIContext?.ids?.length
                        ? "all"
                        : "none",
                    }}
                  >
                    <span className="navi-text">
                      {intl.formatMessage({ id: "Delete" })}
                    </span>
                  </span>
                </li>
                <li
                  className="navi-item"
                  onClick={() => handleSelect("change", tasksUIContext.ids)}
                >
                  <span
                    className="navi-link pointer"
                    id="kt_change_tasks_panel_toggle"
                    style={{
                      pointerEvents: tasksUIContext?.ids?.length
                        ? "all"
                        : "none",
                    }}
                  >
                    <span className="navi-text">
                      {intl.formatMessage({ id: "Change.Task.Owner" })}
                    </span>
                  </span>
                </li>
              </ul>
            </Dropdown.Menu>
          </Dropdown>
          {permission?.task?.create ? (
            <button
              id="kt_create_tasks_panel_toggle"
              className="btn btn-primary  ml-3"
            >
              {intl.formatMessage({ id: "Create.Task" })}
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
      <Card>
        <CardBody>
          {permission?.task?.view ? (
            <>
              <TasksFilter />
              <TasksTable
                show={show}
                setShow={setShow}
                permission={permission}
              />
            </>
          ) : (
            <NotAuthorized />
          )}{" "}
        </CardBody>
      </Card>
      {/* modal for save dialog */}
      <SaveDialog
        setActive={setActive}
        active={active}
        handleComplete={handleComplete}
      />
      {/* task create canva modal */}
      <CreateTasksCanva />
      {/* snack bar component */}
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </>
  );
};
