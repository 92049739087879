import React from 'react';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_test_51HHqFcIE5Vvk0efG4dXZ4Y2a1c2uXEOL396ODH98XDRHukdqqkN6MlYOhcu2fCCHdKuhwuWATNxahBkAahwRTScT00v0LL4wQI'); // Publishable key from Stripe dashboard

const PaymentPage = () => {
    const handleClick = async (event) => {
        const stripe = await stripePromise;

        const result = await stripe.redirectToCheckout({
            lineItems: [
                {
                    price: 'price_1PkCWQIE5Vvk0efGoD0hjZSJ', // Replace with your price ID from Stripe Dashboard
                    quantity: 1,
                },
            ],
            mode: 'payment',
            successUrl: window.location.origin + '/success',
            cancelUrl: window.location.origin + '/cancel',
        });

        if (result.error) {
            console.error(result.error.message);
        }
    };

    return (
        <button role="link" onClick={handleClick}>
            Checkout
        </button>
    );
};

export default PaymentPage;
