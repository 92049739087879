export const productEditValues = (canva) => {
  return {
    mainId:canva?.detailData?.id||"",
    id: canva?.data?.id || "",
    item_price_id: canva?.data?.id || "",
    external_name: canva?.data?.external_name || "",
    name: canva?.data?.name || "",
    price_status: canva?.data?.status || "",
    status: canva?.detailData?.status || "",
    currency_code: canva?.detailData?.currency_code || "",
    pricing_model: canva?.data?.pricing_model || "",
    period: canva?.data?.period || "",
    period_unit: canva?.data?.period_unit || "",
    price: canva.data.price || 0,
    price_description: canva?.data?.description || "",
    show_description_in_invoices:
      canva?.data?.show_description_in_invoices || false,
    show_description_in_quotes:
      canva?.data?.show_description_in_quotes || false,
    is_taxable: canva?.data?.is_taxable
      ? String(canva?.data?.is_taxable)
      : "false",
    invoice_notes: canva?.data?.invoice_notes || "",
    billing_cycles: canva?.data?.billing_cycles || "",
  };
};

export const editPlan = (detailData) => {
  return {
    type: "plan",
    // product_count: detailData?.metadata?.product_count || "",
    external_name: detailData?.external_name || "",
    name: detailData?.name || "",
    country: Number(detailData?.metadata?.country) || "",
    description: detailData?.description || "",
    enabled_in_portal: String(detailData?.enabled_in_portal) || "false",
    enabled_for_checkout: String(detailData?.enabled_for_checkout) || "false",
    id: detailData?.id || "",
    status: detailData?.status || "",
  };
};

export const editAddon = (detailData) => {
  return {
    type: "addon",
    external_name: detailData?.external_name || "",
    name: detailData?.name || "",
    country: detailData?.metadata?.country || "",
    description: detailData?.description || "",
    id: detailData?.id || "",
    unit: detailData?.unit || "",
    enabled_in_portal: String(detailData?.enabled_in_portal) || false,
    currency_code: "",
    status: detailData?.status || "",
  };
};
export const editCharge = (detailData) => {
  return {
    type: "charge",
    external_name: detailData?.external_name || "",
    name: detailData?.name || "",
    description: detailData?.description || "",
    id: detailData?.id || "",
    country: detailData?.metadata?.country || "",
    enabled_in_portal: String(detailData?.enabled_in_portal) || "false",
    pricing_model:
      detailData &&
      detailData.price &&
      detailData.price.length &&
      detailData.price[0]?.item_price?.pricing_model
        ? detailData.price[0]?.item_price?.pricing_model
        : "",
    price:
      detailData &&
      detailData.price &&
      detailData.price.length &&
      detailData.price[0]?.item_price?.price
        ? detailData.price[0]?.item_price?.price
        : "",
    price_description:
      detailData &&
      detailData.price &&
      detailData.price.length &&
      detailData.price[0]?.item_price?.description
        ? detailData.price[0]?.item_price?.description
        : "",
    show_description_in_invoices:
      detailData &&
      detailData.price &&
      detailData.price.length &&
      detailData.price[0]?.item_price?.show_description_in_invoices
        ? String(detailData.price[0]?.item_price.show_description_in_invoices)
        : "false",
    show_description_in_quotes:
      detailData &&
      detailData.price &&
      detailData.price.length &&
      detailData.price[0]?.item_price.show_description_in_quotes
        ? String(detailData.price[0]?.item_price.show_description_in_quotes)
        : "false",
    invoice_notes:
      detailData &&
      detailData.price &&
      detailData.price.length &&
      detailData.price[0]?.item_price.invoice_notes
        ? detailData.price[0]?.item_price.invoice_notes
        : "",
    is_taxable:
      detailData &&
      detailData.price &&
      detailData.price.length &&
      detailData.price[0]?.item_price.is_taxable
        ? String(detailData.price[0]?.item_price.is_taxable)
        : "false",
    is_packaging_material: detailData?.metadata?.is_packaging_material || "0",

    item_price_id:
      detailData &&
      detailData.price &&
      detailData.price.length &&
      detailData.price[0]?.item_price.id
        ? detailData.price[0]?.item_price.id
        : "",
    currency_code: "",
    status: detailData && detailData.status ? detailData.status : "",
    category_type: detailData?.metadata?.category_type || "",
  };
};

export const PricingArray = () => {
  // Define the pricing options
  const pricingOptions = [
    { title: "Weekly", interval: "week", interval_count: 1 },
    { title: "Monthly", interval: "month", interval_count: 1 },
    { title: "Every 2 month", interval: "month", interval_count: 2 },
    { title: "Every 3 month", interval: "month", interval_count: 3 },
    { title: "Every 4 month", interval: "month", interval_count: 4 },
    { title: "Every 5 month", interval: "month", interval_count: 5 },
    { title: "Every 6 months", interval: "month", interval_count: 6 },
    { title: "Every 7 months", interval: "month", interval_count: 7 },
    { title: "Every 8 months", interval: "month", interval_count: 8 },
    { title: "Every 9 months", interval: "month", interval_count: 9 },
    { title: "Every 10 months", interval: "month", interval_count: 10 },
    { title: "Every 11 months", interval: "month", interval_count: 11 },
    { title: "Yearly", interval: "year", interval_count: 1 },
  ];

  // Add the check and disable properties to each pricing option
  const pricingArray = pricingOptions.map((option) => {
    return {
      ...option,
      check: false,
      disable: false,
    };
  });

  return pricingArray;
};
