import axios from "axios";
export const PROMOTIONS_URL = "api/promotions";
export const PROMOTIONS = "promotion";
export const EDIT_PROMOTIONS = "Test_Coupones_tes";
export const DELETE = "/delete/promotion";
export const STRIPE_PROMOTION = "stripe-promotion";

export function getAllPromotions() {
  return axios.get(PROMOTIONS_URL);
}
export function findPromotions(queryParams, nextoffset) {
  const off = nextoffset[queryParams.pageNumber - 2];
  return axios.get(
    process.env.REACT_APP_SITE_URL +
      STRIPE_PROMOTION +
      `?per_page=${queryParams.pageSize}&sort_by=${
        queryParams.sortField
      }&sort_dir=${queryParams.sortOrder}${`&offset=${
        off ? off : ""
      }`}&search_by=${queryParams.filter.search_by}&search_value=${
        queryParams.filter.search_value
      }&search_keyword=${queryParams.filter.search}`

    //apistagingstripe.easystorage.com/api/stripe-promotion?per_page=5&sort_by=created_at&sort_dir=desc&offset=&search_by=&search_value=asd&search_keyword=
    // `${process.env.REACT_APP_SITE_URL + PROMOTIONS}?page=${
    //   queryParams.pageNumber
    // }&per_page=${queryParams.pageSize}&sort_by=${
    //   queryParams.sortField
    // }&sort_dir=${queryParams.sortOrder}&search_keyword=${
    //   queryParams.filter.search
    // }&search_by=${queryParams.filter.search_by}${
    //   queryParams.filter.search_value !== ""
    //     ? `&search_value=${queryParams.filter.search_value}`
    //     : ""
    // }
    // ${
    //   nextoffset && nextoffset.length > 1 && queryParams.pageNumber !== 1
    //     ? `&offset=${JSON.stringify(nextoffset[queryParams.pageNumber - 1])}`
    //     : ""
    // }
    // `
  );
}

export function createPromotion(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + STRIPE_PROMOTION, data);
}
export function promoDetail(id) {
  return axios.get(
    process.env.REACT_APP_SITE_URL + STRIPE_PROMOTION + `/${id}`
  );
}
export function editPromotion(data, id) {
  return axios.post(
    process.env.REACT_APP_SITE_URL + STRIPE_PROMOTION + `/${id}`,
    data
  );
}

export function deletePromotion(data) {
  return axios.post(
    process.env.REACT_APP_SITE_URL + STRIPE_PROMOTION + DELETE,
    data
  );
}
