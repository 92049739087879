//  eslint-disable jsx-a11y/role-supports-aria-props
//  eslint-disable no-script-url,jsx-a11y/anchor-is-valid
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { useIntl } from "react-intl";
import { useSelector, shallowEqual } from "react-redux";
// import { useHistory } from "react-router";

export function AsideMenuList({ layoutProps }) {
  // const history = useHistory();
  const location = useLocation();
  const intl = useIntl();
  const { admin } = useSelector(
    (state) => ({
      admin: state.auth.user,
    }),
    shallowEqual
  );
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
      "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  return (
    <>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className=" menu-icon sideMenuCus">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Files/File.svg")} />
            </span>
            <span className="menu-text blackMenu">
              Dashboard
            </span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/orders", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/orders">
            <span className=" menu-icon sideMenuCus">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Files/File.svg")} />
            </span>
            <span className="menu-text blackMenu">
              {intl.formatMessage({ id: "ORDERS" })}
            </span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/customers", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/customers">
            <span className=" menu-icon sideMenuCus">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Adress-book2.svg"
                )}
              />
            </span>
            <span className="menu-text blackMenu">
              {intl.formatMessage({ id: "CUSTOMERS" })}
            </span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/calendar", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/calendar">
            <span className=" menu-icon sideMenuCus">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Layout/Layout-top-panel-6.svg"
                )}
              />
            </span>
            <span className="menu-text blackMenu">
              {intl.formatMessage({ id: "CALENDAR" })}
            </span>
          </NavLink>
        </li>

        {/* {admin?.roles &&
        admin.roles?.length &&
        admin.roles?.length > 0 &&
        admin.roles[0]?.name !== "franchise-owner" ? (
          <> */}
        <li
          className={`menu-item ${getMenuItemActive("/contacts", true)}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/contacts">
            <span className=" menu-icon sideMenuCus">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Contact1.svg"
                )}
              />
            </span>
            <span className="menu-text blackMenu">CRM</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li
                className={`menu-item  ${getMenuItemActive(
                  "/google-material/inputs/autocomplete"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/contacts">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text blackMenu">
                    {intl.formatMessage({ id: "CONTACTS" })}
                  </span>
                </NavLink>
              </li>


              <li
                className={`menu-item  ${getMenuItemActive(
                  "/google-material/inputs/autocomplete"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link " to="/deals">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text blackMenu">
                    {intl.formatMessage({ id: "DEALS" })}
                  </span>
                </NavLink>
              </li>
              <li
                className={`menu-item  ${getMenuItemActive(
                  "/google-material/inputs/autocomplete"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link " to="/tasks">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text blackMenu">
                    {intl.formatMessage({ id: "TASKS" })}
                  </span>
                </NavLink>
              </li>

              <li
                className={`menu-item  ${getMenuItemActive(
                  "/google-material/inputs/autocomplete"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link " to="/online-quotes">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text blackMenu">
                    {intl.formatMessage({ id: "ONLINE.QUOTES" })}
                  </span>
                </NavLink>
              </li>

              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/google-material/inputs",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              ></li>
            </ul>
          </div>
        </li>
        {/* </>

) : (
  
  ""
)} */}
        {(admin?.roles &&
          admin.roles?.length &&
          admin.roles?.length > 0 &&
          admin.roles[0] === "super-agent") ? "" :
          <li
            className={`menu-item ${getMenuItemActive("/contacts", true)}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/contacts">
              <span className=" menu-icon sideMenuCus">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Contact1.svg"
                  )}
                />
              </span>
              <span className="menu-text blackMenu">Invoice & Subscriptions</span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu ">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li
                  className={`menu-item  ${getMenuItemActive(
                    "/google-material/inputs/autocomplete"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/invoices">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text blackMenu">
                      Invoice
                    </span>
                  </NavLink>
                </li>


                <li
                  className={`menu-item  ${getMenuItemActive(
                    "/google-material/inputs/autocomplete"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link " to="/sub">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text blackMenu">
                    Subscriptions
                    </span>
                  </NavLink>
                </li>

              </ul>
            </div>
          </li>
        }
      </ul>
    </>
  );
}
