//  eslint-disable react-hooks/exhaustive-deps /
// eslint-disable array-callback-return /
import { Form, Modal, Table } from "react-bootstrap";
import React, { useMemo, useState } from "react";
import { useFormik } from "formik";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import SnackBarTool from "../../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { LoadingSpinner } from "../../../../../../_metronic/_partials/controls/LoadingSpinner";
import { getInputClasses } from "../../../../../utils/commonFunction";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import DeleteIcon from "@material-ui/icons/Delete";
import { FormControl, MenuItem } from "@material-ui/core";
// import { chargebee } from "../../../_mocks/dummyData";
import {
  addChargeProducts,
  addChargeProductsInEditOrder,
  addPlanProductsInEditOrder,
  addProduct,
  getProduct,
} from "../../../_redux/order/orderCrud";
import { useEffect } from "react";
import { useOrderUIContext } from "../../../OrderUIContext";
import { getPruductObject } from "../../../EditOrderUtils";
import { useHistory } from "react-router-dom";
// import { editProductCharge } from "../../../order-form/validationSchema";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  // FormLabel,
} from "@material-ui/core";
import NoRecord from "../../../../../../_metronic/_partials/widgets/NoRecord";
import { fetchOrderSummaryListStripe } from "../../../_redux/order/orderAction";
// import { TextField, Autocomplete } from "@material-ui/core";
import Select from "react-select";
import { LoadingCustome } from "../../../../../../_metronic/_partials/controls/LoadingCustome";
import { MuiSelect } from "../../MuiSelect";
import DynamicDialog from "../../../../../../_metronic/_partials/widgets/alert/DynamicDialog";

const AddProductMainModal = ({ dialog, setDialog, state, dispatchReducer }) => {
  const orderUIContext = useOrderUIContext();
  const history = useHistory();
  const dispatch = useDispatch();
  const { editOrderDetail, orderSummaryListStripe, callApi } = useSelector(
    (state) => ({
      editOrderDetail: state.order.editOrderDetail,
      orderSummaryListStripe: state.order.orderSummaryListStripe,
      callApi: state.order.callApi,
    }),
    shallowEqual
  );

  console.log("editOrderDetail", editOrderDetail);
  const [isLoading, setIsLoading] = useState(false);
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);
  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isRtl, setIsRtl] = useState(false);
  const [productArray, setProductArray] = useState([]);
  const [total, setTotal] = useState(0); 
  const [hasDialog, setHasDialog]=useState({modal:false})

  const handleSubmit = () => {
    setLoading(true);
    setHasDialog({modal:false})
    setSnack({ call: false, type: "", message: "" });
    productArray.map((item) => {
      delete item.product_prices;
      if (
        item.type === "addon" &&
        item.stripe_product_id == process.env.REACT_APP_PRODUCT_POD_PROTECT
      ) {
        item.cover = item.quantity * 1000;
        item.quantity = 1;
        item.unit_amount = item.total;
      }
    });
    const insuranceCover = productArray.find(
      (item) =>
        item.stripe_product_id == process.env.REACT_APP_PRODUCT_POD_PROTECT
    );
    let payload = {};
    if (insuranceCover) {
      const insuranceIndex = productArray.findIndex(
        (item) =>
          item.stripe_product_id == process.env.REACT_APP_PRODUCT_POD_PROTECT
      );
      payload = {
        order_id: editOrderDetail.id,
        items: productArray,
        insurance_cover: productArray[insuranceIndex].cover,
      };
    } else {
      payload = {
        order_id: editOrderDetail.id,
        items: productArray,
      };
    }
    if (dialog.type === "charge") {
      addChargeProductsInEditOrder(payload)
        .then((response) => {
          setSnack({
            call: true,
            type: "success",
            message: response.data.message,
          });
          setTimeout(() => {
            handleClose();
            history.push(`/order-details/${editOrderDetail?.id}`);
          }, 2000);
          setLoading(false);
        })
        .catch((error) => {
          setSnack({
            call: true,
            type: "error",
            message: error.response.data.message,
          });
          setTimeout(() => {
            handleClose();
          }, 2000);
          setLoading(false);
        });
    } else {
      addPlanProductsInEditOrder(payload)
        .then((response) => {
          setSnack({
            call: true,
            type: "success",
            message: "Product Added Successfully",
          });
          setTimeout(() => {
            handleClose();
            history.push(`/order-details/${editOrderDetail?.id}`);
          }, 2000);
          setLoading(false);
        })
        .catch((error) => {
          setSnack({
            call: true,
            type: "error",
            message: error.response.data.message,
          });
          setTimeout(() => {
            handleClose();
          }, 2000);
          setLoading(false);
        });
    }
  };

  const handleClose = () => {
    setDialog({ check: false });
  };
  useEffect(() => {
    // const delayDebounceFn = setTimeout(() => {
    dispatch(
      fetchOrderSummaryListStripe({
        franchise_id: editOrderDetail?.franchise_id,
        postcode:
          editOrderDetail?.collection_postcode || editOrderDetail?.postcode,
      })
    );
    // }, 1000);

    // return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editOrderDetail?.franchise_id]);

  const handleAddProduct = (item) => {
    console.log(" ", item);
    const arr = [...productArray];
    const data = {
      stripe_product_id: item?.stripe_product_id,
      name: item?.name,
      type: item?.type,
      product_prices: item?.product_prices,
      total: item?.product_prices[0].unit_amount,
      quantity: 1,
      unit_amount: item?.product_prices[0].unit_amount,
    };
    arr.push(data);
    setProductArray(arr);
  };

const totalPrice = useMemo(()=>{
  let totalSum = productArray.reduce((sum, product) => {
    return sum + parseFloat(product.total); 
}, 0);
return {
   total:totalSum
}
}, [productArray])

console.log("totalPrice", totalPrice);


  const handleChange = (value, index, item) => {
    const arr = [...productArray];
    arr[index].quantity = value;
    if (item.product_prices.length > 1) {
      if (value > 6) {
        console.log("VALUESSS", value);
        arr[index].total =
          Number(item.product_prices[5].unit_amount) +
          Number(item.product_prices[0].unit_amount) * (value - 6);
        arr[index].unit_amount = Number(arr[index].total) / value;
      } else {
        arr[index].total = item?.product_prices[value - 1]?.unit_amount;
        arr[index].unit_amount =
          Number(item?.product_prices[value - 1]?.unit_amount) / value;
      }
    } else {
      arr[index].unit_amount = item?.product_prices[0].unit_amount;
      arr[index].total = item?.product_prices[0].unit_amount * value;
    }
    setProductArray(arr);
  };

  const handleDelete = (index) => {
    const arr = [...productArray];
    arr.splice(index, 1);
    setProductArray(arr);
  };
  const itemsAdd = Array.from({ length: 51 }, (_, index) => index * 1000);
  console.log("editOrderDetail", editOrderDetail);

  return (
    <Modal
      show={dialog?.check}
      size="lg"
      aria-labelledby="example-modal-sizes-title-lg"
      onHide={handleClose}
    >
      <Modal.Header className="d-flex justify-content-between">
        <Modal.Title id="example-modal-sizes-title-lg">
          {dialog?.title}
        </Modal.Title>
        <span
          className="btn btn-xs btn-icon btn-light btn-hover-primary"
          onClick={handleClose}
        >
          <i className="ki ki-close icon-sm text-muted"></i>
        </span>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body
          style={{
            height: "60vh",
            overflow: "scroll",
          }}
        >
          {loading ? (
            <LoadingCustome />
          ) : (
            <>
              <div className="row">
                {/* <div className="w-100 mb-4" style={{ position: "relative" }}>
           <input
             style={{ paddingRight: "40px" }}
             type="text"
             className="form-control"
             name="searchText"
             placeholder={"Search"}
             value={formik.values.search}
             onChange={(e) => {
               formik.setFieldValue("search", e.target.value);
               const value = e.target.value;
               setTimeout(() => {
                 console.log("123123", value);
                 handleGetList(value);
               }, 1500);
               setFieldValue("searchTextBook", "");
               setFieldValue("searchText", e.target.value);
               setTimeout(() => {
                 handleSubmit();
               }, 1000);
             }}
           />
           <i
             style={{
               position: "absolute",
               top: "50%",
               transform: "translateY(-50%)",
               right: "15px",
             }}
             className="fa fa-search"
             aria-hidden="true"
           ></i>
         </div> */}
                <div className="w-100">{callApi && <LoadingSpinner />}</div>
                {/* <Autocomplete
           id="combo-box-demo"
           options={orderSummaryListStripe?.allProducts ?? []} // Using optional chaining and nullish coalescing
           getOptionLabel={(option) => option?.description || ""} // Ensuring option and description are valid
           style={{ width: 300 }}
           renderInput={(params) => (
             <TextField {...params} label="Combo box" variant="outlined" />
           )}
         /> */}
                <div className="w-100">
                  <Select
                    className="basic-single"
                    // classNamePrefix="select"
                    // defaultValue={colourOptions[0]}
                    isDisabled={isDisabled}
                    isLoading={isLoading}
                    isClearable={isClearable}
                    isRtl={isRtl}
                    isSearchable={isSearchable}
                    name="color"
                    value={selectedOption} // Set selected option
                    options={
                      dialog.type === "charge"
                        ? orderSummaryListStripe?.allProducts.filter(
                            (item) => item.type === "charge"
                          )
                        : orderSummaryListStripe?.allProducts.filter(
                            (item) =>
                              (item.type === "plan" || item.type === "addon") &&
                              editOrderDetail?.subscription_data?.phases[0]?.items?.every(
                                (e) => e.product_id !== item.stripe_product_id
                              )
                          ) || []
                    }
                    getOptionLabel={(product) => product.name}
                    onChange={(option) => {
                      handleAddProduct(option); // Update selected option
                      setSelectedOption(null); // Clear selection after each choice
                    }}
                  />
                </div>
                {/* <Table responsive className="summerytable customColor">
           <tbody>
             {orderSummaryListStripe &&
             orderSummaryListStripe?.allProducts?.length > 0 ? (
               orderSummaryListStripe?.allProducts.map((item, index) => {
                 return (
                   <tr
                     onClick={() => {
                       handleAddProduct(item);
                     }}
                   >
                     <td>{item?.description}</td>
                   </tr>
                 );
               })
             ) : (
               <>{!isLoading && <NoRecord />}</>
             )}
           </tbody>
         </Table> */}
              </div>
              {productArray && productArray?.length
                ? productArray.map((item, index) => (
                    <div className="row" key={index}>
                      <Form.Group
                        className=" col-5"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <div style={{ marginTop: "36px" }}>
                          <span>{item.name}</span>
                        </div>
                      </Form.Group>
                      <Form.Group className="mb-5 col-2">
                        <Form.Label>Unit Price</Form.Label>
                        <Form.Control
                          type="text"
                          className={`form-control  ${getInputClasses(
                            `items[${index}].unit_amount`
                          )}`}
                          name={`items[${index}].unit_amount`}
                          value={item.unit_amount}
                          readOnly
                        />
                      </Form.Group>
                      {console.log("itemr", item)}
                      <Form.Group className="mb-5 col-2">
                        <Form.Label>Qty</Form.Label>
                        {process.env.REACT_APP_PRODUCT_POD_PROTECT ==
                        item.stripe_product_id ? (
                          <MuiSelect
                            handleChange={handleChange}
                            item={item}
                            index={index}
                          />
                        ) : (
                          <Form.Control
                            min={1}
                            type="number"
                            className={`form-control  ${getInputClasses(
                              `items[${index}].quantity`
                            )}`}
                            name={`items[${index}].quantity`}
                            value={
                              process.env.REACT_APP_PRODUCT_POD_PROTECT ==
                              item.stripe_product_id
                                ? item.quantity * 1000
                                : item.quantity
                            }
                            onChange={(e) => {
                              if (
                                process.env.REACT_APP_PRODUCT_POD_PROTECT ==
                                item.stripe_product_id
                              ) {
                                const valuee = e.target.value / 1000;
                                handleChange(valuee, index, item);
                                return;
                              }
                              handleChange(e.target.value, index, item);
                            }}
                            // onChange={(e) => {
                            //   formik.setFieldValue(
                            //     `items[${index}].quantity`,
                            //     itemsList[index].quantity < e.target.value
                            //       ? itemsList[index].quantity
                            //       : e.target.value
                            //   );
                            //   formik.setFieldValue(
                            //     `items[${index}].amount`,
                            //     itemsList[index].quantity < e.target.value
                            //       ? itemsList[index].amount
                            //       : Number(e.target.value) *
                            //           Number(item.unit_amount)
                            //   );
                            // }}
                          />
                        )}
                        {/* {/ Button to update value on click /} */}
                      </Form.Group>
                      <Form.Group className="mb-5 col-2">
                        <Form.Label>Total</Form.Label>
                        <Form.Control
                          type="number"
                          className={`form-control  ${getInputClasses(
                            `items[${index}].total`
                          )}`}
                          name={`items[${index}].total`}
                          value={Number(item.total)}
                          // onChange={(e) => {

                          //   const inputValue = parseFloat(e.target.value) || 0;
                          //   formik.setFieldValue(
                          //     `items[${index}].amount`,
                          //     itemsList[index].amount < inputValue
                          //       ? itemsList[index].amount
                          //       : inputValue
                          //   );
                          //   formik.setFieldValue(`items[${index}].quantity`, "0");
                          // }}
                          readOnly
                        />
                      </Form.Group>
                      <div
                        className="mt-5 mx-5"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleDelete(index)}
                      >
                        <DeleteIcon className="mt-5" />
                      </div>
                    </div>
                  ))
                : null}
                {productArray?.length  ? <div style={{paddingLeft:"78%"}}>
                  <span>Total : {totalPrice?.total || "-"}</span>
                </div> : null }
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              onClick={() => handleClose()}
              className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
              //   disabled={isLoading}
            >
              Dismiss
            </button>
            <button
              type="button"
              className="btn btn-primary font-weight-bolder font-size-sm "
              disabled={productArray.length ? false : true}
              onClick={()=>setHasDialog({modal:true})}
            >
              Save
              {loading && (
                <span className="ml-2 mr-2 spinner spinner-white"></span>
              )}
            </button>
          </div>
        </Modal.Footer>
      </form>
      {snack.call ? <SnackBarTool {...snack} /> : ""}
      {hasDialog.modal && (
        <DynamicDialog
          dialog={hasDialog}
          setDialog={setHasDialog}
          header={"Action"}
          mainText={"Are you sure you want to perfome this action"}
          handleChange={handleSubmit}
        />
      )}
    </Modal>
  );
};

export default AddProductMainModal;
