export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    (config) => {
      const {
        auth: { authToken },
      } = store.getState();

      if (authToken) {
        config.headers.Authorization = `${authToken}`;
      }

      return config;
    },
    (err) => Promise.reject(err)
  );
}
export function setupAxiosResponse(axios, history, setSnack) {
  axios.interceptors.response.use(
    function(response) {
      return response;
    },
    function(error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error

      if (
        error &&
        error.response &&
        error.response.status &&
        error.response.status === 401
      ) {
        history.push("/logout");
      }
      return Promise.reject(error);
    }
  );
}
