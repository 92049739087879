import React from "react";
import Card from "../../../_metronic/_partials/settings/components/Card";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

import NewBreadCrumbs from "../../../_metronic/layout/components/subheader/components/NewBreadCrumbs";

export function ReportingPage() {
  const des =
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s";
  return (
    <>
      <NewBreadCrumbs title={"Reporting"} />
      <div className="row reporting-sec"> 
         <div className="col-md-4 pointer" >
        <Card
          title={"Export Order Details"}
          url={"#"}
          description={des}
          svgPath={toAbsoluteUrl(
            "/media/svg/icons/Shopping/Chart-pie.svg"
          )}
        />
        </div>
         <div className="col-md-4 pointer" >
        <Card
          title={"Export Transaction Report"}
          url={"#"}
          description={des}
          svgPath={toAbsoluteUrl(
            "/media/svg/icons/Shopping/Chart-pie.svg"
          )}
        />
        </div>
         <div className="col-md-4 pointer" >
        <Card
          title={"Pods in Storage"}
          url={"/reporting/pods-in-Storage"}
          description={des}
          svgPath={toAbsoluteUrl(
            "/media/svg/icons/Shopping/Chart-pie.svg"
          )}
        />
        </div>

         <div className="col-md-4 pointer" >
        <Card
          title={"Report 2"}
          url={"#"}
          description={des}
          svgPath={toAbsoluteUrl(
            "/media/svg/icons/Shopping/Chart-pie.svg"
          )}
        />
        </div>

         <div className="col-md-4 pointer" >
        <Card
          title={"Report 3"}
          url={"#"}
          description={des}
          svgPath={toAbsoluteUrl(
            "/media/svg/icons/Shopping/Chart-pie.svg"
          )}
        />
        </div>

         <div className="col-md-4 pointer" >
        <Card
          title={"Report 4"}
          url={"#"}
          description={des}
          svgPath={toAbsoluteUrl(
            "/media/svg/icons/Shopping/Chart-pie.svg"
          )}
        />
        </div>

         <div className="col-md-4 pointer" >
        <Card
          title={"Report 5"}
          url={"#"}
          description={des}
          svgPath={toAbsoluteUrl(
            "/media/svg/icons/Shopping/Chart-pie.svg"
          )}
        />
        </div>
         <div className="col-md-4 pointer" >
        <Card
          title={"Report 6"}
          url={"#"}
          description={des}
          svgPath={toAbsoluteUrl(
            "/media/svg/icons/Shopping/Chart-pie.svg"
          )}
        />
        </div>

         <div className="col-md-4 pointer" >
        <Card
          title={"Report 7"}
          url={"#"}
          description={des}
          svgPath={toAbsoluteUrl(
            "/media/svg/icons/Shopping/Chart-pie.svg"
          )}
        />
        </div>
         <div className="col-md-4 pointer" >
        <Card
          title={"Report 8"}
          url={"#"}
          description={des}
          svgPath={toAbsoluteUrl(
            "/media/svg/icons/Shopping/Chart-pie.svg"
          )}
        />
        </div>
      </div>
    </>
  );
}
