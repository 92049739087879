/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { Modal } from "react-bootstrap";
import React, { useState } from "react";
import { useFormik } from "formik";
// import { useDispatch } from "react-redux";
import SnackBarTool from "../../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { LoadingCustome } from "../../../../../../_metronic/_partials/controls/LoadingCustome";
// import { getInputClasses } from "../../../../../utils/commonFunction";
// import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

// import { FormControl, MenuItem, Select } from "@material-ui/core";
// import { chargebee } from "../../../_mocks/dummyData";
// import { findOfferCode } from "../../../_redux/order/orderCrud";
import { BreakDownTable } from "../BreakDownTable";

const BreakDownTableModal = ({ dialog, setDialog, orderUIContext }) => {
  // const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const initialValues = {
    franchise_id: dialog?.franchise || "",
    code: "",
  };

  const handleSubmit = () => {
    setIsLoading(true);
    // findOfferCode(values.code, values?.franchise_id)
    //   .then((res) => {
    //     setIsLoading(false);
    //     const value = res.data?.data.length > 0 && res.data?.data[0]?.coupon;
    //     const data = {
    //       isRemove: true,
    //       amount: value?.discount_amount || "",
    //       type: value?.discount_type || "",
    //       name: value?.name || "",
    //       id: value?.id || "",
    //     };
    //     const arr = [...orderUIContext?.state?.coupon, data];
    //     orderUIContext.dispatchReducer({
    //       type: "SET_DATA",
    //       data: {
    //         ...orderUIContext?.state,
    //         coupon: arr,
    //       },
    //     });
    //     setDialog({ check: false });
    //   })
    //   .catch((error) => {
    //     setIsLoading(false);
    //     setSnack({
    //       call: true,
    //       type: "error",
    //       message: error.response.data.message,
    //     });
    //   });
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    // validationSchema: CompetitorModalSchema,
    onSubmit: handleSubmit,
  });

  const handleClose = () => {
    setDialog({ check: false });
  };
  return (
    <Modal
      show={dialog?.table}
      aria-labelledby="example-modal-sizes-title-lg"
      size="lg"
      onHide={handleClose}
    >
      <Modal.Header className="d-flex justify-content-between">
        <Modal.Title id="example-modal-sizes-title-lg">
          Breakdown of Immediate Charges
        </Modal.Title>
        <span
          className="btn btn-xs btn-icon btn-light btn-hover-primary"
          onClick={handleClose}
        >
          <i className="ki ki-close icon-sm text-muted"></i>
        </span>
      </Modal.Header>
      <form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          {isLoading && <LoadingCustome />}
          <BreakDownTable orderUIContext={orderUIContext} />
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              onClick={() => handleClose()}
              className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
              //   disabled={isLoading}
            >
              Close
            </button>
            {/* <button
              type="submit"
              className="btn btn-primary font-weight-bolder font-size-sm "
            >
              Save
            </button> */}
          </div>
        </Modal.Footer>
      </form>
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </Modal>
  );
};

export default BreakDownTableModal;
