import axios from "axios";

export const ASSIGN_CONTACT = "assign-contact";
export const CALL = "call-rails-list";
export const CONTACT_AGENT = "agent-list";
export const CONTACT_DEAL = "/deal-create";
export const CONTACT_DEAL_LIST = "contact-deals/list";
export const CONTACT_URL = "api/contact";
export const CONTACT_URL_LIVE = "contact";
export const CONTACT_ADD_NOTES = "contact/add/notes";
export const DELETE = "/delete/contact";
export const EMAIL_LISTING = "/email/list";
export const FEED_LIST = "/feeds/list";
export const FRANCHISE_CONTACT = "contact/postcode-franchise-list";
export const FRANCHISE_OWNER_CONTACT = "contact/order/franchise-list";
export const FRANCHISE_OWNER = "contact/contact-owners-list";
export const NOTES_LISTING = "contact/notes/list";
export const REVERT_EMAIL = "/email/imap-email-listing";
export const TASK = "contact/add/tasks";
export const TASK_LIST = "/tasks/list";
export const SEND_EMAIL = "/email/send-contact-mail";
export const SMS_LISTING = "/sms/list";
export const UPDATE_TASKS = "/tasks/update";
export const SEND_SMS = "/add/sms";
export const TASKS_STATUS = "/tasks/update-status";

export function getAllProducts() {
  return axios.get(CONTACT_URL);
}

//contact list api
export function fetchContactList(queryParams) {
  return axios.get(
    `${process.env.REACT_APP_SITE_URL + CONTACT_URL_LIVE}?page=${
      queryParams.pageNumber
    }&per_page=${queryParams.pageSize}&sort_by=${
      queryParams.sortField
    }&sort_dir=${queryParams.sortOrder}&search_keyword=${encodeURIComponent(
      queryParams.filter.search
    )}&search_agent=${queryParams.filter.search_agent}&search_view=${
      queryParams.filter.search_view
    }&from_date=${queryParams.filter?.from_date}&to_date=${
      queryParams.filter?.to_date
    }`
  );
}

//contact deal list
export function contactDealList(id) {
  return axios.get(
    `${process.env.REACT_APP_SITE_URL +
      CONTACT_URL_LIVE}/${CONTACT_DEAL_LIST}?contact_id=${id}`
  );
}

//contact call list
export function contactCallList(id) {
  return axios.get(`${process.env.REACT_APP_SITE_URL}${CALL}?phone=${id}`);
}

//agent list
export function agentList(queryParams) {
  return axios.get(
    `${process.env.REACT_APP_SITE_URL + CONTACT_URL_LIVE}/${CONTACT_AGENT}`
  );
}

//contact detail data
export function contactDetail(id) {
  return axios.get(
    `${process.env.REACT_APP_SITE_URL + CONTACT_URL_LIVE}/${id}`
  );
}

//contact agent assign
export function assignContact(data) {
  return axios.post(
    process.env.REACT_APP_SITE_URL + CONTACT_URL_LIVE + "/" + ASSIGN_CONTACT,
    data
  );
}

//create contact api
export function createContact(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + CONTACT_URL_LIVE, data);
}

//uodate contact api
export function updateContact(id, data) {
  return axios.post(
    process.env.REACT_APP_SITE_URL + CONTACT_URL_LIVE + `/${id}`,
    data
  );
}

//update contact card detail
export function updateContactCard(id, data) {
  return axios.post(
    process.env.REACT_APP_SITE_URL +
      CONTACT_URL_LIVE +
      `/details/update/${id}?franchise_id=${data.franchise_id}&contact_owner_id=${data.contact_owner_id}&contact_stage=${data.contact_stage}&closed_at=${data.closed_at}`,
    data
  );
}

//postcode filter address api
export function getAddress(postcode) {
  return axios.get(
    `${process.env.REACT_APP_GET_ADDRESS_API + "find"}/${postcode}?api-key=${
      process.env.REACT_APP_GET_ADDRESS_KEY
    }&expand=true`
  );
}

//postcode filter franchise api
export function getFranchise(postcode) {
  return axios.get(
    `${process.env.REACT_APP_SITE_URL + FRANCHISE_CONTACT}?postcode=${postcode}`
  );
}
export function getFranchiseOwnerList(postcode) {
  return axios.get(
    `${process.env.REACT_APP_SITE_URL +
      FRANCHISE_OWNER_CONTACT}?postcode=${postcode}`
  );
}

//get franchise Owner api
export function getFranchiseOwner(id) {
  return axios.get(
    `${process.env.REACT_APP_SITE_URL + FRANCHISE_OWNER}?franchise_id=${id}`
  );
}

//add notes on constact details
export function createContactNotes(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + CONTACT_ADD_NOTES, data);
}

//notes listing
export function getContactNotesList(id) {
  return axios.get(
    `${process.env.REACT_APP_SITE_URL + NOTES_LISTING}?contact_id=${id}`
  );
}

//create task
export function createContactTask(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + TASK, data);
}
//tasks list
export function getContactTasksList(id) {
  return axios.get(
    `${process.env.REACT_APP_SITE_URL +
      CONTACT_URL_LIVE +
      TASK_LIST}?contact_id=${id}`
  );
}

//contact email list
export function getContactEmailList(id) {
  return axios.get(
    `${process.env.REACT_APP_SITE_URL +
      CONTACT_URL_LIVE +
      EMAIL_LISTING}?contact_id=${id}`
  );
}
//contact sms list
export function getContactSmsList(id) {
  return axios.get(
    `${process.env.REACT_APP_SITE_URL +
      CONTACT_URL_LIVE +
      SMS_LISTING}?contact_id=${id}`
  );
}

//contact feed list
export function getContactFeedList(id) {
  return axios.get(
    `${process.env.REACT_APP_SITE_URL +
      CONTACT_URL_LIVE +
      FEED_LIST}?contact_id=${id}`
  );
}

//sms create list
export function createSms(data) {
  return axios.post(
    `${process.env.REACT_APP_SITE_URL + CONTACT_URL_LIVE + SEND_SMS}`,
    data
  );
}

//update task on contact detail api
export function updateTask(id, data) {
  return axios.post(
    `${process.env.REACT_APP_SITE_URL + CONTACT_URL_LIVE + UPDATE_TASKS}/${id}`,
    data
  );
}

//send email to  contact
export function sendEmailContact(data) {
  return axios.post(
    `${process.env.REACT_APP_SITE_URL + CONTACT_URL_LIVE + SEND_EMAIL}`,
    data
  );
}

//tasks status
export function taskStatus(data) {
  return axios.post(
    `${process.env.REACT_APP_SITE_URL + CONTACT_URL_LIVE + TASKS_STATUS}`,
    data
  );
}

//contact convert to deal api
export function convertContactDeal(id) {
  return axios.post(
    `${process.env.REACT_APP_SITE_URL + CONTACT_URL_LIVE + CONTACT_DEAL}/${id}`
  );
}

//revert email
export function contactRevertEmail(id) {
  return axios.get(
    `${process.env.REACT_APP_SITE_URL}${CONTACT_URL_LIVE +
      REVERT_EMAIL}?contact_id=${id}`
  );
}

//delete
export function deleteContact(data) {
  return axios.post(
    `${process.env.REACT_APP_SITE_URL + CONTACT_URL_LIVE + DELETE}`,
    data
  );
}
