import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { productsSlice } from "../app/modules/Settings/_redux/products/productsSlice";
import { contactSlice } from "../app/modules/ContactDeals/_redux/contact/contactSlice";
import { dealsSlice } from "../app/modules/ContactDeals/_redux/deals/dealsSlice";
import { customerSlice } from "../app/modules/Customer/_redux/customer/customerSlice";
import { orderSlice } from "../app/modules/Order/_redux/order/orderSlice";
import { promotionsSlice } from "../app/modules/Settings/_redux/promotions/promotionsSlice";
import { countrySlice } from "../app/modules/Settings/_redux/country/countrySlice";
import { reportingSlice } from "../app/modules/Reporting/_redux/reporting/reportingSlice";
import { userSlice } from "../app/modules/Settings/_redux/user/userSlice";
import { tasksSlice } from "../app/modules/Tasks/_redux/TasksSlice";
import { qTasksSlice } from "../app/modules/Quotes/_redux/QTasksSlice";
import { depotSlice } from "../app/modules/Settings/_redux/depots/depotsSlice";
import { franchiseSlice } from "../app/modules/Settings/_redux/franchises/franchisesSlice";
import { commonSlice } from "../app/pages/redux/commonSlice";
import { feedsSlice } from "../app/modules/Settings/_redux/feeds/feedsSlice";
import { containerSlice } from "../app/modules/Settings/_redux/container/containerSlice";
import { InvoiceSlice } from "../app/modules/Invoice/_redux/InvoiceSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  products: productsSlice.reducer,
  contact: contactSlice.reducer,
  deals_list: dealsSlice.reducer,
  customer: customerSlice.reducer,
  order: orderSlice.reducer,
  country: countrySlice.reducer,
  promotions: promotionsSlice.reducer,
  reporting: reportingSlice.reducer,
  userDetail: userSlice.reducer,
  tasks: tasksSlice.reducer,
  quotes: qTasksSlice.reducer,
  depot: depotSlice.reducer,
  franchise: franchiseSlice.reducer,
  common: commonSlice.reducer,
  feeds: feedsSlice.reducer,
  container: containerSlice.reducer,
  invoice: InvoiceSlice.reducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
