import axios from "axios";
export const USER_URL = "api/user";
export const USER = "user";
export const PROFILE = "user/role/list";
export const CREATE_PROFILE = "user/role/add";
export const UPDATE_PROFILE = "user/role/update";
export const USER_PROFILE_DROPDOWN = "/profile-dropdown/list";
export const PARENT_PROFILE = "/parent-profile-dropdown/list";
export const PROFILE_DETAIL = "user/role/details/";
export const DELETE_USER = "user/delete/user";
export const DELETE_PROFILE = "user/delete/role";

export function getAllProducts() {
  return axios.get(USER_URL);
}
export function findUser(queryParams, abortController) {
  return axios.get(
    `${process.env.REACT_APP_SITE_URL + USER}?page=${
      queryParams.pageNumber
    }&per_page=${queryParams.pageSize}&sort_by=${
      queryParams.sortField
    }&sort_dir=${queryParams.sortOrder}&search_keyword=${
      queryParams.filter.search_keyword
    }&search_status=${queryParams.filter.status}&search_franchise=${
      queryParams.filter.search_franchise
    }`,
    { signal: abortController?.signal }
  );
}
export function findUserDetail(id) {
  return axios.get(`${process.env.REACT_APP_SITE_URL + USER}/${id}`);
}

export function createUser(data) {
  return axios.post(`${process.env.REACT_APP_SITE_URL + USER}`, data);
}
export function updateUser(data, id) {
  return axios.post(`${process.env.REACT_APP_SITE_URL + USER}/${id}`, data);
}
export function findProfile(queryParams) {
  return axios.get(
    `${process.env.REACT_APP_SITE_URL + PROFILE}?page=${
      queryParams.pageNumber
    }&per_page=${queryParams.pageSize}&sort_by=${
      queryParams.sortField
    }&sort_dir=${queryParams.sortOrder}&search_keyword=${
      queryParams.filter.search_keyword
    }&search_status=${queryParams.filter.status}`
  );
}

export function createProfile(data) {
  return axios.post(`${process.env.REACT_APP_SITE_URL + CREATE_PROFILE}`, data);
}
export function updateProfile(data, id) {
  return axios.post(
    `${process.env.REACT_APP_SITE_URL + UPDATE_PROFILE}/${id}`,
    data
  );
}

export function findProfileDrop() {
  return axios.get(
    `${process.env.REACT_APP_SITE_URL + USER + USER_PROFILE_DROPDOWN}`
  );
}
export function findProfileDetail(id) {
  return axios.get(`${process.env.REACT_APP_SITE_URL + PROFILE_DETAIL + id}`);
}

export function findParentProfileDrop() {
  return axios.get(`${process.env.REACT_APP_SITE_URL + USER + PARENT_PROFILE}`);
}
//delete
export function deleteUser(data) {
  return axios.post(`${process.env.REACT_APP_SITE_URL + DELETE_USER}`, data);
}
export function deleteProfile(data) {
  return axios.post(`${process.env.REACT_APP_SITE_URL + DELETE_PROFILE}`, data);
}
