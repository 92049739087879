export const ProductStatusCssClasses = ["success", "info", ""];
export const ProductStatusTitles = ["Selling", "Sold"];
export const ProductConditionCssClasses = ["success", "danger", ""];
export const ProductConditionTitles = ["New", "Used"];
export const defaultSorted = [{ dataField: "id", order: "asc" }];
export const sizePerPageList = [
  { text: "20", value: 20 },
  { text: "50", value: 50 },
  { text: "100", value: 100 },
];
export const initialFilter = {
  filter: {
    search: "",
    search_agent: "",
    search_view: "",
    from_date: "",
    to_date: "",
  },
  sortOrder: "", // asc||desc
  sortField: "",
  pageNumber: 1,
  pageSize: 20,
};

export const contactActionarray = [
  {
    id: "notes",
    img: "/media/svg/icons/Design/Edit.svg",
    title: "Create Note",
  },
  {
    id: "tasks",
    img: "/media/svg/icons/Communication/Clipboard-list.svg",
    title: "Create Task",
  },
  // {
  //   id: "calls",
  //   img: "/media/svg/icons/Communication/Active-call.svg",
  //   title: "Call Contact",
  // },
  // {
  //   id: "emails",
  //   img: "/media/svg/icons/Communication/Mail.svg",
  //   title: "Email Contact",
  // },
  {
    id: "sms",
    img: "/media/svg/icons/Communication/Chat5.svg",
    title: "SMS Contact",
  },
];
