import React, { useState, useEffect, useRef } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { MenuItem, FormControl, Select } from "@material-ui/core/";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import DatePicker from "react-datepicker";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import moment from "moment";
import { useIntl } from "react-intl";
import "rc-time-picker/assets/index.css";
import TimePicker from "rc-time-picker";
import { useCallback } from "react";
export function TaskCreate({
  formik,
  ownerList,
  date,
  setDate,
  loading,
  admin,
}) {
  const ref = useRef();
  const intl = useIntl();
  const [timer, setTimer] = useState(false);
  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const handleSetDate = (e) => {
    const formated = moment(e).format("YYYY-MM-DD");
    setDate(e);
    formik.setFieldValue("due_date", formated);
  };
  const handleScroll = useCallback(
    (e) => {
      setTimer(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [timer]
  );
  useEffect(() => {
    if (timer) {
      document
        .getElementById("uncontrolled-tab-example-tabpane-tasks")
        .addEventListener("scroll", handleScroll);
    } else {
      document
        .getElementById("uncontrolled-tab-example-tabpane-tasks")
        .removeEventListener("scroll", handleScroll);
    }

    return () => {
      document
        .getElementById("uncontrolled-tab-example-tabpane-tasks")
        .removeEventListener("scroll", handleScroll);
    };
  }, [timer, handleScroll]);

  return (
    <>
      <div id="demo" ref={ref} className="fieldset-area mt-5">
        <fieldset className="mb-5">
          {/*<legend>15-11-2021 at 13:24</legend>*/}
          <div className="p-5 ">
            <div className="row">
              <div className="col-md-4">
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>
                    {intl.formatMessage({ id: "Task.Type" })}
                  </Form.Label>
                  <FormControl fullWidth>
                    <Select
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                      className={`form-control w-100  ${getInputClasses(
                        "task_type"
                      )}`}
                      name="task_type"
                      {...formik.getFieldProps("task_type")}
                      IconComponent={(props) => (
                        <i {...props}>
                          <KeyboardArrowDownIcon />
                        </i>
                      )}
                      inputProps={{ "aria-label": "Without label" }}
                      displayEmpty
                    >
                      <MenuItem value="">
                        {intl.formatMessage({ id: "Select" })}
                      </MenuItem>
                      <MenuItem value={"Call"}>
                        {intl.formatMessage({ id: "Call" })}
                      </MenuItem>
                      <MenuItem value={"Email"}>
                        {intl.formatMessage({ id: "Email" })}
                      </MenuItem>
                      <MenuItem value={"SMS"}>
                        {intl.formatMessage({ id: "SMS" })}
                      </MenuItem>
                    </Select>
                  </FormControl>
                  {formik.touched.task_type && formik.errors.task_type ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.task_type}
                      </div>
                    </div>
                  ) : null}
                </Form.Group>
              </div>
              <div className="col-md-4 mb-5">
                <FormControl fullWidth>
                  <label>{intl.formatMessage({ id: "Priority" })}</label>
                  <Select
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                    IconComponent={(props) => (
                      <i {...props}>
                        <KeyboardArrowDownIcon />
                      </i>
                    )}
                    inputProps={{ "aria-label": "Without label" }}
                    displayEmpty
                    name="priority"
                    className={`form-control w-100  ${getInputClasses(
                      "priority"
                    )}`}
                    {...formik.getFieldProps("priority")}
                  >
                    <MenuItem value="">
                      {intl.formatMessage({ id: "Select" })}
                    </MenuItem>
                    <MenuItem value="Hot">
                      {intl.formatMessage({ id: "Hot" })}
                    </MenuItem>
                    <MenuItem value="Medium">
                      {intl.formatMessage({ id: "Medium" })}
                    </MenuItem>
                    <MenuItem value="Low">
                      {intl.formatMessage({ id: "Low" })}{" "}
                    </MenuItem>
                  </Select>
                </FormControl>
                {formik.touched.priority && formik.errors.priority ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.priority}
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="col-md-4">
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>
                    {intl.formatMessage({ id: "Task.Name" })}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    className={`form-control  ${getInputClasses("task_name")}`}
                    name="task_name"
                    {...formik.getFieldProps("task_name")}
                  />
                  {formik.touched.task_name && formik.errors.task_name ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.task_name}
                      </div>
                    </div>
                  ) : null}
                </Form.Group>
              </div>
              <div className="col-md-4 date-picker">
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>
                    {intl.formatMessage({ id: "Due.Date" })}
                  </Form.Label>
                  <InputGroup className="calendr">
                    <DatePicker
                      fullWidth
                      fixedHeight
                      className="form-control w-100"
                      wrapperClassName="datepicker"
                      dateFormat="dd-MM-yyyy"
                      minDate={new Date()}
                      selected={Date.parse(date)}
                      onChange={(date) => handleSetDate(date)}
                      // popperClassName="date-picker-reports"
                      placeholderText="DD-MM-YYYY"
                    />
                    <CalendarTodayIcon />
                  </InputGroup>
                  {formik.touched.due_date && formik.errors.due_date ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.due_date}
                      </div>
                    </div>
                  ) : null}
                </Form.Group>
              </div>
              <div className="col-md-4 mb-5">
                <label>{intl.formatMessage({ id: "Time" })}</label>
                <div>
                  <TimePicker
                    defaultValue={moment()}
                    value={moment(
                      `${moment().format("yyyy-MM-DD")} ${
                        formik.values.time === "" ? "00:00" : formik.values.time
                      }`
                    )}
                    showSecond={false}
                    open={timer}
                    onOpen={() => setTimer(true)}
                    onClose={() => setTimer(false)}
                    onChange={(e) =>
                      formik.setFieldValue(
                        "time",
                        moment(e).format("HH:mm") === "Invalid date"
                          ? "00:00"
                          : moment(e).format("HH:mm")
                      )
                    }
                    focusOnOpen
                    clearIcon
                  />
                </div>
                {/* <input
                  type="time"
                  name="time"
                  className={`form-control  ${getInputClasses("time")}`}
                  placeholder={intl.formatMessage({ id: "Task.Name" })}
                  {...formik.getFieldProps("time")}
                /> */}
                {formik.touched.time && formik.errors.time ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.time}</div>
                  </div>
                ) : null}
              </div>
              <div className="col-md-4">
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>
                    {intl.formatMessage({ id: "Task.Owner" })}
                  </Form.Label>
                  <FormControl fullWidth>
                    <Select
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                      IconComponent={(props) => (
                        <i {...props}>
                          <KeyboardArrowDownIcon />
                        </i>
                      )}
                      inputProps={{ "aria-label": "Without label" }}
                      displayEmpty
                      className={`form-control w-100  ${getInputClasses(
                        "task_owner_id"
                      )}`}
                      name="task_owner_id"
                      {...formik.getFieldProps("task_owner_id")}
                    >
                      <MenuItem value="">Select</MenuItem>
                      <MenuItem value="is_unassigned">
                        {intl.formatMessage({ id: "Unassigned" })}
                      </MenuItem>
                      <MenuItem value={admin.id}>
                        {admin && admin.first_name ? admin.first_name : " "}
                        &nbsp;
                        {admin && admin.last_name ? admin.last_name : " "}
                      </MenuItem>
                      {ownerList &&
                        ownerList.map((data, index) => (
                          <MenuItem
                            key={index}
                            value={data.id}
                            disabled={data?.is_available !== 1 ? true : false}
                          >
                            {data && data.first_name ? data.first_name : " "}
                            &nbsp;
                            {data && data.last_name ? data.last_name : " "}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  {formik.touched.task_owner_id &&
                  formik.errors.task_owner_id ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.task_owner_id}
                      </div>
                    </div>
                  ) : null}
                </Form.Group>
              </div>
              <div className="col-md-12">
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>
                    {intl.formatMessage({ id: "Task.Description" })}
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    className={`form-control  ${getInputClasses(
                      "task_description"
                    )}`}
                    name="task_description"
                    {...formik.getFieldProps("task_description")}
                  />
                  {formik.touched.task_description &&
                  formik.errors.task_description ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.task_description}
                      </div>
                    </div>
                  ) : null}
                </Form.Group>
              </div>
              <div className="col-md-12 text-right">
                <button
                  type="reset"
                  className="btn btn-dark font-weight-bolder font-size-sm mr-3"
                  onClick={() => setDate("")}
                >
                  {intl.formatMessage({ id: "CANCEL" })}
                </button>
                <button
                  type="submit"
                  disabled={loading}
                  className="btn btn-primary font-weight-bolder font-size-sm "
                >
                  {intl.formatMessage({ id: "SAVE" })}
                  {loading && (
                    <span className="ml-3 mr-3 spinner spinner-white"></span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
    </>
  );
}
