/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { FormControl, MenuItem, Select } from "@material-ui/core/";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { months } from "../modules/Order/_mocks/dummyData";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import {
  addCards,
  addCharge,
  addPerson,
  expireCheck,
  findNewCustomerCard,
  getBacPaymentIntent,
  getSmsPlaceOrder,
  orderPlaceStripe,
  paymentFaildError,
  paymentIntentStripe,
  placeOrderThroughUrl,
  sendQuoteCheckOut,
  smsAddCards,
  smsAddCharge,
  smsAddPod,
} from "../modules/Order/_redux/order/orderCrud";
import {
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { useFormik } from "formik";
import { getPaymentIntent } from "../modules/Order/_redux/order/orderCrud";
import SnackBarTool from "../../_metronic/_partials/widgets/snack-bar/SnackBar";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { shallowEqual, useSelector } from "react-redux";
import { LoadingCustome } from "../../_metronic/_partials/controls/LoadingCustome";
import { convertGBPtoPoundSymbol } from "../utils/commonFunction";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import valid from "card-validator";
import { CheckOutCardSchema } from "../modules/Order/order-form/validationSchema";
import CancelIcon from "@material-ui/icons/Cancel";
import creditCardType from "credit-card-type";
import CheckoutDialog from "../../_metronic/_partials/widgets/alert/CheckoutDialog";
import { handleFinalPrice, newOrderPaymentIntent } from "../modules/Order/orderUtils";
import { useContextState } from "../modules/Order/reducer/reducerStripe";

const CheckoutPage = () => {
  const { search } = useLocation();
  const query = queryString.parse(search);
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();

  // const initialValues = {
  //   cardNumber: "",
  //   expires: "",
  //   expiresYear: "",
  //   cvv: "",
  //   nameCard: "",
  // };

  // const {
  //   orderData,
  //   searchNewOrder,
  //   cardDetail,
  //   storage,
  //   quoteData,
  //   admin,
  //   permission,
  //   role,
  //   cardDetailArray,
  //   orderSummaryListStripe,
  //   orderListLoading,
  //   siteData, siteLoading
  // } = useSelector(
  //   (state) => ({
  //     orderData: state.order,
  //     searchNewOrder: state.order.searchNew,
  //     cardDetail: state.order.cardDetail,
  //     cardDetailArray: state.order.cardDetailArray,
  //     storage: state.auth.storageData?.customerData,
  //     quoteData: state.order.quoteData,
  //     admin: state.auth.user,
  //     permission: state.auth.permission,
  //     orderSummaryListStripe: state.order.orderSummaryListStripe,
  //     orderListLoading: state.order.callApi,
  //     siteData: state.order.siteData,
  //     siteLoading: state.order.siteLoading,
  //   }),
  //   shallowEqual
  // );

  const { state, dispatchReducer } = useContextState();
  console.log("state", state)
  // const [cardType, setCardType] = useState('');
  const [checkoutData, setCheckoutData] = useState("");
  const [showDetails, setShowDetails] = useState("");
  const [payment, setPayment] = useState({});

  const [paymentIntent, setPaymentIntent] = useState();
  console.log("PAYMENTINTENT",paymentIntent )
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [dialog, setDialog] = useState(false);
  const [isStatus, setIsStatus] = useState({
    loading: false,
    success: false,
    expire: false,
    expireMessage: "",
  });
  console.log("showDetails", showDetails,checkoutData);
  const intl = useIntl();
  const fullYear = new Date().getFullYear();
  const years = Array.from(new Array(20), (val, index) => index + fullYear);
  // const handlePaymentIntent = (value, stValue) => {
  //   setSnack({
  //     call: false,
  //     type: "",
  //     message: "",
  //   });
  //   const data = {
  //     first_name: value?.first_name || "",
  //     last_name: value?.last_name || "",
  //     email: value?.email || "",
  //     address: value?.address || "",
  //     city: value?.city || "",
  //     state: "",
  //     zip: value?.postcode || "",
  //     country: query?.currency ? query?.currency.substring(0, 2) : "",
  //     amount:
  //       query?.is_reservation == 2 &&
  //       (query?.is_container == 1 ||
  //         (query?.is_container != 1 && value?.contact?.franchise_id == 6))
  //         ? (+query?.amount * 0.1).toFixed(2)
  //         : query?.amount || "",
  //     currency_code: query?.currency || "",
  //     franchise_id: value?.contact?.franchise_id || value?.franchise_id || "",
  //   };
  //   formik.setFieldValue(
  //     "nameCard",
  //     value?.first_name + " " + value?.last_name
  //   );
  //   getPaymentIntent(data)
  //     .then((res) => {
  //       // setSnack({ call: true, type: "success", message: res.data.message });
  //       setPayment(res.data);
  //     })
  //     .catch((err) => {
  //       setDialog(true);
  //       setSnack({
  //         call: true,
  //         type: "error",
  //         message: err.response.data.message,
  //       });
  //     });
  // };
  const handleCheck = () => {
    setIsStatus({ ...isStatus, loading: true });
    expireCheck(query?.token)
      .then((res) => {
        setIsStatus({ ...isStatus, loading: true });
      })
      .catch((error) => {
        setIsStatus({
          ...isStatus,
          expire: true,
          expireMessage: error.response.data.message,
        });
      });
  };
  // useEffect(() => {
  //   if (checkoutData?.customer) {
  //     setIsStatus({ ...isStatus, loading: true });
  //     handleCheck();
  //     expireCheck(query?.token)
  //       .then((res) => {
  //         setIsStatus({ ...isStatus, loading: false });
  //       })
  //       .catch((error) => {
  //         setIsStatus({
  //           ...isStatus,
  //           expire: true,
  //           expireMessage: error.response.data.message,
  //         });
  //       });
  //     try {
  //       window.Chargebee.init({
  //         site: checkoutData?.customer?.contact?.franchise?.chargebee_key || "",
  //         publishableKey:
  //           checkoutData?.customer?.contact?.franchise?.stripe_pk || "",
  //       });
  //       // setCbI({ cbInstance: window.Chargebee.getInstance() });
  //     } catch (error) {}

  //     handlePaymentIntent(checkoutData?.customer);
  //   }
  // }, [checkoutData?.customer]);


  const handleCardPaymentIntent = () => {
    setSnack({
      call: false,
      type: "",
      message: "",
    });
    setIsStatus({ ...isStatus, loading: true });

    // setState({ ...state3ds, intentLoading: true });
    if(checkoutData){
      const data = {
        first_name: checkoutData?.customer?.first_name || "",
        last_name: checkoutData?.customer?.last_name || "",
        email: checkoutData?.customer?.email || "",
        address: checkoutData?.customer?.address || "",
        city: checkoutData?.customer?.city || "",
        state: "",
        zip: checkoutData?.customer?.postcode || "",
        country: query?.currency ? query?.currency.substring(0, 2) : "",
        amount:
          (query?.is_reservation == 1 &&
          (query?.is_container == 1 ||
            (query?.is_container != 1 &&
              checkoutData?.customer?.contact?.franchise_id == 6))
            ? (+query?.amount * 0.1).toFixed(2)
            : query?.amount || "")  ,
        currency_code: query?.currency || "",
        franchise_id:
          checkoutData?.customer?.contact?.franchise_id ||
          checkoutData?.customer?.franchise_id ||
          "",
      };
      paymentIntentStripe(data)
      .then((res) => {
        setPaymentIntent(res?.data.data);
        setIsStatus({ ...isStatus, loading: false });
        // dispatchReducer({ type: "paymentIntent", value: res.data });
        // setState({ ...state3ds, intentLoading: false });
      })
      .catch((err) => {
        setSnack({
          call: true,
          type: "error",
          message: err?.response?.data?.message,
        });
      });
    }
    else {
      return;
    }
   
  };

  const handleSubmit = async(event) => {
    event.preventDefault();
    setSnack({ call: false, type: "", message: "" });
    setIsStatus({ ...isStatus, loading: true });

    if (!stripe || !elements) {
      setIsStatus({ ...isStatus, loading: false });
      return; // Stripe.js has not yet loaded
    }

    const cardElement = elements.getElement(CardElement);

    if(paymentIntent){
      const {
        error: piError,
        setupIntent,
      } = await stripe.confirmCardSetup(paymentIntent.client_secret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: checkoutData?.customer?.first_name || "", // Customer's name
            email: checkoutData?.customer?.email || "", // Customer's email
            phone:  checkoutData?.customer?.phone || "", // Customer's phone number
            address: {
              line1:checkoutData?.customer?.address || "",
              line2: "", 
              city:checkoutData?.customer?.city || "",
               
              postal_code:checkoutData?.customer?.postcode || "",
                
              country: "GB", // Two-letter country code (ISO 3166-1 alpha-2)
            },
          },
        },
      });
      if (piError) {
        // Handle error
        setIsStatus({ ...isStatus, loading: false });
        setSnack({
          call: true,
          type: "error",
          message: piError.message,
        });
      } else {        
        const data = {
          link_pay: 1,
          default_payment_method: setupIntent?.payment_method || "",
          data: query?.data,
          customer_id: paymentIntent?.customer?.id || "",
          token: query?.token,
          payment_type: query?.payment_type,
          is_reservation: query?.is_reservation,
        };
        placeOrderThroughUrl(data)
          .then((res) => {
            setSnack({
              call: true,
              type: "success",
              message: res.data.message,
            });
            setIsStatus({ ...isStatus, loading: false, success:true });
          })
          .catch((err) => {
            setIsStatus({ ...isStatus, loading: false });

            setSnack({
              call: true,
              type: "error",
              message: err?.response?.data?.message,
            });
          });
      }
    }
    
  };


  // const formik = useFormik({
  //   initialValues,
  //   validationSchema: CheckOutCardSchema(valid),
  //   onSubmit: handleSubmit,
  // });

  const handleGetCustomerData = () => {
    findNewCustomerCard(query?.user_id)
      .then((response) => {
        const { data } = response.data;
        setCheckoutData({ ...checkoutData, customer: data[0] });
        
      })
      .catch(() => {});
  };
  // const handleCardNumber = (incomingValue) => {
  //   const rawText = [...incomingValue.split(" ").join("")]; // Remove old space
  //   const creditCard = []; // Create card as array
  //   rawText.forEach((t, i) => {
  //     if (i % 4 === 0 && i !== 0) creditCard.push(" "); // Add space
  //     creditCard.push(t);
  //   });

  //   formik.setFieldValue("cardNumber", creditCard.join(""));
  // };
  useEffect(() => {
    if( query?.payment_type !== "bac"){
      handleCheck();
    }
    handleGetCustomerData();
  }, []);
  useEffect(() => {
  if(checkoutData !== ""){
    handleCardPaymentIntent();
  }
  }, [checkoutData]);

  // const handleSetCvv = (e) => {
  //   const cardTypeInfo = creditCardType(formik.values.cardNumber);
  //   const cardType = cardTypeInfo[0]?.type || "";
  //   if (cardType == "american-express") {
  //     if (e.target.value?.length < 5) {
  //       formik.setFieldValue("cvv", e.target.value);
  //     }
  //   } else if (e.target.value?.length < 4) {
  //     formik.setFieldValue("cvv", e.target.value);
  //   }
  // };
  const handleAction = (check) => {
    setSnack({
      call: false,
      type: "",
      message: "",
    });
    if (check === "quote" || check === "draft") {
      const data = {
        quote_by_link: 1,
        quote_type: check,
        data: query?.data,
        customer_id: payment?.data?.customer_id || "",
        token: query?.token,
      };
      sendQuoteCheckOut(data)
        .then((res) =>
          setSnack({
            call: true,
            type: "success",
            message: res.data.message,
          })
        )
        .catch((error) =>
          setSnack({
            call: true,
            type: "error",
            message: error.message,
          })
        );
    } else {
      window.location.reload();
    }
  };

  const cardElementOptions = {
    style: {
      base: {
        // Add your custom styles here
        fontSize: "16px",
        color: "#424770",
        letterSpacing: "0.025em",
        fontFamily: "Source Code Pro, monospace",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#9e2146",
      },
    },
    hidePostalCode: true, // This removes the ZIP code field
  };

  const handleBacPaymentIntent = () => {
    // setSnack({
    //   call: false,
    //   type: "",
    //   message: "",
    // });
    setIsStatus({ ...isStatus, loading: true });
    const data = {
      first_name: checkoutData?.customer?.first_name || "",
      last_name: checkoutData?.customer?.last_name || "",
      email: checkoutData?.customer?.email || "",
      address: checkoutData?.customer?.address || "",
      city: checkoutData?.customer?.city || "",
      state: "",
      zip: checkoutData?.customer?.postcode || "",
      country: query?.currency ? query?.currency.substring(0, 2) : "",
      amount:
        (query?.is_reservation == 1 &&
        (query?.is_container == 1 ||
          (query?.is_container != 1 &&
            checkoutData?.customer?.contact?.franchise_id == 6))
          ? (+query?.amount * 0.1).toFixed(2)
          : query?.amount || "") * 100 || "",
      currency_code: query?.currency || "",
      franchise_id:
        checkoutData?.customer?.contact?.franchise_id ||
        checkoutData?.customer?.franchise_id ||
        "",
    };

    getBacPaymentIntent(data)
      .then((res) => {
        setIsStatus({ ...isStatus, loading: false });
        // setSnack({ call: true, type: "success", message: res.data.message });
        setShowDetails(res.data.data);
        // setPayment(res.data);
      })
      .catch((err) => {
        setDialog(true);
        setSnack({
          call: true,
          type: "error",
          message: err.response.data.message,
        });
      });
  };
  console.log("showDetails-23", showDetails);
  

  const handleBacPlaceOrder = () => {
    const data = {
      link_pay: 1,
      default_payment_method: showDetails?.payment_method || "",
      payment_intent_id:showDetails?.id||"",
      data: query?.data,
      customer_id: showDetails?.customer || "",
      token: query?.token,
      payment_type: query?.payment_type,
      is_reservation: query?.is_reservation,
    };
    placeOrderThroughUrl(data)
      .then((res) => {
        setSnack({
          call: true,
          type: "success",
          message: res.data.message,
        });
        // setTimeout(() => {
        //   history.push(`/successful-order/${res.data.data?.id}`);
        // }, 2000);
      })
      .catch((err) => {
        setSnack({
          call: true,
          type: "error",
          message: err?.response?.data?.message,
        });
      });
  };

  return (
    <div class="Checkout " style={{ overflow:"scroll" }}>
      <div>
        <img
          data-v-16d825fb=""
          src={toAbsoluteUrl("/media/logos/easystorage.png")}
          alt="logo"
        ></img>
      </div>
      {isStatus?.loading && <LoadingCustome />}

      <div class="Form mt-4  row">
        <div className={`col-sm-12 font-size-lg mb-6`}>
          {!isStatus.success && !isStatus.expire && (
            <span>
              Dear{" "}
              <strong>
                {checkoutData?.customer?.first_name +
                  " " +
                  checkoutData?.customer?.last_name}
                ,{" "}
              </strong>
              <div>
                Please use this secure payment link to complete your booking.
              </div>
              {/* , kindly pay your order amount */}
            </span>
          )}
        </div>
        {/* {isStatus.expire ?} */}
        {!isStatus.success && !isStatus.expire && (
          <>
            <div className="col-md-6 col-sm-12">
              <span>
                Order Amount:{" "}
                {query?.currency
                  ? convertGBPtoPoundSymbol(query?.currency)
                  : ""}
                {(+query?.amount).toFixed(2)}
              </span>
              {/* <h2>
                {query?.currency
                  ? convertGBPtoPoundSymbol(query?.currency)
                  : ""}
                {query?.amount}
              </h2> */}
              <div className="mt-2 mb-4 ">
                <span>
                  {query.is_container == 1 ? (
                    "Container"
                  ) : (
                    <>
                      {+query?.planPodsQty * 35} sq ft - {query?.pod || "-"} easyPods
                    </>
                  )}
                </span>
              </div>
              {query.is_container == 1 ? (
                ""
              ) : (
                <div>
                  {query?.pod <= 5 ? (
                    <img
                      data-v-16d825fb=""
                      src={`https://bookstaging.easystorage.com/images/${query?.pod}.png`}
                      alt="pod"
                    ></img>
                  ) : (
                    <img
                      data-v-16d825fb=""
                      src={`https://bookstaging.easystorage.com/images/6.png`}
                      alt="pod"
                    ></img>
                  )}
                </div>
              )}
            </div>
            <div
              className={`${
                query?.payment_type === "bac" ? " " : "col-md-6"
              } col-sm-12`}
            >
              {query?.payment_type === "bac" ? (
                <div className="mt-4">
                  <button
                    className="btn btn-primary w-100"
                    type="button"
                    onClick={() => {
                      handleBacPaymentIntent();
                    }}
                  >
                    Show Bank Information
                  </button>
                  {showDetails &&
                  showDetails !== "" &&
                  showDetails.next_action !== null ? (
                    <div className="mt-4">
                      <h3>Bank Information</h3>
                      <div>
                        <div className=" mt-3">
                          <span>
                            Account holder name :{" "}
                            <strong>
                              {
                                showDetails?.next_action
                                  ?.display_bank_transfer_instructions
                                  ?.financial_addresses[0].sort_code
                                  ?.account_holder_name
                              }
                            </strong>
                          </span>
                          <span>
                            <FileCopyIcon
                              className="mx-2"
                              style={{ cursor: "pointer", fontSize: "1.2rem" }}
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  showDetails?.next_action
                                    ?.display_bank_transfer_instructions
                                    ?.financial_addresses[0].sort_code
                                    ?.account_holder_name
                                );
                                setSnack({
                                  call: true,
                                  type: "success",
                                  message: "Copied",
                                });
                                setTimeout(() => {
                                  setSnack({
                                    call: false,
                                    type: "",
                                    message: "",
                                  });
                                }, 1000);
                              }}
                            />
                          </span>
                        </div>

                        <div className="  mt-2">
                          <span>
                            Account number :{" "}
                            <strong>
                              {
                                showDetails?.next_action
                                  ?.display_bank_transfer_instructions
                                  ?.financial_addresses[0].sort_code
                                  ?.account_number
                              }
                            </strong>
                          </span>
                          <span>
                            <FileCopyIcon
                              className="mx-2"
                              style={{ cursor: "pointer", fontSize: "1.2rem" }}
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  showDetails?.next_action
                                    ?.display_bank_transfer_instructions
                                    ?.financial_addresses[0].sort_code
                                    ?.account_number
                                );
                                setSnack({
                                  call: true,
                                  type: "success",
                                  message: "Copied",
                                });
                                setTimeout(() => {
                                  setSnack({
                                    call: false,
                                    type: "",
                                    message: "",
                                  });
                                }, 1000);
                              }}
                            />
                          </span>
                        </div>
                        <div className=" mt-2">
                          <span>
                            Sort code :{" "}
                            <strong>
                              {
                                showDetails?.next_action
                                  ?.display_bank_transfer_instructions
                                  ?.financial_addresses[0].sort_code?.sort_code
                              }
                            </strong>
                          </span>
                          <span>
                            <FileCopyIcon
                              className="mx-2"
                              style={{ cursor: "pointer", fontSize: "1.2rem" }}
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  showDetails?.next_action
                                    ?.display_bank_transfer_instructions
                                    ?.financial_addresses[0].sort_code
                                    ?.sort_code
                                );
                                setSnack({
                                  call: true,
                                  type: "success",
                                  message: "Copied",
                                });
                                setTimeout(() => {
                                  setSnack({
                                    call: false,
                                    type: "",
                                    message: "",
                                  });
                                }, 1000);
                              }}
                            />
                          </span>
                        </div>
                        <div className="mt-2">
                          <span>
                            Reference Id :{" "}
                            <strong>
                              {
                                showDetails?.next_action
                                  ?.display_bank_transfer_instructions
                                  ?.reference
                              }
                            </strong>
                          </span>
                          <span>
                            <FileCopyIcon
                              className="mx-2"
                              style={{ cursor: "pointer", fontSize: "1.2rem" }}
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  showDetails?.next_action
                                    ?.display_bank_transfer_instructions
                                    ?.reference
                                );
                                setSnack({
                                  call: true,
                                  type: "success",
                                  message: "Copied",
                                });
                                setTimeout(() => {
                                  setSnack({
                                    call: false,
                                    type: "",
                                    message: "",
                                  });
                                }, 1000);
                              }}
                            />
                          </span>
                        </div>
                        <div className="my-2">
                          <h6>
                            *Note: Use this reference id to confirm payment.
                          </h6>
                        </div>
                      </div>
                      <button
                        className="btn btn-primary w-100 mt-3"
                        type="button"
                        onClick={() => {
                          handleBacPlaceOrder();
                        }}
                      >
                        Place Order
                      </button>
                    </div>
                  ) : null}
                </div>
              ) : (
                <form className="mt-5" onSubmit={handleSubmit}>
                   <CardElement options={cardElementOptions} />
                  <button className="btn btn-primary w-100 mt-5" type="submit">
                    Pay{" "}
                    {query?.currency
                      ? convertGBPtoPoundSymbol(query?.currency)
                      : ""}
                    {query?.is_reservation == 1 ? (
                      (+query?.amount * 0.1).toFixed(2)
                    ) : (
                      <>{(+query?.amount).toFixed(2)}</>
                    )}
                  </button>
                </form>
              )}
            </div>
          </>
        )}
        {isStatus?.success && !isStatus.expire && (
          <div className="col-md-12 col-sm-12 text-center">
            <CheckCircleIcon
              style={{ color: "green", height: "20%", width: "20%" }}
            />
            <h3>Payment successful</h3>
          </div>
        )}
        {isStatus.expire && (
          <div className="col-md-12 col-sm-12 text-center">
            <CancelIcon style={{ color: "red", height: "20%", width: "20%" }} />
            <h3>{isStatus?.expireMessage || "Token Expired"}</h3>
          </div>
        )}
      </div>
      <CheckoutDialog
        dialog={dialog}
        setDialog={setDialog}
        handleSend={handleAction}
      />
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </div>
  );
};

export default CheckoutPage;
