import React, { useEffect } from "react";

import { FranchisesCard } from "./FranchisesCard";
import { FranchisesUIProvider } from "./FranchisesUIContext";
import { NewFranchiseCanva } from "./franchise-canva/NewFranchiseCanva";
import { fetchPermission } from "../../../../pages/redux/permission";
import { initIds } from "../../../../../_metronic/_partials/controls/RightPanel";
import { useDispatch } from "react-redux";

export function FranchisesPage(props) {
  const dispatch = useDispatch();
  const init = ["kt_new_franchise_panel"];

  useEffect(() => {
    initIds(init);
  }, [init]);

  const franchisesUIEvents = {};

  useEffect(() => {
    dispatch(fetchPermission());
  }, [dispatch]);

  return (
    <>
      <FranchisesUIProvider franchisesUIEvents={franchisesUIEvents}>
        {/* rendering franchise card  */}
        <FranchisesCard {...props?.location} />
        {/* rendering create franchise canva  */}
        <NewFranchiseCanva />
      </FranchisesUIProvider>
    </>
  );
}
