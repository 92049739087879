/* eslint-disable array-callback-return */
import { InputGroup, Modal } from "react-bootstrap";
import React, { useEffect, useState } from "react";
// import { FormControl, MenuItem, Select } from "@material-ui/core";
// import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { fetchFranchisesDepot } from "../../Settings/_redux/franchises/franchisesActions";
import { requestPayment, resend } from "../_redux/order/orderCrud";
import SnackBarTool from "../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import DatePicker from "react-datepicker";
import moment from "moment";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import {
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    // FormLabel,
} from "@material-ui/core";
import { fetchBillingCardDetail, fetchMyOrderDetailCard } from "../_redux/order/orderAction";

// import {
//   fetchMyOrderDetailCard,
//   fetchMyOrderTimeline,
// } from "../_redux/order/orderAction";
const ReactivateSubModal = ({
    header,
    mainText,
    dialog,
    setDialog,
    handleAction,
    children,
    orderId,
    orderDetail,
}) => {
    const dispatch = useDispatch();
    const [snack, setSnack] = useState({ call: false, type: "", message: "" });
    const initialValues = {
        order_id: orderId || "",
        invoice_option: true,
        reactivate_from: null,



    };


    const handleSubmit = (values) => {
        setSnack({ call: false, type: "", message: "" });
        resend(values)
            .then((res) => {

                dispatch(fetchMyOrderDetailCard(orderDetail?.id));
                // dispatch(fetchMyOrderTimeline(orderDetail?.id));
                setSnack({ call: true, type: "success", message: res.data.message });
                setTimeout(() => {
                    handleClose();
                }, 3000);
            })
            .catch((error) => {
                setSnack({
                    call: true,
                    type: "error",
                    message: error.response.data.message,
                });
            });
    };
    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        // validationSchema: AssignContactSchema(intl, Yup),
        onSubmit: handleSubmit,
    });
    const handleClose = () => {
        setDialog({ check: false });

    };
    return (
        <Modal
            show={dialog.check}
            aria-labelledby="example-modal-sizes-title-lg"
            onHide={() => handleClose()}
        >
            <Modal.Header className="d-flex justify-content-between">
                <Modal.Title id="example-modal-sizes-title-lg">
                    Reactivate Subscription
                </Modal.Title>
                <span
                    class="btn btn-xs btn-icon btn-light btn-hover-primary"
                    onClick={() => handleClose()}
                >
                    <i class="ki ki-close icon-sm text-muted"></i>
                </span>
            </Modal.Header>
            <form onSubmit={formik.handleSubmit}>
                <Modal.Body style={{ marginTop: "-30px" }}>
                    <br />
                    <span className="mt-3 ">Reactivate From </span>
                    <InputGroup className="calendr mb-6">
                        <DatePicker
                            fullWidth
                            fixedHeight
                            className="form-control"
                            wrapperClassName="datepicker"
                            dateFormat="dd-MM-yyyy"
                            placeholderText="From Date"
                            selected={Date.parse(formik?.values?.reactivate_from)}
                            startDate={Date.parse(formik?.values?.reactivate_from)}
                            // endDate={Date.parse(formData?.to_date)}
                            // selectsRange
                            // isClearable
                            onChange={(dates) => {
                                // const [start, end] = dates;
                                // setFormData({ ...formData, date: dates ? moment(dates).format("yyyy-MM-DD") : null })
                                formik.setFieldValue("reactivate_from", dates ? moment(dates).format("yyyy-MM-DD") : null)

                                //   if (dates === null) {
                                //     handleSubmit();
                                //   }

                            }}
                        />


                        <CalendarTodayIcon />
                    </InputGroup>
                    <span className="mt-6 ">Billing Cycle</span>
                    <input
                        type="text"
                        name="qty"
                        placeholder={"Quantity"}
                        label="External Name"
                        className={`form-control  `}
                        max={3}
                        maxLength="3"
                        value={"Forever"}
                        disabled
                    // {...formik.getFieldProps("qty")}
                    />
                    {formik.touched.qty && formik.errors.qty ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formik.errors.qty}</div>
                        </div>
                    ) : null}

                    <FormControl component="fieldset" style={{ marginTop: "5%" }}>
                        <label>Invoice Options</label>
                        <RadioGroup
                            aria-label="gender"
                            name="auto"
                            value={formik.values?.invoice_option}
                            onChange={(e) => {
                                formik.setFieldValue("invoice_option", e.target.value);
                            }}
                        >
                            <FormControlLabel
                                value="true"
                                control={<Radio />}
                                label={"Immediately"}
                            />
                            <FormControlLabel
                                value="false"
                                control={<Radio />}
                                label={"Add to unbilled charges"}
                            />
                        </RadioGroup>
                    </FormControl>
                </Modal.Body>
                <Modal.Footer>
                    <div>
                        <button
                            type="button"
                            onClick={() => handleClose()}
                            className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="btn btn-primary font-weight-bolder font-size-sm "
                        >
                            Ok
                        </button>
                    </div>
                </Modal.Footer>
            </form>
            {snack.call ? <SnackBarTool {...snack} /> : ""}
        </Modal>
    );
};

export default ReactivateSubModal;
